import React from 'react'
import Footer from './Footer';
import { Collapse } from 'reactstrap';
import $ from 'jquery';
import MobileFooter from './MobileFooter';
import CommonHeder from './CommonHeder';
import InnerHelpContact from './InnerHelpContact';

class AllHelpCovid extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
          values: 0
        }
      }
      toggle(value) {
        this.setState({ values: value });
    }

    componentDidMount(){

      $(document).ready(function(){
        $("#myInput").on("keyup", function() {
          var value = $(this).val().toLowerCase();
          $("#myData h5").filter(function() {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
          });

        });
      });
    }

   render(){

    let collapseNo=this.state.values;

       return(
        <div>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
        {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.2/css/all.css" integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay" crossorigin="anonymous"> */}
        <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>truGeny</title>
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/header_footer.css" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <link rel="stylesheet" href="./css/sticky.css" />
        <link rel="stylesheet" href="./style-mobile2.css" />
        <link rel="stylesheet" href="./style-mobile.css" />
        <link rel="stylesheet" href="newsticky.css" />

        <style dangerouslySetInnerHTML={{__html: "\n/* @media only screen and (min-width: 992px)\n/* {\n.custom-pad-mar {\n  padding-left:25rem;\n  padding-right:25rem;\n\n} */\n\n.activenavbar{\n  color:#4f5be7!important;\n}\n.card-body{\n  text-align: left!important;\n}\n.heart-banner-sec-wrap .heart-banner-sec{\n  background: url(./img/help/bg1.png);\n  background-position:center;\n  background-size: cover;\n  background-repeat: no-repeat;\n}\n.i-button{\n  display: inline-block;\nborder-radius: 60px;\nbox-shadow: 0px 0px 2px #888;\npadding: 0.5em 0.6em;\n}\n.heart-banner-sec-wrap .heart-banner-sec{\n  background: url(./img/help/banner-gc.png);\n  background-position:center;\n  background-size: cover;\n  background-repeat: no-repeat;\n}\n" }} />
        <CommonHeder></CommonHeder>

        <InnerHelpContact></InnerHelpContact>

        <section className="heart-banner-sec-wrap banner-position">
        <div className=" heart-banner-sec ">
          <div className="container pt-4 mt-2">
            <h1 ><strong>FAQ - Pregnancy and COVID-19</strong></h1>
            <h2 className="my-3" style={{fontSize: '24px', fontWeight: 300}}>The below information will help you to find answers to common questions, such as to learn about
            What effect does the coronavirus have on pregnant women, What effect will coronavirus have on my baby if I am diagnosed with the infection etc.
            </h2>
            <div className="input-group " style={{height: '75px', boxShadow: '0 17px 40px rgba(92, 103, 108, 0.2)', backgroundColor: '#ffffff', borderRadius: '2rem'}}>
              <input type="text" className="form-control m-0" placeholder="Search for help..." name="name" id="myInput" onkeyup="myFunction()" style={{borderTopLeftRadius: '2rem', borderBottomLeftRadius: '2rem', height: '75px', boxShadow: '0 17px 40px rgba(92, 103, 108, 0.2)', backgroundColor: '#ffffff'}} required />

              <div className="valid-tooltip">
                Looks good!
              </div>
              <div className="input-group-prepend border-0 d-flex justify-content-center">
                <span className="btn btn-outline-primary input-group-text border-0 justify-content-center search" style={{cursor: 'pointer', borderBottomRightRadius: '2rem', borderTopRightRadius: '2rem', backgroundColor: '#4f5be7', width: '160px', color: 'white'}} id="validationTooltip01">SERACH</span>
              </div>
            </div>
          </div>
          {/* eo-container*/}
          <div className="d-flex justify-content-center" id="secc1">
            <a style={{scrollBehavior: '[ auto | smooth ]'}} href="#secc1">  <img className="img-fluid d-none d-md-block d-lg-block btn-hover-white1" src="./img/111.png" /></a>
          </div>
        </div>
        {/* eo-heart-banner-sec */}
      </section>
        {/* eo-heart-banner-sec-wrap */}
        {/* ------------------------------------------------------heart-banner-sec over------------------------------------------------------------------ */}
        <section className="container " id="myData">
          <nav aria-label="breadcrumb mt-5 ">
            <ol className="breadcrumb " style={{backgroundColor: '#fff', paddingTop: '3rem'}}>
              <li className="breadcrumb-item">
                <a href="/al_help" className="roboto-reg-18px" style={{color: '#4f5be7'}}><b>Support</b></a>
              </li>
              {/* <li class="breadcrumb-item"><a href="#">Library</a></li> */}
              <li className="breadcrumb-item active" aria-current="page">
                <a className="roboto-reg-18px">Coronavirus</a>
              </li>
            </ol>
          </nav>
          {/* Support / Genetic Counselling */}
          <section className="d-none useful-resources-sec">
            {/* <div class="container ur-header-wrap ">
          <h2 class="text-center" >Useful Resources for you to learn more</h2>
        </div> */}
            {/* eo what-you-sec-wrap */}
            <div className="ur-pill-wrap pl-0 container row ">
              <div className="  pill-1 col-sm col-md-6 col-lg-6 col-xl-3 mb-3 ur-pill">
                <a className=" ur-link btn rounded-pill clearfix" href="/register" role="button">
                  <span className="ur-img-wrap float-left">
                    <img className="card-img-top img-fluid text-center p-2 " src="img/help/pill1.png" alt="Card image cap" />
                  </span>
                  <span className="ur-link-title float-left ">truGeny<br />Account<br />Creation</span>
                </a>
              </div>{/* eo-ur-pill*/}
              <div className="ur-pill pill-2  col-sm col-md-6 col-lg-6 col-xl-3 mb-3">
                <a className=" ur-link btn rounded-pill clearfix" href="#" role="button">
                  <span className="ur-img-wrap float-left">
                    <img className="card-img-top img-fluid text-center p-2 " src="img/help/pill2.png" alt="Card image cap" />
                  </span>
                  <span className="ur-link-title float-left ">truGeny<br />Test Kit</span>
                </a>
              </div>{/* eo-ur-pill*/}
              <div className="ur-pill pill-3 col-sm col-md-6 col-lg-6 col-xl-3 mb-3">
                <a className=" ur-link btn rounded-pill clearfix" href="#" role="button">
                  <span className="ur-img-wrap float-left">
                    <img className="card-img-top img-fluid text-center p-2 " src="img/help/pill3.png" alt="Card image cap" />
                  </span>
                  <span className="ur-link-title float-left ">My<br />Sample</span>
                </a>
              </div>{/* eo-ur-pill*/}
              <div className="ur-pill pill-4  col-sm col-md-6 col-lg-6 col-xl-3 mb-3">
                <a className=" ur-link btn rounded-pill clearfix" href="#" role="button">
                  <span className="ur-img-wrap float-left">
                    <img className="card-img-top img-fluid text-center p-2 " src="img/help/pill4.png" alt="Card image cap" />
                  </span>
                  <span className="ur-link-title float-left ">My<br />report</span>
                </a>
              </div>{/* eo-ur-pill*/}
              {/*    </div> */}
            </div>{/* eo-ur-pill-wrap */}
          </section>
          <div className=" mt-5 ">
            <h1 className="roboto-black-36px" style={{color: '#4f5be7'}}></h1>
            <div className="text-center">
              <div className="p-2 circle">
                <div className="pr-5 pb-5  hc-accordian-wrap">
                  <div className="accordion" id="accordionIhc">
                    <div className="card">
                      <div className="card-header" id="headingOne">
                        <h5 className="acc-header collapse-color mb-0" style={{color:collapseNo==1?'#2C333C':'#4F5BE7'}}>What effect does the coronavirus have on pregnant women?


                        <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne">{collapseNo==1?<i onClick={()=>this.toggle(0)} className="fa fa-minus-circle  rounded-circle " />:<i onClick={()=>this.toggle(1)} className="fa fa-plus-circle  rounded-circle " />}</button>
                          {/* <a   class="float-right roboto-bold-18px btn btn-link rounded-circle" style="color: #4f5be7;"data-toggle="collapse" data-target="#collapseOne"><p class="d-none"></p><i class="fa fa-plus-circle"></i></a> */}
                        </h5>
                      </div>
                      <Collapse isOpen={collapseNo==1}>
                      <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p><b>Ans :</b>   If you are naturally healthy and are pregnant, you can rest easy. As of yet, no cases have been
                            recorded with proof of increased infection from coronavirus on pregnant women. If infected, you
                            will most likely encounter similar symptoms of cold/flu.<br/><br/>

                              Symptoms of pneumonia might appear in older people with weak immunity or long-term conditions. Other
                               than that, you can stay in touch with your maternity doctor and you should be good to go. For emergency
                                purposes, always keep a tab on the contact information available on <a href="https://www.mohfw.gov.in/">MoHFW | Home</a></p>
                        </div>
                      </div>
                      </Collapse>

                    </div>
                    <div className="card">
                      <div className="card-header" id="headingTwo">
                        <h5 className="acc-header collapse-color mb-0" style={{color:collapseNo==2?'#2C333C':'#4F5BE7'}}>
                        What effect will coronavirus have on my baby if I am diagnosed with the infection?

                        <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne">{collapseNo==2?<i onClick={()=>this.toggle(0)} className="fa fa-minus-circle  rounded-circle " />:<i onClick={()=>this.toggle(2)} className="fa fa-plus-circle  rounded-circle " />}</button>
                        </h5>
                      </div>
                      <Collapse isOpen={collapseNo==2}>
                      <div id="collapseTwo" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p className="roboto-reg-18px"><b>Ans :</b> There has been no concrete evidence of vertical transmission; from mother to child. This is primarily because the virus is relatively new.
<br/><br/>
However, a case from Wuhan reported on March 26 suggested that the infection could occur in newborn babies. Another birth in London also had similar infection reported. Further updates from those are still awaited. We’ll update this section as soon as we receive any conclusive information on the same.
</p>
                        </div>
                      </div>
                      </Collapse>

                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        <h5 className="acc-header collapse-color mb-0" style={{color:collapseNo==3?'#2C333C':'#4F5BE7'}}>
                        What can I do to reduce my risk of catching coronavirus?

                        <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne">{collapseNo==3?<i onClick={()=>this.toggle(0)} className="fa fa-minus-circle  rounded-circle " />:<i onClick={()=>this.toggle(3)} className="fa fa-plus-circle  rounded-circle " />}</button>
                        </h5>
                      </div>
                      <Collapse isOpen={collapseNo==3}>
                      <div id="collapse3" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>Ans : As a normal healthy individual, pregnant or otherwise, you can follow the normal guidelines on https://www.mohfw.gov.in/ in the Awareness section. As a pregnant woman, you can keep in touch with your doctor for the best ad hoc advice on anything health and welfare related.
<br/>
In general, you can -
</p>
<ol>
<li>Wash your hands regularly using soap and water</li>
<li>Use a tissue when you or anyone in your family coughs or sneezes, discard this and wash your hands</li>
<li>Avoid unnecessary contact with anyone outside your immediate physical household</li>
<li>Maintain social physical distancing when commuting or travelling</li>
<li>Avoid non-essential use of public transport when possible</li>
<li>Work from home, where possible</li>
<li>Avoid meeting your family till it is safe, if you’re staying away from them</li>
<li>Avoid meeting people with symptoms of flu or cold</li>
<li>Wash fruits and vegetables with a simple mixture of Dettol and water, rinse them with water before consuming raw</li>
<li>Order groceries or essential items online if possible</li>
<li>Use telephone or online services to contact your GP or other essential services</li>

</ol>
                          </div>
                        </div>
                      </Collapse>
                       <a>
                      </a></div><a>
                    </a><div className="card "><a>
                      </a><div className="card-header" id="headingThree"><a>
                        </a><h5 className="acc-header collapse-color mb-0" style={{color:collapseNo==4?'#2C333C':'#4F5BE7'}}><a>
                          Why are pregnant women in a vulnerable group?
                          </a>
                          <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne">{collapseNo==4?<i onClick={()=>this.toggle(0)} className="fa fa-minus-circle  rounded-circle " />:<i onClick={()=>this.toggle(4)} className="fa fa-plus-circle  rounded-circle " />}</button>
                        </h5>
                      </div>
                      <Collapse isOpen={collapseNo==4}>
                      <div id="collapse4" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>Ans: Stay safe, maintain social physical distancing and follow the safety guidelines issued by the government.

If you are in your third trimester (more than 28 weeks pregnant) you should be particularly attentive towards social physical distancing and minimising any contact with others.
<br/>
<br/>
If you are pregnant, kindly -
</p>
                        <ol>
                        <li>Only go outside for food, health reasons or work (but only if you cannot work from home)</li>
                        <li>Stay at least 2 metres (6 feet) away from other people at all times, if you are outside</li>
                        <li>Wash your hands as soon as you get home or after handling items from outside</li>

                        </ol>
                        <p className="pt-2">In case you need to know more about testing centres for COVID-19, you can visit this link from ICMR.</p>
                        </div>
                      </div>
                      </Collapse>

                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        <h5 className="acc-header collapse-color mb-0" style={{color:collapseNo==5?'#2C333C':'#4F5BE7'}}>
                          Should I attend my antenatal and postnatal appointments?
                          <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne">{collapseNo==5?<i onClick={()=>this.toggle(0)} className="fa fa-minus-circle  rounded-circle " />:<i onClick={()=>this.toggle(5)} className="fa fa-plus-circle  rounded-circle " />}</button>                        </h5>
                      </div>
                      <Collapse isOpen={collapseNo==5}>
                      <div id="collapse5" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>Definitely. However, if you have to postpone or reschedule your appointment, always make it a point to convey the same way in advance to your doctor.
<br/>
Please understand that due to the current situation, some things will change even at clinics, hospitals and maternity centres.
</p>

<br/>
<br/>
<ol>
<li>There will be reduced workforce, so appointments might be rescheduled</li>
<li>Some appointments might be conducted through calls or video conferencing if physical tests aren’t necessary</li>
<li>The recommendation that your bump will be measured from around 26 weeks of pregnancy should be observed by your maternity team unless you are in the recommended 14 day self isolation period</li>
<li>If you are between appointments, please bear with the team and don’t rush them</li>
<li>If you are attending more regularly in pregnancy, then your maternity team will be in touch with plans</li>
<li>If you miss an appointment and haven’t heard back, please contact the team for a new appointment</li>
</ol>

Always remember the following -
<ol>
<li>If you have a concern, it’s better to write to the team clearly. Phone calls can be reserved for emergencies</li>
<li>If you have symptoms suggestive of coronavirus contact your maternity services and they will arrange the right place and time to come for your visits. Do not attend a routine clinic</li>
<li>Keep minimal contact with people outside of your immediate daily circle</li>
<li>You could be asked to reduce the number of antenatal visits if your doctor considers this necessary. Don’t cancel your visits on your own</li>
</ol>
                          {/* <p class="pt-2" >Have more questions? <A href="">Submit a request</a></p> */}
                        </div>
                      </div>
                      </Collapse>

                    </div>
                    <div className="card">
                      <div className="card-header" id="headingOne">
                        <h5 className="acc-header collapse-color mb-0" style={{color:collapseNo==6?'#2C333C':'#4F5BE7'}}>
                          I haven’t received the email address confirmation and my account creation confirmation email.
                          <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne">{collapseNo==6?<i onClick={()=>this.toggle(0)} className="fa fa-minus-circle  rounded-circle " />:<i onClick={()=>this.toggle(6)} className="fa fa-plus-circle  rounded-circle " />}</button>
                        </h5>
                      </div>
                      <Collapse isOpen={collapseNo==6}>
                      <div id="collapse6" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>If you haven’t received the truGeny account creation confirmation mail, try the following:</p>
                          <div className="container">
                            <p>Check your spam and trash folders for emails from donotreply@truGeny.com.</p>
                            <p>There may be a delay in sending or receiving this email. Typically all our customers will receive the email confirmation within a few minutes but in some cases it may take up to 24 hours.</p>
                            <p>Ensure that the spelling of the email address you have provided is accurate and you have access to this mail account. , and that the email address is able to send and receive emails.</p>
                            <p>Check your spam and trash folders for emails from donotreply@truGeny.com.</p>
                            <p>Send an email to our customer service team - donotreply@truGeny.com and our team will help you address the problem.</p>
                          </div>
                        </div>
                      </div>
                      </Collapse>

                    </div>
                    <div className="card">
                      <div className="card-header" id="headingTwo">
                        <h5 className="acc-header collapse-color mb-0" style={{color:collapseNo==7?'#2C333C':'#4F5BE7'}}>
                        What is the travel advice if I am pregnant?
                        <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne">{collapseNo==7?<i onClick={()=>this.toggle(0)} className="fa fa-minus-circle  rounded-circle " />:<i onClick={()=>this.toggle(7)} className="fa fa-plus-circle  rounded-circle " />}</button>
                        </h5>
                      </div>
                      <Collapse isOpen={collapseNo==7}>
                      <div id="collapse8" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>As of April 2020, travel restrictions have been placed across countries and states around the world. The same restrictions apply to you.
                          <br/>
<br/>
If your due date for delivery is approaching, your doctor will advise you to the nearest maternity centre.
</p>
                        </div>
                      </div>
                      </Collapse>

                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        <h5 className="acc-header collapse-color mb-0" style={{color:collapseNo==8?'#2C333C':'#4F5BE7'}}>
                          Will my childbirth choices be affected by the coronavirus pandemic?
                          <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne">{collapseNo==8?<i onClick={()=>this.toggle(0)} className="fa fa-minus-circle  rounded-circle " />:<i onClick={()=>this.toggle(8)} className="fa fa-plus-circle  rounded-circle " />}</button>
                        </h5>
                      </div>
                      <Collapse isOpen={collapseNo==8}>
                      <div id="collapse7" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>Please select your error from the list below:</p>
                          <div className="container">
                            <p>
                            The availability of healthcare and support professionals will be somewhat limited during this pandemic and ensuing lockdown.
                            <br/>
<br/>
If you’re planning for a natural delivery assisted by a midwife, check with your doctor and make sure everyone involved in the delivery procedure is free from infection.
<br/>
<br/>
If you’re advised for or choose to have the delivery at a maternity centre, confirm the appointment and always ensure your doctor is informed of the same.
<br/>
<br/>
                            </p>
                          </div>
                        </div>
                      </div>
                      </Collapse>

                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        <h5 className="acc-header collapse-color mb-0" style={{color:collapseNo==9?'#2C333C':'#4F5BE7'}}>
                          Will I be able to have my birth partner with me during labour and birth?
                          <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne">{collapseNo==9?<i onClick={()=>this.toggle(0)} className="fa fa-minus-circle  rounded-circle " />:<i onClick={()=>this.toggle(9)} className="fa fa-plus-circle  rounded-circle " />}</button>
                        </h5>
                      </div>
                      <Collapse isOpen={collapseNo==9}>
                      <div id="collapse88" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>Definitely. Unless your partner shows any symptoms of coronavirus infection, there would be no problem in this regard.
</p>
                        </div>
                      </div>
                      </Collapse>

                    </div>
    {/*
                    <div classNmae="">
                    <h1 className="roboto-black-36px mt-lg-5" style={{color: '#4f5be7',alignText:"left"}}>Advice for pregnant women with suspected or confirmed coronavirus infection</h1>
                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q10?'#2C333C':'#4F5BE7'}}>
                        What should I do if I think I may have the coronavirus or been exposed?
                          {this.state.b10?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle10}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle10} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q10}>
                      <div id="collapse99" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>If you are pregnant and you have either -</p>
                          <ol>
                          <li>a high temperature</li>
                          <li>a new, continuous cough</li>

                          </ol>
                        </div>
                      </div>

                      </Collapse>

                    </div>
                    <div className="card">
                      <div className="card-header" id="headingOne">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q11?'#2C333C':'#4F5BE7'}}>
                          This barcode has already been used
                          {this.state.b11?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle11}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle11} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q11}>
                      <div id="collapse122" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>You or someone on your behalf might have already completed the activation process for this kit. Look through your email for
                            the activation confirmation message, or sign in to your truGeny account and look for the sample status timeline on the
                            homepage. If your kit is already activated, your sample status will reflect your kit's progress; if it doesn't show any status,
                            click the Start Activate Kit button to begin the process.</p>
                        </div>
                      </div>
                      </Collapse>

                    </div>
                    <div className="card">
                      <div className="card-header" id="headingTwo">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q12?'#2C333C':'#4F5BE7'}}>
                          The kit has expired
                          {this.state.b12?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle12}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle12} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q12}>
                      <div id="collapseOne3e" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>truGeny sample collection kits expire after 12 months from the date of purchase and will need to be replaced. You can contact
                            Customer Care to request a replacement. You need to ensure to include the kit barcode in your message.</p>
                        </div>
                      </div>
                      </Collapse>

                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q13?'#2C333C':'#4F5BE7'}}>
                          Site Error
                          {this.state.b13?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle13}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle13} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q13}>
                      <div id="collapseOne11" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>If you are receiving a site error message, please contact our <b><a href>Customer Care team.</a></b></p>
                        </div>
                      </div>
                      </Collapse>

                    </div>
                    */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* eo-heart-accordian-sec-wrap */}
          {/* 2nd qa */}
          <div className=" ">
            <h1 className="roboto-bold-18px" style={{color: '#4f5be7',fontSize:'24px'}}>Advice for pregnant women with suspected or confirmed coronavirus infection
</h1>
            <div className="text-center">
              <div className="card-block  p-2 circle">
                <div className="pr-5 pb-5 hc-accordian-wrap">
                  <div className="accordion" id="accordionIhc">
                    <div className="card">
                      <div className="card-header" id="headingTwo">
                        <h5 className="acc-header collapse-color mb-0" style={{color:collapseNo==10?'#2C333C':'#4F5BE7'}}>
                        What should I do if I think I may have the coronavirus or been exposed?

                        <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne">{collapseNo==10?<i onClick={()=>this.toggle(0)} className="fa fa-minus-circle  rounded-circle " />:<i onClick={()=>this.toggle(10)} className="fa fa-plus-circle  rounded-circle " />}</button>
                        </h5>
                      </div>
                      <Collapse isOpen={collapseNo==10}>
                      <div id="collapsey5" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>If you are pregnant and you have either -</p>
                          <ol>
                          <li>a high temperature</li>
                          <li>a new, continuous cough</li>
                          </ol>

                          <p>Stay at home for at least 7 days. Do not go to a GP surgery, pharmacy or hospital. There is no need for a coronavirus test unless you have any severe symptoms.
                            <br/><br/>
                            Contact your maternity unit to inform if you have symptoms suggestive of coronavirus, particularly if you have any routine appointments in the next 7 days.
                            <br/><br/>
                            Use the helpline number +91-11-23978046 or 1075 or email ncov2019@gov.in if
                            </p>
                            <ol>
                            <li>
                            you feel you cannot cope with your symptoms at home  </li>
                            <li>your condition gets worse</li>
                            <li>your symptoms do not get better after 7 days</li>
                            </ol>
                            <p>If you seek advice outside of anything already mentioned on https://www.mohfw.gov.in/
</p>

                        </div>
                      </div>
                      </Collapse>

                    </div>
                    <div className="card">
                      <div className="card-header" id="headingTwo">
                        <h5 className="acc-header collapse-color mb-0" style={{color:collapseNo==11?'#2C333C':'#4F5BE7'}}>
                        How will I be tested for coronavirus?
                        <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne">{collapseNo==11?<i onClick={()=>this.toggle(0)} className="fa fa-minus-circle  rounded-circle " />:<i onClick={()=>this.toggle(11)} className="fa fa-plus-circle  rounded-circle " />}</button>
                        </h5>
                      </div>
                      <Collapse isOpen={collapseNo==11}>
                      <div id="collapse66" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>Testing procedures are changing even as you’re reading this statement. The test will involve collecting a sample of your sputum or mucus. However details of the procedure will be available on ICMR or MoHFW websites.
</p>
                        </div>
                      </div>
                      </Collapse>

                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        <h5 className="acc-header collapse-color mb-0" style={{color:collapseNo==12?'#2C333C':'#4F5BE7'}}>
                        What should I do if I test positive for coronavirus?

                        <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne">{collapseNo==12?<i onClick={()=>this.toggle(0)} className="fa fa-minus-circle  rounded-circle " />:<i onClick={()=>this.toggle(12)} className="fa fa-plus-circle  rounded-circle " />}</button>
                        </h5>
                      </div>
                      <Collapse isOpen={collapseNo==12}>
                      <div id="collapseOne7" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>Inform your doctor, your midwife and/or antenatal team immediately. They will advise you on the next steps to be taken.</p>
                        </div>
                      </div>
                      </Collapse>

                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        <h5 className="acc-header collapse-color mb-0" style={{color:collapseNo==13?'#2C333C':'#4F5BE7'}}>
                        Why would I be asked to self-isolate (as opposed to reducing social physical contact)?
                        <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne">{collapseNo==13?<i onClick={()=>this.toggle(0)} className="fa fa-minus-circle  rounded-circle " />:<i onClick={()=>this.toggle(13)} className="fa fa-plus-circle  rounded-circle " />}</button>
                        </h5>
                      </div>
                      <Collapse isOpen={collapseNo==13}>
                      <div id="collapseOner" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>You may be advised to self-isolate because -</p>
                          <ol>
                          <li>You have symptoms of coronavirus, such as a high temperature or new, continuous cough</li>
                          <li>You have tested positive for coronavirus and you’ve been advised to recover at home</li>

                          </ol>
                        </div>
                      </div>
                      </Collapse>

                    </div>
                    <div className="card ">
                      <div className="card-header" id="headcp">
                        <h5 className="acc-header collapse-color mb-0" style={{color:collapseNo==14?'#2C333C':'#4F5BE7'}}>
                        What should I do if I’m asked to self-isolate?
                        <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne">{collapseNo==14?<i onClick={()=>this.toggle(0)} className="fa fa-minus-circle  rounded-circle " />:<i onClick={()=>this.toggle(14)} className="fa fa-plus-circle  rounded-circle " />}</button>
                        </h5>
                      </div>
                      <Collapse isOpen={collapseNo==14}>
                      <div id="cp" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p className="roboto-reg-18px">
                          Pregnant women who have been advised to self-isolate should stay indoors and avoid contact with others for 7 days. If you live with other people, they should stay at home for at least 14 days, to avoid spreading the infection outside the home.
<br/>
                          Self-isolation rules include</p>

                          <ol>
                          <li>Avoiding public places, including healthcare facilities</li>
                          <li>Not using the public transport</li>
                          <li>Staying at home and not allowing visitors</li>
                          <li>Allowing proper ventilation in rooms</li>
                          <li>Avoid contact with other members in the same house by changing routines</li>
                          <li>Get friends or family to run errands but avoid direct contact with them</li>
                          <li>Eat healthy and follow a good exercise regimen</li>

                          </ol>
                        </div>
                      </div>
                      </Collapse>

                    </div>
                  </div>
                </div>
                {/* eo card-block */}
              </div>
              {/* eo card body */}
            </div>
            {/* eo card */}
          </div>
          {/* eo-heart-accordian-sec-wrap */}
          {/* 3rd and 4th */}
          <div className="pt-0">
            <div className="text-center">
              <div className="card-block  p-2 pt-0 mt-0 circle">
                <div className="pr-5 pb-0 hc-accordian-wrap">
                  <div className="accordion" id="accordionIhc">
                    <div className="card">
                      <div className="card-header" id="headingOne">
                        <h5 className="acc-header collapse-color mb-0" style={{color:collapseNo==15?'#2C333C':'#4F5BE7'}}>
                        Can I still attend my antenatal appointments if I am in self-isolation?

                        <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne">{collapseNo==15?<i onClick={()=>this.toggle(0)} className="fa fa-minus-circle  rounded-circle " />:<i onClick={()=>this.toggle(15)} className="fa fa-plus-circle  rounded-circle " />}</button>
                        </h5>
                      </div>
                      <Collapse isOpen={collapseNo==15}>
                      <div id="collapseOnee" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>The best advice here can be given by your doctor. Though preferably you should complete your self-isolation period before going for any appointments.
</p>
                        </div>
                      </div>
                      </Collapse>

                    </div>
                    <div className="card">
                      <div className="card-header" id="headingTwo">
                        <h5 className="acc-header collapse-color mb-0" style={{color:collapseNo==16?'#2C333C':'#4F5BE7'}}>
                        How will my care be managed after I have recovered from coronavirus?

                        <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne">{collapseNo==16?<i onClick={()=>this.toggle(0)} className="fa fa-minus-circle  rounded-circle " />:<i onClick={()=>this.toggle(16)} className="fa fa-plus-circle  rounded-circle " />}</button>
                        </h5>
                      </div>
                      <Collapse isOpen={collapseNo==16}>
                      <div id="collapseOnek" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>If you have confirmed coronavirus infection, as a precautionary approach, an ultrasound scan will be arranged at least two weeks after your recovery, to check that your baby is well.
                          <br/>
                            <br/>
                          If you have recovered from coronavirus and tested negative for the virus before you go into labour, where and how you give birth will not be affected by your previous illness.
                          <br/>
                          <br/>

                          Visitor numbers may be restricted to promote recommended social distancing but your birth partner will be able to be with you, provided they are not unwell.
                          </p>
                        </div>
                      </div>
                      </Collapse>

                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        <h5 className="acc-header collapse-color mb-0" style={{color:collapseNo==17?'#2C333C':'#4F5BE7'}}>
                        What do I do if I feel unwell or I’m worried about my baby during self-isolation?

                        <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne">{collapseNo==17?<i onClick={()=>this.toggle(0)} className="fa fa-minus-circle  rounded-circle " />:<i onClick={()=>this.toggle(17)} className="fa fa-plus-circle  rounded-circle " />}</button>
                        </h5>
                      </div>
                      <Collapse isOpen={collapseNo==17}>
                      <div id="collapseOneh" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>The best advice for you would be to check with your doctor or midwife.
</p>
                        </div>
                      </div>
                      </Collapse>

                    </div>
                    {/* put here your code */}
                    <div className="card">
                      <div className="card-header" id="headingOne">
                        <h5 className="acc-header collapse-color mb-0" style={{color:collapseNo==18?'#2C333C':'#4F5BE7'}}>
                        Will being in self-isolation for suspected or confirmed coronavirus affect where I give birth?

                        <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne">{collapseNo==18?<i onClick={()=>this.toggle(0)} className="fa fa-minus-circle  rounded-circle " />:<i onClick={()=>this.toggle(18)} className="fa fa-plus-circle  rounded-circle " />}</button>
                        </h5>
                      </div>
                      <Collapse isOpen={collapseNo==18}>
                      <div id="collapseOne4-4" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionIhc">
                      <div className="card-body">
                        <p>As a precautionary approach, pregnant women with suspected or confirmed coronavirus when they go into labour, are being advised to go to the obstetric unit for birth, where the baby can be monitored using continuous electronic fetal monitoring, and your oxygen levels can be monitored hourly.
                        The continuous fetal monitoring is to check how your baby is coping with labour. nAs continuous fetal monitoring can only take place in an obstetric unit, where doctors and midwives are present, it is not currently recommended that you give birth at home or in a midwife led unit, where there would not be a doctor present and where this monitoring would not be possible.
<br /><br />
                        Again, check with your doctor for more details.
<br /><br />

                        </p>

                      </div>
                    </div>
                      </Collapse>

                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        <h5 className="acc-header collapse-color mb-0" style={{color:collapseNo==19?'#2C333C':'#4F5BE7'}}>
                        Will being in self-isolation for suspected or confirmed coronavirus affect how I give birth?

                        <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne">{collapseNo==19?<i onClick={()=>this.toggle(0)} className="fa fa-minus-circle  rounded-circle " />:<i onClick={()=>this.toggle(19)} className="fa fa-plus-circle  rounded-circle " />}</button>
                        </h5>
                      </div>
                      <Collapse isOpen={collapseNo==19}>
                      <div id="collapseOnen" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>There is currently no evidence to suggest you cannot give birth vaginally or that you would be safer having a caesarean birth if you have suspected or confirmed coronavirus, so your birth choices should be respected and followed as closely as possible based on your wishes.
                          <br /><br />

However, if your breathing (respiratory condition) suggests that your baby needs to be born urgently, a caesarean birth may be recommended.
<br /><br />

It is not recommended that you give birth in a birthing pool in hospital if you have suspected or confirmed coronavirus, as the virus can sometimes be found in faeces. This means it could contaminate the water, causing infection to pass to the baby. It may also be more difficult for healthcare staff to use adequate protection equipment during a water birth.
<br /><br />

There is no evidence that women with suspected or confirmed coronavirus cannot have an epidural or a spinal block.
</p>

                        </div>
                      </div>
                      </Collapse>
                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        <h5 className="acc-header collapse-color mb-0" style={{color:collapseNo==20?'#2C333C':'#4F5BE7'}}>
                        What happens if I go into labour during my self-isolation period?

                        <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne">{collapseNo==20?<i onClick={()=>this.toggle(0)} className="fa fa-minus-circle  rounded-circle " />:<i onClick={()=>this.toggle(20)} className="fa fa-plus-circle  rounded-circle " />}</button>
                        </h5>
                      </div>
                      <Collapse isOpen={collapseNo==20}>
                      <div id="collapseOnet" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>Call your doctor or maternity unit for the best assistance immediately.
</p>
                        </div>
                      </div>
                      </Collapse>
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingOne">
                        <h5 className="acc-header collapse-color mb-0" style={{color:collapseNo==21?'#2C333C':'#4F5BE7'}}>
                        Could I pass coronavirus to my baby?

                        <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne">{collapseNo==21?<i onClick={()=>this.toggle(0)} className="fa fa-minus-circle  rounded-circle " />:<i onClick={()=>this.toggle(21)} className="fa fa-plus-circle  rounded-circle " />}</button>
                        </h5>
                      </div>
                      <Collapse isOpen={collapseNo==21}>
                      <div id="collapseOne-666" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p className="roboto-reg-18px">
                          Conclusive evidence regarding vertical transmission of coronavirus has still not been found. As research is still going on about this new virus, information is still limited.
                            <br/>
                            <br/>

                          As new facts come to the fore, we’ll update this section.

                        </p>







                        </div>
                      </div>
                      </Collapse>
                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree-0">
                        <h5 className="acc-header collapse-color mb-0" style={{color:collapseNo==22?'#2C333C':'#4F5BE7'}}>
                        Will my baby be tested for coronavirus?

                        <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne">{collapseNo==22?<i onClick={()=>this.toggle(0)} className="fa fa-minus-circle  rounded-circle " />:<i onClick={()=>this.toggle(22)} className="fa fa-plus-circle  rounded-circle " />}</button>
                        </h5>
                      </div>
                      <Collapse isOpen={collapseNo==22}>
                      <div id="collapseOne-0" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p className="roboto-reg-18px">If you have confirmed or suspected coronavirus when the baby is born, doctors who specialise in the care of newborn babies (neonatal doctors) will examine your baby and advise you about the care, including whether testing is needed.

</p>
                        </div>
                      </div>
                      </Collapse>
                    </div>
                    {/* end of accordian code */}
                  </div>
                </div>
                {/* eo card-block */}
              </div>
              {/* eo card body */}
            </div>
            {/* eo card */}
          </div>
          {/* eo-heart-accordian-sec-wrap */}
          {/* 2nd qa */}
          <div className="  ">
            <div className="text-center">
              <div className="card-block  p-2 circle">
                <div className="pr-5 pb-5  hc-accordian-wrap">
                  <div className="accordion" id="accordionIhc">
                    <div className="card">
                      <div className="card-header" id="headingOne">
                        <h5 className="acc-header collapse-color mb-0" style={{color:collapseNo==23?'#2C333C':'#4F5BE7'}} >
                          Will I be able to stay with my baby/give skin-to-skin if I have suspected or confirmed coronavirus?
                          <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne">{collapseNo==23?<i onClick={()=>this.toggle(0)} className="fa fa-minus-circle  rounded-circle " />:<i onClick={()=>this.toggle(23)} className="fa fa-plus-circle  rounded-circle " />}</button>
                          </h5>
                      </div>
                      <Collapse isOpen={collapseNo==23}>
                      <div id="collapseOne87" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>If you are in your self-isolation period, your maternity unit will be the one who can give the best advice regarding this.</p>
                        </div>
                      </div>
                      </Collapse>

                    </div>
                    <div className="card">
                      <div className="card-header" id="headingTwo">
                        <h5 className="acc-header collapse-color mb-0" style={{color:collapseNo==24?'#2C333C':'#4F5BE7'}}>
                        Will I be able to breastfeed my baby if I have suspected or confirmed coronavirus?
                        <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne">{collapseNo==24?<i onClick={()=>this.toggle(0)} className="fa fa-minus-circle  rounded-circle " />:<i onClick={()=>this.toggle(24)} className="fa fa-plus-circle  rounded-circle " />}</button>
                          </h5>
                      </div>
                      <Collapse isOpen={collapseNo==24}>
                      <div id="collapseOnete" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>As of yet, there is no conclusive evidence showing that the virus can be carried in breastmilk. The benefits of breastfeeding outweigh any potential risks of transmission of coronavirus through breastmilk.
<br/>
<br/>

The main risk of breastfeeding is close contact between you and your baby, as if you cough or sneeze, this could contain droplets which are infected with the virus, leading to infection of the baby after birth.
<br/>
<br/>

Discuss with your doctor and maternity unit for more details about this.
</p>
                        </div>
                      </div>
                      </Collapse>

                    </div>
                    <div className="d-flex justify-content-start">
                    <h1 className="roboto-bold-18px mt-5" style={{color: '#4f5be7',fontSize:'24px',alignText:'left'}}>
                    Occupational health guidance for pregnant women who work in a public-facing role
                    </h1></div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        <h5 className="acc-header collapse-color mb-0" style={{color:collapseNo==25?'#2C333C':'#4F5BE7'}}>
                        Can I still go to work? What if I work in a public-facing role?

                        <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne">{collapseNo==25?<i onClick={()=>this.toggle(0)} className="fa fa-minus-circle  rounded-circle " />:<i onClick={()=>this.toggle(25)} className="fa fa-plus-circle  rounded-circle " />}</button>
                        </h5>
                      </div>
                      <Collapse isOpen={collapseNo==25}>
                      <div id="collapseOneme" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>tThe best way is to opt for working from home.
                          <br/>
<br/>
If that is not possible and you’re less than 28 weeks pregnant, with no underlying health conditions, you should practise social physical distancing but can choose to continue to work in a public-facing role, while using personal protective equipment (PPE) and undergoing regular risk assessment.
<br/>
<br/>

If you are in your third trimester (more than 28 weeks pregnant), or have an underlying health condition – such as heart or lung disease - you should work from home where possible, avoid contact with anyone with symptoms of coronavirus, and significantly reduce unnecessary social contact. Follow the directives from MoHFW.
</p>
                        </div>
                      </div>
                      </Collapse>

                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        <h5 className="acc-header collapse-color mb-0" style={{color:collapseNo==26?'#2C333C':'#4F5BE7'}}>
                        What is the advice if I am a healthcare worker and pregnant?

                        <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne">{collapseNo==26?<i onClick={()=>this.toggle(0)} className="fa fa-minus-circle  rounded-circle " />:<i onClick={()=>this.toggle(26)} className="fa fa-plus-circle  rounded-circle " />}</button>
                        </h5>
                      </div>
                      <Collapse isOpen={collapseNo==26}>
                      <div id="collapseOnemmn" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                      <div className="card-body">
                        <p>Similar guidelines apply to you as with any other pregnant woman who works in a public-facing role. It is better to check with your organisation regarding detailed information about your work.</p>



                      </div>
                    </div>
                      </Collapse>

                    </div>
                    <div className="card d-none">
                      <div className="card-header" id="headingThree">
                        <h5 className="acc-header collapse-color mb-0" style={{color:collapseNo==27?'#2C333C':'#4F5BE7'}}>
                          How can i keep track of the status of my sample?
                          <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne">{collapseNo==27?<i onClick={()=>this.toggle(0)} className="fa fa-minus-circle  rounded-circle " />:<i onClick={()=>this.toggle(27)} className="fa fa-plus-circle  rounded-circle " />}</button>
                          </h5>
                      </div>
                      <Collapse isOpen={collapseNo==27}>
                      <div id="collapseOnemm" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>You will receive regular update notifications via email for each step of the sample processing. For instance, we update you once
                            the kit has been dispatched to you, when you've successfully activated the kit, when your sample reaches our lab, and an update
                            once your reports are ready.</p>
                        </div>
                      </div>
                      </Collapse>

                    </div>
                  </div>
                </div>
                {/* eo card-block */}
              </div>
              {/* eo card body */}
            </div>
            {/* eo card */}
          </div>
          {/* eo-heart-accordian-sec-wrap */}
        </section>
        {/* eo-heart-accordian-sec */}
        {/* **********Schedule your Genetic Counseling Session Ends Here************** */}
        {/* Footer Links */}
       <Footer></Footer>
        {/* end of footer */}
        {/* --------eo sticky form----------------- */}
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
        <MobileFooter></MobileFooter>
      </div>


       )
   }

}
export default AllHelpCovid;
