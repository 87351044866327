import React from 'react';
import { getJwt } from './jwt';
import {withRouter} from 'react-router-dom';
import { getUser } from './UserType';


class AuthenticatedComponent extends React.Component{

   
    componentDidMount(){
       const jwt=getJwt();
       const user=getUser();
      
        if(user!=="CUSTOMER"&&user!=="CLINICIANS") {
          
            this.props.history.push("/product-page-index")
        } 
     
    } 
    render(){    
        return(
            <div>{this.props.children}</div>
        )       
    }
}
export default withRouter(AuthenticatedComponent);