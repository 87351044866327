import React from "react";
import *as PayContants from './paymentContants';


class StickyContactMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ContactMenu: false
    };
    this.toggleContactMenu = this.toggleContactMenu.bind(this);
  }
  toggleContactMenu() {
  

    this.setState({ ContactMenu: !this.state.ContactMenu }, () => {});
  }




  render() {
    let contactstyles;
    if (this.state.ContactMenu) {
      contactstyles = {
        right: "-0px"
      };
    } else {
      contactstyles = {
        right: "-342px",
        
      };
    }
    const { right } = contactstyles;

    return (
      <div id="slider" style={{ right }}>
        <div id="sidebar" onClick={this.toggleContactMenu}>
          <p>
            <i
              title="support@trugeny.com"
              className="py-2 fa fa-envelope "
              style={{ color: "white" }}
            />
          </p>
          <p>
            <i
              title="1800 1234567"
              className="pb-2 fa fa-phone "
              style={{ color: "white" }}
            />
          </p>
        </div>
        <div id="header">
          <h3 className="text-center">Contact us</h3>
          <div className="signin-register">
            <ul>
              <li>
                <a
                  className="text-lowercase"
                  style={{ fontSize: "18px!important" }}
                  href={PayContants.truGenyMailhref}
                >
                  <i className="fas fa-envelope-open" /> {PayContants.truGenyMail}
                </a>
              </li>
              <li>
                <a
                  className="text-lowercase"
                  style={{ fontSize: "18px!important" }}
                  id="register"
                  href={PayContants.truGenyHrefConatct}
                >
                  <i className="fas fa-phone-volume" />
                  {PayContants.truGenyConatct}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default StickyContactMenu;
