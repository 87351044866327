import React from "react";
import CommonHeder from "./CommonHeder";
import Cookies from "js-cookie";
import axios from "../axios/axios";
import SubFooter from "./SubFooter";
import Butter from "buttercms";
import MobileFooter from "./MobileFooter";
import LoginSucessCarouselCards from "./LoginSuccessCarouselCards";
;
const butter = Butter("7b59889f30ce2538dd5163bf66b73ee9c8e11b74");

class LoginSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scopes:[],
      doctorId: "",
      appointmentDate: "",
      appointmentTime: "",
      counsellorfirstName: "",
      counsellorLastName: "",
      lastorderHistory: []
    };

    this.viewReports = this.viewReports.bind(this);
    this.orderHistory = this.orderHistory.bind(this);

  }
  componentDidMount(){
   
    
    if(Cookies.get("scopes")){
      this.setState({
        scopes:Cookies.get("scopes")
        
      });
   
        }
    
    this.orderHistory();
   
    
  }


 

  orderHistory() {
    axios
      .get("cartservice/" + Cookies.get("userId") + "/orderHistoryForProduct", {
        headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` }
      })
      .then(response => {
      
        if (response.data) {
        
          this.setState({ lastorderHistory: response.data }, () => {});
        }
      })
      .catch(error => {});
  }

  toBookSession() {
    window.location.href = "/book-counselling";
  }
  toProductPage() {
    window.location.href = "/product-page";
  }
  toActiveKit() {
    window.location.href = "/activate-kit";
  }

  // componentDidMount(){
  //   butter.page.retrieve('truGeny', 'health_care_app').then(function(resp) {
  //     console.log(resp)
  //    });

  //   axios.get('schedule/getAppointmentDetails/'+Cookies.get("userId"),
  // { headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` } }
  // )
  //     .then((response) => {

  //       this.setState({
  //         doctorId : response.data.doctorId,
  //         appointmentDate : response.data.appointmentDate,
  //         appointmentTime : response.data.startTime
  //       })
  //       this.getCounsellorDetails();
  //     });
  // }

  // getCounsellorDetails(){
  //   axios.get('users/user/'+this.state.doctorId,
  //   { headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` } }
  //   )
  //       .then((response) => {
  //   this.setState(
  //       {
  //           counsellorfirstName:response.data.firstName,
  //           counsellorLastName:response.data.lastName
  //       }
  //   )

  //       });
  // }

  viewReports() {
    this.props.history.push("/view-reports");
  }

  render() {
    let styles;
    
    if(!this.state.lastorderHistory.orderId){
      styles = {
        sideBar1: {
          marginLeft: '180px',
        }};
    }else{
      styles = {
        sideBar1: {
          marginLeft: '0px',
        } };

    }
    const { sideBar1 } = styles;

    if(this.state.scopes.indexOf("CUSTOMER") > -1){
      localStorage.setItem("scope","CUSTOMER")
    }


    let activekit;
   if(this.state.lastorderHistory.orderId) { activekit=<div className=" col-sm-12 col-md-12 col-lg-4">
    <div className="card  rounded shadow-lg  mb-5  r4 innerCardLogs">
      <div className="card-body text-center pb-5">
        <div className="card-block mt-2 p-2 circle ">
          <img
            className="card-img-top text-center  p-2"
            src="./img/login/3.png"
            style={{ height: "160px", width: "160px" }}
            alt="Card image cap"
          />
        </div>
        <h3 className="font-card roboto-reg-18px pt-4 pb-1">
          Activating your kit will only take a few
          minutes. You will need the barcode found on your
          saliva tube.
        </h3>
        {/* <h3 class="font-card">Activating your kit will only take a few minutes. You will need the barcode found on your saliva tube.</h3> */}
        {/* <button  style="height:40px;" class="mt-3 mb-2 rounded-pill  btn btn-outline-primary btn-hover-white roboto-bold-14px" onclick="window.location.href = 'view-reports.html';" type="submit" >ACTIVE KIT</button> */}
        <button
          style={{
            height: "40px",
            color: "white",
            background: "#4f5be7"
          }}
          className="mx-auto px-lg-5 px-sm-0 rounded-pill btn btn-outline-primary btn-hover-white my-2 my-sm-0 mb-1 p-2"
          onClick={this.toActiveKit}
          type="submit"
        >
          <b>ACTIVATE KIT</b>
        </button>
        {/* <a href="#"><img class="card-img-top text-center  p-2" src="./img/login/card-b3.png"/></a> */}
      </div>
    </div>
  </div>;}

    return (
      <div>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <link
          rel="stylesheet"
          href="https://www.w3schools.com/w3css/4/w3.css"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i"
          rel="stylesheet"
        />
        {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossorigin="anonymous"> */}
        <link
          rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
          integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
          crossOrigin="anonymous"
        />
        <title>TrueGene</title>
        <link
          rel="stylesheet"
          href="./fonts/fontawesome-free-5.8.2-web/css/all.css"
          type="text/css"
        />
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <style
          dangerouslySetInnerHTML={{
            __html:
              "\n.background-login-card {\n  background: url(./img/registration/3-layers.png) no-repeat center;\n  -webkit-background-size: cover;\n  -moz-background-size: cover;\n  -o-background-size: cover;\n  background-size: cover;\n  width: 100%;\n  /* height: 593px; */\n  /* background-position: top; */\n}\n.footerme {\n  position: fixed;\n  left: 0;\n  bottom: 0;\n  width: 100%;\n  background-color: white;\n  color: black;\n  text-align: center;\n}\nbody {\n  font-family: 'Roboto', sans-serif;\n  color: #2c333c;\n}\n\n.header-font {\n  color: #2c333c;\n  font-family: Roboto - Black;\n  font-size: 36px;\n  font-weight: 400;\n}\n\n.font-card {\n  color: #2c333c;\n  font-family: Roboto;\n  font-size: 18px;\n  font-weight: 400;\n}\n\n.text-highlight {\n  color: #2c333c;\n  font-family: Roboto;\n  font-size: 24px;\n  font-weight: 700;\n}\n\n.blue-text {\n  margin-top: 100px;\n  color: #4f5be7;\n  font-family: \"Roboto - Black\";\n  font-size: 30px;\n  font-weight: 400;\n}\n\n.para {\n  color: #2c333c;\n  font-family: Roboto;\n  font-size: 16px;\n  font-weight: 400;\n  margin-top: 24px;\n}\n\n.space-tp {\n  margin-top: 31px;\n  margin-bottom: 101px;\n}\n\n.card-round {\n  box-shadow: 0 17px 40px rgba(92, 103, 108, 0.2);\n  border-radius: 40px;\n  background-color: #ffffff;\n}\n\n.c2 {\n  border-top-left-radius: 5rem;\n  border-top-right-radius: 2rem;\n  box-shadow: 0 17px 40px rgba(92, 103, 108, 0.2);\n}\n\n"
          }}
        />
        <link rel="stylesheet" href="./styleinline.css" />
        <link rel="stylesheet" href="./style-mobile2.css" />
        <link rel="stylesheet" href="style-mobile.css" />
        {/* ----------------new page-------------------- */}
        {/* header */}
        <CommonHeder category="loginSuccess"></CommonHeder>
        {/* end of header */}
        <div style={{ paddingBottom: "76px" }} className="padding2" />

        <div style={{ paddingBottom: "16rem" }} className="padding"></div>

        <section className="id-1">
          <div className="  p-0 container ">
            <div className="card rs-main-card text-center rounded border-0 shadow-lg r2 r3">
              <div className="rounded">
                <div
                  className="card-header p-4 clearfix border-0 bg-white "
                  style={{
                    borderTopRightRadius: "2rem",
                    borderTopLeftRadius: "2rem"
                  }}
                >
                  <div className="rs-header d-flex flex-column justify-content-start ">
                    <h1 className="text-center  pt-5 roboto-black-36px">
                      Welcome, {Cookies.get("username")}!
                    </h1>
                    <span className="text-center roboto-reg-16px pb-4 pt-1">
                      <p style={{ color: "#2c333c" }}>
                        You don’t have any active tests or results to view. Here
                        are some things you can do:
                      </p>
                    </span>
                  </div>
                </div>

                <div className="card-body p-0 container-fluid ">
                  <div
                    className="background-login-card p-r-5 pl-5 px-4 py-5 container "
                    style={{
                      borderBottomRightRadius: "2rem",
                      borderBottomLeftRadius: "2rem"
                    }}
                  >
                    <div className="container mt-5  mb-5" >
                      <div className="card-deck" id="hide">
                        <div className=" col-sm-12 col-md-12 col-lg-4 " style={sideBar1}>
                          <div className="card  rounded shadow-lg  mb-5  r4 innerCardLogs">
                            <div className="card-body text-center pb-5">
                              <div className="card-block mt-2 p-2 circle ">
                                <img
                                  className="card-img-top text-center  p-2"
                                  src="./img/login/1.png"
                                  style={{ height: "160px", width: "160px" }}
                                  alt="Card image cap"
                                />
                              </div>
                              <h3 className="font-card roboto-reg-18px py-4">
                                Schedule your appointment with our genetic
                                experts to start your genetic journey.
                              </h3>
                              {/* <h3 class="font-card">Activating your kit will only take a few minutes. You will need the barcode found on your saliva tube.</h3> */}
                              {/* <button  style="height:40px;" class="mt-3 mb-2 rounded-pill  btn btn-outline-primary btn-hover-white roboto-bold-14px" onclick="window.location.href = 'view-reports.html';" type="submit" >ACTIVE KIT</button> */}
                              <button
                                style={{
                                  height: "40px",
                                  color: "white",
                                  background: "#4f5be7"
                                }}
                                className="mx-auto px-lg-5 px-sm-0 rounded-pill btn btn-outline-primary btn-hover-white my-2 my-sm-0 mb-1 p-2"
                                onClick={this.toBookSession}
                                type="submit"
                              >
                                <b>COUNSELLING</b>
                              </button>
                              {/* <a href="#"><img class="card-img-top text-center  p-2" src="./img/login/card-b3.png"/></a> */}
                            </div>
                          </div>
                        </div>
                        {/* eo-card-deck-wrap */}
                        <div className=" col-sm-12 col-md-12 col-lg-4 ">
                          <div className="card  rounded shadow-lg  mb-5  r4 innerCardLogs">
                            <div className="card-body text-center pb-5">
                              <div className="card-block mt-2 p-2 circle ">
                                <img
                                  className="card-img-top text-center  p-2"
                                  src="./img/login/2.png"
                                  style={{ height: "160px", width: "160px" }}
                                  alt="Card image cap"
                                />
                              </div>
                              <h3 className="font-card roboto-reg-18px py-4">
                                Understand your genes and evaluate your risks
                                for cancer and cardiac conditions.
                              </h3>
                              {/* <h3 class="font-card">Activating your kit will only take a few minutes. You will need the barcode found on your saliva tube.</h3> */}
                              {/* <button  style="height:40px;" class="mt-3 mb-2 rounded-pill  btn btn-outline-primary btn-hover-white roboto-bold-14px" onclick="window.location.href = 'view-reports.html';" type="submit" >ACTIVE KIT</button> */}
                              <button
                                style={{
                                  height: "40px",
                                  color: "white",
                                  background: "#4f5be7"
                                }}
                                className="mx-auto px-lg-5 px-sm-0 rounded-pill btn btn-outline-primary btn-hover-white my-2 my-sm-0 mb-1 p-2"
                                onClick={this.toProductPage}
                              >
                                <b>BUY truGeny</b>
                              </button>
                              {/* <a href="#"><img class="card-img-top text-center  p-2" src="./img/login/card-b3.png"/></a> */}
                            </div>
                          </div>
                        </div>
                        {/* eo-card-deck-wrap */}
                      {
activekit
                      }
                        {/* eo-card-deck-wrap */}
                      </div>
                      {/* eo-card-deck */}
                      <LoginSucessCarouselCards></LoginSucessCarouselCards>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <SubFooter></SubFooter>
        {/* eo Modal */}
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
        <MobileFooter></MobileFooter>
      </div>
    );
  }
}
export default LoginSuccess;
