import React from 'react';
import Cookies from 'js-cookie';
import NewMainHeader from './NewMainHeader';
import SubMainFooter from './SubMainFooter';
import CommonHeder from './CommonHeder';
import MobileFooter from './MobileFooter';



class ThankYou extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
      menu: false,
      prevScrollpos: window.pageYOffset,
      visible: false,
      desktop:false,
      mobilemenu:false
           
        }
        this.toggleMenu = this.toggleMenu.bind(this);

        this.resize = this.resize.bind(this);
        this.toggleMobileMenu =this.toggleMobileMenu.bind(this);


    }



    componentDidMount(){
        Cookies.remove('username');
        Cookies.remove('jwt-token');
        Cookies.remove('userId');
        Cookies.remove('scope');
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    handleScroll = () => {
      const { prevScrollpos } = this.state;
  
      const currentScrollPos = window.pageYOffset;
  
      const visible = prevScrollpos > 50;
  
      this.setState({
        prevScrollpos: currentScrollPos,
        visible
      },()=>{});
    };
    resize() {
      const desktop= window.innerWidth>=576;
      this.setState({desktop},()=>{});
   }


    componentWillUnmount() {
      window.removeEventListener("scroll", this.handleScroll);
     
    };




 
    toggleMenu() {
      this.setState({ menu: !this.state.menu },()=>{})
    }
    toggleMobileMenu() {
      this.setState({ mobilemenu: !this.state.mobilemenu },()=>{})
    }
  
  
  
   
  
  
  render() {
    const show = (this.state.menu) ? "show" : "";

    const showheader = this.props.category
    
    
    
    let styles;
    if(this.state.mobilemenu){
      styles = {
        sideBar1: {
          width: '250px',
        },
        bgwhit:{
          'margin-left': '250px'
        }
  
  
      };
    }else{
      styles = {
        sideBar1: {
          width: '0px',
        },
        bgwhit:{
          'margin-left': '0px'
        }
  
  
      };

    }
 
    const { sideBar1,bgwhit } = styles;
  
    return (
      
        <div>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        {/* <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css"> */}
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese" rel="stylesheet" />
        <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
        {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossorigin="anonymous"> */}
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>truGeny</title>
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>truGeny</title>
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/header_footer.css" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <link rel="stylesheet" href="./css/sticky.css" />
        <link rel="stylesheet" href="./css/payment.css" />
        <style dangerouslySetInnerHTML={{__html: "\n  /* form focus styles*/\n\n  .form-control:focus {\n    border-color: #ebebeb;\n    box-shadow: inset\n    0 0px 0px rgba(0, 0, 0, .01),\n    0 0 0px rgba(0, 0, 0, .01),\n    0 0 30px rgba(141, 141, 141, 0.5),\n    0 0 0px rgba(0, 0, 0, .01);\n  }\n\n  /*End of  form focus styles*/\n  " }} />
        {/* --------------------------------------------------------------------------------------------head section end-------------------------------------------------------------------------------------------- */}
        <link rel="stylesheet" href="./styleinline.css" />
        <link rel="stylesheet" href="./style-mobile2.css" />
        <link rel="stylesheet" href="style-mobile.css" />
       
        <style dangerouslySetInnerHTML={{__html: "\n.background-pay-card{\n  background: url(./img/risk-page/tq/background.png) no-repeat center;-webkit-background-size: cover;\n  -moz-background-size: cover;\n  -o-background-size: cover;\n  background-size: cover;\n  width:auto;\n  height:630px;\n  margin:auto;\n  border-radius: 2.5rem;\n}\n\n" }} />

       
       
        
        
        <div id="mySidebar" className="sidebar1" style={sideBar1}>
        {Cookies.get("username")?
          <div className="signin-register">
        <ul>
          <li><a style={{ color:  'green' , fontWeight:  'bold'  }} className="signinRegisterBtn" id="signin" href="/loginSuccess">{Cookies.get("username")}</a></li>
        </ul>
      </div>
             : <div className="signin-register">
                <ul>
                  <li><a style={{ color: showheader === "signin" ? '#4f5be7' : '#2c333c', fontWeight: showheader === "signin" ? 'bold' : '' }} className="signinRegisterBtn" id="signin" href="/login" >Sign in</a> <span>|</span> <a className="signinRegisterBtn" id="register" style={{ color: showheader === "register" ? '#4f5be7' : '#2c333c', fontWeight: showheader === "register" ? 'bold' : '' }} href="/register">Register</a></li><hr />
                </ul>
              </div>
        }
              <a  className="closebtn" onClick={this.toggleMobileMenu}>×</a>
             
            
            </div>
           
            
        <div id="main" className="bg-white" style={bgwhit}>
        <div className="row">
          <div className="col-6">
            <div className="d-flex  justify-content-start">
              <a className="navbar-brand  " href="/"><img id="img-1" className="img-size-custom" src="./img/Logo.png" /></a>
            </div>
          </div>
          <div className="col-6">
            <div className="d-flex justify-content-end">
              <button className="openbtn" onClick={this.toggleMobileMenu}>☰ </button>
              {/* <h2>Collapsed Sidebar</h2> */}
            </div>
          </div>
        </div>
      
      </div>
      <header id="headbar" className={(this.state.visible && this.state.desktop) ? "fixed-top bg-white" : ""}>
      <nav id="nav-a-tag" className="main-nav pb-0 navbar navbar-expand-lg nav-menu container">
        <a className="navbar-brand  " href="/"><img id="img-1" className="img-size-custom p-0" src="./img/Logo.png" /></a>
        <button className={show ? "navbar-toggler nav-button change" : "navbar-toggler nav-button collapsed"} type="button" data-toggle="collapse" data-target="#myNavbar" aria-expanded="false" onClick={this.toggleMenu}>
          <div className=" line1" />
          <div className=" line2" />
          <div className=" line3" />
        </button>
        <div className={show ? "main-navbar-collapse navbar-collapse text-center justify-content-lg-end text-uppercase collapse show" : "main-navbar-collapse collapse navbar-collapse text-center justify-content-lg-end  text-uppercase"} id="myNavbar" style={{}}>
         
         <div className="signin-register">
          <ul>
            <li><a style={{ color: showheader === "signin" ? '#4f5be7' : '#2c333c', fontWeight: showheader === "signin" ? 'bold' : '' }} className="signinRegisterBtn" id="signin" href="/login">Sign in</a> <span>|</span> <a className="signinRegisterBtn" id="register" style={{ color: showheader === "register" ? '#4f5be7' : '#2c333c', fontWeight: showheader === "register" ? 'bold' : '' }} href="/register">Register</a></li>
          </ul>
        </div>
                
        
      
        </div>
      </nav>
      <div>
   
     
      </div>
    </header>

        
             {this.state.mobilemenu?"": <div style={{marginTop: '8rem'}}>
             </div>

             }
     
     <div className="container img-flud background-pay-card ">
          <div className="text-center" style={{paddingTop: '300px'}}>
            <h1 className="roboto-black-48px"> Thank you!</h1>
          </div>
        </div>
        <div style={{marginTop: '6rem'}}>
        </div>
        <div style={{paddingTop: '12.25rem'}}>
        </div>
        <SubMainFooter></SubMainFooter>
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
        <MobileFooter></MobileFooter>
      </div>

    );
  }
}
export default ThankYou;