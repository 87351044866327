import React from "react";
import Cookies from "js-cookie";
import CommonHeder from "./CommonHeder";
import axios from "../axios/axios";
import { Form } from "reactstrap";
import Currency from "react-currency-formatter";
import swal from "sweetalert";
import MobileFooter from "./MobileFooter";
import Badge from "react-bootstrap/Badge";
import moment from "moment";
import * as PayContants from './paymentContants';
import SubFooter from './SubFooter';

//API's

const GET_PRODUCT_DETAILS_BY_PRODUCT_NAME = 'productservice/productDetailsName/';
const GET_OFFER_DATES_OF_ORDER_TEST = 'offerDates/getOfferByTest';
const GET_USER_DATA_BY_ID = '/users/user/';
const GET_LAST_ORDER_HISTORY_BY_ID =  'cartservice/' +Cookies.get("userId") +'/lastOrderHistory';

class OrderTrugene extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      //offers logis start
      orderedProduct:'',
      offerDatesData:[],
      offerStartDate:'',
      offerEndDate:'',
      offerName:'',
      offerForProduct:'',
      offerPrice:'',

      //offer logic end

      promoDiscount:0,
      lastorderHistory: [],
      productDetails: [],
      nameError: false,
      phoneError: false,
      emailError: false,
      ageError: false,
      nameError2: false,
      phoneError2: false,
      emailError2: false,
      dropdownError: false,
      againPromocodes: [],
      promoRupeesPlus: 0,
      menu: false,
      dropdownOpen: false,
      isActive: 1,
      values: [],
      mode: "Your tests",
      orderId: null,
      razerpayOrderId: "",
      data: [],
      productId: "",
      productName: "",
      unitPrice: "",
      currency: "",
      productDescription: "",
      gstPercentage: "",
      updatedData: [],
      clicks: 1,
      show: true,
      buttonDisable: false,

      promoCodeData: [],
      promocode: "",
      invalidPromocode: null,
      validPromoCode: false,
      nonStackble: false,
      netAmount: "",
      totalAmount: "",
      gstAmount: "",
      shippingAmount: 1,

      user: "",
      address1: "",
      address2: "",
      city: "",
      pincode: "",
      mobile: "",
      email: "",
      doctor: "",
      gender: "",
      userData: [],

      errorMsg: null,
      errors: {},
      guid: "",
      cartId: null,
      isStackble: false,
      promoCodeDataNew: [],
      truHeartTest: {},
      formList: [],
      butdis: 0,
      futureOrder: false,
      orderHistory:[],
      sessionType:"",
      preSessionReport:"",
      preOrderAServiceId:"",
    };
    this.baseState =this.state;
    this.orderHistory = this.orderHistory.bind(this);
    this.getAddress = this.getAddress.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.submitShipping = this.submitShipping.bind(this);
    this.changePromoHandler = this.changePromoHandler.bind(this);
    this.changeTrugeneHandler = this.changeTrugeneHandler.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.formHandleChange = this.formHandleChange.bind(this);
    this.addform = this.addform.bind(this);
    this.removeform = this.removeform.bind(this);
    this.handleChangeMultiple = this.handleChangeMultiple.bind(this);
    this.handleChangeAddData = this.handleChangeAddData.bind(this);
    this.toggle = this.toggle.bind(this);
    this.placeOrder = this.placeOrder.bind(this);
    this.orderTestHistory = this.orderTestHistory.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.updateSubmitShipping = this.updateSubmitShipping.bind(this);
    this.updatePlaceOrder = this.updatePlaceOrder.bind(this);
    // this.cancelUntrackedOrders = this.cancelUntrackedOrders.bind(this);

  }
  componentWillMount() {
    if(this.props.location.state!==undefined){
        const {orderedProduct}=this.props.location.state
        this.setState({orderedProduct:orderedProduct},function(){})
        
        axios.get(GET_PRODUCT_DETAILS_BY_PRODUCT_NAME+orderedProduct, {
          headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` },
          Accept: "application/json",
          "Content-Type": "application/json"
        })
        .then(response => {
          this.setState({
            data: response.data,
            unitPrice: response.data.unitPrice,
            productId: response.data.productId
          },()=>{});
        })
        .catch(function(error) {});
     }
     this.orderTestHistory();
     this.orderHistory();
     this.getAddress();

    //offer logic
    axios.get(GET_OFFER_DATES_OF_ORDER_TEST)
    .then((response) => {
        this.setState({
          offerDates: response.data,
          offerStartDate: response.data.offerStartDate,
          offerEndDate: response.data.offerEndDate,
          offerName:response.data.offerName,
          offerForProduct:response.data.offerForProduct
        },function(){})

        var listDate = [];
        var startDate = response.data.offerStartDate;
        var endDate = response.data.offerEndDate;
        var dateMove = new Date(startDate);
        var strDate = startDate;
        
        while (strDate < endDate){
          var strDate = dateMove.toISOString().slice(0,10);
          listDate.push(strDate);
          dateMove.setDate(dateMove.getDate()+1);
        };


this.setState({
         offerDatesData:listDate
        },function(){})
      },

      
      
      () => {}
    ).catch(function(error) {});


    this.setState({offerPrice:''})

    if(this.state.offerDatesData.find(x=>x===new Date().format("YYYY-MM-DD"))&&this.state.orderedProduct===this.state.offerForProduct){

      this.setState({offerPrice:this.state.offerDates.offerPrice},function(){})

    }else{
      this.setState({offerPrice:''})

    }

    //offer code end here
    


  }
  getAddress(){
    
    axios.get(GET_USER_DATA_BY_ID + Cookies.get("userId"), {
      headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` },
      Accept: "application/json",
      "Content-Type": "application/json"
    })
    .then(response => {
      this.setState(
        {
          userData: response.data,
          guid: response.data.address[0].guid,
          user: response.data.address[0].user,
          address1: response.data.address[0].address1,
          address2: response.data.address[0].address2,
          city: response.data.address[0].city,
          pincode: response.data.address[0].pincode,
          mobile: response.data.address[0].mobile,
          email: response.data.address[0].email
        },
        () => {this.subStringContact()}
      );
    })
    .catch(function(error) {});
  }

  orderHistory(){
    axios
    .get(GET_LAST_ORDER_HISTORY_BY_ID,
      { headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` } }
    )
    .then(response => {
      
      if (response.data.orderId) {
      // console.log(response.data.orderId)
        this.setState(
          { sessionType: "pre",
          preSessionReport:response.data.preSessionReport,
          preOrderAServiceId:response.data.appointmentId,
               
        },
          () => {}
        );
      
      }
    })
    .catch(error => {});
  }


  IncrementItem = () => {
    this.setState({ clicks: this.state.clicks + 1 });
  };
  DecreaseItem = () => {
    this.setState({ clicks: this.state.clicks - 1 });
  };
  ToggleClick = () => {
    this.setState({ show: !this.state.show });
  };
  toggleMenu() {
    this.setState({ menu: !this.state.menu });
  }
  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }
  handleChangeMultiple(event) {
    let test = this.state.truHeartTest;
    test[event.target.name] = { value: event.target.value };
    this.setState({
      truHeartTest: test
    });
  }

  handleChangeAddData(event, name) {
    let data = this.state.truHeartTest[name];
    data[event.target.name] = event.target.value;

    let healthhis = this.state.truHeartTest;
    healthhis[name] = data;
    this.setState({
      truHeartTest: healthhis
    });
  }
  toActivatekit() {
    window.location.href = "/activate-kit";
  }

  createUI() {
    return this.state.formList.map((form, index) => (
      <div key={index}>
        <p className="roboto-bold-18px" style={{ color: "red" }}>
          Please Fill Person {index + 2} Details
        </p>
        {/* <p class="roboto-bold-18px" style="color:red">Person</p><p id="result">1</p> */}
        <div className="form-group row">
          <label htmlFor="text" className="col-sm-2 col-form-label">
            First Name
          </label>
          <div className="col-sm-10">
            <input
              className="form-control"
              id="inputfname"
              name="fname"
              placeholder="Firstname"
              type="text"
              onChange={e => this.formHandleChange(e, index)}
              name="firstName"
              id="0"
              value={form.firstName}
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="text" className="col-sm-2 col-form-label">
            Last Name
          </label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              names="lname"
              onChange={e => this.formHandleChange(e, index)}
              name="lastName"
              id="1"
              value={form.lastName}
              placeholder="Lastname"
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="text" className="col-sm-2 col-form-label">
            Relationship
          </label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              id="inputlrel"
              names="relation"
              onChange={e => this.formHandleChange(e, index)}
              name="relationship"
              id="2"
              value={form.relationship}
              id="relation"
              placeholder="Relationship"
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="text" className="col-sm-2 col-form-label">
            Age
          </label>
          <div className="col-sm-10">
            <input
              type="number"
              className="form-control"
              id="inputage"
              onChange={e => this.formHandleChange(e, index)}
              name="age"
              id="3"
              value={form.age}
              placeholder="Age"
            />
          </div>
        </div>
      </div>
    ));
  }

  formHandleChange(e, index) {
    let formListIndex=this.state.formList[index];
    if (e.target.name === "firstName") {
      formListIndex.firstName = e.target.value;
    }
    if (e.target.name === "lastName") {
      formListIndex.lastName = e.target.value;
    }
    if (e.target.name === "age") {
      formListIndex.age = e.target.value;
    }
    if (e.target.name === "relationship") {
      formListIndex.relationship = e.target.value;
    }
    this.setState({ formList: this.state.formList });
  }

  addform() {
    this.setState({ clicks: this.state.clicks + 1 });

    this.setState({ butdis: this.state.butdis + 1 });

    this.setState({
      formList: [
        ...this.state.formList.concat([
          { firstName: "", lastName: "", relationship: "", age: "" }
        ])
      ]
    });
  }
  removeform(index) {
    this.setState({ clicks: this.state.clicks - 1 });

    this.state.formList.splice(index - 2, 2);
    this.setState({ butdis: this.state.butdis - 1 });
    this.setState({ formList: this.state.formList });
  }

  handleChange(event, name, subname) {
    let values = [...this.state.values];
    values = event.target.value;
    this.setState({ values });
  }

  addClick() {
    this.setState({ clicks: this.state.clicks + 1 });

    this.setState(prevState => ({ values: [...prevState.values, ""] }));
  }
  removeClick(i) {
    let values = [...this.state.values];
    values.splice(i - 2, 2);
    this.setState({ clicks: this.state.clicks - 1 });
    this.setState({ values });
  }

  handleSubmit(event) {
    event.preventDefault();
  }
  changeTab(value) {
    if (value === 1) {
      this.setState({
        mode: "Your tests"
      });
    }
    if (value === 2) {
      this.setState({
        mode: "Ship to"
      });
    }
    if (value === 4) {
      this.setState({
        mode: "Pay with"
      });
    }
    this.setState({
      isActive: value
    });
  }
  
  subStringContact() {
    this.setState({ mobile: this.state.mobile.substr(3) }, () => {});
  }
  orderTestHistory() {
    axios
      .get(
        "cartservice/" +
          Cookies.get("userId") +
          "/orderHistoryForProductConfirmed",
        { headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` } }
      )
      .then(response => {
        if (response.data) {
          this.setState(
            { lastorderHistory: response.data, futureOrder: true },
            () => {}
          );
          if (response.data.razorPaymentId) {
           
            axios
              .get(
                "productservice/findByProduct/" +
                  response.data.products[0].productId,
                {
                  headers: {
                    Authorization: `Bearer ${Cookies.get("jwt-token")}`
                  }
                }
              )
              .then(response => {
                if (response.data) {
                  this.setState({ productDetails: response.data }, () => {});
                }
              })
              .catch(error => {});
          } else {
           swal("Your Last Transation was not Successfull").then(
            this.cancelUntrackedOrders()
          );
          }
        }
      })
      .catch(error => {});
  }

  cancelUntrackedOrders(){
    this.setState({futureOrder:false},()=>{})
    axios
    .get(
      "cartservice/" +
        this.state.lastorderHistory.orderId +
        "/cancelUnTrackeredOrders",
      { headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` } }
    )
    .then((response) => {

        window.location.reload();
     
    })
    .catch(error => {});

  }
  changeHandler(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  changeTrugeneHandler(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  validateForm() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;

    if (!fields["address1"]) {
      formIsValid = false;
      errors["address1"] = "*Please enter your address line 1.";
    }

    if (!fields["user"]) {
      formIsValid = false;
      errors["user"] = "*Please enter your user.";
    }
    if (!fields["address2"]) {
      formIsValid = false;
      errors["address2"] = "*Please enter your address line 2.";
    }

    if (!fields["city"]) {
      formIsValid = false;
      errors["city"] = "*Please enter your city.";
    }

    if (!fields["pincode"]) {
      formIsValid = false;
      errors["pincode"] = "*Please enter your pincode.";
    }

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "*Please enter your email address";
    }

    if (typeof fields["email"] !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "*Please enter valid email address";
      }
    }

    if (!fields["mobile"]) {
      formIsValid = false;
      errors["mobile"] = "*Please enter your mobile no.";
    }

    if (typeof fields["mobile"] !== "undefined") {
      if (!fields["mobile"].match(/^[0-9]{10}$/)) {
        formIsValid = false;
        errors["mobile"] = "*Please enter valid mobile no.";
      }
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  validateTrugeneForm() {
    let fields = this.state.truHeartTest;
    let fields2 = this.state.truHeartTest.willUse;
    let fields3 = this.state.truHeartTest.test;

    let errors = {};
    let formIsValid = true;

    if (!fields["test"]) {
      formIsValid = false;
      swal("Please select This test has been recommended by?");
    }

    if (!fields["willUse"]) {
      formIsValid = false;

      swal("Please select Who will use this test?");
    }

    this.state.formList.map((formDetails, index) => {
      if (
        formDetails["firstName"] === undefined ||
        formDetails["firstName"] === ""
      ) {
        swal("Please fill first name");
        formIsValid = false;
      }

      if (
        formDetails["lastName"] === undefined ||
        formDetails["lastName"] === ""
      ) {
        swal("Please fill last name");
        formIsValid = false;
      }

      if (
        formDetails["relationship"] === undefined ||
        formDetails["relationship"] === ""
      ) {
        swal("Please fill relationship ");
        formIsValid = false;
      }

      if (formDetails["age"] === undefined || formDetails["age"] === "") {
        swal("Please fill age");
        formIsValid = false;
      }
    });

    if (
      this.state.truHeartTest.willUse &&
      this.state.truHeartTest.willUse.value === "some"
    ) {
      if (fields2["name"] === undefined || fields2["name"] === "") {
        this.setState({ nameError: true });
        formIsValid = false;
      } else {
        this.setState({ nameError: false });
      }
      if (fields2["phone"] === undefined || fields2["phone"] === "") {
        this.setState({ phoneError: true });
        formIsValid = false;
      } else {
        this.setState({ phoneError: false });
      }
      if (fields2["email"] === undefined || fields2["email"] === "") {
        this.setState({ emailError: true });
        formIsValid = false;
      } else {
        this.setState({ emailError: false });
      }
      if (fields2["age"] === undefined || fields2["age"] === "") {
        this.setState({ ageError: true });
        formIsValid = false;
      } else {
        this.setState({ ageError: false });
      }
    }
    if (
      this.state.truHeartTest.test &&
      this.state.truHeartTest.test.value === "doctor"
    ) {
      if (fields3["cname"] === undefined || fields3["cname"] === "") {
        this.setState({ nameError2: true });
        formIsValid = false;
      } else {
        this.setState({ nameError2: false });
      }
      if (fields3["cmobile"] === undefined || fields3["cmobile"] === "") {
        this.setState({ phoneError2: true });
        formIsValid = false;
      } else {
        this.setState({ phoneError2: false });
      }
      if (fields3["cemail"] === undefined || fields3["cemail"] === "") {
        this.setState({ emailError2: true });
        formIsValid = false;
      } else {
        this.setState({ emailError2: false });
      }
      if (fields3["intrest"] === undefined || fields3["intrest"] === "") {
        this.setState({ dropdownError: true });
        formIsValid = false;
      } else {
        this.setState({ dropdownError: false });
      }
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  submitShipping(e) {
    e.preventDefault();
    if (this.validateForm()) {
      const {
        user,
        address1,
        address2,
        city,
        pincode,
        email,
        mobile
      } = this.state;

      axios
        .put(
          "users/shippingAdress/" + Cookies.get("userId"),
          { user, address1, address2, city, pincode, email, mobile },
          {
            headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` },
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        )
        .then(response => {
          this.setState({
            guid: response.data.address[0].guid
          });
        })
        .catch(error => {
          if (error.response === undefined) {
            this.props.history.push("/error");
          } else {
            this.setState({
              errorMsg: error.response.data.message
            });
          }
        });

      this.placeOrder();
      e.target.reset();
    }
  }

  updateSubmitShipping(e) {
    e.preventDefault();
    if (this.validateForm()) {
      const {
        user,
        address1,
        address2,
        city,
        pincode,
        email,
        mobile
      } = this.state;

      axios
        .put(
          "users/shippingAdress/" + Cookies.get("userId"),
          { user, address1, address2, city, pincode, email, mobile },
          {
            headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` },
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        )
        .then(response => {
          this.setState({
            guid: response.data.address[0].guid
          });
        })
        .catch(error => {
          if (error.response === undefined) {
            this.props.history.push("/error");
          } else {
            this.setState({
              errorMsg: error.response.data.message
            });
          }
        });

      this.updatePlaceOrder();
      e.target.reset();
    }
  }

  updatePlaceOrder() {
    this.orderTestHistory();
    let orderId = this.state.lastorderHistory.orderId;

    axios
      .get(`cartservice/` + orderId + "/updateOrder", {
        headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` },
        Accept: "application/json",
        "Content-Type": "application/json"
      })
      .then(response => {
        swal("Your Address Successful Updated");
        this.props.history.push("/your-reports");
      })
      .catch(error => {
        if (error.response === undefined) {
          this.props.history.push("/error");
        } else {
          this.setState({ errorMsg: error.response.data.message }, () => {});
          swal(this.state.errorMsg);
        }
      });
  }

  changePromoHandler(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  goTOPayment() {
    this.changeTab(4);
  }

  applyPromo() {
    this.setState({
      invalidPromocode: null,
      validPromoCode: false
    });

    if (this.state.againPromocodes.indexOf(this.state.promocode.toUpperCase()) >-1) {
      swal("Promocode already applied...!");

    } else {
      axios
        .get(`cartservice/applyPromoCode/` + this.state.promocode, {
          headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` },
          Accept: "application/json",
          "Content-Type": "application/json"
        })
        .then(response => {
          if (response.data.stackble == true) {
            this.promoCodeCalculations(response.data);
            this.setState({
              validPromoCode: true,
              nonStackble: false
            });
            //alert(" stackble ")
          } else if (this.state.isStackble) {
            this.setState({
              nonStackble: true,
              validPromoCode: false
            });
          } else {
            this.setState({
              isStackble: true,
              validPromoCode: true
            });
            this.promoCodeCalculations(response.data);
          }
        })
        .catch(error => {
          this.setState({
            invalidPromocode: error
          });
        });
    }
  }

  promoCodeCalculations(promoCodeData) {
    let promoRupees = promoCodeData.promoRupees;
    let promoPrecent = promoCodeData.promoPrecent;
    let promoCodeId = promoCodeData.promoCodeId;
    let promoCode = promoCodeData.promoCode;
    let lists = this.state.promoCodeDataNew;
    this.setState({
      againPromocodes: this.state.againPromocodes.concat(promoCode)
    });

    lists.push({
      promoRupees: promoRupees,
      promoPrecent: promoPrecent,
      promoCodeId: promoCodeId,
      promoCode: promoCode
    });

    this.setState({
      promoCodeDataNew: lists
    });
    this.renderTableData(promoRupees, promoPrecent);
  }

  renderAppliedPromoCodes() {
    return this.state.promoCodeDataNew.map((promoDetails, index) => {
      const { promoCode, promoCodeId } = promoDetails;

      return (
        <table className="table" style={{ border: "none" }}>
          <thead>
            <tr></tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Badge pill variant="info">
                  {promoCode}
                </Badge>
              </td>
              <td>
                <i
                  onClick={this.removePromocode.bind(this, index, promoCode)}
                  className="fas fa-trash-alt pr-2"
                />
              </td>
            </tr>
          </tbody>
        </table>
      );
    });
  }

  invalidPromocodeMsg() {
    if (this.state.invalidPromocode) {
      return (
        <h6 style={{ color: "red", fontSize: "14px" }}>
          Promo Code is not valid
        </h6>
      );
    }
  }

  validPromocodeMsg() {
    if (this.state.validPromoCode) {
      return (
        <h6 style={{ color: "green", fontSize: "14px" }}>Promo Code applied</h6>
      );
    }
  }
  nonStackblePromocodeMsg() {
    if (this.state.nonStackble) {
      return (
        <h6 style={{ color: "red", fontSize: "14px" }}>
          Promo Code already applied
        </h6>
      );
    }
  }

  cart(value) {
    if (this.validateTrugeneForm()) {
      let productId = localStorage.getItem("pid");
      let quantity = this.state.clicks;
      let customerId = Cookies.get("userId");

      let firstOrder = this.state.truHeartTest;
      let productOrderToPerson = this.state.formList;
      let promoDiscount=localStorage.getItem("promoDiscount")
      let cartId=this.state.cartId


      axios
        .post(
          `cartservice/` + customerId + "/cart",
          [{ productId, quantity, firstOrder, promoDiscount,productOrderToPerson,cartId }],
          {
            headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` },
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        )
        .then(response => {
          this.setState({
            cartId: response.data.cartId
          });
        },()=>{})
        .catch(error => {
          if (error.response === undefined) {
            this.props.history.push("/error");
          } else {
            this.setState({
              errorMsg: error.response.data.message
            });
          }
        });

      this.setState({
        isActive: value
      });
    }
  }

  removeItem = (id, promoCode) => {
    const newList = this.state.promoCodeDataNew;

    //const itemIndex = newList.findIndex(item => item.name === selectedItem.name);

    if (id > -1) {
      newList.splice(id, 1);
    } else {
      newList.push(promoCode);
    }

    this.setState({
      promoCodeDataNew: newList
    });

    const newList2 = this.state.againPromocodes;

    //const itemIndex2 = newList2.findIndex(item => item.name === selectedItem.name);

    if (id > -1) {
      newList2.splice(id, 1);
    } else {
      newList2.push(promoCode);
    }

    this.setState({
      againPromocodes: newList2
    });
  };

  removePromocode(id, promoCode) {
    this.removeItem(id, promoCode);

    this.setState(
      {
        invalidPromocode: null,
        validPromoCode: false,
        promocode: "",
        isStackble: false
      },
      function() {}
    );
    axios
      .get(`cartservice/applyPromoCode/` + promoCode, {
        headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` },
        Accept: "application/json",
        "Content-Type": "application/json"
      })
      .then(response => {
        this.setState(
          {
            promoRupeesPlus: response.data.promoRupees
          },
          function() {}
        );

        //alert(" stackble ")
      })
      .catch(error => {});
  }

  alertLess() {
    swal("You can not buy lesser then 1....!");
  }
  alertMore() {
    swal("You can not buy more then 5....!");
  }
  decrement() {
    let clicks = this.state.clicks;
    if (clicks == 1) {
      return (
        <div className="d-flex inline">
          <img
            className="img-fluid pr-3"
            src="./img/payment/minus.png"
            onClick={this.alertLess}
          />
        </div>
      );
    } else {
      return (
        <div className="d-flex inline">
          <img
            className="img-fluid pr-3"
            src="./img/payment/minus.png"
            onClick={this.removeform.bind(this, clicks)}
          />
        </div>
      );
    }
  }

  increment() {
    let clicks = this.state.clicks;
    if (clicks === 5) {
      return (
        <div className="d-flex inline">
          <img
            className="img-fluid pr-3"
            src="./img/payment/plus.png"
            onClick={this.alertMore}
          />
        </div>
      );
    } else {
      return (
        <div className="d-flex inline">
          <img
            className="img-fluid pr-3"
            src="./img/payment/plus.png"
            onClick={this.addform}
          />
        </div>
      );
    }
  }

  placeOrder() {
    let cartId = this.state.cartId;
    let shippingMethod = "office";
    let paymentType = "card";
    let guid = this.state.guid;
    let orderId = this.state.orderId;
    let preOrderAServiceId = this.state.preOrderAServiceId;
    let preSessionReport = this.state.preSessionReport;
    let sessionType = this.state.sessionType;
    axios
      .post(
        `cartservice/` + cartId + "/order",
        { shippingMethod, paymentType, guid, orderId,sessionType,preSessionReport,preOrderAServiceId },
        {
          headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` },
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      )
      .then(response => {
        this.changeTab(3);
console.log(response.data)
        this.setState(
          {
            razerpayOrderId: response.data.razorOrderId,
            orderId: response.data.orderId
          },
          () => {}
        );
      })
      .catch(error => {
        if (error.response === undefined) {
          this.props.history.push("/error");
        } else {
          this.setState({
            errorMsg: error.response.data.message
          });
        }
      });
  }

  renderProducts=()=> {
    return (
      <div>
        {this.state.data.map((products,index) => {
          return (
            <div className="row" key={index}>
              <div className="col-sm-6 col-lg-4 mt-5">
                <img src="./img/payment/Box.png" />
              </div>
              <div className="col-sm-6 col-lg-8 px-5 py-lg-5 py-sm-1">
                <h2 style={{ fontFamily: "Roboto Black", fontSize: "36px" }}>
                  {products.productName}
                </h2>
                <h3 style={{ fontFamily: "Roboto regular", fontSize: "24px" }}>
                  Quantity
                </h3>
                <div className="d-flex inline">
                  {/* <img className="img-fluid pr-3" src="./TrueGene22_files/minus.png" onClick={this.DecreaseItem} /> */}
                  {this.decrement()}
                  {this.state.show ? <h5>{this.state.clicks}</h5> : ""}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {this.increment()}
                  {/* { this.state.clicks==0 ?  <button disabled onClick={this.DecreaseItem}> decrease </button> :  <button onClick={this.IncrementItem}>increment </button> } */}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  showingPromoCode() {
    if (this.state.isActive === 1) {
      return (
        <div>
          <h2
            className="mt-3"
            style={{ fontFamily: "Roboto Bold", fontSize: "30px" }}
          >
            <b>Promo Code</b>
          </h2>
          <div className="d-flex inline  ">
            <div
              className="input-group-prepend mb-3 mr-2 "
              style={{ border: "solid 1px #ebebeb", width: "180px" }}
            >
              <input
                type="text"
                placeholder="Gifts or Promo codes"
                value={this.state.promocode}
                required
                name="promocode"
                onChange={this.changePromoHandler}
                className="form-control roboto-reg-16px"
                style={{ border: "none", height: "60px" }}
                aria-label="Text input with dropdown button"
              />
            </div>
            <button
              className="btn btn-hover-white my-2 my-sm-0 promoButton"
            
              onClick={() => this.applyPromo()}
              type="submit"
            >
              <b>Apply</b>
            </button>
          </div>

          {/*<a onClick={() => this.removePromocode()} id="payment-tab" data-toggle="tab" href="#payment" role="tab" aria-controls="payment"><i className="fas fa-trash-alt pr-2" /> Remove Code</a>*/}
          {this.invalidPromocodeMsg()}
          {this.validPromocodeMsg()}
          {this.nonStackblePromocodeMsg()}
          {this.renderAppliedPromoCodes()}
        </div>
      );
    }
  }

  renderTableData() {
    return this.state.data.map((productDetails, index) => {
      const {
        productId,
        productName,
        unitPrice,
        gstPercentage
      } = productDetails; //destructuring
      localStorage.setItem("pid", productId);
      localStorage.setItem("pname", productName);
      let gstPer = (unitPrice * gstPercentage) / 100;
      let qty = this.state.clicks;
      let finalGst = gstPer * qty;
      let totalAmount = unitPrice;
      let gstAmount = finalGst;
      let promoRupees;
      let promoPrecent;
      let finalAmount;
      let promoDiscount;
      let promdisc = 0;
      finalAmount = totalAmount * qty;
      finalAmount = finalAmount + gstAmount + this.state.shippingAmount;

      {
        this.state.promoCodeDataNew.map(function(promo, i) {
          promoRupees = promo.promoRupees;
          promoPrecent = promo.promoPrecent;

          promoDiscount = (finalAmount * promoPrecent) / 100;

          if (promoDiscount > promoRupees) {
            finalAmount = finalAmount - promoRupees;
            promoDiscount = promoRupees;
            promdisc = promdisc + promoDiscount;
          } else {
            finalAmount = finalAmount - promoDiscount;
          }
        });
        // this.setState({promoDiscount:promdisc},()=>{})

        localStorage.setItem("promoDiscount",promdisc)

      }

      if (promoDiscount) {
        return (
          <div key={index}>
             <h2 style={{ color: "#4f5be7" }}>
                  <b>Summary</b>
                </h2>
            <table className="table" style={{ border: "none" }}>
              <thead>
                <tr></tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <b>{productName}</b>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <Currency
                      currency="INR"
                      quantity={this.state.clicks * totalAmount}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Shipping + Handling</b>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <Currency
                      currency="INR"
                      quantity={this.state.shippingAmount}
                    />
                  </td>
                </tr>

                {/* <tr>
                  <td>
                    <b>GST @{gstPercentage}%</b>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <b>
                      <Currency currency="INR" quantity={gstAmount} />
                    </b>
                  </td>
                </tr> */}
                <tr>
                  <td>
                    <b>Promo Discount</b>
                  </td>
                  <td style={{ textAlign: "right" }}>{promdisc}</td>
                </tr>
                <tr>
                  <td>
                    <b>Total Rs</b>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <Currency currency="INR" quantity={finalAmount} />
                  </td>
                </tr>
              </tbody>
            </table>
            {this.showingPromoCode()}
          </div>
        );
      } else {
        return (
          <div key={index}>
             <h2 style={{ color: "#4f5be7" }}>
                  <b>Summary</b>
                </h2>
                
            <table className="table" style={{ border: "none" }}>
              <thead>
                <tr></tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <b>{productName}</b>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <Currency currency="INR" quantity={qty * totalAmount} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Shipping + Handling</b>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <Currency
                      currency="INR"
                      quantity={this.state.shippingAmount}
                    />
                  </td>
                </tr>

                {/* <tr>
                  <td>
                    <b>GST @{gstPercentage}%</b>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <b>
                      <Currency currency="INR" quantity={gstAmount} />
                    </b>
                  </td>
                </tr> */}

                <tr>
                  <td>
                    <b>Total Rs</b>
                  </td>
                  {/* <td style={{textAlign: 'right'}}>{(qty * totalAmount) + gstAmount + this.state.shippingAmount}</td> */}
                  <td style={{ textAlign: "right" }}>
                    <Currency
                      currency="INR"
                      quantity={
                        qty * totalAmount +
                        gstAmount +
                        this.state.shippingAmount
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            {this.showingPromoCode()}
          </div>
        );
      }
    });
  }

  toPaymentSuccess() {
    window.location.href = "/payment-success";
  }

  onUpdate() {
    this.setState({ futureOrder: false, isActive: 2 }, () => {
    });
  }
  close(){
    window.location.href="/product-page";
  }

  render() {
 
    let razorpayOrderId = this.state.razerpayOrderId;
    const show = this.state.menu ? "show" : "";

    
    let updateButton;
    let renderPages;

    if(this.state.lastorderHistory.orderTestStatus==="Order Confirmed"){
      updateButton=<button
      className="float-right btn btn-hover-white my-2 my-sm-0 center  mt-5"
      style={{
        height: "60px",
        width: "210px",
        borderRadius: "30px"
      }}
      onClick={this.onUpdate}
    >
      Update
    </button>;
    }
    else{
    updateButton=<button
      className="float-right btn btn-hover-white my-2 my-sm-0 center  mt-5"
      style={{
        height: "60px",
        width: "210px",
        borderRadius: "30px"
      }}
     onClick={this.close}
    >
      Close
    </button>;
    }


    if (this.state.futureOrder) {
      renderPages = (
        <div className="container hello" style={{ backgroundColor: "#ffffff" }}>
          <section className="reasons-sec">
            <div className="container reasons-sec-wrap gr-sec-wrap">
              <h3
                className="pl-0 ml-0"
                style={{
                  color: "#4f5be7",
                  fontSize: "16px",
                  fontWeight: 700,
                  textTransform: "uppercase"
                }}
              >
                You Already have a Order Placed{" "}
              </h3>
              <div className="col-lg-12">
                <p
                  style={{
                    color: "#2c333c",
                    fontSize: "18px",
                    fontWeight: 900,
                    lineHeight: "24px"
                  }}
                >
                  Order Id : {this.state.lastorderHistory.orderId}
                </p>
                <p
                  style={{
                    color: "#2c333c",
                    fontSize: "18px",
                    fontWeight: 900,
                    lineHeight: "24px"
                  }}
                >
                  Product Name : {this.state.productDetails.productName}
                </p>
             
                <p
                  style={{
                    color: "#2c333c",
                    fontSize: "18px",
                    fontWeight: 900,
                    lineHeight: "24px"
                  }}
                >
                  Ordered Date :
                  {moment(this.state.lastorderHistory.createdDate).format(
                    "DD-MM-YYYY"
                  )}
                </p>
                <p
                  style={{
                    color: "#2c333c",
                    fontSize: "18px",
                    fontWeight: 900,
                    lineHeight: "24px"
                  }}
                >
                  Ordered Status :{this.state.lastorderHistory.orderTestStatus}
                </p>
                <p
                  style={{
                    color: "#2c333c",
                    fontSize: "18px",
                    fontWeight: 600,
                    lineHeight: "36px"
                  }}
                >
                  Shipping Details : 
                  <div className="col-lg-12">
                  <p
                  style={{
                    color: "#2c333c",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "20px"
                  }}
                >
                  Name :
                   {this.state.user}
                </p>
                <p
                  style={{
                    color: "#2c333c",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "20px"
                  }}
                >
                  Email :
                   {this.state.email}
                </p>
                <p
                  style={{
                    color: "#2c333c",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "20px"
                  }}
                >
                  Contact No :
                   {this.state.mobile}
                </p>
                <p
                  style={{
                    color: "#2c333c",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "20px"
                  }}
                >
                  Address 1 :
                   {this.state.address1}
                </p>
                <p
                  style={{
                    color: "#2c333c",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "20px"
                  }}
                >
                  Address 2 :
                   {this.state.address2}
                </p>  <p
                  style={{
                    color: "#2c333c",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "20px"
                  }}
                >
                  City :
                   {this.state.city}
                </p>
                <p
                  style={{
                    color: "#2c333c",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "20px"
                  }}
                >
                  Pin Code :
                   {this.state.pincode}
                </p>



                  </div>

                </p>
              
              </div>
             
            {updateButton}
            </div>
          </section>
        </div>
      );
    } else {
      renderPages = (
        <div>
          {/* --------------------------------------------------------------------------------------------head section end-------------------------------------------------------------------------------------------- */}

          {}
          {/* end of navbar */}
          <div
            className="container "
            style={{
              boxShadow: "0 17px 40px rgba(92, 103, 108, 0.2)",
              borderRadius: "40px",
              backgroundColor: "#ffffff",
              paddingLeft: "40px!important"
            }}
          >
            <div className="row">
              <div className="col-sm-8" style={{ paddingTop: "4rem" }}>
                <h2 style={{ fontFamily: "Roboto Black", fontSize: "36px" }}>
                  <b>{this.state.mode}</b>
                </h2>
              </div>
              <div
                className="col-sm-4"
                style={{ background: "#eff7ff", borderTopRightRadius: "40px" }}
              >
                {/* <h1>....</h1> */}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-8">
                {/* <h1>NAV TAB LIST</h1> */}
                <ul className="nav" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link pl-0 active"
                      style={{
                        color: this.state.isActive === 1 ? "#4F5BE7" : "#000000"
                      }}
                      id="home-tab"
                      data-toggle="tab"
                      href="#home"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      TEST&nbsp;&nbsp;&nbsp;
                      <i className="fas fa-caret-right" />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="shipping-tab"
                      data-toggle="tab"
                      style={{
                        color: this.state.isActive === 2 ? "#4F5BE7" : "#000000"
                      }}
                      role="tab"
                      aria-controls="shipping"
                      aria-selected="false"
                    >
                      SHIPPING&nbsp;&nbsp;&nbsp;
                      <i className="fas fa-caret-right" />
                    </a>
                  </li>
                  {/* <li className="nav-item">
                <a className="nav-link" id="payment-tab" data-toggle="tab" href="#payment" role="tab" style={{ color: this.state.isActive === 4 ? "#4F5BE7" : "#000000" }} aria-controls="payment" onClick={() => this.changeTab(4)} aria-selected="false">PAYMENT&nbsp;&nbsp;&nbsp;<i className="fas fa-caret-right" /></a>
              </li> */}

                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="review-tab"
                      data-toggle="tab"
                      style={{
                        color: this.state.isActive === 3 ? "#4F5BE7" : "#000000"
                      }}
                      role="tab"
                      aria-controls="review"
                      aria-selected="false"
                    >
                      REVIEW
                    </a>
                  </li>
                </ul>

                {/* <a className="active" style={{ color: this.state.isActive === 1 ? "#0080ff" : "#000000" }} id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" onClick={() => this.changeTab(1)} aria-selected="true">TEST  <i className="fas fa-chevron-right" /></a> <a id="shipping-tab" data-toggle="tab" href="#shipping" style={{ color: this.state.isActive === 2 ? "#0080ff" : "#000000" }} role="tab" aria-controls="shipping" onClick={() => this.changeTab(2)} aria-selected="false"> SHIPPING  <i className="fas fa-chevron-right" /></a>  <a id="payment-tab" data-toggle="tab" href="#payment" role="tab" style={{ color: this.state.isActive === 4 ? "#0080ff" : "#000000" }} aria-controls="payment" onClick={() => this.changeTab(4)} aria-selected="false"> PAY  <i className="fas fa-chevron-right" /></a>  <a id="review-tab" data-toggle="tab" href="#review" style={{ color: this.state.isActive === 3 ? "#0080ff" : "#000000" }} role="tab" aria-controls="review" onClick={() => this.changeTab(3)} aria-selected="false"> REVIEW</a> */}
              </div>
              <div className="col-sm-4" style={{ background: "#eff7ff" }}>
               
              </div>
            </div>
            <div className="row">
              <div className="col-sm-8" style={{}}>
                {/* <h1>MAIN CONTENT AREAR</h1> */}
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade active show"
                    id="home"
                    className={
                      this.state.isActive === 1 ? "tab-pane active" : "tab-pane"
                    }
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    {this.renderProducts()}
                    <hr />
                    <div>
                      <div className="row">
                        <div className="col-sm-6 col-lg-4 mt-lg-5 mt-sm-1">
                          <h2 style={{ fontWeight: 400, fontSize: "1.5rem" }}>
                            Who will use this test?
                          </h2>
                        </div>
                        <div className="col-sm-6 col-lg-8 pt-lg-5 pt-sm-1">
                          <input
                            type="radio"
                            name="willUse"
                            value="me"
                            onChange={this.handleChangeMultiple}
                            checked={
                              this.state.truHeartTest.willUse
                                ? this.state.truHeartTest.willUse.value === "me"
                                  ? "checked"
                                  : ""
                                : ""
                            }
                          />{" "}
                          This test is for me
                          <br />
                          <br />
                          <input
                            type="radio"
                            name="willUse"
                            value="some"
                            onChange={this.handleChangeMultiple}
                            checked={
                              this.state.truHeartTest.willUse
                                ? this.state.truHeartTest.willUse.value ===
                                  "some"
                                  ? "checked"
                                  : ""
                                : ""
                            }
                            data-toggle="collapse"
                            href="#collapseExample"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                          />
                          This test is for someone else
                          {this.state.truHeartTest.willUse ? (
                            this.state.truHeartTest.willUse.value === "some" ? (
                              <form
                                className="gc-form needs-validation p-0 m-0"
                                validated
                              >
                                <div className="form-row mt-3">
                                  <div className="gc-col pl-0  col-md-5 mb-3">
                                    <div
                                      className="input-group"
                                      style={{
                                        borderColor: this.state.nameError
                                          ? "#ff0000"
                                          : ""
                                      }}
                                    >
                                      <input
                                        type="text"
                                        className="form-control m-0"
                                        id="validationTooltip01"
                                        name="name"
                                        onChange={e =>
                                          this.handleChangeAddData(e, "willUse")
                                        }
                                        value={
                                          this.state.truHeartTest.willUse.name
                                        }
                                        required
                                      />
                                      <div className="valid-tooltip">
                                        Looks good!
                                      </div>
                                      <div className="input-group-prepend border-0">
                                        <span
                                          className="input-group-text border-0"
                                          id="validationTooltip01"
                                        >
                                          <img src="img/homepage/i1.png" />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="gc-col col-md-5 mb-3">
                                    <div
                                      className="input-group"
                                      style={{
                                        borderColor: this.state.phoneError
                                          ? "#ff0000"
                                          : ""
                                      }}
                                    >
                                      <input
                                        type="text"
                                        className="form-control m-0"
                                        name="phone"
                                        id="validationTooltip02"
                                        placeholder="Phone number"
                                        onChange={e =>
                                          this.handleChangeAddData(e, "willUse")
                                        }
                                        value={
                                          this.state.truHeartTest.willUse.phone
                                        }
                                        required
                                      />
                                      <div className="valid-tooltip">
                                        Looks good!
                                      </div>
                                      <div className="input-group-prepend">
                                        <span
                                          className="input-group-text"
                                          id="validationTooltip02"
                                        >
                                          <img src="img/homepage/i5.png" />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* end of form-row */}
                                <div className="form-row ">
                                  <div className=" gc-col pl-0 col-md-5 mb-3">
                                    <div
                                      className="input-group"
                                      style={{
                                        borderColor: this.state.emailError
                                          ? "#ff0000"
                                          : ""
                                      }}
                                    >
                                      <input
                                        type="text"
                                        className="form-control m-0"
                                        id="validationTooltipEmailPrepend"
                                        name="email"
                                        onChange={e =>
                                          this.handleChangeAddData(e, "willUse")
                                        }
                                        value={
                                          this.state.truHeartTest.willUse.email
                                        }
                                        placeholder="E-mail ID"
                                        aria-describedby="validationTooltipEmailPrepend"
                                        required
                                      />
                                      <div className="invalid-tooltip">
                                        Please enter a valid E-mail ID.
                                      </div>
                                      <div className="input-group-prepend">
                                        <span
                                          className="input-group-text"
                                          id="validationTooltipEmailPrepend"
                                        >
                                          <img src="img/homepage/i3.png" />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="gc-col col-md-5 mb-3">
                                    <div
                                      className="input-group"
                                      style={{
                                        borderColor: this.state.ageError
                                          ? "#ff0000"
                                          : ""
                                      }}
                                    >
                                      <input
                                        type="number"
                                        className="form-control m-0"
                                        name="age"
                                        id="validationTooltip03"
                                        onChange={e =>
                                          this.handleChangeAddData(e, "willUse")
                                        }
                                        value={
                                          this.state.truHeartTest.willUse.age
                                        }
                                        placeholder="Age"
                                        required
                                      />
                                      <div className="valid-tooltip">
                                        Looks good!
                                      </div>
                                      <div className="input-group-prepend">
                                        <span
                                          className="input-group-text"
                                          id="validationTooltip03"
                                        >
                                          <img src="img/homepage/i4.png" />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <hr />
                      {}
                      {this.createUI()}

                      {/* ---------------------------------------2nd----------------------------- */}
                      <div className="row">
                        <div className="col-sm-6 col-lg-4 mt-lg-5 mt-sm-1 pl-lg-3 pl-sm-2">
                          <h2 style={{ fontWeight: 400, fontSize: "1.5rem" }}>
                            This test has been recommended by?
                          </h2>
                        </div>
                        <div className="col-sm-6 col-lg-8 pt-lg-5 pt-sm-1">
                          <input
                            type="radio"
                            name="test"
                            value="doctor"
                            onChange={this.handleChangeMultiple}
                            checked={
                              this.state.truHeartTest.test
                                ? this.state.truHeartTest.test.value ===
                                  "doctor"
                                  ? "checked"
                                  : ""
                                : ""
                            }
                          />{" "}
                          My Doctor has ordered this test
                          <br />
                          <br />
                          {this.state.truHeartTest.test ? (
                            this.state.truHeartTest.test.value === "doctor" ? (
                              <form
                                className="gc-form needs-validation p-0 m-0"
                                validated
                              >
                                <div className="form-row mt-3">
                                  <div className="gc-col pl-0  col-md-5 mb-3">
                                    <div
                                      className="input-group"
                                      style={{
                                        borderColor: this.state.nameError2
                                          ? "#ff0000"
                                          : ""
                                      }}
                                    >
                                      <input
                                        type="text"
                                        className="form-control m-0"
                                        id="validationTooltip01"
                                        name="cname"
                                        placeholder="Name"
                                        onChange={e =>
                                          this.handleChangeAddData(e, "test")
                                        }
                                        value={
                                          this.state.truHeartTest.test.cname
                                        }
                                        required
                                      />
                                      <div className="valid-tooltip">
                                        Looks good!
                                      </div>
                                      <div className="input-group-prepend border-0">
                                        <span
                                          className="input-group-text border-0"
                                          id="validationTooltip01"
                                        >
                                          <img src="img/homepage/i1.png" />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="gc-col col-md-5 mb-3">
                                    <div
                                      className="input-group"
                                      style={{
                                        borderColor: this.state.phoneError2
                                          ? "#ff0000"
                                          : ""
                                      }}
                                    >
                                      <input
                                        type="text"
                                        className="form-control m-0"
                                        id="validationTooltip02"
                                        name="cmobile"
                                        onChange={e =>
                                          this.handleChangeAddData(e, "test")
                                        }
                                        value={
                                          this.state.truHeartTest.test.cmobile
                                        }
                                        placeholder="Phone number"
                                        required
                                      />
                                      <div className="valid-tooltip">
                                        Looks good!
                                      </div>
                                      <div className="input-group-prepend">
                                        <span
                                          className="input-group-text"
                                          id="validationTooltip02"
                                        >
                                          <img src="img/homepage/i5.png" />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* end of form-row */}
                                <div className="form-row ">
                                  <div className=" gc-col pl-0 col-md-5 mb-3">
                                    <div
                                      className="input-group"
                                      style={{
                                        borderColor: this.state.emailError2
                                          ? "#ff0000"
                                          : ""
                                      }}
                                    >
                                      <input
                                        type="text"
                                        className="form-control m-0"
                                        id="validationTooltipEmailPrepend"
                                        placeholder="E-mail ID"
                                        name="cemail"
                                        onChange={e =>
                                          this.handleChangeAddData(e, "test")
                                        }
                                        value={
                                          this.state.truHeartTest.test.cemail
                                        }
                                        aria-describedby="validationTooltipEmailPrepend"
                                        required
                                      />
                                      <div className="invalid-tooltip">
                                        Please enter a valid E-mail ID.
                                      </div>
                                      <div className="input-group-prepend">
                                        <span
                                          className="input-group-text"
                                          id="validationTooltipEmailPrepend"
                                        >
                                          <img src="img/homepage/i3.png" />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="gc-col col-md-5 mb-3">
                                    <div
                                      className="input-group"
                                      style={{
                                        borderColor: this.state.dropdownError
                                          ? "#ff0000"
                                          : ""
                                      }}
                                    >
                                      <select
                                        className="form-control roboto-reg-16px"
                                        id="exampleFormControlSelect1"
                                        name="intrest"
                                        type="text"
                                        onChange={e =>
                                          this.handleChangeAddData(e, "test")
                                        }
                                        value={
                                          this.state.truHeartTest.test.intrest
                                        }
                                      >
                                        <option
                                          className="dropdown-item roboto-reg-16px"
                                          disabled
                                          selected
                                          value="myDoctor"
                                        >
                                          {" "}
                                          My Doctor is a
                                        </option>
                                        <option
                                          className="dropdown-item roboto-reg-16px"
                                          value="familyDoctor"
                                        >
                                          {" "}
                                          Family Doctor
                                        </option>
                                        <option
                                          className="dropdown-item roboto-reg-16px"
                                          value="Oncologist"
                                        >
                                          {" "}
                                          Oncologist
                                        </option>
                                        <option
                                          className="dropdown-item roboto-reg-16px"
                                          value="Cardiologist"
                                        >
                                          {" "}
                                          Cardiologist{" "}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                          <input
                            type="radio"
                            name="test"
                            value="counsellor"
                            onChange={this.handleChangeMultiple}
                            checked={
                              this.state.truHeartTest.test
                                ? this.state.truHeartTest.test.value ===
                                  "counsellor"
                                  ? "checked"
                                  : ""
                                : ""
                            }
                          />{" "}
                          This test has been recommended by my genetic
                          counsellor.<br></br><br></br>
                          <input
                            type="radio"
                            name="test"
                            value="I'm ordering"
                            onChange={this.handleChangeMultiple}
                            checked={
                              this.state.truHeartTest.test
                                ? this.state.truHeartTest.test.value ===
                                  "I'm ordering"
                                  ? "checked"
                                  : ""
                                : ""
                            }
                          />{" "}
                          I'm ordering this test directly.

                        </div>
                      </div>
                    </div>
                    <br />
                    <hr />
                    <br />
                    {/* <a className="nav-link" id="shipping-tab" data-toggle="tab" href="#shipping" role="tab" aria-controls="shipping" onClick={() => this.cart(2)} aria-selected="false">
                  <img className="float-right" src="./TrueGene22_files/continue.png" /></a> */}
                    <button
                      onClick={() => this.cart(2)}
                      className="float-right btn btn-hover-white my-2 my-sm-0 center  mt-5"
                      id="con1"
                      href="#settings"
                      data-toggle="tab"
                      style={{
                        height: "60px",
                        width: "210px",
                        borderRadius: "30px"
                      }}
                      type="submit"
                    >
                      <b>Continue</b>
                    </button>
                  </div>

                  <div
                    id="shipping"
                    className={
                      this.state.isActive === 2 ? "tab-pane active" : "tab-pane"
                    }
                    role="tabpanel"
                    aria-labelledby="shipping-tab"
                  >
                    <br />
                    <Form
                      onSubmit={
                        this.state.lastorderHistory.orderId
                          ? this.updateSubmitShipping
                          : this.submitShipping
                      }
                    >
                      <div
                        className="container p-0 m-0"
                        style={{ paddingBottom: "20px" }}
                      >
                        <h2
                          className="mt-3"
                          style={{
                            fontFamily: "Roboto Bold",
                            fontSize: "30px"
                          }}
                        >
                          <b>Delivery Address</b>
                        </h2>
                        <div className="d-flex inline">
                          <div
                            className="input-group-prepend mb-3 ml-0 mr-2"
                            style={{
                              border: "solid 1px #ebebeb",
                              width: "445px",
                              height: "60px"
                            }}
                          >
                            <input
                              type="text"
                              placeholder="Name"
                              value={this.state.user}
                              name="user"
                              onChange={this.changeHandler}
                              className="form-control roboto-reg-16px"
                              style={{ height: "57px", border: "none" }}
                              aria-label="Text input with dropdown button"
                            />
                            <button
                              className="btn btn-outline-secondary roboto-bold-14px "
                              href="#"
                              style={{
                                border: "none",
                                backgroundColor: "white"
                              }}
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="img/payment/ship-Icon-1.png" />
                            </button>
                          </div>

                          <div
                            className="input-group-prepend mb-3 mr-2"
                            style={{
                              border: "solid 1px #ebebeb",
                              width: "445px"
                            }}
                          >
                            <input
                              type="text"
                              placeholder="Mobile number"
                              value={this.state.mobile}
                              name="mobile"
                              onChange={this.changeHandler}
                              className="form-control roboto-reg-16px"
                              style={{ border: "none", height: "60px" }}
                              aria-label="Text input with dropdown button"
                            />
                            <button
                              className="btn btn-outline-secondary roboto-bold-14px "
                              href="#"
                              style={{
                                height: "57px",
                                border: "none",
                                backgroundColor: "white"
                              }}
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="img/payment/ship-Icon-4.png" />
                            </button>
                          </div>
                          <div
                            className="input-group-prepend mb-3 mr-2"
                            style={{
                              border: "solid 1px #ebebeb",
                              width: "445px"
                            }}
                          >
                            <input
                              type="text"
                              placeholder="Email Id"
                              value={this.state.email}
                              name="email"
                              onChange={this.changeHandler}
                              className="form-control roboto-reg-16px"
                              style={{ border: "none", height: "60px" }}
                              aria-label="Text input with dropdown button"
                            />
                            <button
                              className="btn btn-outline-secondary roboto-bold-14px "
                              href="#"
                              style={{
                                height: "57px",
                                border: "none",
                                backgroundColor: "white"
                              }}
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="img/payment/ship-Icon-6.png" />
                            </button>
                          </div>
                        </div>
                        <div className="d-flex inline ">
                          <div
                            className="input-group-prepend mb-3 mr-2"
                            style={{
                              color: "red",
                              fontSize: "12px",
                              width: "445px"
                            }}
                          >
                            {this.state.errors.user}
                          </div>

                          <div
                            className="input-group-prepend mb-3 mr-2"
                            style={{
                              color: "red",
                              fontSize: "12px",
                              width: "445px"
                            }}
                          >
                            {this.state.errors.mobile}
                          </div>

                          <div
                            className="input-group-prepend mb-3 mr-2"
                            style={{
                              color: "red",
                              fontSize: "12px",
                              width: "445px"
                            }}
                          >
                            {this.state.errors.email}
                          </div>
                        </div>
                        <div className="d-flex inline  ">
                          <div
                            className="input-group-prepend mb-3 mr-2 "
                            style={{
                              border: "solid 1px #ebebeb",
                              width: "445px"
                            }}
                          >
                            <input
                              type="text"
                              name="address1"
                              value={this.state.address1}
                              placeholder="Address line 1"
                              onChange={this.changeHandler}
                              className="form-control roboto-reg-16px"
                              style={{ border: "none", height: "60px" }}
                              aria-label="Text input with dropdown button"
                            />
                            <button
                              className="btn btn-outline-secondary roboto-bold-14px "
                              href="#"
                              style={{
                                height: "57px",
                                border: "none",
                                backgroundColor: "white"
                              }}
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="img/payment/ship-Icon-3.png" />
                            </button>
                          </div>
                          <div
                            className="input-group-prepend mb-3 mr-2"
                            style={{
                              border: "solid 1px #ebebeb",
                              width: "445px"
                            }}
                          >
                            <input
                              type="text"
                              placeholder="Address line 2"
                              value={this.state.address2}
                              name="address2"
                              onChange={this.changeHandler}
                              className="form-control roboto-reg-16px"
                              style={{ border: "none", height: "60px" }}
                              aria-label="Text input with dropdown button"
                            />
                            <button
                              className="btn btn-outline-secondary roboto-bold-14px "
                              href="#"
                              style={{
                                height: "57px",
                                border: "none",
                                backgroundColor: "white"
                              }}
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="img/payment/ship-Icon-5.png" />
                            </button>
                          </div>
                        </div>
                        <div className="d-flex inline ">
                          <div
                            className="input-group-prepend mb-3 mr-2"
                            style={{
                              color: "red",
                              fontSize: "12px",
                              width: "445px"
                            }}
                          >
                            {this.state.errors.address1}
                          </div>

                          <div
                            className="input-group-prepend mb-3 mr-2"
                            style={{
                              color: "red",
                              fontSize: "12px",
                              width: "445px"
                            }}
                          >
                            {this.state.errors.address2}
                          </div>
                        </div>
                        <div className="d-flex inline pl-0 ml-0">
                          <div
                            className="input-group-prepend mb-3 mr-2"
                            style={{
                              border: "solid 1px #ebebeb",
                              width: "445px",
                              height: "60px"
                            }}
                          >
                            <input
                              type="text"
                              placeholder="City"
                              value={this.state.city}
                              onChange={this.changeHandler}
                              name="city"
                              className="form-control roboto-reg-16px"
                              style={{ height: "57px", border: "none" }}
                              aria-label="Text input with dropdown button"
                            />
                            <button
                              className="btn btn-outline-secondary roboto-bold-14px "
                              href="#"
                              style={{
                                border: "none",
                                backgroundColor: "white"
                              }}
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="img/payment/ship-Icon-2.png" />
                            </button>
                          </div>
                          <div
                            className="input-group-prepend mb-3 mr-2"
                            style={{
                              border: "solid 1px #ebebeb",
                              width: "445px",
                              height: "60px"
                            }}
                          >
                            <input
                              type="text"
                              placeholder="Pin"
                              value={this.state.pincode}
                              onChange={this.changeHandler}
                              name="pincode"
                              className="form-control roboto-reg-16px"
                              style={{ height: "57px", border: "none" }}
                              aria-label="Text input with dropdown button"
                            />
                            <button
                              className="btn btn-outline-secondary roboto-bold-14px "
                              href="#"
                              style={{
                                border: "none",
                                backgroundColor: "white"
                              }}
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="img/payment/ship-Icon-1.png" />
                            </button>
                          </div>
                        </div>
                        <div className="d-flex inline ">
                          <div
                            className="input-group-prepend mb-3 mr-2"
                            style={{
                              color: "red",
                              fontSize: "12px",
                              width: "445px"
                            }}
                          >
                            {this.state.errors.city}
                          </div>

                          <div
                            className="input-group-prepend mb-3 mr-2"
                            style={{
                              color: "red",
                              fontSize: "12px",
                              width: "445px"
                            }}
                          >
                            {this.state.errors.pincode}
                          </div>
                        </div>
                        <div className="col-sm-8 pt-5 pb-lg-5 pb-sm-0">
                          {/* <h1 class="float-right">Continue Button</h1> */}
                        </div>
                      </div>
                      {/* <hr> */}
                      {/* --------------------------------------1st---------------------- */}
                      <br />
                      <hr />
                      {/* <button className="float-right btn btn-hover-white my-2 my-sm-0 center  my-5 " style={{ height: '60px', width: '150px', borderRadius: '30px', color: 'white', backgroundColor: '#4F5BE7' }} type="submit"><b>CONTINUE</b></button> */}
                      <button
                        className="float-right btn btn-hover-white my-2 my-sm-0 center  mt-5"
                        id="con2"
                        href="#settings"
                        data-toggle="tab"
                        style={{
                          height: "60px",
                          width: "210px",
                          borderRadius: "30px"
                        }}
                        type="submit"
                      >
                        <b>Continue</b>
                      </button>

                      <br />
                      <br />
                      <br />
                      <br />
                    </Form>
                  </div>
                  <div
                    id="payment"
                    className={
                      this.state.isActive === 4 ? "tab-pane active" : "tab-pane"
                    }
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    {/* <div class="row">
    </div> */}
                    <br />
                    <div
                      className="container p-0 m-0"
                      style={{ paddingBottom: "20px" }}
                    >
                      <h2
                        className="mt-3"
                        style={{ fontFamily: "Roboto Bold", fontSize: "30px" }}
                      >
                        <b>Credit or debit card</b>
                      </h2>
                      <div className=" d-flex inline ">
                        <div className="container col-sm-12 col-lg-6 col-md-12 m-0 p-0">
                          <div
                            className=" input-group-prepend mb-3 ml-0 pl-0 mr-2"
                            style={{
                              border: "solid 1px #ebebeb",
                              width: "300px",
                              height: "60px"
                            }}
                          >
                            <input
                              type="text"
                              placeholder="Mr. John D."
                              className="form-control roboto-reg-16px"
                              style={{ border: "none", height: "60px" }}
                              aria-label="Text input with dropdown button"
                            />
                            <button
                              className="btn btn-outline-secondary roboto-bold-14px "
                              href="#"
                              style={{
                                border: "none",
                                backgroundColor: "white"
                              }}
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="img/payment/visa.png" />
                            </button>
                          </div>
                        </div>
                        <div className="container col-sm-12 col-lg-6 col-md-12">
                          <a href="#">
                            {" "}
                            <img
                              className="img-fluid"
                              style={{ paddingLeft: "100px" }}
                              src="img/payment/ssl.png"
                            />
                          </a>
                        </div>
                      </div>
                      <div className="d-flex inline  ">
                        <div className="container col-sm-12 col-lg-6 col-md-12 m-0 p-0">
                          <div
                            className="d-flex inline"
                            style={{ width: "310px" }}
                          >
                            <div
                              className="input-group-prepend mb-3 mr-2 "
                              style={{
                                border: "solid 1px #ebebeb",
                                width: "200px"
                              }}
                            >
                              <input
                                type="text"
                                placeholder="10 / 19"
                                className="form-control roboto-reg-16px"
                                style={{ border: "none", height: "60px" }}
                                aria-label="Text input with dropdown button"
                              />
                            </div>
                            <div
                              className="input-group-prepend mb-3 mr-2 "
                              style={{
                                border: "solid 1px #ebebeb",
                                width: "200px"
                              }}
                            >
                              <input
                                type="text"
                                placeholder={944}
                                className="form-control roboto-reg-16px"
                                style={{ border: "none", height: "60px" }}
                                aria-label="Text input with dropdown button"
                              />
                              <button
                                className="btn btn-outline-secondary roboto-bold-14px "
                                href="#"
                                style={{
                                  border: "none",
                                  backgroundColor: "white"
                                }}
                                type="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="img/payment/pay-Icon-2.png" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="container col-sm-6 col-lg-6 col-md-6 ">
                          <p
                            className="float-right float-md-left float-sm-left"
                            style={{ color: "#84888e" }}
                          >
                            We use SSL encription to protect your personal
                            information, including your payment information.
                          </p>
                        </div>
                      </div>
                      <h2
                        className="mt-3"
                        style={{ fontFamily: "Roboto Bold", fontSize: "30px" }}
                      >
                        <b>Promo Code</b>
                      </h2>
                      <div className="d-flex inline  ">
                        <div
                          className="input-group-prepend mb-3 mr-2 "
                          style={{
                            border: "solid 1px #ebebeb",
                            width: "180px"
                          }}
                        >
                          <input
                            type="text"
                            placeholder="Gifts or Promo codes"
                            value={this.state.promocode}
                            required
                            name="promocode"
                            onChange={this.changePromoHandler}
                            className="form-control roboto-reg-16px"
                            style={{ border: "none", height: "60px" }}
                            aria-label="Text input with dropdown button"
                          />
                        </div>
                        {/* <button className="btn btn-outline-secondary roboto-bold-14px " style={{ border: 'none', backgroundColor: 'white' }} type="button" onClick={() => this.applyPromo()} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="img/payment/apply.png" /></button> */}
                        <button
                          className="btn btn-hover-white my-2 my-sm-0 promoButton"
                         
                          onClick={() => this.applyPromo()}
                          type="submit"
                        >
                          <b>Apply</b>
                        </button>
                      </div>
                      {/* <a className="nav-link" id="shipping-tab" data-toggle="tab" href="#shipping" role="tab" aria-controls="shipping" onClick={() => this.cart()} aria-selected="false">
                  <img className="float-right" src="./TrueGene22_files/continue.png" /></a> */}
                    </div>
                    <a
                      onClick={() => this.removePromocode()}
                      id="payment-tab"
                      data-toggle="tab"
                      href="#payment"
                      role="tab"
                      aria-controls="payment"
                    >
                      <i className="fas fa-trash-alt pr-2" /> Remove Code
                    </a>

                    {this.invalidPromocodeMsg()}
                    {this.validPromocodeMsg()}
                    {this.nonStackblePromocodeMsg()}
                    {this.renderAppliedPromoCodes()}

                    {/* <hr> */}
                    {/* --------------------------------------1st---------------------- */}
                    <br />

                    <br />
                    <hr />
                    {/* <button onClick={() => this.placeOrder()} className="float-right btn btn-hover-white my-2 my-sm-0 center" style={{ height: '60px', width: '150px', borderRadius: '30px', color: 'white', backgroundColor: '#4F5BE7' }} type="submit">CONTINUE</button> */}
                    <button
                      onClick={() => this.placeOrder()}
                      className="float-right btn btn-hover-white my-2 my-sm-0 center  mt-5"
                      id="con1"
                      href="#settings"
                      data-toggle="tab"
                      style={{
                        height: "60px",
                        width: "210px",
                        borderRadius: "30px"
                      }}
                      type="submit"
                    >
                      <b>Continue</b>
                    </button>

                    <br />
                  </div>
                  <div
                    className={
                      this.state.isActive === 3 ? "tab-pane active" : "tab-pane"
                    }
                    id="review"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <p
                      className="mt-3"
                      style={{
                        color: "#84888e",
                        fontSize: "16px",
                        fontWeight: 400
                      }}
                    >
                      Make sure the information below is correct.
                    </p>
                    <div className="row">
                      <div className="col-md-4 col-sm-12 col-lg-4 ">
                        <h2
                          style={{
                            color: "#2c333c",
                            fontFamily: "Roboto",
                            fontSize: "30px",
                            fontWeight: 700
                          }}
                        >
                          Test
                        </h2>
                      </div>
                      <div className="col-md-4 col-sm-12 col-lg-4 d-flex">
                        <img
                          className="m-0 img-fluid"
                          src="img/payment/Box_confirm-page.png"
                          style={{
                            width: "134px",
                            height: "76px",
                            paddingTop: "0px"
                          }}
                        />
                        <h3
                          style={{
                            color: "#2c333c",
                            fontFamily: "Roboto",
                            fontSize: "24px",
                            fontWeight: 400
                          }}
                        >
                        {localStorage.getItem('pname')}                        </h3>
                      </div>
                      <div className="col-md-4 col-sm-12 col-lg-4">
                        <button
                          className="float-right btn btn-hover-white my-2 my-sm-0 center"
                          style={{
                            height: "60px",
                            width: "100px",
                            borderRadius: "30px"
                          }}
                          onClick={() => this.changeTab(1)}
                          type="submit"
                        >
                          <b>Edit</b>
                        </button>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-4 col-sm-12 col-lg-4">
                        <h2
                          style={{
                            color: "#2c333c",
                            fontFamily: "Roboto",
                            fontSize: "30px",
                            fontWeight: 700
                          }}
                        >
                          Ship to
                        </h2>
                      </div>
                      <div className="col-md-4 col-sm-12 col-lg-4">
                        <h3
                          style={{
                            color: "#2c333c",
                            fontFamily: "Roboto",
                            fontSize: "24px",
                            fontWeight: 400
                          }}
                        >
                          {this.state.user},<br></br>
                          {this.state.address1}, <br></br>
                          {this.state.address2},<br></br>
                          {this.state.city} {this.state.pincode}
                        </h3>
                      </div>
                      <div className="col-md-4 col-sm-12 col-lg-4">
                        <button
                          className="float-right btn btn-hover-white my-2 my-sm-0 center"
                          style={{
                            height: "60px",
                            width: "100px",
                            borderRadius: "30px"
                          }}
                          onClick={() => this.changeTab(2)}
                          type="submit"
                        >
                          <b>Edit</b>
                        </button>
                      </div>
                    </div>

                    <hr />
                    <br />
                    <br />

                    <form
                      method="POST"
                      action={PayContants.action}
                    >
                      <input
                        type="hidden"
                        name="key_id"
                        defaultValue={PayContants.key_id}
                      />
                      <input
                        type="hidden"
                        name="order_id"
                        defaultValue={razorpayOrderId}
                      />
                      <input
                        type="hidden"
                        name="name"
                        defaultValue={PayContants.pay_name}
                      />
                      <input
                        type="hidden"
                        name="description"
                        defaultValue={PayContants.pay_description}
                      />
                      <input
                        type="hidden"
                        name="prefill[name]"
                        defaultValue={PayContants.pay_profileName}
                      />
                      <input
                        type="hidden"
                        name="prefill[contact]"
                        defaultValue={PayContants.pay_contact}
                      />
                      <input
                        type="hidden"
                        name="prefill[email]"
                        defaultValue={PayContants.pay_email}
                      />
                      <input
                        type="hidden"
                        name="notes[shipping address]"
                        defaultValue={PayContants.pay_address}
                      />
                      <input
                        type="hidden"
                        name="callback_url"
                        defaultValue={PayContants.callback_url}
                      />
                      <input
                        type="hidden"
                        name="cancel_url"
                        defaultValue={PayContants.cancel_url}
                      />

                      <button
                        className="float-right btn btn-hover-white my-2 my-sm-0 center  mt-5"
                        id="con1"
                        href="#settings"
                        data-toggle="tab"
                        style={{
                          height: "60px",
                          width: "210px",
                          borderRadius: "30px"
                        }}
                        type="submit"
                      >
                        <b>Purchase</b>
                      </button>
                    </form>

                    {/* <a className="nav-link" id="payment-tab" data-toggle="tab" href="#payment" role="tab" aria-controls="payment"  aria-selected="false">
                <Link to={{pathname:'/razorpay',razorpayOrderId:razorpayOrderId}} className="float-right btn btn-hover-white my-2 my-sm-0 center" style={{ height: '60px', width: '150px', borderRadius: '30px', color: 'white', backgroundColor: '#4F5BE7' }}>PURCHASE</Link></a> */}
                  </div>
                </div>
              </div>
              <div className="col-sm-4" style={{ background: "#eff7ff" }}>
                {/* <h1>LIST OF PURCHASE</h1> */}
                <div className="table-responsive-sm">
                  {this.renderTableData()}
                </div>
              </div>
            </div>
            <div className="row ">
              <div
                className="col-sm-12 col-lg-8 col-md-12 pt-0 pb-lg-5 pb-sm-0"
                style={{}}
              >
                {/* <button class="float-right btn btn-hover-white my-2 my-sm-0 center  my-5" id="con1"    href="#profile"  data-toggle="tab" style="height:60px;width:210px;border-radius:30px;" type="submit"><b>Continue</b></button> */}
              </div>
              <div
                className="col-sm-12 col-lg-4 col-md-12 pb-lg-5 pb-sm-0"
                style={{
                  background: "#eff7ff",
                  borderBottomRightRadius: "40px"
                }}
              >
                <h4
                  style={{
                    color: "#4f5be7",
                    fontSize: "24px",
                    fontWeight: 700,
                    display: "none"
                  }}
                >
                  How did you hear about us?
                </h4>
                <h4>
                  <div
                    className="gc-col px-0 col-md-5 mb-3 mt-3"
                    style={{ maxWidth: "334px" }}
                  >
                    <select
                      className="form-control roboto-reg-16px d-none"
                      id="exampleFormControlSelect1"
                      type="text"
                      name="interest"
                      style={{ border: "none", height: "60px" }}
                    >
                      <option className="dropdown-item roboto-reg-16px">
                        How did you heard about us
                      </option>
                      <option className="dropdown-item roboto-reg-16px">
                        Ad
                      </option>
                      <option className="dropdown-item roboto-reg-16px">
                        Website
                      </option>
                      <option className="dropdown-item roboto-reg-16px">
                        Internet
                      </option>
                      <option className="dropdown-item roboto-reg-16px">
                        Social Media
                      </option>
                    </select>
                  </div>
                </h4>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        {/* <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css"> */}
      
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="./fonts/fontawesome-free-5.8.2-web/css/all.css"
          type="text/css"
        />
       
        <link
          rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
          integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
          crossOrigin="anonymous"
        />
        <title>truGeny</title>
        <link rel="stylesheet" href="./css/fonts.css" />
        <link rel="stylesheet" href="./css/jquery.calendar.css" />
        <link
          rel="icon"
          href="./img/landingpage/Logo.png"
          type="image/gif"
          sizes="16x16"
        />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <style
          dangerouslySetInnerHTML={{
            __html:
              "\n      .background-pay-card{\n        background: url(./img/registration/3-layers.png) no-repeat center;-webkit-background-size: cover;\n        -moz-background-size: cover;\n        -o-background-size: cover;\n        background-size: cover;\n        width:100%;\n        height:500px;\n      }\n      "
          }}
        />
        <link rel="stylesheet" href="./styleinline.css" />
        <link rel="stylesheet" href="./style-mobile2.css" />
        <link rel="stylesheet" href="style-mobile.css" />
        <CommonHeder category="product-page"></CommonHeder>
        <div style={{ paddingBottom: "76px" }} className="padding22" />

        <div style={{ marginTop: "8rem" }} className="margin"></div>
        {renderPages}
     <SubFooter/>
        {}
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
        <MobileFooter/>
      </div>
    );
  }
}
export default OrderTrugene;
