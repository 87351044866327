import React, { Component } from 'react';
import './App.css';
import { withRouter } from 'react-router-dom'
import './styles.css';
import 'react-sticky-header/styles.css';
import Prospect from './components/Prospect';
import Footer from './components/Footer';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import NewMainHeader from './components/NewMainHeader';
import CarouselCards2 from './components/CarouselCards2';
import CarouselCards1 from './components/CarouselCards1';
import StickyContactMenu from './components/StickyContactMenu';
import MetaTags from 'react-meta-tags';
import * as constants from './components/paymentContants';
import "react-multi-carousel/lib/styles.css";
import MobileFooter from './components/MobileFooter';
import ForYouVideo from './components/videos/ForYouVideo';

function initializeReactGA() {
  ReactGA.initialize('UA-148376759-1');
  ReactGA.pageview(constants.trugenyUrl);
  ReactGA.pageview(constants.trugenyUrl+'/about-us');
  ReactGA.pageview(constants.trugenyUrl+'/help');
  ReactGA.pageview(constants.trugenyUrl+'/login');
  ReactGA.pageview(constants.trugenyUrl+'/register');
  ReactGA.pageview(constants.trugenyUrl+'/ordertrugene');
  ReactGA.pageview(constants.trugenyUrl+'/book-counselling');
  ReactGA.pageview(constants.trugenyUrl+'/ThanksListen');

 }
 const tagManagerArgs = {
  gtmId: 'GTM-K27BQ7L'
 }
 TagManager.initialize(tagManagerArgs)

 const responsive = {

  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
class App extends Component {
  constructor(props) {
    super(props);

  }


componentDidMount(){


   if ('serviceWorker' in navigator) {
      window.addEventListener('load', function () {
        navigator.serviceWorker.register('sw.js').then(function (registration) {
          // console.log('Worker registration successful', registration.scope);
        }, function (err) {
          console.log('Worker registration failed', err);
        }).catch(function (err) {
          console.log(err);
        });
      });
    } else {
      console.log('Service Worker is not supported by browser.');
    }
}
toBookSession(){
  window.location.href="/book-counselling"
}
toOrder(){
  window.location.href="/product-page"
}

  toGeneticCouncelling() {
      window.location.href="/genetic-counselling";
    }

  toCancer() {
      window.location.href="/genetic-testing-cancer-risk";
    }
  toHeart() {
      window.location.href="/genetic-testing-heart-disease";
    }
    toFamily() {
        window.location.href="/family-planning";
      }

    toRiskWelcome() {
        window.location.href="/risk_assessment";
      }

  render() {

    return (
      <div>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />


        <title>truGeny</title>
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/header_footer.css" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <link rel="stylesheet" href="./css/sticky.css" />
        <link rel="stylesheet" href="./styleinline.css" />
        <link rel="stylesheet" href="./style-mobile2.css" />
        <link rel="stylesheet" href="style-mobile.css" />


        <MetaTags>
        <title>TruGeny: DNA Test | Genetic Test Kit | Genetic Counselling</title>
		    <meta name="description" content="truGeny helps you to learn about your genes to understand your risks and take actions for preventing diseases and leading a healthier life."/>
        <meta property="og:description" content="truGeny helps you to learn about your genes to understand your risks and take actions for preventing diseases and leading a healthier life."/>

        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta property="og:title" content="TruGeny: DNA Test | Genetic Test Kit | Genetic Counselling"/>
        <meta property="og:site_name" content="truGeny"/>
        <meta property="og:url" content="https://trugeny.com/"/>
        <meta property="og:type" content="website"/>
        <meta property="og:image" content=""/>
    
        {/*16-05-2020*/}
        <meta name="keyword" content="trugeny,genetic counselling,genetic testing,genetic counselor near me,genetic counselling in india,genetic counselor, genetic testing in india,genetic counselling in bangalore, genetic counselors, genetic counselling labs, DNA testing labs in india, genetic counselling services, genetic counselling centres in india, medical laboratory in bengaluru, genetic counsellos in india,"/>
        <meta property="og:keyword" content="trugeny,genetic counselling,genetic testing,genetic counselor near me,genetic counselling in india,genetic counselor, genetic testing in india,genetic counselling in bangalore, genetic counselors, genetic counselling labs, DNA testing labs in india, genetic counselling services, genetic counselling centres in india, medical laboratory in bengaluru, genetic counsellos in india,"/>
        <meta name="ahrefs-site-verification" content="792c51c2f7ba1d925616fec59bb4c4b0dd076bdeef02c8f5742edbda7347d84c"></meta>
     
        </MetaTags>
        <style dangerouslySetInnerHTML={{__html: "\n .card-body {\n     -ms-flex: 1 1 auto;\n     flex: 1 1 auto;\n     padding: .75rem;\n }\n " }} />
       
          <NewMainHeader category="foryou"></NewMainHeader>

          <div class="banner_familyplanning">
      <div class="container">
          <div class="row">
              <div class="col-sm-12">
                <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                          <h1 class="plan-future">Plan For Your Future<br/>
                            Children Better! </h1>
                      </div>
                      <div class="carousel-item">
                        <h1 class="plan-future">Plan For Your Future<br/>
                            Children Better2! </h1>
                      </div>
                      <div class="carousel-item">
                        <h1 class="plan-future">Plan For Your Future<br/>
                            Children Better3! </h1>
                      </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    </button>
                  </div>
              </div>
          </div>
      </div>
  </div>

  <div class="familyplanning1 home_new1 shadow">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="familyplanning2 pt-5 px-5">The ability to learn about your risk of passing on a condition to your child can protect the well being of your future family. Learn how you can protect your family. </p>
          <div class="text-center mb-5">
            <a href="" class="familyplanning3 btn btn-primary">LEARN NOW</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container home_new1">
    <div class="row">
      <div class="col-sm-12 py-5 text-center">
        <p class="familyplanning4">You will be pass on a lot of yours to your children, including your health.</p>
      </div>
    </div>
  </div>

  <div class="container home_new2">
    <div class="row">
      <div class="col-12">
        <p class="familyplanning2 pt-5">The ability to learn about your risk of passing on a condition to your child can protect the well being of your future family. Learn how you can protect your family. </p>
        <div class="text-center mb-5">
          <a href="" class="familyplanning3 btn btn-primary">LEARN NOW</a>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6 px-0">
        <div class="familyplanning5 p-5">
          <p class="familyplanning6 familyplanning8">Did you know? </p>
          <p class="familyplanning7 familyplanning8">Almost all diseases have a genetic<br/>
            factor to them?<br/><br/>                
            Over 80% of babies with genetic disorders such as Cystic Fibrosis, Down syndrome, Thalassemia are born to parents with no known family history of that disease.  </p>
        </div>
      </div>
      <div class="col-sm-6 text-center">
        <img class="img-fluid" src="./img/newpages/Group 176.png"/>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-sm-12 py-5 text-center">
        <p class="familyplanning4">PLANNING FOR A PREGNANCY?</p>
        <p class="familyplanning9 familyplanning10">truGeny empowers you with knowledge about your genes so you can take preventive
          measures against passing on certain conditions to your children</p>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="">
      <div class="row py-5">
        <div class="col-sm-5 text-center" style={{zIndex: '5'}}>
          <img class="img-fluid familyplanning12 pt-4" src="./img/newpages/Illustration6.png"/>
        </div>
        <div class="col-sm-7 px-0">
          <div class="familyplanning11 p-5">
            <p class="familyplanning6 familyplanning13 pl-5 familyplanning14">Pregnancy</p>
            <p class="familyplanning7 familyplanning13 pl-5 familyplanning14">Genes are passed on from a parent to the child. A child gets one set of genes from the father and one set from the mother.
              Knowing your risk of passing on a genetic condition to your Child can make a difference to the well being of your future family.</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row py-5">
      <div class="col-sm-6">
        <p class="familyplanning6 familyplanning13">Tele-Genetic Health Consultation</p>
        <p class="familyplanning7 familyplanning13">As a first step, truGeny helps you learn about your genes and if you and your partner carry any genetic risk. A genetic counselling session with our certified expert genetic counsellors will help you:
          <br/><br/>
          <div class="row">
            <div class="col-2">
              <img src="./img/newpages/7-18-07.png" class="img-fluid"/>
            </div>
            <div class="col-10">
              <ul class="p-0">
                <li class="familyplanning15 familyplanning13 familyplanning7" sty>Understand and Assess your genetic risk and
                  determine if genetic testing is a good option for you</li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              <img src="./img/newpages/7-18-08.png" class="img-fluid"/>
            </div>
            <div class="col-10">
              <ul class="p-0">
                <li class="familyplanning16 familyplanning13 familyplanning7 pt-2">Select the right genetic test</li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              <img src="./img/newpages/7-18-09.png" class="img-fluid"/>
            </div>
            <div class="col-10">
              <ul class="p-0">
                <li class="familyplanning17 familyplanning13 familyplanning7 pt-2">Make the most informed decisions for a healthy pregnancy</li>
              </ul>
            </div>
          </div>
         
        </p>
        <div class="row">
          <div class="col-2"></div>
          <div class="col-10">
            <div class=" mb-5 mt-3">
              <a href="" class="familyplanning3 btn btn-primary">SCHEDULE APPOINTMENT</a>
            </div>
          </div>
        </div>
        
      </div>
      <div class="col-sm-6 text-center">
        <img class="img-fluid " src="./img/newpages//Illu_4.png"/>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-sm-12 py-5 text-center">
        <p class="familyplanning4 pb-4">Watch to Learn More!</p>
        <iframe  class="iframevieo" src="https://www.youtube.com/embed/TGWIGNSgdIA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <p class="familyplanning4 py-5">I WANT TO LEARN ABOUT</p>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 text-center">
        <img class="img-fluid" src="./img/newpages/Illustration4.png"/>
        <div class=" mb-5 mt-5">
          <a href="" class="familyplanning18 btn btn-primary">Genetic Counselling</a>
        </div>
      </div>
      <div class="col-sm-6 text-center">
        <img class="img-fluid" src="./img/newpages/Illustration5.png"/>
        <div class=" mb-5 mt-5">
          <a href="" class="familyplanning18 btn btn-primary">Family Planning</a>
        </div>
      </div>
    </div>
  </div>
  
  <div class="familyplanning19 mt-5">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <p class="familyplanning4 py-5">truFamily. A Highly Advanced Genetic Screening Test</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 text-center">
          <img class="img-fluid" src="./img/newpages/Box.png"/>
        </div>
        <div class="col-sm-6 ">
          <div class="row pt-4">
            <div class="col-2  text-center"><img class="img-fluid" src="./img/newpages/Group 87.png"/></div>
            <div class="col-10 ">
              <p class="familyplanning6 familyplanning13">truFamily</p>
              <p class="familyplanning7 familyplanning13">Carrier Screening Test</p>
            </div>
          </div>
          <p class="familyplanning7 familyplanning13 familyplanning20">truFamily Carrier screening test will help you and your spouse
            find out if you are a carrier for certain inherited conditions</p>
             
        </div>
      </div>
      <div class="row py-5">
        <div class="col-12 text-center">
          <a href="" class="familyplanning3 btn btn-primary">ORDER TEST</a>
        </div>
      </div> 
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-sm-12 py-5 text-center">
        <p class="familyplanning4">IT'S AN EASY AND SIMPLE PROCESS</p>
        <p class="familyplanning9 familyplanning10">What do you GET?</p>
        <p class="familyplanning9 familyplanning10 pt-3">It starts with a conversation </p>
      </div>
    </div>
    <div class="row py-5">
      <div class="col-sm-3 text-center">
        <img class="img-fluid familyplanning21" src="./img/newpages/Group 210.png"/>
        <p class="familyplanning7 familyplanning13 pt-4">1. SCHEDULE AN<br/>APPOINTMENT</p>
      </div>
      <div class="col-sm-3 text-center">
        <img class="img-fluid familyplanning21" src="./img/newpages/Group 211.png"/>
        <p class="familyplanning7 familyplanning13 pt-4">2. INITIAL<br/> CONSULTATION</p>
      </div>
      <div class="col-sm-3 text-center">
        <img class="img-fluid familyplanning21" src="./img/newpages/Group 212.png"/>
        <p class="familyplanning7 familyplanning13 pt-4">3. SAMPLE<br/>COLLECTION</p>
      </div>
      <div class="col-sm-3 text-center">
        <img class="img-fluid familyplanning21" src="./img/newpages/Group 210.png"/>
        <p class="familyplanning7 familyplanning13 pt-4">4. RESULTS<br/> CONSULTATION</p>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 py-5 text-center">
        <p class="familyplanning4 mb-5">GET A 15 MIN FREE HEALTH CONSULTATION! </p>
        <a href="" class="familyplanning3 btn btn-primary">BOOK MY APPOINTMENT</a>
      </div>
    </div>
  </div>

<div class="familyplanning22 py-5 mb-5">
    <div class="container">
      <div class="row py-4">
        <div class="col-12">
          <div class="familyplanning23 text-center">
            <p class="familyplanning6 mb-0 py-5 ">Get your health risk score <img class="img-fluid" src="./img/newpages/Group 16.png"/> </p>
          </div>
        </div>
      </div>
    </div>
  </div>



    
        <Prospect></Prospect>



       <Footer></Footer>
        <div className="modal fade bd-example-modal-lg" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content" style={{borderRadius: '40px'}}>
              <div className="modal-header bg-blue clearfix border-0" style={{borderTopRightRadius: '40px', borderTopLeftRadius: '40px'}}>
                <h5 className="modal-title" id="exampleModalLongTitle" style={{paddingTop: '5px', paddingBottom: '25px', color: '#2c333c', fontSize: '30px', fontWeight: 700}}>
                  Start your journey in 3 easy steps</h5>
                <button type="button" className="text-center close" data-dismiss="modal" aria-label="Close" style={{paddingTop: '25px', paddingBottom: '25px'}}>
                  <img className="img-fluid" src=".\img\registration\X.png" />
                </button>
                {/* <p class="float-left">Question 1 of 3</p> */}
              </div>
              <div className="bg-blue border-bottom-1">
                <p className="pl-3">Question 1 of 3</p>
              </div>
              <div className="modal-body pb-3 pt-0 m-0">
                <div className="container ">
                  <br />
                  <div className="tab-content">
                    <div id="home" className="container tab-pane active">
                      <br />
                      <h3>How do you indentify your gender?</h3>
                      <hr />
                      <div className="d-flex d-flex-inline">
                        <div className="float-right pt-2 mt-2 mr-5">
                          <img className="btn-hover-white" src="./img/male.png" alt="" style={{borderRadius: '50%', height: '68px', width: '88px'}} />
                          <img className="p-2 pl-3 btn-hover-white" src="./img/female.png" alt="" style={{borderRadius: '50%', height: '68px', width: '88px'}} />
                        </div>
                      </div>
                      <div className="float-right pt-2 mt-2 ml-5">
                        <button type="button" className=" btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill" href="#menu1">Next</button>
                      </div>
                    </div>
                    <div id="menu1" className=" m-0 p-0 container tab-pane fade">
                      <br />
                      <h3>Does anyone in your family have 2 diabetes?</h3>
                      <hr />
                      <h4>Certain ethnic groups have higher risk for diabetes,<br />
                        even at lower body weights.</h4>
                      <div className="col-xs-12 mt-2 ">
                        <div className="input-group-prepend " style={{border: 'solid 1px #ebebeb', maxWidth: '312px'}}>
                          <input type="text" placeholder="Select your ethnicity" className="form-control roboto-reg-16px" style={{border: 'none', height: '60px'}} aria-label="Text input with dropdown button" />
                          <button className="m-0 p-0 btn btn-outline-secondary roboto-bold-14px " href="#" style={{border: 'none', backgroundColor: 'white'}} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img className="m-0 p-0" src="img/q&a/carot.png" /></button>
                        </div>
                      </div>
                      <div className="float-right pt-2 mt-2">
                        <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill" href="#menu2">Next</button>
                      </div>
                    </div>
                    <div id="menu2" className=" m-0 p-0 container tab-pane fade">
                      <br />
                      <h3>Does anyone in your family have 2 diabetes?</h3>
                      <hr />
                      <div className="radio">
                        <label>
                          <input type="radio" name="optradio" defaultChecked /> Parents</label>
                      </div>
                      <div className="radio">
                        <label>
                          <input type="radio" name="optradio" /> Siblings</label>
                      </div>
                      <div className="float-right pt-2 mt-2">
                        <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill" href="#menu3">Next</button>
                      </div>
                    </div>
                    <div id="menu3" className=" m-0 p-0 container tab-pane fade">
                      <div className="gc-form-card container">
                        <div className="gc-form-card-wrap  ">
                          <div className=" gc-form-card-top">
                            <h2 className="text-center pt-0"> REACH OUT TO LEARN MORE</h2>
                            <p className=" gc-form-subtitle text-center">All you need to do is,fill out the form below and one of our experts will reach out to you very soon. </p>
                            {/* <p class="text-center" > sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p> */}
                          </div>{/* gc-form-card-top */}
                          <div className="gc-form-card-btm">
                            <form className="gc-form needs-validation" action="/office//trugene-final/insert2.php" method="post" >
                              <div className="form-row justify-content-center">
                                <div className="gc-col  col-md-5 mb-3" htmlFor="validationTooltip01">
                                  <div className="input-group ">
                                    <input type="text" name="name" className="form-control m-0" id="validationTooltip011" placeholder="Name" required />
                                    <div className="valid-tooltip">
                                      Looks good!
                                    </div>
                                    <div className="input-group-prepend border-0">
                                      <span className="input-group-text border-0" id="validationTooltip01"><img src="img/homepage/i1.png" /></span>
                                    </div>
                                  </div>
                                </div>
                                <div className="gc-col col-md-5 mb-3">
                                  <div className="input-group">
                                    <input type="text" name="reason" className="form-control m-0" id="validationTooltip021" placeholder="Reason" defaultValue="Reason" required />
                                    <div className="valid-tooltip">
                                      Looks good!
                                    </div>
                                    <div className="input-group-prepend">
                                      <span className="input-group-text" id="validationTooltip02"><img src="img/homepage/i2.png" /></span>
                                    </div>
                                  </div>
                                </div>
                              </div>{/* end of form-row */}
                              <div className="form-row justify-content-center">
                                <div className=" gc-col col-md-5 mb-3">
                                  <div className="input-group">
                                    <input type="email" name="email" className="form-control m-0" id="validationTooltipEmailPrepend1" placeholder="E-mail ID" aria-describedby="validationTooltipEmailPrepend" required />
                                    <div className="invalid-tooltip">
                                      Please enter a valid E-mail ID.
                                    </div>
                                    <div className="input-group-prepend">
                                      <span className="input-group-text" id="validationTooltipEmailPrepend"><img src="img/homepage/i3.png" /></span>
                                    </div>
                                  </div>
                                </div>
                                <div className="gc-col col-md-5 mb-3">
                                  <div className="input-group">
                                    <input type="text" name="refered" className="form-control m-0" id="validationTooltip03" placeholder="Referred by" defaultValue="Referred by" aria-describedby="validationTooltip03" required />
                                    <div className="valid-tooltip">
                                      Looks good!
                                    </div>
                                    <div className="input-group-prepend">
                                      <span className="input-group-text" id="validationTooltip03"><img src="img/homepage/i4.png" /></span>
                                    </div>
                                  </div>
                                </div>
                              </div>{/* end of form-row */}
                              <div className="form-row justify-content-center">
                                <div className="gc-col col-md-5 mb-3">
                                  <div className="input-group">
                                    <input className="form-control m-0" type="tel" id="validationTooltip051" placeholder="Phone number" name="phone" pattern="[0-9]{10}" required />
                                    <div className="invalid-tooltip">
                                      Please provide a valid Phone number.
                                    </div>
                                    <div className="input-group-prepend">
                                      <span className="input-group-text" id="validationTooltip05"><img src="img/homepage/i5.png" /></span>
                                    </div>
                                  </div>
                                </div>
                                <div className="gc-col col-md-5 mb-3">
                                  <div className="input-group">
                                    <textarea name="message" className="form-control m-0 " id="validationTextarea" rows={1} placeholder="Message" required defaultValue={""} />
                                  </div>
                                </div>
                              </div>{/* end of form-row */}
                              <div className=" pt-3 mt-4 pb-5">
                                <button className="center-block btn btn-outline-primary btn-hover-white  my-sm-0 center" style={{height: '60px', width: '210px', borderRadius: '30px'}} name="submit" type="submit"><b>SUBMIT</b></button>
                              </div>
                            </form>
                          </div>{/* end of gc-form-card-btm */}
                        </div>{/* end of gc-form-card-wrap */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* sticky form---------------- */}
              {/* <div class="modal-footer">
        <button type="button" class="btn btn-secondary"  data-toggle="pill" href="#menu2">Close</button>
        <button type="button" class="btn btn-primary"  data-toggle="pill" href="#menu1">Next</button>
      </div> */}
            </div>
          </div>
        </div>
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}

        <MobileFooter></MobileFooter>

      </div>

    );
  }
}

export default withRouter(App);
