import React, { Component } from "react";

import Carousel from "react-bootstrap/Carousel";
class GeneticCounsellingCarouselCards2 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-ride="false"
      >
        <div className="carousel-inner new">
          <Carousel controls={true} interval={false} indicators={false}>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="splitter col-sm-12 col-md-6 col-lg-6 col-xl-3 ">
                  <div
                    className="card card-1 rounded newcard"
                    style={{ height: "375px !important" }}
                  >
                    <div className="card-body  text-center ">
                    <div className="text-center">
                      <span className="card-number op-no-1">1</span>
                      </div>
                      <div className=" op-img-box  ">
                        <span className="op-img-wrap ">
                          <img
                            className="card-img-top"
                            src="heart-img/consult-icon.png"
                            alt="Card image cap"
                          />
                        </span>
                      </div>
                      <h5 className="card-title">Initial Consultation</h5>
                      <p className="d-none">
                        Our genetic counsellors will review your personal and
                        family health history, answer your questions and explain
                        disease risks if any.
                      </p>
                    </div>
                  </div>
                  {/* eo card */}
                </div>
                {/* eo splitter */}
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="splitter col-sm-12 col-md-6 col-lg-6 col-xl-3">
                  <div
                    className="card card-2 rounded newcard"
                    style={{ height: "375px !important" }}
                  >
                    <div className="card-body  text-center ">
                    <div className="text-center">
                      <span className="card-number op-no-2">2</span>
                      </div>
                      <div className=" op-img-box">
                        <span className="op-img-wrap">
                          <img
                            className="card-img-top"
                            src="heart-img/call-out-icon.png"
                            alt="Card image cap"
                          />
                        </span>
                      </div>
                      <h5 className="card-title pb-xl-5">Discussion</h5>
                      <p className="d-none">
                        Based on your risk, your counsellor will help you select
                        and order the right test for you and your family.
                      </p>
                    </div>
                  </div>
                  {/* eo card */}
                </div>
                {/* eo splitter */}
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="splitter col-sm-12 col-md-6 col-lg-6 col-xl-3 ">
                  <div
                    className="card card-3 rounded newcard"
                    style={{ height: "375px !important" }}
                  >
                    <div className="card-body  text-center">
                    <div className="text-center">
                      <span className="card-number op-no-3">3</span>
                      </div>
                      <div className=" op-img-box">
                        <span className="op-img-wrap">
                          <img
                            className="card-img-top"
                            src="heart-img/sample-collection.png"
                            alt="Card image cap"
                          />
                        </span>
                      </div>
                      <h5 className="card-title">sample collection</h5>
                      <p className="d-none">
                        If you decide to have testing done, we will ship the
                        sample collection kit to your preferred address..
                      </p>
                    </div>
                  </div>
                  {/* eo card */}
                </div>
                {/* eo splitter */}
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="splitter col-sm-12 col-md-6 col-lg-6 col-xl-3">
                  <div
                    className="card card-4 rounded newcard"
                    style={{ height: "375px !important" }}
                  >
                    <div className="card-body  text-center">
                    <div className="text-center">
                      <span className="card-number op-no-4">4</span>
                      </div>
                      <div className=" op-img-box  ">
                        <span className="op-img-wrap">
                          <img
                            className="card-img-top"
                            src="heart-img/results-icon.png"
                            alt="Card image cap"
                          />
                        </span>
                      </div>
                      <h5 className="card-title">report consultation</h5>
                      <p className="d-none">
                        Your genetic counsellor will review your test report,
                        explain in detail on what it means, answer your
                        questions, guide you on next steps.
                      </p>
                    </div>
                  </div>
                  {/* eo card */}
                </div>
                {/* eo splitter */}
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    );
  }
}
export default GeneticCounsellingCarouselCards2;
