import React from 'react'
import NewMainHeader from './NewMainHeader';
import Footer from './Footer';
import { Collapse } from 'reactstrap';
import $ from 'jquery';
import MobileFooter from './MobileFooter';
import StickyContactMenu from './StickyContactMenu';

class FaqsComponent extends React.Component{
    constructor(props) {
        super(props);

        this.state = {

          q1:true,
          b1:false,

          q2:false,
          b2:true,

          q3:false,
          b3:true,

          q4:false,
          b4:true,

          q5:false,
          b5:true,

          q6:false,
          b6:true,

          q7:false,
          b7:true,

          q8:false,
          b8:true,

          q9:false,
          b9:true,



        }
        this.toggle1 = this.toggle1.bind(this);
        this.toggle2 = this.toggle2.bind(this);
        this.toggle3 = this.toggle3.bind(this);
        this.toggle4 = this.toggle4.bind(this);
        this.toggle5 = this.toggle5.bind(this);
        this.toggle6 = this.toggle6.bind(this);
        this.toggle7 = this.toggle7.bind(this);
        this.toggle8 = this.toggle8.bind(this);
        this.toggle9 = this.toggle9.bind(this);





    }

    toggle1() {
      this.setState(state => ({ q1: !state.q1 }));
      this.setState(state => ({ b1: !state.b1 }));


      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});
      this.setState({q6: false}, function () {});
      this.setState({b6: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q8: false}, function () {});
      this.setState({b8: true}, function () {});
      this.setState({q9: false}, function () {});
      this.setState({b9: true}, function () {});


    }

    toggle2() {
      this.setState(state => ({ q2: !state.q2 }));
      this.setState(state => ({ b2: !state.b2 }));

      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});
      this.setState({q6: false}, function () {});
      this.setState({b6: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q8: false}, function () {});
      this.setState({b8: true}, function () {});
      this.setState({q9: false}, function () {});
      this.setState({b9: true}, function () {});

    }

    toggle3() {
      this.setState(state => ({ q3: !state.q3 }));
      this.setState(state => ({ b3: !state.b3 }));

      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});
      this.setState({q6: false}, function () {});
      this.setState({b6: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q8: false}, function () {});
      this.setState({b8: true}, function () {});
      this.setState({q9: false}, function () {});
      this.setState({b9: true}, function () {});

    }

    toggle4() {
      this.setState(state => ({ q4: !state.q4 }));
      this.setState(state => ({ b4: !state.b4 }));

      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});
      this.setState({q6: false}, function () {});
      this.setState({b6: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q8: false}, function () {});
      this.setState({b8: true}, function () {});
      this.setState({q9: false}, function () {});
      this.setState({b9: true}, function () {});

    }
    toggle5() {
      this.setState(state => ({ q5: !state.q5 }));
      this.setState(state => ({ b5: !state.b5 }));

      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});
      this.setState({q6: false}, function () {});
      this.setState({b6: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q8: false}, function () {});
      this.setState({b8: true}, function () {});
      this.setState({q9: false}, function () {});
      this.setState({b9: true}, function () {});

    }


    toggle6() {
      this.setState(state => ({ q6: !state.q6 }));
      this.setState(state => ({ b6: !state.b6 }));

      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q8: false}, function () {});
      this.setState({b8: true}, function () {});
      this.setState({q9: false}, function () {});
      this.setState({b9: true}, function () {});

    }


    toggle7() {
      this.setState(state => ({ q7: !state.q7 }));
      this.setState(state => ({ b7: !state.b7 }));

      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});
      this.setState({q6: false}, function () {});
      this.setState({b6: true}, function () {});
      this.setState({q8: false}, function () {});
      this.setState({b8: true}, function () {});
      this.setState({q9: false}, function () {});
      this.setState({b9: true}, function () {});
    }



    toggle8() {
      this.setState(state => ({ q8: !state.q8 }));
      this.setState(state => ({ b8: !state.b8 }));

      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});
      this.setState({q6: false}, function () {});
      this.setState({b6: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q9: false}, function () {});
      this.setState({b9: true}, function () {});

    }



    toggle9() {
      this.setState(state => ({ q9: !state.q9 }));
      this.setState(state => ({ b9: !state.b9 }));

      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});
      this.setState({q6: false}, function () {});
      this.setState({b6: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q8: false}, function () {});
      this.setState({b8: true}, function () {});

    }





    componentDidMount() {

      $(document).ready(function(){
        $("#myInput").on("keyup", function() {
          var value = $(this).val().toLowerCase();
          $("#myData h5").filter(function() {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
          });

        });
      });
    }

    toGeneticHelp(){
      window.location.href = "/genetic-help";

    }

   render(){


       return(
        <div>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
        {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.2/css/all.css" integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay" crossorigin="anonymous"> */}
        <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>truGeny</title>
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/header_footer.css" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <link rel="stylesheet" href="./css/sticky.css" />
        <link rel="stylesheet" href="newsticky.css" />
        <style dangerouslySetInnerHTML={{__html: "\n/* @media only screen and (min-width: 992px)\n/* {\n.custom-pad-mar {\n  padding-left:25rem;\n  padding-right:25rem;\n\n} */\n\n.activenavbar{\n  color:#4f5be7!important;\n}\n.card-body{\n  text-align: left!important;\n}\n.heart-banner-sec-wrap .heart-banner-sec{\n  background: url(./img/help/bg1.png);\n  background-position:center;\n  background-size: cover;\n  background-repeat: no-repeat;\n}\n.i-button{\n  display: inline-block;\nborder-radius: 60px;\nbox-shadow: 0px 0px 2px #888;\npadding: 0.5em 0.6em;\n}\n.heart-banner-sec-wrap .heart-banner-sec{\n  background: url(./img/help/banner-gc.png);\n  background-position:center;\n  background-size: cover;\n  background-repeat: no-repeat;\n}\n" }} />

        <section className="container heart-banner-sec-wrap banner-position">
        <section className="genetic-cause-sec-mainn ">
          <div className="genetic-cause-sec-wrap">
            <div className="genetic-cause-header-wrap">
              <h2 className="genetic-cause-header genetics-h2 pt-0 text-center">
                Most diseases have a genetic cause.
              </h2>
              <p className="genetic-cause-subtitle genetics-h2-p text-center">Our genetic counsellors can help evaluate if you’re at risk, and if genetic testing may be right for you.</p>
            </div>{/* eo gr-header */}
            <div className="questions-btn text-center mx-auto">
              <h3 className="TruGeneColor"><b>Find answers to common questions.</b></h3>
            </div>


          </div>{/* genetic-cause-sec-wrap */}
        </section>
          <div className=" mt-1">
            <div className="text-center">
              <div className="card-block  p-2 circle">
                <div className="pr-5 pb-5 pl-5 hc-accordian-wrap">
                  <div className="accordion" id="accordionIhc">
                    <div className="card">
                      <div className="card-header" id="headingOne">
                        {/* <a href="help_indivisual.html" style="color:#4f5be7"> */}
                        <h5 className="acc-header collapse-color mb-0">
                          Who is a genetic counsellor?
                          {this.state.b1?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle1}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle1} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q1}>
                      <div id="a1" className="collapse show " aria-labelledby="headingOne" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>Genetic counselors are board certified professionals with specialized education
and training in genetics and counseling. They assist individuals and patients to
make the right decision about their health and wellness based on the genetic
make-up. Genetic counsellors evaluate risk based on personal and family health
history, explain their inheritance pattern in families, help in selecting the right
genetic test and interpreting test results to make informed decisions regarding
their health.</p>

                        </div>
                      </div>
                      </Collapse>

                    </div>
                    <div className="card">
                      <div className="card-header" id="headingTwo">
                        <h5 className="acc-header collapse-color mb-0">
                          {/* <a href="help_indivisual.html" style="color:#4f5be7"> */}
                          What information and details do i need to provide before my genetic counselling appointment?
                          {this.state.b2?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle2}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle2} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q2}>

                      <div id="a2" className="collapse show " aria-labelledby="headingTwo" data-parent="#accordionIhc">
                        <div className="card-body">
                          {/* <a href="help_indivisual.html" style="color:#000"> */}
                          <p>Genetic counselling (GC) sessions begin with a conversation with the individual /
patient, which typically happens over audio or video in a telegenetics session.
The components of a typical GC session include:</p><br />
                          <p>Additionally, before your appointment you may want to prepare a list of questions, so that your counselling appointment time
                            is well utilised and help the counsellor to focus and address on a specific concern area.</p>

                          <ul>
                            <li>Understanding the purpose of counselling session</li>
                            <li>Taking detailed medical and family health history</li>
                            <li>Making note of relevant environmental and lifestyle risk factors</li>
                            <li>Providing risk assessment and explaining inheritance pattern</li>
                            <li>Recommending and choosing the appropriate genetic test</li>
                            <li>Interpreting and explaining the test results</li>
                            <li>Sharing information regarding routine health assessment, healthy life-style
recommendations and instituting preventive and management measures under
medical guidance</li>
                            <li>Addressing psychosocial issues and emotional concerns</li>
                            <li>Drawing up plans for further course of action</li>


                            </ul>
                        </div>
                      </div>

                      </Collapse>

                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        {/* <a href="help_indivisual.html" style="color:#4f5be7"> */}
                        <h5 className="acc-header collapse-color mb-0">
                        What is pre-test genetic counselling?
                          {this.state.b3?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle3}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle3} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q3}>
                      <div id="a3" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          {/* <a href="help_indivisual.html" style="color:#000"> */}
                          <p>A pre-test genetic counseling refers to counseling carried out prior to undertaking
genetic testing wherein the counsellor reviews your personal and family health history in
detail, explains how the genetic condition can be passed on in families, evaluates the
need for genetic testing and explains the advantages and disadvantages of genetic
testing including psychosocial implications to you and your family. If found necessary,
the counsellor helps to choose the right genetic test for you, explain the process of
sample collection and processing as well as what to expect regarding performance of
the genetic test. Pretest counseling should be done in a clear, objective, and
nondirective fashion, which allows patients sufficient time to understand information and

make informed decisions regarding testing and further evaluation or treatment. The
outcome of a genetic test can help individuals / patients and their treating physicians to
make informed decisions regarding their health and wellness. A detailed report with a
summary of the pre-test counselling session will be given to the counsellee.</p>
                        </div>
                      </div>
                      </Collapse>

                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        <h5 className="acc-header collapse-color mb-0">
                          {/* <a href="help_indivisual.html" style="color:#4f5be7"> */}
                          What is post-test genetic counselling?
                          {this.state.b4?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle4}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle4} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q4}>
                      <div id="a4" className="collapse show" aria-labelledby="headingFour" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>A post-test genetic counseling takes place between individuals / patients along with
close family members in the presence of a genetic counsellor. This session involves a
brief review of the counselee’s medical and family history, explanation about the genetic
test results in detail, the associated risk to the individual / patient / couple planning a
pregnancy / foetus and what those results may mean to them and their family. During
the counselling session, the counsellor will recommend consultation with a Physician
who can plan a personalized health screening schedule for prevention or individualized
treatment plan for informed management of disease based on the test results.
Alternatives regarding reproductive options and prenatal diagnostics will be explained
to couples if necessary. Psychological and emotional support can be provided.
Depending on the context and the disease being tested, follow-up genetic counselling
sessions may be offered. Information material regarding the associated genetic
condition / diseases will be given to help the counsellee share with other members of
the family. Further, information on patient support organisations may be offered.
Following the counselling session, a detailed post-test report will be given to the
counsellee.</p>
                        </div>
                      </div>
                      </Collapse>

                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingFive">
                        <h5 className="acc-header collapse-color mb-0">
                          {/* <p style="color:#4f5be7"> */}
                          Why and when should I undergo genetic counselling?
                          {this.state.b5?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle5}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle5} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q5}>
                      <div id="a5" className="collapse show" aria-labelledby="headingFive" data-parent="#accordionIhc">
                        <div className="card-body">
                          {/* <a href="help_indivisual.html" style="color:#000"> */}
                          <p>You should undergo genetic counselling if you have concerns for yourself or a family
member regarding lifestyle diseases like cancer or heart disease either because they
run in the family or you have one or more environmental risk factors or you as a couple
are planning a pregnancy and are anxious to have a normal baby. Genetic counselling
can help in diagnosis, estimation of risk and for implementing prevention and optimal
management of genetic conditions.
Genetic counselling can be availed in a variety of situations. You should undergo
genetic counselling if-</p>


                          <ul>
                            <li>You are pregnant, or planning a pregnancy and concerned about your baby’s
health</li>
                            <li>You have difficulty in conceiving or had multiple abortions</li>
                            <li>You are concerned about any inherited condition occurring in your family and
want to know if your baby carries a risk of having that condition.</li>
                            <li>You are pregnant, had genetic testing and received an abnormal test result.</li>
                            <li>You, your child or a family member has been diagnosed with a rare disease of
unknown cause</li>
                            <li>There is a history of some birth defects or other genetic conditions in your
family</li>
                            <li>A close family member has been diagnosed with cancer or heart disease
under 60 years of age or has any other disease condition that could be be due
to an inherited gene and you are worried if you or your children could have
inherited that genetic abnormality</li>
                            <li>You are interested in knowing your risk for cancers or heart disease and take
proactive steps towards prevention</li>
                            <li>Any of your family members have a suspected genetic condition and you are
seeking counselling on their behalf to advise them appropriately.</li>


                            </ul>
                        </div>
                      </div>
                      </Collapse>

                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingFive">
                        <h5 className="acc-header collapse-color mb-0">
                          {/* <p style="color:#4f5be7"> */}
                        What is genetic counselling for cancers ?
                          {this.state.b6?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle6}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle6} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q6}>
                      <div id="a6" className="collapse show" aria-labelledby="headingSix" data-parent="#accordionIhc">
                        <div className="card-body">
                          {/* <a href="help_indivisual.html" style="color:#000"> */}
                          <p>Some types of cancers can run in families. If you have had cancer at a young age, had
two or more different cancers, or have several family members with cancer, you may
consider genetic counseling followed by genetic testing, if necessary. During the
counselling process, the genetic counsellor will conduct a detailed evaluation of your
family’s health history and your personal health history to evaluate your likelihood of
having cancer in your lifetime. If genetic testing is found useful for you, the counsellor
will recommend and select the right genetic test. The counsellor can also suggest
cancer screening and management options for those at risk, aimed at proactive
prevention.</p>
                        </div>
                      </div>
                      </Collapse>

                    </div>

                    <div className="card ">
                      <div className="card-header" id="headingFive">
                        <h5 className="acc-header collapse-color mb-0">
                          {/* <p style="color:#4f5be7"> */}
                          What is genetic counselling for heart disease?
                          {this.state.b7?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle7}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle7} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q7}>
                      <div id="a7" className="collapse show" aria-labelledby="headingSeven" data-parent="#accordionIhc">
                        <div className="card-body">
                          {/* <a href="help_indivisual.html" style="color:#000"> */}
                          <p>During the genetic counselling process for heart disease, a detailed evaluation of your
family and personal health history is done to understand your lifetime risk of developing
heart disease. Your family health history provides information regarding presence of
heart disease (treated for severe chest pain / had angioplasty or stenting / underwent
bypass or open heart surgery) among your close relatives (siblings, parents) at a young
age (less than 60 years). For personal health history, the counsellor will ask for
information regarding your environmental and lifestyle risk factors (uncontrolled
diabetes, high blood pressure or cholesterol, obesity, exercise and eating pattern,
smoking and drinking habits etc). Based on the above information, the counsellor will
recommend genetic testing for you. Based on your genetic risk score (polygenic risk
score), your doctors can suggest appropriate proactive measures such as routine health
screening, healthy lifestyle changes including exercise and diet and prophylactic drugs
to lower your risk for heart diseases.</p>
                        </div>
                      </div>
                      </Collapse>

                    </div>


                    <div className="card ">
                      <div className="card-header" id="headingFive">
                        <h5 className="acc-header collapse-color mb-0">
                          {/* <p style="color:#4f5be7"> */}
                          What is genetic counselling for pregnancy planning?
                          {this.state.b8?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle8}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle8} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q8}>
                      <div id="a7" className="collapse show" aria-labelledby="headingEight" data-parent="#accordionIhc">
                        <div className="card-body">
                          {/* <a href="help_indivisual.html" style="color:#000"> */}
                          <p>Genetic counselling can be availed by couples at various stages of family planning, both
prior to and during prenatal phase to ensure that you have the best chances for a
normal baby. The genetic counsellor will undertake detailed evaluation of your previous
reproductive history, menstrual cycles, miscarriages, hormonal treatment,
consanguinity, history of any genetic condition / birth defects in the family to understand
your risks and chances of a normal baby. The counselor will also explain about genetic
disorders and their inheritance pattern, prepare for any risks of the condition as well as
share published guidelines for a healthy pregnancy. In the event of an abnormal genetic
result in you or your spouse, the counsellor will explain about assisted reproductive
techniques. As such, genetic counselling can be of help from the stage of family
planning up to the time of delivery and beyond.</p>
                        </div>
                      </div>
                      </Collapse>

                    </div>

                    <div className="card ">
                      <div className="card-header" id="headingFive">
                        <h5 className="acc-header collapse-color mb-0">
                          {/* <p style="color:#4f5be7"> */}
                        What is prenatal genetic counselling?
                          {this.state.b9?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle9}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle9} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q9}>
                      <div id="a9" className="collapse show" aria-labelledby="headingnine" data-parent="#accordionIhc">
                        <div className="card-body">
                          {/* <a href="help_indivisual.html" style="color:#000"> */}
                          <p>Prenatal (Pregnancy-related) genetic counselling is carried out either while planning for
a pregnancy or during pregnancy in order to provide the couple with the best chances of
having a normal healthy baby. Prenatal counselling can include assessment of specific
risks for a pregnancy based on family history and personal reproductive history as well
as evaluation of options for genetic testing, screening and / or assisted reproductive
methods.
You can avail prenatal genetic counselling prior to genetic testing if you have a family
history of some birth defect and are worried or if you are 35 years or older and
considering pregnancy, if you have received a genetic test result that is of concern or if
your doctor has recommended you to avail standard prenatal screening to rule out
genetic conditions like Down syndrome or spina bifida.
During a typical prenatal counselling session, your genetic counsellor will ask questions
to understand your concerns about your pregnancy, explore your medical and family
health history and discuss your risk of having a child with a genetic condition, clarify
genetic screening and testing options before and during pregnancy, explain the benefits
and disadvantages of testing, help in selecting the appropriate test for you, explain the
implications of the test results to you and your baby including alternate reproductive
options as well as provide you with emotional support and allay your concerns and
relieve you from stress and anxiety. In the event, you are found to be carrying a baby
with birth defect or a genetic condition, the counsellor will explain the medical
implications / complications about the condition, help you prepare for the birth of a child
with special needs as well as discuss options such as pregnancy termination or
adoption.</p>
                        </div>
                      </div>
                      </Collapse>

                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="questions-btn text-center mx-auto">
          <a className="btn btn-outline-primary btn-hover-white rounded-pill text-center " onClick={this.toGeneticHelp} role="button" style={{backgroundColor: '#4f5be7', color: 'white'}}>More Faq's </a>
          </div>

        </section>

      </div>


       )
   }

}
export default FaqsComponent;
