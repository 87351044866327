import React from 'react';
import Cookies from 'js-cookie';
import CommonHeder from './CommonHeder';
import MobileFooter from './MobileFooter';
import Currency from 'react-currency-formatter';
import axios from '../axios/axios';
import 'jspdf-autotable';
import SubFooter from './SubFooter';
import {Redirect}  from 'react-router-dom';

class BBPaymentSuccess extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            dropdownOpen: false,

            productId:'',
            appointmentDetails:[],
            userData: [],
            orderDetails:[],
            redirect: false        }
      


    }


  componentDidMount(){

        axios.get('schedule/getAllAppointmentDetails/'+Cookies.get("userId"), 
        { headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` } }
        ).then((response) => {
           if(response.data){this.setState({appointmentDetails:response.data},()=>{})}
           axios.get('cartservice/'+response.data[0].appointmentId+'/lastOrderDetails', 
           { headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` } }
           ).then((response) => {
              if(response.data){this.setState({orderDetails:response.data},()=>{})}
             }).catch(error => {});
          }).catch(error => {});



          axios.get('/users/user/' + Cookies.get('userId'), {
            headers: { Authorization: `Bearer ${Cookies.get('jwt-token')}` }
            , 'Accept': 'application/json',
            'Content-Type': 'application/json'
          })
            .then(response => {
              this.setState({
               
               userData:response.data
      
              })
      
            })
            .catch(function (error) {
             
            });


            setTimeout(() => this.setState({ redirect: true }), 12000)
  }
  
  toProductPage(){
    window.location.href="/product-page"
  }
  render() {

    
 
    return (
       this.state.redirect?<Redirect to="/your-reports"/>:
        <div>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese" rel="stylesheet" />
        <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
        {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossorigin="anonymous"> */}
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>trueGene</title>
        <link rel="icon" href="./img/landingpage/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./style-mobile2.css" />
        <style dangerouslySetInnerHTML={{__html: "\n      .background-pay-card{\n        background: url(./img/registration/3-layers.png) no-repeat center;-webkit-background-size: cover;\n        -moz-background-size: cover;\n        -o-background-size: cover;\n        background-size: cover;\n        width:100%;\n        /* height:500px; */\n      }\n      .checked {\n  color: orange;}\n      " }} />
        {/* --------------------------------------------------------------------------------------------head section end-------------------------------------------------------------------------------------------- */}
        <CommonHeder category="product-page"></CommonHeder>
        <div style={{paddingBottom: '76px'}} className="padding22" />
        <div style={{marginTop: '6rem'}} className="margin"></div>

        <div className="container " style={{boxShadow: '0 17px 40px rgba(92, 103, 108, 0.2)', borderRadius: '40px', backgroundColor: '#eff7ff'}}>
          <div className="row">
            <div className="col-sm-6 col-lg-8 background-pay-card d-flex justify-content-between py-0 px-0" style={{backgroundColor: '#ffffff'}}>
              <div className="mx-auto" style={{paddingTop: '180px'}}>
                {/* <h1 style="font-size: 36px;color:#2c333c;font-weight:900">Thank you!</h1> */}
                <h2 className="  text-center align-self-center roboto-black-36px " style={{fontWeight: 900, maxWidth: '650px'}}>
                  Thank you for your purchase of truGeny’s
                  <span style={{fontWeight: 400}}>"{this.state.orderDetails.plan}"</span></h2><br />
                <p className="text-center">You will receive a confirmatory email along with a 'what to expect'<br />
                  guide document from our support team within the next 24 hours</p>
                {/* <button class="center-block btn btn-hover-white my-2 my-sm-0 center btn-hover-white  "style="height:60px;width:210px;border-radius:30px; " type="submit"><i class="fas fa-print"> Print your invoice </i></button> */}
                <button className="center-block btn btn-hover-white my-2 my-sm-0 center btn-hover-white  " onClick={this.toProductPage} style={{height: 'auto', width: 'auto', borderRadius: '30px', background: '#4f5be7', color: 'white'}} type="submit">Order Your truGeny Test Now ! </button>
                {/* <p class="text-center">Have Questions?</p> */}<br />
                <button className="center-block btn btn-hover-white my-2 my-sm-0 center btn-hover-white  " style={{height: 'auto', width: 'auto', borderRadius: '30px', fontSize: '10px'}} type="submit"><i className="fas fa-print"> Print your invoice </i></button>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4 p-5">
              <h2>Summary</h2>
              <div className="table-responsive-sm">
                <table className="table" style={{border: 'none'}}>
                  <thead>
                    <tr>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><b>{this.state.orderDetails.plan}</b></td>
                      <td><b><Currency currency="INR" quantity={this.state.orderDetails.unitPrice} /></b></td>
                    </tr>
                    {/* <tr>
      <td><b>Shipping + Handling</b></td>
      <td>Rs 9000.00</td>

    </tr> */}
                    {/* <tr>
                      <td><b>GST @{this.state.orderDetails.gstPercentage}%</b></td>
                      <td><b><Currency currency="INR" quantity={this.state.orderDetails.unitPrice * this.state.orderDetails.gstPercentage / 100}/></b></td>
                    </tr> */}
                    <tr>
                      <td><b>Total</b></td>
                      <td><b><Currency currency="INR" quantity={(this.state.orderDetails.unitPrice * this.state.orderDetails.gstPercentage / 100)+this.state.orderDetails.unitPrice}/></b></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div id="hearabout">
                {/* <h4 style="color: #4f5be7;font-size: 24px;font-weight: 700;">How did you hear about us?<h4> */}
                <div className="gc-col px-0 col-md-5 mb-3 mt-3" style={{maxWidth: '334px'}}>
                  <select className="form-control roboto-reg-16px " id="exampleFormControlSelect1" type="text" onchange="changeIt()" name="interest" style={{border: 'none', height: '60px'}}>
                    <option className="dropdown-item roboto-reg-16px">How did you hear about us?</option>
                    <option className="dropdown-item roboto-reg-16px">Ad</option>
                    <option className="dropdown-item roboto-reg-16px">Website</option>
                    <option className="dropdown-item roboto-reg-16px">Internet</option>
                    <option className="dropdown-item roboto-reg-16px">Social Media</option>
                    <option className="dropdown-item roboto-reg-16px">Referred by Doctor's</option>
                    <option className="dropdown-item roboto-reg-16px">Referred by Other's</option>
                  </select>
                </div>
                <div className="d-flex mb-3">
                  <h6>  Rate your experience with truGeny</h6>
                  <span className="fa fa-star checked" />
                  <span className="fa fa-star checked" />
                  <span className="fa fa-star checked" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                </div>
                <button className="float-right btn btn-hover-white  my-sm-0 center  " id="con1" onclick="window.location.href = 'Activate-kit.html';" style={{borderRadius: '30px', background: '#4f5be7', color: 'white'}} type="submit"><b>Submit</b></button>
              </div>
            </div>
          </div>
        </div>
        <div style={{paddingTop: '12.25rem'}}>
        </div>
       <SubFooter></SubFooter>
        <MobileFooter></MobileFooter>
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>


    );
  }
}
export default BBPaymentSuccess;