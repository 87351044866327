import React from 'react'
import axios from '../axios/axios';
import *as PayContants from './paymentContants';


class LandingClinicians extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          pemail:'',
          pphone:'',
          pname:'',
          pmessage:'',
          drPracticeNo:'',

            errors:{}
          };
          this.baseState = this.state;

        this.prospectReg = this.prospectReg.bind(this);

    }

    prospect = (e) => {
      const prospectForm = this.state
      prospectForm[e.target.name] = e.target.value;
      this.setState(prospectForm);
    }


    validateForm() {
      let fields = this.state;
      let errors = {};
      let formIsValid = true;

      if (!fields["pname"]) {
        formIsValid = false;
        errors["pname"] = "*Please enter your name";
    }


      if (!fields["pemail"]) {
          formIsValid = false;
          errors["pemail"] = "*Please enter your email address";
      }

      if (typeof fields["pemail"] !== "undefined") {
          //regular expression for email validation
          var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          if (!pattern.test(fields["pemail"])) {
              formIsValid = false;
              errors["pemail"] = "*Please enter valid email address";
          }
      }

      if (!fields["pphone"]) {
          formIsValid = false;
          errors["pphone"] = "*Please enter your mobile no.";
      }
      if(fields["drPracticeNo"]){
      if(fields["drPracticeNo"].length>20){
        formIsValid = false;
              errors["drPracticeNo"] = "*Please enter valid Practice Number";
      }
      }

    

      if (typeof fields["pphone"] !== "undefined") {
          if (!fields["pphone"].match(/^[0-9]{10}$/)) {
              formIsValid = false;
              errors["pphone"] = "*Please enter valid mobile no.";
          }
      }
      this.setState({
          errors: errors
      });
      return formIsValid;
    }

    prospectReg(e) {
      e.preventDefault();
        if (this.validateForm()) {
        const prospectForm = this.state
        prospectForm["source"] = "LandingClinicians";
        this.setState(prospectForm);
          const { pname, pphone, pemail,pmessage ,source,drPracticeNo} = this.state;
          axios.post('users/prospect', { pname, pphone, pemail, pmessage,source,drPracticeNo })
              .then((response) => {
                this.setState(this.baseState);
                this.redirect()
                //   swal("Thank you for your interest. We will get back you soon...").then(
                //     setTimeout(() => {
                //      this.redirect()
                //   }, 4000)
                // );

              }).catch(error => {
                if(error.response===undefined){
                  this.props.history.push("/error")
                }else{
                  this.setState({
                      errorMsg: error.response.data.message
                  })
                }

              });
              e.target.reset();
              this.setState(this.baseState);
            }


  }
 redirect(){
    window.location.href="ThanksListen"
 }

   render(){


       return(
        <div>

        <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      
            <link rel="stylesheet" href="style-clinicians.css" />
<title>truGeny Clinicians, India’s First Tele-Genetics Platform with Deep Clinical Expertise.</title>

<div className="container-fluid bg-darkk ">
<div className="container pt-2 customtext1">
<small><a className="pt-5 cilinicians2" href="/">www.trugeny.com</a></small>
</div>
    <h1 className="  text-center text-white pt-lg-3 pt-sm-5 mt-sm-5 mt-lg-1 customtext cilinicians1 " >Introducing</h1>
	<h3 className="text-center cilinicians4">India’s First Tele-Genetics Platform with Deep Clinical Expertise.</h3>
<div className="container">
<div className="row mt-lg-5  mt-sm-1 pt-lg-5 pt-sm-1 ">
<div className="col-lg-6 col-sm-12 justify-content-center mb-1 cilinicians3">

<img class="img-fluid custom-fluid cilinicians5" src="./img/landing-clininicians-1/stestispo.png"    />
</div>
<br/>
<br/><br/>
<div className="col-lg-6 col-sm-12" >
<div className="c1 mt-5">
<p className="text-white text-center pt-2 cilinicians6" >GET STARTED TODAY</p>
<form onSubmit={this.prospectReg}>


  <div className="form-group">
    <label for="exampleInputEmail1 text-white"><small className="text-white pb-0 mb-0">Name *</small></label>
    <input type="text" className="form-control" id="exampleInputEmail1" name="pname" onChange={this.prospect} aria-describedby="emailHelp" />
    <div className="landingformerror">{this.state.errors.pname}</div>

  </div>
  <div className="form-group">
    <label for="exampleInputPassword1 text-white"><small className="text-white pb-0 mb-0">Mobile Number *</small></label>
    <input type="tel" className="form-control" id="exampleInputPassword1" pattern="[1-9]{1}[0-9]{9}" onChange={this.prospect} name="pphone" />
    <div className="landingformerror">{this.state.errors.pphone}</div>

  </div>
   <div className="form-group">
    <label for="exampleInputPassword1 text-white"><small className="text-white pb-0 mb-0">Email *</small></label>
    <input type="email" className="form-control" id="exampleInputPassword1"  name="pemail"  onChange={this.prospect}/>
    <div className="landingformerror">{this.state.errors.pemail}</div>

  </div> <div className="form-group">
    <label for="exampleInputPassword1 text-white"><small className="text-white pb-0 mb-0">My Practice Number</small></label>
    <input type="text" className="form-control" id="exampleInputPassword1" name="drPracticeNo" placeholder="Optional" onChange={this.prospect} />
    <div className="landingformerror">{this.state.errors.drPracticeNo}</div>

 </div>
<h1  className="text-center">
  <button type="submit" class="btn btn-primary">Submit</button></h1>
</form>
</div>
</div>
</div>
</div>
<br/>
<br/><br/>
<div className="container-fluid" >
<div className=" container cilinicians7" >
<div className="py-1 cilinicians8" >
<h4 className="py-2 text-white px-3">We listen, we collaborate and we ﬁt your workﬂow to deliver on your patients needs.</h4>
</div></div>
</div>
<br/>

<div className="container mx-auto">
<div className="row" >

<div className="col-lg-4 col-sm-12 mb-5" >
<div className="colo-me pt-3 cilinicians9" >
<p className="text-white text-light" >Expert Genetic Counselling Service<br/>
Our proprietary tele-genetics platform enables you to deliver highly personalised genetic counselling service to all your patients. </p>

<h4 classNames="text-white">Medical Specification</h4>
<ul className="pl-0 text-white cilinicians10" >
  <li><span><img className="img-fluid px-2 cilinicians11" src="./img/landing-clininicians-1/1.png"/> Oncology</span></li>
  <li><span className="cilinicians12" ><img className="img-fluid px-2 cilinicians13"  src="./img/landing-clininicians-1/2.png"/>Family Planning &<br/>
Reproductive Health</span></li>
  <li><span><img className="img-fluid px-2 cilinicians14" src="./img/landing-clininicians-1/3.png" />Pediatrics</span></li>
   <li><span><img className="img-fluid px-2 cilinicians15" src="./img/landing-clininicians-1/4.png"/>Cardiology</span></li>
</ul>
</div>
</div>

<div className="col-lg-8 col-sm-12">
<div className="colo-me pt-3 pb-5 cilinicians16" >
<p className="text-white mr-5 text-light">Ensure the right test is done for your patients<br/>
Clinical grade tests and reports with actionable insights empower your to provide the best quality care around genetic conditions for your patients.</p>
<br/>
<br/>

<div className="row " >

<div className="col-lg-3 col-sm-12">

<p className="text-white">truFind<br/>
Hereditary Cancer Test</p>

<img className="img-fluid" src="./img/landing-clininicians-1/a1.png"/>
</div>
<div className="col-lg-1 hide-me">
<img src="./img/landing-clininicians-1/line.png"/>
</div>
<div className="col-lg-3 col-sm-12">

<p className="text-white">truHeart<br/>
Coronary Artery Disease Test</p>

<img className="img-fluid" src="./img/landing-clininicians-1/a2.png"/>
</div>
<div className="col-lg-1 hide-me ">
<img src="./img/landing-clininicians-1/line.png"/>
</div>
<div className="col-lg-3 col-sm-12 ">

<p className="text-white">truFamily<br/>
Carrier Screening Test</p>

<img className="img-fluid" src="./img/landing-clininicians-1/a3.png"/>

</div>
</div>
</div>


</div>
</div>
</div>
<div className="container">
<div className="row">
<div className="col-lg-8 col-sm-12 " >
<p className="text-white pt-5"><a  className="cilinicians17" href={PayContants.truGenyDrMailhref}>Email : {PayContants.truGenyDrMail}</a><br/><a class="cilinicians17"  href={PayContants.truGenyHrefConatct}> Dial : {PayContants.truGenyConatct} </a></p>
</div>

<div className="col-lg-4 col-sm-12 mb-2 cilinicians18" >
<a href="/">
<img className="pl-5 pr-0 pb-5 "src="./img/landing-clininicians-1/logo.png"/>
</a>
</div>
</div>
</div>
</div>




        </div>

         )
     }

  }
  export default LandingClinicians;
