import React from 'react'
import HeaderLinks from './HeaderLinks';
import NewMainHeader from './NewMainHeader';
import SubMainFooter from './SubMainFooter';

class RiskWelcome extends React.Component{
    constructor(props) {
        super(props);   
        
        this.state={
          //defaultCheck1: false,
          errors: {}         
        }

        this.checkboxvalue=this.checkboxvalue.bind(this);
        this.formvalidation=this.formvalidation.bind(this);
        this.toRisPage=this.toRisPage.bind(this);
    }


    checkboxvalue = (e) =>{
      const state = this.state
    
      if(e.target.checked){
        state[e.target.name] = true;
        
      }else{
        state[e.target.name] = false;
      }
      this.setState(state);
    }
  
   formvalidation() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;

      if (fields["defaultCheck1"]=== 'false' || fields["defaultCheck1"]===undefined) {
        formIsValid = false;
        errors["defaultCheck1"] = "*Please accept terms and conditions";
      }
  
      this.setState({
        errors: errors
      });
      return formIsValid;
  
    }

        toRisPage(e) {
          e.preventDefault();

      if(this.formvalidation()){
        window.location.href="/risk-page";
      }
      // browserHistory.push("/ordertrugene");        
        //window.location.href="/risk-page";
       
      }
   render(){
  

       return(
        <div>
        <HeaderLinks></HeaderLinks>
        <style dangerouslySetInnerHTML={{__html: "\n.rule h3{\n  font-size:24px;\n  color: #4f5be7;\n  font-weight:700;\n}\n.rule p{\n  font-size:18px;\n  color: #000;\n  font-weight:400;\n}\n\n" }} />
        <NewMainHeader></NewMainHeader>
        {/* end of header */}
        <div style={{marginTop: '6rem'}} />
        <section className="container shadow px-0" style={{borderRadius: '2.5rem'}}>
          <div className="container">
            <h1 className="roboto-black-36px py-5 px-4">WELCOME TO YOUR RISK ASSESSMENT SNAPSHOT</h1>
          </div>
          <div style={{backgroundColor: '#eff7ff', padding: '2.5rem'}}>
            <h2 className="roboto-bold-36px" style={{color: '#4f5be7'}}>How does it work?</h2>
          </div>
          <div className="row">
            <div className="col-7">
              <div className="rule" style={{backgroundColor: '#fff', padding: '2.5rem'}}>
                <h3>Step 1:</h3>
                <p>There will be a few questions asked about the things that may affect your risks for a specific chronic disease.
                </p>
                <h3>Step 2:</h3>
                <p>Your Disease Risk assessment is not a diagnosis, therefore it doesn’t tell you if you'll get a disease or not. It does show you ways you can lower your risks.
                </p>
                <h3>Step 3:</h3>
                <p>The calculation of your risk is based on various scientific studies of people across various age categories. We believe everyone can benefit by learning about their health risks and make a personal health action plan accordingly.
                </p>
                <h3>Step 4:</h3>
                <p>Over a period of time your risks can change. We recommend for you to keep coming back often to see if there are any new changes and updates. We suggest coming back every so often to see whether there has been a change.
                </p>
                <h3>Step 5:</h3>
                <p>This risk assessment is not intended as an alternative to diagnosis.</p>
              </div>
            </div>
            <div className="col-5 align-self-center">
              <img className="img-fluid" src="img/risk-page/tq/Illustration.png" alt="Illustration" />
            </div>
          </div>
          <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
          <div className="px-5 risk d-flex  py-5">
            <div className="form-check  ">
            <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.defaultCheck1}</div>
              <input className="form-check-input" type="checkbox" defaultValue name="defaultCheck1" id="defaultCheck1" onChange={this.checkboxvalue}/>
              <label className="form-check-label" htmlFor="exampleRadios1">
                <h6 className="reg-18"> I confirm I have read the <a href>Terms and Conditions</a> of Use, I understand its contents,
                  and I agree to be bound by them</h6>
              </label>
            </div>
            <div className="justify-content-end ml-5">
              {/* <button type="submit" class="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3"onclick="hideandshowhomesec1()" style="height:3.75rem;width:8.3rem;"/>Back</button> */}
              <button id="ctnbtn1" type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" onClick={this.toRisPage} style={{height: '2.75rem', width: '8.3rem'}}>Get Started</button>
            </div>
          </div>
        </section>
        <div style={{marginTop: '6rem'}}>
        </div>
        <SubMainFooter></SubMainFooter>

        {/* eo Modal */}
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>
        
       )
   }

}
export default RiskWelcome;