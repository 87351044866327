import React from 'react';
import MainHeader from './MainHeader';
import Footer from './Footer';
import ReferProspect from './ReferProspect';
import MobileFooter from './MobileFooter';
import MetaTags from 'react-meta-tags';
import axios from '../axios/axios';
import StickyContactMenu from './StickyContactMenu';
import OpenReferProspect from './OpenReferProspect';
import swal from 'sweetalert';

import Cookies from 'js-cookie';

class CliniciansRefer1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          errors:{},
          refer: false,
          
    
        }

        this.prospectReg = this.prospectReg.bind(this);

    
    
      }
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  toLogin() {
        window.location.href="/clinicians_login";
    }

    
    toGeneticHelp() {

       window.location.href="/clinicians_genetic-help";
      }
      prospect = (e) => {
        const prospectForm = this.state
        prospectForm[e.target.name] = e.target.value;
        this.setState(prospectForm);
      }
      validateForm() {
    
        let fields = this.state;
        let errors = {};
        let formIsValid = true;
    
        if (!fields["pemail"]) {
            formIsValid = false;
            errors["pemail"] = "*Please enter your email address";
        }
    
        if (typeof fields["pemail"] !== "undefined") {
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["pemail"])) {
                formIsValid = false;
                errors["pemail"] = "*Please enter valid email address";
            }
        }
    
        if (!fields["pphone"]) {
            formIsValid = false;
            errors["pphone"] = "*Please enter your mobile no.";
        }
    
        if (typeof fields["pphone"] !== "undefined") {
            if (!fields["pphone"].match(/^[0-9]{10}$/)) {
                formIsValid = false;
                errors["pphone"] = "*Please enter valid mobile no.";
            }
        }
        this.setState({
            errors: errors
        });
        return formIsValid;
    
    }
      prospectReg(e) {
        e.preventDefault();
    
        if (this.validateForm()) {
        const source= "ReferedByClinician";

            const { pname, pphone, pemail,pmessage } = this.state;
            axios.post('users/prospect', { pname, pphone, pemail, pmessage,source })
                .then((response) => {
                    
                    swal("Thank you for your interest. We will get back you soon...")
                    // this.setState({
                    //     sucessMsg:"Thank you for your interest. We will get back you soon..."
                    // })
                   // alert("Thanks for your intersr. We will get back you soon")   
                
     
                }).catch(error => {
                  if(error.response===undefined){
                    this.props.history.push("/clinicians_error")
                  }else{
                    this.setState({
                        errorMsg: error.response.data.message
                    })  
                  }
                   
                });
                e.target.reset();
        }
       
      
    }
    validationsForHealthHistorySession(name){
      document.getElementById(name).scrollIntoView();
  
    }
  render() {
    return (
      

        <div>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
        {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.2/css/all.css" integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay" crossorigin="anonymous"> */}
        <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>truGeny</title>
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/header_footer.css" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <link rel="stylesheet" href="./css/sticky.css" />
        <MetaTags>
        <title>Learn More about Genetic Counseling for your Patients - truGeny</title>
		<meta name="description" content="truGeny’s genetic counselling services will help you treat your patients better. "/>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta property="og:title" content="Learn More about Genetic Counseling for your Patients - truGeny"/>
        <meta property="og:site_name" content="truGeny"/>
        <meta property="og:url" content="https://trugeny.com/"/>
        <meta property="og:description" content="truGeny’s genetic counselling services will help you treat your patients better. "/>
        <meta property="og:type" content="website"/>
        <meta property="og:image" content=""/>
</MetaTags>
        <style dangerouslySetInnerHTML={{__html: "\n  /* @media only screen and (min-width: 1200px)\n{\n/* .custom-pad-mar {\n  padding-left:25rem;\n  padding-right:25rem;\n\n} */\n\n  .genetics-banner-sec-wrap .genetics-banner-sec {\n    background: url(./img/clinicians/refer/1/bg.png) no-repeat center;\n    -webkit-background-size: cover;\n    background-position: center;\n    background-size: cover;\n    background-repeat: no-repeat;\n  }\n  " }} />
        <MainHeader category="genetic-counselling"></MainHeader>
      {/* eo-container */}
      <div style={{marginTop:'12rem'}}></div>
        <div className="container pt-0 pb-2 mt-0 d-none">
          <nav className="navbar container navbar-expand-xl  navbar-light d-none d-lg-block d-xl-block float-left">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon">
              </span>
            </button>
            <ul className=" row navbar-nav mr-auto ">
              <li className="nav-item ">
                <a className="nav-link float-right " href="clinicians.html">Clinicians</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" style={{color: '#4f5be7'}} href="clinicians-genetic-counseling.html"><b>Genetic Counselling </b></a>
              </li>
              <li className="nav-item">
                {/* <a class="nav-link" href="clinicians-medical-spec.html">Medical Specialities</a> */}
              </li>
              <li className="nav-item ">
                <a className="nav-link" href="clinicians-test-overview.html">Test</a>
              </li>
              <li className="nav-item ">
                <a className="nav-link" href="how-does-it-works.html">How does it work?</a>
              </li>
              <li className="nav-item ">
                {/* <a class="nav-link" href="clinicians-cancer-test.html">Cancer Test</a> */}
              </li>
              <li className="nav-item ">
                {/* <a class="nav-link" style="color:#4f5be7" href="clinicians-heart-test.html"><b>Heart Test</b></a> */}
              </li>
            </ul>
          </nav>
          <br />
          <br />
        </div>
        {/* eo-container */}
        <section className="genetic-Counseling-main-sec genetics-banner-sec-wrap">
          <div className=" genetics-banner-sec ">
            <div className="container pt-4 mt-2">
              <h1>Refer a Patient</h1>
              <p className="text-justify mt-3 mb-4">We Deliver a service that fits your needs.</p>
            </div>
            {/* eo-container*/}
            <div className="d-flex justify-content-center" id="secc1">
              <a style={{scrollBehavior: '[ auto | smooth ]'}} href="#secc1">  <img className="img-fluid d-none d-md-block d-lg-block btn-hover-white1" src="./img/111.png" /></a>
            </div>
          </div>
          {/* eo-heart-banner-sec */}
        </section>
        {/* eo-heart-banner-sec-wrap */}
        <StickyContactMenu></StickyContactMenu>
        {/* ------------------------------------------------------heart-banner-sec over------------------------------------------------------------------ */}
        <section className="genetics-informed-sec-main heart-disease-sec-main heart-disease-sec-main ">
          <div className="heart-disease-sec-main-wrap container">
            <div className="genetics-intro-text text-center">
              <h2 className=" text-center genetics-h2 ">How does it work?</h2>
            </div>
            {/* eo-intro-text-wrap*/}
            <div className=" heart-disease-sec row container">
              <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 d-block text-center  ">
                <img className="img-fluid mx-auto" src="./img/clinicians/refer/1/Illustration1.png" />
              </div>
              {/* eo-hd-img */}
              <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center">
                <div className="roboto-reg-18px">
                  <p>Once you recommend a patient, truGeny will contact the patient to schedule
                    an appointment with our genetic counselling experts.
                    <br />
                    <br />During the counselling session, our genetic counsellor will conduct
                    a thorough family history check to understand and determine any familial
                    or hereditary trends.
                    <br />
                    <br />If there is any genetic test being considered for your patient, you
                    will be informed. Additionally you will receive a summary of the
                    counselling session and any other genetic test reports.</p>
                </div>
                {/* <p class="heartdisease-content ><b>  7 Lakh new cancer cases being registered each year in India.</b></p>
          <p class="heartdisease-content">  truGeny’s hereditary cancer test enables your patients to understand their genetic risks for developing cancers. When caught at an early stage, patient survival rates for cancers covered by truGeny’s hereditary cancer test increase significantly.</p> */}
              </div>
              {/* eo-hd-text */}
            </div>
            {/* eo-row */}
            <section id="card-refer" className="gtk-sec ">
              <div className="container gtk-sec-wrap pb-0 gr-sec-wrap">
                <div className="gtk-main-header-wrap">
                  <h2 className="text-center">
                    How to get started
                  </h2>
                </div>
                {/* eo gr-header */}
                <div className="container gtk-card-main-wrap pt-5 mt-5  mb-5">
                  <div className="row">
                    <div className="gtk-card-wrap  col-12 col-md-6 col-lg-6 col-xl-6">
                      <div className=" card-1 shadow-lg r4 border-0 mb-5">
                        <div className="card-body p-0">
                          <h3 className="roboto-black-36px pt-5 px-0" style={{color: '#4f5be7'}}>Refer a Patient</h3>
                          <div className="card-top pb-0">
                            <div className="text-center py-lg-5">
                              <img className="img-fluid mx-auto" src="./img/clinicians/refer/1/Illustration2.png" />
                            </div>
                            {/* eo-hd-img */}
                          </div>
                          {/* eo-card-top */}
                          <div className="card-btm ">
                            <p className="card-text">When you refer a patient to <b>truGeny</b> we can schedule
                              an appointment for a pre or post test counselling whithin
                              24-48 hours.</p>
                            <div className="she-btn text-center mx-auto">
                              <a className="btn btn-outline-primary btn-hover-white rounded-pill text-center " onClick={()=>this.setState({refer:true})} style={{color: 'white', cursor: 'pointer', backgroundColor: '#4f5be7'}} role="button"> Refer a patient now</a>
                            </div>
                          </div>
                          {/* eo-card-btm */}
                        </div>
                        {/* eo-card-body */}
                      </div>
                      {/* eo-card-1 */}
                    </div>
                    {/* eo-ns-card-wrap */}
                    <div className="gtk-card-wrap  col-12 col-md-6 col-lg-6 col-xl-6">
                      <div className=" card-1 shadow-lg r4 border-0 mb-5">
                        <div className="card-body p-0">
                          <h3 className="roboto-black-36px pt-5 mb-3" style={{color: '#4f5be7'}}>Just Want to Find Out More?</h3>
                          <div className="card-top">
                            <div className="text-center py-lg-5">
                              <img className="img-fluid mx-auto" src="./img/clinicians/refer/1/Illustration3.png" />
                            </div>
                            {/* eo-hd-img */}
                          </div>
                          {/* eo-card-top */}
                          <div className="card-btm my-2 mt-3">
                            <p className="card-text">Simply connect with us and one of our representatatives will
                              help you understatnd how your patients can benefit with
                              truGeny</p>
                            <div className="she-btn text-center mx-auto">
                              <a className="btn btn-outline-primary btn-hover-white rounded-pill text-center " onClick={()=>this.validationsForHealthHistorySession("connect")} role="button" style={{color: 'white', backgroundColor: '#4f5be7'}}>
                                Connect with us</a>
                            </div>
                          </div>
                          {/* eo-card-btm */}
                        </div>
                        {/* eo-card-body */}
                      </div>
                      {/* eo-card-1 */}
                    </div>
                    {/* eo-ns-card-wrap */}
                  </div>
                  {/* eo-row */}
                </div>
                {/* gr-card-wrap */}
              </div>
              {/* eo gr-sec-wrap */}
            </section>
          </div>
          {/* eo-heart-disease-sec-main-wrap*/}
        </section>
        {/* eo-heart-disease-sec-main */}

        {this.state.refer?<section id="form-data">
        {(Cookies.get("userId"))?
         <ReferProspect/>:<OpenReferProspect/>
        }
          {/* Start of Genetic Counsell Help cards  */}

        </section>:''}

        <div className="gc-form-card container" id="connect">
        <div className="gc-form-card-wrap  shadow-lg">
          <div className=" gc-form-card-top">
            <h2 className="text-center"> Take control of your health, NOW!</h2>
            <p className=" gc-form-subtitle text-center">All you need to do is, fill out the form below and one of our experts will reach out to you very soon. </p>
            {/* <p class="text-center" > sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p> */}
          </div>{/* gc-form-card-top */}
          <div className="gc-form-card-btm">
            <form className="gc-form needs-validation" onSubmit={this.prospectReg}>
              <div className="form-row justify-content-center">
                <div className="gc-col  col-md-5 mb-3" htmlFor="validationTooltip01">
                  <div className="input-group ">
                    <input type="text"  className="form-control m-0" id="validationTooltip01" name="pname" placeholder="Name:" onChange={this.prospect}  placeholder="Name" required />
                    <div className="valid-tooltip">
                      Looks good!
                    </div>
                    <div className="input-group-prepend border-0">
                      <span className="input-group-text border-0" id="validationTooltip01"><img src="img/homepage/i1.png" /></span>
                    </div>
                  </div>
                </div>
                <div className="gc-col col-md-5 mb-3">
                  <div className="input-group">
                    <input type="email" name="pemail" onChange={this.prospect}  className="form-control m-0" required id="validationTooltipEmailPrepend" placeholder="E-mail ID" aria-describedby="validationTooltipEmailPrepend"  />
                    <div className="invalid-tooltip">
                      Please enter a valid E-mail ID.
                    </div>
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="validationTooltipEmailPrepend"><img src="img/homepage/i3.png" /></span>
                    </div>
                  </div>
                  <div className="input-group d-none">
                    <input type="text"  className="form-control m-0" id="validationTooltip02" placeholder="Reason" defaultValue="Reason" required/>
                    <div className="valid-tooltip">
                      Looks good!
                    </div>
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="validationTooltip02"><img src="img/homepage/i2.png" /></span>
                    </div>
                  </div>
                </div>
              </div>{/* end of form-row */}
              {/* <div className="form-row justify-content-center d-none">
                <div className=" gc-col col-md-5 mb-3">
                  <div className="input-group">
                    <input type="email" name="email" className="form-control m-0" id="validationTooltipEmailPrepend" placeholder="E-mail ID" aria-describedby="validationTooltipEmailPrepend" required />
                    <div className="invalid-tooltip">
                      Please enter a valid E-mail ID.
                    </div>
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="validationTooltipEmailPrepend"><img src="img/homepage/i3.png" /></span>
                    </div>
                  </div>
                </div>
                <div className="gc-col col-md-5 mb-3">
                  <div className="input-group">
                    <input type="text" name="refered" className="form-control m-0" id="validationTooltip03" placeholder="Referred by" defaultValue="Referred by" aria-describedby="validationTooltip03" required />
                    <div className="valid-tooltip">
                      Looks good!
                    </div>
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="validationTooltip03"><img src="img/homepage/i4.png" /></span>
                    </div>
                  </div>
                </div>
              </div>end of form-row */}
              <div className="form-row justify-content-center">
                <div className="gc-col col-md-5 mb-3">
                  <div className="input-group">
                    <input className="form-control m-0" type="tel" maxLength={10} onChange={this.prospect} id="validationTooltip05" required placeholder="Phone number" name="pphone" pattern="[0-9]{10}"  />
                    <div className="invalid-tooltip">
                      Please provide a valid Phone number.
                    </div>
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="validationTooltip05"><img src="img/homepage/i5.png" /></span>
                    </div>
                  </div>
                </div>
                <div className="gc-col col-md-5 mb-3">
                  <select className="form-control roboto-reg-16px" required id="exampleFormControlSelect1" placeholder="Select an answer" name="pmessage" onChange={this.prospect}style={{border: 'none', height: '60px'}}>
                    <option className="dropdown-item roboto-reg-16px" defaultValue disabled>I’m interested to learn about.</option>
                    <option className="dropdown-item roboto-reg-16px" value="Pregnancy">Pregnancy</option>
                    <option className="dropdown-item roboto-reg-16px" value="My risk for Cancer">My risk for Cancer</option>
                    <option className="dropdown-item roboto-reg-16px" value="My risk for heart disease">My risk for heart disease</option>
                  </select>
                  {/* <div className="input-group d-none">
                    <textarea name="message" className="form-control m-0 " id="validationTextarea" rows={1} placeholder="Message"  defaultValue={""} />
                  </div> */}
                </div>
              </div>{/* end of form-row */}
              <div className="wrapper pt-3 mt-4 mb-5  pb-5">
                <button className="center-block btn btn-outline-primary btn-hover-white my-2 my-sm-0 center" style={{height: '60px', width: '210px', borderRadius: '30px', color: 'white', background: '#4f5be7'}} name="submit" type="submit"><b>SUBMIT</b></button>
              </div>
            </form>
          </div>{/* end of gc-form-card-btm */}
        </div>{/* end of gc-form-card-wrap */}
      </div>

        {/* Start of Genetic Counsell Help cards  */}
        {/* **********Schedule your Genetic Counseling Session Ends Here************** */}
        {/* Footer Links */}
        <Footer></Footer>
        <MobileFooter></MobileFooter>        {/* -------------------------------------start q and a------------------------------ */}
        <div className="modal fade bd-example-modal-lg d-none" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content" style={{borderRadius: '40px'}}>
              <div className="modal-header bg-blue clearfix border-0" style={{borderTopRightRadius: '40px', borderTopLeftRadius: '40px'}}>
                <h5 className="modal-title" id="exampleModalLongTitle" style={{paddingTop: '5px', paddingBottom: '25px', color: '#2c333c', fontSize: '30px', fontWeight: 700}}>
                  Start your journey in 3 easy steps</h5>
                <button type="button" className="text-center close" data-dismiss="modal" aria-label="Close" style={{paddingTop: '25px', paddingBottom: '25px'}}>
                  <img className="img-fluid" src=".\img\registration\X.png" />
                </button>
                {/* <p class="float-left">Question 1 of 3</p> */}
              </div>
              <div className="bg-blue border-bottom-1">
                <p className="pl-3">Question 1 of 3</p>
              </div>
              <div className="modal-body pb-5 pt-0 m-0">
                <div className="container ">
                  <br />
                  <div className="tab-content">
                    <div id="home" className="container tab-pane active">
                      <br />
                      <h3>How do you indentify your gender?</h3>
                      <hr />
                      <div className="d-flex d-flex-inline">
                        <img className="mr-3" src="./img/q&a/female.png" alt="" />
                        <img className="mr-3" src="./img/q&a/female.png" alt="" />
                      </div>
                      <div className="float-right pt-2 mt-2">
                        <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill" href="#menu1">Next</button>
                      </div>
                    </div>
                    <div id="menu1" className=" m-0 p-0 container tab-pane fade">
                      <br />
                      <h3>Does anyone in your family have 2 diabetes?</h3>
                      <hr />
                      <h4>Certain ethnic groups have higher risk for diabetes,<br />
                        even at lower body weights.</h4>
                      <div className="col-xs-12 mt-2 ">
                        <div className="input-group-prepend " style={{border: 'solid 1px #ebebeb', maxWidth: '312px'}}>
                          <input type="text" placeholder="Select your ethnicity" className="form-control roboto-reg-16px" style={{border: 'none', height: '60px'}} aria-label="Text input with dropdown button" />
                          <button className="m-0 p-0 btn btn-outline-secondary roboto-bold-14px " href="#" style={{border: 'none', backgroundColor: 'white'}} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img className="m-0 p-0" src="img/q&a/carot.png" /></button>
                        </div>
                      </div>
                      <div className="float-right pt-2 mt-2">
                        <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill" href="#menu2">Next</button>
                      </div>
                    </div>
                    <div id="menu2" className=" m-0 p-0 container tab-pane fade">
                      <br />
                      <h3>Does anyone in your family have 2 diabetes?</h3>
                      <hr />
                      <div className="radio">
                        <label>
                          <input type="radio" name="optradio" defaultChecked /> Parents</label>
                      </div>
                      <div className="radio">
                        <label>
                          <input type="radio" name="optradio" /> Siblings</label>
                      </div>
                      <div className="float-right pt-2 mt-2">
                        <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill"  href="#">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* sticky form---------------- */}
              {/* <div class="modal-footer">
                  <button type="button" class="btn btn-secondary"  data-toggle="pill" href="#menu2">Close</button>
                  <button type="button" class="btn btn-primary"  data-toggle="pill" href="#menu1">Next</button>
                </div> */}
            </div>
          </div>
        </div>
        {/* sticky form---------------- */}
        
        {/* --------eo sticky form----------------- */}
        {/* ----------------------------------------------------eo Q and A--------------------------------- */}
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>
    );
  }
}
export default CliniciansRefer1;
