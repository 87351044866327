// Local Test
//  export const baseUrl="http://localhost:8080";


// Test Server
// export const baseUrl="http://13.232.201.151:8081";
//  export const baseUrl="http://35.154.243.213:8081";

// Razeer Pa
// export const key_id="rzp_test_3oWYKsj947PbQ0";
// export const pay_profileName="gaurav";

// export const pay_email="gaurav.kumar@example.com";
// export const pay_contact=9123456780;


//Live
export const baseUrl = "https://trugene.in:8081";

export const key_id = "rzp_live_4upJjqO83mExfq";


export const pay_profileName = "Anil";

export const pay_contact = 9930777361;

export const pay_email = "it.support@trugeny.com";


//No need

// export const trugenyUrl="http://13.233.38.135";

export const trugenyUrl = "https://trugeny.com";

// export const blogUrl="https://13.233.38.135/blog/";


// export const blogUrl="https://trugeny.com/blog";

export const blogUrl = "https://blog.trugeny.com";

export const facebookUrl = "https://www.facebook.com/trugeny/";

export const twitterUrl = "https://twitter.com/trugeny";

export const instagramUrl = "https://www.instagram.com/trugeny_/";

export const linkedinUrl = "https://www.linkedin.com/company/trugeny/";



export const action = "https://api.razorpay.com/v1/checkout/embedded";



export const pay_name = "Acme Corp";

export const pay_description = "TruGeny Payment GateWay";


export const pay_address = "truGeny, Longevity Labs";

export const callback_url = baseUrl + "/cartservice/razorpay";


export const cancel_url = baseUrl + ":3000";

export const truGenyConatct = 18005726889;

export const truGenyHrefConatct = "tel:1800 572 6889";

export const truGenyMailhref = "mailto:support@truGeny.com";

export const truGenyMail = "support@truGeny.com";

export const copyRight = "Copyright @2020 truGeny";

export const copyRightwithInc = "Copyright @2020 truGeny,Inc.";

export const truGenyDrMailhref = "mailto:doctorsupport@trugeny.com";

export const truGenyDrMail = "doctorsupport@trugeny.com";



