import React from 'react';
import *as PayContants from './paymentContants';

class FamilyPlanningLandingFooter extends React.Component{
render(
    
){
    return(
        <div className="last">

<div className="container">
  <div className="row py-3">
    <div className="col-sm-6">
      <img className="fp24 pt-2"src="./img/family-planning/TruGeny_logo-mob.png"/>

      <p className="pt-2 fp35">{PayContants.copyRightwithInc}</p>
    </div>
    <div className="col-sm-6 "><br/>
      <p className="fp23">Connect with us <span><a href={PayContants.facebookUrl}><img src="./img/family-planning/fb.png"/></a><a href={PayContants.instagramUrl}><img src="./img/family-planning/in.png"/></a><a href={PayContants.twitterUrl}><img src="./img/family-planning/tw.png"/></a><a href={PayContants.linkedinUrl}><img src="./img/family-planning/ln.png"/></a></span></p>
    </div>
  </div>
</div>

</div>
    )
}
}
export default FamilyPlanningLandingFooter;