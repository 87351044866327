import React, { Component } from "react";

import Carousel from "react-bootstrap/Carousel";
class CarouselCards1 extends Component {
  constructor(props) {
    super(props);
  }
  toCancer() {
    window.location.href = "/genetic-testing-cancer-risk";
  }
  toHeart() {
    window.location.href = "/genetic-testing-heart-disease";
  }
  toFamily() {
    window.location.href = "/family-planning";
  }

  render() {
    return (
      <div
        id="carouselExampleIndicators"
        className="carousel slide new"
        data-ride="false"
      >
        <div className="carousel-inner">
          <Carousel controls={true} interval={false} indicators={false}>
            <Carousel.Item>
              <div className=" col-sm-12 col-md-12 col-lg-4 mb-md-5 mb-sm-2 py-md-5 py-sm-2">
                <div className="card rounded shadow-lg  r4 maincard">
                  <div className="card-body  text-center">
                    <div className="card-block  p-2 circle">
                      <img
                        className="card-img-top text-center  "
                        src="./img/payment/cancer.png"
                        style={{ height: "126px", width: "126px" }}
                        alt="Card image cap"
                      />
                      <h3
                        className="text-highlight pt-4 "
                        style={{ fontWeight: 900 }}
                      >
                        <strong>truFind</strong>
                        <br />
                        <span className="textspancard">
                          Hereditary Cancer Test
                        </span>
                      </h3>
                    </div>
                    <p className="roboto-reg-18px pb-2">
                      A detailed analysis of over 100 genes to help you learn
                      your risks for developing cancers of the breast, ovarian,
                      uterine, colorectal, prostrate, pancreatic and stomach
                      cancers.
                    </p>
                    <button
                      style={{ height: "40px", width: "160px" }}
                      className="mx-auto px-3 rounded-pill btn btn-outline-primary btn-hover-white "
                      onClick={this.toCancer}
                      type="submit"
                    >
                      <b>Learn more</b> &nbsp;
                      <i className="fas fa-long-arrow-alt-right" />
                      &nbsp;
                    </button>
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className=" col-sm-12 col-md-12 col-lg-4 mb-md-5 mb-sm-2 py-md-5 py-sm-2 pb-5">
                <div className="card  rounded shadow-lg r4 maincard">
                  <div className="card-body text-center">
                    <div className="card-block p-2 circle ">
                      <img
                        className="card-img-top text-center "
                        src="./img/payment/heart.png"
                        style={{ height: "126px", width: "126px" }}
                        alt="Card image cap"
                      />
                      <h3
                        className="text-highlight pt-4 "
                        style={{ fontWeight: 900 }}
                      >
                        <strong>truHeart</strong>
                        <br />
                        <span className="textspancard">
                          Coronary Artery Disease Test
                        </span>
                      </h3>
                    </div>
                    <p className="roboto-reg-18px  pb-1">
                      A next generation Polygenic Risk Score (PRS) based genetic
                      test to you help you evaluate your risks for developing
                      heart conditions such as Familial Hypercholesterolemia,
                      Cardiomyopathy, Arrythmia.
                    </p>
                    {/* <a href="view-reports.html">  <img class="card-img-top text-center mb-1 p-2" onclick="view-reports.html" src="./img/payment/view-report-button.png"/></a> */}
                    <button
                      style={{ height: "40px", width: "160px" }}
                      className="mx-auto px-3 rounded-pill btn btn-outline-primary btn-hover-white "
                      onClick={this.toHeart}
                      type="submit"
                    >
                      <b>Learn more</b> &nbsp;
                      <i className="fas fa-long-arrow-alt-right" />
                      &nbsp;
                    </button>
                    <br />
                    <br />
                  </div>
                </div>
              </div>
              {/* eo-card-deck-wrap */}
            </Carousel.Item>
            <Carousel.Item>
              <div className=" col-sm-12 col-md-12 col-lg-4 mb-md-5 mb-sm-2 py-md-5 py-sm-2">
                <div className="card rounded shadow-lg  r4 maincard">
                  <div className="card-body  text-center">
                    <div className="card-block  p-2 circle">
                      <img
                        className="card-img-top text-center  "
                        src="./img/career/familyplanning.png"
                        style={{ height: "126px", width: "126px" }}
                        alt="Card image cap"
                      />
                      <h3
                        className="text-highlight pt-4 "
                        style={{ fontWeight: 900 }}
                      >
                        <strong>truFamily</strong>
                        <br />
                        <span className="textspancard">
                        Carrier Screening Test
                        </span>
                      </h3>
                    </div>
                    <p className="roboto-reg-18px  pb-1">
                      Planning for your future family’s health matters.
                      Knowledge about your risk Of passing on a genetic
                      condition to your Child can make a difference to the well
                      being of your future children.
                    </p>
                    <button
                      style={{ height: "40px", width: "160px" }}
                      className="mx-auto px-3 rounded-pill btn btn-outline-primary btn-hover-white "
                      onClick={this.toFamily}
                      type="submit"
                    >
                      <b>Learn more</b> &nbsp;
                      <i className="fas fa-long-arrow-alt-right" />
                      &nbsp;
                    </button>
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
        {/* <a class="btn btn-primary border-0" href="view-reports.html#your-risks" role="button"><i class="fas fa-chevron-right"></i></a> */}
      </div>
    );
  }
}
export default CarouselCards1;
