import React from 'react';
import CommonHeder from './CommonHeder';
import axios from '../axios/axios';
import Cookies from 'js-cookie';
import converter from 'number-to-words'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import MobileFooter from './MobileFooter';
import swal from 'sweetalert';
import NumberFormat from 'react-number-format';
import { tsImportEqualsDeclaration } from '@babel/types';
import SubFooter from './SubFooter';


class HealthHistory extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

      age1:'',
      dob1:'',
      monthOfBirth:'',
      dayOfBirth:'',
      yearOfBirth:'',
      heightInches:'',
      heightFeet:'',
      isActive: 1,
      showing: true,
      title: "YOUR HEALTH HISTORY MATTERS",
      noOfFiles: 0,

      showingFamilyTreee: false,
      showingParents: false,
      healthHistory: {
        old:'',
        weight:''
      },
      userData: [],
      medicinesListError: false,
      problems: false,
      hadCancerError: false,
      numberOfPolypsError: false,
      boneMarrowTransplantOrbloodTransfusionError: false,
      wasTheTransplatedBoneMarrowYourOwnError: false,
      whoPerformedGeneticTestError: false,
      whoPerformedGeneticTest2Error: false,
      mutationIdentifiedError: false,
      enlargementOfArteryAggError: false,
      aneursmLocatedError: false,
      irregularHeartbeatAggError: false,
      irregularHeartbeatArrhythmia: false,
      tearOfAnArteryAggError: false,
      tearOfAnArteryaneursmLocatedError: false,
      enlargedHeartAggError: false,
      cornealArcusAggError: false,
      cornealArcusValuesError: false,
      hereditaryHeartConditionswhoPerformedGeneticTestError: false,
      mutationIdentifiedForHeridHeartError: false,
      familyTree: {


        parentsAndGrandparents: {
          motherAgeTill: "Now",
          fatherAgeTill: "Now",
          maternamGrandMotherAgeTill: "Now",
          maternamGrandFatherAgeTill: "Now",
          paternamGrandMotherAgeTill: "Now",
          paternalGrandFatherAgeTill: "Now",
          familyTreeStatus:"Complete"

        }
      },
      doesYourMotherHaveAnySiblingssError: false,
      doesYourFatherHaveAnySiblingsError: false,
      familyHealthHistory: {
        personalOrFamilyHistory:"",
      },
      biologicaldaughtersCount: 0,
      biologicalsonsCount: 0,
      sistersCount: 0,
      brothersCount: 0,
      show: true,
      healthHistoryFormErrors: {},
      familyTreeFormErrors: {},
      familyHealthHistoryFormErrors: {},
      //takingAnyMedications:{value:'',medicinesList:''}
      checkedlist: []

    }

    this.changeTab = this.changeTab.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.onContinue = this.onContinue.bind(this)
    this.onContinueFirstPage = this.onContinueFirstPage.bind(this)
    this.handleChangeMultiple = this.handleChangeMultiple.bind(this)
    this.handle = this.handle.bind(this)
    this.handleTall=this.handleTall.bind(this)

    //family tree
    this.handleChangefamilyTree = this.handleChangefamilyTree.bind(this)
    this.handleChangeMultiplefamilyTree = this.handleChangeMultiplefamilyTree.bind(this)
    this.handleChangeAddDatafamilyTree = this.handleChangeAddDatafamilyTree.bind(this)
    this.submitFamilyTree = this.submitFamilyTree.bind(this)
    this.handleChangeFamilyTree=this.handleChangeFamilyTree.bind(this)

    this.FamilyTreeSkip = this.FamilyTreeSkip.bind(this)

    //family health history
    this.handleChangefamilyHealthHistory = this.handleChangefamilyHealthHistory.bind(this)
    this.handleChangeMultiplefamilyHealthHistory = this.handleChangeMultiplefamilyHealthHistory.bind(this)
    this.handleChangeAddDatafamilyHealthHistory = this.handleChangeAddDatafamilyHealthHistory.bind(this)
    this.submitfamilyHealthHistory = this.submitfamilyHealthHistory.bind(this)

    this.handleChangeMultiplefamilyTreeMany = this.handleChangeMultiplefamilyTreeMany.bind(this)

    this.checkHandleChangeAddData = this.checkHandleChangeAddData.bind(this)
    this.checkHandleChangeAgeAddData = this.checkHandleChangeAgeAddData.bind(this)

    //upload image
    this.uploadImage = this.uploadImage.bind(this)

    this.refreshData = this.refreshData.bind(this)

  }
  refreshData() {
    axios.get('users/user/' + Cookies.get("userId"))
      .then((response) => {

        if (response.data) {
          this.setState({

            userData: response.data
          })
          if (this.state.userData.healthDocuments) {
            this.setState({
              noOfFiles: this.state.userData.healthDocuments.length
            })
          }

        }

      })


  }
  componentDidMount() {
    /* fetch health History Data*/
    // this.refreshData()
    // setInterval(this.refreshData, 500); 

    axios.get('healthHistory/findHealthHistoryById/' + Cookies.get("userId"))
      .then((response) => {

        if (response.data) {

          let dob = response.data.dateOfBirth
          if (dob) {
            let birthDate = dob.split('/')
            this.setState({
              dayOfBirth: birthDate[0],
              monthOfBirth: birthDate[1],
              yearOfBirth: birthDate[2]

            })
          }
          let bp = response.data.bloodPressureReading
          if (bp) {


            let bpressure = bp.split('/')
            this.setState({
              bloodPressureMin: bpressure[0],
              bloodPressureMax: bpressure[1]

            })
          }
          let tall = response.data.tall
          if (tall) {

            let finalTall = tall.split('.')
            this.setState({
              heightFeet: finalTall[0],
              heightInches: finalTall[1],
              //heightCms: finalTall[2]

            })
          }
          this.setState({

            healthHistory: response.data
          })


        }

      });

    /* fetch family Tree Data*/
    axios.get('familyTree/findFamilyTreeById/' + Cookies.get("userId"))
      .then((response) => {

        if (response.data) {
          this.setState({

            familyTree: response.data
          })
        }

        if (this.state.familyTree !== null || this.state.familyTree !== "") {

          this.setState({
            showingFamilyTreee: true
          })

        }

        // if (this.state.familyTreeStatus.doesYourFatherHaveAnySiblings !== undefined || this.state.familyTreeStatus.doesYourFatherHaveAnySiblings !== "") {

        //   this.setState({

        //     familyTreeStatus: true

        //   })


        // }


        this.state.familyTree.doYouHaveAnyChildren ? this.state.familyTree.doYouHaveAnyChildren.biologicaldaughters ? this.state.biologicaldaughtersCount = this.state.familyTree.doYouHaveAnyChildren.biologicaldaughters[0].value : this.state.biologicaldaughtersCount = 0 : this.state.biologicaldaughtersCount = 0
        this.state.familyTree.doYouHaveAnyChildren ? this.state.familyTree.doYouHaveAnyChildren.biologicalsons ? this.state.biologicalsonsCount = this.state.familyTree.doYouHaveAnyChildren.biologicalsons[0].value : this.state.biologicalsonsCount = 0 : this.state.biologicalsonsCount = 0
        this.state.familyTree.doYouHaveAnySiblings ? this.state.familyTree.doYouHaveAnySiblings.sisters ? this.state.sistersCount = this.state.familyTree.doYouHaveAnySiblings.sisters[0].value : this.state.sistersCount = 0 : this.state.sistersCount = 0
        this.state.familyTree.doYouHaveAnySiblings ? this.state.familyTree.doYouHaveAnySiblings.brothers ? this.state.brothersCount = this.state.familyTree.doYouHaveAnySiblings.brothers[0].value : this.state.brothersCount = 0 : this.state.brothersCount = 0



      });



    /*fetching familyhealth history data*/

    axios.get('familyHealthHistory/familyHealthHistoryById/' + Cookies.get("userId"))
      .then((response) => {

        if (response.data) {
          this.setState({

            familyHealthHistory: response.data
          })
        }


        // if (this.state.familyHealthHistoryStatus.personalOrFamilyHistory !== undefined || this.state.familyHealthHistoryStatus.personalOrFamilyHistory !== "") {

        //   this.setState({

        //     familyHealthHistoryStatus: true

        //   })


        // }

      });


    /*fetching user data by id*/



  }
  changeTab(value) {
    this.setState({
      isActive: value
    })
    if (value === 1) {

      this.setState({
        title: "YOUR HEALTH HISTORY MATTERS"
      })

    }
    if (value === 2) {

      this.setState({
        title: "BUILD YOUR FAMILY TREE"
      })

    }
    if (value === 3) {

      this.setState({
        title: "RECORD YOUR FAMILY HEALTH HISTORY"
      })

    }
    if (value === 4) {

      this.setState({
        title: "THANK YOU FOR FILLING IN THE INFORMATION."
      })

    }

  }
  FamilyTreeSkip() {

    this.setState({
      isActive: 3
    })

  }

  handle(event) {
    this.setState({ [event.target.name]: event.target.value });
    if(event.target.name==="dayOfBirth"||event.target.name==="monthOfBirth"){
      this.setState({yearOfBirth:''},function(){})
    }
    let { AgeFromDate} = require('age-calculator');
    let ageFromDate = new AgeFromDate(new Date(event.target.value)).age;

    let healthhis = this.state.healthHistory
    healthhis["old"] =ageFromDate;    
    this.setState({healthHistory: healthhis}, function () {
  });


  
  }
  
  handleTall(event) {
    this.setState({ [event.target.name]: event.target.value });
  
  }
  handleChange(event) {
    let healthhis = this.state.healthHistory
    healthhis[event.target.name] = event.target.value;
    this.setState({ healthHistory: healthhis })
    if (event.target.name === "doYouSmoke" || event.target.name === "drinkAlcohol" || event.target.name === "haveDiabetes" || event.target.name === "takemedicationToTreatHighBloodPressure") {
      if (this.state.dayOfBirth == undefined || this.state.dayOfBirth == "" || this.state.monthOfBirth == undefined || this.state.monthOfBirth == "" || this.state.yearOfBirth == undefined || this.state.yearOfBirth == "" ||this.state.healthHistory.old == undefined || this.state.healthHistory.old == "" || this.state.healthHistory.weight == undefined || this.state.healthHistory.weight == "" ||  this.state.heightFeet === undefined || this.state.heightFeet === "" || this.state.heightInches === undefined || this.state.heightInches === "") {
        this.healthHistoryFormValidateForFirstSession()
      } else {
        let errors = {}
        this.setState({
          healthHistoryFormErrors: errors
        });
      }
    }
    //1 st section saving

    if (event.target.name === "doYouSmoke" || event.target.name === "drinkAlcohol") {
    if(this.healthHistoryFormValidateForFirstSession()){
      //if (this.state.dayOfBirth !== undefined && this.state.dayOfBirth !== "" && this.state.monthOfBirth !== undefined && this.state.monthOfBirth !== "" && this.state.yearOfBirth !== undefined && this.state.yearOfBirth !== "" && this.state.healthHistory.old !== undefined && this.state.healthHistory.old !== "" &&this.state.healthHistory.weight !== undefined && this.state.healthHistory.weight !== "" &&  this.state.heightFeet !== "" && this.state.heightFeet !== undefined && this.state.heightInches !== "" && this.state.heightInches !== undefined) {
        this.onContinueSectionWiseHealthHistory()
      }
    }
    // if (event.target.name === "hadLiverTransplant") {
    //   if (this.state.healthHistory.doYouSmoke == undefined || this.state.healthHistory.drinkAlcohol == undefined || this.state.healthHistory.haveDiabetes == "" || this.state.healthHistory.takemedicationToTreatHighBloodPressure == undefined) {
    //     this.healthHistoryFormValidateForSecondSession()
    //   }
    // }
    // if (event.target.name === "hadLiverTransplant") {
    //   if (this.state.healthHistory.doYouSmoke !== undefined && this.state.healthHistory.drinkAlcohol !== undefined && this.state.healthHistory.haveDiabetes !== undefined && this.state.healthHistory.takemedicationToTreatHighBloodPressure !== undefined) {
    //     this.onContinueSectionWiseHealthHistory()
    //   }
    // }

    // if (event.target.name === "bloodTransfusionInTheWeekBeforeProvidingYourTruGeneSample" || event.target.name === "faintingOrPassingOutafterExercise") {
    //   if (this.state.healthHistory.takingAnyMedications == undefined || this.state.healthHistory.hadLiverTransplant == undefined || this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem == undefined) {
    //     this.healthHistoryFormValidateForThirdSession()
    //   }
    // }
    // if (event.target.name === "bloodTransfusionInTheWeekBeforeProvidingYourTruGeneSample" || event.target.name === "faintingOrPassingOutafterExercise") {
    //   if (this.state.healthHistory.takingAnyMedications !== undefined && this.state.healthHistory.takingAnyMedications.value === "true") {
    //     if (this.state.healthHistory.takingAnyMedications.medicinesList === undefined || this.state.healthHistory.takingAnyMedications.medicinesList === "") {
    //       this.setState({
    //         medicinesListError: true
    //       })
    //     } else {
    //       this.setState({
    //         medicinesListError: false
    //       })
    //     }
    //   }


    // }
    //setting errors for sub sequent questions kidney or liver problem?

    // if (event.target.name === "bloodTransfusionInTheWeekBeforeProvidingYourTruGeneSample" || event.target.name === "faintingOrPassingOutafterExercise") {
    //   if (this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem !== undefined && this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem.value === "true") {
    //     if (this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem.problems === undefined || this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem.problems === "") {
    //       this.setState({
    //         problems: true
    //       })
    //     } else {
    //       this.setState({
    //         problems: false
    //       })
    //     }
    //   }


    // }

    // if (event.target.name === "haveHighCholesterol") {
    //   if (this.state.healthHistory.hadCancer == undefined || this.state.healthHistory.colonPolypsIdentifiedOnColonscopyOrSigmoidoscopy == undefined || this.state.healthHistory.boneMarrowTransplantOrbloodTransfusion == undefined || this.state.healthHistory.wasTheTransplatedBoneMarrowYourOwn == undefined || this.state.healthHistory.genetiicTestForHereditaryCancerRisk == undefined || this.state.healthHistory.tearOfAnArtery == undefined || this.state.healthHistory.enlargementOfArtery == undefined || this.state.healthHistory.irregularHeartbeat == undefined || this.state.healthHistory.enlargedHeart == undefined) {
    //     this.healthHistoryFormValidateForFourthSession()
    //   }
    // }

    
 //sub sequence errors for heartAttack

 if (event.target.name === "haveHighCholesterol" || event.target.name === "healthHistoryhereditaryHeartConditions") {
  if (this.state.healthHistory.healthHistoryHeartAttack !== undefined && this.state.healthHistory.healthHistoryHeartAttack.value === "true") {
   
    if (this.state.healthHistory.healthHistoryHeartAttack.age === undefined || this.state.healthHistory.healthHistoryHeartAttack.age === "") {
     
      this.setState({
        heartAttackAggError: true,
      });

    } else {
      this.setState({
        heartAttackAggError: false,
      });
    }

  }
}


//sub sequence errors for Sudden cardiac arrest



if (event.target.name === "haveHighCholesterol" || event.target.name === "healthHistoryhereditaryHeartConditions") {
  if (this.state.healthHistory.suddenCardiacArrest !== undefined && this.state.healthHistory.suddenCardiacArrest.value === "true") {
    if (this.state.healthHistory.suddenCardiacArrest.age === undefined || this.state.healthHistory.suddenCardiacArrest.age === "") {
      this.setState({
        suddenCardiacArrestAggError: true,
      });

    } else {
      this.setState({
        suddenCardiacArrestAggError: false,
      });
    }

  }
}

//sub sequence errors for Stroke



if (event.target.name === "haveHighCholesterol" || event.target.name === "healthHistoryhereditaryHeartConditions") {
  if (this.state.healthHistory.healthHistoryStroke !== undefined && this.state.healthHistory.healthHistoryStroke.value === "true") {
    if (this.state.healthHistory.healthHistoryStroke.age === undefined || this.state.healthHistory.healthHistoryStroke.age === "") {
      this.setState({
        strokeAggError: true,
      });

    } else {
      this.setState({
        strokeAggError: false,
      });
    }

  }
}

if (event.target.name === "haveHighCholesterol" || event.target.name === "healthHistoryhereditaryHeartConditions") {
  if (this.state.healthHistory.healthHistoryHeartAttack !== undefined && this.state.healthHistory.suddenCardiacArrest !== undefined && this.state.healthHistory.healthHistoryStroke !== undefined && this.state.healthHistory.haveHighCholesterol !== undefined) {

    if (this.state.healthHistory.healthHistoryHeartAttack.value === "true" && (this.state.healthHistory.healthHistoryHeartAttack.age === undefined || this.state.healthHistory.healthHistoryHeartAttack.age === "") || this.state.healthHistory.suddenCardiacArrest.value === "true" && (this.state.healthHistory.suddenCardiacArrest.age === undefined || this.state.healthHistory.suddenCardiacArrest.age === "") || this.state.healthHistory.healthHistoryStroke.value === "true" && (this.state.healthHistory.healthHistoryStroke.age === undefined || this.state.healthHistory.healthHistoryStroke.age === "")) {
      return null;
    } else {
      this.onContinueSectionWiseHealthHistory()

    }
    //this.saveDataSectionWiseHealthHistory()
  }
}
  }

  handleChangeMultiple(event) {

    let healthhis = this.state.healthHistory
    healthhis[event.target.name] = { value: event.target.value }
    this.setState({
      healthHistory: healthhis
    })
    /*validations secttionwise*/
    if (event.target.name === "takingAnyMedications" ||  event.target.name === "diagnosedORTreatedForKidneyOrLiverProblem") {
      if (this.state.healthHistory.doYouSmoke == undefined || this.state.healthHistory.drinkAlcohol == undefined || this.state.healthHistory.haveDiabetes == undefined || this.state.healthHistory.takemedicationToTreatHighBloodPressure == undefined) {
        this.healthHistoryFormValidateForSecondSession()
      } else {
        let errors = {}
        this.setState({
          healthHistoryFormErrors: errors
        });
      }
    }
    //27-06-2019 for session wise saving
    if (event.target.name === "takingAnyMedications" || event.target.name === "diagnosedORTreatedForKidneyOrLiverProblem") {
      if (this.state.healthHistory.doYouSmoke !== undefined && this.state.healthHistory.drinkAlcohol !== undefined && this.state.healthHistory.haveDiabetes !== undefined && this.state.healthHistory.takemedicationToTreatHighBloodPressure !== undefined) {
        this.onContinueSectionWiseHealthHistory()
      }
    }

    // if (event.target.name === "hadCancer" || event.target.name === "colonPolypsIdentifiedOnColonscopyOrSigmoidoscopy" || event.target.name === "boneMarrowTransplantOrbloodTransfusion" || event.target.name === "wasTheTransplatedBoneMarrowYourOwn" || event.target.name === "genetiicTestForHereditaryCancerRisk" || event.target.name === "tearOfAnArtery" || event.target.name === "enlargementOfArtery" || event.target.name === "irregularHeartbeat" || event.target.name === "enlargedHeart") {
    //   if (this.state.healthHistory.takingAnyMedications == undefined || this.state.healthHistory.hadLiverTransplant == undefined || this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem == undefined) {
    //     this.healthHistoryFormValidateForThirdSession()
    //   } else {
    //     let errors = {}
    //     this.setState({
    //       healthHistoryFormErrors: errors
    //     });
    //   }
    // }

    // //setting errors for sub sequent questions taking any medications

    // if (event.target.name === "hadCancer" || event.target.name === "colonPolypsIdentifiedOnColonscopyOrSigmoidoscopy" || event.target.name === "boneMarrowTransplantOrbloodTransfusion" || event.target.name === "wasTheTransplatedBoneMarrowYourOwn" || event.target.name === "genetiicTestForHereditaryCancerRisk" || event.target.name === "tearOfAnArtery" || event.target.name === "enlargementOfArtery" || event.target.name === "irregularHeartbeat" || event.target.name === "enlargedHeart") {
    //   if (this.state.healthHistory.takingAnyMedications !== undefined && this.state.healthHistory.takingAnyMedications.value === "true") {
    //     if (this.state.healthHistory.takingAnyMedications.medicinesList === undefined || this.state.healthHistory.takingAnyMedications.medicinesList === "") {
    //       this.setState({
    //         medicinesListError: true
    //       })
    //     } else {
    //       this.setState({
    //         medicinesListError: false
    //       })
    //     }
    //   }


    // }


    // //setting errors for sub sequent questions kidney or liver problem?

    // if (event.target.name === "hadCancer" || event.target.name === "colonPolypsIdentifiedOnColonscopyOrSigmoidoscopy" || event.target.name === "boneMarrowTransplantOrbloodTransfusion" || event.target.name === "wasTheTransplatedBoneMarrowYourOwn" || event.target.name === "genetiicTestForHereditaryCancerRisk" || event.target.name === "tearOfAnArtery" || event.target.name === "enlargementOfArtery" || event.target.name === "irregularHeartbeat" || event.target.name === "enlargedHeart") {
    //   if (this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem !== undefined && this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem.value === "true") {
    //     if (this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem.problems === undefined || this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem.problems === "") {
    //       this.setState({
    //         problems: true
    //       })
    //     } else {
    //       this.setState({
    //         problems: false
    //       })
    //     }
    //   }


    // }


    // if (event.target.name === "hadCancer" || event.target.name === "colonPolypsIdentifiedOnColonscopyOrSigmoidoscopy") {
    //   if (this.state.healthHistory.takingAnyMedications !== undefined && this.state.healthHistory.hadLiverTransplant !== undefined && this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem !== undefined) {
    //     if (this.state.healthHistory.takingAnyMedications.value === "true" && this.state.healthHistory.takingAnyMedications.medicinesList === undefined || this.state.healthHistory.takingAnyMedications.medicinesList === "" || this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem.value === "true" && this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem.problems === undefined || this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem.problems === "") {
    //       return null;
    //     } else {
    //       this.onContinueSectionWiseHealthHistory()

    //     }
    //   }
    // }


    if (event.target.name === "healthHistoryHeartAttack" || event.target.name === "suddenCardiacArrest" || event.target.name === "healthHistoryStroke") {
      if (this.state.healthHistory.hadCancer == undefined ||  this.state.healthHistory.genetiicTestForHereditaryCancerRisk == undefined ) {
        this.healthHistoryFormValidateForFourthSession()
      } else {
        let errors = {}
        this.setState({
          healthHistoryFormErrors: errors
        });
      }
    }
    if (event.target.name === "healthHistoryHeartAttack" || event.target.name === "suddenCardiacArrest" || event.target.name === "healthHistoryStroke") {
      if (this.state.healthHistory.hadCancer !== undefined && this.state.healthHistory.hadCancer.value === "true") {
        if (this.state.healthHistory.hadCancer.cancerType === undefined || this.state.healthHistory.hadCancer.cancerType === "") {
          this.setState({
            hadCancerError: true
          });

        } else {
          this.setState({
            hadCancerError: false
          });
        }

      }
    }
    // if (event.target.name === "heartAttack" || event.target.name === "suddenCardiacArrest" || event.target.name === "stroke") {
    //   if (this.state.healthHistory.colonPolypsIdentifiedOnColonscopyOrSigmoidoscopy !== undefined && this.state.healthHistory.colonPolypsIdentifiedOnColonscopyOrSigmoidoscopy.value === "true") {
    //     if (this.state.healthHistory.colonPolypsIdentifiedOnColonscopyOrSigmoidoscopy.numberOfPolyps === undefined || this.state.healthHistory.colonPolypsIdentifiedOnColonscopyOrSigmoidoscopy.numberOfPolyps === "") {
    //       this.setState({
    //         numberOfPolypsError: true
    //       });

    //     } else {
    //       this.setState({
    //         numberOfPolypsError: false
    //       });
    //     }

    //   }
    // }

    //sub sequence errors for Bone marrow transplant or blood transfusion

    // if (event.target.name === "heartAttack" || event.target.name === "suddenCardiacArrest" || event.target.name === "stroke") {
    //   if (this.state.healthHistory.boneMarrowTransplantOrbloodTransfusion !== undefined && this.state.healthHistory.boneMarrowTransplantOrbloodTransfusion.value === "true") {
    //     if (this.state.healthHistory.boneMarrowTransplantOrbloodTransfusion.boneMarrowTrasplant === undefined) {
    //       this.setState({
    //         boneMarrowTransplantOrbloodTransfusionError: true
    //       });

    //     } else {
    //       this.setState({
    //         boneMarrowTransplantOrbloodTransfusionError: false
    //       });
    //     }

    //   }
    // }


    //sub sequence errors for wasTheTransplatedBoneMarrowYourOwn

    // if (event.target.name === "heartAttack" || event.target.name === "suddenCardiacArrest" || event.target.name === "stroke") {
    //   if (this.state.healthHistory.wasTheTransplatedBoneMarrowYourOwn !== undefined && this.state.healthHistory.wasTheTransplatedBoneMarrowYourOwn.value === "true") {
    //     if (this.state.healthHistory.wasTheTransplatedBoneMarrowYourOwn.bloodTransfusion === undefined) {
    //       this.setState({
    //         wasTheTransplatedBoneMarrowYourOwnError: true
    //       });

    //     } else {
    //       this.setState({
    //         wasTheTransplatedBoneMarrowYourOwnError: false
    //       });
    //     }

    //   }
    // }


    //sub sequence errors for a genetiic test for hereditary cancer risk 

    // if (event.target.name === "heartAttack" || event.target.name === "suddenCardiacArrest" || event.target.name === "stroke") {
    //   if (this.state.healthHistory.genetiicTestForHereditaryCancerRisk !== undefined && this.state.healthHistory.genetiicTestForHereditaryCancerRisk.value === "true") {
    //     if (this.state.healthHistory.genetiicTestForHereditaryCancerRisk.whoPerformedGeneticTest === undefined || this.state.healthHistory.genetiicTestForHereditaryCancerRisk.whoPerformedGeneticTest === "") {
    //       this.setState({
    //         whoPerformedGeneticTestError: true,
    //         mutationIdentifiedError: true
    //       });

    //     } else {
    //       this.setState({
    //         whoPerformedGeneticTestError: false,
    //         mutationIdentifiedError: false
    //       });
    //     }

    //   }
    // }

    // if (event.target.name === "heartAttack" || event.target.name === "suddenCardiacArrest" || event.target.name === "stroke") {
    //   if (this.state.healthHistory.genetiicTestForHereditaryCancerRisk !== undefined && this.state.healthHistory.genetiicTestForHereditaryCancerRisk.value === "true") {
    //     if (this.state.healthHistory.genetiicTestForHereditaryCancerRisk.whoPerformedGeneticTest !== undefined || this.state.healthHistory.genetiicTestForHereditaryCancerRisk.whoPerformedGeneticTest !== "") {
    //       if (this.state.healthHistory.genetiicTestForHereditaryCancerRisk.mutationIdentified === undefined) {
    //         this.setState({
    //           mutationIdentifiedError: true
    //         });
    //       } else {
    //         this.setState({
    //           mutationIdentifiedError: false
    //         });
    //       }


    //     }

    //   }
    // }


    // if (event.target.name === "heartAttack" || event.target.name === "suddenCardiacArrest" || event.target.name === "stroke") {
    //   if (this.state.healthHistory.genetiicTestForHereditaryCancerRisk !== undefined && this.state.healthHistory.genetiicTestForHereditaryCancerRisk.value === "true") {
    //     if (this.state.healthHistory.genetiicTestForHereditaryCancerRisk.mutationIdentified !== undefined && this.state.healthHistory.genetiicTestForHereditaryCancerRisk.mutationIdentified === "yes") {
    //       if (this.state.healthHistory.genetiicTestForHereditaryCancerRisk.whoPerformedGeneticTest2 === undefined || this.state.healthHistory.genetiicTestForHereditaryCancerRisk.whoPerformedGeneticTest2 === "") {
    //         this.setState({
    //           whoPerformedGeneticTest2Error: true
    //         });

    //       } else {
    //         this.setState({
    //           whoPerformedGeneticTest2Error: false
    //         });
    //       }


    //     }

    //   }
    // }


    // if (event.target.name === "heartAttack" || event.target.name === "suddenCardiacArrest" || event.target.name === "stroke") {
    //   if (this.state.healthHistory.enlargementOfArtery !== undefined && this.state.healthHistory.enlargementOfArtery.value[0] === "true") {
    //     if (this.state.healthHistory.enlargementOfArtery.age === undefined || this.state.healthHistory.enlargementOfArtery.age === "" || this.state.healthHistory.enlargementOfArtery.aneursmLocated === "" || this.state.healthHistory.enlargementOfArtery.aneursmLocated === undefined) {
    //       this.setState({
    //         enlargementOfArteryAggError: true,
    //         aneursmLocatedError: true
    //       });

    //     } else {
    //       this.setState({
    //         enlargementOfArteryAggError: false,
    //         aneursmLocatedError: false

    //       });
    //     }

    //   }
    // }


    //sub sequence errors for Tear of an artery

    // if (event.target.name === "heartAttack" || event.target.name === "suddenCardiacArrest" || event.target.name === "stroke") {
    //   if (this.state.healthHistory.tearOfAnArtery !== undefined && this.state.healthHistory.tearOfAnArtery.value[0] === "true") {
    //     if (this.state.healthHistory.tearOfAnArtery.age === undefined || this.state.healthHistory.tearOfAnArtery.age === "" || this.state.healthHistory.tearOfAnArtery.aneursmLocated === undefined || this.state.healthHistory.tearOfAnArtery.aneursmLocated === "") {
    //       this.setState({
    //         tearOfAnArteryAggError: true,
    //         tearOfAnArteryaneursmLocatedError: true

    //       });

    //     } else {
    //       this.setState({
    //         tearOfAnArteryAggError: false,
    //         tearOfAnArteryaneursmLocatedError: false
    //       });
    //     }

    //   }
    // }


    //sub sequence errors for Irregular heartbeat 

    // if (event.target.name === "heartAttack" || event.target.name === "suddenCardiacArrest" || event.target.name === "stroke") {
    //   if (this.state.healthHistory.irregularHeartbeat !== undefined && this.state.healthHistory.irregularHeartbeat.value[0] === "true") {
    //     if (this.state.healthHistory.irregularHeartbeat.age === undefined || this.state.healthHistory.irregularHeartbeat.age === "" || this.state.healthHistory.irregularHeartbeat.arrhythmia === "" || this.state.healthHistory.irregularHeartbeat.arrhythmia === undefined) {
    //       this.setState({
    //         irregularHeartbeatAggError: true,
    //         irregularHeartbeatArrhythmia: true

    //       });

    //     } else {
    //       this.setState({
    //         irregularHeartbeatAggError: false,
    //         irregularHeartbeatArrhythmia: false
    //       });
    //     }

    //   }
    // }


    //sub sequence errors for Enlarged heart

    // if (event.target.name === "healthHistoryHeartAttack" || event.target.name === "suddenCardiacArrest" || event.target.name === "healthHistoryStroke") {
    //   if (this.state.healthHistory.enlargedHeart !== undefined && this.state.healthHistory.enlargedHeart.value === "true") {
    //     if (this.state.healthHistory.enlargedHeart.age === undefined || this.state.healthHistory.enlargedHeart.age === "") {
    //       this.setState({
    //         enlargedHeartAggError: true,
    //       });

    //     } else {
    //       this.setState({
    //         enlargedHeartAggError: false,
    //       });
    //     }

    //   }
    // }


    if (event.target.name === "healthHistoryHeartAttack" || event.target.name === "suddenCardiacArrest") {
      if (this.state.healthHistory.hadCancer !== undefined  && this.state.healthHistory.genetiicTestForHereditaryCancerRisk !== undefined) {

        if (this.state.healthHistory.hadCancer.value[0] === "true" && (this.state.healthHistory.hadCancer.cancerType === undefined || this.state.healthHistory.hadCancer.cancerType === "")  ) {

          return "";
        } else {
          this.onContinueSectionWiseHealthHistory()

        }

      }
    }



    if (event.target.name === "haveHighCholesterol" || event.target.name === "healthHistoryhereditaryHeartConditions") {
      if (this.state.healthHistory.healthHistoryHeartAttack == undefined || this.state.healthHistory.suddenCardiacArrest == undefined || this.state.healthHistory.healthHistoryStroke == undefined || this.state.healthHistory.haveHighCholesterol == undefined) {
        this.healthHistoryFormValidateForFifthSession()
      } else {
        let errors = {}
        this.setState({
          healthHistoryFormErrors: errors
        });
      }
    }

    //sub sequence errors for heartAttack

    if (event.target.name === "haveHighCholesterol" || event.target.name === "healthHistoryhereditaryHeartConditions") {
      if (this.state.healthHistory.healthHistoryHeartAttack !== undefined && this.state.healthHistory.healthHistoryHeartAttack.value === "true") {
       
        if (this.state.healthHistory.healthHistoryHeartAttack.age === undefined || this.state.healthHistory.healthHistoryHeartAttack.age === "") {
         
          this.setState({
            heartAttackAggError: true,
          });

        } else {
          this.setState({
            heartAttackAggError: false,
          });
        }

      }
    }


    //sub sequence errors for Sudden cardiac arrest



    if (event.target.name === "haveHighCholesterol" || event.target.name === "healthHistoryhereditaryHeartConditions") {
      if (this.state.healthHistory.suddenCardiacArrest !== undefined && this.state.healthHistory.suddenCardiacArrest.value === "true") {
        if (this.state.healthHistory.suddenCardiacArrest.age === undefined || this.state.healthHistory.suddenCardiacArrest.age === "") {
          this.setState({
            suddenCardiacArrestAggError: true,
          });

        } else {
          this.setState({
            suddenCardiacArrestAggError: false,
          });
        }

      }
    }

    //sub sequence errors for Stroke



    if (event.target.name === "haveHighCholesterol" || event.target.name === "healthHistoryhereditaryHeartConditions") {
      if (this.state.healthHistory.healthHistoryStroke !== undefined && this.state.healthHistory.healthHistoryStroke.value === "true") {
        if (this.state.healthHistory.healthHistoryStroke.age === undefined || this.state.healthHistory.healthHistoryStroke.age === "") {
          this.setState({
            strokeAggError: true,
          });

        } else {
          this.setState({
            strokeAggError: false,
          });
        }

      }
    }

    if (event.target.name === "haveHighCholesterol" || event.target.name === "healthHistoryhereditaryHeartConditions") {
      if (this.state.healthHistory.healthHistoryHeartAttack !== undefined && this.state.healthHistory.suddenCardiacArrest !== undefined && this.state.healthHistory.healthHistoryStroke !== undefined && this.state.healthHistory.haveHighCholesterol !== undefined) {

        if (this.state.healthHistory.healthHistoryHeartAttack.value === "true" && (this.state.healthHistory.healthHistoryHeartAttack.age === undefined || this.state.healthHistory.healthHistoryHeartAttack.age === "") || this.state.healthHistory.suddenCardiacArrest.value === "true" && (this.state.healthHistory.suddenCardiacArrest.age === undefined || this.state.healthHistory.suddenCardiacArrest.age === "") || this.state.healthHistory.healthHistoryStroke.value === "true" && (this.state.healthHistory.healthHistoryStroke.age === undefined || this.state.healthHistory.healthHistoryStroke.age === "")) {
          return null;
        } else {
          this.onContinueSectionWiseHealthHistory()

        }
        //this.saveDataSectionWiseHealthHistory()
      }
    }



    // if (event.target.name === "hereditaryHeartConditions") {
    //   if (this.state.healthHistory.eyeliidsOrTendons == undefined || this.state.healthHistory.cornealArcus == undefined) {
    //     this.healthHistoryFormValidateForSixthSession()
    //   } else {
    //     let errors = {}
    //     this.setState({
    //       healthHistoryFormErrors: errors
    //     });
    //   }
    // }


    // sub sequence errors for eyeliids options

    // if (event.target.name === "hereditaryHeartConditions") {
    //   if (this.state.healthHistory.eyeliidsOrTendons !== undefined && this.state.healthHistory.eyeliidsOrTendons.value[0] === "true") {
    //     if (this.state.healthHistory.eyeliidsOrTendons.values === undefined || this.state.healthHistory.eyeliidsOrTendons.values === "" || this.state.healthHistory.eyeliidsOrTendons.values.length === 0) {
    //       this.setState({
    //         cornealArcusValuesError: true,

    //       });

    //     } else {
    //       this.setState({
    //         cornealArcusValuesError: false,
    //       });
    //     }

    //   }
    // }


    //sub sequence errors for A white ring around your iris (corneal arcus)

    // if (event.target.name === "hereditaryHeartConditions") {
    //   if (this.state.healthHistory.cornealArcus !== undefined && this.state.healthHistory.cornealArcus.value[0] === "yes") {
    //     if (this.state.healthHistory.cornealArcus.age === undefined || this.state.healthHistory.cornealArcus.age === "" || this.state.healthHistory.cornealArcus.age.length === 0) {
    //       this.setState({
    //         cornealArcusAggError: true,

    //       });

    //     } else {
    //       this.setState({
    //         cornealArcusAggError: false,
    //       });
    //     }

    //   }
    // }


    // if (event.target.name === "hereditaryHeartConditions") {
    //   if (this.state.healthHistory.eyeliidsOrTendons !== undefined && this.state.healthHistory.cornealArcus !== undefined) {

    //       this.onContinueSectionWiseHealthHistory()

        
    //     //this.saveDataSectionWiseHealthHistory()
    //   }
    // }



    /*validations secttionwise end*/


  }
  handleChangeAddData(event, name) {



    let data = this.state.healthHistory[name]
    data[event.target.name] = event.target.value

    let healthhis = this.state.healthHistory
    healthhis[name] = data
    this.setState({
      healthHistory: healthhis
    })

  }
  checkHandleChangeAddData(event, name, subname) {

    let hh = this.state.healthHistory
    if (hh[name] === undefined) {
      hh[name] = { value: [event.target.value] }
    } else {
      hh[name].value = [event.target.value]
    }

    this.setState({
      healthHistory: hh
    })

    if (event.target.name === "eyeliidsOrTendons" || event.target.name === "cornealArcus") {
      if (this.state.healthHistory.heartAttack == undefined || this.state.healthHistory.suddenCardiacArrest == undefined || this.state.healthHistory.healthHistoryStroke == undefined || this.state.healthHistory.haveHighCholesterol == undefined) {
        this.healthHistoryFormValidateForFifthSession()
      } else {
        let errors = {}
        this.setState({
          healthHistoryFormErrors: errors
        });
      }
    }

    //sub sequence errors for heartAttack

    if (event.target.name === "eyeliidsOrTendons" || event.target.name === "cornealArcus") {
      if (this.state.healthHistory.heartAttack !== undefined && this.state.healthHistory.heartAttack.value === "true") {
        if (this.state.healthHistory.heartAttack.age === undefined || this.state.healthHistory.heartAttack.age === "") {
          this.setState({
            heartAttackAggError: true,
          });

        } else {
          this.setState({
            heartAttackAggError: false,
          });
        }

      }
    }


    //sub sequence errors for Sudden cardiac arrest



    if (event.target.name === "haveHighCholesterol") {
      if (this.state.healthHistory.suddenCardiacArrest !== undefined && this.state.healthHistory.suddenCardiacArrest.value === "true") {
        if (this.state.healthHistory.suddenCardiacArrest.age === undefined || this.state.healthHistory.suddenCardiacArrest.age === "") {
          this.setState({
            suddenCardiacArrestAggError: true,
          });

        } else {
          this.setState({
            suddenCardiacArrestAggError: false,
          });
        }

      }
    }

    //sub sequence errors for Stroke



    if (event.target.name === "haveHighCholesterol") {
      if (this.state.healthHistory.healthHistoryStroke !== undefined && this.state.healthHistory.healthHistoryStroke.value === "true") {
        if (this.state.healthHistory.healthHistoryStroke.age === undefined || this.state.healthHistory.healthHistoryStroke.age === "") {
          this.setState({
            strokeAggError: true,
          });

        } else {
          this.setState({
            strokeAggError: false,
          });
        }

      }
    }


    if (event.target.name === "haveHighCholesterol") {
      if (this.state.healthHistory.healthHistoryHeartAttack !== undefined && this.state.healthHistory.suddenCardiacArrest !== undefined && this.state.healthHistory.healthHistoryStroke !== undefined && this.state.healthHistory.haveHighCholesterol !== undefined) {

        if (this.state.healthHistory.healthHistoryHeartAttack.value === "true" && (this.state.healthHistory.healthHistoryHeartAttack.age === undefined || this.state.healthHistory.healthHistoryHeartAttack.age === "") || this.state.healthHistory.suddenCardiacArrest.value === "true" && (this.state.healthHistory.suddenCardiacArrest.age === undefined || this.state.healthHistory.suddenCardiacArrest.age === "") || this.state.healthHistory.healthHistoryStroke.value === "true" && (this.state.healthHistory.healthHistoryStroke.age === undefined || this.state.healthHistory.healthHistoryStroke.age === "")) {
          return null;
        } else {
          this.onContinueSectionWiseHealthHistory()

        }

      }
    }



  }
  checkHandleChangeAddDataFamilyHealthHistory(event, name, subname) {

    let hh = this.state.familyHealthHistory
    if (hh[name] === undefined) {
      hh[name] = { value: [event.target.value] }
    } else {
      hh[name].value = [event.target.value]
    }

    this.setState({
      familyHealthHistory: hh
    })



  }

  checkHandleChangeAddDataMutationFamilyHealthHistory(event, name, subname) {

    let hh = this.state.familyHealthHistory
    if (hh[name] === undefined) {
      hh[name] = { mutationIdentifiedForHereditaryHeart: [event.target.value] }
    } else {
      hh[name].mutationIdentifiedForHereditaryHeart = [event.target.value]
    }

    this.setState({
      familyHealthHistory: hh
    })



  }

  checkHandleChangeAddDataMutationFatherFamilyHealthHistory(event, name, subname) {

    let hh = this.state.familyHealthHistory
    if (hh[name] === undefined) {
      hh[name] = { FatherMutation: [event.target.value] }
    } else {
      hh[name].FatherMutation = [event.target.value]
    }

    this.setState({
      familyHealthHistory: hh
    })



  }

  checkHandleChangeFamilyHealthHistoryHeartFailureSiblingsAge(event, name, subname) {

    let hh = this.state.familyHealthHistory
    if (hh[name] === undefined) {
      hh[name] = { SiblingsAge: [event.target.value] }
    } else {
      hh[name].SiblingsAge = [event.target.value]
    }

    this.setState({
      familyHealthHistory: hh
    })



  }
  checkHandleChangeAddDataMutationSiblingsFamilyHealthHistory(event, name, subname) {

    let hh = this.state.familyHealthHistory
    if (hh[name] === undefined) {
      hh[name] = { SiblingsMutation: [event.target.value] }
    } else {
      hh[name].SiblingsMutation = [event.target.value]
    }

    this.setState({
      familyHealthHistory: hh
    })




  }
  checkHandleChangeAgeAddData(event, name, subname) {
    let hh = this.state.healthHistory
    if (hh[name] === undefined) {
      hh[name] = { age: [event.target.value] }
    } else {
      hh[name].age = [event.target.value]
    }

    this.setState({
      healthHistory: hh
    })

  }
  checkHandleChangeOtherCancer(event, name, subname) {
    let hh = this.state.healthHistory
    if (hh[name] === undefined) {
      hh[name] = { OtherCancerValue: [event.target.value] }
    } else {
      hh[name].OtherCancerValue = [event.target.value]
    }

    this.setState({
      healthHistory: hh
    })

  }
  checkHandleChangeAgeAddData2(event, name, subname) {
    let hh = this.state.healthHistory
    if (hh[name] === undefined) {
      hh[name] = { other: [event.target.value] }
    } else {
      hh[name].other = [event.target.value]
    }

    this.setState({
      healthHistory: hh
    })

  }

  checkHandleChangeFamilyHealthHistory(e, names, subname, name) {
    let healthhis = this.state.familyHealthHistory
    let lists = healthhis[names];
    let value = e.target.value;
    let aneurysm = lists[subname]


    if (aneurysm === undefined) {

      lists[subname] = [e.target.value];
      //healthhis[subname] = aneurysm
    } else {
      if (e.target.checked) {
        aneurysm.push(e.target.value)
        // healthhis[subname] = aneurysm
      } else {
        // const index = aneurysm.findIndex((ch) => ch.id === value);
        // console.log('aneurysm.........',aneurysm)
        // alert(value)
        // aneurysm.splice(value);
        var index = aneurysm.indexOf(e.target.value)
        if (index !== -1) {
          aneurysm.splice(index, 1);
          //this.setState({people: array});
        }
        //aneurysm.splice(aneurysm.findIndex(obj => obj.value ===  e.target.value), 1)
        //healthhis[subname] = aneurysm

      }
    }

    this.setState({ familyHealthHistory: healthhis })

  }

  checkHandleChangemotherOtherCancer(event, name, subname) {
    let hh = this.state.familyHealthHistory
    if (hh[name] === undefined) {
      hh[name] = { motherOtherCancer: [event.target.value] }
    } else {
      hh[name].motherOtherCancer = [event.target.value]
    }

    this.setState({
      familyHealthHistory: hh
    })


  }

  checkHandleChangeFatherOtherCancer(event, name, subname) {
    let hh = this.state.familyHealthHistory
    if (hh[name] === undefined) {
      hh[name] = { FatherOtherCancer: [event.target.value] }
    } else {
      hh[name].FatherOtherCancer = [event.target.value]
    }

    this.setState({
      familyHealthHistory: hh
    })


  }

  checkHandleChangeSiblingsOtherCancer(event, name, subname) {
    let hh = this.state.familyHealthHistory
    if (hh[name] === undefined) {
      hh[name] = { SiblingsOtherCancer: [event.target.value] }
    } else {
      hh[name].SiblingsOtherCancer = [event.target.value]
    }

    this.setState({
      familyHealthHistory: hh
    })

  }

  checkHandleChangeMaternalGrandfatherOtherCancer(event, name, subname) {

    let hh = this.state.familyHealthHistory
    if (hh[name] === undefined) {
      hh[name] = { MaternalGrandfatherOtherCancer: [event.target.value] }
    } else {
      hh[name].MaternalGrandfatherOtherCancer = [event.target.value]
    }

    this.setState({
      familyHealthHistory: hh
    })

  }

  checkHandleChangePaternalGrandfatherOtherCancer(event, name, subname) {

    let hh = this.state.familyHealthHistory
    if (hh[name] === undefined) {
      hh[name] = { PaternalGrandfatherOtherCancer: [event.target.value] }
    } else {
      hh[name].PaternalGrandfatherOtherCancer = [event.target.value]
    }

    this.setState({
      familyHealthHistory: hh
    })

  }

  checkHandleChangeMaternalGrandmotherOtherCancer(event, name, subname) {

    let hh = this.state.familyHealthHistory
    if (hh[name] === undefined) {
      hh[name] = { MaternalGrandmotherOtherCancer: [event.target.value] }
    } else {
      hh[name].MaternalGrandmotherOtherCancer = [event.target.value]
    }

    this.setState({
      familyHealthHistory: hh
    })

  }

  checkHandleChangePaternalGrandmotherOtherCancer(event, name, subname) {

    let hh = this.state.familyHealthHistory
    if (hh[name] === undefined) {
      hh[name] = { PaternalGrandmotherOtherCancer: [event.target.value] }
    } else {
      hh[name].PaternalGrandmotherOtherCancer = [event.target.value]
    }

    this.setState({
      familyHealthHistory: hh
    })

  }

  checkHandleChangeFamilyHealthHistoryAgeAddData(event, name, subname) {
    let hh = this.state.familyHealthHistory
    if (hh[name] === undefined) {
      hh[name] = { whoPerfomedGeneticTest: [event.target.value] }
    } else {
      hh[name].whoPerfomedGeneticTest = [event.target.value]
    }

    this.setState({
      familyHealthHistory: hh
    })


  }

  checkHandleChangeFamilyHealthHistoryHeartFailureMotherAge(event, name, subname) {
    let hh = this.state.familyHealthHistory
    if (hh[name] === undefined) {
      hh[name] = { motherAge: [event.target.value] }
    } else {
      hh[name].motherAge = [event.target.value]
    }

    this.setState({
      familyHealthHistory: hh
    })


  }

  checkHandleChangeFamilyHealthHistoryHeartFailureFatherAge(event, name, subname) {
    let hh = this.state.familyHealthHistory
    if (hh[name] === undefined) {
      hh[name] = { fatherAge: [event.target.value] }
    } else {
      hh[name].fatherAge = [event.target.value]
    }

    this.setState({
      familyHealthHistory: hh
    })


  }
  checkHandleChangeFamilyHealthHistoryHeartFailureMaternalGrandmotherAge(event, name, subname) {
    let hh = this.state.familyHealthHistory
    if (hh[name] === undefined) {
      hh[name] = { MaternalGrandmotherAge: [event.target.value] }
    } else {
      hh[name].MaternalGrandmotherAge = [event.target.value]
    }

    this.setState({
      familyHealthHistory: hh
    })


  }

  checkHandleChangeFamilyHealthHistoryHeartFailureMaternalGrandfatherAge(event, name, subname) {
    let hh = this.state.familyHealthHistory
    if (hh[name] === undefined) {
      hh[name] = { MaternalGrandfatherAge: [event.target.value] }
    } else {
      hh[name].MaternalGrandfatherAge = [event.target.value]
    }

    this.setState({
      familyHealthHistory: hh
    })


  }
  checkHandleChangeFamilyHealthHistoryHeartFailurePaternalGrandmotherAge(event, name, subname) {
    let hh = this.state.familyHealthHistory
    if (hh[name] === undefined) {
      hh[name] = { PaternalGrandmotherAge: [event.target.value] }
    } else {
      hh[name].PaternalGrandmotherAge = [event.target.value]
    }

    this.setState({
      familyHealthHistory: hh
    })

  }
  checkHandleChangeFamilyHealthHistoryHeartFailurePaternalGrandfatherAge(event, name, subname) {
    let hh = this.state.familyHealthHistory
    if (hh[name] === undefined) {
      hh[name] = { PaternalGrandfatherAge: [event.target.value] }
    } else {
      hh[name].PaternalGrandfatherAge = [event.target.value]
    }

    this.setState({
      familyHealthHistory: hh
    })


  }
  checkHandleChange(e, names, subname, name) {
    let healthhis = this.state.healthHistory
    let lists = healthhis[names];
    let value = e.target.value;
    let aneurysm = lists[subname]


    if (aneurysm === undefined) {

      lists[subname] = [e.target.value];
      //healthhis[subname] = aneurysm
    } else {
      if (e.target.checked) {
        aneurysm.push(e.target.value)
        // healthhis[subname] = aneurysm
      } else {
        const index = aneurysm.findIndex((ch) => ch.id === value);
        aneurysm.splice(index, 1);
        //aneurysm.splice(aneurysm.findIndex(obj => obj.value ===  e.target.value), 1)
        //healthhis[subname] = aneurysm

      }
    }

    this.setState({ healthHistory: healthhis })







  }

  onContinueSectionWiseHealthHistory() {
    let helathhis = this.state.healthHistory
      if (this.state.dayOfBirth !== undefined && this.state.monthOfBirth !== undefined && this.state.yearOfBirth !== undefined) {
      let dateOfBirth = this.state.dayOfBirth + "/" + this.state.monthOfBirth + "/" + this.state.yearOfBirth
      helathhis['dateOfBirth'] = dateOfBirth;
      let tall = this.state.heightFeet + "." + this.state.heightInches
      helathhis['tall'] = tall;

    }

    if (this.state.bloodPressureMax !== undefined && this.state.bloodPressureMin !== undefined) {
      let bloodPressureReading = this.state.bloodPressureMin + "/" + this.state.bloodPressureMax
      helathhis['bloodPressureReading'] = bloodPressureReading;


    }
    this.setState({ healthHistory: helathhis }, function () {
      this.saveDataSectionWiseHealthHistory()
    })

  }
  saveDataSectionWiseHealthHistory() {



    //if (this.healthHistoryFormValidate()) {
    axios.post('healthHistory/savehealthHistory/' + Cookies.get('userId'), this.state.healthHistory)
      .then((response) => {

        //this.changeTab(2);
      }).catch(
        error => {

          if (error.response === undefined) {
            this.props.history.push("/error")
          } else {
            this.setState({
              errorMsg: error.response.data.message
            })
          }
        }
      )
    // }
  }
  /* FORM VALIDATIONS SECTIONWISE */
  healthHistoryFormValidate() {
    let fields = this.state.healthHistory;
    let hadCancerfields = this.state.healthHistory.hadCancer;
    let healthHistoryHeartAttackfields=this.state.healthHistory.healthHistoryHeartAttack
    let suddenCardiacArrestfields=this.state.healthHistory.suddenCardiacArrest
    let healthHistoryStrokefields=this.state.healthHistory.healthHistoryStroke
    let healthenlargedHeartFileds=this.state.healthHistory.enlargedHeart
    let firstFields = this.state;
    let errors = {};
    let formIsValid = true;

    if (!fields["weight"]) {
      formIsValid = false;
      errors["weight"] = "*Please enter your weight.";
      this.validationsForHealthHistorySession("check weight")    }


    if (!firstFields["monthOfBirth"]) {
      formIsValid = false;
      errors["monthOfBirth"] = "*Please enter your monthOfBirth.";
    }

    if (!firstFields["dayOfBirth"]) {
      formIsValid = false;
      errors["dayOfBirth"] = "*Please enter your dayOfBirth.";
    }

    if (!firstFields["yearOfBirth"]) {
      formIsValid = false;
      errors["yearOfBirth"] = "*Please enter your yearOfBirth.";
      window.scrollTo(500,500)
    }
    if (!firstFields["heightFeet"]) {
      formIsValid = false;
      errors["heightFeet"] = "*Please enter your heightFeet.";
    }
    if (!firstFields["heightInches"]) {
      formIsValid = false;
      errors["heightInches"] = "*Please enter your heightInches.";
    }


  if (fields["heightInches"] < 0 || fields["heightInches"] > 12) {

      formIsValid = false;

      errors["heightInches"] = "*Please enter your valid heightInches.";

    }
	if (fields["heightFeet"] < 0 || fields["heightFeet"] > 6) {

      formIsValid = false;

      errors["heightFeet"] = "*Please enter your valid heightFeet.";
	  }
	  //   if (fields["yearOfBirth"].length != 4){

    //   formIsValid = false;

    //   errors["yearOfBirth"] = "*Please enter your valid yearOfBirth.";

    // }
	if (fields["dayOfBirth"]<0 || fields["dayOfBirth"]>31) {

      formIsValid = false;

      errors["dayOfBirth"] = "*Please enter your valid dayOfBirth.";

    }
	 if (fields["monthOfBirth"]<0 || fields["monthOfBirth"]>12 ) {

      formIsValid = false;

      errors["monthOfBirth"] = "*Please enter a valid monthOfBirth.";

    }
    // if (!firstFields["heightCms"]) {
    //   formIsValid = false;
    //   errors["heightCms"] = "*Please enter your heightCms.";
    // }
    // if (!firstFields["bloodPressureMax"]) {
    //   formIsValid = false;
    //   errors["bloodPressureMax"] = "*Please enter your blood Pressure Max.";
    // }
    // if (!firstFields["bloodPressureMin"]) {
    //   formIsValid = false;
    //   errors["bloodPressureMin"] = "*Please enter your blood Pressure Min.";
    // }
    if (!fields["doYouSmoke"]) {
      formIsValid = false;
      errors["doYouSmoke"] = "*Mandatory options Smoke.";
    }
    if (!fields["drinkAlcohol"]) {
      formIsValid = false;
      errors["drinkAlcohol"] = "*Mandatory options drinkAlcohol.";
    }
    if (!fields["haveDiabetes"]) {
      formIsValid = false;
      errors["haveDiabetes"] = "*Required Diabetes.";
    }

    if (!fields["takemedicationToTreatHighBloodPressure"]) {
      formIsValid = false;
      errors["takemedicationToTreatHighBloodPressure"] = "*This field is Required.";
    }

    if (!fields["takingAnyMedications"]) {
      formIsValid = false;
      errors["takingAnyMedications"] = "*Required Medications field.";
    }


    // if (!fields["hadLiverTransplant"]) {
    //   formIsValid = false;
    //   errors["hadLiverTransplant"] = "*This field is Required .";
    // }

    if (!fields["diagnosedORTreatedForKidneyOrLiverProblem"]) {
      formIsValid = false;
      errors["diagnosedORTreatedForKidneyOrLiverProblem"] = "*This field is Required.";
    }

    if (!fields["hadCancer"]) {
      formIsValid = false;
      errors["hadCancer"] = "*Required Cancer field.";
      window.scrollTo(500,300)
    }

    if (this.state.healthHistory.hadCancer && this.state.healthHistory.hadCancer.value[0] === "true") {
      if (hadCancerfields["cancerType"] === undefined || hadCancerfields["cancerType"].length === 0) {
        formIsValid = false;
        errors["cancerType"] = swal("*Please check one of the option from Had cancer...");
        window.scrollTo(500, 300);

      }
    }

    // if (!fields["colonPolypsIdentifiedOnColonscopyOrSigmoidoscopy"]) {
    //   formIsValid = false;
    //   errors["colonPolypsIdentifiedOnColonscopyOrSigmoidoscopy"] = "*This field is Required .";
    // }

    // if (!fields["boneMarrowTransplantOrbloodTransfusion"]) {
    //   formIsValid = false;
    //   errors["boneMarrowTransplantOrbloodTransfusion"] = "*This field is Required.";
    // }
    // if (!fields["wasTheTransplatedBoneMarrowYourOwn"]) {
    //   formIsValid = false;
    //   errors["wasTheTransplatedBoneMarrowYourOwn"] = "*This field is Required.";
    // }
    if (!fields["genetiicTestForHereditaryCancerRisk"]) {
      formIsValid = false;
      errors["genetiicTestForHereditaryCancerRisk"] = "*This field is Required.";
      this.validationsForHealthHistorySession("check genetiicTestForHereditaryCancerRisk")

    }
    // if (!fields["tearOfAnArtery"]) {
    //   formIsValid = false;
    //   errors["tearOfAnArtery"] = "*This field is Required.";
    //   this.validationsForHealthHistorySession("check fields")

    // }
    // if (!fields["enlargementOfArtery"]) {
    //   formIsValid = false;
    //   errors["enlargementOfArtery"] = "*This field is Required.";
    //   this.validationsForHealthHistorySession("check fields")

    // }

    // if (!fields["irregularHeartbeat"]) {
    //   formIsValid = false;
    //   errors["irregularHeartbeat"] = "*This field is Required.";
    // }



    // if (!fields["enlargedHeart"]) {
    //   formIsValid = false;
    //   errors["enlargedHeart"] = "*This field is Required.";
    //   this.validationsForHealthHistorySession("check fields")

    // }

    // if (!fields["bloodTransfusionInTheWeekBeforeProvidingYourTruGeneSample"]) {
    //   formIsValid = false;
    //   errors["bloodTransfusionInTheWeekBeforeProvidingYourTruGeneSample"] = "*This field is Required.";
    // }
    if (!fields["faintingOrPassingOutafterExercise"]) {
      formIsValid = false;
      errors["faintingOrPassingOutafterExercise"] = "*This field is Required.";
      this.validationsForHealthHistorySession("check fields")

    }

    if (!fields["healthHistoryHeartAttack"]) {
      formIsValid = false;
      errors["heartAttack"] = "*Required healthHistoryHeartAttack field.";
      this.validationsForHealthHistorySession("check 2fields")
    }

    if (this.state.healthHistory.healthHistoryHeartAttack && this.state.healthHistory.healthHistoryHeartAttack.value === "true") {
      if (healthHistoryHeartAttackfields["age"] === undefined || healthHistoryHeartAttackfields["age"] === "") {
        formIsValid = false;
        this.setState({
          heartAttackAggError: true,
        });
        swal("*Please fill age from Heart Attack...");
        this.validationsForHealthHistorySession("check 2fields")

      }
      else{
        this.setState({
          heartAttackAggError: false,
        });
      }
    }

    if (!fields["suddenCardiacArrest"]) {
      formIsValid = false;
      errors["suddenCardiacArrest"] = "*This field is Required .";
      this.validationsForHealthHistorySession("check 2fields")

    }

    if (this.state.healthHistory.suddenCardiacArrest && this.state.healthHistory.suddenCardiacArrest.value === "true") {
      if (suddenCardiacArrestfields["age"] === undefined || suddenCardiacArrestfields["age"] === "") {
        formIsValid = false;
        this.setState({
          suddenCardiacArrestAggError: true,
        });
        swal("*Please fill age from Sudden cardiac Attack...");
        this.validationsForHealthHistorySession("check 2fields")

      }
      else{
        this.setState({
          suddenCardiacArrestAggError: false,
        });
      }
    }

    if (!fields["healthHistoryStroke"]) {
      formIsValid = false;
      errors["healthHistoryStroke"] = "*This field is Required.";
      this.validationsForHealthHistorySession("check 2fields")

    }

    if (this.state.healthHistory.healthHistoryStroke && this.state.healthHistory.healthHistoryStroke.value === "true") {
      if (healthHistoryStrokefields["age"] === undefined || healthHistoryStrokefields["age"] === "") {
        formIsValid = false;
        this.setState({
          strokeAggError: true,
        });
        swal("*Please fill age from Stroke...");
        this.validationsForHealthHistorySession("check 2fields")

      }
      else{
        this.setState({
          strokeAggError: false,
        });
      }
    }
    // if (this.state.healthHistory.enlargedHeart && this.state.healthHistory.enlargedHeart.value === "true") {
    //   if (healthenlargedHeartFileds["age"] === undefined || healthenlargedHeartFileds["age"] === "") {
    //     formIsValid = false;
    //     this.setState({
    //       enlargedHeartAggError: true,
    //     });
    //     swal("*Please fill age from Enlarged heart...");
    //     this.validationsForHealthHistorySession("check 2fields")

    //   }else{
    //     this.setState({
    //       enlargedHeartAggError: false,
    //     });
    //   }
    // }
    
    if (!fields["haveHighCholesterol"]) {
      formIsValid = false;
      errors["haveHighCholesterol"] = "*This field is Required.";
      this.validationsForHealthHistorySession("check 2fields")

    }
    // if (!fields["eyeliidsOrTendons"]) {
    //   formIsValid = false;
    //   errors["eyeliidsOrTendons"] = "*Required  field.";
    // }


    // if (!fields["cornealArcus"]) {
    //   formIsValid = false;
    //   errors["cornealArcus"] = "*This field is Required .";
    // }

    if (!fields["healthHistoryhereditaryHeartConditions"]) {
      formIsValid = false;
      errors["hereditaryHeartConditions"] = "*This field is Required .";
    }

    

    this.setState({
      healthHistoryFormErrors: errors,

    });
    return formIsValid;
  }
  healthHistoryFormValidateFirstPage() {
    let fields = this.state.healthHistory;
    let firstFields = this.state;
    let subFieldForKidneYproblem = this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem;
    let errors = {};
    let formIsValid = true;
    if (!fields["weight"]) {
      formIsValid = false;
      errors["weight"] = "*Please enter your weight.";
      this.validationsForHealthHistorySession("check weight")
      window.scrollTo(500,800)
    }


    if (!firstFields["monthOfBirth"]) {
      formIsValid = false;
      errors["monthOfBirth"] = "*Please enter your monthOfBirth.";
      window.scrollTo(500,500)
    }



    if (!firstFields["dayOfBirth"]) {
      formIsValid = false;
      errors["dayOfBirth"] = "*Please enter your dayOfBirth.";
      window.scrollTo(500,500)
    }

    if (!firstFields["yearOfBirth"]) {
      formIsValid = false;
      errors["yearOfBirth"] = "*Please enter your yearOfBirth.";
      window.scrollTo(500,500)
    }
    if (!firstFields["heightFeet"]) {
      formIsValid = false;
      errors["heightFeet"] = "*Please enter your heightFeet.";
      window.scrollTo(500,700)
    }
    if (!firstFields["heightInches"]) {
      formIsValid = false;
      errors["heightInches"] = "*Please enter your heightInches.";
      window.scrollTo(500,700)
    }
    if (firstFields["heightInches"] < 0 || firstFields["heightInches"] > 12) {
      formIsValid = false;
      errors["heightInches"] = "*Please enter your valid heightInches.";
      window.scrollTo(500,700)
    }
	if (firstFields["heightFeet"] < 0 || firstFields["heightFeet"] > 6) {
      formIsValid = false;
      errors["heightFeet"] = "*Please enter your valid heightFeet.";
      window.scrollTo(500,700)
	  }
	    if (firstFields["yearOfBirth"].length != 4){
      
      formIsValid = false;
      errors["yearOfBirth"] = "*Please enter your valid yearOfBirth.";
      window.scrollTo(500,500)
    }
	if (firstFields["dayOfBirth"]<0 || firstFields["dayOfBirth"]>31) {
      formIsValid = false;
      errors["dayOfBirth"] = "*Please enter your valid dayOfBirth.";
      window.scrollTo(500,500)
    }
	 if (firstFields["monthOfBirth"]<0 || firstFields["monthOfBirth"]>12 ) {
      formIsValid = false;
      errors["monthOfBirth"] = "*Please enter a valid monthOfBirth.";
      window.scrollTo(500,500)
    }
    if (firstFields["monthOfBirth"]<0 || firstFields["monthOfBirth"]>12 ) {
      formIsValid = false;
      errors["monthOfBirth"] = "*Please enter a valid monthOfBirth.";
      window.scrollTo(500,500)
    }
    // if (!firstFields["heightCms"]) {
    //   formIsValid = false;
    //   errors["heightCms"] = "*Please enter your heightCms.";
    // }
    // if (!firstFields["bloodPressureMax"]) {
    //   formIsValid = false;
    //   errors["bloodPressureMax"] = "*Please enter your blood Pressure Max.";
    // }
    // if (!firstFields["bloodPressureMin"]) {
    //   formIsValid = false;
    //   errors["bloodPressureMin"] = "*Please enter your blood Pressure Min.";
    // }
    if (!fields["doYouSmoke"]) {
      formIsValid = false;
      errors["doYouSmoke"] = "*Mandatory options Smoke.";
      window.scrollTo(500,1500)
    }
    if (!fields["drinkAlcohol"]) {
      formIsValid = false;
      errors["drinkAlcohol"] = "*Mandatory options drinkAlcohol.";
      window.scrollTo(500,1500)
    }
    if (!fields["haveDiabetes"]) {
      formIsValid = false;
      errors["haveDiabetes"] = "*Required Diabetes.";
      window.scrollTo(500,1500)
    }

    if (!fields["takemedicationToTreatHighBloodPressure"]) {
      formIsValid = false;
      errors["takemedicationToTreatHighBloodPressure"] = "*This field is Required.";
      window.scrollTo(500,1500)
    }

    if (!fields["takingAnyMedications"]) {
      formIsValid = false;
      errors["takingAnyMedications"] = "*Required Medications field.";
    }

    if (!fields["takingAnyMedications"]) {
      formIsValid = false;
      errors["takingAnyMedications"] = "*Required Medications field.";
    }
    if(this.state.healthHistory.takingAnyMedications!==undefined&&this.state.healthHistory.takingAnyMedications.value==="true"){

      if(this.state.healthHistory.takingAnyMedications.medicinesList===undefined||this.state.healthHistory.takingAnyMedications.medicinesList===""){
        formIsValid = false;
        swal("Medications list should not be empty")
      }

    }


    // if (!fields["hadLiverTransplant"]) {
    //   formIsValid = false;
    //   errors["hadLiverTransplant"] = "*This field is Required .";
    // }

    if (!fields["diagnosedORTreatedForKidneyOrLiverProblem"]) {
      formIsValid = false;
      errors["diagnosedORTreatedForKidneyOrLiverProblem"] = "*This field is Required.";
    }

    if (this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem && this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem.value[0] === "true") {
      if (subFieldForKidneYproblem["problems"] === undefined || subFieldForKidneYproblem["problems"].length === 0) {
        formIsValid = false;
        errors["problems"] = swal("*Please check one of the option from diagnosed or treated...");
        window.scrollTo(1000,1500)

      }
    }

    this.setState({
      healthHistoryFormErrors: errors,

    });
    return formIsValid;

  }
  healthHistoryFormValidateFirstPageKidneyProblem() {

    let subFieldForKidneYproblem = this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem;
    let errors = {};
    let formIsValid = true;


    if (this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem && this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem.value[0] === "yes") {
      if (subFieldForKidneYproblem["problems"] === undefined || subFieldForKidneYproblem["problems"].length === 0) {
        formIsValid = false;
        errors["problems"] = alert("*Please check one of the option from Had cancer...");

      }
    }


    this.setState({
      healthHistoryFormErrors: errors,

    });
    return formIsValid;

  }
  validationsForHealthHistorySession(name){
    document.getElementById(name).scrollIntoView();

  }
  healthHistoryFormValidateForFirstSession() {

    let fields = this.state;
    let fieldshelathHistory = this.state.healthHistory;
    let errors = {};
    let formIsValid = true;
    
    if (!fieldshelathHistory["weight"]) {
      formIsValid = false;
      errors["weight"] = "*Please enter your weight.";
      this.validationsForHealthHistorySession("check weight")
    }

    if (!fieldshelathHistory["old"]) {
      formIsValid = false;
      errors["old"] = "*Please enter your Age.";
    }


    if (!fields["monthOfBirth"]) {
      formIsValid = false;
      errors["monthOfBirth"] = "*Please enter your monthOfBirth.";
    }
    if (fields["monthOfBirth"]<0 || fields["monthOfBirth"]>12 ) {
      formIsValid = false;
      errors["monthOfBirth"] = "*Please enter a valid monthOfBirth.";
    }

    if (!fields["dayOfBirth"]) {
      formIsValid = false;
      errors["dayOfBirth"] = "*Please enter your dayOfBirth.";
    }
    if (fields["dayOfBirth"]<0 || fields["dayOfBirth"]>31) {
      formIsValid = false;
      errors["dayOfBirth"] = "*Please enter your valid dayOfBirth.";
    }

    if (!fields["yearOfBirth"]) {
      formIsValid = false;
      errors["yearOfBirth"] = "*Please enter your yearOfBirth.";
      window.scrollTo(500,500)
    }
    if (fields["yearOfBirth"].length != 4){
    
      formIsValid = false;
      errors["yearOfBirth"] = "*Please enter your valid yearOfBirth.";
      window.scrollTo(500,500)
    }

    if (!fields["heightFeet"]) {
      formIsValid = false;
      errors["heightFeet"] = "*Please enter your heightFeet.";
    }
    if (fields["heightFeet"] < 0 || fields["heightFeet"] > 6) {
      formIsValid = false;
      errors["heightFeet"] = "*Please enter your valid heightFeet.";
    }
    if (!fields["heightInches"]) {
      formIsValid = false;
      errors["heightInches"] = "*Please enter your heightInches.";
    }
    if (fields["heightInches"] < 0 || fields["heightInches"] > 12) {
      formIsValid = false;
      errors["heightInches"] = "*Please enter your valid heightInches.";
    }
    // if (!fields["heightCms"]) {
    //   formIsValid = false;
    //   errors["heightCms"] = "*Please enter your heightCms.";
    // }
    // if (!fields["bloodPressureMax"]) {
    //   formIsValid = false;
    //   errors["bloodPressureMax"] = "*Please enter your blood Pressure Max.";
    // }
    // if (!fields["bloodPressureMin"]) {
    //   formIsValid = false;
    //   errors["bloodPressureMin"] = "*Please enter your blood Pressure Min.";
    // }


    this.setState({
      healthHistoryFormErrors: errors
    });
    return formIsValid;


  }

  subsequentErrorForMedications() {

    let fields = this.state.healthHistory;
    let errors = {};
    let formIsValid = true;

    if (!fields["medicinesList"]) {
      formIsValid = false;
      errors["medicinesList"] = "*Required Medications field.";
    }


    this.setState({
      healthHistoryFormErrors: errors
    });


    return formIsValid;
  }


  healthHistoryFormValidateForSecondSession() {
    let fields = this.state.healthHistory;
    let errors = {};
    let formIsValid = true;

    if (!fields["doYouSmoke"]) {
      formIsValid = false;
      errors["doYouSmoke"] = "*Required smoke field.";
    }


    if (!fields["drinkAlcohol"]) {
      formIsValid = false;
      errors["drinkAlcohol"] = "*Required Alcohol.";
    }

    if (!fields["haveDiabetes"]) {
      formIsValid = false;
      errors["haveDiabetes"] = "*Required Diabetes.";
    }

    if (!fields["takemedicationToTreatHighBloodPressure"]) {
      formIsValid = false;
      errors["takemedicationToTreatHighBloodPressure"] = "*This field is Required.";
    }

    this.setState({
      healthHistoryFormErrors: errors
    });



    return formIsValid;


  }

  healthHistoryFormValidateForThirdSession() {

    let fields = this.state.healthHistory;
    let errors = {};
    let formIsValid = true;

    if (!fields["takingAnyMedications"]) {
      formIsValid = false;
      errors["takingAnyMedications"] = "*Required Medications field.";
    }


    // if (!fields["hadLiverTransplant"]) {
    //   formIsValid = false;
    //   errors["hadLiverTransplant"] = "*This field is Required .";
    // }

    if (!fields["diagnosedORTreatedForKidneyOrLiverProblem"]) {
      formIsValid = false;
      errors["diagnosedORTreatedForKidneyOrLiverProblem"] = "*This field is Required.";
    }


    this.setState({
      healthHistoryFormErrors: errors
    });


    return formIsValid;


  }

  healthHistoryFormValidateForFourthSession() {

    let fields = this.state.healthHistory;
    let errors = {};
    let formIsValid = true;

    if (!fields["hadCancer"]) {
      formIsValid = false;
      errors["hadCancer"] = "*Required Cancer field.";
    }


    // if (!fields["colonPolypsIdentifiedOnColonscopyOrSigmoidoscopy"]) {
    //   formIsValid = false;
    //   errors["colonPolypsIdentifiedOnColonscopyOrSigmoidoscopy"] = "*This field is Required .";
    // }

    // if (!fields["boneMarrowTransplantOrbloodTransfusion"]) {
    //   formIsValid = false;
    //   errors["boneMarrowTransplantOrbloodTransfusion"] = "*This field is Required.";
    // }
    // if (!fields["wasTheTransplatedBoneMarrowYourOwn"]) {
    //   formIsValid = false;
    //   errors["wasTheTransplatedBoneMarrowYourOwn"] = "*This field is Required.";
    // }
    if (!fields["genetiicTestForHereditaryCancerRisk"]) {
      formIsValid = false;
      errors["genetiicTestForHereditaryCancerRisk"] = "*This field is Required.";
    }
    // if (!fields["tearOfAnArtery"]) {
    //   formIsValid = false;
    //   errors["tearOfAnArtery"] = "*This field is Required.";
    // }
    // if (!fields["enlargementOfArtery"]) {
    //   formIsValid = false;
    //   errors["enlargementOfArtery"] = "*This field is Required.";
    // }

    // if (!fields["irregularHeartbeat"]) {
    //   formIsValid = false;
    //   errors["irregularHeartbeat"] = "*This field is Required.";
    // }
    // if (!fields["arrhythmia"]) {
    //   formIsValid = false;
    //   errors["arrhythmia"] = "*This field is Required.";
    // }
    // if (!fields["enlargedHeart"]) {
    //   formIsValid = false;
    //   errors["enlargedHeart"] = "*This field is Required.";
    // }
    // if (!fields["enlargedHeart"]) {
    //   formIsValid = false;
    //   errors["enlargedHeart"] = "*This field is Required.";
    // }
    // if (!fields["bloodTransfusionInTheWeekBeforeProvidingYourTruGeneSample"]) {
    //   formIsValid = false;
    //   errors["bloodTransfusionInTheWeekBeforeProvidingYourTruGeneSample"] = "*This field is Required.";
    // }
    if (!fields["faintingOrPassingOutafterExercise"]) {
      formIsValid = false;
      errors["faintingOrPassingOutafterExercise"] = "*This field is Required.";
    }


    this.setState({
      healthHistoryFormErrors: errors
    });



    return formIsValid;


  }

  healthHistoryFormValidateForFifthSession() {

    let fields = this.state.healthHistory;
    let errors = {};
    let formIsValid = true;

    if (!fields["healthHistoryHeartAttack"]) {
      formIsValid = false;
      errors["healthHistoryHeartAttack"] = "*Required HeartAttack field.";
    }


    if (!fields["suddenCardiacArrest"]) {
      formIsValid = false;
      errors["suddenCardiacArrest"] = "*This field is Required .";
    }

    if (!fields["healthHistoryStroke"]) {
      formIsValid = false;
      errors["healthHistoryStroke"] = "*This field is Required.";
    }
    if (!fields["haveHighCholesterol"]) {
      formIsValid = false;
      errors["haveHighCholesterol"] = "*This field is Required.";
    }


    this.setState({
      healthHistoryFormErrors: errors
    });


    return formIsValid;


  }

  healthHistoryFormValidateForSixthSession() {

    let fields = this.state.healthHistory;
    let errors = {};
    let formIsValid = true;

    // if (!fields["eyeliidsOrTendons"]) {
    //   formIsValid = false;
    //   errors["eyeliidsOrTendons"] = "*Required  field.";
    // }


    // if (!fields["cornealArcus"]) {
    //   formIsValid = false;
    //   errors["cornealArcus"] = "*This field is Required .";
    // }



    this.setState({
      healthHistoryFormErrors: errors
    });



    return formIsValid;


  }
  /* FORM VALIDATIONS SECTION WISE END */

  handleChangefamilyTree(event) {
    let family = this.state.familyTree
    family[event.target.name] = event.target.value;
    this.setState({ familyTree: family })

    if (event.target.name === "inWhatCountryWereYouBorn") {
      if (this.state.familyTree.parentsAndGrandparents.motherAge == undefined || this.state.familyTree.parentsAndGrandparents.fatherAge == undefined || this.state.familyTree.parentsAndGrandparents.maternamGrandMotherAge == undefined || this.state.familyTree.parentsAndGrandparents.maternamGrandFatherAge == undefined || this.state.familyTree.parentsAndGrandparents.paternamGrandMotherAge == undefined || this.state.familyTree.parentsAndGrandparents.paternalGrandFatherAge == undefined) {
        this.familyTreeFormValidateForThirdSession()
      } else {
        let errors = {}
        this.setState({
          familyTreeFormErrors: errors
        });
      }
    }

    //saving 3rd section data
    if (event.target.name === "inWhatCountryWereYouBorn") {
      //alert(this.state.familyTree.parentsAndGrandparents.motherAge)
      if ((this.state.familyTree.parentsAndGrandparents.motherAge !== "" && this.state.familyTree.parentsAndGrandparents.motherAge !== undefined) && (this.state.familyTree.parentsAndGrandparents.fatherAge !== "" && this.state.familyTree.parentsAndGrandparents.fatherAge !== undefined) && (this.state.familyTree.parentsAndGrandparents.maternamGrandMotherAge !== "" && this.state.familyTree.parentsAndGrandparents.maternamGrandMotherAge !== undefined) && (this.state.familyTree.parentsAndGrandparents.maternamGrandFatherAge !== "" && this.state.familyTree.parentsAndGrandparents.maternamGrandFatherAge !== undefined) && (this.state.familyTree.parentsAndGrandparents.paternamGrandMotherAge !== "" && this.state.familyTree.parentsAndGrandparents.paternamGrandMotherAge !== undefined) && (this.state.familyTree.parentsAndGrandparents.paternalGrandFatherAge !== "" && this.state.familyTree.parentsAndGrandparents.paternalGrandFatherAge !== undefined)) {
        //   alert("yes...",this.state.familyTree.parentsAndGrandparents)
        this.saveFamilyTreeSectionWise()
      }
    }
  }
  handleChangeMultiplefamilyTree(event) {

    let family = this.state.familyTree
    family[event.target.name] = { value: event.target.value }
    this.setState({
      familyTree: family
    })
    if (event.target.name === "doesYourMotherHaveAnySiblings") {
      if (this.state.familyTree.inWhatCountryWereYouBorn == undefined) {
        this.familyTreeFormValidateForCountrySelection()
      } else {
        let errors = {}
        this.setState({
          familyTreeFormErrors: errors
        });
      }
    }

    //saving 3rd section data
    if (event.target.name === "doesYourMotherHaveAnySiblings") {
      if (this.state.familyTree.inWhatCountryWereYouBorn !== null && this.state.familyTree.inWhatCountryWereYouBorn !== undefined) {
        this.saveFamilyTreeSectionWise()
      }
    }



    if (event.target.name === "doesYourFatherHaveAnySiblings") {
      if (this.state.familyTree.doesYourMotherHaveAnySiblings == undefined) {
        this.familyTreeFormValidateForFourthSession()
      } else {
        let errors = {}
        this.setState({
          familyTreeFormErrors: errors
        });
      }
    }
    // subsequent error for Does your mother have any siblings

    if (event.target.name === "doesYourFatherHaveAnySiblings") {
      if (this.state.familyTree.doesYourMotherHaveAnySiblings !== undefined && this.state.familyTree.doesYourMotherHaveAnySiblings.value === "yes") {
        if (this.state.familyTree.doesYourMotherHaveAnySiblings.howManySisters === undefined || this.state.familyTree.doesYourMotherHaveAnySiblings.howManySisters === "" || this.state.familyTree.doesYourMotherHaveAnySiblings.howManyBrothers === undefined || this.state.familyTree.doesYourMotherHaveAnySiblings.howManyBrothers === "") {
          this.setState({
            doesYourMotherHaveAnySiblingssError: true
          })
        } else {
          this.setState({
            doesYourMotherHaveAnySiblingssError: false
          })
        }
      }


    }

    //saving 4th section data
    // if (event.target.name === "doesYourFatherHaveAnySiblings") {
    //   if (this.state.familyTree.doesYourMotherHaveAnySiblings !== undefined) {

    //     this.saveDataSectionWiseHealthHistory()
    //   }
    // }

    if (event.target.name === "doesYourFatherHaveAnySiblings") {
      if (this.state.familyTree.doesYourMotherHaveAnySiblings !== undefined) {
        if (this.state.familyTree.doesYourMotherHaveAnySiblings.value === "yes" && ((this.state.familyTree.doesYourMotherHaveAnySiblings.howManySisters === undefined || this.state.familyTree.doesYourMotherHaveAnySiblings.howManySisters === "") && (this.state.familyTree.doesYourMotherHaveAnySiblings.howManyBrothers === undefined || this.state.familyTree.doesYourMotherHaveAnySiblings.howManyBrothers === ""))) {
          return null;
        } else {
          this.saveFamilyTreeSectionWise()

        }
      }
    }



  }

  handleChangeFamilyTree(event) {
    let healthhis = this.state.familyTree
    healthhis[event.target.name] = event.target.value;
    this.setState({ familyTree: healthhis })
    
    
    
    }
  handleChangeAddDatafamilyTree(event, name) {

    let data = this.state.familyTree[name]
    data[event.target.name] = event.target.value

    let family = this.state.familyTree
    family[name] = data
    this.setState({
      familyTree: family
    })
    if (event.target.name === "motherAge" || event.target.name === "fatherAge" || event.target.name === "maternamGrandMotherAge" || event.target.name === "maternamGrandFatherAge" || event.target.name === "paternamGrandMotherAge" || event.target.name === "paternalGrandFatherAge") {

      this.familyTreeFormValidateForDoYouHaveAnySiblings()

    }


    // second session saving

    if (event.target.name === "motherAge" || event.target.name === "fatherAge" || event.target.name === "maternamGrandMotherAge" || event.target.name === "maternamGrandFatherAge" || event.target.name === "paternamGrandMotherAge" || event.target.name === "paternalGrandFatherAge") {
      // alert(this.state.familyTree.doYouHaveAnySiblings)
      if (this.state.familyTree.doYouHaveAnySiblings !== undefined) {
        this.saveFamilyTreeSectionWise()
      }
    }

  }

  /* special case for childrens,siblings,... */


  handleChangeMultiplefamilyTreeMany(event) {

    let family = this.state.familyTree
    family[event.target.name] = { value: [{ value: event.target.value }] }
    this.setState({
      familyTree: family
    })

    if (event.target.name === "doYouHaveAnySiblings") {

      this.familyTreeFormValidateForDoYouHaveAnyChildren()

    }

    // first section saving

    if (event.target.name === "doYouHaveAnySiblings") {
      if (this.state.familyTree.doYouHaveAnyChildren !== undefined && this.state.familyTree.yourBiologicalFamilyMembers !== undefined) {

        this.saveFamilyTreeSectionWise()
      }
    }





  }
  handleChangeAddDatafamilyTreeForMany(event, name) {
    let data = this.state.familyTree[name]

    let count = event.target.name + "Count"
    if (data[event.target.name] != undefined) {
      data[event.target.name][0]['value'] = parseInt(this.state[count]) + 1
    } else {
      data[event.target.name] = [{ value: parseInt(this.state[count]) + 1 }]
    }
    //data[event.target.name][0]['value'] =  event.target.value

    let family = this.state.familyTree
    family[name] = data
    this.setState({
      familyTree: family
    })

  }
  handleChangeAddDatafamilyTreeForManyRemove(event, name) {

    let data = this.state.familyTree[name]
    let count = event + "Count"

    if (data[event] != undefined) {
      data[event][0]['value'] = this.state[count] - 1
      delete data[event][this.state[count]]
    } else {

      data[event] = [{ value: this.state[count] - 1 }]
      delete data[event][this.state[count]]
    }


    let family = this.state.familyTree
    family[name] = data
    this.setState({
      familyTree: family
    })

  }
  handleChangeAddDatafamilyTreeForManyFamilyMembers(event, name, member, index) {


    let data = this.state.familyTree[name]
    let memberData = data[member]
    //let memData = memberData[index+1]
    // memData[event.target.name] = event.target.value
    memberData[index + 1] = { ...memberData[index + 1], [event.target.name]: event.target.value }

    //memberData[event.target.name] = event.target.value
    //data[event.target.name] = {value : event.target.value}

    let family = this.state.familyTree
    family[name] = data
    this.setState({
      familyTree: family,
    })

  }
  /* special case for childrens,siblings,...  end*/

  /* SECTION WISE VALIDATIONS FOR FAMILY TREE */
  familyTreeFormValidate() {

    let fields = this.state.familyTree;
    let ageFields = this.state.familyTree.parentsAndGrandparents;
    let errors = {};
    let formIsValid = true;

    // if (!fields["yourBiologicalFamilyMembers"]) {
    //   formIsValid = false;
    //   errors["yourBiologicalFamilyMembers"] = "*Please enter your yourBiologicalFamilyMembers.";
    // }


    if (!fields["doYouHaveAnyChildren"]) {
      swal("Please check Do you have any children")
      this.validations("Please check Do you have any children")
      formIsValid = false;
      errors["doYouHaveAnyChildren"] = "*Please enter your doYouHaveAnyChildren.";
    }

  //   if(this.state.familyTree.doYouHaveAnyChildren!==undefined &&this.state.familyTree.doYouHaveAnyChildren.value[0].value==="true"){
  //     if(this.state.familyTree.doYouHaveAnyChildren.biologicaldaughters===undefined||this.state.familyTree.doYouHaveAnyChildren.biologicaldaughters.length==0||this.state.familyTree.doYouHaveAnyChildren.biologicaldaughters[0].value==0){

  //     swal("Please add your Children's information")
  //     this.validations("Please check Do you have any children")
  //     formIsValid = false;
  //     }

  // }

//   if(this.state.familyTree.doYouHaveAnyChildren!==undefined &&this.state.familyTree.doYouHaveAnyChildren.value[0].value==="true"){
//     if(this.state.familyTree.doYouHaveAnyChildren.biologicaldaughters.length>0){
      
        
//       this.state.familyTree.doYouHaveAnyChildren.biologicaldaughters.map((formDetails, index) => {
//         if (
//           formDetails["daughterFirstName"] === undefined ||
//           formDetails["daughterFirstName"] === ""
//         ) {
//           this.validations("Please check Do you have any children")
  
//           swal("Please fill first name");
//           formIsValid = false;
//         }
  
//         if (
//           formDetails["daughterLastName"] === undefined ||
//           formDetails["daughterLastName"] === ""
//         ) {
//           this.validations("Please check Do you have any children")
  
//           swal("Please fill last name");
//           formIsValid = false;
//         }
  
        
  
//         if (formDetails["daughterAge"] === undefined || formDetails["daughterAge"] === "") {
//           this.validations("Please check Do you have any children")
  
//           swal("Please fill age");
//           formIsValid = false;
//         }
//       });
      

//     }
    

// }

//   if(this.state.familyTree.doYouHaveAnySiblings!==undefined &&this.state.familyTree.doYouHaveAnySiblings.value[0].value==="true"){
//     if(this.state.familyTree.doYouHaveAnySiblings.brothers===undefined||this.state.familyTree.doYouHaveAnySiblings.brothers.length==0||this.state.familyTree.doYouHaveAnySiblings.brothers[0].value==0){

//     swal("Please add your siblings information")
//     this.validations("Please check Do you have any siblings")
//     formIsValid = false;
//     }

// }


    if (!fields["doYouHaveAnySiblings"]) {
      swal("Please check Do you have any siblings")
      this.validations("Please check Do you have any siblings")
      formIsValid = false;
      errors["doYouHaveAnySiblings"] = "*Please enter your doYouHaveAnySiblings.";
    }

    if (!fields["doesYourMotherHaveAnySiblings"]) {
      formIsValid = false;
      errors["doesYourMotherHaveAnySiblings"] = "*Please enter your doesYourMotherHaveAnySiblings.";
    }
    if (!fields["doesYourFatherHaveAnySiblings"]) {
      formIsValid = false;
      errors["doesYourFatherHaveAnySiblings"] = "*Please enter your blood doesYourFatherHaveAnySiblings .";
    }

    if (!fields["parentsAndGrandparents"]) {
      formIsValid = false;
      errors["parentsAndGrandparents"] = "*Please enter your blood parentsAndGrandparents .";
    }



    if (!fields["parentsMarriedWithinRelative"]) {
      formIsValid = false;
      swal("Please check Was your parents marriage consanguineous")
      this.validations("family married")
      errors["parentsMarriedWithinRelative"] = "*Please enter your parentsMarriedWithinRelative.";
    }

    
    if (!fields["married"]) {
      swal("Please select are you married")
      window.scrollTo(500,500)
      formIsValid = false;
      errors["married"] = "*Please enter your married.";
    }

    if(this.state.familyTree.married!==undefined &&this.state.familyTree.married==="yes"){

        if(this.state.familyTree.marriedRelative===undefined||this.state.familyTree.marriedRelative===""){

        swal("Please check married relative")
        this.validations("Please check married relative")
        }

    }


    this.setState({
      familyTreeFormErrors: errors,

    });


    return formIsValid;
  }

  familyTreeFormValidateForFirstSession() {

    let fields = this.state.familyTree;
    let errors = {};
    let formIsValid = true;

    if (!fields["yourBiologicalFamilyMembers"]) {
      formIsValid = false;
      errors["yourBiologicalFamilyMembers"] = "*Please enter your yourBiologicalFamilyMembers.";
    }


    if (!fields["doYouHaveAnyChildren"]) {
      formIsValid = false;
      errors["doYouHaveAnyChildren"] = "*Please enter your doYouHaveAnyChildren.";
    }



    this.setState({
      familyTreeFormErrors: errors,

    });
    return formIsValid;
  }

  familyTreeFormValidateForSecondSession() {

    let fields = this.state.familyTree;
    let errors = {};
    let formIsValid = true;

    if (!fields["doYouHaveAnySiblings"]) {
      formIsValid = false;
      errors["doYouHaveAnySiblings"] = "*Please enter your doYouHaveAnySiblings.";
    }



    this.setState({
      familyTreeFormErrors: errors,

    });
    return formIsValid;
  }

  familyTreeFormValidateForThirdSession() {

    let fields = this.state.familyTree;
    let errors = {};
    let formIsValid = true;


    if (!fields["motherAge"]) {
      formIsValid = false;
      errors["motherAge"] = "*Please enter your motherAge.";
    }
    if (!fields["fatherAge"]) {
      formIsValid = false;
      errors["fatherAge"] = "*Mandatory fatherAge.";
    }
    if (!fields["maternamGrandMotherAge"]) {
      formIsValid = false;
      errors["maternamGrandMotherAge"] = "*Mandatory options maternamGrandMotherAge.";
    }

    if (!fields["maternamGrandFatherAge"]) {
      formIsValid = false;
      errors["maternamGrandFatherAge"] = "*Please enter your maternamGrandFatherAge.";
    }
    if (!fields["paternamGrandMotherAge"]) {
      formIsValid = false;
      errors["paternamGrandMotherAge"] = "*Mandatory paternamGrandMotherAge.";
    }
    if (!fields["paternalGrandFatherAge"]) {
      formIsValid = false;
      errors["paternalGrandFatherAge"] = "*Mandatory options paternalGrandFatherAge.";
    }


    this.setState({
      familyTreeFormErrors: errors,

    });
    return formIsValid;
  }
  familyTreeFormValidateForFourthSession() {

    let fields = this.state.familyTree;
    let errors = {};
    let formIsValid = true;


    if (!fields["doesYourMotherHaveAnySiblings"]) {
      formIsValid = false;
      errors["doesYourMotherHaveAnySiblings"] = "*Please enter your doesYourMotherHaveAnySiblings.";
    }


    this.setState({
      familyTreeFormErrors: errors,

    });
    return formIsValid;
  }

  familyTreeFormValidateForCountrySelection() {
    let fields = this.state.familyTree;
    let errors = {};
    let formIsValid = true;


    if (!fields["inWhatCountryWereYouBorn"]) {
      formIsValid = false;
      errors["inWhatCountryWereYouBorn"] = "*Please enter your inWhatCountryWereYouBorn.";
    }


    this.setState({
      familyTreeFormErrors: errors,

    });
    return formIsValid;
  }


  familyTreeFormValidateForDoYouHaveAnyChildren() {
    let fieldsForFirstSection = this.state.familyTree
    let errors = {};
    let formIsValid = true;



    // if (!fieldsForFirstSection["doYouHaveAnyChildren"]) {
    //   formIsValid = false;
    //   errors["doYouHaveAnyChildren"] = "*Please enter your doYouHaveAnyChildren.";
    // }

    if (this.state.familyTree.doYouHaveAnyChildren !== undefined && this.state.familyTree.doYouHaveAnyChildren.biologicaldaughters !== undefined) {

      let fields = this.state.familyTree.doYouHaveAnyChildren.biologicaldaughters;

      {
        [...Array(parseInt(this.state.familyTree.doYouHaveAnyChildren.biologicaldaughters[0].value))].map((e, i) => {

          if (fields[i + 1] === undefined) {
            formIsValid = false;
            swal("Please fill children information")
            this.validations("Please check Do you have any children")
            errors["daughterAge" + i] = "";
          } else if (fields[i + 1]['daughterAge'] === undefined || fields[i + 1]['daughterAge'] === "") {
            formIsValid = false;
            errors["daughterAge" + i] = "";
          }
          if (this.state.familyTree.doYouHaveAnyChildren.biologicaldaughters[i + 1] !== undefined) {
            if (this.state.familyTree.doYouHaveAnyChildren.biologicaldaughters[i + 1]['daughterAgeTill'] === undefined) {
              this.state.familyTree.doYouHaveAnyChildren.biologicaldaughters[i + 1]['daughterAgeTill'] = "Now"
            }
          }
          return null;
        })
      }

    }


    if (this.state.familyTree.doYouHaveAnyChildren !== undefined && this.state.familyTree.doYouHaveAnyChildren.biologicalsons !== undefined) {

      let fields = this.state.familyTree.doYouHaveAnyChildren.biologicalsons;

      {
        [...Array(parseInt(this.state.familyTree.doYouHaveAnyChildren.biologicalsons[0].value))].map((e, i) => {

          if (fields[i + 1] === undefined) {
            formIsValid = false;
            swal("Please fill children information")
            this.validations("Please check Do you have any children")
            errors["sonAge" + i] = "";
          } else if (fields[i + 1]['sonAge'] === undefined || fields[i + 1]['sonAge'] === "") {
            formIsValid = false;
            errors["sonAge" + i] = "";
          }
          if (this.state.familyTree.doYouHaveAnyChildren.biologicalsons[i + 1] !== undefined) {
            if (this.state.familyTree.doYouHaveAnyChildren.biologicalsons[i + 1]['sonAgeTill'] === undefined) {
              this.state.familyTree.doYouHaveAnyChildren.biologicalsons[i + 1]['sonAgeTill'] = "Now"
            }
          }
          return null;
        })
      }

    }


    if (formIsValid) {
      this.setState({
        familyTreeFormErrors: ""
      });
    } else {
      this.setState({
        familyTreeFormErrors: errors,

      });
    }

    return formIsValid;
  }



  familyTreeFormValidateForDoYouHaveAnySiblings() {

    let fieldsForFirstSection = this.state.familyTree
    let errors = {};
    let formIsValid = true;

    // if (!fieldsForFirstSection["doYouHaveAnySiblings"]) {
    //   formIsValid = false;
    //   errors["doYouHaveAnySiblings"] = "*Please enter your .";
    // }

    if (this.state.familyTree.doYouHaveAnySiblings !== undefined && this.state.familyTree.doYouHaveAnySiblings.sisters !== undefined) {

      let fields = this.state.familyTree.doYouHaveAnySiblings.sisters;

      // alert(this.state.familyTree.doYouHaveAnySiblings.sisters[0].value)


      {
        [...Array(parseInt(this.state.familyTree.doYouHaveAnySiblings.sisters[0].value))].map((e, i) => {

          if (fields[i + 1] === undefined) {
            formIsValid = false;
            swal("Please fill siblings information")
            this.validations("Please check Do you have any siblings")
            errors["sisterAge" + i] = "";
          } else if (fields[i + 1]['sisterAge'] === undefined || fields[i + 1]['sisterAge'] === "") {
            formIsValid = false;
            errors["sisterAge" + i] = "";
          }
          if (this.state.familyTree.doYouHaveAnySiblings.sisters[i + 1] !== undefined) {
            if (this.state.familyTree.doYouHaveAnySiblings.sisters[i + 1]['sisterAgeTill'] === undefined) {
              this.state.familyTree.doYouHaveAnySiblings.sisters[i + 1]['sisterAgeTill'] = "Now"
            }

          }
          return null;
        })
      }

    }


    if (this.state.familyTree.doYouHaveAnySiblings !== undefined && this.state.familyTree.doYouHaveAnySiblings.brothers !== undefined) {

      let fields = this.state.familyTree.doYouHaveAnySiblings.brothers;
      {
        [...Array(parseInt(this.state.familyTree.doYouHaveAnySiblings.brothers[0].value))].map((e, i) => {

          if (fields[i + 1] === undefined) {
            formIsValid = false;
            swal("Please fill siblings information")
            this.validations("Please check Do you have any siblings")
            errors["brotherAge" + i] = "";
          } else if (fields[i + 1]['brotherAge'] === undefined || fields[i + 1]['brotherAge'] === "") {
            formIsValid = false;
            errors["brotherAge" + i] = "";
          }
          if (this.state.familyTree.doYouHaveAnySiblings.brothers[i + 1] !== undefined) {
            if (this.state.familyTree.doYouHaveAnySiblings.brothers[i + 1]['brotherAgeTill'] === undefined) {
              this.state.familyTree.doYouHaveAnySiblings.brothers[i + 1]['brotherAgeTill'] = "Now"
            }
          }
          return null;
        })
      }

    }


    if (formIsValid) {
      this.setState({
        familyTreeFormErrors: ""
      });
    } else {
      this.setState({
        familyTreeFormErrors: errors,

      });
    }


    return formIsValid;
  }

  /* SECTION WISE FAMILY TREE END*/
  validations(name){
    document.getElementById(name).scrollIntoView();

  }

  familyHealthHistoryFormValidate() {
    let fields = this.state.familyHealthHistory;
    let hadCancerSubFields = this.state.familyHealthHistory.inYourFamilyHadCancer;
    let geneticTestForHereditaryCancerRiskSubFields = this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk;
    let fhSubFields = this.state.familyHealthHistory.fh;
    let cardiomyopathySubFields = this.state.familyHealthHistory.cardiomyopathy;
    let HereditaryArrhythmiaSubFields = this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder;
    let arteriopathyConnectiveTissueDisorderSubFields = this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder;
    let heartfailureSubFields = this.state.familyHealthHistory.heartfailure;
    let cardiomegalyFamilyHealthHistorySubFields = this.state.familyHealthHistory.cardiomegalyFamilyHealthHistory;
    let suddenCardiacSubFields = this.state.familyHealthHistory.suddenCardiac;
    let heartAttackSubFields = this.state.familyHealthHistory.heartAttack;
    let strokeSubFields = this.state.familyHealthHistory.stroke;
    let aneurysmSubFields = this.state.familyHealthHistory.aneurysm;
    let dissectionSubFields = this.state.familyHealthHistory.dissection;
    let faintingOrPassingSubFields = this.state.familyHealthHistory.faintingOrPassing;
    let heartOrVascularSubFields = this.state.familyHealthHistory.heartOrVascular;
    let arrhythmiaFamilyHealthHistorySubFields = this.state.familyHealthHistory.arrhythmiaFamilyHealthHistory;
    let cholesterolDepositsSubFields = this.state.familyHealthHistory.cholesterolDeposits;
    let diabetesFamilyHealthHistorySubFields = this.state.familyHealthHistory.diabetesFamilyHealthHistory;
    let cornealArcusFamilyHistorySubFields = this.state.familyHealthHistory.cornealArcusFamilyHistory;
    let hyperTensionSubFields=this.state.familyHealthHistory.hyperTension;
    let hereditaryHeartConditionsSubFields = this.state.familyHealthHistory.hereditaryHeartConditions;



    let errors = {};
    let formIsValid = true;


    if (this.state.familyHealthHistory.inYourFamilyHadCancer && this.state.familyHealthHistory.inYourFamilyHadCancer.value[0] === "yes") {
      if (hadCancerSubFields["member"] === undefined || hadCancerSubFields["member"].length === 0) {
        formIsValid = false;
        errors["member"] = swal("*Please check one of the option from Had cancer...");
        window.scrollTo(500, 500);

      }
      if (this.state.familyHealthHistory.inYourFamilyHadCancer.member && this.state.familyHealthHistory.inYourFamilyHadCancer.member.find(x => x === "mother") === "mother") {
        if (hadCancerSubFields["mother"] === undefined || hadCancerSubFields["mother"].length === 0) {
          formIsValid = false;
          errors["mother"] = swal("*Please check one of the option from Mother cancer...");
          this.validations("check mother cancer")
          //window.scrollTo(500, 500);

        }
      }
      if (this.state.familyHealthHistory.inYourFamilyHadCancer.member && this.state.familyHealthHistory.inYourFamilyHadCancer.member.find(x => x === "Father") === "Father") {
        if (hadCancerSubFields["Father"] === undefined || hadCancerSubFields["Father"].length === 0) {
          formIsValid = false;
          errors["Father"] = swal("*Please check one of the option from Father cancer...");
          this.validations("check father cancer")

        }
      }
      if (this.state.familyHealthHistory.inYourFamilyHadCancer.member && this.state.familyHealthHistory.inYourFamilyHadCancer.member.find(x => x === "Siblings") === "Siblings") {
        if (hadCancerSubFields["Siblings"] === undefined || hadCancerSubFields["Siblings"].length === 0) {
          formIsValid = false;
          errors["Siblings"] = swal("*Please check one of the option from Siblings cancer...");
          this.validations("check Siblings cancer")
        }
      }
      if (this.state.familyHealthHistory.inYourFamilyHadCancer.member && this.state.familyHealthHistory.inYourFamilyHadCancer.member.find(x => x === "MaternalGrandmother") === "MaternalGrandmother") {
        if (hadCancerSubFields["MaternalGrandmother"] === undefined || hadCancerSubFields["MaternalGrandmother"].length === 0) {
          formIsValid = false;
          errors["MaternalGrandmother"] = swal("*Please check one of the option from MaternalGrandmother cancer...");
          this.validations("check MaternalGrandmother cancer")

        }
      }
      if (this.state.familyHealthHistory.inYourFamilyHadCancer.member && this.state.familyHealthHistory.inYourFamilyHadCancer.member.find(x => x === "PaternalGrandmother") === "PaternalGrandmother") {
        if (hadCancerSubFields["PaternalGrandmother"] === undefined || hadCancerSubFields["PaternalGrandmother"].length === 0) {
          formIsValid = false;
          errors["PaternalGrandmother"] = swal("*Please check one of the option from PaternalGrandmother cancer...");
          this.validations("check PaternalGrandmother cancer")


        }
      }

      if (this.state.familyHealthHistory.inYourFamilyHadCancer.member && this.state.familyHealthHistory.inYourFamilyHadCancer.member.find(x => x === "MaternalGrandfather") === "MaternalGrandfather") {
        if (hadCancerSubFields["MaternalGrandfather"] === undefined || hadCancerSubFields["MaternalGrandfather"].length === 0) {
          formIsValid = false;
          errors["MaternalGrandfather"] = swal("*Please check one of the option from MaternalGrandfather cancer...");
          this.validations("check MaternalGrandfather cancer")

        }
      }

      if (this.state.familyHealthHistory.inYourFamilyHadCancer.member && this.state.familyHealthHistory.inYourFamilyHadCancer.member.find(x => x === "PaternalGrandfather") === "PaternalGrandfather") {
        if (hadCancerSubFields["PaternalGrandfather"] === undefined || hadCancerSubFields["PaternalGrandfather"].length === 0) {
          formIsValid = false;
          errors["PaternalGrandfather"] = swal("*Please check one of the option from PaternalGrandfather cancer...");
          this.validations("check PaternalGrandfather cancer")

        }
      }
    }

    if (!fields["inYourFamilyHadCancer"]) {
      formIsValid = false;
      errors["inYourFamilyHadCancer"] = swal("*Mandatory option cancer");
      window.scrollTo(500, 500);
    }


    if (!fields["geneticTestForHereditaryCancerRisk"]) {
      formIsValid = false;
      errors["geneticTestForHereditaryCancerRisk"] = "*Please enter your geneticTestForHereditaryCancerRisk.";
      window.scrollTo(500, 500);
    }

    if (this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk && this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.value[0] === "yes") {

      if (geneticTestForHereditaryCancerRiskSubFields["member"] === undefined || geneticTestForHereditaryCancerRiskSubFields["member"].length === 0) {
        formIsValid = false;
        errors["member"] = swal("*Please check one of the option from HereditaryCancerRisk...!");
        this.validations("check HereditaryCancerRisk cancer")

      }
      if (this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.member && this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.member.find(x => x === "mother") === "mother") {

        if (geneticTestForHereditaryCancerRiskSubFields["mutationIdentifiedForHereditaryHeart"] === undefined || geneticTestForHereditaryCancerRiskSubFields["mutationIdentifiedForHereditaryHeart"].length === 0 ) {
          formIsValid = false;
          errors["mother"] = swal("*Please fill required fields from Mother hereditary cancer risk....!");
          this.validations("check fields from Mother hereditary cancer risk")

        }

      }

      if (this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.member && this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.member.find(x => x === "Father") === "Father") {

        if (geneticTestForHereditaryCancerRiskSubFields["FatherMutation"] === undefined || geneticTestForHereditaryCancerRiskSubFields["FatherMutation"].length === 0 ) {
          formIsValid = false;
          errors["Father"] = swal("*Please fill required fields from Father hereditary cancer risk....!");
          this.validations("check fields from Father hereditary cancer risk")

        }

      }

      if (this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.member && this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.member.find(x => x === "Siblings") === "Siblings") {

        if (geneticTestForHereditaryCancerRiskSubFields["SiblingsMutation"] === undefined || geneticTestForHereditaryCancerRiskSubFields["SiblingsMutation"].length === 0 ) {
          formIsValid = false;
          errors["Siblings"] = swal("*Please fill required fields from Siblings hereditary cancer risk....!");
          this.validations("check fields from Siblings hereditary cancer risk")

        }

      }
    }


    // if (!fields["fh"]) {
    //   formIsValid = false;
    //   errors["fh"] = "*Please enter your fh.";
    // }
    // if (this.state.familyHealthHistory.fh && this.state.familyHealthHistory.fh.value[0] === "yes") {
    //   if (fhSubFields["members"] === undefined || fhSubFields["members"].length === 0) {
    //     formIsValid = false;
    //     errors["members"] = swal("*Please check one of the option from Familial hypercholesterolemia...");
    //     this.validations("check fields from Familial hypercholesterolemia")

    //   }
    // }


    if (!fields["cardiomyopathy"]) {
      formIsValid = false;
      errors["cardiomyopathy"] =swal("*Mandatory option cardiomyopathy");
      this.validations("check fields from Cardiomyopathy")
    }
    if (this.state.familyHealthHistory.cardiomyopathy && this.state.familyHealthHistory.cardiomyopathy.value[0] === "yes") {
      if (cardiomyopathySubFields["member"] === undefined || cardiomyopathySubFields["member"].length === 0) {
        formIsValid = false;
        errors["member"] = swal("*Please check one of the option from cardiomyopathy...");
        this.validations("check fields from Cardiomyopathy")
       

      }
      // if (this.state.familyHealthHistory.cardiomyopathy.member && this.state.familyHealthHistory.cardiomyopathy.member.find(x => x === "Mother") === "Mother") {
      //   if (cardiomyopathySubFields["Mother"] === undefined || cardiomyopathySubFields["Mother"].length === 0) {
      //     formIsValid = false;
      //     errors["Mother"] = alert("*Please check one of the option from Mother cardiomyopathy...");

      //   }
      // }
      // if (this.state.familyHealthHistory.cardiomyopathy.member && this.state.familyHealthHistory.cardiomyopathy.member.find(x => x === "Father") === "Father") {
      //   if (cardiomyopathySubFields["Father"] === undefined || cardiomyopathySubFields["Father"].length === 0) {
      //     formIsValid = false;
      //     errors["Father"] = alert("*Please check one of the option from Father cardiomyopathy...");

      //   }
      // }
      // if (this.state.familyHealthHistory.cardiomyopathy.member && this.state.familyHealthHistory.cardiomyopathy.member.find(x => x === "MaternalGrandmother") === "MaternalGrandmother") {
      //   if (cardiomyopathySubFields["MaternalGrandmother"] === undefined || cardiomyopathySubFields["MaternalGrandmother"].length === 0) {
      //     formIsValid = false;
      //     errors["MaternalGrandmother"] = alert("*Please check one of the option from MaternalGrandmother cardiomyopathy...");

      //   }
      // }
      // if (this.state.familyHealthHistory.cardiomyopathy.member && this.state.familyHealthHistory.cardiomyopathy.member.find(x => x === "PaternalGrandmother") === "PaternalGrandmother") {
      //   if (cardiomyopathySubFields["PaternalGrandmother"] === undefined || cardiomyopathySubFields["PaternalGrandmother"].length === 0) {
      //     formIsValid = false;
      //     errors["PaternalGrandmother"] = alert("*Please check one of the option from PaternalGrandmother cardiomyopathy...");

      //   }
      // }

      // if (this.state.familyHealthHistory.cardiomyopathy.member && this.state.familyHealthHistory.cardiomyopathy.member.find(x => x === "MaternalGrandfather") === "MaternalGrandfather") {
      //   if (cardiomyopathySubFields["MaternalGrandfather"] === undefined || cardiomyopathySubFields["MaternalGrandfather"].length === 0) {
      //     formIsValid = false;
      //     errors["MaternalGrandfather"] = alert("*Please check one of the option from MaternalGrandfather cardiomyopathy...");

      //   }
      // }

      // if (this.state.familyHealthHistory.cardiomyopathy.member && this.state.familyHealthHistory.cardiomyopathy.member.find(x => x === "PaternalGrandfather") === "PaternalGrandfather") {
      //   if (cardiomyopathySubFields["PaternalGrandfather"] === undefined || cardiomyopathySubFields["PaternalGrandfather"].length === 0) {
      //     formIsValid = false;
      //     errors["PaternalGrandfather"] = alert("*Please check one of the option from PaternalGrandfather cardiomyopathy...");

      //   }
      // }
    }
    if (!fields["hereditaryArrhythmiaOrConductionDisorder"]) {
      formIsValid = false;
      errors["hereditaryArrhythmiaOrConductionDisorder"] = swal("*Mandatory option Hereditary arrhythmia or conduction disorder.");
      this.validations("check fields from ConductionDisorder")
    }
    if (this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder && this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.value[0] === "yes") {
      if (HereditaryArrhythmiaSubFields["member"] === undefined || HereditaryArrhythmiaSubFields["member"].length === 0) {
        formIsValid = false;
        errors["member"] = swal("*Please check one of the option from Hereditary arrhythmia or conduction disorder.");
        this.validations("check fields from ConductionDisorder")

      }
      // if (this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member && this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member.find(x => x === "Mother") === "Mother") {
      //   if (HereditaryArrhythmiaSubFields["Mother"] === undefined || HereditaryArrhythmiaSubFields["Mother"].length === 0) {
      //     formIsValid = false;
      //     errors["Mother"] = alert("*Please check one of the option from Mother ConductionDisorder...");

      //   }
      // }
      // if (this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member && this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member.find(x => x === "Father") === "Father") {
      //   if (HereditaryArrhythmiaSubFields["Father"] === undefined || HereditaryArrhythmiaSubFields["Father"].length === 0) {
      //     formIsValid = false;
      //     errors["Father"] = alert("*Please check one of the option from Father ConductionDisorder...");

      //   }
      // }
      // if (this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member && this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member.find(x => x === "MaternalGrandmother") === "MaternalGrandmother") {
      //   if (HereditaryArrhythmiaSubFields["MaternalGrandmother"] === undefined || HereditaryArrhythmiaSubFields["MaternalGrandmother"].length === 0) {
      //     formIsValid = false;
      //     errors["MaternalGrandmother"] = alert("*Please check one of the option from MaternalGrandmother ConductionDisorder...");

      //   }
      // }
      // if (this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member && this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member.find(x => x === "PaternalGrandmother") === "PaternalGrandmother") {
      //   if (HereditaryArrhythmiaSubFields["PaternalGrandmother"] === undefined || HereditaryArrhythmiaSubFields["PaternalGrandmother"].length === 0) {
      //     formIsValid = false;
      //     errors["PaternalGrandmother"] = alert("*Please check one of the option from PaternalGrandmother ConductionDisorder...");

      //   }
      // }

      // if (this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member && this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member.find(x => x === "MaternalGrandfather") === "MaternalGrandfather") {
      //   if (HereditaryArrhythmiaSubFields["MaternalGrandfather"] === undefined || HereditaryArrhythmiaSubFields["MaternalGrandfather"].length === 0) {
      //     formIsValid = false;
      //     errors["MaternalGrandfather"] = alert("*Please check one of the option from MaternalGrandfather ConductionDisorder...");

      //   }
      // }

      // if (this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member && this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member.find(x => x === "PaternalGrandfather") === "PaternalGrandfather") {
      //   if (HereditaryArrhythmiaSubFields["PaternalGrandfather"] === undefined || HereditaryArrhythmiaSubFields["PaternalGrandfather"].length === 0) {
      //     formIsValid = false;
      //     errors["PaternalGrandfather"] = alert("*Please check one of the option from PaternalGrandfather hereditaryArrhythmiaOrConductionDisorder...");

      //   }
      // }
    }
    if (!fields["arteriopathyConnectiveTissueDisorder"]) {
      formIsValid = false;
      errors["arteriopathyConnectiveTissueDisorder"] = "*Please enter your connective tissue disorder.";
      this.validations("check fields from arteriopathyConnectiveTissueDisorder")
    }

    if (this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder && this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.value[0] === "yes") {
      if (arteriopathyConnectiveTissueDisorderSubFields["member"] === undefined || arteriopathyConnectiveTissueDisorderSubFields["member"].length === 0) {
        formIsValid = false;
        errors["member"] = swal("*Please check one of the option from Arteriopathy/connective tissue disorder");
        this.validations("check fields from arteriopathyConnectiveTissueDisorder")

      }
      // if (this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member && this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member.find(x => x === "Mother") === "Mother") {
      //   if (arteriopathyConnectiveTissueDisorderSubFields["Mother"] === undefined || arteriopathyConnectiveTissueDisorderSubFields["Mother"].length === 0) {
      //     formIsValid = false;
      //     errors["Mother"] = alert("*Please check one of the option from Mother connective tissue disorder...");

      //   }
      // }
      // if (this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member && this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member.find(x => x === "Father") === "Father") {
      //   if (arteriopathyConnectiveTissueDisorderSubFields["Father"] === undefined || arteriopathyConnectiveTissueDisorderSubFields["Father"].length === 0) {
      //     formIsValid = false;
      //     errors["Father"] = alert("*Please check one of the option from Father connective tissue disorder...");

      //   }
      // }
      // if (this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member && this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member.find(x => x === "MaternalGrandmother") === "MaternalGrandmother") {
      //   if (arteriopathyConnectiveTissueDisorderSubFields["MaternalGrandmother"] === undefined || arteriopathyConnectiveTissueDisorderSubFields["MaternalGrandmother"].length === 0) {
      //     formIsValid = false;
      //     errors["MaternalGrandmother"] = alert("*Please check one of the option from MaternalGrandmother connective tissue disorder...");

      //   }
      // }
      // if (this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member && this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member.find(x => x === "PaternalGrandmother") === "PaternalGrandmother") {
      //   if (arteriopathyConnectiveTissueDisorderSubFields["PaternalGrandmother"] === undefined || arteriopathyConnectiveTissueDisorderSubFields["PaternalGrandmother"].length === 0) {
      //     formIsValid = false;
      //     errors["PaternalGrandmother"] = alert("*Please check one of the option from PaternalGrandmother connective tissue disorder...");

      //   }
      // }

      // if (this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member && this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member.find(x => x === "MaternalGrandfather") === "MaternalGrandfather") {
      //   if (arteriopathyConnectiveTissueDisorderSubFields["MaternalGrandfather"] === undefined || arteriopathyConnectiveTissueDisorderSubFields["MaternalGrandfather"].length === 0) {
      //     formIsValid = false;
      //     errors["MaternalGrandfather"] = alert("*Please check one of the option from MaternalGrandfather connective tissue disorder...");

      //   }
      // }

      // if (this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member && this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member.find(x => x === "PaternalGrandfather") === "PaternalGrandfather") {
      //   if (arteriopathyConnectiveTissueDisorderSubFields["PaternalGrandfather"] === undefined || arteriopathyConnectiveTissueDisorderSubFields["PaternalGrandfather"].length === 0) {
      //     formIsValid = false;
      //     errors["PaternalGrandfather"] = alert("*Please check one of the option from PaternalGrandfather connective tissue disorder...");

      //   }
      // }
    }
    if (!fields["heartfailure"]) {
      formIsValid = false;
      errors["heartfailure"] =  swal("*Mandatory option heartfailure.");
      this.validations("check fields from heartfailure")
    }

    if (this.state.familyHealthHistory.heartfailure && this.state.familyHealthHistory.heartfailure.value[0] === "yes") {
      if (heartfailureSubFields["member"] === undefined || heartfailureSubFields["member"].length === 0) {
        formIsValid = false;
        errors["member"] = swal("*Please check one of the option from heartfailure...");
        this.validations("check fields from heartfailure")


      }
      if (this.state.familyHealthHistory.heartfailure.member && this.state.familyHealthHistory.heartfailure.member.find(x => x === "Mother") === "Mother") {
        if (heartfailureSubFields["motherAge"] === undefined || heartfailureSubFields["motherAge"].length === 0) {
          formIsValid = false;
          errors["motherAge"] = swal("*Please fill motherAge heartfailure...");
          this.validations("check fields from motherAge heartfailure")


        }
      }
      if (this.state.familyHealthHistory.heartfailure.member && this.state.familyHealthHistory.heartfailure.member.find(x => x === "Father") === "Father") {
        if (heartfailureSubFields["fatherAge"] === undefined || heartfailureSubFields["fatherAge"].length === 0) {
          formIsValid = false;
          errors["fatherAge"] = swal("*Please fill fatherAge heartfailure...");
          this.validations("check fields from fatherAge heartfailure")


        }
      }
      if (this.state.familyHealthHistory.heartfailure.member && this.state.familyHealthHistory.heartfailure.member.find(x => x === "MaternalGrandmother") === "MaternalGrandmother") {
        if (heartfailureSubFields["MaternalGrandmotherAge"] === undefined || heartfailureSubFields["MaternalGrandmotherAge"].length === 0) {
          formIsValid = false;
          errors["MaternalGrandmotherAge"] = swal("*Please fill MaternalGrandmotherAge heartfailure...");
          this.validations("check fields from MaternalGrandmotherAge heartfailure")


        }
      }
      if (this.state.familyHealthHistory.heartfailure.member && this.state.familyHealthHistory.heartfailure.member.find(x => x === "PaternalGrandmother") === "PaternalGrandmother") {
        if (heartfailureSubFields["PaternalGrandmotherAge"] === undefined || heartfailureSubFields["PaternalGrandmotherAge"].length === 0) {
          formIsValid = false;
          errors["PaternalGrandmotherAge"] = swal("*Please fill PaternalGrandmotherAge heartfailure...");
          this.validations("check fields from PaternalGrandmotherAge heartfailure")


        }
      }

      if (this.state.familyHealthHistory.heartfailure.member && this.state.familyHealthHistory.heartfailure.member.find(x => x === "MaternalGrandfather") === "MaternalGrandfather") {
        if (heartfailureSubFields["MaternalGrandfatherAge"] === undefined || heartfailureSubFields["MaternalGrandfatherAge"].length === 0) {
          formIsValid = false;
          errors["MaternalGrandfatherAge"] = swal("*Please fill MaternalGrandfatherAge heartfailure...");
          this.validations("check fields from MaternalGrandfatherAge heartfailure")


        }
      }

      if (this.state.familyHealthHistory.heartfailure.member && this.state.familyHealthHistory.heartfailure.member.find(x => x === "PaternalGrandfather") === "PaternalGrandfather") {
        if (heartfailureSubFields["PaternalGrandfatherAge"] === undefined || heartfailureSubFields["PaternalGrandfatherAge"].length === 0) {
          formIsValid = false;
          errors["PaternalGrandfatherAge"] = swal("*Please fill PaternalGrandfatherAge heartfailure...");
          this.validations("check fields from PaternalGrandfatherAge heartfailure")


        }
      }
    }

    if (!fields["cardiomegalyFamilyHealthHistory"]) {
      formIsValid = false;
      errors["cardiomegalyFamilyHealthHistory"] = "*Mandatory options cardiomegalyFamilyHealthHistory.";
      this.validations("check fields from Enlarged heart ")
    }

    if (this.state.familyHealthHistory.cardiomegalyFamilyHealthHistory && this.state.familyHealthHistory.cardiomegalyFamilyHealthHistory.value[0] === "yes") {
      if (cardiomegalyFamilyHealthHistorySubFields["members"] === undefined || cardiomegalyFamilyHealthHistorySubFields["members"].length === 0) {
        formIsValid = false;
        errors["members"] = swal("*Please check one of the option from Enlarged heart...");
        this.validations("check fields from Enlarged heart ")


      }
    }

    if (!fields["suddenCardiac"]) {
      formIsValid = false;
      errors["suddenCardiac"] = "*Please chec suddenCardiac.";
      this.validations("check fields from suddenCardiac ")

    }
    if (this.state.familyHealthHistory.suddenCardiac && this.state.familyHealthHistory.suddenCardiac.value[0] === "yes") {
      if (suddenCardiacSubFields["member"] === undefined || suddenCardiacSubFields["member"].length === 0) {
        formIsValid = false;
        errors["member"] = swal("*Please check one of the option from suddenCardiac...");
        this.validations("check fields from suddenCardiac ")


      }
      if (this.state.familyHealthHistory.suddenCardiac.member && this.state.familyHealthHistory.suddenCardiac.member.find(x => x === "Mother") === "Mother") {
        if (suddenCardiacSubFields["motherAge"] === undefined || suddenCardiacSubFields["motherAge"].length === 0) {
          formIsValid = false;
          errors["motherAge"] = swal("*Please fill motherAge suddenCardiac...");
          this.validations("check fields from motherAge suddenCardiac ")


        }
      }
      if (this.state.familyHealthHistory.suddenCardiac.member && this.state.familyHealthHistory.suddenCardiac.member.find(x => x === "Father") === "Father") {
        if (suddenCardiacSubFields["fatherAge"] === undefined || suddenCardiacSubFields["fatherAge"].length === 0) {
          formIsValid = false;
          errors["fatherAge"] = swal("*Please fill fatherAge suddenCardiac...");
          this.validations("check fields from fatherAge suddenCardiac ")


        }
      }
      if (this.state.familyHealthHistory.suddenCardiac.member && this.state.familyHealthHistory.suddenCardiac.member.find(x => x === "MaternalGrandmother") === "MaternalGrandmother") {
        if (suddenCardiacSubFields["MaternalGrandmotherAge"] === undefined || suddenCardiacSubFields["MaternalGrandmotherAge"].length === 0) {
          formIsValid = false;
          errors["MaternalGrandmotherAge"] = swal("*Please fill MaternalGrandmotherAge suddenCardiac...");
          this.validations("check fields from MaternalGrandmotherAge suddenCardiac ")


        }
      }
      if (this.state.familyHealthHistory.suddenCardiac.member && this.state.familyHealthHistory.suddenCardiac.member.find(x => x === "PaternalGrandmother") === "PaternalGrandmother") {
        if (suddenCardiacSubFields["PaternalGrandmotherAge"] === undefined || suddenCardiacSubFields["PaternalGrandmotherAge"].length === 0) {
          formIsValid = false;
          errors["PaternalGrandmotherAge"] = swal("*Please fill PaternalGrandmotherAge suddenCardiac...");
          this.validations("check fields from PaternalGrandmotherAge suddenCardiac ")


        }
      }

      if (this.state.familyHealthHistory.suddenCardiac.member && this.state.familyHealthHistory.suddenCardiac.member.find(x => x === "MaternalGrandfather") === "MaternalGrandfather") {
        if (suddenCardiacSubFields["MaternalGrandfatherAge"] === undefined || suddenCardiacSubFields["MaternalGrandfatherAge"].length === 0) {
          formIsValid = false;
          errors["MaternalGrandfatherAge"] = swal("*Please fill MaternalGrandfatherAge suddenCardiac...");
          this.validations("check fields from MaternalGrandfatherAge suddenCardiac ")


        }
      }

      if (this.state.familyHealthHistory.suddenCardiac.member && this.state.familyHealthHistory.suddenCardiac.member.find(x => x === "PaternalGrandfather") === "PaternalGrandfather") {
        if (suddenCardiacSubFields["PaternalGrandfatherAge"] === undefined || suddenCardiacSubFields["PaternalGrandfatherAge"].length === 0) {
          formIsValid = false;
          errors["PaternalGrandfatherAge"] = swal("*Please fill PaternalGrandfatherAge suddenCardiac...");
          this.validations("check fields from PaternalGrandfatherAge suddenCardiac ")


        }
      }
    }
    if (!fields["heartAttack"]) {
      formIsValid = false;
      errors["heartAttack"] = swal("*Mandatory option heartAttack.");
      this.validations("check fields from heartAttack ")
    }
    if (this.state.familyHealthHistory.heartAttack && this.state.familyHealthHistory.heartAttack.value[0] === "yes") {
      if (heartAttackSubFields["member"] === undefined || heartAttackSubFields["member"].length === 0) {
        formIsValid = false;
        errors["member"] = swal("*Please check one of the option from heartAttack...");
        this.validations("check fields from heartAttack ")


      }
      if (this.state.familyHealthHistory.heartAttack.member && this.state.familyHealthHistory.heartAttack.member.find(x => x === "Mother") === "Mother") {
        if (heartAttackSubFields["motherAge"] === undefined || heartAttackSubFields["motherAge"].length === 0) {
          formIsValid = false;
          errors["motherAge"] = swal("*Please fill motherAge heartAttack...");
          this.validations("check fields from motherAge heartAttack ")


        }
      }
      if (this.state.familyHealthHistory.heartAttack.member && this.state.familyHealthHistory.heartAttack.member.find(x => x === "Father") === "Father") {
        if (heartAttackSubFields["fatherAge"] === undefined || heartAttackSubFields["fatherAge"].length === 0) {
          formIsValid = false;
          errors["fatherAge"] = swal("*Please fill fatherAge heartAttack...");
          this.validations("check fields from fatherAge heartAttack ")


        }
      }
      if (this.state.familyHealthHistory.heartAttack.member && this.state.familyHealthHistory.heartAttack.member.find(x => x === "MaternalGrandmother") === "MaternalGrandmother") {
        if (heartAttackSubFields["MaternalGrandmotherAge"] === undefined || heartAttackSubFields["MaternalGrandmotherAge"].length === 0) {
          formIsValid = false;
          errors["MaternalGrandmotherAge"] = swal("*Please fill MaternalGrandmotherAge heartAttack...");
          this.validations("check fields from MaternalGrandmotherAge heartAttack ")


        }
      }
      if (this.state.familyHealthHistory.heartAttack.member && this.state.familyHealthHistory.heartAttack.member.find(x => x === "PaternalGrandmother") === "PaternalGrandmother") {
        if (heartAttackSubFields["PaternalGrandmotherAge"] === undefined || heartAttackSubFields["PaternalGrandmotherAge"].length === 0) {
          formIsValid = false;
          errors["PaternalGrandmotherAge"] = swal("*Please fill PaternalGrandmotherAge heartAttack...");
          this.validations("check fields from PaternalGrandmotherAge heartAttack ")


        }
      }

      if (this.state.familyHealthHistory.heartAttack.member && this.state.familyHealthHistory.heartAttack.member.find(x => x === "MaternalGrandfather") === "MaternalGrandfather") {
        if (heartAttackSubFields["MaternalGrandfatherAge"] === undefined || heartAttackSubFields["MaternalGrandfatherAge"].length === 0) {
          formIsValid = false;
          errors["MaternalGrandfatherAge"] = swal("*Please fill MaternalGrandfatherAge heartAttack...");
          this.validations("check fields from MaternalGrandfatherAge heartAttack ")


        }
      }

      if (this.state.familyHealthHistory.heartAttack.member && this.state.familyHealthHistory.heartAttack.member.find(x => x === "PaternalGrandfather") === "PaternalGrandfather") {
        if (heartAttackSubFields["PaternalGrandfatherAge"] === undefined || heartAttackSubFields["PaternalGrandfatherAge"].length === 0) {
          formIsValid = false;
          errors["PaternalGrandfatherAge"] = swal("*Please fill PaternalGrandfatherAge heartAttack...");
          this.validations("check fields from PaternalGrandfatherAge heartAttack ")


        }
      }
    }
    if (!fields["stroke"]) {
      formIsValid = false;
      errors["stroke"] = swal("*Mandatory options stroke.");
      this.validations("check fields from stroke")
    }

    if (this.state.familyHealthHistory.stroke && this.state.familyHealthHistory.stroke.value[0] === "yes") {
      if (strokeSubFields["member"] === undefined || strokeSubFields["member"].length === 0) {
        formIsValid = false;
        errors["member"] = swal("*Please check one of the option from stroke...");
        this.validations("check fields from stroke")


      }
      if (this.state.familyHealthHistory.stroke.member && this.state.familyHealthHistory.stroke.member.find(x => x === "Mother") === "Mother") {
        if (strokeSubFields["motherAge"] === undefined || strokeSubFields["motherAge"].length === 0) {
          formIsValid = false;
          errors["motherAge"] = swal("*Please fill motherAge stroke...");
          this.validations("check fields from motherAge stroke")


        }
      }
      if (this.state.familyHealthHistory.stroke.member && this.state.familyHealthHistory.stroke.member.find(x => x === "Father") === "Father") {
        if (strokeSubFields["fatherAge"] === undefined || strokeSubFields["fatherAge"].length === 0) {
          formIsValid = false;
          errors["fatherAge"] = swal("*Please fill fatherAge stroke...");
          this.validations("check fields from fatherAge stroke")


        }
      }
      if (this.state.familyHealthHistory.stroke.member && this.state.familyHealthHistory.stroke.member.find(x => x === "MaternalGrandmother") === "MaternalGrandmother") {
        if (strokeSubFields["MaternalGrandmotherAge"] === undefined || strokeSubFields["MaternalGrandmotherAge"].length === 0) {
          formIsValid = false;
          errors["MaternalGrandmotherAge"] = swal("*Please fill MaternalGrandmotherAge stroke...");
          this.validations("check fields from MaternalGrandmotherAge stroke")


        }
      }
      if (this.state.familyHealthHistory.stroke.member && this.state.familyHealthHistory.stroke.member.find(x => x === "PaternalGrandmother") === "PaternalGrandmother") {
        if (strokeSubFields["PaternalGrandmotherAge"] === undefined || strokeSubFields["PaternalGrandmotherAge"].length === 0) {
          formIsValid = false;
          errors["PaternalGrandmotherAge"] = swal("*Please fill PaternalGrandmotherAge stroke...");
          this.validations("check fields from PaternalGrandmotherAge stroke")


        }
      }

      if (this.state.familyHealthHistory.stroke.member && this.state.familyHealthHistory.stroke.member.find(x => x === "MaternalGrandfather") === "MaternalGrandfather") {
        if (strokeSubFields["MaternalGrandfatherAge"] === undefined || strokeSubFields["MaternalGrandfatherAge"].length === 0) {
          formIsValid = false;
          errors["MaternalGrandfatherAge"] = swal("*Please fill MaternalGrandfatherAge stroke...");
          this.validations("check fields from MaternalGrandfatherAge stroke")


        }
      }

      if (this.state.familyHealthHistory.stroke.member && this.state.familyHealthHistory.stroke.member.find(x => x === "PaternalGrandfather") === "PaternalGrandfather") {
        if (strokeSubFields["PaternalGrandfatherAge"] === undefined || strokeSubFields["PaternalGrandfatherAge"].length === 0) {
          formIsValid = false;
          errors["PaternalGrandfatherAge"] = swal("*Please fill PaternalGrandfatherAge stroke...");
          this.validations("check fields from PaternalGrandfatherAge stroke")


        }
      }
    }

    if (!fields["aneurysm"]) {
      formIsValid = false;
      errors["aneurysm"] = swal("*Mandatory option Enlargement of an artery (aneurysm)"); 
      this.validations("check fields from  aneurysm")

    }
    if (this.state.familyHealthHistory.aneurysm && this.state.familyHealthHistory.aneurysm.value[0] === "yes") {
      if (aneurysmSubFields["member"] === undefined || aneurysmSubFields["member"].length === 0) {
        formIsValid = false;
        errors["member"] = swal("*Please check one of the option from aneurysm...");
        this.validations("check fields from  aneurysm")


      }
      if (this.state.familyHealthHistory.aneurysm.member && this.state.familyHealthHistory.aneurysm.member.find(x => x === "Mother") === "Mother") {
        if (aneurysmSubFields["motherAge"] === undefined || aneurysmSubFields["motherAge"].length === 0) {
          formIsValid = false;
          errors["motherAge"] = swal("*Please fill motherAge aneurysm...");
          this.validations("check fields from  motherAge aneurysm")


        }
      }
      if (this.state.familyHealthHistory.aneurysm.member && this.state.familyHealthHistory.aneurysm.member.find(x => x === "Father") === "Father") {
        if (aneurysmSubFields["fatherAge"] === undefined || aneurysmSubFields["fatherAge"].length === 0) {
          formIsValid = false;
          errors["fatherAge"] = swal("*Please fill fatherAge aneurysm...");
          this.validations("check fields from  fatherAge aneurysm")


        }
      }
      if (this.state.familyHealthHistory.aneurysm.member && this.state.familyHealthHistory.aneurysm.member.find(x => x === "MaternalGrandmother") === "MaternalGrandmother") {
        if (aneurysmSubFields["MaternalGrandmotherAge"] === undefined || aneurysmSubFields["MaternalGrandmotherAge"].length === 0) {
          formIsValid = false;
          errors["MaternalGrandmotherAge"] = swal("*Please fill MaternalGrandmotherAge aneurysm...");
          this.validations("check fields from  MaternalGrandmotherAge aneurysm")


        }
      }
      if (this.state.familyHealthHistory.aneurysm.member && this.state.familyHealthHistory.aneurysm.member.find(x => x === "PaternalGrandmother") === "PaternalGrandmother") {
        if (aneurysmSubFields["PaternalGrandmotherAge"] === undefined || aneurysmSubFields["PaternalGrandmotherAge"].length === 0) {
          formIsValid = false;
          errors["PaternalGrandmotherAge"] = swal("*Please fill PaternalGrandmotherAge aneurysm...");
          this.validations("check fields from  PaternalGrandmotherAge aneurysm")


        }
      }

      if (this.state.familyHealthHistory.aneurysm.member && this.state.familyHealthHistory.aneurysm.member.find(x => x === "MaternalGrandfather") === "MaternalGrandfather") {
        if (aneurysmSubFields["MaternalGrandfatherAge"] === undefined || aneurysmSubFields["MaternalGrandfatherAge"].length === 0) {
          formIsValid = false;
          errors["MaternalGrandfatherAge"] = swal("*Please fill MaternalGrandfatherAge aneurysm...");
          this.validations("check fields from  MaternalGrandfatherAge aneurysm")


        }
      }

      if (this.state.familyHealthHistory.aneurysm.member && this.state.familyHealthHistory.aneurysm.member.find(x => x === "PaternalGrandfather") === "PaternalGrandfather") {
        if (aneurysmSubFields["PaternalGrandfatherAge"] === undefined || aneurysmSubFields["PaternalGrandfatherAge"].length === 0) {
          formIsValid = false;
          errors["PaternalGrandfatherAge"] = swal("*Please fill PaternalGrandfatherAge aneurysm...");
          this.validations("check fields from  PaternalGrandfatherAge aneurysm")


        }
      }
    }

    if (!fields["dissection"]) {
      formIsValid = false;
      errors["dissection"] = swal("*Mandatory option Tear of an artery"); 
      this.validations("check fields from  dissection")
    }
    if (this.state.familyHealthHistory.dissection && this.state.familyHealthHistory.dissection.value[0] === "yes") {
      if (dissectionSubFields["member"] === undefined || dissectionSubFields["member"].length === 0) {
        formIsValid = false;
        errors["member"] = swal("*Please check one of the option from dissection...");
        this.validations("check fields from  dissection")


      }
      if (this.state.familyHealthHistory.dissection.member && this.state.familyHealthHistory.dissection.member.find(x => x === "Mother") === "Mother") {
        if (dissectionSubFields["motherAge"] === undefined || dissectionSubFields["motherAge"].length === 0) {
          formIsValid = false;
          errors["motherAge"] = swal("*Please fill motherAge dissection...");
          this.validations("check fields from  motherAge dissection")


        }
      }
      if (this.state.familyHealthHistory.dissection.member && this.state.familyHealthHistory.dissection.member.find(x => x === "Father") === "Father") {
        if (dissectionSubFields["fatherAge"] === undefined || dissectionSubFields["fatherAge"].length === 0) {
          formIsValid = false;
          errors["fatherAge"] = swal("*Please fill fatherAge dissection...");
          this.validations("check fields from  fatherAge dissection")


        }
      }
      if (this.state.familyHealthHistory.dissection.member && this.state.familyHealthHistory.dissection.member.find(x => x === "MaternalGrandmother") === "MaternalGrandmother") {
        if (dissectionSubFields["MaternalGrandmotherAge"] === undefined || dissectionSubFields["MaternalGrandmotherAge"].length === 0) {
          formIsValid = false;
          errors["MaternalGrandmotherAge"] = swal("*Please fill MaternalGrandmotherAge dissection...");
          this.validations("check fields from  MaternalGrandmotherAge dissection")


        }
      }
      if (this.state.familyHealthHistory.dissection.member && this.state.familyHealthHistory.dissection.member.find(x => x === "PaternalGrandmother") === "PaternalGrandmother") {
        if (dissectionSubFields["PaternalGrandmotherAge"] === undefined || dissectionSubFields["PaternalGrandmotherAge"].length === 0) {
          formIsValid = false;
          errors["PaternalGrandmotherAge"] = swal("*Please fill PaternalGrandmotherAge dissection...");
          this.validations("check fields from  PaternalGrandmotherAge dissection")


        }
      }

      if (this.state.familyHealthHistory.dissection.member && this.state.familyHealthHistory.dissection.member.find(x => x === "MaternalGrandfather") === "MaternalGrandfather") {
        if (dissectionSubFields["MaternalGrandfatherAge"] === undefined || dissectionSubFields["MaternalGrandfatherAge"].length === 0) {
          formIsValid = false;
          errors["MaternalGrandfatherAge"] = swal("*Please fill MaternalGrandfatherAge dissection...");
          this.validations("check fields from  MaternalGrandfatherAge dissection")


        }
      }

      if (this.state.familyHealthHistory.dissection.member && this.state.familyHealthHistory.dissection.member.find(x => x === "PaternalGrandfather") === "PaternalGrandfather") {
        if (dissectionSubFields["PaternalGrandfatherAge"] === undefined || dissectionSubFields["PaternalGrandfatherAge"].length === 0) {
          formIsValid = false;
          errors["PaternalGrandfatherAge"] = swal("*Please fill PaternalGrandfatherAge dissection...");
          this.validations("check fields from  PaternalGrandfatherAge dissection")


        }
      }
    }
    if (!fields["faintingOrPassing"]) {
      formIsValid = false;
      errors["faintingOrPassing"] = swal("*Mandatory option faintingOrPassing"); 
      this.validations("check fields from  Fainting or passing ")
    }
    if (this.state.familyHealthHistory.faintingOrPassing && this.state.familyHealthHistory.faintingOrPassing.value[0] === "yes") {
      if (faintingOrPassingSubFields["members"] === undefined || faintingOrPassingSubFields["members"].length === 0) {
        formIsValid = false;
        errors["members"] = swal("*Please check one of the option from Fainting or passing...");
        this.validations("check fields from  Fainting or passing ")


      }
    }
    if (!fields["heartOrVascular"]) {
      formIsValid = false;
      errors["heartOrVascular"] = swal("*Mandatory heartOrVascular.");
      this.validations("check fields from  Fainting or passing ")
    }

    if (this.state.familyHealthHistory.heartOrVascular && this.state.familyHealthHistory.heartOrVascular.value[0] === "yes") {
      if (heartOrVascularSubFields["members"] === undefined || heartOrVascularSubFields["members"].length === 0) {
        formIsValid = false;
        errors["members"] = swal("*Please check one of the option from Heart or vascular...");
        this.validations("check fields from  Heart or vascular ")


      }
    }
    // if (!fields["arrhythmiaFamilyHealthHistory"]) {
    //   formIsValid = false;
    //   errors["arrhythmiaFamilyHealthHistory"] = "*Mandatory options arrhythmiaFamilyHealthHistory.";
    // }

    if (this.state.familyHealthHistory.arrhythmiaFamilyHealthHistory && this.state.familyHealthHistory.arrhythmiaFamilyHealthHistory.value[0] === "yes") {
      if (arrhythmiaFamilyHealthHistorySubFields["members"] === undefined || arrhythmiaFamilyHealthHistorySubFields["members"].length === 0) {
        formIsValid = false;
        errors["members"] = swal("*Please check one of the option from Irregular heartbeat...");
        this.validations("check fields from  Irregular heartbeat ")


      }
    }

    // if (!fields["cholesterolDeposits"]) {
    //   formIsValid = false;
    //   errors["cholesterolDeposits"] = "*Please enter your cholesterolDeposits.";
    // }
    if (this.state.familyHealthHistory.cholesterolDeposits && this.state.familyHealthHistory.cholesterolDeposits.value[0] === "yes") {
      if (cholesterolDepositsSubFields["members"] === undefined || cholesterolDepositsSubFields["members"].length === 0) {
        formIsValid = false;
        errors["members"] = swal("*Please check one of the option from Cholesterol deposits...");
        this.validations("check fields from   Cholesterol deposits ")


      }
    }
    if (!fields["diabetesFamilyHealthHistory"]) {
      formIsValid = false;
      errors["diabetesFamilyHealthHistory"] = swal("*Mandatory option Diabetes");
      this.validations("check fields from  Diabetes ")
    }
    if (this.state.familyHealthHistory.diabetesFamilyHealthHistory && this.state.familyHealthHistory.diabetesFamilyHealthHistory.value[0] === "yes") {
      if (diabetesFamilyHealthHistorySubFields["members"] === undefined || diabetesFamilyHealthHistorySubFields["members"].length === 0) {
        formIsValid = false;
        errors["members"] = swal("*Please check one of the option from Diabetes...");
        this.validations("check fields from  Diabetes ")


      }
    }
    if (!fields["hyperTension"]) {
      formIsValid = false;
      errors["hyperTension"] =swal("*Mandatory option Hypertension");
    }
    if (this.state.familyHealthHistory.hyperTension && this.state.familyHealthHistory.hyperTension.value[0] === "yes") {
      if (hyperTensionSubFields["members"] === undefined || hyperTensionSubFields["members"].length === 0) {
        formIsValid = false;
        errors["members"] = swal("*Please check one of the option from hyperTension...");
        this.validations("check fields from  check fields from  Hypertension ")


      }
    }
    if (!fields["hereditaryHeartConditions"]) {
      formIsValid = false;
      errors["hereditaryHeartConditions"] = swal("*Mandatory option hereditary heart conditions.")
    }

    if (this.state.familyHealthHistory.hereditaryHeartConditions && this.state.familyHealthHistory.hereditaryHeartConditions.value[0] === "yes") {
      if (hereditaryHeartConditionsSubFields["members"] === undefined || hereditaryHeartConditionsSubFields["members"].length === 0) {
        formIsValid = false;
        errors["members"] = swal("*Please check one of the option from hereditaryHeartConditions...");
        this.validations("check fields from  check fields from  hereditaryHeartConditions")


      }
    }

    // if(this.state.familyHealthHistory.hereditaryHeartConditions.value==="yes"){
    //   if (hereditaryHeartConditionsSubFields["familyMember"]===undefined||hereditaryHeartConditionsSubFields["familyMember"]==="") {
    //     formIsValid = false;
    //     errors["familyMember"] = alert("*Please select family member from heart conditions...");

    //   }
    // }



    // if (!fields["personalOrFamilyHistory"]) {
    //   formIsValid = false;
    //   errors["personalOrFamilyHistory"] = "*Mandatory personalOrFamilyHistory.";
    // }
    // if (!fields["cornealArcusFamilyHistory"]) {
    //   formIsValid = false;
    //   errors["cornealArcusFamilyHistory"] = "*Mandatory options cornealArcusFamilyHistory.";
    // }
    if (this.state.familyHealthHistory.cornealArcusFamilyHistory && this.state.familyHealthHistory.cornealArcusFamilyHistory.value[0] === "yes") {
      if (cornealArcusFamilyHistorySubFields["members"] === undefined || cornealArcusFamilyHistorySubFields["members"].length === 0) {
        formIsValid = false;
        errors["members"] = alert("*Please check one of the option from Diabetes...");
        this.validations("check fields from  Diabetes ")


      }
    }


    this.setState({
      familyHealthHistoryFormErrors: errors,

    });
    return formIsValid;
  }



  handleChangefamilyHealthHistory(event) {
    let family = this.state.familyHealthHistory
    family[event.target.name] = event.target.value;
    this.setState({ familyHealthHistory: family })
  }
  handleChangeMultiplefamilyHealthHistory(event) {

    let family = this.state.familyHealthHistory
    family[event.target.name] = { value: event.target.value }
    this.setState({
      familyHealthHistory: family
    })

  }

  handleChangeAddDatafamilyHealthHistory(event, name) {

    let data = this.state.familyHealthHistory[name]
    data[event.target.name] = event.target.value

    let family = this.state.familyHealthHistory
    family[name] = data
    this.setState({
      familyHealthHistory: family
    })

  }
  onContinue() {
    let helathhis = this.state.healthHistory

    if (this.state.dayOfBirth !== undefined && this.state.monthOfBirth !== undefined && this.state.yearOfBirth !== undefined) {
      let dateOfBirth = this.state.dayOfBirth + "/" + this.state.monthOfBirth + "/" + this.state.yearOfBirth
      helathhis['dateOfBirth'] = dateOfBirth;
      let tall = this.state.heightFeet + "." + this.state.heightInches
      helathhis['tall'] = tall;

    }

    if (this.state.bloodPressureMax !== undefined && this.state.bloodPressureMin !== undefined) {
      let bloodPressureReading = this.state.bloodPressureMin + "/" + this.state.bloodPressureMax
      helathhis['bloodPressureReading'] = bloodPressureReading;


    }
    // this.setState({
    //   dateOfBirth: dateOfBirth,
    //   bloodPressureReading: bloodPressureReading
    // }) 
    this.setState({ healthHistory: helathhis }, function () {
      this.saveData()
    })


    //     this.setState({ healthHistory:{ dateOfBirth: dateOfBirth,
    //          bloodPressureReading: bloodPressureReading} }, function () {
    //       console.log(this.state);
    //       this.saveData()
    //  });

  }

  onContinueFirstPage() {
    let helathhis = this.state.healthHistory
    let fields=this.state

    if (this.state.dayOfBirth !== undefined && this.state.monthOfBirth !== undefined && this.state.yearOfBirth !== undefined && fields["dayOfBirth"]<0 || fields["dayOfBirth"]>31 && fields["monthOfBirth"]<0 || fields["monthOfBirth"]>12 && fields["yearOfBirth"].length != 4) {
      let dateOfBirth = fields.dayOfBirth + "/" + fields.monthOfBirth + "/" + fields.yearOfBirth
      helathhis['dateOfBirth'] = dateOfBirth;
    }
     
     if(fields["heightInches"] != undefined && fields["heightInches"] < 0 || fields["heightInches"] > 12 && fields["heightFeet"] != undefined && fields["heightFeet"] < 0 || fields["heightFeet"] > 6)
     { let tall = fields.heightFeet + "." + fields.heightInches
      helathhis['tall'] = tall;
    }

    

    if (this.state.bloodPressureMax !== undefined && this.state.bloodPressureMin !== undefined) {
      let bloodPressureReading = this.state.bloodPressureMin + "/" + this.state.bloodPressureMax
      helathhis['bloodPressureReading'] = bloodPressureReading;


    }
    // this.setState({
    //   dateOfBirth: dateOfBirth,
    //   bloodPressureReading: bloodPressureReading
    // }) 
    this.setState({ healthHistory: helathhis }, function () {
      this.saveDataFirstPage()
    })


    //     this.setState({ healthHistory:{ dateOfBirth: dateOfBirth,
    //          bloodPressureReading: bloodPressureReading} }, function () {
    //       console.log(this.state);
    //       this.saveData()
    //  });


  }
  hereditaryHeartConditionsValidations() {
    let formIsValid = true;
    if (this.state.healthHistory.healthHistoryHeartAttack !== undefined && this.state.healthHistory.healthHistoryHeartAttack.value === "true") {
      if (this.state.healthHistory.healthHistoryHeartAttack.age === undefined || this.state.healthHistory.healthHistoryHeartAttack.age=== "") {
        this.setState({
          heartAttackAggError: true,
        });
        formIsValid = false;

      } else {
        this.setState({
          heartAttackAggError: false,
        });
      }

    }
    if (this.state.healthHistory.suddenCardiacArrest !== undefined && this.state.healthHistory.suddenCardiacArrest.value === "true") {
      if (this.state.healthHistory.suddenCardiacArrest.age === undefined || this.state.healthHistory.suddenCardiacArrest.age=== "") {
        this.setState({
          suddenCardiacArrestAggError: true,
        });
        formIsValid = false;

      } else {
        this.setState({
          suddenCardiacArrestAggError: false,
        });
      }

    }
    if (this.state.healthHistory.healthHistoryStroke !== undefined && this.state.healthHistory.healthHistoryStroke.value === "true") {
      if (this.state.healthHistory.healthHistoryStroke.age === undefined || this.state.healthHistory.healthHistoryStroke.age=== "") {
        this.setState({
          strokeAggError: true,
        });
        formIsValid = false;

      } else {
        this.setState({
          strokeAggError: false,
        });
      }

    }
    if (this.state.healthHistory.healthHistoryhereditaryHeartConditions !== undefined && this.state.healthHistory.healthHistoryhereditaryHeartConditions.value === "true") {
      if (this.state.healthHistory.healthHistoryhereditaryHeartConditions.whoPerformedGeneticTest === undefined || this.state.healthHistory.healthHistoryhereditaryHeartConditions.whoPerformedGeneticTest === "") {
        this.setState({
          hereditaryHeartConditionswhoPerformedGeneticTestError: true,
          mutationIdentifiedForHeridHeartError: true
        });
        formIsValid = false;

      } else {
        this.setState({
          mutationIdentifiedForHeridHeartError: false,
          hereditaryHeartConditionswhoPerformedGeneticTestError: false,
        });
      }

    }


    if (this.state.healthHistory.healthHistoryhereditaryHeartConditions !== undefined && this.state.healthHistory.healthHistoryhereditaryHeartConditions.value === "true") {
      if (this.state.healthHistory.healthHistoryhereditaryHeartConditions.mutationIdentifiedForHeridHeart === undefined || this.state.healthHistory.healthHistoryhereditaryHeartConditions.mutationIdentifiedForHeridHeart === "") {
        this.setState({
          mutationIdentifiedForHeridHeartError: true
        });
        formIsValid = false;
      } else {
        this.setState({
          mutationIdentifiedForHeridHeartError: false
        });
      }

    }
    return formIsValid;
  }



  validationsForDoesYourFatherHaveSiblings() {
    let formIsValid = true;

    if (this.state.familyTree.doesYourFatherHaveAnySiblings !== undefined && this.state.familyTree.doesYourFatherHaveAnySiblings.value === "yes") {
      if (this.state.familyTree.doesYourFatherHaveAnySiblings.howManySisters === undefined || this.state.familyTree.doesYourFatherHaveAnySiblings.howManySisters === "" || this.state.familyTree.doesYourFatherHaveAnySiblings.howManyBrothers === undefined || this.state.familyTree.doesYourFatherHaveAnySiblings.howManyBrothers === "") {
        this.setState({
          doesYourFatherHaveAnySiblingsError: true
        })
        formIsValid = false;

      } else {
        this.setState({
          doesYourFatherHaveAnySiblingsError: false
        })
      }
    }
    return formIsValid;
  }

  saveData() {
    if (this.state.healthHistory.healthHistoryhereditaryHeartConditions !== undefined && this.state.healthHistory.healthHistoryhereditaryHeartConditions !== "") {
      let data=this.state.healthHistory
        data["healthHistoryStatus"]="Complete"
        this.setState({healthHistory:data})

    }
    //console.log(this.state.dateOfBirth)
    if (this.healthHistoryFormValidate() && this.hereditaryHeartConditionsValidations()) {
      axios.post('healthHistory/savehealthHistory/' + Cookies.get("userId"), this.state.healthHistory)
        .then((response) => {

          this.changeTab(2);
        }).catch(
          error => {
            if (error.response === undefined) {
              this.props.history.push("/error")
            } else {
              this.setState({
                errorMsg: error.response.data.message
              })
            }
          }
        )

    }
  }

  saveDataFirstPage() {
    if (this.healthHistoryFormValidateFirstPage()) {
      axios.post('healthHistory/savehealthHistory/' + Cookies.get("userId"), this.state.healthHistory)
        .then((response) => {
          this.setState({ showing: false })

        }).catch(
          error => {
            if (error.response === undefined) {
              this.props.history.push("/error")
            } else {
              this.setState({
                errorMsg: error.response.data.message
              })
            }
          }
        )

    }

  }


  submitFamilyTree() {
      if (this.state.familyTree.doesYourFatherHaveAnySiblings !== undefined || this.state.familyTree.doesYourFatherHaveAnySiblings !== "") {
        let data=this.state.familyTree
        data["familyTreeStatus"]="Complete"
        this.setState({familyTree:data})
        }
    if (this.familyTreeFormValidate() && this.validationsForDoesYourFatherHaveSiblings()&&this.familyTreeFormValidateForDoYouHaveAnyChildren()&&this.familyTreeFormValidateForDoYouHaveAnySiblings()) {
      axios.post('familyTree/saveFamilyTree/' + Cookies.get("userId"), this.state.familyTree)
        .then((response) => {
          this.changeTab(3);
        }).catch(
          error => {
            if (error.response === undefined) {
              this.props.history.push("/error")
            } else {
              this.setState({
                errorMsg: error.response.data.message
              })
            }
          }
        )

    }
  }

  saveFamilyTreeSectionWise() {
    axios.post('familyTree/saveFamilyTree/' + Cookies.get("userId"), this.state.familyTree)
      .then((response) => {

        // this.changeTab(3);
      }).catch(
        error => {
          if (error.response === undefined) {
            this.props.history.push("/error")
          } else {
            this.setState({
              errorMsg: error.response.data.message
            })
          }
        }
      )

  }

  submitfamilyHealthHistory() {
   let data=this.state.familyHealthHistory
   data["familyHealthHistoryStatus"]="Complete"
   this.setState({familyHealthHistory:data})
    if (this.familyHealthHistoryFormValidate()) {
      axios.post('familyHealthHistory/saveFamilyHealthHistory/' + Cookies.get("userId"), this.state.familyHealthHistory)
        .then((response) => {

          this.changeTab(4);
        }).catch(
          error => {
            if (error.response === undefined) {
              this.props.history.push("/error")
            } else {
              this.setState({
                errorMsg: error.response.data.message
              })
            }
          }
        )

    }
  }

  decrement(sName, name) {
    let count = name + "Count"

    if (this.state[count] == 0) {
      return <img name={name} className="img-fluid pr-3" src="./img/payment/minus.png" />
    } else {
      return <img name={name} className="img-fluid pr-3" src="./img/payment/minus.png" onClick={(e) => this.removeItem(e, sName)} />
    }
  }

  IncrementItem = (event, name) => {
    //alert(event.target.value)
    let count = event.target.name + "Count"
    this.setState({ [count]: parseInt(this.state[count]) + 1 });
    this.handleChangeAddDatafamilyTreeForMany(event, name)
  }
  removeItem = (event, name) => {

    let value = event.target.name
    let n = name

    // if(value==="biologicaldaughters"){

    //   if(this.state.familyTree.doYouHaveAnyChildren.biologicaldaughters[1]!==undefined && (this.state.familyTree.doYouHaveAnyChildren.biologicaldaughters[1].daughterAge===""||this.state.familyTree.doYouHaveAnyChildren.biologicaldaughters[1].daughterAge===undefined)){
    //     alert("empty..");
    //   }else if(this.state.familyTree.doYouHaveAnyChildren.biologicaldaughters[1]===undefined){
    //     alert("empty..2");
    //   }else{
    //     alert("no..2");
    //   }

    // }


    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to remove.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.DecreaseItem(value, n)
        },
        {
          label: 'No',
        }
      ]
    });
  }
  DecreaseItem = (event, name) => {
    //alert(event)
    let count = event + "Count"
    this.setState({ [count]: parseInt(this.state[[count]]) - 1 });
    this.handleChangeAddDatafamilyTreeForManyRemove(event, name)

  }
  uploadImage(e) {
    let file = e.target.files[0];
    if(file.size > 5000000) {
        swal("File max limit has been exceed")
    }else{
      this.setState({
        prescription: file,
        fileName: file.name
      }, function () {
        this.Image()
      }
      )
    }
  }
  Image() {

    let file = this.state.prescription
    const formdata = new FormData()

    //  formdata.append("file", Buffer.from(file), {
    //   'filename': file.name,
    //   'contentType': file.mimetype,
    //  });


    formdata.append('file', file)
    // formdata.append('name','Prescription')
    //const formData = this.state.prescription
    //   const config = {     
    //     headers: { 'content-type': 'multipart/form-data' }
    // }

    axios.post('documents/uploadDocuments/' + Cookies.get("userId"), formdata)
      .then((response) => {

        // this.changeTab(4);
      }).catch(
        error => {
          if (error.response === undefined) {
            this.props.history.push("/error")
          } else {
            this.setState({
              errorMsg: error.response.data.message
            })
          }
        }
      )
  }
  calculate_age = (dob1) => {
    var today = new Date();
    var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
    {
        age_now--;
    }
    return age_now;
  }
  handleChange_age = (event) => {

    this.setState({ dob1: event.target.value }, () => {
      // example of setState callback
      // this will have the latest this.state.dob1
    })

    // call calculate_age with event.target.value
    var age_latest = {age_latest: this.calculate_age(event.target.value)}

    this.setState({ age1: age_latest }, () => {
      // this will have the latest this.state.age1
    })
  }

  render() {
   

    let biologicaldaughters;
    let biologicalsons;
    let sisters;
    let brothers;
    //let noOfFiles=converter.toWords(this.state.noOfFiles);
    let noOfFiles = this.state.noOfFiles;

    var files = '' + noOfFiles;
    this.state.familyTree.doYouHaveAnyChildren !== undefined ? this.state.familyTree.doYouHaveAnyChildren.biologicaldaughters !== undefined ? console.log() : biologicaldaughters = 0 : biologicaldaughters = 0

    this.state.familyTree.doYouHaveAnyChildren !== undefined ? this.state.familyTree.doYouHaveAnyChildren.biologicaldaughters !== undefined ? biologicaldaughters = this.state.familyTree.doYouHaveAnyChildren.biologicaldaughters[0].value : biologicaldaughters = 0 : biologicaldaughters = 0
    //this.state.doYouHaveAnyChildren.biologicaldaughters!=undefined? biologicaldaughters=0 : biologicaldaughters = this.state.doYouHaveAnyChildren.biologicaldaughters

    this.state.familyTree.doYouHaveAnyChildren !== undefined ? this.state.familyTree.doYouHaveAnyChildren.biologicalsons !== undefined ? biologicalsons = this.state.familyTree.doYouHaveAnyChildren.biologicalsons[0].value : biologicalsons = 0 : biologicalsons = 0

    this.state.familyTree.doYouHaveAnySiblings !== undefined ? this.state.familyTree.doYouHaveAnySiblings.sisters !== undefined ? sisters = this.state.familyTree.doYouHaveAnySiblings.sisters[0].value : sisters = 0 : sisters = 0

    this.state.familyTree.doYouHaveAnySiblings !== undefined ? this.state.familyTree.doYouHaveAnySiblings.brothers !== undefined ? brothers = this.state.familyTree.doYouHaveAnySiblings.brothers[0].value : brothers = 0 : brothers = 0
    return (
      <div>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
        <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
        {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css"
    integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf"
    crossorigin="anonymous"> */}
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <link href="https://www.jqueryscript.net/css/jquerysctipttop.css" rel="stylesheet" type="text/css" />
        <title>truGeny</title>
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <link rel="stylesheet" href="./css/jquery.calendar.css" />
        <style dangerouslySetInnerHTML={{ __html: "\n  .hello {\n    padding: 0px;\n    box-shadow: 0 17px 73px rgba(0, 0, 0, 0.57);\n    border-radius: 40px;\n    background-color: #eff7ff;\n  }\n\n  .title-font-modal {\n    width: 514px;\n    height: 36px;\n    color: #2c333c;\n    font-family: \"Roboto - Black\";\n    font-size: 36px;\n    font-weight: 400;\n  }\n\n  .title-part-modal {\n    width: 890px;\n    height: 130px;\n    background-color: #eff7ff;\n  }\n\n  .resize-1 {\n    width: 396px;\n    height: 60px;\n    background-color: #ffffff;\n  }\n\n  .textr-box-size {\n    /* Style for \"Input\" */\n    width: 396px;\n    height: 60px;\n    border: 1px solid #ebebeb;\n    background-color: #ffffff;\n  }\n  " }} />
        <link rel="stylesheet" href="./styleinline.css" />
        
        <link rel="stylesheet" href="style-mobile.css" />
        <link rel="stylesheet" href="./style-mobile2.css" />
        
        
          {/* header */}
          <CommonHeder category="healthHistory"></CommonHeder>
          {/* end of header */}
          <div style={{paddingBottom: '76px'}} className="padding22" />

          <div style={{marginTop: '8rem'}} className="margin">
        </div>
          {/* -----------------------------------------start book my session------------------------------------------------- */}
          <div className="container hello" style={{ backgroundColor: '#ffffff' }}>
            <section className="reasons-sec">
              <div className="container reasons-sec-wrap gr-sec-wrap pr-0 pl-0 pb-0">
                {/* <div class="container my-5"> */}
                <div className="reasons-main-header-wrap">
                  <div className="abc bg-white">
                    <h2 className="pad-l-2rem text-uppercase" style={{ fontSize: '2.25rem', fontWeight: '900px', marginTop: '4.18rem' }}>{this.state.title}</h2>
                    {/* <p class=" p-0 m-0" style="font-weight:400;font-size:1rem">You have just taken a step towards better health.</p> */}
                    <div style={{ paddingBottom: '2rem' }}>
                    </div>
                    <ul className="nav " id="myTab" role="tablist">
                      <li className="nav-item">
                        <a className="nav-link active pad-l-2rem" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true" style={{ color: this.state.isActive === 1 ? "#4f5be7" : "#2c333c" }} onClick={() => this.changeTab(1)}>HEALTH HISTORY&nbsp;&nbsp;&nbsp;<i className="fas fa-caret-right" /></a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false" style={{ color: this.state.isActive === 2 ? "#4f5be7" : "#2c333c" }} onClick={() => this.changeTab(2)}>FAMILY TREE&nbsp;&nbsp;&nbsp;<i className="fas fa-caret-right" /></a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" id="messages-tab" data-toggle="tab" href="#messages" role="tab" aria-controls="messages" aria-selected="false" style={{ color: this.state.isActive === 3 ? "#4f5be7" : "#2c333c" }} onClick={() => this.changeTab(3)}>FAMILY HEALTH HISTORY&nbsp;&nbsp;&nbsp;<i className="fas fa-caret-right"></i></a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" id="settings-tab" data-toggle="tab" href="#settings" role="tab" aria-controls="settings" aria-selected="false" style={{ color: this.state.isActive === 4 ? "#4f5be7" : "#2c333c" }} onClick={() => this.changeTab(4)}>STATUS</a>
                      </li>
                    </ul>
                  </div>
                  {/* eo gr-header */}
                </div>
                {/* eo gr-header */}
                <div style={{ paddingBottom: '2rem' }}>
                </div>
                <div className="tab-content">
                  <div className={this.state.isActive === 1 ? "tab-pane active" : "tab-pane"} id="home" role="tabpanel" aria-labelledby="home-tab">
                    {/* <h2 class="text-center pb-5" style="font-size: 1.8;font-weight: 900;color: #4f5be7;">Select a plan that works best for you</h2> */}
                    <div className="health-his-firsttab-first-sec container p-0">
                      <div className="row">
                        <div className="col-sm-6 align-self-center roboto-reg-16px ">
                          <p className="pad-l-2rem">The information you provide in this section will help you receive
                            better guidance from our Genetic counsellors and will enable
                            us to make your truGeny test report more informative and personalised,
                                  </p><p>
                          </p><p className="pad-l-2rem">All the information you provide is kept confidential and
                            secure. So go ahead and fill in the information to the
                                    best extent you can.</p>
                        </div>
                        <div className="col-sm-6 mb-5 pl-3 d-flex justify-content-center">
                          <img className="img-fluid p1" src="./img/Health-History/Illustration-1.png" />
                        </div>
                      </div>
                    </div>

                    {this.state.showing ? <div>
                      <div className="hh-ftab-2-sec container p-0">
                        <h2 className="pad-l-2rem pt-3 pb-3">General wellness</h2>
                        <p className="pad-l-2rem pt-0 pb-4 ">This information you provide will be used to assess your risk of
                          developing a cardiovascular disease (including heart attack, stroke,
                                heart failure, and others) within the next 10 years.</p>
                      </div>
                      <div className="hh-ft-3-qa-sec container p-0">
                        <h2 className=" pad-l-2rem mb-2">What is your date of birth ?</h2>
                        <div className="d-flex inline pad-l-2rem ">
                        <div className="input-group-prepend mb-3 mr-2" style={{ border: 'solid 1px #ebebeb', width: '200px', height: '60px' }}>
                            <NumberFormat allowNegative={false} maxLength={2}   name="dayOfBirth"   placeholder="Day" className="form-control roboto-reg-16px" style={{ height: '57px', borderColor: this.state.healthHistoryFormErrors.dayOfBirth ? "#ff0000" : "" }} aria-label="Text input with dropdown button" value={this.state.dayOfBirth} onChange={this.handle} />
                          </div>
                          <div className=" input-group-prepend input-group date mb-3 ml-0 mr-2 " id="datetimepicker1" style={{ border: 'solid 1px #ebebeb', width: '200px', height: '60px' }}>
                            <NumberFormat allowNegative={false} maxLength={2}    name="monthOfBirth" placeholder="Month" className="form-control roboto-reg-16px " style={{ height: '57px', borderColor: this.state.healthHistoryFormErrors.monthOfBirth ? "#ff0000" : "" }} aria-label="Text input with dropdown button" value={this.state.monthOfBirth} onChange={this.handle} />
                            {/*  */}
                          </div>
                          
                          <div className="input-group-prepend mb-3 mr-2" style={{ border: 'solid 1px #ebebeb', width: '200px', height: '60px' }}>
                            <NumberFormat allowNegative={false} maxLength={4} minLength={4} name="yearOfBirth" placeholder="Year"  className="form-control roboto-reg-16px" style={{ height: '57px', borderColor: this.state.healthHistoryFormErrors.yearOfBirth ? "#ff0000" : "" }} aria-label="Text input with dropdown button" value={this.state.yearOfBirth} onChange={this.handle} />
                          </div>
                         
                        </div>
                        <h2 className=" pad-l-2rem mb-2">Your age is:</h2>
                        <div className="d-flex inline pad-l-2rem ">
                          <div className="input-group-prepend mb-3 mr-2" style={{ border: 'solid 1px #ebebeb', width: '200px', height: '60px' }}>
                          <input  readOnly name="old" placeholder="Your Age is" className="form-control roboto-reg-16px" style={{ height: '57px', borderColor: this.state.healthHistoryFormErrors.old ? "#ff0000" : "" }} aria-label="Text input with dropdown button" value={this.state.healthHistory.old}  />
                        </div>
                        </div>
                       
                        <h2 className=" pad-l-2rem mb-2">How tall are you?</h2>
                        <div className="d-flex inline pad-l-2rem ">
                          <div className=" input-group-prepend mb-3 ml-0 mr-2" style={{ border: 'solid 1px #ebebeb', width: '200px', height: '60px' }}>
                            <NumberFormat allowNegative={false} maxLength={2}  name="heightFeet" placeholder="Feet" className="form-control roboto-reg-16px" style={{ height: '57px', borderColor: this.state.healthHistoryFormErrors.heightFeet ? "#ff0000" : "" }} aria-label="Text input with dropdown button" value={this.state.heightFeet} onChange={this.handleTall} aria-label="Text input with dropdown button" />
                          </div>
                          <div className=" input-group-prepend mb-3 ml-0 mr-2" style={{ border: 'solid 1px #ebebeb', width: '200px', height: '60px' }}>
                            <NumberFormat allowNegative={false} maxLength={2}  name="heightInches" placeholder="Inches/Cms" className="form-control roboto-reg-16px" style={{ height: '57px', borderColor: this.state.healthHistoryFormErrors.heightInches ? "#ff0000" : "" }} aria-label="Text input with dropdown button" value={this.state.heightInches} onChange={this.handleTall} aria-label="Text input with dropdown button" />
                          </div>
                          
                        </div>
                        <h2 className=" pad-l-2rem mb-2" >How much do you weigh?</h2>
                        <div className="d-flex inline pad-l-2rem " id="check weight">
                          <div className=" input-group-prepend mb-3 ml-0 mr-2" style={{ border: 'solid 1px #ebebeb', width: '200px', height: '60px' }}>
                            <NumberFormat allowNegative={false} maxLength={3}  name="weight" placeholder="Kgs" className="form-control roboto-reg-16px" style={{ height: '57px', borderColor: this.state.healthHistoryFormErrors.weight ? "#ff0000" : "" }} aria-label="Text input with dropdown button" value={this.state.healthHistory.weight} onChange={this.handleChange} />
                          </div>
                        </div>
                        {/* <h2 className=" pad-l-2rem mb-2">Your most recent blood pressure reading.</h2>
                        <div className="d-flex inline pad-l-2rem ">
                          <div className=" input-group-prepend mb-3 ml-0 mr-2" style={{ border: 'solid 1px #ebebeb', width: '200px', height: '60px' }}>
                            <input type="number" name="bloodPressureMin" className="form-control roboto-reg-16px" style={{ height: '57px', borderColor: this.state.healthHistoryFormErrors.bloodPressureMin ? "#ff0000" : "" }} aria-label="Text input with dropdown button" value={this.state.bloodPressureMin} onChange={this.handle} />
                          </div>
                          <h1>/</h1><h1>
                            <div className="input-group-prepend mb-3 mr-2" style={{ border: 'solid 1px #ebebeb', width: '200px', height: '60px' }}>
                              <input type="number" maxLength="2" title="only two numbers" name="bloodPressureMax" className="form-control roboto-reg-16px" style={{ height: '57px', borderColor: this.state.healthHistoryFormErrors.bloodPressureMax ? "#ff0000" : "" }} aria-label="Text input with dropdown button" value={this.state.bloodPressureMax} onChange={this.handle} />
                            </div>
                          </h1></div> */}
                        <h2 className=" pad-l-2rem mb-2">Do you currently:</h2>
                        <div className="container pad-l-2rem ">
                          <table className="table table-responsive">

                            <tbody>
                              <tr>
                                {/* <th scope="row">1</th> */}
                                <td className="bold-18" style={{ borderTop: 'none' }}>Smoke Cigarettes</td>
                                <td style={{ borderTop: 'none' }}>
                                  <div className="form-check  mr-5">
                                    <input className="form-check-input" type="radio" name="doYouSmoke" id="doYouSmoke"  value={true} onChange={this.handleChange} checked={this.state.healthHistory.doYouSmoke === "true" ? "checked" : ""} />
                                    <label className="form-check-label" htmlFor="exampleRadios1">
                                      <h6 style={{ color: this.state.healthHistoryFormErrors.doYouSmoke ? "#ff0000" : "" }} className="reg-18">Yes</h6>
                                    </label>
                                  </div>
                                </td>
                                <td style={{ borderTop: 'none' }}>
                                  <div className="form-check  mr-5">
                                    <input className="form-check-input" type="radio" name="doYouSmoke" id="doYouSmoke"  value={false} onChange={this.handleChange} checked={this.state.healthHistory.doYouSmoke === "false" ? "checked" : ""} />
                                    <label className="form-check-label" htmlFor="exampleRadios1">
                                      <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.doYouSmoke ? "#ff0000" : "" }}>  No</h6>
                                    </label>
                                  </div>
                                </td>
                                <td style={{ borderTop: 'none' }} />
                              </tr>
                              <tr>
                                {/* <th scope="row">2</th> */}
                                <td className="bold-18">Drink Alcohol</td>
                                <td>
                                  <div className="form-check  mr-5">
                                    <input className="form-check-input" type="radio" name="drinkAlcohol" id="drinkAlcohol"  value="Heavily" onChange={this.handleChange} checked={this.state.healthHistory.drinkAlcohol === "Heavily" ? "checked" : ""} />
                                    <label className="form-check-label" htmlFor="drinkAlcohol">
                                      <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.drinkAlcohol ? "#ff0000" : "" }}>Yes Heavily (over 5 times a week)</h6>
                                    </label>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-check  mr-5">
                                    <input className="form-check-input" type="radio" name="drinkAlcohol" id="drinkAlcohol"  value="moderately" onChange={this.handleChange} checked={this.state.healthHistory.drinkAlcohol === "moderately" ? "checked" : ""} />
                                    <label className="form-check-label" htmlFor="drinkAlcohol">
                                      <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.drinkAlcohol ? "#ff0000" : "" }}>Moderately (twice a week)</h6>
                                    </label>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-check  mr-5">
                                    <input className="form-check-input" type="radio" name="drinkAlcohol" id="drinkAlcohol"  value="never" onChange={this.handleChange} checked={this.state.healthHistory.drinkAlcohol === "never" ? "checked" : ""} />
                                    <label className="form-check-label" htmlFor="drinkAlcohol">
                                      <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.drinkAlcohol ? "#ff0000" : "" }}>  Never</h6>
                                    </label>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                {/* <th scope="row">3</th> */}
                                <td className="bold-18">Have Diabetes</td>
                                <td>
                                  <div className="form-check  mr-5">
                                    <input className="form-check-input" type="radio" name="haveDiabetes" id="haveDiabetes"  value={true} onChange={this.handleChange} checked={this.state.healthHistory.haveDiabetes === "true" ? "checked" : ""} />
                                    <label className="form-check-label" htmlFor="haveDiabetes">
                                      <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.haveDiabetes ? "#ff0000" : "" }}>Yes</h6>
                                    </label>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-check  mr-5">
                                    <input className="form-check-input" type="radio" name="haveDiabetes" id="haveDiabetes"  value={false} onChange={this.handleChange} checked={this.state.healthHistory.haveDiabetes === "false" ? "checked" : ""} />
                                    <label className="form-check-label" htmlFor="haveDiabetes">
                                      <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.haveDiabetes ? "#ff0000" : "" }}>No</h6>
                                    </label>
                                  </div>
                                </td>
                                <td />
                              </tr>
                              <tr>
                                {/* <th scope="row">2</th> */}
                                <td className="bold-18">Have high blood pressure</td>
                                <td>
                                  <div className="form-check  mr-5">
                                    <input className="form-check-input" type="radio" name="takemedicationToTreatHighBloodPressure" id="takemedicationToTreatHighBloodPressure"  value={true} onChange={this.handleChange} checked={this.state.healthHistory.takemedicationToTreatHighBloodPressure === "true" ? "checked" : ""} />
                                    <label className="form-check-label" htmlFor="takemedicationToTreatHighBloodPressure">
                                      <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.takemedicationToTreatHighBloodPressure ? "#ff0000" : "" }}>Yes</h6>
                                    </label>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-check  mr-5">
                                    <input className="form-check-input" type="radio" name="takemedicationToTreatHighBloodPressure" id="takemedicationToTreatHighBloodPressure"  value={false} onChange={this.handleChange} checked={this.state.healthHistory.takemedicationToTreatHighBloodPressure === "false" ? "checked" : ""} />
                                    <label className="form-check-label" htmlFor="takemedicationToTreatHighBloodPressure">
                                      <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.takemedicationToTreatHighBloodPressure ? "#ff0000" : "" }}>No</h6>
                                    </label>
                                  </div>
                                </td>
                                <td />
                              </tr></tbody>
                          </table>
                        </div>
                        <h3 className="pad-l-2rem ">Are you currently seeing a doctor and diagnosed or treated for any of the following ailments</h3>
                          <div className="container d-flex  d-flex-inline mb-2 " style={{ paddingLeft: '30px' }}>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="diagnosedORTreatedForKidneyOrLiverProblem" id="diagnosedORTreatedForKidneyOrLiverProblem"  value={true} onChange={(e) => this.checkHandleChangeAddData(e, "diagnosedORTreatedForKidneyOrLiverProblem", "option")} checked={this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem ? this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem.value[0] === "true" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="exampleRadios1">
                                <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.diagnosedORTreatedForKidneyOrLiverProblem ? "#ff0000" : "" }}>  Yes</h6>
                              </label>
                            </div>
                            <div className="form-check pad-1-2rem mr-5">
                              <input className="form-check-input " type="radio" name="diagnosedORTreatedForKidneyOrLiverProblem" id="diagnosedORTreatedForKidneyOrLiverProblem"  value={false} onChange={(e) => this.checkHandleChangeAddData(e, "diagnosedORTreatedForKidneyOrLiverProblem", "option")} checked={this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem ? this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem.value[0] === "false" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="exampleRadios1">
                                <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.diagnosedORTreatedForKidneyOrLiverProblem ? "#ff0000" : "" }}>  No</h6>
                              </label>
                            </div>
                          </div>
                          {this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem ? this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem.value[0] === "true" ?
                            // <div className="pad-l-2rem mb-4">
                            //   <select name="problems" style={{ height: '57px', borderColor: this.state.problems ? "#ff0000" : "" }} onChange={(e) => this.handleChangeAddData(e, "diagnosedORTreatedForKidneyOrLiverProblem")} value={this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem.problems}>
                            //     <option value="">Select</option>
                            //     <option value="kidneyFailureInLast6Weeks">Kidney failure in the last 6 weeks</option>
                            //     <option value="kidneyInfectionInLast6Weeks">Kidney infection in the last 6 weeks</option>
                            //     <option value="kidneyStones">Kidney stones</option>
                            //     <option value="kidneyDialysis">Kidney dialysis</option>
                            //     <option value="polycysticKidneys">Polycystic kidneys</option>
                            //     <option value="liverCirrhosis">Liver cirrhosis</option>
                            //     <option value="liverFailureInLast6Weeks">Liver failure in the last 6 weeks</option>
                            //     <option value="eclampsia/pre-eclampsia">Eclampsia/pre-eclampsia (pregnancy-related hypertension which can ressult in liver or kidney danage)</option>
                            //     <option value="hivInfection">HIV infection (kidney and liver disease may be complicaations)</option>
                            //     <option value="notSure">I’m not sure</option>
                            //   </select>
                            // </div> 
                            <div className="pad-l-2rem mb-4">

                              <div className="form-check">
                                <input className="form-check-input" name="heart" value="heart" type="checkbox" onChange={(e) => this.checkHandleChange(e, "diagnosedORTreatedForKidneyOrLiverProblem", "problems", "heart")} defaultChecked={this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem.problems ? this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem.problems.find(x => x === "heart") === "heart" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Heart </h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" name="lungs" value="lungs" type="checkbox" onChange={(e) => this.checkHandleChange(e, "diagnosedORTreatedForKidneyOrLiverProblem", "problems", "lungs")} defaultChecked={this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem.problems ? this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem.problems.find(x => x === "lungs") === "lungs" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Lungs </h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" name="stomach" value="stomach" type="checkbox" onChange={(e) => this.checkHandleChange(e, "diagnosedORTreatedForKidneyOrLiverProblem", "problems", "stomach")} defaultChecked={this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem.problems ? this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem.problems.find(x => x === "stomach") === "stomach" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Stomach </h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" name="kidney" value="kidney" type="checkbox" onChange={(e) => this.checkHandleChange(e, "diagnosedORTreatedForKidneyOrLiverProblem", "problems", "kidney")} defaultChecked={this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem.problems ? this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem.problems.find(x => x === "kidney") === "kidney" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Kidney</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" name="liver" value="liver" type="checkbox" onChange={(e) => this.checkHandleChange(e, "diagnosedORTreatedForKidneyOrLiverProblem", "problems", "liver")} defaultChecked={this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem.problems ? this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem.problems.find(x => x === "liver") === "liver" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Liver</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" name="chronicInfection" value="chronicInfection" type="checkbox" onChange={(e) => this.checkHandleChange(e, "diagnosedORTreatedForKidneyOrLiverProblem", "problems", "chronicInfection")} defaultChecked={this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem.problems ? this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem.problems.find(x => x === "chronicInfection") === "chronicInfection" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Chronic Infection</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" name="Not sure" value="Not sure" type="checkbox" onChange={(e) => this.checkHandleChange(e, "diagnosedORTreatedForKidneyOrLiverProblem", "problems", "Not sure")} defaultChecked={this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem.problems ? this.state.healthHistory.diagnosedORTreatedForKidneyOrLiverProblem.problems.find(x => x === "Not sure") === "Not sure" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Not sure</h6>
                                </label>
                              </div>
                             
                            </div>

                            : '' : ''}
                        <div className="hh-ftab-4-sec container p-0" style={{ height: '11rem' }}>
                          <h2 className="pad-l-2rem pt-3 pb-3">Medications</h2>
                          {/* <p className="pad-l-2rem pt-0 pb-4 ">
                        We encourage you to share information about your current prescriptions.<br />
                        You can upload <a href="#" style={{ color: '#4f5be7' }}>your prescriptions here</a> 
                                           </p> */}
                          <p className="pad-l-2rem pt-0 pb-4 ">
                            We encourage you to share information about your current prescriptions.<br />
                            You can upload &nbsp;
                          <label style={{ color: '#4f5be7' }}>
                              <input type="file" multiple name="prescription" onChange={this.uploadImage} style={{ display: 'none' }} />
                              <a><u>your prescriptions here</u></a>
                            </label></p>
                        </div>
                        <div className="hh-ftab-5-sec container p-0">
                          <h3 className=" pad-l-2rem pt-2">Are you currently taking any medications?</h3>
                          <div className="container d-flex  d-flex-inline " style={{ paddingLeft: '30px' }}>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="takingAnyMedications" id="takingAnyMedications"  value={true} onChange={this.handleChangeMultiple} checked={this.state.healthHistory.takingAnyMedications ? this.state.healthHistory.takingAnyMedications.value === "true" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="takingAnyMedications">
                                <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.takingAnyMedications ? "#ff0000" : "" }}>Yes</h6>
                              </label>
                            </div>
                            <div className="form-check pad-1-2rem mr-5">
                              <input className="form-check-input " type="radio" name="takingAnyMedications" id="takingAnyMedications"  value={false} onChange={this.handleChangeMultiple} checked={this.state.healthHistory.takingAnyMedications ? this.state.healthHistory.takingAnyMedications.value === "false" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="takingAnyMedications">
                                <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.takingAnyMedications ? "#ff0000" : "" }}>No</h6>
                              </label>
                            </div>
                          </div>
                          {this.state.healthHistory.takingAnyMedications ? this.state.healthHistory.takingAnyMedications.value === "true" ?
                            <div>
                              <p className="pad-l-2rem " style={{ fontSize: '18px', fontWeight: 400 }}>Please list all current prescription medications.</p>
                              <div className="row pad-l-2rem ">
                                <div className="mr-5 input-group-prepend mb-3 ml-0 mr-2" style={{ border: 'solid 1px #ebebeb', width: '397px', height: '60px' }}>
                                  <input type="text" name="medicinesList" placeholder="start typing for medicine" className="form-control roboto-reg-16px" style={{ height: '57px', borderColor: this.state.medicinesListError ? "#ff0000" : "" }} aria-label="Text input with dropdown button" onChange={(e) => this.handleChangeAddData(e, "takingAnyMedications")} value={this.state.healthHistory.takingAnyMedications.medicinesList} />
                                </div>
                                {/* <label className=" btn-outline-primary pt-4 ml-5 btn-hover-white text-center rounded-pill roboto-bold-14px text-uppercase" style={{ width: '18.75rem' }}>upload your prescription <input type="file" multiple name="prescription" hidden id="customFile" onChange={this.uploadImage}/>                            </label> */}
                                {/* <div class="custom-file d-flex justify-content-center">
                                              <a class="btn btn-outline-primary btn-hover-white rounded-pill roboto-bold-14px text-uppercase onhover-white" style="max-width: 250px; height:50px;line-height:2.8; color:#4f5be7;" >upload your prescription<input type="file" class="custom-file-input" id="customFile" >
                                            </a>
                                          </div> */}
                              </div></div> : '' : ''}
                          {/* <h3 className="pad-l-2rem ">Have you had a liver transplant?</h3>
                          <div className="container d-flex  d-flex-inline mb-2 " style={{ paddingLeft: '30px' }}>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="hadLiverTransplant" id="hadLiverTransplant"  value={true} onChange={this.handleChange} checked={this.state.healthHistory.hadLiverTransplant === "true" ? "checked" : ""} />
                              <label className="form-check-label" htmlFor="exampleRadios1">
                                <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.hadLiverTransplant ? "#ff0000" : "" }}>Yes</h6>
                              </label>
                            </div>
                            <div className="form-check pad-1-2rem mr-5">
                              <input className="form-check-input " type="radio" name="hadLiverTransplant" id="hadLiverTransplant"  value={false} onChange={this.handleChange} checked={this.state.healthHistory.hadLiverTransplant === "false" ? "checked" : ""} />
                              <label className="form-check-label" htmlFor="exampleRadios1">
                                <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.hadLiverTransplant ? "#ff0000" : "" }}>No</h6>
                              </label>
                            </div>
                          </div> */}


                          {/* ----------------------------------------new section------------------------------------------ */}
                        </div>
                      </div>

                      <div>
                        <div className="container pad-l-2rem mt-2">
                          <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                        </div>
                        <div className="container d-flex justify-content-end">
                          <button id="ctnbtn1" type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-5" onClick={this.onContinueFirstPage} style={{ height: '3.75rem', width: '8.3rem' }}>Continue</button>
                        </div>
                      </div>

                    </div>

                      : <div> <div className="hh-ftab-6-sec container p-0 pt-4 " style={{ height: '7rem' }}>
                        <h2 className="pad-l-2rem pt-3 pb-3">Your cancer health history</h2>
                      </div>
                        <h3 className=" pad-l-2rem pt-2" id="check genetiicTestForHereditaryCancerRisk">Have you had (or do you currently have) cancer?</h3>
                        <div className="container d-flex  d-flex-inline  mb-2" style={{ paddingLeft: '30px' }}>
                          <div className="form-check  mr-5">
                            <input className="form-check-input" type="radio" name="hadCancer" id="hadCancer"  value={true} onChange={(e) => this.checkHandleChangeAddData(e, "hadCancer", "option")} checked={this.state.healthHistory.hadCancer ? this.state.healthHistory.hadCancer.value[0] === "true" ? "checked" : "" : ""} />
                            <label className="form-check-label" htmlFor="hadCancer">
                              <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.hadCancer ? "#ff0000" : "" }}>Yes</h6>
                            </label>
                          </div>
                          <div className="form-check pad-1-2rem mr-5">
                            <input className="form-check-input " type="radio" name="hadCancer" id="hadCancer"  value={false} onChange={(e) => this.checkHandleChangeAddData(e, "hadCancer", "option")} checked={this.state.healthHistory.hadCancer ? this.state.healthHistory.hadCancer.value[0] === "false" ? "checked" : "" : ""} />
                            <label className="form-check-label" htmlFor="hadCancer">
                              <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.hadCancer ? "#ff0000" : "" }}>No</h6>
                            </label>
                          </div>
                        </div>
                        {this.state.healthHistory.hadCancer ? this.state.healthHistory.hadCancer.value[0] === "true" ?

                          <div className="pad-l-2rem mb-4">
                            {/* <select name="cancerType" style={{ height: '57px', borderColor: this.state.hadCancerError ? "#ff0000" : "" }} onChange={(e) => this.handleChangeAddData(e, "hadCancer")} value={this.state.healthHistory.hadCancer.cancerType}>
                           <option value="">Select</option>
                           <option value="maleBreastCancer">Male breast cancer</option>
                           <option value="colonCancer">colon (coloteral) cancer</option>
                           <option value="melanoma">Melanoma</option>
                           <option value="thyroidCancer">Thyroid cancer</option>
                           <option value="kidneycancer">Kidney cancer</option>
                           <option value="prostateCancer">Prostate cancer</option>
                           <option value="hematologicalMalignancy">Hematological malignancy (for example, Leukemia, Lymphoma, or Multiple myeloma)</option>
                           <option value="others">Other Cancer</option>
                         </select> */}

                            <div className="pad-l-2rem mb-4">
                              <div className="form-check">
                                <input className="form-check-input" name="breastCancer" value="breastCancer" type="checkbox" onChange={(e) => this.checkHandleChange(e, "hadCancer", "cancerType", "breastCancer")} defaultChecked={this.state.healthHistory.hadCancer.cancerType ? this.state.healthHistory.hadCancer.cancerType.find(x => x === "breastCancer") === "breastCancer" ? "checked" : "" : ""} />
                                <label className="form-check-label " htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Breast cancer</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" name="colorectalCancer" value="colorectalCancer" type="checkbox" onChange={(e) => this.checkHandleChange(e, "hadCancer", "cancerType", "colorectalCancer")} defaultChecked={this.state.healthHistory.hadCancer.cancerType ? this.state.healthHistory.hadCancer.cancerType.find(x => x === "colorectalCancer") === "colorectalCancer" ? "checked" : "" : ""} />
                                <label className="form-check-label " htmlFor="defaultCheck1">
                                  <h6 className="reg-18">  Colorectal cancer</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" name="Melanoma" value="Melanoma" type="checkbox" onChange={(e) => this.checkHandleChange(e, "hadCancer", "cancerType", "Melanoma")} defaultChecked={this.state.healthHistory.hadCancer.cancerType ? this.state.healthHistory.hadCancer.cancerType.find(x => x === "Melanoma") === "Melanoma" ? "checked" : "" : ""} />
                                <label className="form-check-label " htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Melanoma (Skin cancer</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" name="ThyroidCancer" value="ThyroidCancer" type="checkbox" onChange={(e) => this.checkHandleChange(e, "hadCancer", "cancerType", "ThyroidCancer")} defaultChecked={this.state.healthHistory.hadCancer.cancerType ? this.state.healthHistory.hadCancer.cancerType.find(x => x === "ThyroidCancer") === "ThyroidCancer" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Thyroid cancer</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" name="KidneyCancer" value="KidneyCancer" type="checkbox" onChange={(e) => this.checkHandleChange(e, "hadCancer", "cancerType", "KidneyCancer")} defaultChecked={this.state.healthHistory.hadCancer.cancerType ? this.state.healthHistory.hadCancer.cancerType.find(x => x === "KidneyCancer") === "KidneyCancer" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Kidney cancer</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" name="lungcancer" value="lungcancer" type="checkbox" onChange={(e) => this.checkHandleChange(e, "hadCancer", "cancerType", "lungcancer")} defaultChecked={this.state.healthHistory.hadCancer.cancerType ? this.state.healthHistory.hadCancer.cancerType.find(x => x === "lungcancer") === "lungcancer" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Lung cancer</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" name="ProstateCancer" value="ProstateCancer" type="checkbox" onChange={(e) => this.checkHandleChange(e, "hadCancer", "cancerType", "ProstateCancer")} defaultChecked={this.state.healthHistory.hadCancer.cancerType ? this.state.healthHistory.hadCancer.cancerType.find(x => x === "ProstateCancer") === "ProstateCancer" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Prostate cancer</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" name="uterineCancer" value="uterineCancer" type="checkbox" onChange={(e) => this.checkHandleChange(e, "hadCancer", "cancerType", "uterineCancer")} defaultChecked={this.state.healthHistory.hadCancer.cancerType ? this.state.healthHistory.hadCancer.cancerType.find(x => x === "uterineCancer") === "uterineCancer" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Uterine cancer</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" name="stomachCancer" value="stomachCancer" type="checkbox" onChange={(e) => this.checkHandleChange(e, "hadCancer", "cancerType", "stomachCancer")} defaultChecked={this.state.healthHistory.hadCancer.cancerType ? this.state.healthHistory.hadCancer.cancerType.find(x => x === "stomachCancer") === "stomachCancer" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Stomach cancer</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" name="ovarianCancer" value="ovarianCancer" type="checkbox" onChange={(e) => this.checkHandleChange(e, "hadCancer", "cancerType", "ovarianCancer")} defaultChecked={this.state.healthHistory.hadCancer.cancerType ? this.state.healthHistory.hadCancer.cancerType.find(x => x === "ovarianCancer") === "ovarianCancer" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Ovarian cancer</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" name="brainTumors" value="brainTumors" type="checkbox" onChange={(e) => this.checkHandleChange(e, "hadCancer", "cancerType", "brainTumors")} defaultChecked={this.state.healthHistory.hadCancer.cancerType ? this.state.healthHistory.hadCancer.cancerType.find(x => x === "brainTumors") === "brainTumors" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Brain Tumors</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" name="HematologicalMalignancy" value="HematologicalMalignancy" type="checkbox" onChange={(e) => this.checkHandleChange(e, "hadCancer", "cancerType", "HematologicalMalignancy")} defaultChecked={this.state.healthHistory.hadCancer.cancerType ? this.state.healthHistory.hadCancer.cancerType.find(x => x === "HematologicalMalignancy") === "HematologicalMalignancy" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Hematological malignancy (for example, Leukemia, Lymphoma, or Multiple myeloma)</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input " name="OtherCancer" value="OtherCancer" type="checkbox" onChange={(e) => this.checkHandleChange(e, "hadCancer", "cancerType", "OtherCancer")} defaultChecked={this.state.healthHistory.hadCancer.cancerType ? this.state.healthHistory.hadCancer.cancerType.find(x => x === "OtherCancer") === "OtherCancer" ? "checked" : "" : ""} />
                                <label className="form-check-label" htmlFor="defaultCheck1">
                                  <input className="form-check-label form-control" type="text" placeholder="Others" name="other" id="other" onChange={(e) => this.checkHandleChangeOtherCancer(e, "hadCancer", "other")} value={this.state.healthHistory.hadCancer.OtherCancerValue} />
                                </label>
                              </div>
                            </div>
                          </div> : '' : ''}


                        <h3 className=" pad-l-2rem pt-2 " >Have you ever had:</h3>
                        <div className="row mt-3">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <p className=" pad-l-2rem font-18px-bold " style={{ borderRightWidth: '44px', marginRight: '68px' }}>Have you had a genetiic test for hereditary cancer risk?</p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="row">
                            <div className="col-3">
                              <div className="form-check mr-5 ml-4">
                              <input className="form-check-input" type="radio" name="genetiicTestForHereditaryCancerRisk" id="genetiicTestForHereditaryCancerRisk"  value={true} onChange={this.handleChange} checked={this.state.healthHistory.genetiicTestForHereditaryCancerRisk === "true" ? "checked" : ""} />
                              <label className="form-check-label" htmlFor="exampleRadios1">
                                  <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.genetiicTestForHereditaryCancerRisk ? "#ff0000" : "" }}>  Yes</h6>
                                </label>
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="genetiicTestForHereditaryCancerRisk" id="genetiicTestForHereditaryCancerRisk"  value={false} onChange={this.handleChange} checked={this.state.healthHistory.genetiicTestForHereditaryCancerRisk === "false" ? "checked" : ""} />
                              <label className="form-check-label" htmlFor="exampleRadios1">
                                  <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.genetiicTestForHereditaryCancerRisk ? "#ff0000" : "" }}>  No</h6>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                       

                        <div className="container pad-l-2rem mt-2">
                          <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                        </div>
                        {/* heart section */}
                        <div className="hh-ftab-6-sec container p-0 pt-4 " style={{ height: '7rem' }} id="check fields">
                          <h2 className="pad-l-2rem pt-3 pb-3">Your heart health history</h2>
                        </div>
                        <h3 className=" pad-l-2rem pt-2 mt-2 pb-2">Have you ever been diagnosed with:</h3>
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <p className=" pad-l-2rem font-18px-bold " style={{ borderRightWidth: '44px', marginRight: '68px' }}>Familial hypercholesterolemia(FH)&nbsp;<span style={{ fontSize: '12px', color: 'gray' }}> Optional</span></p>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="row">
                              <div className="col-3">
                                <div className="form-check mr-5 ml-4">
                                  <input className="form-check-input" type="radio" name="familialHypercholesterolemia" id="familialHypercholesterolemia" value={true} onChange={this.handleChangeMultiple} checked={this.state.healthHistory.familialHypercholesterolemia ? this.state.healthHistory.familialHypercholesterolemia.value === "true" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="exampleRadios1">
                                    <h6 className="reg-18">  Yes</h6>
                                  </label>
                                </div>
                              </div>
                              <div className="col-3">
                                <div className="form-check  mr-5 ">
                                  <input className="form-check-input" type="radio" name="familialHypercholesterolemia" id="familialHypercholesterolemia" value={false} onChange={this.handleChangeMultiple} checked={this.state.healthHistory.familialHypercholesterolemia ? this.state.healthHistory.familialHypercholesterolemia.value === "false" ? "checked" : "" : ""}  />
                                  <label className="form-check-label" htmlFor="exampleRadios1">
                                    <h6 className="reg-18">  No</h6>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {this.state.healthHistory.familialHypercholesterolemia ? this.state.healthHistory.familialHypercholesterolemia.value === "true" ?
                          <div className="container pad-l-2rem mt-2">
                            <p className="reg-18">How old were you?</p>
                            <input className="form-control" name="age" type="text" style={{ height: '3.5rem', width: '14rem' }} placeholder="Estimates are okay" onChange={(e) => this.handleChangeAddData(e, "familialHypercholesterolemia")} value={this.state.healthHistory.familialHypercholesterolemia.age} />
                            <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                          </div> : '' : ''}

                        <div className="row mt-2">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <p className=" pad-l-2rem font-18px-bold " style={{ borderRightWidth: '44px', marginRight: '68px' }}>Cardiomyopathy&nbsp;<span style={{ fontSize: '12px', color: 'gray' }}> Optional</span></p>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="row">
                              <div className="col-3">
                                <div className="form-check mr-5 ml-4">
                                  <input className="form-check-input" type="radio" name="healthHistoryCardiomyopathy" id="healthHistoryCardiomyopathy"  value={true} onChange={this.handleChangeMultiple} checked={this.state.healthHistory.healthHistoryCardiomyopathy ? this.state.healthHistory.healthHistoryCardiomyopathy.value === "true" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="exampleRadios1">
                                    <h6 className="reg-18">  Yes</h6>
                                  </label>
                                </div>
                              </div>
                              <div className="col-3">
                                <div className="form-check  mr-5">
                                  <input className="form-check-input" type="radio" name="healthHistoryCardiomyopathy" id="healthHistoryCardiomyopathy"  value={false} onChange={this.handleChangeMultiple} checked={this.state.healthHistory.healthHistoryCardiomyopathy ? this.state.healthHistory.healthHistoryCardiomyopathy.value === "false" ? "checked" : "" : ""}  />
                                  <label className="form-check-label" htmlFor="exampleRadios1">
                                    <h6 className="reg-18">  No</h6>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {this.state.healthHistory.healthHistoryCardiomyopathy ? this.state.healthHistory.healthHistoryCardiomyopathy.value === "true" ?
                          <div>
                            
                            <div className="container pad-l-2rem mt-2">
                              <p className="reg-18">How old were you?</p>
                              <input className="form-control" type="text" style={{ height: '3.5rem', width: '14rem' }} placeholder="Estimates are okay" name="age" type="text" style={{ height: '3.5rem', width: '14rem' }} placeholder="Estimates are okay" onChange={(e) => this.handleChangeAddData(e, "healthHistoryCardiomyopathy")} value={this.state.healthHistory.healthHistoryCardiomyopathy.age} />
                              {/* <hr class="mx-auto"style="height:1px;background-color: #cdcdcd;"> */}
                            </div>
                         
                          </div> : '' : ''}




                          <div className="row mt-2">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                          <p className=" pad-l-2rem font-18px-bold " style={{ borderRightWidth: '44px', marginRight: '68px' }}>Hereditary arrhythmia or conduction disorder&nbsp;<span style={{ fontSize: '12px', color: 'gray' }}> Optional</span></p>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="row">
                              <div className="col-3">
                                <div className="form-check mr-5 ml-4">
                                  <input className="form-check-input" type="radio" name="healthHistoryHereditaryArrhythmiaOrConductionDisorder" id="healthHistoryHereditaryArrhythmiaOrConductionDisorder"  value={true} onChange={this.handleChangeMultiple} checked={this.state.healthHistory.healthHistoryHereditaryArrhythmiaOrConductionDisorder ? this.state.healthHistory.healthHistoryHereditaryArrhythmiaOrConductionDisorder.value === "true" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="exampleRadios1">
                                    <h6 className="reg-18">  Yes</h6>
                                  </label>
                                </div>
                              </div>
                              <div className="col-3">
                                <div className="form-check  mr-5">
                                  <input className="form-check-input" type="radio" name="hereditaryArrhythmiaOrConductionDisorder" id="hereditaryArrhythmiaOrConductionDisorder"  value={false} onChange={this.handleChangeMultiple} checked={this.state.healthHistory.healthHistoryHereditaryArrhythmiaOrConductionDisorder ? this.state.healthHistory.healthHistoryHereditaryArrhythmiaOrConductionDisorder.value === "false" ? "checked" : "" : ""}  />
                                  <label className="form-check-label" htmlFor="exampleRadios1">
                                    <h6 className="reg-18">  No</h6>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {this.state.healthHistory.healthHistoryHereditaryArrhythmiaOrConductionDisorder ? this.state.healthHistory.healthHistoryHereditaryArrhythmiaOrConductionDisorder.value === "true" ?
                          <div>
                            
                            <div className="container pad-l-2rem mt-2">
                              <p className="reg-18">How old were you?</p>
                              <input className="form-control" type="text" style={{ height: '3.5rem', width: '14rem' }} placeholder="Estimates are okay" name="age" type="text" style={{ height: '3.5rem', width: '14rem' }} placeholder="Estimates are okay" onChange={(e) => this.handleChangeAddData(e, "healthHistoryHereditaryArrhythmiaOrConductionDisorder")} value={this.state.healthHistory.healthHistoryHereditaryArrhythmiaOrConductionDisorder.age} />
                              {/* <hr class="mx-auto"style="height:1px;background-color: #cdcdcd;"> */}
                            </div>
                         
                          </div> : '' : ''}



                          

                        <div className="container pad-l-2rem mt-2">
     

                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <p className="font-18px-bold " style={{ borderRightWidth: '44px', marginRight: '68px' }}>Arteriopathy/connective tissue disorder &nbsp;<span style={{ fontSize: '12px', color: 'gray' }}> Optional</span></p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <div className="row">
                                <div className="col-3">
                                  <div className="form-check mr-5 ml-3">
                                    <input className="form-check-input" type="radio" name="arteriopathy" id="arteriopathy" value={true} onChange={this.handleChange} checked={this.state.healthHistory.arteriopathy === "true" ? "checked" : ""} />
                                    <label className="form-check-label" htmlFor="exampleRadios1">
                                      <h6 className="reg-18">  Yes</h6>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="form-check  mr-5">
                                    <input className="form-check-input" type="radio" name="arteriopathy" id="arteriopathy" value={false} onChange={this.handleChange} checked={this.state.healthHistory.arteriopathy === "false" ? "checked" : ""}  />
                                    <label className="form-check-label" htmlFor="exampleRadios1">
                                      <h6 className="reg-18">  No</h6>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                    
                          </div>


                        </div>

                        <div className="row mt-3">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <p className=" pad-l-2rem font-18px-bold " style={{ borderRightWidth: '44px', marginRight: '68px' }}>Heart failure<span style={{ fontSize: '12px', color: 'gray' }}> Optional</span></p>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="row">
                              <div className="col-3">
                                <div className="form-check mr-5 ml-4">
                                  <input className="form-check-input" type="radio" name="heartFailure" id="heartFailure"  value={true} onChange={this.handleChangeMultiple} checked={this.state.healthHistory.heartFailure ? this.state.healthHistory.heartFailure.value === "true" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="exampleRadios1">
                                    <h6 className="reg-18">  Yes</h6>
                                  </label>
                                </div>
                              </div>
                              <div className="col-3">
                                <div className="form-check  mr-5">
                                  <input className="form-check-input" type="radio" name="heartFailure" id="heartFailure"  value={false} onChange={this.handleChangeMultiple} checked={this.state.healthHistory.heartFailure ? this.state.healthHistory.heartFailure.value === "false" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="exampleRadios1">
                                    <h6 className="reg-18">  No</h6>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {this.state.healthHistory.heartFailure ? this.state.healthHistory.heartFailure.value === "true" ?

                          <div>
                            <div className="container pad-l-2rem ">
                              <p className="reg-18">How old were you?</p>
                            </div>
                            <div className="container pad-l-2rem mt-2">
                              <input className="form-control" name="age" type="text" style={{ height: '3.5rem', width: '14rem', borderColor: this.state.heartFailure ? "#ff0000" : "" }} placeholder="Estimates are okay" onChange={(e) => this.handleChangeAddData(e, "heartFailure")} value={this.state.healthHistory.heartFailure.age} />
                              <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                            </div>
                          </div> : '' : ''}

                       
                        



                        <div className="row mt-3">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <p className=" pad-l-2rem font-18px-bold " style={{ borderRightWidth: '44px', marginRight: '68px' }}>Enlargement of an artery (aneurysm)<span style={{ fontSize: '12px', color: 'gray' }}> Optional</span></p>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="row">
                              <div className="col-3">
                                <div className="form-check mr-5 ml-4">
                                <input className="form-check-input" type="radio" name="enlargementOfArtery" id="enlargementOfArtery"  value={true} onChange={this.handleChange} checked={this.state.healthHistory.enlargementOfArtery === "true" ? "checked" : ""} />
                                <label className="form-check-label" htmlFor="exampleRadios1">
                                    <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.enlargementOfArtery ? "#ff0000" : "" }}>  Yes</h6>
                                  </label>
                                </div>
                              </div>
                              <div className="col-3">
                                <div className="form-check  mr-5">
                                <input className="form-check-input" type="radio" name="enlargementOfArtery" id="enlargementOfArtery"  value={false} onChange={this.handleChange} checked={this.state.healthHistory.enlargementOfArtery === "false" ? "checked" : ""}/>
                                <label className="form-check-label" htmlFor="exampleRadios1">
                                    <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.enlargementOfArtery ? "#ff0000" : "" }}>  No</h6>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* onChange={(e) => this.checkHandleChangeAddData(e, "enlargementOfArtery", "option")} checked={this.state.healthHistory.enlargementOfArtery ? this.state.healthHistory.enlargementOfArtery.value[0] === "false" ? "checked" : "" : ""}  */}
                        {/* {this.state.healthHistory.enlargementOfArtery ? this.state.healthHistory.enlargementOfArtery.value[0] === "true" ?
                          <div>
                            <div className="container pad-l-2rem ">
                              <p className="reg-18">How old were you?</p>
                            </div>
                            <div className="container pad-l-2rem mt-2">
                              <input className="form-control" name="age" type="text" style={{ height: '3.5rem', width: '14rem', borderColor: this.state.enlargementOfArteryAggError ? "#ff0000" : "" }} placeholder="Estimates are okay" onChange={(e) => this.checkHandleChangeAgeAddData(e, "enlargementOfArtery", "age")} value={this.state.healthHistory.enlargementOfArtery.age} />
                              <p className="roboto-reg-18px mt-2" style={{ color: this.state.aneursmLocatedError ? "#ff0000" : "" }}>Where was your aneurysm located?</p>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="aorta" value="aorta" id="aorta" onChange={(e) => this.checkHandleChange(e, "enlargementOfArtery", "aneursmLocated", "aorta")} defaultChecked={this.state.healthHistory.enlargementOfArtery.aneursmLocated ? this.state.healthHistory.enlargementOfArtery.aneursmLocated.find(x => x === "aorta") === "aorta" ? "checked" : "" : ""} />
                                <label className="form-check-label" htmlFor="aorta">
                                  <h6 className="reg-18">Aorta</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="carotid" value="carotid" id="carotid" onChange={(e) => this.checkHandleChange(e, "enlargementOfArtery", "aneursmLocated", "carotid")} defaultChecked={this.state.healthHistory.enlargementOfArtery.aneursmLocated ? this.state.healthHistory.enlargementOfArtery.aneursmLocated.find(x => x === "carotid") === "carotid" ? "checked" : "" : ""} />
                                <label className="form-check-label" htmlFor="carotid">
                                  <h6 className="reg-18">Carotid</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="other" value="other" id="other" onChange={(e) => this.checkHandleChange(e, "enlargementOfArtery", "aneursmLocated", "other")} defaultChecked={this.state.healthHistory.enlargementOfArtery.aneursmLocated ? this.state.healthHistory.enlargementOfArtery.aneursmLocated.find(x => x === "other") === "other" ? "checked" : "" : ""} />
                                <label className="form-check-label" htmlFor="other">
                                  <h6 className="reg-18">Other</h6>
                                </label>
                              </div>
                              <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                            </div>
                          </div> : '' : ''} */}
                          <div className="row mt-3">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                          <p className=" pad-l-2rem font-18px-bold " style={{ borderRightWidth: '44px', marginRight: '68px' }}>Tear of an artery (dissection)<span style={{ fontSize: '12px', color: 'gray' }}> Optional</span></p>
                        </div>                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="row">
                              <div className="col-3">
                                <div className="form-check mr-5 ml-4">
                            <input className="form-check-input" type="radio" name="tearOfAnArtery" id="tearOfAnArtery"  value={true} onChange={this.handleChange} checked={this.state.healthHistory.tearOfAnArtery === "true" ? "checked" : ""} />
                            <label className="form-check-label" htmlFor="tearOfAnArtery">
                              <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.tearOfAnArtery ? "#ff0000" : "" }}>Yes</h6>
                            </label>
                            </div>
                            </div>
                            <div className="col-3">
                            <div className="form-check  mr-5">
                            <input className="form-check-input" type="radio" name="tearOfAnArtery" id="tearOfAnArtery"  value={false} onChange={this.handleChange} checked={this.state.healthHistory.tearOfAnArtery === "false" ? "checked" : ""} />
                            <label className="form-check-label" htmlFor="tearOfAnArtery">
                              <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.tearOfAnArtery ? "#ff0000" : "" }}>No</h6>
                            </label>
                            </div>
                            </div>
                          </div>
                        </div>
                      </div>

                        {/* {this.state.healthHistory.tearOfAnArtery ? this.state.healthHistory.tearOfAnArtery.value[0] === "true" ?
                          <div>
                            <div className="container pad-l-2rem ">
                              <p className="reg-18" >How old were you?</p>
                            </div>
                            <div className="container pad-l-2rem mt-2">
                              <input className="form-control" name="age" type="text" style={{ height: '3.5rem', width: '14rem', borderColor: this.state.tearOfAnArteryAggError ? "#ff0000" : "" }} placeholder="Estimates are okay" onChange={(e) => this.checkHandleChangeAgeAddData(e, "tearOfAnArtery", "age")} value={this.state.healthHistory.tearOfAnArtery.age} />
                              <p className="roboto-reg-18px mt-2" style={{ color: this.state.tearOfAnArteryaneursmLocatedError ? "#ff0000" : "" }}>Where was your aneurysm located?</p>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="aorta" value="aorta" id="aorta" onChange={(e) => this.checkHandleChange(e, "tearOfAnArtery", "aneursmLocated", "aorta")} defaultChecked={this.state.healthHistory.tearOfAnArtery.aneursmLocated ? this.state.healthHistory.tearOfAnArtery.aneursmLocated.find(x => x === "aorta") === "aorta" ? "checked" : "" : ""} />
                                <label className="form-check-label" htmlFor="aorta">
                                  <h6 className="reg-18">Aorta</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="carotid" value="carotid" id="carotid" onChange={(e) => this.checkHandleChange(e, "tearOfAnArtery", "aneursmLocated", "carotid")} defaultChecked={this.state.healthHistory.tearOfAnArtery.aneursmLocated ? this.state.healthHistory.tearOfAnArtery.aneursmLocated.find(x => x === "carotid") === "carotid" ? "checked" : "" : ""} />
                                <label className="form-check-label" htmlFor="carotid">
                                  <h6 className="reg-18">Carotid</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="other" value="other" id="other" onChange={(e) => this.checkHandleChange(e, "tearOfAnArtery", "aneursmLocated", "other")} defaultChecked={this.state.healthHistory.tearOfAnArtery.aneursmLocated ? this.state.healthHistory.tearOfAnArtery.aneursmLocated.find(x => x === "other") === "other" ? "checked" : "" : ""} />
                                <label className="form-check-label" htmlFor="other">
                                  <h6 className="reg-18">Other</h6>
                                </label>
                              </div>
                              <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                            </div>
                          </div> : '' : ""} */}
                          <div className="row mt-3">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className=" pad-l-2rem font-18px-bold " style={{ borderRightWidth: '44px', marginRight: '68px' }}>Fainting or passing outafter exercise<span style={{ fontSize: '12px', color: 'gray' }}> Optional</span></p>
                      </div>  <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="row">
                        <div className="col-3">
                          <div className="form-check mr-5 ml-4">
                            <input className="form-check-input" type="radio" name="faintingOrPassingOutafterExercise" id="faintingOrPassingOutafterExercise"  value={true} onChange={this.handleChange} checked={this.state.healthHistory.faintingOrPassingOutafterExercise === "true" ? "checked" : ""} />
                            <label className="form-check-label" htmlFor="faintingOrPassingOutafterExercise">
                              <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.faintingOrPassingOutafterExercise ? "#ff0000" : "" }}>Yes</h6>
                            </label>
                            </div>
                            </div>
                            <div className="col-3">
                              <div className="form-check  mr-5">
                            <input className="form-check-input" type="radio" name="faintingOrPassingOutafterExercise" id="faintingOrPassingOutafterExercise"  value={false} onChange={this.handleChange} checked={this.state.healthHistory.faintingOrPassingOutafterExercise === "false" ? "checked" : ""} />
                            <label className="form-check-label" htmlFor="faintingOrPassingOutafterExercise">
                              <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.faintingOrPassingOutafterExercise ? "#ff0000" : "" }}>No</h6>
                            </label>
                            </div>
                            </div>
                          </div>
                        </div>
                      </div>
                        <div className="container pad-l-2rem mt-2">
                          <hr className=" pr-2 pl-2" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                        </div>
                        {/* <div className="d-flex ">
                          <p className=" pad-l-2rem font-18px-bold reg-18 " style={{ borderRightWidth: '44px', marginRight: '68px' }}>Irregular heartbeat (arrhythmia)</p>
                          <div className="form-check mr-5">
                            <input className="form-check-input" type="radio" name="irregularHeartbeat" id="irregularHeartbeat"  value={true} onChange={(e) => this.checkHandleChangeAddData(e, "irregularHeartbeat", "option")} checked={this.state.healthHistory.irregularHeartbeat ? this.state.healthHistory.irregularHeartbeat.value[0] === "true" ? "checked" : "" : ""} />
                            <label className="form-check-label" htmlFor="irregularHeartbeat">
                              <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.irregularHeartbeat ? "#ff0000" : "" }}>Yes</h6>
                            </label>
                          </div>
                          <div className="form-check  mr-5">
                            <input className="form-check-input" type="radio" name="irregularHeartbeat" id="irregularHeartbeat"  value={false} onChange={(e) => this.checkHandleChangeAddData(e, "irregularHeartbeat", "option")} checked={this.state.healthHistory.irregularHeartbeat ? this.state.healthHistory.irregularHeartbeat.value[0] === "false" ? "checked" : "" : ""} />
                            <label className="form-check-label" htmlFor="irregularHeartbeat">
                              <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.irregularHeartbeat ? "#ff0000" : "" }}>No</h6>
                            </label>
                          </div>
                        </div>
                        {this.state.healthHistory.irregularHeartbeat ? this.state.healthHistory.irregularHeartbeat.value[0] === "true" ?

                          <div>
                            <div className="container pad-l-2rem ">
                              <p className="reg-18">How old were you?</p>
                            </div>
                            <div className="container pad-l-2rem mt-2">
                              <input className="form-control" name="age" type="text" style={{ height: '3.5rem', width: '14rem', borderColor: this.state.irregularHeartbeatAggError ? "#ff0000" : "" }} placeholder="Estimates are okay" onChange={(e) => this.checkHandleChangeAgeAddData(e, "irregularHeartbeat", "age")} value={this.state.healthHistory.irregularHeartbeat.age} />
                              <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                            </div>

                            <h3 className=" pad-l-2rem pt-2 font-18px-bold" style={{ color: this.state.irregularHeartbeatArrhythmia ? "#ff0000" : "" }}>What type of irregular heartbeat (arrhythmia) did your healtcare provider diagnose you with?</h3>
                            <div className="container " style={{ paddingLeft: '30px' }}>
                              <div className="mb-4">
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="afib" value="afib" id="afib" onChange={(e) => this.checkHandleChange(e, "irregularHeartbeat", "arrhythmia", "afib")} defaultChecked={this.state.healthHistory.irregularHeartbeat.arrhythmia ? this.state.healthHistory.irregularHeartbeat.arrhythmia.find(x => x === "afib") === "afib" ? "checked" : "" : ""} />
                                  <label className="form-check-label " htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Atrial fibrillation (afib)</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="LeftBundleBranchBlock" value="LeftBundleBranchBlock" id="LeftBundleBranchBlock" onChange={(e) => this.checkHandleChange(e, "irregularHeartbeat", "arrhythmia", "LeftBundleBranchBlock")} defaultChecked={this.state.healthHistory.irregularHeartbeat.arrhythmia ? this.state.healthHistory.irregularHeartbeat.arrhythmia.find(x => x === "LeftBundleBranchBlock") === "LeftBundleBranchBlock" ? "checked" : "" : ""} />
                                  <label className="form-check-label " htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Left bundle branch block</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="PeriventricularTachycardia " value="PeriventricularTachycardia" id="PeriventricularTachycardia" onChange={(e) => this.checkHandleChange(e, "irregularHeartbeat", "arrhythmia", "PeriventricularTachycardia")} defaultChecked={this.state.healthHistory.irregularHeartbeat.arrhythmia ? this.state.healthHistory.irregularHeartbeat.arrhythmia.find(x => x === "PeriventricularTachycardia") === "PeriventricularTachycardia" ? "checked" : "" : ""} />
                                  <label className="form-check-label " htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Periventricular tachycardia (PVT)</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="RightBundleBranchBlock" value="RightBundleBranchBlock" id="RightBundleBranchBlock" onChange={(e) => this.checkHandleChange(e, "irregularHeartbeat", "arrhythmia", "RightBundleBranchBlock")} defaultChecked={this.state.healthHistory.irregularHeartbeat.arrhythmia ? this.state.healthHistory.irregularHeartbeat.arrhythmia.find(x => x === "RightBundleBranchBlock") === "RightBundleBranchBlock" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Right bundle branch block</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="SinoventricularTachycardia" value="SinoventricularTachycardia" id="SinoventricularTachycardia" onChange={(e) => this.checkHandleChange(e, "irregularHeartbeat", "arrhythmia", "SinoventricularTachycardia")} defaultChecked={this.state.healthHistory.irregularHeartbeat.arrhythmia ? this.state.healthHistory.irregularHeartbeat.arrhythmia.find(x => x === "SinoventricularTachycardia") === "SinoventricularTachycardia" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Sinoventricular tachycardia (SVT)</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="SinoatrialBlock" value="SinoatrialBlock" id="SinoatrialBlock" onChange={(e) => this.checkHandleChange(e, "irregularHeartbeat", "arrhythmia", "SinoatrialBlock")} defaultChecked={this.state.healthHistory.irregularHeartbeat.arrhythmia ? this.state.healthHistory.irregularHeartbeat.arrhythmia.find(x => x === "SinoatrialBlock") === "SinoatrialBlock" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Sinoatrial block</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input " name="other" value="other" type="checkbox" onChange={(e) => this.checkHandleChange(e, "irregularHeartbeat", "arrhythmia", "other")} defaultChecked={this.state.healthHistory.irregularHeartbeat.arrhythmia ? this.state.healthHistory.irregularHeartbeat.arrhythmia.find(x => x === "other") === "other" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <input className="form-check-label form-control" type="text" name="other" id="other" onChange={(e) => this.checkHandleChangeAgeAddData2(e, "irregularHeartbeat", "other")} value={this.state.healthHistory.irregularHeartbeat.other} />
                                  </label>
                                </div>
                              </div>
                              <hr className="mx-auto pr-2 pl-2" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                            </div>
                          </div> : '' : ''} */}
                        {/* <h3 className=" pad-l-2rem pt-2 font-18px-bold">What type of irregular heartbeat (arrhythmia) did your healtcare provider diagnose you with?</h3>
                     <div className="container " style={{ paddingLeft: '30px' }}>
                       <div className="mb-4">
                         <select name="arrhythmia" style={{ height: '3.5rem', borderColor: this.state.healthHistoryFormErrors.arrhythmia ? "#ff0000" : "" }} onChange={this.handleChange} value={this.state.healthHistory.arrhythmia}>
                           <option value="">Select</option>
 
                           <option value="maleBreastCancer">Male breast cancer</option>
                           <option value="colonCancer">colon (coloteral) cancer</option>
                           <option value="melanoma">Melanoma</option>
                           <option value="thyroidCancer">Thyroid cancer</option>
                           <option value="kidneycancer">Kidney cancer</option>
                           <option value="prostateCancer">Prostate cancer</option>
                           <option value="hematologicalMalignancy">Hematological malignancy (for example, Leukemia, Lymphoma, or Multiple myeloma)</option>
                           <option value="others">Other Cancer</option>
                         </select>
                         
                       </div>
                       <hr className="mx-auto pr-2 pl-2" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                     </div> */}
                        <div className=" row ">
                          <div className="col-lg-6 col-md-6 col-sm-6">
                            <p className=" pad-l-2rem font-18px-bold " style={{ borderRightWidth: '44px', marginRight: '1rem' }}>Heart or vascular surgery <span style={{ fontSize: '12px', color: 'gray' }}> Optional</span></p>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className=" row ">
                              <div className=" col-3 ">
                                <div className="form-check mr-5 ml-4">
                                  <input className="form-check-input" type="radio"  name="heartVascularSurgery" id="heartVascularSurgery" value={true} onChange={(e) => this.checkHandleChangeAddData(e, "heartVascularSurgery", "option")} checked={this.state.healthHistory.heartVascularSurgery ? this.state.healthHistory.heartVascularSurgery.value[0] === "true" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="exampleRadios1">
                                    <h6 className="reg-18">Yes</h6>
                                  </label>
                                </div>
                              </div>
                              <div className=" col-3 ">
                                <div className="form-check  mr-5">
                                  <input className="form-check-input" type="radio" name="heartVascularSurgery" id="heartVascularSurgery" value={false} onChange={(e) => this.checkHandleChangeAddData(e, "heartVascularSurgery", "option")} checked={this.state.healthHistory.heartVascularSurgery ? this.state.healthHistory.heartVascularSurgery.value[0] === "false" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="exampleRadios1">
                                    <h6 className="reg-18">  No</h6>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                        {this.state.healthHistory.heartVascularSurgery ? this.state.healthHistory.heartVascularSurgery.value[0] === "true" ?
                          <div>
                            <h3 className=" pad-l-2rem pt-2 font-18px-bold">Type of surgery (check all that apply):</h3>
                            <div className="container " style={{ paddingLeft: '30px' }}>
                              <div className="mb-4">
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="bypassSurgery" value="bypassSurgery" onChange={(e) => this.checkHandleChange(e, "heartVascularSurgery", "typeOfSurgery", "bypassSurgery")} defaultChecked={this.state.healthHistory.heartVascularSurgery.typeOfSurgery ? this.state.healthHistory.heartVascularSurgery.typeOfSurgery.find(x => x === "bypassSurgery") === "bypassSurgery" ? "checked" : "" : ""} />
                                  <label className="form-check-label " htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Bypass surgery</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="stent" value="stent" onChange={(e) => this.checkHandleChange(e, "heartVascularSurgery", "typeOfSurgery", "stent")} defaultChecked={this.state.healthHistory.heartVascularSurgery.typeOfSurgery ? this.state.healthHistory.heartVascularSurgery.typeOfSurgery.find(x => x === "stent") === "stent" ? "checked" : "" : ""} />
                                  <label className="form-check-label " htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Stent</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="angioplasty" value="angioplasty" onChange={(e) => this.checkHandleChange(e, "heartVascularSurgery", "typeOfSurgery", "angioplasty")} defaultChecked={this.state.healthHistory.heartVascularSurgery.typeOfSurgery ? this.state.healthHistory.heartVascularSurgery.typeOfSurgery.find(x => x === "angioplasty") === "angioplasty" ? "checked" : "" : ""} />
                                  <label className="form-check-label " htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Angioplasty</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="pacemakerOrDefibrillator" value="pacemakerOrDefibrillator" onChange={(e) => this.checkHandleChange(e, "heartVascularSurgery", "typeOfSurgery", "pacemakerOrDefibrillator")} defaultChecked={this.state.healthHistory.heartVascularSurgery.typeOfSurgery ? this.state.healthHistory.heartVascularSurgery.typeOfSurgery.find(x => x === "pacemakerOrDefibrillator") === "pacemakerOrDefibrillator" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Surgery to implant a pacemaker or defibrillator</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="heartTransplant" value="heartTransplant" onChange={(e) => this.checkHandleChange(e, "heartVascularSurgery", "typeOfSurgery", "heartTransplant")} defaultChecked={this.state.healthHistory.heartVascularSurgery.typeOfSurgery ? this.state.healthHistory.heartVascularSurgery.typeOfSurgery.find(x => x === "heartTransplant") === "heartTransplant" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Heart transplant</h6>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          : '' : ''}
                          
                        

                        <div className="row mt-3"  id="check 2fields">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <p className=" pad-l-2rem font-18px-bold " style={{ borderRightWidth: '44px', marginRight: '68px' }}>Enlarged heart (cardiomegaly)<span style={{ fontSize: '12px', color: 'gray' }}> Optional</span></p>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="row">
                              <div className="col-3">
                                <div className="form-check mr-5 ml-4">
                                <input className="form-check-input" type="radio" name="enlargedHeart" id="enlargedHeart"  value={true} onChange={this.handleChangeMultiple} checked={this.state.healthHistory.enlargedHeart ? this.state.healthHistory.enlargedHeart.value === "true" ? "checked" : "" : ""} />
                                <label className="form-check-label" htmlFor="exampleRadios1">
                                    <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.enlargedHeart ? "#ff0000" : "" }}>  Yes</h6>
                                  </label>
                                </div>
                              </div>
                              <div className="col-3">
                                <div className="form-check  mr-5">
                                <input className="form-check-input" type="radio" name="enlargedHeart" id="enlargedHeart"  value={false} onChange={this.handleChangeMultiple} checked={this.state.healthHistory.enlargedHeart ? this.state.healthHistory.enlargedHeart.value === "false" ? "checked" : "" : ""} />
                                <label className="form-check-label" htmlFor="exampleRadios1">
                                    <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.enlargedHeart ? "#ff0000" : "" }}>  No</h6>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {this.state.healthHistory.enlargedHeart ? this.state.healthHistory.enlargedHeart.value === "true" ?
                          <div className="container pad-l-2rem mt-2">
                            <p className="reg-18">How old were you?</p>
                            <input className="form-control" name="age" type="text" style={{ height: '3.5rem', width: '14rem', borderColor: this.state.enlargedHeartAggError ? "#ff0000" : "" }} placeholder="Estimates are okay" onChange={(e) => this.handleChangeAddData(e, "enlargedHeart")} value={this.state.healthHistory.enlargedHeart.age} />
                            <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                          </div> : '' : ''}


                        {/* -------------------------------------------------have you ever been section----------------------------------- */}
                        <h3 className=" pad-l-2rem pt-2" style={{
                          color: '#2c333c', /* fontFamily: 'Roboto', */
                          fontSize: '30px', fontWeight: 700
                        }}>Have you ever experienced:</h3>
                       
                        <div className="row mt-3">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <p className=" pad-l-2rem font-18px-bold " style={{ borderRightWidth: '44px', marginRight: '68px' }}>Heart attack</p>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="row">
                              <div className="col-3">
                                <div className="form-check mr-5 ml-4">
                                <input className="form-check-input" type="radio" name="healthHistoryHeartAttack" id="healthHistoryHeartAttack"  value={true} onChange={this.handleChangeMultiple} checked={this.state.healthHistory.healthHistoryHeartAttack ? this.state.healthHistory.healthHistoryHeartAttack.value === "true" ? "checked" : "" : ""} />
                                <label className="form-check-label" htmlFor="exampleRadios1">
                                    <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.heartAttack ? "#ff0000" : "" }}>  Yes</h6>
                                  </label>
                                </div>
                              </div>
                              <div className="col-3">
                                <div className="form-check  mr-5">
                                <input className="form-check-input" type="radio" name="healthHistoryHeartAttack" id="healthHistoryHeartAttack"  value={false} onChange={this.handleChangeMultiple} checked={this.state.healthHistory.healthHistoryHeartAttack ? this.state.healthHistory.healthHistoryHeartAttack.value === "false" ? "checked" : "" : ""} />
                                <label className="form-check-label" htmlFor="exampleRadios1">
                                    <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.heartAttack ? "#ff0000" : "" }}>  No</h6>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        {this.state.healthHistory.healthHistoryHeartAttack ? this.state.healthHistory.healthHistoryHeartAttack.value === "true" ?
                          <div className="container pad-l-2rem mt-2">
                            <p className="pad-l-2rem- reg-18">How old were you?</p>
                            <input className="form-control" name="age" type="text" style={{ height: '3.5rem', width: '14rem', borderColor: this.state.heartAttackAggError ? "#ff0000" : "" }} placeholder="Estimates are okay" onChange={(e) => this.handleChangeAddData(e, "healthHistoryHeartAttack")} value={this.state.healthHistory.healthHistoryHeartAttack.age} />
                          </div> : '' : ''}
                        


                        <div className="row mt-3">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <p className=" pad-l-2rem font-18px-bold " style={{ borderRightWidth: '44px', marginRight: '68px' }}>Sudden cardiac arrest</p>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="row">
                              <div className="col-3">
                                <div className="form-check mr-5 ml-4">
                                <input className="form-check-input" type="radio" name="suddenCardiacArrest" id="suddenCardiacArrest"  value={true} onChange={this.handleChangeMultiple} checked={this.state.healthHistory.suddenCardiacArrest ? this.state.healthHistory.suddenCardiacArrest.value === "true" ? "checked" : "" : ""} />
                                <label className="form-check-label" htmlFor="exampleRadios1">
                                    <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.suddenCardiacArrest ? "#ff0000" : "" }}>  Yes</h6>
                                  </label>
                                </div>
                              </div>
                              <div className="col-3">
                                <div className="form-check  mr-5">
                                <input className="form-check-input" type="radio" name="suddenCardiacArrest" id="suddenCardiacArrest"  value={false} onChange={this.handleChangeMultiple} checked={this.state.healthHistory.suddenCardiacArrest ? this.state.healthHistory.suddenCardiacArrest.value === "false" ? "checked" : "" : ""} />
                                <label className="form-check-label" htmlFor="exampleRadios1">
                                    <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.suddenCardiacArrest ? "#ff0000" : "" }}>  No</h6>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        {this.state.healthHistory.suddenCardiacArrest ? this.state.healthHistory.suddenCardiacArrest.value === "true" ?
                          <div className="container pad-l-2rem mt-2">
                            <p className="reg-18">How old were you?</p>
                            <input className="form-control" name="age" type="text" style={{ height: '3.5rem', width: '14rem', borderColor: this.state.suddenCardiacArrestAggError ? "#ff0000" : "" }} placeholder="Estimates are okay" onChange={(e) => this.handleChangeAddData(e, "suddenCardiacArrest")} value={this.state.healthHistory.suddenCardiacArrest.age} />
                          </div> : '' : ''}
                        


                        <div className="row mt-3">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <p className=" pad-l-2rem font-18px-bold " style={{ borderRightWidth: '44px', marginRight: '68px' }}>Stroke</p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="row">
                            <div className="col-3">
                              <div className="form-check mr-5 ml-4">
                              <input className="form-check-input" type="radio" name="healthHistoryStroke" id="healthHistoryStroke"  value={true} onChange={this.handleChangeMultiple} checked={this.state.healthHistory.healthHistoryStroke ? this.state.healthHistory.healthHistoryStroke.value === "true" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="exampleRadios1">
                                  <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.healthHistoryStroke ? "#ff0000" : "" }}>  Yes</h6>
                                </label>
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="healthHistoryStroke" id="healthHistoryStroke"  value={false} onChange={this.handleChangeMultiple} checked={this.state.healthHistory.healthHistoryStroke ? this.state.healthHistory.healthHistoryStroke.value === "false" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="exampleRadios1">
                                  <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.healthHistoryStroke ? "#ff0000" : "" }}>  No</h6>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                        {this.state.healthHistory.healthHistoryStroke ? this.state.healthHistory.healthHistoryStroke.value === "true" ?
                          <div className="container pad-l-2rem mt-2">
                            <p className="reg-18">How old were you?</p>
                            <input className="form-control" name="age" type="text" style={{ height: '3.5rem', width: '14rem', borderColor: this.state.strokeAggError ? "#ff0000" : "" }} placeholder="Estimates are okay" onChange={(e) => this.handleChangeAddData(e, "healthHistoryStroke")} value={this.state.healthHistory.healthHistoryStroke.age} />
                            <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                          </div> : '' : ''}
                        {/* --------------------------another section have you ever been------------------------ */}
                        <h3 className=" pad-l-2rem pt-2" style={{ color: '#2c333c', fontSize: '30px', fontWeight: 700 }}>Have you ever been told that you have high cholesterol?</h3>
                        <div className=" d-flex pad-l-2rem ">
                          <div className="form-check mr-5 mt-2 pt-2">
                            <input className="form-check-input" type="radio" name="haveHighCholesterol" id="haveHighCholesterol" value="yes" onChange={this.handleChange} checked={this.state.healthHistory.haveHighCholesterol === "yes" ? "checked" : ""} />
                            <label className="form-check-label" htmlFor="haveHighCholesterol">
                              <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.haveHighCholesterol ? "#ff0000" : "" }}>Yes</h6>
                            </label>
                          </div>
                          <div className="form-check  mr-5 mt-2 pt-2">
                            <input className="form-check-input" type="radio" name="haveHighCholesterol" id="haveHighCholesterol" value="no"  onChange={this.handleChange} checked={this.state.healthHistory.haveHighCholesterol === "no" ? "checked" : ""} />
                            <label className="form-check-label" htmlFor="haveHighCholesterol">
                              <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.haveHighCholesterol ? "#ff0000" : "" }}>No</h6>
                            </label>
                          </div>
                          <div className="form-check  mr-5 mt-2 pt-2">
                            <input className="form-check-input" type="radio" name="haveHighCholesterol" id="haveHighCholesterol" value="not sure"  onChange={this.handleChange} checked={this.state.healthHistory.haveHighCholesterol === "not sure" ? "checked" : ""} />
                            <label className="form-check-label" htmlFor="haveHighCholesterol">
                              <h6 className="reg-18" style={{ color: this.state.healthHistoryFormErrors.haveHighCholesterol ? "#ff0000" : "" }}>I’m not sure</h6>
                            </label>
                          </div>
                        </div>
                        <div className="container pad-l-2rem mt-2">
                          <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                        </div>
                        {/* -----------------------------------another section------------------------------ */}
                       
                        
                        {/* ----------------------------------------------------------------------------last section---------------------------------- */}
                        <h3 className=" pad-l-2rem pt-2" style={{
                          color: '#2c333c', /* fontFamily: 'Roboto', */
                          fontSize: '30px', fontWeight: 700
                        }}>Have you ever had a genetic test for hereditary heart conditions?</h3>
                        {/* <p class="pad-l-2rem" style="font-weight:700;font-size:18px">Have you ever had a genetic test for hereditary heart conditions?</p> */}
                        <div className=" d-flex pad-l-2rem ">
                          <div className="form-check mr-5">
                            <input className="form-check-input" type="radio" name="healthHistoryhereditaryHeartConditions" id="healthHistoryhereditaryHeartConditions"  value={true} onChange={this.handleChangeMultiple} checked={this.state.healthHistory.healthHistoryhereditaryHeartConditions ? this.state.healthHistory.healthHistoryhereditaryHeartConditions.value === "true" ? "checked" : "" : ""} />
                            <label className="form-check-label" htmlFor="exampleRadios1" style={{ color: this.state.healthHistoryFormErrors.hereditaryHeartConditions ? "#ff0000" : "" }}>
                              Yes
                                 </label>
                          </div>
                          <div className="form-check  mr-5">
                            <input className="form-check-input" type="radio" name="healthHistoryhereditaryHeartConditions" id="healthHistoryhereditaryHeartConditions"  value={false} onChange={this.handleChangeMultiple} checked={this.state.healthHistory.healthHistoryhereditaryHeartConditions ? this.state.healthHistory.healthHistoryhereditaryHeartConditions.value === "false" ? "checked" : "" : ""} />
                            <label className="form-check-label" htmlFor="hereditaryHeartConditions" style={{ color: this.state.healthHistoryFormErrors.hereditaryHeartConditions ? "#ff0000" : "" }}>
                              No
                                 </label>
                          </div>
                        </div>
                        <div className="container " style={{ paddingLeft: '30px' }}>
                          {/* <div class="form-check">
         <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
         <label class="form-check-label" for="defaultCheck1">
         On your eyelids (xanthealasmas)
         </label>
         </div>
         <div class="form-check">
         <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
         <label class="form-check-label" for="defaultCheck1">
         In your tendons (xanthomas)
         </label>
         
         </div> */}
                        </div>
                        {this.state.healthHistory.healthHistoryhereditaryHeartConditions ? this.state.healthHistory.healthHistoryhereditaryHeartConditions.value === "true" ?
                          <div>
                            <p className="pad-l-2rem mt-2" style={{ fontWeight: 700, fontSize: '18px' }}>Who performed the genetic test?</p>
                            <div className="container pad-l-2rem ">
                              <div className="learn-more-form input-group-prepend mb-3">
                                <input type="text" className="form-control roboto-reg-16px" name="whoPerformedGeneticTest" id="whoPerformedGeneticTest" type="text" placeholder=" Name " style={{ height: '3.5rem', width: '16rem', borderColor: this.state.hereditaryHeartConditionswhoPerformedGeneticTestError ? "#ff0000" : "" }} onChange={(e) => this.handleChangeAddData(e, "healthHistoryhereditaryHeartConditions")} value={this.state.healthHistory.healthHistoryhereditaryHeartConditions.whoPerformedGeneticTest} />


                              </div>
                            </div>
                            <p className="pad-l-2rem" style={{ fontWeight: 700, fontSize: '18px' }}>Was a mutation identified?</p>
                            <div className="container d-flex pad-l-2rem ">
                              <div className="form-check  mr-5">
                                <input className="form-check-input" type="radio" name="mutationIdentifiedForHeridHeart" id="mutationIdentifiedForHeridHeart"  value="yes" onChange={(e) => this.handleChangeAddData(e, "healthHistoryhereditaryHeartConditions")} checked={this.state.healthHistory.healthHistoryhereditaryHeartConditions ? this.state.healthHistory.healthHistoryhereditaryHeartConditions.mutationIdentifiedForHeridHeart === "yes" ? "checked" : "" : ""} />
                                <label className="form-check-label" htmlFor="mutationIdentifiedForHeridHeart" style={{ color: this.state.mutationIdentifiedForHeridHeartError ? "#ff0000" : "" }}>
                                  Yes
                                 </label>
                              </div>
                              <div className="form-check  mr-5">
                                <input className="form-check-input" type="radio" name="mutationIdentifiedForHeridHeart" id="mutationIdentifiedForHeridHeart"  value="no" onChange={(e) => this.handleChangeAddData(e, "healthHistoryhereditaryHeartConditions")} checked={this.state.healthHistory.healthHistoryhereditaryHeartConditions ? this.state.healthHistory.healthHistoryhereditaryHeartConditions.mutationIdentifiedForHeridHeart === "no" ? "checked" : "" : ""} />
                                <label className="form-check-label" htmlFor="mutationIdentifiedForHeridHeart" style={{ color: this.state.mutationIdentifiedForHeridHeartError ? "#ff0000" : "" }}>
                                  no
                                 </label>
                              </div>
                              <div className="form-check  mr-5">
                                <input className="form-check-input" type="radio" name="mutationIdentifiedForHeridHeart" id="mutationIdentifiedForHeridHeart"  value="not sure" onChange={(e) => this.handleChangeAddData(e, "healthHistoryhereditaryHeartConditions")} checked={this.state.healthHistory.healthHistoryhereditaryHeartConditions ? this.state.healthHistory.healthHistoryhereditaryHeartConditions.mutationIdentifiedForHeridHeart === "not sure" ? "checked" : "" : ""} />
                                <label className="form-check-label" htmlFor="mutationIdentifiedForHeridHeart" style={{ color: this.state.mutationIdentifiedForHeridHeartError ? "#ff0000" : "" }}>
                                  I'm not sure
                                 </label>
                              </div>
                            </div>
                          </div> : '' : ''}
                        <div className="container d-flex pad-l-2rem pt-4">
                        </div>
                        <div className="container pad-l-2rem mt-2">
                          <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                        </div>

                        <div className="container d-flex justify-content-end">
                          <button type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-5" onClick={() => this.setState({ showing: true })} style={{ height: '3.75rem', width: '8.3rem' }}>Back</button>

                          <button type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-5" style={{ height: '3.75rem', width: '8.3rem' }} onClick={this.onContinue}>Continue</button>
                        </div></div>}

                  </div>

                  {/* --------------------------------------close of first section----------------------------------------------------------------------------------------------------- */}
                  {/*--------------------- ----------------------------------------------------start second  section start----------------------------------------------------------------------- */}

                  <div className={this.state.isActive === 2 ? "tab-pane active" : "tab-pane"} id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <div className="health-his-firsttab-first-sec container p-0">
                      <div className="row">
                        <div className="col-sm-6 align-self-center roboto-reg-16px ">
                          <p className="pad-l-2rem">Knowing your Family health is very important to assess your risks for certain diseases.</p><p>
                          </p><p className="pad-l-2rem">The first step to build your family tree. Dont't worry if you dont know all the answers.You can always take the help of your relatives.</p>
                        </div>
                        <div className="col-sm-6 mb-5 pl-3 d-flex justify-content-center">
                          <img className="img-fluid p1" src="./img/Health-History/Illustration-2.png" />
                        </div>
                      </div>
                      {/* <h3 className=" pad-l-2rem pt-2 roboto-bold-30px">Are you adopted or do you have no health information about one, or both sides of your biological family members?</h3>
                      <div className="container d-flex  d-flex-inline " style={{ paddingLeft: '30px' }}>
                        <div className="form-check  mr-5">
                          <input className="form-check-input" type="radio" name="yourBiologicalFamilyMembers" id="yourBiologicalFamilyMembers"  value={true} onChange={this.handleChangefamilyTree} checked={this.state.familyTree.yourBiologicalFamilyMembers === "true" ? "checked" : ""} />
                          <label className="form-check-label" htmlFor="yourBiologicalFamilyMembers">
                            <h6 className="reg-18" style={{ color: this.state.familyTreeFormErrors.yourBiologicalFamilyMembers ? "#ff0000" : "" }}>Yes</h6>
                          </label>
                        </div>
                        <div className="form-check pad-1-2rem mr-5">
                          <input className="form-check-input " type="radio" name="yourBiologicalFamilyMembers" id="yourBiologicalFamilyMembers"  value={false} onChange={this.handleChangefamilyTree} checked={this.state.familyTree.yourBiologicalFamilyMembers === "false" ? "checked" : ""} />
                          <label className="form-check-label" htmlFor="yourBiologicalFamilyMembers">
                            <h6 className="reg-18" style={{ color: this.state.familyTreeFormErrors.yourBiologicalFamilyMembers ? "#ff0000" : "" }}>No</h6>
                          </label>
                        </div>
                      </div> */}
                    </div>
                    {/* <div className="container">
                      <h6 className="roboto-reg-18px ml-5">As needed, indicate if you are unsure of family information, or leave ages empty.</h6>
                    </div> */}
                    {/* --------------------------question 2-------------------------*/}
                    <div className="container pl-5 d-flex  d-flex-inline " style={{}}>
                      <div className="form-check  mr-5">
                        <input className="form-check-input" type="radio" name="familyhisttabradio" onClick={() => this.setState({ showingFamilyTreee: true })} checked={this.state.showingFamilyTreee}/>
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">Fill now</h6>
                        </label>
                      </div>
                      <div className="form-check pad-1-2rem mr-5" id="Please check married relative">
                        <input className="form-check-input " type="radio" name="familyhisttabradio" id="familyhisttabradio"  onClick={() => this.setState({ showingFamilyTreee: false,familyTree:[] })}/>
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">Fill Later</h6>
                        </label>
                      </div>
                    </div>
                    <div className="container pad-l-2rem mt-2">
                      <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                    </div>

                    {this.state.showingFamilyTreee ?
                      <div>

                        <h2 className=" pad-l-2rem mb-2" id="Please check Do you have any children">Tell us about yourself:</h2>
                        <div className="container pad-l-2rem ">
                          <table className="table table-responsive">

                            <tbody>
                              <tr>
                                {/* <th scope="row">1</th> */}
                                <td className="bold-18" style={{ borderTop: 'none' }}>Are you married?</td>
                                <td style={{ borderTop: 'none' }}>
                                  <div className="form-check  mr-5">
                                  <input className="form-check-input" type="radio" name="married" id="married"  value="yes" onChange={this.handleChangeFamilyTree} checked={this.state.familyTree.married === "yes" ? "checked" : ""} />
                                    <label className="form-check-label" htmlFor="exampleRadios1">
                                      <h6  className="reg-18" style={{ color: this.state.familyTreeFormErrors.married ? "#ff0000" : "" }}>Yes</h6>
                                    </label>
                                  </div>
                                </td>
                                <td style={{ borderTop: 'none' }}>
                                  <div className="form-check  mr-5">
                                  <input className="form-check-input" type="radio" name="married" id="married"  value="no" onChange={this.handleChangeFamilyTree} checked={this.state.familyTree.married === "no" ? "checked" : ""} />
                                    <label className="form-check-label" htmlFor="exampleRadios1">
                                      <h6 className="reg-18" style={{ color: this.state.familyTreeFormErrors.married ? "#ff0000" : "" }}>No</h6>
                                    </label>
                                  </div>
                                </td>
                               
                                <td style={{ borderTop: 'none' }} />
                              </tr>
                              {this.state.familyTree.married==="yes"?
                              <tr>
                              {/* <th scope="row">2</th> */}
                              <td className="bold-18">Did you marry your relative (consanguineous)</td>
                              <td>
                                <div className="form-check  mr-5">
                                <input className="form-check-input" type="radio" name="marriedRelative" id="marriedRelative"  value="yes" onChange={this.handleChangeFamilyTree} checked={this.state.familyTree.marriedRelative === "yes" ? "checked" : ""} />
                                <label className="form-check-label" htmlFor="marriedRelative">
                                    <h6 className="reg-18" style={{ color: this.state.familyTreeFormErrors.marriedRelative ? "#ff0000" : "" }}>Yes</h6>
                                  </label>
                                </div>
                              </td>
                              <td>
                                <div className="form-check  mr-5">
                                  <input className="form-check-input" type="radio" name="marriedRelative" id="marriedRelative"  value="no" onChange={this.handleChangeFamilyTree} checked={this.state.familyTree.marriedRelative === "no" ? "checked" : ""} />
                                  <label className="form-check-label" htmlFor="marriedRelative">
                                    <h6 className="reg-18" style={{ color: this.state.familyTreeFormErrors.marriedRelative ? "#ff0000" : "" }} >No</h6>
                                  </label>
                                </div>
                              </td>
                             
                            </tr>:""}
                              
                              </tbody>
                          </table>
                        </div>
                        <div className="container pad-l-2rem mt-2">
                          <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                          {/* //padding */}
                        </div>
                        
                        <div className="conatiner ml-5" id="Please check Do you have any siblings">
                          <h3 className="roboto-bold-30px ml-2 ">Do you have any children?</h3>
                          <div className="container d-flex  d-flex-inline " >
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="doYouHaveAnyChildren" id="doYouHaveAnyChildren"  value={true} onChange={this.handleChangeMultiplefamilyTreeMany} checked={this.state.familyTree.doYouHaveAnyChildren ? this.state.familyTree.doYouHaveAnyChildren.value[0].value === "true" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="doYouHaveAnyChildren">
                                <h6 className="reg-18" style={{ color: this.state.familyTreeFormErrors.doYouHaveAnyChildren ? "#ff0000" : "" }}>Yes</h6>
                              </label>
                            </div>
                            <div className="form-check pad-1-2rem mr-5">
                              <input className="form-check-input " type="radio" name="doYouHaveAnyChildren" id="doYouHaveAnyChildren"  value={false} onChange={this.handleChangeMultiplefamilyTreeMany} checked={this.state.familyTree.doYouHaveAnyChildren ? this.state.familyTree.doYouHaveAnyChildren.value[0].value === "false" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="doYouHaveAnyChildren">
                                <h6 className="reg-18" style={{ color: this.state.familyTreeFormErrors.doYouHaveAnyChildren ? "#ff0000" : "" }}>No</h6>
                              </label>
                            </div>
                          </div></div>
                        {this.state.familyTree.doYouHaveAnyChildren ? this.state.familyTree.doYouHaveAnyChildren.value[0]['value'] === "true" ?
                          <div className="container ml-5">

                            <h6 className="roboto-bold-18px ">How many biological daughters ?</h6>

                            <div className="col-sm-6 col-lg-8 p-5">
                              <div className="d-flex inline">

                                {/* <img className="img-fluid pr-3" src="./TrueGene22_files/minus.png" onClick={this.DecreaseItem} /> */}
                                {this.decrement("doYouHaveAnyChildren", "biologicaldaughters")}
                                {this.state.show ? <h5>{this.state.biologicaldaughtersCount}</h5> : ''}&nbsp;&nbsp;&nbsp;&nbsp;

                              <img className="img-fluid pr-3" name="biologicaldaughters" src="./img/payment/plus.png" onClick={(e) => this.IncrementItem(e, "doYouHaveAnyChildren")} />

                                {/* { this.state.biologicaldaughtersCount==0 ?  <button disabled onClick={this.DecreaseItem}> decrease </button> :  <button onClick={this.IncrementItem}>increment </button> } */}
                              </div>
                            </div>
                            {[...Array(parseInt(biologicaldaughters))].map((e, i) => {

                              return <div className="row" key="1">
                                <div className="col-sm-12 col-md-12 col-lg-6 pr-0">
                                  <p className="roboto-bold-18px mr-5">{converter.toWordsOrdinal(i + 1)} daughter’s name (optional)</p>
                                  <div className="row container">
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px' }}>
                                      <input type="text" placeholder="First name" name="daughterFirstName" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.handleChangeAddDatafamilyTreeForManyFamilyMembers(e, "doYouHaveAnyChildren", "biologicaldaughters", i)} value={this.state.familyTree.doYouHaveAnyChildren ? this.state.familyTree.doYouHaveAnyChildren.biologicaldaughters[i + 1] ? this.state.familyTree.doYouHaveAnyChildren.biologicaldaughters[i + 1].daughterFirstName : '' : ''} />
                                    </div>
                                    <div className="input-group-prepend mb-3 pr-0" style={{ border: 'solid 1px #ebebeb', height: '60px' }}>
                                      <input type="text" placeholder="Last name" name="daughterLastName" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.handleChangeAddDatafamilyTreeForManyFamilyMembers(e, "doYouHaveAnyChildren", "biologicaldaughters", i)} value={this.state.familyTree.doYouHaveAnyChildren ? this.state.familyTree.doYouHaveAnyChildren.biologicaldaughters[i + 1] ? this.state.familyTree.doYouHaveAnyChildren.biologicaldaughters[i + 1].daughterLastName : '' : ''} />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 pl-0">
                                  <p className="roboto-bold-18px mr-5">{converter.toWordsOrdinal(i + 1)} daughter’s age:</p>
                                  <div className="row container">
                                    <div className="input-group-prepend mb-3 mr-4 ml-0 pl-0" style={{ border: 'solid 1px #ebebeb', height: '60px' }}>
                                      <input type="num" placeholder="Year" name="daughterAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', borderColor: this.state.familyTreeFormErrors['daughterAge' + i] !== undefined ? this.state.familyTreeFormErrors['daughterAge' + i] === "" ? "#ff0000" : "" : "" }} aria-label="Text input with dropdown button" onChange={(e) => this.handleChangeAddDatafamilyTreeForManyFamilyMembers(e, "doYouHaveAnyChildren", "biologicaldaughters", i)} value={this.state.familyTree.doYouHaveAnyChildren ? this.state.familyTree.doYouHaveAnyChildren.biologicaldaughters[i + 1] ? this.state.familyTree.doYouHaveAnyChildren.biologicaldaughters[i + 1].daughterAge : '' : ''} />
                                    </div>
                                    <div className="form-group learn-text-box mb-3 mr-4 ml-0" style={{ border: 'solid 1px #ebebeb' }}>
                                      <select className="form-control roboto-reg-16px" name="daughterAgeTill" id="daughterAgeTill" type="text" placeholder=" I am interested to learn about: " style={{ border: 'none', height: '60px', width: '10.5rem' }} onChange={(e) => this.handleChangeAddDatafamilyTreeForManyFamilyMembers(e, "doYouHaveAnyChildren", "biologicaldaughters", i)} value={this.state.familyTree.doYouHaveAnyChildren ? this.state.familyTree.doYouHaveAnyChildren.biologicaldaughters[i + 1] ? this.state.familyTree.doYouHaveAnyChildren.biologicaldaughters[i + 1].daughterAgeTill : '' : ''}>
                                        <option className="dropdown-item roboto-reg-16px" value="now">Now</option>
                                        <option className="dropdown-item roboto-reg-16px" value="timeOfDeath">Time of death</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            })}
                            <div className="conatiner">
                              <h6 className="roboto-bold-18px ">How many biological Sons?</h6>
                            </div>

                            <div className="col-sm-6 col-lg-8 p-5">
                              <div className="d-flex inline">

                                {this.decrement("doYouHaveAnyChildren", "biologicalsons")}
                                {this.state.show ? <h5>{this.state.biologicalsonsCount}</h5> : ''}&nbsp;&nbsp;&nbsp;&nbsp;

                              <img className="img-fluid pr-3" name="biologicalsons" src="./img/payment/plus.png" onClick={(e) => this.IncrementItem(e, "doYouHaveAnyChildren")} />

                              </div>
                            </div>
                            {[...Array(parseInt(biologicalsons))].map((e, i) => {
                              return <div className="row" key="2">
                                <div className="col-sm-12 col-md-12 col-lg-6 pr-0">
                                  <p className="roboto-bold-18px mr-5">{converter.toWordsOrdinal(i + 1)} Son's name (optional)</p>
                                  <div className="row container">
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px' }}>
                                      <input type="text" placeholder="First name" name="sonFirstName" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.handleChangeAddDatafamilyTreeForManyFamilyMembers(e, "doYouHaveAnyChildren", "biologicalsons", i)} value={this.state.familyTree.doYouHaveAnyChildren ? this.state.familyTree.doYouHaveAnyChildren.biologicalsons[i + 1] ? this.state.familyTree.doYouHaveAnyChildren.biologicalsons[i + 1].sonFirstName : '' : ''} />
                                    </div>
                                    <div className="input-group-prepend mb-3 pr-0" style={{ border: 'solid 1px #ebebeb', height: '60px' }}>
                                      <input type="text" placeholder="Last name" name="sonLastName" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.handleChangeAddDatafamilyTreeForManyFamilyMembers(e, "doYouHaveAnyChildren", "biologicalsons", i)} value={this.state.familyTree.doYouHaveAnyChildren ? this.state.familyTree.doYouHaveAnyChildren.biologicalsons[i + 1] ? this.state.familyTree.doYouHaveAnyChildren.biologicalsons[i + 1].sonLastName : '' : ''} />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 pl-0">
                                  <p className="roboto-bold-18px mr-5">{converter.toWordsOrdinal(i + 1)} son’s age:</p>
                                  <div className="row container">
                                    <div className="input-group-prepend mb-3 mr-4 ml-0 pl-0" style={{ border: 'solid 1px #ebebeb', height: '60px' }}>
                                      <input type="text" placeholder="Year" name="sonAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', borderColor: this.state.familyTreeFormErrors['sonAge' + i] !== undefined ? this.state.familyTreeFormErrors['sonAge' + i] === "" ? "#ff0000" : "" : "" }} aria-label="Text input with dropdown button" onChange={(e) => this.handleChangeAddDatafamilyTreeForManyFamilyMembers(e, "doYouHaveAnyChildren", "biologicalsons", i)} value={this.state.familyTree.doYouHaveAnyChildren ? this.state.familyTree.doYouHaveAnyChildren.biologicalsons[i + 1] ? this.state.familyTree.doYouHaveAnyChildren.biologicalsons[i + 1].sonAge : '' : ''} />
                                    </div>
                                    <div className="form-group learn-text-box mb-3 mr-4 ml-0" style={{ border: 'solid 1px #ebebeb' }}>
                                      <select className="form-control roboto-reg-16px" name="sonAgeTill" id="exampleFormControlSelect1" type="text"  placeholder=" I am interested to learn about: " style={{ border: 'none', height: '60px', width: '10.5rem' }} onChange={(e) => this.handleChangeAddDatafamilyTreeForManyFamilyMembers(e, "doYouHaveAnyChildren", "biologicalsons", i)} value={this.state.familyTree.doYouHaveAnyChildren ? this.state.familyTree.doYouHaveAnyChildren.biologicalsons[i + 1] ? this.state.familyTree.doYouHaveAnyChildren.biologicalsons[i + 1].sonAgeTillNow : '' : ''}>
                                        <option className="dropdown-item roboto-reg-16px">Now</option>
                                        <option className="dropdown-item roboto-reg-16px">Time of death</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            })}

                          </div> : '' : ''}

                        <div className="container pad-l-2rem mt-2">
                          <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                          {/* //padding */}
                        </div>
                        <div className="container ml-5" id="family married">
                          <h3 className=" pt-2 roboto-bold-30px">Do you have any siblings?</h3>
                          <div className="d-flex  d-flex-inline ">
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="doYouHaveAnySiblings" id="doYouHaveAnySiblings"  value="true" onChange={this.handleChangeMultiplefamilyTreeMany} checked={this.state.familyTree.doYouHaveAnySiblings ? this.state.familyTree.doYouHaveAnySiblings.value[0].value === "true" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="doYouHaveAnySiblings">
                                <h6 className="reg-18" style={{ color: this.state.familyTreeFormErrors.doYouHaveAnySiblings ? "#ff0000" : "" }}>Yes</h6>
                              </label>
                            </div>
                            <div className="form-check mr-5">
                              <input className="form-check-input " type="radio" name="doYouHaveAnySiblings" id="doYouHaveAnySiblings"  value="false" onChange={this.handleChangeMultiplefamilyTreeMany} checked={this.state.familyTree.doYouHaveAnySiblings ? this.state.familyTree.doYouHaveAnySiblings.value[0].value === "false" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="doYouHaveAnySiblings">
                                <h6 className="reg-18" style={{ color: this.state.familyTreeFormErrors.doYouHaveAnySiblings ? "#ff0000" : "" }}>No</h6>
                              </label>
                            </div>
                            {/* <div className="form-check mr-5">
                              <input className="form-check-input " type="radio" name="doYouHaveAnySiblings" id="doYouHaveAnySiblings"  value="I'm not Sure" onChange={this.handleChangeMultiplefamilyTreeMany} checked={this.state.familyTree.doYouHaveAnySiblings ? this.state.familyTree.doYouHaveAnySiblings.value[0].value === "I'm not Sure" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="exampleRadios1">
                                <h6 className="reg-18">I'm not Sure</h6>
                              </label>
                            </div> */}
                          </div>
                          {this.state.familyTree.doYouHaveAnySiblings ? this.state.familyTree.doYouHaveAnySiblings.value[0]['value'] === "true" ?
                            <div>
                              <div className="conatiner">
                                <h6 className="roboto-bold-18px ">How many sisters do you have?</h6>
                              </div>

                              <div className="col-sm-6 col-lg-8 p-5">
                                <div className="d-flex inline">

                                  {this.decrement("doYouHaveAnySiblings", "sisters")}
                                  {this.state.show ? <h5>{this.state.sistersCount}</h5> : ''}&nbsp;&nbsp;&nbsp;&nbsp;

                              <img className="img-fluid pr-3" name="sisters" src="./img/payment/plus.png" onClick={(e) => this.IncrementItem(e, "doYouHaveAnySiblings")} />

                                </div>
                              </div>
                              {[...Array(parseInt(sisters))].map((e, i) => {

                                return <div className="row" key="3">
                                  <div className="col-sm-12 col-md-12 col-lg-6 pr-0">
                                    <p className="roboto-bold-18px mr-5"> {converter.toWordsOrdinal(i + 1)} sister’s name (optional)</p>
                                    <div className="row container">
                                      <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px' }}>
                                        <input type="text" placeholder="First name" name="sisterFirstName" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.handleChangeAddDatafamilyTreeForManyFamilyMembers(e, "doYouHaveAnySiblings", "sisters", i)} value={this.state.familyTree.doYouHaveAnySiblings ? this.state.familyTree.doYouHaveAnySiblings.sisters[i + 1] ? this.state.familyTree.doYouHaveAnySiblings.sisters[i + 1].sisterFirstName : '' : ''} />
                                      </div>
                                      <div className="input-group-prepend mb-3 pr-0" style={{ border: 'solid 1px #ebebeb', height: '60px' }}>
                                        <input type="text" placeholder="Last name" name="sisterLastName" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.handleChangeAddDatafamilyTreeForManyFamilyMembers(e, "doYouHaveAnySiblings", "sisters", i)} value={this.state.familyTree.doYouHaveAnySiblings ? this.state.familyTree.doYouHaveAnySiblings.sisters[i + 1] ? this.state.familyTree.doYouHaveAnySiblings.sisters[i + 1].sisterLastName : '' : ''} />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-12 col-md-12 col-lg-6 pl-0">
                                    <p className="roboto-bold-18px mr-5">{converter.toWordsOrdinal(i + 1)} sister’s age:</p>
                                    <div className="row container">
                                      <div className="input-group-prepend mb-3 mr-4 ml-0 pl-0" style={{ border: 'solid 1px #ebebeb', height: '60px' }}>
                                        <input type="text" placeholder="Year" name="sisterAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', borderColor: this.state.familyTreeFormErrors['sisterAge' + i] !== undefined ? this.state.familyTreeFormErrors['sisterAge' + i] === "" ? "#ff0000" : "" : "" }} aria-label="Text input with dropdown button" onChange={(e) => this.handleChangeAddDatafamilyTreeForManyFamilyMembers(e, "doYouHaveAnySiblings", "sisters", i)} value={this.state.familyTree.doYouHaveAnySiblings ? this.state.familyTree.doYouHaveAnySiblings.sisters[i + 1] ? this.state.familyTree.doYouHaveAnySiblings.sisters[i + 1].sisterAge : '' : ''} />
                                      </div>
                                      <div className="form-group learn-text-box mb-3  ml-0" style={{ border: 'solid 1px #ebebeb' }}>
                                        <select className="form-control roboto-reg-16px" name="sisterAgeTill" id="sisterAgeTill" type="text"  placeholder=" I am interested to learn about: " style={{ border: 'none', height: '60px', width: '10.5rem' }} onChange={(e) => this.handleChangeAddDatafamilyTreeForManyFamilyMembers(e, "doYouHaveAnySiblings", "sisters", i)} value={this.state.familyTree.doYouHaveAnySiblings ? this.state.familyTree.doYouHaveAnySiblings.sisters[i + 1] ? this.state.familyTree.doYouHaveAnySiblings.sisters[i + 1].sisterAgeTill : '' : ''}>
                                          <option className="dropdown-item roboto-reg-16px" value="now">Now</option>
                                          <option className="dropdown-item roboto-reg-16px" value="timeOfDeath">Time of death</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              })}
                            </div> : '' : ''}
                        </div>

                        {this.state.familyTree.doYouHaveAnySiblings ? this.state.familyTree.doYouHaveAnySiblings.value[0]['value'] === "true" ?

                          <div>
                            <div className="container pad-l-2rem mt-2">
                              <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                              {/* //padding */}
                            </div>
                            <div className="container ml-5">
                              <div className="conatiner">
                                {/* <h6 class="roboto-bold-18px ">How many full brothers do you have?</h6> */}
                                <h6 className="roboto-bold-18px ">How many brothers do you have?</h6>
                              </div>

                              <div className="col-sm-6 col-lg-8 p-5">
                                <div className="d-flex inline">

                                  {this.decrement("doYouHaveAnySiblings", "brothers")}
                                  {this.state.show ? <h5>{this.state.brothersCount}</h5> : ''}&nbsp;&nbsp;&nbsp;&nbsp;

                              <img className="img-fluid pr-3" name="brothers" src="./img/payment/plus.png" onClick={(e) => this.IncrementItem(e, "doYouHaveAnySiblings")} />

                                </div>
                              </div>
                              {[...Array(parseInt(brothers))].map((e, i) => {
                                return <div className="row" key="4">
                                  <div className="col-sm-12 col-md-12 col-lg-6 pr-0">
                                    <p className="roboto-bold-18px mr-5">{converter.toWordsOrdinal(i + 1)} brother’s name (optional)</p>
                                    <div className="row container">
                                      <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px' }}>
                                        <input type="text" placeholder="First name" name="brotherFirstName" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.handleChangeAddDatafamilyTreeForManyFamilyMembers(e, "doYouHaveAnySiblings", "brothers", i)} value={this.state.familyTree.doYouHaveAnySiblings ? this.state.familyTree.doYouHaveAnySiblings.brothers[i + 1] ? this.state.familyTree.doYouHaveAnySiblings.brothers[i + 1].brotherFirstName : '' : ''} />
                                      </div>
                                      <div className="input-group-prepend mb-3 pr-0" style={{ border: 'solid 1px #ebebeb', height: '60px' }}>
                                        <input type="text" placeholder="Last name" name="brotherLastName" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.handleChangeAddDatafamilyTreeForManyFamilyMembers(e, "doYouHaveAnySiblings", "brothers", i)} value={this.state.familyTree.doYouHaveAnySiblings ? this.state.familyTree.doYouHaveAnySiblings.brothers[i + 1] ? this.state.familyTree.doYouHaveAnySiblings.brothers[i + 1].brotherLastName : '' : ''} />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-12 col-md-12 col-lg-6 pl-0">
                                    <p className="roboto-bold-18px mr-5">{converter.toWordsOrdinal(i + 1)} brother’s age:</p>
                                    <div className="row container">
                                      <div className="input-group-prepend mb-3 mr-4 ml-0 pl-0" style={{ border: 'solid 1px #ebebeb', height: '60px' }}>
                                        <input type="text" placeholder="Year" name="brotherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', borderColor: this.state.familyTreeFormErrors['brotherAge' + i] !== undefined ? this.state.familyTreeFormErrors['brotherAge' + i] === "" ? "#ff0000" : "" : "" }} aria-label="Text input with dropdown button" onChange={(e) => this.handleChangeAddDatafamilyTreeForManyFamilyMembers(e, "doYouHaveAnySiblings", "brothers", i)} value={this.state.familyTree.doYouHaveAnySiblings ? this.state.familyTree.doYouHaveAnySiblings.brothers[i + 1] ? this.state.familyTree.doYouHaveAnySiblings.brothers[i + 1].brotherAge : '' : ''} />
                                      </div>
                                      <div className="form-group learn-text-box mb-3  ml-0" style={{ border: 'solid 1px #ebebeb' }}>
                                        <select className="form-control roboto-reg-16px" name="brotherAgeTill" id="brotherAgeTill" type="text"  placeholder=" I am interested to learn about: " style={{ border: 'none', height: '60px', width: '10.5rem' }} onChange={(e) => this.handleChangeAddDatafamilyTreeForManyFamilyMembers(e, "doYouHaveAnySiblings", "brothers", i)} value={this.state.familyTree.doYouHaveAnySiblings ? this.state.familyTree.doYouHaveAnySiblings.brothers[i + 1] ? this.state.familyTree.doYouHaveAnySiblings.brothers[i + 1].brotherAgeTill : '' : ''}>
                                          <option className="dropdown-item roboto-reg-16px" value="now">Now</option>
                                          <option className="dropdown-item roboto-reg-16px" value="timeOfDeath">Time of death</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              })}
                            </div>
                          </div> : '' : ''}

                        <div className="container pad-l-2rem mt-2">
                          <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                          {/* //padding */}
                        </div>


                        <div className="conatiner ml-5">
                          <h3 className="roboto-bold-30px ml-2 ">Was your parents marriage consanguineous(married within relatives)?</h3>
                          <div className="container d-flex  d-flex-inline " >
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="parentsMarriedWithinRelative" id="parentsMarriedWithinRelative"  value={true} onChange={this.handleChangeFamilyTree} checked={this.state.familyTree.parentsMarriedWithinRelative === "true" ? "checked" : ""} />
                              <label className="form-check-label" htmlFor="parentsMarriedWithinRelative">
                                <h6 className="reg-18" style={{ color: this.state.familyTreeFormErrors.parentsMarriedWithinRelative ? "#ff0000" : "" }}>Yes</h6>
                              </label>
                            </div>
                            <div className="form-check pad-1-2rem mr-5">
                              <input className="form-check-input " type="radio" name="parentsMarriedWithinRelative" id="parentsMarriedWithinRelative"  value={false} onChange={this.handleChangeFamilyTree} checked={this.state.familyTree.parentsMarriedWithinRelative === "false" ? "checked" : ""} />
                              <label className="form-check-label" htmlFor="parentsMarriedWithinRelative">
                                <h6 className="reg-18" style={{ color: this.state.familyTreeFormErrors.parentsMarriedWithinRelative ? "#ff0000" : "" }}>No</h6>
                              </label>
                            </div>
                          </div></div>


                        <div className="container ml-5">
                          <h2 className="roboto-bold-30px">Tell us about your parents and grandparents.</h2>
                          <p className="roboto-reg-18px">Estimates are okay.</p>
                          <div className="d-flex  d-flex-inline " style={{}}>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="parentsAndGrandparents" value="fillnow" onChange={this.handleChangeMultiplefamilyTree} checked={this.state.familyTree.parentsAndGrandparents ? this.state.familyTree.parentsAndGrandparents.value === "fillnow" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="exampleRadios1">
                                <h6 className="reg-18" style={{ color: this.state.familyTreeFormErrors.parentsAndGrandparents === "" ? "#ff0000" : "" }}>Fill now</h6>
                              </label>
                            </div>
                            <div className="form-check pad-1-2rem mr-5">
                              <input className="form-check-input " type="radio" name="parentsAndGrandparents" value="later" onChange={this.handleChangeMultiplefamilyTree} checked={this.state.familyTree.parentsAndGrandparents ? this.state.familyTree.parentsAndGrandparents.value === "later" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="exampleRadios1">
                                <h6 className="reg-18" style={{ color: this.state.familyTreeFormErrors.parentsAndGrandparents === "" ? "#ff0000" : "" }}>Fill Later</h6>

                              </label>
                            </div>
                          </div>

                        </div>


                        {this.state.familyTree.parentsAndGrandparents ? this.state.familyTree.parentsAndGrandparents.value === "fillnow" ?
                          <div>
                            <div className="container ml-5">
                              <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6 pr-0">
                                  <p className="roboto-bold-18px mr-5">Mother's name (optional):</p>
                                  <div className="row container">
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px' }}>
                                      <input type="text" placeholder="First name" name="motherFirstName" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.handleChangeAddDatafamilyTree(e, "parentsAndGrandparents")} value={this.state.familyTree.parentsAndGrandparents ? this.state.familyTree.parentsAndGrandparents.motherFirstName : ''} />
                                    </div>
                                    <div className="input-group-prepend mb-3 pr-0" style={{ border: 'solid 1px #ebebeb', height: '60px' }}>
                                      <input type="text" placeholder="Last name" name="motherLastName" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.handleChangeAddDatafamilyTree(e, "parentsAndGrandparents")} value={this.state.familyTree.parentsAndGrandparents ? this.state.familyTree.parentsAndGrandparents.motherLastName : ''} />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 pl-0">
                                  <p className="roboto-bold-18px mr-5">Mother's age:</p>
                                  <div className="row container">
                                    <div className="input-group-prepend mb-3 mr-4 ml-0 pl-0" style={{ border: 'solid 1px #ebebeb', height: '60px' }}>
                                      <input type="text" placeholder="Year" name="motherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', borderColor: this.state.familyTreeFormErrors.motherAge ? "#ff0000" : "" }} aria-label="Text input with dropdown button" onChange={(e) => this.handleChangeAddDatafamilyTree(e, "parentsAndGrandparents")} value={this.state.familyTree.parentsAndGrandparents ? this.state.familyTree.parentsAndGrandparents.motherAge : ''} />
                                    </div>
                                    <div className="form-group learn-text-box mb-3  ml-0" style={{ border: 'solid 1px #ebebeb' }}>
                                      <select className="form-control roboto-reg-16px" name="motherAgeTill" id="exampleFormControlSelect1" type="text"  placeholder=" I am interested to learn about: " style={{ border: 'none', height: '60px', width: '10.5rem' }} onChange={(e) => this.handleChangeAddDatafamilyTree(e, "parentsAndGrandparents")} value={this.state.familyTree.parentsAndGrandparents ? this.state.familyTree.parentsAndGrandparents.motherAgeTill : ''}>
                                        <option className="dropdown-item roboto-reg-16px">Now</option>
                                        <option className="dropdown-item roboto-reg-16px">Time of death</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="container pad-l-2rem mt-2">
                              <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                              {/* //padding */}
                            </div>
                            <div className="container ml-5">
                              <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6 pr-0">
                                  <p className="roboto-bold-18px mr-5">Father's name (optional):</p>
                                  <div className="row container">
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px' }}>
                                      <input type="text" placeholder="First name" name="fatherFirstName" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.handleChangeAddDatafamilyTree(e, "parentsAndGrandparents")} value={this.state.familyTree.parentsAndGrandparents ? this.state.familyTree.parentsAndGrandparents.fatherFirstName : ''} />
                                    </div>
                                    <div className="input-group-prepend mb-3 pr-0" style={{ border: 'solid 1px #ebebeb', height: '60px' }}>
                                      <input type="text" placeholder="Last name" name="fatherLastName" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.handleChangeAddDatafamilyTree(e, "parentsAndGrandparents")} value={this.state.familyTree.parentsAndGrandparents ? this.state.familyTree.parentsAndGrandparents.fatherLastName : ''} />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 pl-0">
                                  <p className="roboto-bold-18px mr-5">Father's age:</p>
                                  <div className="row container">
                                    <div className="input-group-prepend mb-3 mr-4 ml-0 pl-0" style={{ border: 'solid 1px #ebebeb', height: '60px' }}>
                                      <input type="text" placeholder="Year" name="fatherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', borderColor: this.state.familyTreeFormErrors.fatherAge ? "#ff0000" : "" }} aria-label="Text input with dropdown button" onChange={(e) => this.handleChangeAddDatafamilyTree(e, "parentsAndGrandparents")} value={this.state.familyTree.parentsAndGrandparents ? this.state.familyTree.parentsAndGrandparents.fatherAge : ''} />
                                    </div>
                                    <div className="form-group learn-text-box mb-3  ml-0" style={{ border: 'solid 1px #ebebeb' }}>
                                      <select className="form-control roboto-reg-16px" name="fatherAgeTill" id="fatherAgeTill" type="text"  placeholder=" I am interested to learn about: " style={{ border: 'none', height: '60px', width: '10.5rem' }} onChange={(e) => this.handleChangeAddDatafamilyTree(e, "parentsAndGrandparents")} value={this.state.familyTree.parentsAndGrandparents ? this.state.familyTree.parentsAndGrandparents.fatherAgeTill : ''}>
                                        <option className="dropdown-item roboto-reg-16px">Now</option>
                                        <option className="dropdown-item roboto-reg-16px">Time of death</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="container pad-l-2rem mt-2">
                              <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                              {/* //padding */}
                            </div>
                            <div className="container ml-5">
                              <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6 pr-0">
                                  <p className="roboto-bold-18px mr-5">Maternal Grandmother's name (optional):</p>
                                  <div className="row container">
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px' }}>
                                      <input type="text" placeholder="First name" name="maternamGrandMotherFirstName" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.handleChangeAddDatafamilyTree(e, "parentsAndGrandparents")} value={this.state.familyTree.parentsAndGrandparents ? this.state.familyTree.parentsAndGrandparents.maternamGrandMotherFirstName : ''} />
                                    </div>
                                    <div className="input-group-prepend mb-3 pr-0" style={{ border: 'solid 1px #ebebeb', height: '60px' }}>
                                      <input type="text" placeholder="Last name" name="maternamGrandMotherLastName" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.handleChangeAddDatafamilyTree(e, "parentsAndGrandparents")} value={this.state.familyTree.parentsAndGrandparents ? this.state.familyTree.parentsAndGrandparents.maternamGrandMotherLastName : ''} />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 pl-0">
                                  <p className="roboto-bold-18px mr-5">Maternal Grandmother's age:</p>
                                  <div className="row container">
                                    <div className="input-group-prepend mb-3 mr-4 ml-0 pl-0" style={{ border: 'solid 1px #ebebeb', height: '60px' }}>
                                      <input type="text" placeholder="Year" style={{ height: '57px', width: '16.5rem', borderColor: this.state.familyTreeFormErrors.maternamGrandMotherAge ? "#ff0000" : "" }} name="maternamGrandMotherAge" className="form-control roboto-reg-16px" aria-label="Text input with dropdown button" onChange={(e) => this.handleChangeAddDatafamilyTree(e, "parentsAndGrandparents")} value={this.state.familyTree.parentsAndGrandparents ? this.state.familyTree.parentsAndGrandparents.maternamGrandMotherAge : ''} />
                                    </div>
                                    <div className="form-group learn-text-box mb-3  ml-0" style={{ border: 'solid 1px #ebebeb' }}>
                                      <select className="form-control roboto-reg-16px" name="maternamGrandMotherAgeTill" id="maternamGrandMotherAgeTill" type="text"  placeholder=" I am interested to learn about: " style={{ border: 'none', height: '60px', width: '10.5rem' }} onChange={(e) => this.handleChangeAddDatafamilyTree(e, "parentsAndGrandparents")} value={this.state.familyTree.parentsAndGrandparents ? this.state.familyTree.parentsAndGrandparents.maternamGrandMotherAgeTill : ''}>
                                        <option className="dropdown-item roboto-reg-16px">Now</option>
                                        <option className="dropdown-item roboto-reg-16px">Time of death</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="container pad-l-2rem mt-2">
                              <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                              {/* //padding */}
                            </div>
                            <div className="container ml-5">
                              <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6 pr-0">
                                  <p className="roboto-bold-18px mr-5">Maternal Grandfather's name (optional):</p>
                                  <div className="row container">
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px' }}>
                                      <input type="text" placeholder="First name" name="maternamGrandFatherFirstName" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.handleChangeAddDatafamilyTree(e, "parentsAndGrandparents")} value={this.state.familyTree.parentsAndGrandparents ? this.state.familyTree.parentsAndGrandparents.maternamGrandFatherFirstName : ''} />
                                    </div>
                                    <div className="input-group-prepend mb-3 pr-0" style={{ border: 'solid 1px #ebebeb', height: '60px' }}>
                                      <input type="text" placeholder="Last name" name="maternamGrandFatherLastName" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.handleChangeAddDatafamilyTree(e, "parentsAndGrandparents")} value={this.state.familyTree.parentsAndGrandparents ? this.state.familyTree.parentsAndGrandparents.maternamGrandFatherLastName : ''} />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 pl-0">
                                  <p className="roboto-bold-18px mr-5">Maternal Grandfather's age:</p>
                                  <div className="row container">
                                    <div className="input-group-prepend mb-3 mr-4 ml-0 pl-0" style={{ border: 'solid 1px #ebebeb', height: '60px' }}>
                                      <input type="text" placeholder="Year" style={{ height: '57px', width: '16.5rem', borderColor: this.state.familyTreeFormErrors.maternamGrandFatherAge ? "#ff0000" : "" }} name="maternamGrandFatherAge" className="form-control roboto-reg-16px" aria-label="Text input with dropdown button" onChange={(e) => this.handleChangeAddDatafamilyTree(e, "parentsAndGrandparents")} value={this.state.familyTree.parentsAndGrandparents ? this.state.familyTree.parentsAndGrandparents.maternamGrandFatherAge : ''} />
                                    </div>
                                    <div className="form-group learn-text-box mb-3  ml-0" style={{ border: 'solid 1px #ebebeb' }}>
                                      <select className="form-control roboto-reg-16px" name="maternamGrandFatherAgeTill" id="maternamGrandFatherAgeTill" type="text"  placeholder=" I am interested to learn about: " style={{ border: 'none', height: '60px', width: '10.5rem' }} onChange={(e) => this.handleChangeAddDatafamilyTree(e, "parentsAndGrandparents")} value={this.state.familyTree.parentsAndGrandparents ? this.state.familyTree.parentsAndGrandparents.maternamGrandFatherAgeTill : ''}>
                                        <option className="dropdown-item roboto-reg-16px">Now</option>
                                        <option className="dropdown-item roboto-reg-16px">Time of death</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="container pad-l-2rem mt-2">
                              <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                              {/* //padding */}
                            </div>
                            <div className="container ml-5">
                              <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6 pr-0">
                                  <p className="roboto-bold-18px mr-5">Paternal Grandmother's name (optional):</p>
                                  <div className="row container">
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px' }}>
                                      <input type="text" placeholder="First name" name="paternamGrandMotherFirstName" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.handleChangeAddDatafamilyTree(e, "parentsAndGrandparents")} value={this.state.familyTree.parentsAndGrandparents ? this.state.familyTree.parentsAndGrandparents.paternamGrandMotherFirstName : ''} />
                                    </div>
                                    <div className="input-group-prepend mb-3 pr-0" style={{ border: 'solid 1px #ebebeb', height: '60px' }}>
                                      <input type="text" placeholder="Last name" name="paternamGrandMotherLastName" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.handleChangeAddDatafamilyTree(e, "parentsAndGrandparents")} value={this.state.familyTree.parentsAndGrandparents ? this.state.familyTree.parentsAndGrandparents.paternamGrandMotherLastName : ''} />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 pl-0">
                                  <p className="roboto-bold-18px mr-5">Paternal Grandmother's age:</p>
                                  <div className="row container">
                                    <div className="input-group-prepend mb-3 mr-4 ml-0 pl-0" style={{ border: 'solid 1px #ebebeb', height: '60px' }}>
                                      <input type="text" placeholder="Year" name="paternamGrandMotherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', borderColor: this.state.familyTreeFormErrors.paternamGrandMotherAge ? "#ff0000" : "" }} aria-label="Text input with dropdown button" onChange={(e) => this.handleChangeAddDatafamilyTree(e, "parentsAndGrandparents")} value={this.state.familyTree.parentsAndGrandparents ? this.state.familyTree.parentsAndGrandparents.paternamGrandMotherAge : ''} />
                                    </div>
                                    <div className="form-group learn-text-box mb-3  ml-0" style={{ border: 'solid 1px #ebebeb' }}>
                                      <select className="form-control roboto-reg-16px" name="paternamGrandMotherAgeTill" id="paternamGrandMotherAgeTill" type="text" placeholder=" I am interested to learn about: " style={{ border: 'none', height: '60px', width: '10.5rem' }} onChange={(e) => this.handleChangeAddDatafamilyTree(e, "parentsAndGrandparents")} value={this.state.familyTree.parentsAndGrandparents ? this.state.familyTree.parentsAndGrandparents.paternamGrandMotherAgeTill : ''}>
                                        <option className="dropdown-item roboto-reg-16px">Now</option>
                                        <option className="dropdown-item roboto-reg-16px">Time of death</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="container pad-l-2rem mt-2">
                              <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                              {/* //padding */}
                            </div>
                            <div className="container ml-5">
                              <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6 pr-0">
                                  <p className="roboto-bold-18px mr-5">Paternal Grandfather's name (optional):</p>
                                  <div className="row container">
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px' }}>
                                      <input type="text" placeholder="First name" name="paternalGrandFatherFirstName" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.handleChangeAddDatafamilyTree(e, "parentsAndGrandparents")} value={this.state.familyTree.parentsAndGrandparents ? this.state.familyTree.parentsAndGrandparents.paternalGrandFatherFirstName : ''} />
                                    </div>
                                    <div className="input-group-prepend mb-3 pr-0" style={{ border: 'solid 1px #ebebeb', height: '60px' }}>
                                      <input type="text" placeholder="Last name" name="paternalGrandFatherLastName" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.handleChangeAddDatafamilyTree(e, "parentsAndGrandparents")} value={this.state.familyTree.parentsAndGrandparents ? this.state.familyTree.parentsAndGrandparents.paternalGrandFatherLastName : ''} />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 pl-0">
                                  <p className="roboto-bold-18px mr-5">Paternal Grandfather's age:</p>
                                  <div className="row container">
                                    <div className="input-group-prepend mb-3 mr-4 ml-0 pl-0" style={{ border: 'solid 1px #ebebeb', height: '60px' }}>
                                      <input type="text" placeholder="Year" name="paternalGrandFatherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', borderColor: this.state.familyTreeFormErrors.paternalGrandFatherAge ? "#ff0000" : "" }} aria-label="Text input with dropdown button" onChange={(e) => this.handleChangeAddDatafamilyTree(e, "parentsAndGrandparents")} value={this.state.familyTree.parentsAndGrandparents ? this.state.familyTree.parentsAndGrandparents.paternalGrandFatherAge : ''} />
                                    </div>
                                    <div className="form-group learn-text-box mb-3  ml-0" style={{ border: 'solid 1px #ebebeb' }}>
                                      <select className="form-control roboto-reg-16px" name="paternalGrandFatherAgeTill" id="exampleFormControlSelect1" type="text" placeholder=" I am interested to learn about: " style={{ border: 'none', height: '60px', width: '10.5rem' }} onChange={(e) => this.handleChangeAddDatafamilyTree(e, "parentsAndGrandparents")} value={this.state.familyTree.parentsAndGrandparents ? this.state.familyTree.parentsAndGrandparents.paternalGrandFatherAgeTill : ''}>
                                        <option className="dropdown-item roboto-reg-16px">Now</option>
                                        <option className="dropdown-item roboto-reg-16px">Time of death</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> : "" : ""}


                        {/* <div className="container ml-5">
                      <h3 className=" pt-2 roboto-bold-30px">In what country were you born?</h3>
                      <p className="roboto-reg-18px">Option</p>
                      <select className="form-control roboto-reg-16px" id="inWhatCountryWereYouBorn" placeholder="#" name="inWhatCountryWereYouBorn" style={{ height: '57px', width: '16.5rem', borderColor: this.state.familyTreeFormErrors.inWhatCountryWereYouBorn ? "#ff0000" : "" }} onChange={this.handleChangefamilyTree} value={this.state.familyTree.inWhatCountryWereYouBorn ? this.state.familyTree.inWhatCountryWereYouBorn : ''}>
                        <option value="">Select</option>
                        <option value="Afghanistan">Afghanistan</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antartica">Antarctica</option>
                        <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bosnia and Herzegowina">Bosnia and Herzegowina</option>
                        <option value="Botswana">Botswana</option>
                        <option value="Bouvet Island">Bouvet Island</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                        <option value="Brunei Darussalam">Brunei Darussalam</option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">Central African Republic</option>
                        <option value="Chad">Chad</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">Christmas Island</option>
                        <option value="Cocos Islands">Cocos (Keeling) Islands</option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Congo">Congo, the Democratic Republic of the</option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cota D'Ivoire">Cote d'Ivoire</option>
                        <option value="Croatia">Croatia (Hrvatska)</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">Dominican Republic</option>
                        <option value="East Timor">East Timor</option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands">Falkland Islands (Malvinas)</option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="France Metropolitan">France, Metropolitan</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">French Polynesia</option>
                        <option value="French Southern Territories">French Southern Territories</option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Heard and McDonald Islands">Heard and Mc Donald Islands</option>
                        <option value="Holy See">Holy See (Vatican City State)</option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="India">India</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="Iran">Iran (Islamic Republic of)</option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Democratic People's Republic of Korea">Korea, Democratic People's Republic of</option>
                        <option value="Korea">Korea, Republic of</option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Lao">Lao People's Democratic Republic</option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macau">Macau</option>
                        <option value="Macedonia">Macedonia, The Former Yugoslav Republic of</option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">Marshall Islands</option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Micronesia">Micronesia, Federated States of</option>
                        <option value="Moldova">Moldova, Republic of</option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Namibia">Namibia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherlands">Netherlands</option>
                        <option value="Netherlands Antilles">Netherlands Antilles</option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau">Palau</option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">Papua New Guinea</option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Philippines">Philippines</option>
                        <option value="Pitcairn">Pitcairn</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Reunion">Reunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russia">Russian Federation</option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                        <option value="Saint LUCIA">Saint LUCIA</option>
                        <option value="Saint Vincent">Saint Vincent and the Grenadines</option>
                        <option value="Samoa">Samoa</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Slovakia">Slovakia (Slovak Republic)</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="South Georgia">South Georgia and the South Sandwich Islands</option>
                        <option value="Span">Spain</option>
                        <option value="SriLanka">Sri Lanka</option>
                        <option value="St. Helena">St. Helena</option>
                        <option value="St. Pierre and Miguelon">St. Pierre and Miquelon</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Svalbard">Svalbard and Jan Mayen Islands</option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syria">Syrian Arab Republic</option>
                        <option value="Taiwan">Taiwan, Province of China</option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania">Tanzania, United Republic of</option>
                        <option value="Thailand">Thailand</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks and Caicos">Turks and Caicos Islands</option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Emirates">United Arab Emirates</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="United States">United States</option>
                        <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Vietnam">Viet Nam</option>
                        <option value="Virgin Islands (British)">Virgin Islands (British)</option>
                        <option value="Virgin Islands (U.S)">Virgin Islands (U.S.)</option>
                        <option value="Wallis and Futana Islands">Wallis and Futuna Islands</option>
                        <option value="Western Sahara">Western Sahara</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Yugoslavia">Yugoslavia</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                      </select>
                    </div> */}

                        <div className="container pad-l-2rem mt-2">
                          <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                          {/* //padding */}
                          <h3 className="  pad-l-2rem pt-2 roboto-bold-30px">Does your mother have any siblings?</h3>
                          <div className="container d-flex  d-flex-inline " style={{ paddingLeft: '30px' }}>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="doesYourMotherHaveAnySiblings" id="doesYourMotherHaveAnySiblings"  value="yes" onChange={this.handleChangeMultiplefamilyTree} checked={this.state.familyTree.doesYourMotherHaveAnySiblings ? this.state.familyTree.doesYourMotherHaveAnySiblings.value === "yes" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="doesYourMotherHaveAnySiblings">
                                <h6 className="reg-18" style={{ color: this.state.familyTreeFormErrors.doesYourMotherHaveAnySiblings ? "#ff0000" : "" }}>Yes</h6>
                              </label>
                            </div>
                            <div className="form-check pad-1-2rem mr-5 ">
                              <input className="form-check-input " type="radio" name="doesYourMotherHaveAnySiblings" id="doesYourMotherHaveAnySiblings"  value="no" onChange={this.handleChangeMultiplefamilyTree} checked={this.state.familyTree.doesYourMotherHaveAnySiblings ? this.state.familyTree.doesYourMotherHaveAnySiblings.value === "no" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="doesYourMotherHaveAnySiblings">
                                <h6 className="reg-18" style={{ color: this.state.familyTreeFormErrors.doesYourMotherHaveAnySiblings ? "#ff0000" : "" }}>No</h6>
                              </label>
                            </div>
                            {/* <div className="form-check pad-1-2rem mr-5 ">
                              <input className="form-check-input " type="radio" name="doesYourMotherHaveAnySiblings" id="doesYourMotherHaveAnySiblings"  value="may be" onChange={this.handleChangeMultiplefamilyTree} checked={this.state.familyTree.doesYourMotherHaveAnySiblings ? this.state.familyTree.doesYourMotherHaveAnySiblings.value === "may be" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="doesYourMotherHaveAnySiblings">
                                <h6 className="reg-18" style={{ color: this.state.familyTreeFormErrors.doesYourMotherHaveAnySiblings ? "#ff0000" : "" }}>May be</h6>
                              </label>
                            </div> */}
                          </div>
                          {this.state.familyTree.doesYourMotherHaveAnySiblings ? this.state.familyTree.doesYourMotherHaveAnySiblings.value === "yes" ?
                            <div>
                              <p className="  pad-l-2rem roboto-reg-18px">How many sisters does he have?</p>
                              <div className="container ml-3">
                                <select className="   form-control roboto-reg-16px" id="howManySisters" name="howManySisters" type="text" placeholder=" I am interested to learn about: " style={{ height: '3.5rem', width: '16rem', borderColor: this.state.doesYourMotherHaveAnySiblingssError ? "#ff0000" : "" }} onChange={(e) => this.handleChangeAddDatafamilyTree(e, "doesYourMotherHaveAnySiblings")} value={this.state.familyTree.doesYourMotherHaveAnySiblings.howManySisters} >
                                  <option value="">Select</option>
                                  <option className="dropdown-item roboto-reg-16px">0</option>
                                  <option className="dropdown-item roboto-reg-16px">1</option>
                                  <option className="dropdown-item roboto-reg-16px">2</option>
                                  <option className="dropdown-item roboto-reg-16px">3</option>
                                  <option className="dropdown-item roboto-reg-16px">4</option>
                                  <option className="dropdown-item roboto-reg-16px">5</option>
                                </select>
                              </div>
                              <p className=" pad-l-2rem roboto-reg-18px mt-1">How many brothers does he have?</p>
                              <div className="container ml-3">
                                <select className="   form-control roboto-reg-16px" id="howManyBrothers" name="howManyBrothers" type="text" placeholder=" I am interested to learn about: " style={{ height: '3.5rem', width: '16rem', borderColor: this.state.doesYourMotherHaveAnySiblingssError ? "#ff0000" : "" }} onChange={(e) => this.handleChangeAddDatafamilyTree(e, "doesYourMotherHaveAnySiblings")} value={this.state.familyTree.doesYourMotherHaveAnySiblings.howManyBrothers}>
                                  <option value="">Select</option>
                                  <option className="dropdown-item roboto-reg-16px">0</option>
                                  <option className="dropdown-item roboto-reg-16px">1</option>
                                  <option className="dropdown-item roboto-reg-16px">2</option>
                                  <option className="dropdown-item roboto-reg-16px">3</option>
                                  <option className="dropdown-item roboto-reg-16px">4</option>
                                  <option className="dropdown-item roboto-reg-16px">5</option>
                                </select>
                              </div>
                            </div> : '' : ''}
                          <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                          <h3 className="  pad-l-2rem pt-2 roboto-bold-30px">Does your father have any siblings?</h3>
                          <div className="container d-flex  d-flex-inline " style={{ paddingLeft: '30px' }}>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="doesYourFatherHaveAnySiblings" id="doesYourFatherHaveAnySiblings"  value="yes" onChange={this.handleChangeMultiplefamilyTree} checked={this.state.familyTree.doesYourFatherHaveAnySiblings ? this.state.familyTree.doesYourFatherHaveAnySiblings.value === "yes" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="doesYourFatherHaveAnySiblings">
                                <h6 className="reg-18" style={{ color: this.state.familyTreeFormErrors.doesYourFatherHaveAnySiblings ? "#ff0000" : "" }}>Yes</h6>
                              </label>
                            </div>
                            <div className="form-check pad-1-2rem mr-5 ">
                              <input className="form-check-input " type="radio" name="doesYourFatherHaveAnySiblings" id="doesYourFatherHaveAnySiblings"  value="no" onChange={this.handleChangeMultiplefamilyTree} checked={this.state.familyTree.doesYourFatherHaveAnySiblings ? this.state.familyTree.doesYourFatherHaveAnySiblings.value === "no" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="doesYourFatherHaveAnySiblings">
                                <h6 className="reg-18" style={{ color: this.state.familyTreeFormErrors.doesYourFatherHaveAnySiblings ? "#ff0000" : "" }}>No</h6>
                              </label>
                            </div>
                            {/* <div className="form-check pad-1-2rem mr-5 ">
                              <input className="form-check-input " type="radio" name="doesYourFatherHaveAnySiblings" id="doesYourFatherHaveAnySiblings"  value="may be" onChange={this.handleChangeMultiplefamilyTree} checked={this.state.familyTree.doesYourFatherHaveAnySiblings ? this.state.familyTree.doesYourFatherHaveAnySiblings.value === "may be" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="doesYourFatherHaveAnySiblings">
                                <h6 className="reg-18" style={{ color: this.state.familyTreeFormErrors.doesYourFatherHaveAnySiblings ? "#ff0000" : "" }}>May be</h6>
                              </label>
                            </div> */}
                          </div>
                          {this.state.familyTree.doesYourFatherHaveAnySiblings ? this.state.familyTree.doesYourFatherHaveAnySiblings.value === "yes" ?
                            <div>
                              <p className="  pad-l-2rem roboto-reg-18px">How many sisters does he have?</p>
                              <div className="container ml-3">
                                <select className="   form-control roboto-reg-16px" name="howManySisters" id="howManySisters" type="text"  placeholder=" I am interested to learn about: " style={{ height: '3.5rem', width: '16rem', borderColor: this.state.doesYourFatherHaveAnySiblingsError ? "#ff0000" : "" }} onChange={(e) => this.handleChangeAddDatafamilyTree(e, "doesYourFatherHaveAnySiblings")} value={this.state.familyTree.doesYourFatherHaveAnySiblings.howManySisters}>
                                  <option value="">Select</option>
                                  <option className="dropdown-item roboto-reg-16px">0</option>
                                  <option className="dropdown-item roboto-reg-16px">1</option>
                                  <option className="dropdown-item roboto-reg-16px">2</option>
                                  <option className="dropdown-item roboto-reg-16px">3</option>
                                  <option className="dropdown-item roboto-reg-16px">4</option>
                                  <option className="dropdown-item roboto-reg-16px">5</option>
                                </select>
                              </div>
                              <p className=" pad-l-2rem roboto-reg-18px mt-1">How many brothers does he have?</p>
                              <div className="container ml-3">
                                <select className="   form-control roboto-reg-16px" name="howManyBrothers" id="howManyBrothers" type="text"  placeholder=" I am interested to learn about: " style={{ height: '3.5rem', width: '16rem', borderColor: this.state.doesYourFatherHaveAnySiblingsError ? "#ff0000" : "" }} onChange={(e) => this.handleChangeAddDatafamilyTree(e, "doesYourFatherHaveAnySiblings")} value={this.state.familyTree.doesYourFatherHaveAnySiblings.howManyBrothers}>
                                  <option value="">Select</option>
                                  <option className="dropdown-item roboto-reg-16px">0</option>
                                  <option className="dropdown-item roboto-reg-16px">1</option>
                                  <option className="dropdown-item roboto-reg-16px">2</option>
                                  <option className="dropdown-item roboto-reg-16px">3</option>
                                  <option className="dropdown-item roboto-reg-16px">4</option>
                                  <option className="dropdown-item roboto-reg-16px">5</option>
                                </select>
                                <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                              </div>
                            </div> : '' : ''}
                        </div>
                        <div className="container d-flex justify-content-end">
                          <button type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mr-5 mb-5" style={{ height: '3.75rem', width: '8.3rem' }} onClick={this.submitFamilyTree}>Continue</button>
                        </div>

                      </div> : <div className="container d-flex justify-content-end">
                        <button type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mr-5 mb-5" style={{ height: '3.75rem', width: '8.3rem' }} onClick={this.FamilyTreeSkip}>Continue</button>
                      </div>}



                  </div>
                  {/* --------------------------------------close of second section----------------------------------------------------------------------------------------------------- */}
                  {/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
                  {/* ----------------------------------------------------start 3rd section start----------------------------------------------------------------------- */}
                  <div className={this.state.isActive === 3 ? "tab-pane active" : "tab-pane"} id="messages" role="tabpanel" aria-labelledby="messages-tab">
                    <div className="hh-ftab-22-sec container p-0">
                      <p className="pad-l-2rem roboto-bold-24px">Your Family Health History</p>
                      <div className="row">
                        <div className="col-sm-6 align-self-center roboto-reg-16px ">
                          <p className="pad-l-2rem">
                            The record of your family health history will enable us to better understand,
                            interpret and personalise your reports. If your are uncertain about some of the
                            questions please make your best estimation.
                            </p><p>
                          </p></div>
                        <div className="col-sm-6 mb-5 pl-3 d-flex justify-content-center">
                          <img className="img-fluid p1" src="./img/Health-History/Illustration-3.png" />
                        </div>
                      </div>
                      <div className="hh-ftab-22-sec  py-4" style={{ backgroundColor: '#eff7ff' }}>
                        <h2 className="pad-l-2rem roboto-black-36px" style={{ color: '#4f5be7' }}>Your family's cancer history</h2>
                      </div>

                      <div className="hh-ftab-22-sec ">
                        <h2 className="pad-l-2rem roboto-bold-30px pt-4">Did anyone in your family have cancer?</h2>
                        <div className=" d-flex pad-l-2rem ml-2">

                          <div className="form-check  mr-5">
                            <input className="form-check-input" type="radio" type="radio" name="inYourFamilyHadCancer" id="inYourFamilyHadCancer" value="yes" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "inYourFamilyHadCancer", "option")} checked={this.state.familyHealthHistory.inYourFamilyHadCancer ? this.state.familyHealthHistory.inYourFamilyHadCancer.value[0] === "yes" ? "checked" : "" : ""} />
                            <label className="form-check-label" htmlFor="exampleRadios1">
                              <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.inYourFamilyHadCancer ? "#ff0000" : "" }}>  Yes</h6>
                            </label>
                          </div>
                          <div className="form-check  mr-5">
                            <input className="form-check-input" type="radio" name="inYourFamilyHadCancer" id="inYourFamilyHadCancer" value="no" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "inYourFamilyHadCancer", "option")} checked={this.state.familyHealthHistory.inYourFamilyHadCancer ? this.state.familyHealthHistory.inYourFamilyHadCancer.value[0] === "no" ? "checked" : "" : ""} />
                            <label className="form-check-label" htmlFor="exampleRadios1">
                              <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.inYourFamilyHadCancer ? "#ff0000" : "" }}>  No</h6>
                            </label>
                          </div>
                          <div className="form-check  mr-5">
                            <input className="form-check-input" type="radio" name="inYourFamilyHadCancer" id="inYourFamilyHadCancer" value="may be" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "inYourFamilyHadCancer", "option")} checked={this.state.familyHealthHistory.inYourFamilyHadCancer ? this.state.familyHealthHistory.inYourFamilyHadCancer.value[0] === "may be" ? "checked" : "" : ""} />
                            <label className="form-check-label" htmlFor="exampleRadios1">
                              <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.inYourFamilyHadCancer ? "#ff0000" : "" }}>Maybe</h6>
                            </label>
                          </div>
                        </div>
                        {this.state.familyHealthHistory.inYourFamilyHadCancer ? this.state.familyHealthHistory.inYourFamilyHadCancer.value[0] === "yes" ?
                          <div>
                            <div className=" pad-l-2rem  ml-4" id="check mother cancer">
                              <input className="form-check-input" type="checkbox" name="mother" value="mother" id="mother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "member", "mother")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.member ? this.state.familyHealthHistory.inYourFamilyHadCancer.member.find(x => x === "mother") === "mother" ? "checked" : "" : ""} />
                              <label className="form-check-label mb-1" >
                                <h6 className="reg-18">Mother</h6>
                              </label>
                            </div>

                            {this.state.familyHealthHistory.inYourFamilyHadCancer ? this.state.familyHealthHistory.inYourFamilyHadCancer.member ? this.state.familyHealthHistory.inYourFamilyHadCancer.member.find(x => x === "mother") === "mother" ?

                              <div style={{ marginLeft: '3.50rem' }}>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="BreastCancer" value="BreastCancer" id="BreastCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "mother", "BreastCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.mother ? this.state.familyHealthHistory.inYourFamilyHadCancer.mother.find(x => x === "BreastCancer") === "BreastCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Breast cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="OvarianCancer" value="OvarianCancer" id="OvarianCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "mother", "OvarianCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.mother ? this.state.familyHealthHistory.inYourFamilyHadCancer.mother.find(x => x === "OvarianCancer") === "OvarianCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Ovarian cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="FallopianTubeCancer" value="FallopianTubeCancer" id="FallopianTubeCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "mother", "FallopianTubeCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.mother ? this.state.familyHealthHistory.inYourFamilyHadCancer.mother.find(x => x === "FallopianTubeCancer") === "FallopianTubeCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Fallopian tube cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="PrimaryPeritonealCancer" value="PrimaryPeritonealCancer" id="PrimaryPeritonealCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "mother", "PrimaryPeritonealCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.mother ? this.state.familyHealthHistory.inYourFamilyHadCancer.mother.find(x => x === "PrimaryPeritonealCancer") === "PrimaryPeritonealCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Primary peritoneal cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="ColonCancer" value="ColonCancer" id="ColonCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "mother", "ColonCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.mother ? this.state.familyHealthHistory.inYourFamilyHadCancer.mother.find(x => x === "ColonCancer") === "ColonCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Colon (colorectal) cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="UterineCancer" value="UterineCancer" id="UterineCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "mother", "UterineCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.mother ? this.state.familyHealthHistory.inYourFamilyHadCancer.mother.find(x => x === "UterineCancer") === "UterineCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Uterine (endometrial) cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="StomachCancer" value="StomachCancer" id="StomachCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "mother", "StomachCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.mother ? this.state.familyHealthHistory.inYourFamilyHadCancer.mother.find(x => x === "StomachCancer") === "StomachCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Stomach (gastric) cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="Melanoma" value="Melanoma" id="OvarianCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "mother", "Melanoma")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.mother ? this.state.familyHealthHistory.inYourFamilyHadCancer.mother.find(x => x === "Melanoma") === "Melanoma" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Melanoma</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="PancreaticCancer" value="PancreaticCancer" id="PancreaticCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "mother", "PancreaticCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.mother ? this.state.familyHealthHistory.inYourFamilyHadCancer.mother.find(x => x === "PancreaticCancer") === "PancreaticCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Pancreatic cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="ThyroidCancer" value="ThyroidCancer" id="ThyroidCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "mother", "ThyroidCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.mother ? this.state.familyHealthHistory.inYourFamilyHadCancer.mother.find(x => x === "ThyroidCancer") === "ThyroidCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Thyroid cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="KidneyCancer" value="KidneyCancer" id="KidneyCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "mother", "KidneyCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.mother ? this.state.familyHealthHistory.inYourFamilyHadCancer.mother.find(x => x === "KidneyCancer") === "KidneyCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Kidney cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="motherOtherCancer" value="motherOtherCancer" id="motherOtherCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "mother", "motherOtherCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.mother ? this.state.familyHealthHistory.inYourFamilyHadCancer.mother.find(x => x === "motherOtherCancer") === "motherOtherCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Other cancer</h6>
                                  </label>
                                </div>
                                {this.state.familyHealthHistory.inYourFamilyHadCancer ? this.state.familyHealthHistory.inYourFamilyHadCancer.mother ? this.state.familyHealthHistory.inYourFamilyHadCancer.mother.find(x => x === "motherOtherCancer") === "motherOtherCancer" ?
                                  <div>
                                    <div className=" pad-l-2rem roboto-reg-18px ml-lg-4">
                                      <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                        <input type="text" placeholder="Other cancer" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangemotherOtherCancer(e, "inYourFamilyHadCancer", "motherOtherCancer")} name="motherOtherCancer" value={this.state.familyHealthHistory.inYourFamilyHadCancer.motherOtherCancer} />
                                      </div>
                                    </div>
                                  </div>
                                  : "" : "" : ""}

                              </div>
                              : "" : "" : ""}


                            <div className=" pad-l-2rem  ml-4" id="check father cancer">
                              <input className="form-check-input" type="checkbox" name="Father" value="Father" id="Father" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "member", "Father")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.member ? this.state.familyHealthHistory.inYourFamilyHadCancer.member.find(x => x === "Father") === "Father" ? "checked" : "" : ""} />
                              <label className="form-check-label mb-1" >
                                <h6 className="reg-18">Father</h6>
                              </label>
                            </div>
                            {this.state.familyHealthHistory.inYourFamilyHadCancer ? this.state.familyHealthHistory.inYourFamilyHadCancer.member ? this.state.familyHealthHistory.inYourFamilyHadCancer.member.find(x => x === "Father") === "Father" ?

                              <div style={{ marginLeft: '3.50rem' }}>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="BreastCancer" value="BreastCancer" id="BreastCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "Father", "BreastCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.Father ? this.state.familyHealthHistory.inYourFamilyHadCancer.Father.find(x => x === "BreastCancer") === "BreastCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Breast cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="OvarianCancer" value="OvarianCancer" id="OvarianCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "Father", "OvarianCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.Father ? this.state.familyHealthHistory.inYourFamilyHadCancer.Father.find(x => x === "OvarianCancer") === "OvarianCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Ovarian cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="FallopianTubeCancer" value="FallopianTubeCancer" id="FallopianTubeCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "Father", "FallopianTubeCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.Father ? this.state.familyHealthHistory.inYourFamilyHadCancer.Father.find(x => x === "FallopianTubeCancer") === "FallopianTubeCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Fallopian tube cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="PrimaryPeritonealCancer" value="PrimaryPeritonealCancer" id="PrimaryPeritonealCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "Father", "PrimaryPeritonealCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.Father ? this.state.familyHealthHistory.inYourFamilyHadCancer.Father.find(x => x === "PrimaryPeritonealCancer") === "PrimaryPeritonealCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Primary peritoneal cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="ColonCancer" value="ColonCancer" id="ColonCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "Father", "ColonCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.Father ? this.state.familyHealthHistory.inYourFamilyHadCancer.Father.find(x => x === "ColonCancer") === "ColonCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Colon (colorectal) cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="UterineCancer" value="UterineCancer" id="UterineCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "Father", "UterineCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.Father ? this.state.familyHealthHistory.inYourFamilyHadCancer.Father.find(x => x === "UterineCancer") === "UterineCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Uterine (endometrial) cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="StomachCancer" value="StomachCancer" id="StomachCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "Father", "StomachCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.Father ? this.state.familyHealthHistory.inYourFamilyHadCancer.Father.find(x => x === "StomachCancer") === "StomachCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Stomach (gastric) cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="Melanoma" value="Melanoma" id="OvarianCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "Father", "Melanoma")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.Father ? this.state.familyHealthHistory.inYourFamilyHadCancer.Father.find(x => x === "Melanoma") === "Melanoma" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Melanoma</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="PancreaticCancer" value="PancreaticCancer" id="PancreaticCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "Father", "PancreaticCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.Father ? this.state.familyHealthHistory.inYourFamilyHadCancer.Father.find(x => x === "PancreaticCancer") === "PancreaticCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Pancreatic cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="ThyroidCancer" value="ThyroidCancer" id="ThyroidCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "Father", "ThyroidCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.Father ? this.state.familyHealthHistory.inYourFamilyHadCancer.Father.find(x => x === "ThyroidCancer") === "ThyroidCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Thyroid cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="KidneyCancer" value="KidneyCancer" id="KidneyCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "Father", "KidneyCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.Father ? this.state.familyHealthHistory.inYourFamilyHadCancer.Father.find(x => x === "KidneyCancer") === "KidneyCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Kidney cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="motherOtherCancer" value="FatherOtherCancer" id="FatherOtherCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "Father", "FatherOtherCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.Father ? this.state.familyHealthHistory.inYourFamilyHadCancer.Father.find(x => x === "FatherOtherCancer") === "FatherOtherCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Other cancer</h6>
                                  </label>
                                </div>
                                {this.state.familyHealthHistory.inYourFamilyHadCancer ? this.state.familyHealthHistory.inYourFamilyHadCancer.Father ? this.state.familyHealthHistory.inYourFamilyHadCancer.Father.find(x => x === "FatherOtherCancer") === "FatherOtherCancer" ?
                                  <div>
                                    <div className=" pad-l-2rem roboto-reg-18px ml-lg-4">
                                      <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                        <input type="text" placeholder="Other cancer" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFatherOtherCancer(e, "inYourFamilyHadCancer", "FatherOtherCancer")} name="FatherOtherCancer" value={this.state.familyHealthHistory.inYourFamilyHadCancer.FatherOtherCancer} />
                                      </div>
                                    </div>
                                  </div>
                                  : "" : "" : ""}

                              </div>
                              : "" : "" : ""}


                            <div className=" pad-l-2rem  ml-4" id="check Siblings cancer">
                              <input className="form-check-input" type="checkbox" name="Siblings" value="Siblings" id="Siblings" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "member", "Siblings")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.member ? this.state.familyHealthHistory.inYourFamilyHadCancer.member.find(x => x === "Siblings") === "Siblings" ? "checked" : "" : ""} />
                              <label className="form-check-label mb-1" >
                                <h6 className="reg-18">Siblings</h6>
                              </label>
                            </div>
                            {this.state.familyHealthHistory.inYourFamilyHadCancer ? this.state.familyHealthHistory.inYourFamilyHadCancer.member ? this.state.familyHealthHistory.inYourFamilyHadCancer.member.find(x => x === "Siblings") === "Siblings" ?

                              <div style={{ marginLeft: '3.50rem' }}>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="BreastCancer" value="BreastCancer" id="BreastCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "Siblings", "BreastCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.Siblings ? this.state.familyHealthHistory.inYourFamilyHadCancer.Siblings.find(x => x === "BreastCancer") === "BreastCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Breast cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="OvarianCancer" value="OvarianCancer" id="OvarianCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "Siblings", "OvarianCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.Siblings ? this.state.familyHealthHistory.inYourFamilyHadCancer.Siblings.find(x => x === "OvarianCancer") === "OvarianCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Ovarian cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="FallopianTubeCancer" value="FallopianTubeCancer" id="FallopianTubeCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "Siblings", "FallopianTubeCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.Siblings ? this.state.familyHealthHistory.inYourFamilyHadCancer.Siblings.find(x => x === "FallopianTubeCancer") === "FallopianTubeCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Fallopian tube cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="PrimaryPeritonealCancer" value="PrimaryPeritonealCancer" id="PrimaryPeritonealCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "Siblings", "PrimaryPeritonealCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.Siblings ? this.state.familyHealthHistory.inYourFamilyHadCancer.Siblings.find(x => x === "PrimaryPeritonealCancer") === "PrimaryPeritonealCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Primary peritoneal cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="ColonCancer" value="ColonCancer" id="ColonCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "Siblings", "ColonCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.Siblings ? this.state.familyHealthHistory.inYourFamilyHadCancer.Siblings.find(x => x === "ColonCancer") === "ColonCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Colon (colorectal) cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="UterineCancer" value="UterineCancer" id="UterineCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "Siblings", "UterineCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.Siblings ? this.state.familyHealthHistory.inYourFamilyHadCancer.Siblings.find(x => x === "UterineCancer") === "UterineCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Uterine (endometrial) cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="StomachCancer" value="StomachCancer" id="StomachCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "Siblings", "StomachCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.Siblings ? this.state.familyHealthHistory.inYourFamilyHadCancer.Siblings.find(x => x === "StomachCancer") === "StomachCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Stomach (gastric) cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="Melanoma" value="Melanoma" id="OvarianCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "Siblings", "Melanoma")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.Siblings ? this.state.familyHealthHistory.inYourFamilyHadCancer.Siblings.find(x => x === "Melanoma") === "Melanoma" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Melanoma</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="PancreaticCancer" value="PancreaticCancer" id="PancreaticCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "Siblings", "PancreaticCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.Siblings ? this.state.familyHealthHistory.inYourFamilyHadCancer.Siblings.find(x => x === "PancreaticCancer") === "PancreaticCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Pancreatic cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="ThyroidCancer" value="ThyroidCancer" id="ThyroidCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "Siblings", "ThyroidCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.Siblings ? this.state.familyHealthHistory.inYourFamilyHadCancer.Siblings.find(x => x === "ThyroidCancer") === "ThyroidCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Thyroid cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="KidneyCancer" value="KidneyCancer" id="KidneyCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "Siblings", "KidneyCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.Siblings ? this.state.familyHealthHistory.inYourFamilyHadCancer.Siblings.find(x => x === "KidneyCancer") === "KidneyCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Kidney cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="SiblingsOtherCancer" value="SiblingsOtherCancer" id="SiblingsOtherCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "Siblings", "SiblingsOtherCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.Siblings ? this.state.familyHealthHistory.inYourFamilyHadCancer.Siblings.find(x => x === "SiblingsOtherCancer") === "SiblingsOtherCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Other cancer</h6>
                                  </label>
                                </div>
                                {this.state.familyHealthHistory.inYourFamilyHadCancer ? this.state.familyHealthHistory.inYourFamilyHadCancer.Siblings ? this.state.familyHealthHistory.inYourFamilyHadCancer.Siblings.find(x => x === "SiblingsOtherCancer") === "SiblingsOtherCancer" ?
                                  <div>
                                    <div className=" pad-l-2rem roboto-reg-18px ml-lg-4">
                                      <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                        <input type="text" placeholder="Other cancer" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeSiblingsOtherCancer(e, "inYourFamilyHadCancer", "SiblingsOtherCancer")} name="SiblingsOtherCancer" value={this.state.familyHealthHistory.inYourFamilyHadCancer.SiblingsOtherCancer} />
                                      </div>
                                    </div>
                                  </div>
                                  : "" : "" : ""}

                              </div>
                              : "" : "" : ""}





                            <div className=" pad-l-2rem  ml-4" id="check MaternalGrandmother cancer">
                              <input className="form-check-input" type="checkbox" name="MaternalGrandmother" value="MaternalGrandmother" id="MaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "member", "MaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.member ? this.state.familyHealthHistory.inYourFamilyHadCancer.member.find(x => x === "MaternalGrandmother") === "MaternalGrandmother" ? "checked" : "" : ""} />
                              <label className="form-check-label mb-1" >
                                <h6 className="reg-18">MaternalGrandmother</h6>
                              </label>
                            </div>
                            {this.state.familyHealthHistory.inYourFamilyHadCancer ? this.state.familyHealthHistory.inYourFamilyHadCancer.member ? this.state.familyHealthHistory.inYourFamilyHadCancer.member.find(x => x === "MaternalGrandmother") === "MaternalGrandmother" ?

                              <div style={{ marginLeft: '3.50rem' }}>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="BreastCancer" value="BreastCancer" id="BreastCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "MaternalGrandmother", "BreastCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandmother ? this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandmother.find(x => x === "BreastCancer") === "BreastCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Breast cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="OvarianCancer" value="OvarianCancer" id="OvarianCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "MaternalGrandmother", "OvarianCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandmother ? this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandmother.find(x => x === "OvarianCancer") === "OvarianCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Ovarian cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="FallopianTubeCancer" value="FallopianTubeCancer" id="FallopianTubeCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "MaternalGrandmother", "FallopianTubeCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandmother ? this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandmother.find(x => x === "FallopianTubeCancer") === "FallopianTubeCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Fallopian tube cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="PrimaryPeritonealCancer" value="PrimaryPeritonealCancer" id="PrimaryPeritonealCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "MaternalGrandmother", "PrimaryPeritonealCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandmother ? this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandmother.find(x => x === "PrimaryPeritonealCancer") === "PrimaryPeritonealCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Primary peritoneal cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="ColonCancer" value="ColonCancer" id="ColonCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "MaternalGrandmother", "ColonCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandmother ? this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandmother.find(x => x === "ColonCancer") === "ColonCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Colon (colorectal) cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="UterineCancer" value="UterineCancer" id="UterineCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "MaternalGrandmother", "UterineCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandmother ? this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandmother.find(x => x === "UterineCancer") === "UterineCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Uterine (endometrial) cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="StomachCancer" value="StomachCancer" id="StomachCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "MaternalGrandmother", "StomachCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandmother ? this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandmother.find(x => x === "StomachCancer") === "StomachCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Stomach (gastric) cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="Melanoma" value="Melanoma" id="OvarianCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "MaternalGrandmother", "Melanoma")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandmother ? this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandmother.find(x => x === "Melanoma") === "Melanoma" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Melanoma</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="PancreaticCancer" value="PancreaticCancer" id="PancreaticCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "MaternalGrandmother", "PancreaticCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandmother ? this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandmother.find(x => x === "PancreaticCancer") === "PancreaticCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Pancreatic cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="ThyroidCancer" value="ThyroidCancer" id="ThyroidCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "MaternalGrandmother", "ThyroidCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandmother ? this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandmother.find(x => x === "ThyroidCancer") === "ThyroidCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Thyroid cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="KidneyCancer" value="KidneyCancer" id="KidneyCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "MaternalGrandmother", "KidneyCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandmother ? this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandmother.find(x => x === "KidneyCancer") === "KidneyCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Kidney cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="MaternalGrandmotherOtherCancer" value="MaternalGrandmotherOtherCancer" id="MaternalGrandmotherOtherCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "MaternalGrandmother", "MaternalGrandmotherOtherCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandmother ? this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandmother.find(x => x === "MaternalGrandmotherOtherCancer") === "MaternalGrandmotherOtherCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Other cancer</h6>
                                  </label>
                                </div>
                                {this.state.familyHealthHistory.inYourFamilyHadCancer ? this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandmother ? this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandmother.find(x => x === "MaternalGrandmotherOtherCancer") === "MaternalGrandmotherOtherCancer" ?
                                  <div>
                                    <div className=" pad-l-2rem roboto-reg-18px ml-lg-4">
                                      <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                        <input type="text" placeholder="Other cancer" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeMaternalGrandmotherOtherCancer(e, "inYourFamilyHadCancer", "MaternalGrandmotherOtherCancer")} name="MaternalGrandmotherOtherCancer" value={this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandmotherOtherCancer} />
                                      </div>
                                    </div>
                                  </div>
                                  : "" : "" : ""}

                              </div>
                              : "" : "" : ""}

                            <div className=" pad-l-2rem  ml-4"  id="check MaternalGrandfather cancer">
                              <input className="form-check-input" type="checkbox" name="MaternalGrandfather" value="MaternalGrandfather" id="MaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "member", "MaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.member ? this.state.familyHealthHistory.inYourFamilyHadCancer.member.find(x => x === "MaternalGrandfather") === "MaternalGrandfather" ? "checked" : "" : ""} />
                              <label className="form-check-label mb-1" >
                                <h6 className="reg-18">MaternalGrandfather</h6>
                              </label>
                            </div>
                            {this.state.familyHealthHistory.inYourFamilyHadCancer ? this.state.familyHealthHistory.inYourFamilyHadCancer.member ? this.state.familyHealthHistory.inYourFamilyHadCancer.member.find(x => x === "MaternalGrandfather") === "MaternalGrandfather" ?

                              <div style={{ marginLeft: '3.50rem' }}>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="BreastCancer" value="BreastCancer" id="BreastCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "MaternalGrandfather", "BreastCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandfather ? this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandfather.find(x => x === "BreastCancer") === "BreastCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Breast cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="OvarianCancer" value="OvarianCancer" id="OvarianCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "MaternalGrandfather", "OvarianCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandfather ? this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandfather.find(x => x === "OvarianCancer") === "OvarianCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Ovarian cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="FallopianTubeCancer" value="FallopianTubeCancer" id="FallopianTubeCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "MaternalGrandfather", "FallopianTubeCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandfather ? this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandfather.find(x => x === "FallopianTubeCancer") === "FallopianTubeCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Fallopian tube cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="PrimaryPeritonealCancer" value="PrimaryPeritonealCancer" id="PrimaryPeritonealCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "MaternalGrandfather", "PrimaryPeritonealCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandfather ? this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandfather.find(x => x === "PrimaryPeritonealCancer") === "PrimaryPeritonealCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Primary peritoneal cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="ColonCancer" value="ColonCancer" id="ColonCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "MaternalGrandfather", "ColonCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandfather ? this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandfather.find(x => x === "ColonCancer") === "ColonCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Colon (colorectal) cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="UterineCancer" value="UterineCancer" id="UterineCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "MaternalGrandfather", "UterineCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandfather ? this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandfather.find(x => x === "UterineCancer") === "UterineCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Uterine (endometrial) cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="StomachCancer" value="StomachCancer" id="StomachCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "MaternalGrandfather", "StomachCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandfather ? this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandfather.find(x => x === "StomachCancer") === "StomachCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Stomach (gastric) cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="Melanoma" value="Melanoma" id="OvarianCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "MaternalGrandfather", "Melanoma")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandfather ? this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandfather.find(x => x === "Melanoma") === "Melanoma" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Melanoma</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="PancreaticCancer" value="PancreaticCancer" id="PancreaticCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "MaternalGrandfather", "PancreaticCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandfather ? this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandfather.find(x => x === "PancreaticCancer") === "PancreaticCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Pancreatic cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="ThyroidCancer" value="ThyroidCancer" id="ThyroidCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "MaternalGrandfather", "ThyroidCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandfather ? this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandfather.find(x => x === "ThyroidCancer") === "ThyroidCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Thyroid cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="KidneyCancer" value="KidneyCancer" id="KidneyCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "MaternalGrandfather", "KidneyCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandfather ? this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandfather.find(x => x === "KidneyCancer") === "KidneyCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Kidney cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="motherOtherCancer" value="MaternalGrandfatherOtherCancer" id="MaternalGrandfatherOtherCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "MaternalGrandfather", "MaternalGrandfatherOtherCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandfather ? this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandfather.find(x => x === "MaternalGrandfatherOtherCancer") === "MaternalGrandfatherOtherCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Other cancer</h6>
                                  </label>
                                </div>
                                {this.state.familyHealthHistory.inYourFamilyHadCancer ? this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandfather ? this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandfather.find(x => x === "MaternalGrandfatherOtherCancer") === "MaternalGrandfatherOtherCancer" ?
                                  <div>
                                    <div className=" pad-l-2rem roboto-reg-18px ml-lg-4">
                                      <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                        <input type="text" placeholder="Other cancer" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeMaternalGrandfatherOtherCancer(e, "inYourFamilyHadCancer", "MaternalGrandfatherOtherCancer")} name="MaternalGrandfatherOtherCancer" value={this.state.familyHealthHistory.inYourFamilyHadCancer.MaternalGrandfatherOtherCancer} />
                                      </div>
                                    </div>
                                  </div>
                                  : "" : "" : ""}

                              </div>
                              : "" : "" : ""}

                            <div className=" pad-l-2rem  ml-4" id="check PaternalGrandmother cancer">
                              <input className="form-check-input" type="checkbox" name="PaternalGrandmother" value="PaternalGrandmother" id="PaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "member", "PaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.member ? this.state.familyHealthHistory.inYourFamilyHadCancer.member.find(x => x === "PaternalGrandmother") === "PaternalGrandmother" ? "checked" : "" : ""} />
                              <label className="form-check-label mb-1" >
                                <h6 className="reg-18">PaternalGrandmother</h6>
                              </label>
                            </div>
                            {this.state.familyHealthHistory.inYourFamilyHadCancer ? this.state.familyHealthHistory.inYourFamilyHadCancer.member ? this.state.familyHealthHistory.inYourFamilyHadCancer.member.find(x => x === "PaternalGrandmother") === "PaternalGrandmother" ?

                              <div style={{ marginLeft: '3.50rem' }}>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="BreastCancer" value="BreastCancer" id="BreastCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "PaternalGrandmother", "BreastCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandmother ? this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandmother.find(x => x === "BreastCancer") === "BreastCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Breast cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="OvarianCancer" value="OvarianCancer" id="OvarianCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "PaternalGrandmother", "OvarianCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandmother ? this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandmother.find(x => x === "OvarianCancer") === "OvarianCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Ovarian cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="FallopianTubeCancer" value="FallopianTubeCancer" id="FallopianTubeCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "PaternalGrandmother", "FallopianTubeCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandmother ? this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandmother.find(x => x === "FallopianTubeCancer") === "FallopianTubeCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Fallopian tube cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="PrimaryPeritonealCancer" value="PrimaryPeritonealCancer" id="PrimaryPeritonealCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "PaternalGrandmother", "PrimaryPeritonealCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandmother ? this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandmother.find(x => x === "PrimaryPeritonealCancer") === "PrimaryPeritonealCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Primary peritoneal cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="ColonCancer" value="ColonCancer" id="ColonCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "PaternalGrandmother", "ColonCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandmother ? this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandmother.find(x => x === "ColonCancer") === "ColonCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Colon (colorectal) cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="UterineCancer" value="UterineCancer" id="UterineCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "PaternalGrandmother", "UterineCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandmother ? this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandmother.find(x => x === "UterineCancer") === "UterineCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Uterine (endometrial) cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="StomachCancer" value="StomachCancer" id="StomachCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "PaternalGrandmother", "StomachCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandmother ? this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandmother.find(x => x === "StomachCancer") === "StomachCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Stomach (gastric) cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="Melanoma" value="Melanoma" id="OvarianCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "PaternalGrandmother", "Melanoma")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandmother ? this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandmother.find(x => x === "Melanoma") === "Melanoma" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Melanoma</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="PancreaticCancer" value="PancreaticCancer" id="PancreaticCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "PaternalGrandmother", "PancreaticCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandmother ? this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandmother.find(x => x === "PancreaticCancer") === "PancreaticCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Pancreatic cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="ThyroidCancer" value="ThyroidCancer" id="ThyroidCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "PaternalGrandmother", "ThyroidCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandmother ? this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandmother.find(x => x === "ThyroidCancer") === "ThyroidCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Thyroid cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="KidneyCancer" value="KidneyCancer" id="KidneyCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "PaternalGrandmother", "KidneyCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandmother ? this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandmother.find(x => x === "KidneyCancer") === "KidneyCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Kidney cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="PaternalGrandmotherOtherCancer" value="PaternalGrandmotherOtherCancer" id="PaternalGrandmotherOtherCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "PaternalGrandmother", "PaternalGrandmotherOtherCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandmother ? this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandmother.find(x => x === "PaternalGrandmotherOtherCancer") === "PaternalGrandmotherOtherCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Other cancer</h6>
                                  </label>
                                </div>
                                {this.state.familyHealthHistory.inYourFamilyHadCancer ? this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandmother ? this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandmother.find(x => x === "PaternalGrandmotherOtherCancer") === "PaternalGrandmotherOtherCancer" ?
                                  <div>
                                    <div className=" pad-l-2rem roboto-reg-18px ml-lg-4">
                                      <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                        <input type="text" placeholder="Other cancer" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangePaternalGrandmotherOtherCancer(e, "inYourFamilyHadCancer", "PaternalGrandmotherOtherCancer")} name="PaternalGrandmotherOtherCancer" value={this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandmotherOtherCancer} />
                                      </div>
                                    </div>
                                  </div>
                                  : "" : "" : ""}

                              </div>
                              : "" : "" : ""}





                            <div className=" pad-l-2rem  ml-4"  id="check PaternalGrandfather cancer">
                              <input className="form-check-input" type="checkbox" name="PaternalGrandfather" value="PaternalGrandfather" id="PaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "member", "PaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.member ? this.state.familyHealthHistory.inYourFamilyHadCancer.member.find(x => x === "PaternalGrandfather") === "PaternalGrandfather" ? "checked" : "" : ""} />
                              <label className="form-check-label mb-1" >
                                <h6 className="reg-18">PaternalGrandfather</h6>
                              </label>
                            </div>
                            {this.state.familyHealthHistory.inYourFamilyHadCancer ? this.state.familyHealthHistory.inYourFamilyHadCancer.member ? this.state.familyHealthHistory.inYourFamilyHadCancer.member.find(x => x === "PaternalGrandfather") === "PaternalGrandfather" ?

                              <div style={{ marginLeft: '3.50rem' }}>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="BreastCancer" value="BreastCancer" id="BreastCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "PaternalGrandfather", "BreastCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandfather ? this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandfather.find(x => x === "BreastCancer") === "BreastCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Breast cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="OvarianCancer" value="OvarianCancer" id="OvarianCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "PaternalGrandfather", "OvarianCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandfather ? this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandfather.find(x => x === "OvarianCancer") === "OvarianCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Ovarian cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="FallopianTubeCancer" value="FallopianTubeCancer" id="FallopianTubeCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "PaternalGrandfather", "FallopianTubeCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandfather ? this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandfather.find(x => x === "FallopianTubeCancer") === "FallopianTubeCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Fallopian tube cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="PrimaryPeritonealCancer" value="PrimaryPeritonealCancer" id="PrimaryPeritonealCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "PaternalGrandfather", "PrimaryPeritonealCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandfather ? this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandfather.find(x => x === "PrimaryPeritonealCancer") === "PrimaryPeritonealCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Primary peritoneal cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="ColonCancer" value="ColonCancer" id="ColonCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "PaternalGrandfather", "ColonCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandfather ? this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandfather.find(x => x === "ColonCancer") === "ColonCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Colon (colorectal) cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="UterineCancer" value="UterineCancer" id="UterineCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "PaternalGrandfather", "UterineCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandfather ? this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandfather.find(x => x === "UterineCancer") === "UterineCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Uterine (endometrial) cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="StomachCancer" value="StomachCancer" id="StomachCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "PaternalGrandfather", "StomachCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandfather ? this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandfather.find(x => x === "StomachCancer") === "StomachCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Stomach (gastric) cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="Melanoma" value="Melanoma" id="OvarianCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "PaternalGrandfather", "Melanoma")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandfather ? this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandfather.find(x => x === "Melanoma") === "Melanoma" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Melanoma</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="PancreaticCancer" value="PancreaticCancer" id="PancreaticCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "PaternalGrandfather", "PancreaticCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandfather ? this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandfather.find(x => x === "PancreaticCancer") === "PancreaticCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Pancreatic cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="ThyroidCancer" value="ThyroidCancer" id="ThyroidCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "PaternalGrandfather", "ThyroidCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandfather ? this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandfather.find(x => x === "ThyroidCancer") === "ThyroidCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Thyroid cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="KidneyCancer" value="KidneyCancer" id="KidneyCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "PaternalGrandfather", "KidneyCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandfather ? this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandfather.find(x => x === "KidneyCancer") === "KidneyCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Kidney cancer</h6>
                                  </label>
                                </div>
                                <div className="pad-l-2rem ml-4">
                                  <input className="form-check-input" type="checkbox" name="motherOtherCancer" value="PaternalGrandfatherOtherCancer" id="PaternalGrandfatherOtherCancer" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "inYourFamilyHadCancer", "PaternalGrandfather", "PaternalGrandfatherOtherCancer")} defaultChecked={this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandfather ? this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandfather.find(x => x === "PaternalGrandfatherOtherCancer") === "PaternalGrandfatherOtherCancer" ? "checked" : "" : ""} />
                                  <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Other cancer</h6>
                                  </label>
                                </div>
                                {this.state.familyHealthHistory.inYourFamilyHadCancer ? this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandfather ? this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandfather.find(x => x === "PaternalGrandfatherOtherCancer") === "PaternalGrandfatherOtherCancer" ?
                                  <div>
                                    <div className=" pad-l-2rem roboto-reg-18px ml-lg-4">
                                      <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                        <input type="text" placeholder="Other cancer" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangePaternalGrandfatherOtherCancer(e, "inYourFamilyHadCancer", "PaternalGrandfatherOtherCancer")} name="PaternalGrandfatherOtherCancer" value={this.state.familyHealthHistory.inYourFamilyHadCancer.PaternalGrandfatherOtherCancer} />
                                      </div>
                                    </div>
                                  </div>
                                  : "" : "" : ""}

                              </div>
                              : "" : "" : ""}


                          </div>


                          : '' : ''}



                        <h2 id="check HereditaryCancerRisk cancer" className="roboto-bold-30px pad-l-2rem pt-2">Did anyone had a genetic test for hereditary cancer risk?</h2>
                        <div className=" d-flex pad-l-2rem ml-2">
                          <div className="form-check  mr-5">
                            <input className="form-check-input" type="radio" name="geneticTestForHereditaryCancerRisk" id="geneticTestForHereditaryCancerRisk"  value="yes" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "geneticTestForHereditaryCancerRisk", "option")} checked={this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk ? this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.value[0] === "yes" ? "checked" : "" : ""} />
                            <label className="form-check-label" htmlFor="geneticTestForHereditaryCancerRisk">
                              <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.geneticTestForHereditaryCancerRisk ? "#ff0000" : "" }}>  Yes</h6>
                            </label>
                          </div>
                          <div className="form-check  mr-5">
                            <input className="form-check-input" type="radio" name="geneticTestForHereditaryCancerRisk" id="geneticTestForHereditaryCancerRisk"  value="no" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "geneticTestForHereditaryCancerRisk", "option")} checked={this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk ? this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.value[0] === "no" ? "checked" : "" : ""} />
                            <label className="form-check-label" htmlFor="geneticTestForHereditaryCancerRisk">
                              <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.geneticTestForHereditaryCancerRisk ? "#ff0000" : "" }}>  No</h6>
                            </label>
                          </div>
                          <div className="form-check  mr-5">
                            <input className="form-check-input" type="radio" name="geneticTestForHereditaryCancerRisk" id="geneticTestForHereditaryCancerRisk"  value="may be" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "geneticTestForHereditaryCancerRisk", "option")} checked={this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk ? this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.value[0] === "may be" ? "checked" : "" : ""} />
                            <label className="form-check-label" htmlFor="geneticTestForHereditaryCancerRisk">
                              <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.geneticTestForHereditaryCancerRisk ? "#ff0000" : "" }}>Maybe</h6>
                            </label>
                          </div>
                        </div>
                        {this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk ? this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.value[0] === "yes" ?
                          <div style={{ marginLeft: '1.6rem' }}>
                            <div className="pad-l-2rem " id="check fields from Mother hereditary cancer risk">
                              <input className="form-check-input" type="checkbox" name="mother" value="mother" id="mother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "geneticTestForHereditaryCancerRisk", "member", "mother")} defaultChecked={this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.member ? this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.member.find(x => x === "mother") === "mother" ? "checked" : "" : ""} />
                              <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                <h6 className="reg-18 ">Mother</h6>
                              </label>
                            </div>

                            {this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk ? this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.member ? this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.member.find(x => x === "mother") === "mother" ?
                              <div >
                                <p className="roboto-reg-18px ml-4 mt-2" >Who performed the genetic test?</p>
                                <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                  <input type="text"  name="whoPerfomedGeneticTest" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryAgeAddData(e, "geneticTestForHereditaryCancerRisk", "whoPerfomedGeneticTest")} value={this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.whoPerfomedGeneticTest} />
                                </div>

                                <p className="roboto-reg-18px ml-4 mt-2">Was a mutation identified?</p>
                                <div className=" d-flex ">
                                  <div className="form-check  ml-4 mb-2">
                                    <input className="form-check-input" type="radio" name="mutationIdentifiedForHereditaryHeart" value="yes" onChange={(e) => this.checkHandleChangeAddDataMutationFamilyHealthHistory(e, "geneticTestForHereditaryCancerRisk", "mutationIdentifiedForHereditaryHeart")} checked={this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.mutationIdentifiedForHereditaryHeart ? this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.mutationIdentifiedForHereditaryHeart[0] === "yes" ? "checked" : "" : ""} />
                                    <label className="form-check-label" >
                                      <h6 className="reg-18">  Yes</h6>
                                    </label>
                                  </div>
                                  <div className="form-check  ml-4 mb-2">
                                    <input className="form-check-input" type="radio" name="mutationIdentifiedForHereditaryHeart" value="no" onChange={(e) => this.checkHandleChangeAddDataMutationFamilyHealthHistory(e, "geneticTestForHereditaryCancerRisk", "mutationIdentifiedForHereditaryHeart")} checked={this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.mutationIdentifiedForHereditaryHeart ? this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.mutationIdentifiedForHereditaryHeart[0] === "no" ? "checked" : "" : ""} />
                                    <label className="form-check-label">
                                      <h6 className="reg-18">  No</h6>
                                    </label>
                                  </div>
                                  <div className="form-check  ml-4 mb-2">
                                    <input className="form-check-input" type="radio" name="mutationIdentifiedForHereditaryHeart" value="may be" onChange={(e) => this.checkHandleChangeAddDataMutationFamilyHealthHistory(e, "geneticTestForHereditaryCancerRisk", "mutationIdentifiedForHereditaryHeart")} checked={this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.mutationIdentifiedForHereditaryHeart ? this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.mutationIdentifiedForHereditaryHeart[0] === "may be" ? "checked" : "" : ""} />
                                    <label className="form-check-label" >
                                      <h6 className="reg-18">Maybe</h6>
                                    </label>
                                  </div>
                                </div>

                              </div>


                              : "" : "" : ""}


                            <div className="pad-l-2rem " id="check fields from Father hereditary cancer risk">
                              <input className="form-check-input" type="checkbox" name="Father" value="Father" id="Father" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "geneticTestForHereditaryCancerRisk", "member", "Father")} defaultChecked={this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.member ? this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.member.find(x => x === "Father") === "Father" ? "checked" : "" : ""} />
                              <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                <h6 className="reg-18 ">Father</h6>
                              </label>
                            </div>

                            {this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk ? this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.member ? this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.member.find(x => x === "Father") === "Father" ?
                              <div >
                                <p className="roboto-reg-18px ml-4 mt-2" >Who performed the genetic test?</p>
                                <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                  <input type="text"  name="whoPerfomedGeneticTest" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureFatherAge(e, "geneticTestForHereditaryCancerRisk", "fatherAge")} value={this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.fatherAge} />
                                </div>

                                <p className="roboto-reg-18px ml-4 mt-2">Was a mutation identified?</p>
                                <div className=" d-flex ">
                                  <div className="form-check  ml-4 mb-2">
                                    <input className="form-check-input" type="radio" name="FatherMutation" value="yes" onChange={(e) => this.checkHandleChangeAddDataMutationFatherFamilyHealthHistory(e, "geneticTestForHereditaryCancerRisk", "FatherMutation")} checked={this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.FatherMutation ? this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.FatherMutation[0] === "yes" ? "checked" : "" : ""} />
                                    <label className="form-check-label" >
                                      <h6 className="reg-18">  Yes</h6>
                                    </label>
                                  </div>
                                  <div className="form-check  ml-4 mb-2">
                                    <input className="form-check-input" type="radio" name="FatherMutation" value="no" onChange={(e) => this.checkHandleChangeAddDataMutationFatherFamilyHealthHistory(e, "geneticTestForHereditaryCancerRisk", "FatherMutation")} checked={this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.FatherMutation ? this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.FatherMutation[0] === "no" ? "checked" : "" : ""} />
                                    <label className="form-check-label">
                                      <h6 className="reg-18">  No</h6>
                                    </label>
                                  </div>
                                  <div className="form-check  ml-4 mb-2">
                                    <input className="form-check-input" type="radio" name="FatherMutation" value="may be" onChange={(e) => this.checkHandleChangeAddDataMutationFatherFamilyHealthHistory(e, "geneticTestForHereditaryCancerRisk", "FatherMutation")} checked={this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.FatherMutation ? this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.FatherMutation[0] === "may be" ? "checked" : "" : ""} />
                                    <label className="form-check-label" >
                                      <h6 className="reg-18">May be</h6>
                                    </label>
                                  </div>
                                </div>

                              </div>



                              : "" : "" : ""}

                            <div className="pad-l-2rem " id="check fields from Siblings hereditary cancer risk">
                              <input className="form-check-input" type="checkbox" name="Siblings" value="Siblings" id="Siblings" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "geneticTestForHereditaryCancerRisk", "member", "Siblings")} defaultChecked={this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.member ? this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.member.find(x => x === "Siblings") === "Siblings" ? "checked" : "" : ""} />
                              <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                <h6 className="reg-18 ">Siblings</h6>
                              </label>
                            </div>

                            {this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk ? this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.member ? this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.member.find(x => x === "Siblings") === "Siblings" ?
                              <div >
                                <p className="roboto-reg-18px ml-4 mt-2" >Who performed the genetic test?</p>
                                <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                  <input type="text"  name="whoPerfomedGeneticTest" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureSiblingsAge(e, "geneticTestForHereditaryCancerRisk", "SiblingsAge")} value={this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.SiblingsAge} />
                                </div>

                                <p className="roboto-reg-18px ml-4 mt-2">Was a mutation identified?</p>
                                <div className=" d-flex ">
                                  <div className="form-check  ml-4 mb-2">
                                    <input className="form-check-input" type="radio" name="SiblingsMutation" value="yes" onChange={(e) => this.checkHandleChangeAddDataMutationSiblingsFamilyHealthHistory(e, "geneticTestForHereditaryCancerRisk", "SiblingsMutation")} checked={this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.SiblingsMutation ? this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.SiblingsMutation[0] === "yes" ? "checked" : "" : ""} />
                                    <label className="form-check-label" >
                                      <h6 className="reg-18">  Yes</h6>
                                    </label>
                                  </div>
                                  <div className="form-check  ml-4 mb-2">
                                    <input className="form-check-input" type="radio" name="SiblingsMutation" value="no" onChange={(e) => this.checkHandleChangeAddDataMutationSiblingsFamilyHealthHistory(e, "geneticTestForHereditaryCancerRisk", "SiblingsMutation")} checked={this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.SiblingsMutation ? this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.SiblingsMutation[0] === "no" ? "checked" : "" : ""} />
                                    <label className="form-check-label">
                                      <h6 className="reg-18">  No</h6>
                                    </label>
                                  </div>
                                  <div className="form-check  ml-4 mb-2">
                                    <input className="form-check-input" type="radio" name="SiblingsMutation" value="may be" onChange={(e) => this.checkHandleChangeAddDataMutationSiblingsFamilyHealthHistory(e, "geneticTestForHereditaryCancerRisk", "SiblingsMutation")} checked={this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.SiblingsMutation ? this.state.familyHealthHistory.geneticTestForHereditaryCancerRisk.SiblingsMutation[0] === "may be" ? "checked" : "" : ""} />
                                    <label className="form-check-label" >
                                      <h6 className="reg-18">Maybe</h6>
                                    </label>
                                  </div>
                                </div>

                              </div>


                              : "" : "" : ""}



                          </div>


                          : '' : ''}

                        <div className="hh-ftab-22-sec  mt-2" style={{ backgroundColor: '#eff7ff' }}>
                          <h2 id="check fields from Familial hypercholesterolemia" className="pad-l-2rem roboto-black-36px py-4" style={{ color: '#4f5be7' }}>Your family's heart health history</h2>
                        </div>
                        <div className="container pad-l-2rem ">
                        </div>
                        <p className="roboto-bold-18px  pad-l-2rem ">Familial hypercholesterolemia (FH) (optional)</p>
                        <div className="d-flex" style={{ marginLeft: '1.50rem' }}>
                          <div className="form-check pad-l-2rem  mr-5">
                            <input className="form-check-input" type="radio" name="fh" id="fh"  value="yes" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "fh", "option")} checked={this.state.familyHealthHistory.fh ? this.state.familyHealthHistory.fh.value[0] === "yes" ? "checked" : "" : ""} />
                            <label className="form-check-label" htmlFor="fh">
                              <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.fh ? "#ff0000" : "" }}>  Yes</h6>
                            </label>
                          </div>
                          <div className="form-check  mr-5">
                            <input className="form-check-input" type="radio" name="fh" id="fh"  value="no" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "fh", "option")} checked={this.state.familyHealthHistory.fh ? this.state.familyHealthHistory.fh.value[0] === "no" ? "checked" : "" : ""} />
                            <label className="form-check-label" htmlFor="fh">
                              <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.fh ? "#ff0000" : "" }}>  No</h6>
                            </label>
                          </div>
                          <div className="form-check  mr-5">
                            <input className="form-check-input" type="radio" name="fh" id="fh"  value="may be" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "fh", "option")} checked={this.state.familyHealthHistory.fh ? this.state.familyHealthHistory.fh.value[0] === "may be" ? "checked" : "" : ""} />
                            <label className="form-check-label" htmlFor="fh">
                              <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.fh ? "#ff0000" : "" }}>Maybe</h6>
                            </label>
                          </div>
                        </div>
                        <div className="container pad-l-2rem ">
                        </div>
                        <div className="container pad-l-2rem mt-2">
                          {this.state.familyHealthHistory.fh ? this.state.familyHealthHistory.fh.value[0] === "yes" ?

                            <div >
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="mother" value="mother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "fh", "members", "mother")} defaultChecked={this.state.familyHealthHistory.fh.members ? this.state.familyHealthHistory.fh.members.find(x => x === "mother") === "mother" ? "checked" : "" : ""} />
                                <label className="form-check-label" htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Mother</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="father" value="father" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "fh", "members", "father")} defaultChecked={this.state.familyHealthHistory.fh.members ? this.state.familyHealthHistory.fh.members.find(x => x === "father") === "father" ? "checked" : "" : ""} />
                                <label className="form-check-label" htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Father</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="siblings" value="siblings" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "fh", "members", "siblings")} defaultChecked={this.state.familyHealthHistory.fh.members ? this.state.familyHealthHistory.fh.members.find(x => x === "siblings") === "siblings" ? "checked" : "" : ""} />
                                <label className="form-check-label" htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Siblings</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="MaternalGrandmother" value="MaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "fh", "members", "MaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.fh.members ? this.state.familyHealthHistory.fh.members.find(x => x === "MaternalGrandmother") === "MaternalGrandmother" ? "checked" : "" : ""} />
                                <label className="form-check-label" htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Maternal Grandmother </h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="MaternalGrandfather" value="MaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "fh", "members", "MaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.fh.members ? this.state.familyHealthHistory.fh.members.find(x => x === "MaternalGrandfather") === "MaternalGrandfather" ? "checked" : "" : ""} />
                                <label className="form-check-label" htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Maternal Grandfather</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="PaternalGrandmother" value="PaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "fh", "members", "PaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.fh.members ? this.state.familyHealthHistory.fh.members.find(x => x === "PaternalGrandmother") === "PaternalGrandmother" ? "checked" : "" : ""} />
                                <label className="form-check-label" htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Paternal Grandmother</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="PaternalGrandfather" value="PaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "fh", "members", "PaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.fh.members ? this.state.familyHealthHistory.fh.members.find(x => x === "PaternalGrandfather") === "PaternalGrandfather" ? "checked" : "" : ""} />
                                <label className="form-check-label" htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Paternal Grandfather</h6>
                                </label>
                              </div>
                            </div>
                            : '' : ''}

                          <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                          <p className="roboto-bold-18px  " id="check fields from Cardiomyopathy" >Cardiomyopathy</p>
                          <div className=" d-flex">
                            <div className="form-check   mr-5">
                              <input className="form-check-input" type="radio" name="cardiomyopathy" id="cardiomyopathy"  value="yes" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "cardiomyopathy", "option")} checked={this.state.familyHealthHistory.cardiomyopathy ? this.state.familyHealthHistory.cardiomyopathy.value[0] === "yes" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="cardiomyopathy">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.cardiomyopathy ? "#ff0000" : "" }}>  Yes</h6>
                              </label>
                            </div>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="cardiomyopathy" id="cardiomyopathy"  value="no" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "cardiomyopathy", "option")} checked={this.state.familyHealthHistory.cardiomyopathy ? this.state.familyHealthHistory.cardiomyopathy.value[0] === "no" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="cardiomyopathy">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.cardiomyopathy ? "#ff0000" : "" }}>  No</h6>
                              </label>
                            </div>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="cardiomyopathy" id="cardiomyopathy"  value="may be" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "cardiomyopathy", "option")} checked={this.state.familyHealthHistory.cardiomyopathy ? this.state.familyHealthHistory.cardiomyopathy.value[0] === "may be" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="cardiomyopathy">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.cardiomyopathy ? "#ff0000" : "" }}>Maybe</h6>
                              </label>
                            </div>
                          </div>
                          <div className="container pad-l-2rem ">
                          </div>
                          {this.state.familyHealthHistory.cardiomyopathy ? this.state.familyHealthHistory.cardiomyopathy.value[0] === "yes" ?


                            <div>

<div className="form-check">
                                <input className="form-check-input" type="checkbox" name="Mother" value="Mother" id="Mother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "member", "Mother")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.member ? this.state.familyHealthHistory.cardiomyopathy.member.find(x => x === "Mother") === "Mother" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Mother</h6>
                                </label>
                              </div>
                              {/* {this.state.familyHealthHistory.cardiomyopathy ? this.state.familyHealthHistory.cardiomyopathy.member ? this.state.familyHealthHistory.cardiomyopathy.member.find(x => x === "Mother") === "Mother" ?

                                <div style={{ marginLeft: '3.50rem' }}>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="Hypertrophiccardiomyopathy" value="Hypertrophiccardiomyopathy" id="Hypertrophiccardiomyopathy" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "Mother", "Hypertrophiccardiomyopathy")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.Mother ? this.state.familyHealthHistory.cardiomyopathy.Mother.find(x => x === "Hypertrophiccardiomyopathy") === "Hypertrophiccardiomyopathy" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Hypertrophic cardiomyopathy</h6>
                                    </label>
                                  </div>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="Dilatedcardiomyopathy" value="Dilatedcardiomyopathy" id="Dilatedcardiomyopathy" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "Mother", "Dilatedcardiomyopathy")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.Mother ? this.state.familyHealthHistory.cardiomyopathy.Mother.find(x => x === "Dilatedcardiomyopathy") === "Dilatedcardiomyopathy" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Dilated cardiomyopathy</h6>
                                    </label>
                                  </div>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="Restrictivecardiomyopathy" value="Restrictivecardiomyopathy" id="Restrictivecardiomyopathy" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "Mother", "Restrictivecardiomyopathy")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.Mother ? this.state.familyHealthHistory.cardiomyopathy.Mother.find(x => x === "Restrictivecardiomyopathy") === "Restrictivecardiomyopathy" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Restrictive cardiomyopathy</h6>
                                    </label>
                                  </div>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="Arrhythmogenic" value="Arrhythmogenic" id="Arrhythmogenic" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "Mother", "Arrhythmogenic")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.Mother ? this.state.familyHealthHistory.cardiomyopathy.Mother.find(x => x === "Arrhythmogenic") === "Arrhythmogenic" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Arrhythmogenic cardiomyopathy</h6>
                                    </label>
                                  </div>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="LeftVentricular" value="LeftVentricular" id="LeftVentricular" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "Mother", "LeftVentricular")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.Mother ? this.state.familyHealthHistory.cardiomyopathy.Mother.find(x => x === "LeftVentricular") === "LeftVentricular" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Left ventricular noncompaction cardiomyopathy</h6>
                                    </label>
                                  </div>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="FabryDisease" value="FabryDisease" id="FabryDisease" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "Mother", "FabryDisease")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.Mother ? this.state.familyHealthHistory.cardiomyopathy.Mother.find(x => x === "FabryDisease") === "FabryDisease" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Fabry disease</h6>
                                    </label>
                                  </div>


                                </div>
                                : "" : "" : ""} */}


<div className="form-check">
                                <input className="form-check-input" type="checkbox" name="Father" value="Father" id="Father" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "member", "Father")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.member ? this.state.familyHealthHistory.cardiomyopathy.member.find(x => x === "Father") === "Father" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Father</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="siblings" value="siblings" id="siblings" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "member", "siblings")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.member ? this.state.familyHealthHistory.cardiomyopathy.member.find(x => x === "siblings") === "siblings" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Siblings</h6>
                                </label>
                              </div>
                              {/* {this.state.familyHealthHistory.cardiomyopathy ? this.state.familyHealthHistory.cardiomyopathy.member ? this.state.familyHealthHistory.cardiomyopathy.member.find(x => x === "Father") === "Father" ?

                                <div style={{ marginLeft: '3.50rem' }}>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="Hypertrophiccardiomyopathy" value="Hypertrophiccardiomyopathy" id="Hypertrophiccardiomyopathy" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "Father", "Hypertrophiccardiomyopathy")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.Father ? this.state.familyHealthHistory.cardiomyopathy.Father.find(x => x === "Hypertrophiccardiomyopathy") === "Hypertrophiccardiomyopathy" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Hypertrophic cardiomyopathy</h6>
                                    </label>
                                  </div>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="Dilatedcardiomyopathy" value="Dilatedcardiomyopathy" id="Dilatedcardiomyopathy" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "Father", "Dilatedcardiomyopathy")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.Father ? this.state.familyHealthHistory.cardiomyopathy.Father.find(x => x === "Dilatedcardiomyopathy") === "Dilatedcardiomyopathy" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Dilated cardiomyopathy</h6>
                                    </label>
                                  </div>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="Restrictivecardiomyopathy" value="Restrictivecardiomyopathy" id="Restrictivecardiomyopathy" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "Father", "Restrictivecardiomyopathy")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.Father ? this.state.familyHealthHistory.cardiomyopathy.Father.find(x => x === "Restrictivecardiomyopathy") === "Restrictivecardiomyopathy" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Restrictive cardiomyopathy</h6>
                                    </label>
                                  </div>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="Arrhythmogenic" value="Arrhythmogenic" id="Arrhythmogenic" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "Father", "Arrhythmogenic")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.Father ? this.state.familyHealthHistory.cardiomyopathy.Father.find(x => x === "Arrhythmogenic") === "Arrhythmogenic" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Arrhythmogenic cardiomyopathy</h6>
                                    </label>
                                  </div>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="LeftVentricular" value="LeftVentricular" id="LeftVentricular" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "Father", "LeftVentricular")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.Father ? this.state.familyHealthHistory.cardiomyopathy.Father.find(x => x === "LeftVentricular") === "LeftVentricular" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Left ventricular noncompaction cardiomyopathy</h6>
                                    </label>
                                  </div>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="FabryDisease" value="FabryDisease" id="FabryDisease" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "Father", "FabryDisease")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.Father ? this.state.familyHealthHistory.cardiomyopathy.Father.find(x => x === "FabryDisease") === "FabryDisease" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Fabry disease</h6>
                                    </label>
                                  </div>


                                </div>
                                : "" : "" : ""} */}

<div className="form-check">
                                <input className="form-check-input" type="checkbox" name="MaternalGrandmother" value="MaternalGrandmother" id="MaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "member", "MaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.member ? this.state.familyHealthHistory.cardiomyopathy.member.find(x => x === "MaternalGrandmother") === "MaternalGrandmother" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Maternal Grandmother</h6>
                                </label>
                              </div>
                              {/* {this.state.familyHealthHistory.cardiomyopathy ? this.state.familyHealthHistory.cardiomyopathy.member ? this.state.familyHealthHistory.cardiomyopathy.member.find(x => x === "MaternalGrandmother") === "MaternalGrandmother" ?

                                <div style={{ marginLeft: '3.50rem' }}>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="Hypertrophiccardiomyopathy" value="Hypertrophiccardiomyopathy" id="Hypertrophiccardiomyopathy" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "MaternalGrandmother", "Hypertrophiccardiomyopathy")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.MaternalGrandmother ? this.state.familyHealthHistory.cardiomyopathy.MaternalGrandmother.find(x => x === "Hypertrophiccardiomyopathy") === "Hypertrophiccardiomyopathy" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Hypertrophic cardiomyopathy</h6>
                                    </label>
                                  </div>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="Dilatedcardiomyopathy" value="Dilatedcardiomyopathy" id="Dilatedcardiomyopathy" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "MaternalGrandmother", "Dilatedcardiomyopathy")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.MaternalGrandmother ? this.state.familyHealthHistory.cardiomyopathy.MaternalGrandmother.find(x => x === "Dilatedcardiomyopathy") === "Dilatedcardiomyopathy" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Dilated cardiomyopathy</h6>
                                    </label>
                                  </div>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="Restrictivecardiomyopathy" value="Restrictivecardiomyopathy" id="Restrictivecardiomyopathy" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "MaternalGrandmother", "Restrictivecardiomyopathy")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.MaternalGrandmother ? this.state.familyHealthHistory.cardiomyopathy.MaternalGrandmother.find(x => x === "Restrictivecardiomyopathy") === "Restrictivecardiomyopathy" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Restrictive cardiomyopathy</h6>
                                    </label>
                                  </div>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="Arrhythmogenic" value="Arrhythmogenic" id="Arrhythmogenic" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "MaternalGrandmother", "Arrhythmogenic")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.MaternalGrandmother ? this.state.familyHealthHistory.cardiomyopathy.MaternalGrandmother.find(x => x === "Arrhythmogenic") === "Arrhythmogenic" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Arrhythmogenic cardiomyopathy</h6>
                                    </label>
                                  </div>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="LeftVentricular" value="LeftVentricular" id="LeftVentricular" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "MaternalGrandmother", "LeftVentricular")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.MaternalGrandmother ? this.state.familyHealthHistory.cardiomyopathy.MaternalGrandmother.find(x => x === "LeftVentricular") === "LeftVentricular" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Left ventricular noncompaction cardiomyopathy</h6>
                                    </label>
                                  </div>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="FabryDisease" value="FabryDisease" id="FabryDisease" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "MaternalGrandmother", "FabryDisease")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.MaternalGrandmother ? this.state.familyHealthHistory.cardiomyopathy.MaternalGrandmother.find(x => x === "FabryDisease") === "FabryDisease" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Fabry disease</h6>
                                    </label>
                                  </div>


                                </div>
                                : "" : "" : ""} */}

<div className="form-check">
                                <input className="form-check-input" type="checkbox" name="MaternalGrandfather" value="MaternalGrandfather" id="MaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "member", "MaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.member ? this.state.familyHealthHistory.cardiomyopathy.member.find(x => x === "MaternalGrandfather") === "MaternalGrandfather" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Maternal Grandfather</h6>
                                </label>
                              </div>
                              {/* {this.state.familyHealthHistory.cardiomyopathy ? this.state.familyHealthHistory.cardiomyopathy.member ? this.state.familyHealthHistory.cardiomyopathy.member.find(x => x === "MaternalGrandfather") === "MaternalGrandfather" ?

                                <div style={{ marginLeft: '3.50rem' }}>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="Hypertrophiccardiomyopathy" value="Hypertrophiccardiomyopathy" id="Hypertrophiccardiomyopathy" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "MaternalGrandfather", "Hypertrophiccardiomyopathy")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.MaternalGrandfather ? this.state.familyHealthHistory.cardiomyopathy.MaternalGrandfather.find(x => x === "Hypertrophiccardiomyopathy") === "Hypertrophiccardiomyopathy" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Hypertrophic cardiomyopathy</h6>
                                    </label>
                                  </div>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="Dilatedcardiomyopathy" value="Dilatedcardiomyopathy" id="Dilatedcardiomyopathy" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "MaternalGrandfather", "Dilatedcardiomyopathy")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.MaternalGrandfather ? this.state.familyHealthHistory.cardiomyopathy.MaternalGrandfather.find(x => x === "Dilatedcardiomyopathy") === "Dilatedcardiomyopathy" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Dilated cardiomyopathy</h6>
                                    </label>
                                  </div>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="Restrictivecardiomyopathy" value="Restrictivecardiomyopathy" id="Restrictivecardiomyopathy" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "MaternalGrandfather", "Restrictivecardiomyopathy")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.MaternalGrandfather ? this.state.familyHealthHistory.cardiomyopathy.MaternalGrandfather.find(x => x === "Restrictivecardiomyopathy") === "Restrictivecardiomyopathy" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Restrictive cardiomyopathy</h6>
                                    </label>
                                  </div>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="Arrhythmogenic" value="Arrhythmogenic" id="Arrhythmogenic" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "MaternalGrandfather", "Arrhythmogenic")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.MaternalGrandfather ? this.state.familyHealthHistory.cardiomyopathy.MaternalGrandfather.find(x => x === "Arrhythmogenic") === "Arrhythmogenic" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Arrhythmogenic cardiomyopathy</h6>
                                    </label>
                                  </div>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="LeftVentricular" value="LeftVentricular" id="LeftVentricular" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "MaternalGrandfather", "LeftVentricular")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.MaternalGrandfather ? this.state.familyHealthHistory.cardiomyopathy.MaternalGrandfather.find(x => x === "LeftVentricular") === "LeftVentricular" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Left ventricular noncompaction cardiomyopathy</h6>
                                    </label>
                                  </div>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="FabryDisease" value="FabryDisease" id="FabryDisease" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "MaternalGrandfather", "FabryDisease")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.MaternalGrandfather ? this.state.familyHealthHistory.cardiomyopathy.MaternalGrandfather.find(x => x === "FabryDisease") === "FabryDisease" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Fabry disease</h6>
                                    </label>
                                  </div>


                                </div>
                                : "" : "" : ""} */}

<div className="form-check">
                                <input className="form-check-input" type="checkbox" name="PaternalGrandmother" value="PaternalGrandmother" id="PaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "member", "PaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.member ? this.state.familyHealthHistory.cardiomyopathy.member.find(x => x === "PaternalGrandmother") === "PaternalGrandmother" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Paternal Grandmother</h6>
                                </label>
                              </div>
                              {/* {this.state.familyHealthHistory.cardiomyopathy ? this.state.familyHealthHistory.cardiomyopathy.member ? this.state.familyHealthHistory.cardiomyopathy.member.find(x => x === "PaternalGrandmother") === "PaternalGrandmother" ?

                                <div style={{ marginLeft: '3.50rem' }}>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="Hypertrophiccardiomyopathy" value="Hypertrophiccardiomyopathy" id="Hypertrophiccardiomyopathy" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "PaternalGrandmother", "Hypertrophiccardiomyopathy")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.PaternalGrandmother ? this.state.familyHealthHistory.cardiomyopathy.PaternalGrandmother.find(x => x === "Hypertrophiccardiomyopathy") === "Hypertrophiccardiomyopathy" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Hypertrophic cardiomyopathy</h6>
                                    </label>
                                  </div>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="Dilatedcardiomyopathy" value="Dilatedcardiomyopathy" id="Dilatedcardiomyopathy" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "PaternalGrandmother", "Dilatedcardiomyopathy")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.PaternalGrandmother ? this.state.familyHealthHistory.cardiomyopathy.PaternalGrandmother.find(x => x === "Dilatedcardiomyopathy") === "Dilatedcardiomyopathy" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Dilated cardiomyopathy</h6>
                                    </label>
                                  </div>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="Restrictivecardiomyopathy" value="Restrictivecardiomyopathy" id="Restrictivecardiomyopathy" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "PaternalGrandmother", "Restrictivecardiomyopathy")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.PaternalGrandmother ? this.state.familyHealthHistory.cardiomyopathy.PaternalGrandmother.find(x => x === "Restrictivecardiomyopathy") === "Restrictivecardiomyopathy" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Restrictive cardiomyopathy</h6>
                                    </label>
                                  </div>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="Arrhythmogenic" value="Arrhythmogenic" id="Arrhythmogenic" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "PaternalGrandmother", "Arrhythmogenic")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.PaternalGrandmother ? this.state.familyHealthHistory.cardiomyopathy.PaternalGrandmother.find(x => x === "Arrhythmogenic") === "Arrhythmogenic" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Arrhythmogenic cardiomyopathy</h6>
                                    </label>
                                  </div>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="LeftVentricular" value="LeftVentricular" id="LeftVentricular" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "PaternalGrandmother", "LeftVentricular")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.PaternalGrandmother ? this.state.familyHealthHistory.cardiomyopathy.PaternalGrandmother.find(x => x === "LeftVentricular") === "LeftVentricular" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Left ventricular noncompaction cardiomyopathy</h6>
                                    </label>
                                  </div>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="FabryDisease" value="FabryDisease" id="FabryDisease" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "PaternalGrandmother", "FabryDisease")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.PaternalGrandmother ? this.state.familyHealthHistory.cardiomyopathy.PaternalGrandmother.find(x => x === "FabryDisease") === "FabryDisease" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Fabry disease</h6>
                                    </label>
                                  </div>


                                </div>
                                : "" : "" : ""} */}

<div className="form-check">
                                <input className="form-check-input" type="checkbox" name="PaternalGrandfather" value="PaternalGrandfather" id="PaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "member", "PaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.member ? this.state.familyHealthHistory.cardiomyopathy.member.find(x => x === "PaternalGrandfather") === "PaternalGrandfather" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">PaternalGrandfather</h6>
                                </label>
                              </div>
                              {/* {this.state.familyHealthHistory.cardiomyopathy ? this.state.familyHealthHistory.cardiomyopathy.member ? this.state.familyHealthHistory.cardiomyopathy.member.find(x => x === "PaternalGrandfather") === "PaternalGrandfather" ?

                                <div style={{ marginLeft: '3.50rem' }}>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="Hypertrophiccardiomyopathy" value="Hypertrophiccardiomyopathy" id="Hypertrophiccardiomyopathy" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "PaternalGrandfather", "Hypertrophiccardiomyopathy")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.PaternalGrandfather ? this.state.familyHealthHistory.cardiomyopathy.PaternalGrandfather.find(x => x === "Hypertrophiccardiomyopathy") === "Hypertrophiccardiomyopathy" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Hypertrophic cardiomyopathy</h6>
                                    </label>
                                  </div>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="Dilatedcardiomyopathy" value="Dilatedcardiomyopathy" id="Dilatedcardiomyopathy" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "PaternalGrandfather", "Dilatedcardiomyopathy")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.PaternalGrandfather ? this.state.familyHealthHistory.cardiomyopathy.PaternalGrandfather.find(x => x === "Dilatedcardiomyopathy") === "Dilatedcardiomyopathy" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Dilated cardiomyopathy</h6>
                                    </label>
                                  </div>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="Restrictivecardiomyopathy" value="Restrictivecardiomyopathy" id="Restrictivecardiomyopathy" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "PaternalGrandfather", "Restrictivecardiomyopathy")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.PaternalGrandfather ? this.state.familyHealthHistory.cardiomyopathy.PaternalGrandfather.find(x => x === "Restrictivecardiomyopathy") === "Restrictivecardiomyopathy" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Restrictive cardiomyopathy</h6>
                                    </label>
                                  </div>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="Arrhythmogenic" value="Arrhythmogenic" id="Arrhythmogenic" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "PaternalGrandfather", "Arrhythmogenic")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.PaternalGrandfather ? this.state.familyHealthHistory.cardiomyopathy.PaternalGrandfather.find(x => x === "Arrhythmogenic") === "Arrhythmogenic" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Arrhythmogenic cardiomyopathy</h6>
                                    </label>
                                  </div>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="LeftVentricular" value="LeftVentricular" id="LeftVentricular" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "PaternalGrandfather", "LeftVentricular")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.PaternalGrandfather ? this.state.familyHealthHistory.cardiomyopathy.PaternalGrandfather.find(x => x === "LeftVentricular") === "LeftVentricular" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Left ventricular noncompaction cardiomyopathy</h6>
                                    </label>
                                  </div>
                                  <div className="pad-l-2rem ml-4">
                                    <input className="form-check-input" type="checkbox" name="FabryDisease" value="FabryDisease" id="FabryDisease" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomyopathy", "PaternalGrandfather", "FabryDisease")} defaultChecked={this.state.familyHealthHistory.cardiomyopathy.PaternalGrandfather ? this.state.familyHealthHistory.cardiomyopathy.PaternalGrandfather.find(x => x === "FabryDisease") === "FabryDisease" ? "checked" : "" : ""} />
                                    <label className="form-check-label mb-1" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Fabry disease</h6>
                                    </label>
                                  </div>


                                </div>
                                : "" : "" : ""} */}


                            </div>
                            : '' : ''}



                          <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                          <p className="roboto-bold-18px  " id="check fields from ConductionDisorder">Hereditary arrhythmia or conduction disorder.</p>
                          <div className=" d-flex">
                            <div className="form-check   mr-5">
                              <input className="form-check-input" type="radio" name="hereditaryArrhythmiaOrConductionDisorder" id="hereditaryArrhythmiaOrConductionDisorder"  value="yes" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "option")} checked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.value[0] === "yes" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="hereditaryArrhythmiaOrConductionDisorder">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.hereditaryArrhythmiaOrConductionDisorder ? "#ff0000" : "" }}>  Yes</h6>
                              </label>
                            </div>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="hereditaryArrhythmiaOrConductionDisorder" id="hereditaryArrhythmiaOrConductionDisorder"  value="no" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "option")} checked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.value[0] === "no" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="hereditaryArrhythmiaOrConductionDisorder">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.hereditaryArrhythmiaOrConductionDisorder ? "#ff0000" : "" }}>  No</h6>
                              </label>
                            </div>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="hereditaryArrhythmiaOrConductionDisorder" id="hereditaryArrhythmiaOrConductionDisorder"  value="may be" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "option")} checked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.value[0] === "may be" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="hereditaryArrhythmiaOrConductionDisorder">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.hereditaryArrhythmiaOrConductionDisorder ? "#ff0000" : "" }}>Maybe</h6>
                              </label>
                            </div>
                          </div>

                          {this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.value[0] === "yes" ?


                            <div>

                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="Mother" value="Mother" id="Mother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "member", "Mother")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member.find(x => x === "Mother") === "Mother" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Mother</h6>
                                </label>
                              </div>
                              {/* {this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member.find(x => x === "Mother") === "Mother" ?

                                <div style={{ marginLeft: '3.50rem' }}>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="LongQTsyndrome" value="LongQTsyndrome" id="LongQTsyndrome" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "Mother", "LongQTsyndrome")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.Mother ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.Mother.find(x => x === "LongQTsyndrome") === "LongQTsyndrome" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">  Long QT syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="ShortQTsyndrome" value="ShortQTsyndrome" id="ShortQTsyndrome" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "Mother", "ShortQTsyndrome")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.Mother ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.Mother.find(x => x === "ShortQTsyndrome") === "ShortQTsyndrome" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Short QT syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Brugada" value="Brugada" id="Brugada" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "Mother", "Brugada")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.Mother ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.Mother.find(x => x === "Brugada") === "Brugada" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Brugada syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Catecholaminergic" value="Catecholaminergic" id="Catecholaminergic" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "Mother", "Catecholaminergic")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.Mother ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.Mother.find(x => x === "Catecholaminergic") === "Catecholaminergic" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Catecholaminergic polymorphic ventricular tachycardia</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Bundle" value="Bundle" id="Bundle" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "Mother", "Bundle")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.Mother ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.Mother.find(x => x === "Bundle") === "Bundle" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Bundle branch block or heart block</h6>
                                    </label>
                                  </div>


                                </div>
                                : "" : "" : ""} */}


                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="Father" value="Father" id="Father" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "member", "Father")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member.find(x => x === "Father") === "Father" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Father</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="siblings" value="siblings" id="siblings" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "member", "siblings")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member.find(x => x === "siblings") === "siblings" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Siblings</h6>
                                </label>
                              </div>
                              {/* {this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member.find(x => x === "Father") === "Father" ?

                                <div style={{ marginLeft: '3.50rem' }}>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="LongQTsyndrome" value="LongQTsyndrome" id="LongQTsyndrome" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "Father", "LongQTsyndrome")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.Father ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.Father.find(x => x === "LongQTsyndrome") === "LongQTsyndrome" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">  Long QT syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="ShortQTsyndrome" value="ShortQTsyndrome" id="ShortQTsyndrome" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "Father", "ShortQTsyndrome")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.Father ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.Father.find(x => x === "ShortQTsyndrome") === "ShortQTsyndrome" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Short QT syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Brugada" value="Brugada" id="Brugada" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "Father", "Brugada")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.Father ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.Father.find(x => x === "Brugada") === "Brugada" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Brugada syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Catecholaminergic" value="Catecholaminergic" id="Catecholaminergic" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "Father", "Catecholaminergic")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.Father ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.Father.find(x => x === "Catecholaminergic") === "Catecholaminergic" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Catecholaminergic polymorphic ventricular tachycardia</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Bundle" value="Bundle" id="Bundle" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "Father", "Bundle")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.Father ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.Father.find(x => x === "Bundle") === "Bundle" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Bundle branch block or heart block</h6>
                                    </label>
                                  </div>


                                </div>
                                : "" : "" : ""} */}

                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="MaternalGrandmother" value="MaternalGrandmother" id="MaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "member", "MaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member.find(x => x === "MaternalGrandmother") === "MaternalGrandmother" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Maternal Grandmother</h6>
                                </label>
                              </div>
                              {/* {this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member.find(x => x === "MaternalGrandmother") === "MaternalGrandmother" ?

                                <div style={{ marginLeft: '3.50rem' }}>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="LongQTsyndrome" value="LongQTsyndrome" id="LongQTsyndrome" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "MaternalGrandmother", "LongQTsyndrome")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.MaternalGrandmother ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.MaternalGrandmother.find(x => x === "LongQTsyndrome") === "LongQTsyndrome" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">  Long QT syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="ShortQTsyndrome" value="ShortQTsyndrome" id="ShortQTsyndrome" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "MaternalGrandmother", "ShortQTsyndrome")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.MaternalGrandmother ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.MaternalGrandmother.find(x => x === "ShortQTsyndrome") === "ShortQTsyndrome" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Short QT syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Brugada" value="Brugada" id="Brugada" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "MaternalGrandmother", "Brugada")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.MaternalGrandmother ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.MaternalGrandmother.find(x => x === "Brugada") === "Brugada" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Brugada syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Catecholaminergic" value="Catecholaminergic" id="Catecholaminergic" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "MaternalGrandmother", "Catecholaminergic")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.MaternalGrandmother ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.MaternalGrandmother.find(x => x === "Catecholaminergic") === "Catecholaminergic" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Catecholaminergic polymorphic ventricular tachycardia</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Bundle" value="Bundle" id="Bundle" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "MaternalGrandmother", "Bundle")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.MaternalGrandmother ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.MaternalGrandmother.find(x => x === "Bundle") === "Bundle" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Bundle branch block or heart block</h6>
                                    </label>
                                  </div>


                                </div>
                                : "" : "" : ""} */}

                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="MaternalGrandfather" value="MaternalGrandfather" id="MaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "member", "MaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member.find(x => x === "MaternalGrandfather") === "MaternalGrandfather" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Maternal Grandfather</h6>
                                </label>
                              </div>
                              {/* {this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member.find(x => x === "MaternalGrandfather") === "MaternalGrandfather" ?

                                <div style={{ marginLeft: '3.50rem' }}>

                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="LongQTsyndrome" value="LongQTsyndrome" id="LongQTsyndrome" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "MaternalGrandfather", "LongQTsyndrome")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.MaternalGrandfather ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.MaternalGrandfather.find(x => x === "LongQTsyndrome") === "LongQTsyndrome" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">  Long QT syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="ShortQTsyndrome" value="ShortQTsyndrome" id="ShortQTsyndrome" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "MaternalGrandfather", "ShortQTsyndrome")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.MaternalGrandfather ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.MaternalGrandfather.find(x => x === "ShortQTsyndrome") === "ShortQTsyndrome" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Short QT syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Brugada" value="Brugada" id="Brugada" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "MaternalGrandfather", "Brugada")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.MaternalGrandfather ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.MaternalGrandfather.find(x => x === "Brugada") === "Brugada" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Brugada syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Catecholaminergic" value="Catecholaminergic" id="Catecholaminergic" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "MaternalGrandfather", "Catecholaminergic")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.MaternalGrandfather ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.MaternalGrandfather.find(x => x === "Catecholaminergic") === "Catecholaminergic" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Catecholaminergic polymorphic ventricular tachycardia</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Bundle" value="Bundle" id="Bundle" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "MaternalGrandfather", "Bundle")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.MaternalGrandfather ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.MaternalGrandfather.find(x => x === "Bundle") === "Bundle" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Bundle branch block or heart block</h6>
                                    </label>
                                  </div>

                                </div>
                                : "" : "" : ""} */}

                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="PaternalGrandmother" value="PaternalGrandmother" id="PaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "member", "PaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member.find(x => x === "PaternalGrandmother") === "PaternalGrandmother" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Paternal Grandmother</h6>
                                </label>
                              </div>
                              {/* {this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member.find(x => x === "PaternalGrandmother") === "PaternalGrandmother" ?

                                <div style={{ marginLeft: '3.50rem' }}>

                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="LongQTsyndrome" value="LongQTsyndrome" id="LongQTsyndrome" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "PaternalGrandmother", "LongQTsyndrome")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.PaternalGrandmother ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.PaternalGrandmother.find(x => x === "LongQTsyndrome") === "LongQTsyndrome" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">  Long QT syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="ShortQTsyndrome" value="ShortQTsyndrome" id="ShortQTsyndrome" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "PaternalGrandmother", "ShortQTsyndrome")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.PaternalGrandmother ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.PaternalGrandmother.find(x => x === "ShortQTsyndrome") === "ShortQTsyndrome" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Short QT syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Brugada" value="Brugada" id="Brugada" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "PaternalGrandmother", "Brugada")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.PaternalGrandmother ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.PaternalGrandmother.find(x => x === "Brugada") === "Brugada" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Brugada syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Catecholaminergic" value="Catecholaminergic" id="Catecholaminergic" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "PaternalGrandmother", "Catecholaminergic")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.PaternalGrandmother ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.PaternalGrandmother.find(x => x === "Catecholaminergic") === "Catecholaminergic" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Catecholaminergic polymorphic ventricular tachycardia</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Bundle" value="Bundle" id="Bundle" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "PaternalGrandmother", "Bundle")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.PaternalGrandmother ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.PaternalGrandmother.find(x => x === "Bundle") === "Bundle" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Bundle branch block or heart block</h6>
                                    </label>
                                  </div>

                                </div>
                                : "" : "" : ""} */}

                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="PaternalGrandfather" value="PaternalGrandfather" id="PaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "member", "PaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member.find(x => x === "PaternalGrandfather") === "PaternalGrandfather" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Paternal Grandfather</h6>
                                </label>
                              </div>
                              {/* {this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.member.find(x => x === "PaternalGrandfather") === "PaternalGrandfather" ?

                                <div style={{ marginLeft: '3.50rem' }}>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="LongQTsyndrome" value="LongQTsyndrome" id="LongQTsyndrome" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "PaternalGrandfather", "LongQTsyndrome")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.PaternalGrandfather ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.PaternalGrandfather.find(x => x === "LongQTsyndrome") === "LongQTsyndrome" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">  Long QT syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="ShortQTsyndrome" value="ShortQTsyndrome" id="ShortQTsyndrome" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "PaternalGrandfather", "ShortQTsyndrome")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.PaternalGrandfather ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.PaternalGrandfather.find(x => x === "ShortQTsyndrome") === "ShortQTsyndrome" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Short QT syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Brugada" value="Brugada" id="Brugada" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "PaternalGrandfather", "Brugada")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.PaternalGrandfather ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.PaternalGrandfather.find(x => x === "Brugada") === "Brugada" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Brugada syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Catecholaminergic" value="Catecholaminergic" id="Catecholaminergic" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "PaternalGrandfather", "Catecholaminergic")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.PaternalGrandfather ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.PaternalGrandfather.find(x => x === "Catecholaminergic") === "Catecholaminergic" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Catecholaminergic polymorphic ventricular tachycardia</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Bundle" value="Bundle" id="Bundle" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryArrhythmiaOrConductionDisorder", "PaternalGrandfather", "Bundle")} defaultChecked={this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.PaternalGrandfather ? this.state.familyHealthHistory.hereditaryArrhythmiaOrConductionDisorder.PaternalGrandfather.find(x => x === "Bundle") === "Bundle" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Bundle branch block or heart block</h6>
                                    </label>
                                  </div>


                                </div>
                                : "" : "" : ""} */}


                            </div>
                            : '' : ''}


                          <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                          <p className="roboto-bold-18px  " id="check fields from arteriopathyConnectiveTissueDisorder">Arteriopathy/connective tissue disorder</p>
                          <div className=" d-flex">
                            <div className="form-check   mr-5">
                              <input className="form-check-input" type="radio" name="arteriopathyConnectiveTissueDisorder" id="arteriopathyConnectiveTissueDisorder"  value="yes" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "option")} checked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.value[0] === "yes" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="arteriopathyConnectiveTissueDisorder">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.arteriopathyConnectiveTissueDisorder ? "#ff0000" : "" }}>  Yes</h6>
                              </label>
                            </div>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="arteriopathyConnectiveTissueDisorder" id="arteriopathyConnectiveTissueDisorder"  value="no" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "option")} checked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.value[0] === "no" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="arteriopathyConnectiveTissueDisorder">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.arteriopathyConnectiveTissueDisorder ? "#ff0000" : "" }}>  No</h6>
                              </label>
                            </div>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="arteriopathyConnectiveTissueDisorder" id="arteriopathyConnectiveTissueDisorder"  value="may be" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "option")} checked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.value[0] === "may be" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="arteriopathyConnectiveTissueDisorder">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.arteriopathyConnectiveTissueDisorder ? "#ff0000" : "" }}>Maybe</h6>
                              </label>
                            </div>
                          </div>

                          {this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.value[0] === "yes" ?


                            <div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="Mother" value="Mother" id="Mother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "member", "Mother")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member.find(x => x === "Mother") === "Mother" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Mother</h6>
                                </label>
                              </div>
                              {/* {this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member.find(x => x === "Mother") === "Mother" ?

                                <div style={{ marginLeft: '3.50rem' }}>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="LongQTsyndrome" value="LongQTsyndrome" id="LongQTsyndrome" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "Mother", "LongQTsyndrome")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.Mother ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.Mother.find(x => x === "LongQTsyndrome") === "LongQTsyndrome" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">  Long QT syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="ShortQTsyndrome" value="ShortQTsyndrome" id="ShortQTsyndrome" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "Mother", "ShortQTsyndrome")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.Mother ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.Mother.find(x => x === "ShortQTsyndrome") === "ShortQTsyndrome" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Short QT syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Brugada" value="Brugada" id="Brugada" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "Mother", "Brugada")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.Mother ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.Mother.find(x => x === "Brugada") === "Brugada" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Brugada syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Catecholaminergic" value="Catecholaminergic" id="Catecholaminergic" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "Mother", "Catecholaminergic")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.Mother ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.Mother.find(x => x === "Catecholaminergic") === "Catecholaminergic" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Catecholaminergic polymorphic ventricular tachycardia</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Bundle" value="Bundle" id="Bundle" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "Mother", "Bundle")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.Mother ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.Mother.find(x => x === "Bundle") === "Bundle" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Bundle branch block or heart block</h6>
                                    </label>
                                  </div>


                                </div>
                                : "" : "" : ""} */}


                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="Father" value="Father" id="Father" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "member", "Father")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member.find(x => x === "Father") === "Father" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Father</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="siblings" value="siblings" id="siblings" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "member", "siblings")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member.find(x => x === "siblings") === "siblings" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Siblings</h6>
                                </label>
                              </div>
                              {/* {this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member.find(x => x === "Father") === "Father" ?

                                <div style={{ marginLeft: '3.50rem' }}>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="LongQTsyndrome" value="LongQTsyndrome" id="LongQTsyndrome" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "Father", "LongQTsyndrome")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.Father ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.Father.find(x => x === "LongQTsyndrome") === "LongQTsyndrome" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">  Long QT syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="ShortQTsyndrome" value="ShortQTsyndrome" id="ShortQTsyndrome" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "Father", "ShortQTsyndrome")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.Father ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.Father.find(x => x === "ShortQTsyndrome") === "ShortQTsyndrome" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Short QT syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Brugada" value="Brugada" id="Brugada" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "Father", "Brugada")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.Father ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.Father.find(x => x === "Brugada") === "Brugada" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Brugada syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Catecholaminergic" value="Catecholaminergic" id="Catecholaminergic" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "Father", "Catecholaminergic")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.Father ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.Father.find(x => x === "Catecholaminergic") === "Catecholaminergic" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Catecholaminergic polymorphic ventricular tachycardia</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Bundle" value="Bundle" id="Bundle" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "Father", "Bundle")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.Father ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.Father.find(x => x === "Bundle") === "Bundle" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Bundle branch block or heart block</h6>
                                    </label>
                                  </div>


                                </div>
                                : "" : "" : ""} */}

                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="MaternalGrandmother" value="MaternalGrandmother" id="MaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "member", "MaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member.find(x => x === "MaternalGrandmother") === "MaternalGrandmother" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Maternal Grandmother</h6>
                                </label>
                              </div>
                              {/* {this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member.find(x => x === "MaternalGrandmother") === "MaternalGrandmother" ?

                                <div style={{ marginLeft: '3.50rem' }}>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="LongQTsyndrome" value="LongQTsyndrome" id="LongQTsyndrome" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "MaternalGrandmother", "LongQTsyndrome")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.MaternalGrandmother ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.MaternalGrandmother.find(x => x === "LongQTsyndrome") === "LongQTsyndrome" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">  Long QT syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="ShortQTsyndrome" value="ShortQTsyndrome" id="ShortQTsyndrome" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "MaternalGrandmother", "ShortQTsyndrome")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.MaternalGrandmother ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.MaternalGrandmother.find(x => x === "ShortQTsyndrome") === "ShortQTsyndrome" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Short QT syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Brugada" value="Brugada" id="Brugada" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "MaternalGrandmother", "Brugada")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.MaternalGrandmother ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.MaternalGrandmother.find(x => x === "Brugada") === "Brugada" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Brugada syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Catecholaminergic" value="Catecholaminergic" id="Catecholaminergic" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "MaternalGrandmother", "Catecholaminergic")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.MaternalGrandmother ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.MaternalGrandmother.find(x => x === "Catecholaminergic") === "Catecholaminergic" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Catecholaminergic polymorphic ventricular tachycardia</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Bundle" value="Bundle" id="Bundle" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "MaternalGrandmother", "Bundle")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.MaternalGrandmother ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.MaternalGrandmother.find(x => x === "Bundle") === "Bundle" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Bundle branch block or heart block</h6>
                                    </label>
                                  </div>


                                </div>
                                : "" : "" : ""} */}

                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="MaternalGrandfather" value="MaternalGrandfather" id="MaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "member", "MaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member.find(x => x === "MaternalGrandfather") === "MaternalGrandfather" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Maternal Grandfather</h6>
                                </label>
                              </div>
                              {/* {this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member.find(x => x === "MaternalGrandfather") === "MaternalGrandfather" ?

                                <div style={{ marginLeft: '3.50rem' }}>

                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="LongQTsyndrome" value="LongQTsyndrome" id="LongQTsyndrome" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "MaternalGrandfather", "LongQTsyndrome")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.MaternalGrandfather ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.MaternalGrandfather.find(x => x === "LongQTsyndrome") === "LongQTsyndrome" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">  Long QT syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="ShortQTsyndrome" value="ShortQTsyndrome" id="ShortQTsyndrome" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "MaternalGrandfather", "ShortQTsyndrome")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.MaternalGrandfather ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.MaternalGrandfather.find(x => x === "ShortQTsyndrome") === "ShortQTsyndrome" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Short QT syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Brugada" value="Brugada" id="Brugada" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "MaternalGrandfather", "Brugada")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.MaternalGrandfather ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.MaternalGrandfather.find(x => x === "Brugada") === "Brugada" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Brugada syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Catecholaminergic" value="Catecholaminergic" id="Catecholaminergic" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "MaternalGrandfather", "Catecholaminergic")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.MaternalGrandfather ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.MaternalGrandfather.find(x => x === "Catecholaminergic") === "Catecholaminergic" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Catecholaminergic polymorphic ventricular tachycardia</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Bundle" value="Bundle" id="Bundle" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "MaternalGrandfather", "Bundle")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.MaternalGrandfather ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.MaternalGrandfather.find(x => x === "Bundle") === "Bundle" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Bundle branch block or heart block</h6>
                                    </label>
                                  </div>

                                </div>
                                : "" : "" : ""} */}

                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="PaternalGrandmother" value="PaternalGrandmother" id="PaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "member", "PaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member.find(x => x === "PaternalGrandmother") === "PaternalGrandmother" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Paternal Grandmother</h6>
                                </label>
                              </div>
                              {/* {this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member.find(x => x === "PaternalGrandmother") === "PaternalGrandmother" ?

                                <div style={{ marginLeft: '3.50rem' }}>

                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="LongQTsyndrome" value="LongQTsyndrome" id="LongQTsyndrome" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "PaternalGrandmother", "LongQTsyndrome")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.PaternalGrandmother ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.PaternalGrandmother.find(x => x === "LongQTsyndrome") === "LongQTsyndrome" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">  Long QT syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="ShortQTsyndrome" value="ShortQTsyndrome" id="ShortQTsyndrome" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "PaternalGrandmother", "ShortQTsyndrome")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.PaternalGrandmother ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.PaternalGrandmother.find(x => x === "ShortQTsyndrome") === "ShortQTsyndrome" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Short QT syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Brugada" value="Brugada" id="Brugada" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "PaternalGrandmother", "Brugada")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.PaternalGrandmother ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.PaternalGrandmother.find(x => x === "Brugada") === "Brugada" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Brugada syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Catecholaminergic" value="Catecholaminergic" id="Catecholaminergic" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "PaternalGrandmother", "Catecholaminergic")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.PaternalGrandmother ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.PaternalGrandmother.find(x => x === "Catecholaminergic") === "Catecholaminergic" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Catecholaminergic polymorphic ventricular tachycardia</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Bundle" value="Bundle" id="Bundle" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "PaternalGrandmother", "Bundle")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.PaternalGrandmother ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.PaternalGrandmother.find(x => x === "Bundle") === "Bundle" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Bundle branch block or heart block</h6>
                                    </label>
                                  </div>

                                </div>
                                : "" : "" : ""} */}

                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="PaternalGrandfather" value="PaternalGrandfather" id="PaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "member", "PaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member.find(x => x === "PaternalGrandfather") === "PaternalGrandfather" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Paternal Grandfather</h6>
                                </label>
                              </div>
                              {/* {this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.member.find(x => x === "PaternalGrandfather") === "PaternalGrandfather" ?

                                <div style={{ marginLeft: '3.50rem' }}>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="LongQTsyndrome" value="LongQTsyndrome" id="LongQTsyndrome" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "PaternalGrandfather", "LongQTsyndrome")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.PaternalGrandfather ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.PaternalGrandfather.find(x => x === "LongQTsyndrome") === "LongQTsyndrome" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">  Long QT syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="ShortQTsyndrome" value="ShortQTsyndrome" id="ShortQTsyndrome" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "PaternalGrandfather", "ShortQTsyndrome")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.PaternalGrandfather ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.PaternalGrandfather.find(x => x === "ShortQTsyndrome") === "ShortQTsyndrome" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Short QT syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Brugada" value="Brugada" id="Brugada" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "PaternalGrandfather", "Brugada")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.PaternalGrandfather ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.PaternalGrandfather.find(x => x === "Brugada") === "Brugada" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Brugada syndrome</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Catecholaminergic" value="Catecholaminergic" id="Catecholaminergic" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "PaternalGrandfather", "Catecholaminergic")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.PaternalGrandfather ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.PaternalGrandfather.find(x => x === "Catecholaminergic") === "Catecholaminergic" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Catecholaminergic polymorphic ventricular tachycardia</h6>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Bundle" value="Bundle" id="Bundle" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arteriopathyConnectiveTissueDisorder", "PaternalGrandfather", "Bundle")} defaultChecked={this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.PaternalGrandfather ? this.state.familyHealthHistory.arteriopathyConnectiveTissueDisorder.PaternalGrandfather.find(x => x === "Bundle") === "Bundle" ? "checked" : "" : ""} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                      <h6 className="reg-18">Bundle branch block or heart block</h6>
                                    </label>
                                  </div>


                                </div>
                                : "" : "" : ""} */}

                            </div>
                            : '' : ''}
                          <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                          {/* eof */}
                          <p className="roboto-bold-18px  " id="check fields from heartfailure">Heart failure</p>
                          <div className=" d-flex">
                            <div className="form-check   mr-5">
                              <input className="form-check-input" type="radio" name="heartfailure" id="heartfailure"  value="yes" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "heartfailure", "option")} checked={this.state.familyHealthHistory.heartfailure ? this.state.familyHealthHistory.heartfailure.value[0] === "yes" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="heartfailure">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.heartfailure ? "#ff0000" : "" }}>  Yes</h6>
                              </label>
                            </div>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="heartfailure" id="heartfailure"  value="no" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "heartfailure", "option")} checked={this.state.familyHealthHistory.heartfailure ? this.state.familyHealthHistory.heartfailure.value[0] === "no" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="heartfailure">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.heartfailure ? "#ff0000" : "" }}>  No</h6>
                              </label>
                            </div>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="heartfailure" id="heartfailure"  value="may be" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "heartfailure", "option")} checked={this.state.familyHealthHistory.heartfailure ? this.state.familyHealthHistory.heartfailure.value[0] === "may be" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="heartfailure">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.heartfailure ? "#ff0000" : "" }}>Maybe</h6>
                              </label>
                            </div>
                          </div>
                          {this.state.familyHealthHistory.heartfailure ? this.state.familyHealthHistory.heartfailure.value[0] === "yes" ?
                            <div>
                              <div className="form-check" id="check fields from motherAge heartfailure">
                                <input className="form-check-input" type="checkbox" name="Mother" value="Mother" id="Mother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "heartfailure", "member", "Mother")} defaultChecked={this.state.familyHealthHistory.heartfailure.member ? this.state.familyHealthHistory.heartfailure.member.find(x => x === "Mother") === "Mother" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Mother</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.heartfailure ? this.state.familyHealthHistory.heartfailure.member ? this.state.familyHealthHistory.heartfailure.member.find(x => x === "Mother") === "Mother" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was she?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="motherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureMotherAge(e, "heartfailure", "motherAge")} value={this.state.familyHealthHistory.heartfailure.motherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}

                              <div className="form-check" id="check fields from fatherAge heartfailure">
                                <input className="form-check-input" type="checkbox" name="Father" value="Father" id="Father" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "heartfailure", "member", "Father")} defaultChecked={this.state.familyHealthHistory.heartfailure.member ? this.state.familyHealthHistory.heartfailure.member.find(x => x === "Father") === "Father" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Father</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.heartfailure ? this.state.familyHealthHistory.heartfailure.member ? this.state.familyHealthHistory.heartfailure.member.find(x => x === "Father") === "Father" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was he?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="fatherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureFatherAge(e, "heartfailure", "fatherAge")} value={this.state.familyHealthHistory.heartfailure.fatherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}


                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="Siblings" value="Siblings" id="Siblings" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "heartfailure", "member", "Siblings")} defaultChecked={this.state.familyHealthHistory.heartfailure.member ? this.state.familyHealthHistory.heartfailure.member.find(x => x === "Siblings") === "Siblings" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Siblings</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.heartfailure ? this.state.familyHealthHistory.heartfailure.member ? this.state.familyHealthHistory.heartfailure.member.find(x => x === "Siblings") === "Siblings" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was he/she?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="SiblingsAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureSiblingsAge(e, "heartfailure", "SiblingsAge")} value={this.state.familyHealthHistory.heartfailure.SiblingsAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}


                              <div className="form-check" id="check fields from MaternalGrandmotherAge heartfailure">
                                <input className="form-check-input" type="checkbox" name="MaternalGrandmother" value="MaternalGrandmother" id="MaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "heartfailure", "member", "MaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.heartfailure.member ? this.state.familyHealthHistory.heartfailure.member.find(x => x === "MaternalGrandmother") === "MaternalGrandmother" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">MaternalGrandmother</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.heartfailure ? this.state.familyHealthHistory.heartfailure.member ? this.state.familyHealthHistory.heartfailure.member.find(x => x === "MaternalGrandmother") === "MaternalGrandmother" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was she?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="MaternalGrandmotherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureMaternalGrandmotherAge(e, "heartfailure", "MaternalGrandmotherAge")} value={this.state.familyHealthHistory.heartfailure.MaternalGrandmotherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}


                              <div className="form-check" id="check fields from MaternalGrandfatherAge heartfailure">
                                <input className="form-check-input" type="checkbox" name="MaternalGrandfather" value="MaternalGrandfather" id="MaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "heartfailure", "member", "MaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.heartfailure.member ? this.state.familyHealthHistory.heartfailure.member.find(x => x === "MaternalGrandfather") === "MaternalGrandfather" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">MaternalGrandfather</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.heartfailure ? this.state.familyHealthHistory.heartfailure.member ? this.state.familyHealthHistory.heartfailure.member.find(x => x === "MaternalGrandfather") === "MaternalGrandfather" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was she?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="MaternalGrandfatherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureMaternalGrandfatherAge(e, "heartfailure", "MaternalGrandfatherAge")} value={this.state.familyHealthHistory.heartfailure.MaternalGrandfatherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}



                              <div className="form-check" id="check fields from PaternalGrandmotherAge heartfailure">
                                <input className="form-check-input" type="checkbox" name="PaternalGrandmother" value="PaternalGrandmother" id="PaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "heartfailure", "member", "PaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.heartfailure.member ? this.state.familyHealthHistory.heartfailure.member.find(x => x === "PaternalGrandmother") === "PaternalGrandmother" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">PaternalGrandmother</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.heartfailure ? this.state.familyHealthHistory.heartfailure.member ? this.state.familyHealthHistory.heartfailure.member.find(x => x === "PaternalGrandmother") === "PaternalGrandmother" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was she?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="PaternalGrandmotherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailurePaternalGrandmotherAge(e, "heartfailure", "PaternalGrandmotherAge")} value={this.state.familyHealthHistory.heartfailure.PaternalGrandmotherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}


                              <div className="form-check" id="check fields from PaternalGrandfatherAge heartfailure">
                                <input className="form-check-input" type="checkbox" name="PaternalGrandfather" value="PaternalGrandfather" id="PaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "heartfailure", "member", "PaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.heartfailure.member ? this.state.familyHealthHistory.heartfailure.member.find(x => x === "PaternalGrandfather") === "PaternalGrandfather" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">PaternalGrandfather</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.heartfailure ? this.state.familyHealthHistory.heartfailure.member ? this.state.familyHealthHistory.heartfailure.member.find(x => x === "PaternalGrandfather") === "PaternalGrandfather" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was she?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="PaternalGrandfatherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailurePaternalGrandfatherAge(e, "heartfailure", "PaternalGrandfatherAge")} value={this.state.familyHealthHistory.heartfailure.PaternalGrandfatherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}



                            </div> : '' : ''}



                          <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                          {/* eof */}
                          <p className="roboto-bold-18px" id="check fields from Enlarged heart ">Enlarged heart (cardiomegalyFamilyHealthHistory)</p>
                          <div className=" d-flex" style={{ marginLeft: '-1.012rem' }}>
                            <div className="form-check pad-l-2rem  mr-5">
                              <input className="form-check-input" type="radio" name="cardiomegalyFamilyHealthHistory" id="cardiomegalyFamilyHealthHistory"  value="yes" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "cardiomegalyFamilyHealthHistory", "option")} checked={this.state.familyHealthHistory.cardiomegalyFamilyHealthHistory ? this.state.familyHealthHistory.cardiomegalyFamilyHealthHistory.value[0] === "yes" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="cardiomegalyFamilyHealthHistory">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.cardiomegalyFamilyHealthHistory ? "#ff0000" : "" }}>  Yes</h6>
                              </label>
                            </div>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="cardiomegalyFamilyHealthHistory" id="cardiomegalyFamilyHealthHistory"  value="no" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "cardiomegalyFamilyHealthHistory", "option")} checked={this.state.familyHealthHistory.cardiomegalyFamilyHealthHistory ? this.state.familyHealthHistory.cardiomegalyFamilyHealthHistory.value[0] === "no" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="cardiomegalyFamilyHealthHistory">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.cardiomegalyFamilyHealthHistory ? "#ff0000" : "" }}>  No</h6>
                              </label>
                            </div>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="cardiomegalyFamilyHealthHistory" id="cardiomegalyFamilyHealthHistory"  value="may be" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "cardiomegalyFamilyHealthHistory", "option")} checked={this.state.familyHealthHistory.cardiomegalyFamilyHealthHistory ? this.state.familyHealthHistory.cardiomegalyFamilyHealthHistory.value[0] === "may be" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="cardiomegalyFamilyHealthHistory">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.cardiomegalyFamilyHealthHistory ? "#ff0000" : "" }}>Maybe</h6>
                              </label>
                            </div>
                          </div>
                          {this.state.familyHealthHistory.cardiomegalyFamilyHealthHistory ? this.state.familyHealthHistory.cardiomegalyFamilyHealthHistory.value[0] === "yes" ?

                            <div >
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="mother" value="mother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomegalyFamilyHealthHistory", "members", "mother")} defaultChecked={this.state.familyHealthHistory.cardiomegalyFamilyHealthHistory.members ? this.state.familyHealthHistory.cardiomegalyFamilyHealthHistory.members.find(x => x === "mother") === "mother" ? "checked" : "" : ""} />
                                <label className="form-check-label" htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Mother</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="father" value="father" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomegalyFamilyHealthHistory", "members", "father")} defaultChecked={this.state.familyHealthHistory.cardiomegalyFamilyHealthHistory.members ? this.state.familyHealthHistory.cardiomegalyFamilyHealthHistory.members.find(x => x === "father") === "father" ? "checked" : "" : ""} />
                                <label className="form-check-label" htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Father</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="siblings" value="siblings" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomegalyFamilyHealthHistory", "members", "siblings")} defaultChecked={this.state.familyHealthHistory.cardiomegalyFamilyHealthHistory.members ? this.state.familyHealthHistory.cardiomegalyFamilyHealthHistory.members.find(x => x === "siblings") === "siblings" ? "checked" : "" : ""} />
                                <label className="form-check-label" htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Siblings</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="MaternalGrandmother" value="MaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomegalyFamilyHealthHistory", "members", "MaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.cardiomegalyFamilyHealthHistory.members ? this.state.familyHealthHistory.cardiomegalyFamilyHealthHistory.members.find(x => x === "MaternalGrandmother") === "MaternalGrandmother" ? "checked" : "" : ""} />
                                <label className="form-check-label" htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Maternal Grandmother </h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="MaternalGrandfather" value="MaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomegalyFamilyHealthHistory", "members", "MaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.cardiomegalyFamilyHealthHistory.members ? this.state.familyHealthHistory.cardiomegalyFamilyHealthHistory.members.find(x => x === "MaternalGrandfather") === "MaternalGrandfather" ? "checked" : "" : ""} />
                                <label className="form-check-label" htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Maternal Grandfather</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="PaternalGrandmother" value="PaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomegalyFamilyHealthHistory", "members", "PaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.cardiomegalyFamilyHealthHistory.members ? this.state.familyHealthHistory.cardiomegalyFamilyHealthHistory.members.find(x => x === "PaternalGrandmother") === "PaternalGrandmother" ? "checked" : "" : ""} />
                                <label className="form-check-label" htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Paternal Grandmother</h6>
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="PaternalGrandfather" value="PaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "cardiomegalyFamilyHealthHistory", "members", "PaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.cardiomegalyFamilyHealthHistory.members ? this.state.familyHealthHistory.cardiomegalyFamilyHealthHistory.members.find(x => x === "PaternalGrandfather") === "PaternalGrandfather" ? "checked" : "" : ""} />
                                <label className="form-check-label" htmlFor="defaultCheck1">
                                  <h6 className="reg-18">Paternal Grandfather</h6>
                                </label>
                              </div>
                            </div>
                            : '' : ''}

                          <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />

                          {/* ---------------eof----------------- */}
                          <h3 className="roboto-black-36px  pl-2 " style={{ marginLeft: '-1.012rem' }}>Has anyone in your family experienced:</h3>
                          <p className="roboto-bold-18px  " id="check fields from suddenCardiac ">Sudden cardiac arrest or sudden death</p>
                          <div className=" d-flex">
                            <div className="form-check   mr-5">
                              <input className="form-check-input" type="radio" name="suddenCardiac" id="suddenCardiac"  value="yes" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "suddenCardiac", "option")} checked={this.state.familyHealthHistory.suddenCardiac ? this.state.familyHealthHistory.suddenCardiac.value[0] === "yes" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="suddenCardiac">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.suddenCardiac ? "#ff0000" : "" }}>  Yes</h6>
                              </label>

                            </div>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="suddenCardiac" id="suddenCardiac"  value="no" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "suddenCardiac", "option")} checked={this.state.familyHealthHistory.suddenCardiac ? this.state.familyHealthHistory.suddenCardiac.value[0] === "no" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="suddenCardiac">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.suddenCardiac ? "#ff0000" : "" }}>  No</h6>
                              </label>

                            </div>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="suddenCardiac" id="suddenCardiac"  value="may be" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "suddenCardiac", "option")} checked={this.state.familyHealthHistory.suddenCardiac ? this.state.familyHealthHistory.suddenCardiac.value[0] === "may be" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="suddenCardiac">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.suddenCardiac ? "#ff0000" : "" }}>Maybe</h6>
                              </label>
                            </div>
                          </div>
                          {this.state.familyHealthHistory.suddenCardiac ? this.state.familyHealthHistory.suddenCardiac.value[0] === "yes" ?
                            <div>
                              <div className="form-check" id="check fields from motherAge suddenCardiac ">
                                <input className="form-check-input" type="checkbox" name="Mother" value="Mother" id="Mother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "suddenCardiac", "member", "Mother")} defaultChecked={this.state.familyHealthHistory.suddenCardiac.member ? this.state.familyHealthHistory.suddenCardiac.member.find(x => x === "Mother") === "Mother" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Mother</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.suddenCardiac ? this.state.familyHealthHistory.suddenCardiac.member ? this.state.familyHealthHistory.suddenCardiac.member.find(x => x === "Mother") === "Mother" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was she?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="motherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureMotherAge(e, "suddenCardiac", "motherAge")} value={this.state.familyHealthHistory.suddenCardiac.motherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}

                              <div className="form-check" id="check fields from fatherAge suddenCardiac ">
                                <input className="form-check-input" type="checkbox" name="Father" value="Father" id="Father" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "suddenCardiac", "member", "Father")} defaultChecked={this.state.familyHealthHistory.suddenCardiac.member ? this.state.familyHealthHistory.suddenCardiac.member.find(x => x === "Father") === "Father" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Father</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.suddenCardiac ? this.state.familyHealthHistory.suddenCardiac.member ? this.state.familyHealthHistory.suddenCardiac.member.find(x => x === "Father") === "Father" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was he?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="fatherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureFatherAge(e, "suddenCardiac", "fatherAge")} value={this.state.familyHealthHistory.suddenCardiac.fatherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}

                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="siblings" value="siblings" id="siblings" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "suddenCardiac", "member", "siblings")} defaultChecked={this.state.familyHealthHistory.suddenCardiac.member ? this.state.familyHealthHistory.suddenCardiac.member.find(x => x === "siblings") === "siblings" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Siblings</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.suddenCardiac ? this.state.familyHealthHistory.suddenCardiac.member ? this.state.familyHealthHistory.suddenCardiac.member.find(x => x === "siblings") === "siblings" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was he/she?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="siblingsAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureSiblingsAge(e, "suddenCardiac", "siblingsAge")} value={this.state.familyHealthHistory.suddenCardiac.siblingsAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}


                              <div className="form-check" id="check fields from MaternalGrandmotherAge suddenCardiac ">
                                <input className="form-check-input" type="checkbox" name="MaternalGrandmother" value="MaternalGrandmother" id="MaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "suddenCardiac", "member", "MaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.suddenCardiac.member ? this.state.familyHealthHistory.suddenCardiac.member.find(x => x === "MaternalGrandmother") === "MaternalGrandmother" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">MaternalGrandmother</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.suddenCardiac ? this.state.familyHealthHistory.suddenCardiac.member ? this.state.familyHealthHistory.suddenCardiac.member.find(x => x === "MaternalGrandmother") === "MaternalGrandmother" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was she?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="MaternalGrandmotherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureMaternalGrandmotherAge(e, "suddenCardiac", "MaternalGrandmotherAge")} value={this.state.familyHealthHistory.suddenCardiac.MaternalGrandmotherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}


                              <div className="form-check" id="check fields from MaternalGrandfatherAge suddenCardiac ">
                                <input className="form-check-input" type="checkbox" name="MaternalGrandfather" value="MaternalGrandfather" id="MaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "suddenCardiac", "member", "MaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.suddenCardiac.member ? this.state.familyHealthHistory.suddenCardiac.member.find(x => x === "MaternalGrandfather") === "MaternalGrandfather" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">MaternalGrandfather</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.suddenCardiac ? this.state.familyHealthHistory.suddenCardiac.member ? this.state.familyHealthHistory.suddenCardiac.member.find(x => x === "MaternalGrandfather") === "MaternalGrandfather" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was he?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="MaternalGrandfatherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureMaternalGrandfatherAge(e, "suddenCardiac", "MaternalGrandfatherAge")} value={this.state.familyHealthHistory.suddenCardiac.MaternalGrandfatherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}



                              <div className="form-check" id="check fields from PaternalGrandmotherAge suddenCardiac ">
                                <input className="form-check-input" type="checkbox" name="PaternalGrandmother" value="PaternalGrandmother" id="PaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "suddenCardiac", "member", "PaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.suddenCardiac.member ? this.state.familyHealthHistory.suddenCardiac.member.find(x => x === "PaternalGrandmother") === "PaternalGrandmother" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">PaternalGrandmother</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.suddenCardiac ? this.state.familyHealthHistory.suddenCardiac.member ? this.state.familyHealthHistory.suddenCardiac.member.find(x => x === "PaternalGrandmother") === "PaternalGrandmother" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was she?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="PaternalGrandmotherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailurePaternalGrandmotherAge(e, "suddenCardiac", "PaternalGrandmotherAge")} value={this.state.familyHealthHistory.suddenCardiac.PaternalGrandmotherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}


                              <div className="form-check" id="check fields from PaternalGrandfatherAge suddenCardiac ">
                                <input className="form-check-input" type="checkbox" name="PaternalGrandfather" value="PaternalGrandfather" id="PaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "suddenCardiac", "member", "PaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.suddenCardiac.member ? this.state.familyHealthHistory.suddenCardiac.member.find(x => x === "PaternalGrandfather") === "PaternalGrandfather" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">PaternalGrandfather</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.suddenCardiac ? this.state.familyHealthHistory.suddenCardiac.member ? this.state.familyHealthHistory.suddenCardiac.member.find(x => x === "PaternalGrandfather") === "PaternalGrandfather" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was he?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="PaternalGrandfatherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailurePaternalGrandfatherAge(e, "suddenCardiac", "PaternalGrandfatherAge")} value={this.state.familyHealthHistory.suddenCardiac.PaternalGrandfatherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}



                            </div> : '' : ''}
                        </div>
                        <div className="container">
                          <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                        </div>
                        <p className="roboto-bold-18px  pad-l-2rem " id="check fields from heartAttack ">Heart attack</p>
                        <div className="container pad-l-2rem">
                          <div className="d-flex">
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="heartAttack" id="heartAttack"  value="yes" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "heartAttack", "option")} checked={this.state.familyHealthHistory.heartAttack ? this.state.familyHealthHistory.heartAttack.value[0] === "yes" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="heartAttack">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.heartAttack ? "#ff0000" : "" }}>  Yes</h6>
                              </label>
                            </div>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="heartAttack" id="heartAttack"  value="no" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "heartAttack", "option")} checked={this.state.familyHealthHistory.heartAttack ? this.state.familyHealthHistory.heartAttack.value[0] === "no" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="heartAttack">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.heartAttack ? "#ff0000" : "" }}>  No</h6>
                              </label>
                            </div>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="heartAttack" id="heartAttack"  value="may be" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "heartAttack", "option")} checked={this.state.familyHealthHistory.heartAttack ? this.state.familyHealthHistory.heartAttack.value[0] === "may be" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="heartAttack">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.heartAttack ? "#ff0000" : "" }}>Maybe</h6>
                              </label>
                            </div>
                          </div>
                          {this.state.familyHealthHistory.heartAttack ? this.state.familyHealthHistory.heartAttack.value[0] === "yes" ?
                            <div>
                              <div className="form-check" id="check fields from motherAge heartAttack ">
                                <input className="form-check-input" type="checkbox" name="Mother" value="Mother" id="Mother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "heartAttack", "member", "Mother")} defaultChecked={this.state.familyHealthHistory.heartAttack.member ? this.state.familyHealthHistory.heartAttack.member.find(x => x === "Mother") === "Mother" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Mother</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.heartAttack ? this.state.familyHealthHistory.heartAttack.member ? this.state.familyHealthHistory.heartAttack.member.find(x => x === "Mother") === "Mother" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was she?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="motherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureMotherAge(e, "heartAttack", "motherAge")} value={this.state.familyHealthHistory.heartAttack.motherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}

                              <div className="form-check" id="check fields from fatherAge heartAttack ">
                                <input className="form-check-input" type="checkbox" name="Father" value="Father" id="Father" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "heartAttack", "member", "Father")} defaultChecked={this.state.familyHealthHistory.heartAttack.member ? this.state.familyHealthHistory.heartAttack.member.find(x => x === "Father") === "Father" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Father</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.heartAttack ? this.state.familyHealthHistory.heartAttack.member ? this.state.familyHealthHistory.heartAttack.member.find(x => x === "Father") === "Father" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was he?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="fatherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureFatherAge(e, "heartAttack", "fatherAge")} value={this.state.familyHealthHistory.heartAttack.fatherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}


                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="siblings" value="siblings" id="siblings" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "heartAttack", "member", "siblings")} defaultChecked={this.state.familyHealthHistory.heartAttack.member ? this.state.familyHealthHistory.heartAttack.member.find(x => x === "siblings") === "siblings" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">siblings</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.heartAttack ? this.state.familyHealthHistory.heartAttack.member ? this.state.familyHealthHistory.heartAttack.member.find(x => x === "siblings") === "siblings" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was he/she?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="siblingsAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureSiblingsAge(e, "heartAttack", "siblingsAge")} value={this.state.familyHealthHistory.heartAttack.siblingsAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}





                              <div className="form-check" id="check fields from MaternalGrandmotherAge heartAttack ">
                                <input className="form-check-input" type="checkbox" name="MaternalGrandmother" value="MaternalGrandmother" id="MaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "heartAttack", "member", "MaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.heartAttack.member ? this.state.familyHealthHistory.heartAttack.member.find(x => x === "MaternalGrandmother") === "MaternalGrandmother" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">MaternalGrandmother</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.heartAttack ? this.state.familyHealthHistory.heartAttack.member ? this.state.familyHealthHistory.heartAttack.member.find(x => x === "MaternalGrandmother") === "MaternalGrandmother" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was she?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="MaternalGrandmotherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureMaternalGrandmotherAge(e, "heartAttack", "MaternalGrandmotherAge")} value={this.state.familyHealthHistory.heartAttack.MaternalGrandmotherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}


                              <div className="form-check" id="check fields from MaternalGrandfatherAge heartAttack ">
                                <input className="form-check-input" type="checkbox" name="MaternalGrandfather" value="MaternalGrandfather" id="MaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "heartAttack", "member", "MaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.heartAttack.member ? this.state.familyHealthHistory.heartAttack.member.find(x => x === "MaternalGrandfather") === "MaternalGrandfather" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">MaternalGrandfather</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.heartAttack ? this.state.familyHealthHistory.heartAttack.member ? this.state.familyHealthHistory.heartAttack.member.find(x => x === "MaternalGrandfather") === "MaternalGrandfather" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was he?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="MaternalGrandfatherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureMaternalGrandfatherAge(e, "heartAttack", "MaternalGrandfatherAge")} value={this.state.familyHealthHistory.heartAttack.MaternalGrandfatherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}



                              <div className="form-check" id="check fields from PaternalGrandmotherAge heartAttack ">
                                <input className="form-check-input" type="checkbox" name="PaternalGrandmother" value="PaternalGrandmother" id="PaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "heartAttack", "member", "PaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.heartAttack.member ? this.state.familyHealthHistory.heartAttack.member.find(x => x === "PaternalGrandmother") === "PaternalGrandmother" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">PaternalGrandmother</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.heartAttack ? this.state.familyHealthHistory.heartAttack.member ? this.state.familyHealthHistory.heartAttack.member.find(x => x === "PaternalGrandmother") === "PaternalGrandmother" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was she?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="PaternalGrandmotherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailurePaternalGrandmotherAge(e, "heartAttack", "PaternalGrandmotherAge")} value={this.state.familyHealthHistory.heartAttack.PaternalGrandmotherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}


                              <div className="form-check" id="check fields from PaternalGrandfatherAge heartAttack ">
                                <input className="form-check-input" type="checkbox" name="PaternalGrandfather" value="PaternalGrandfather" id="PaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "heartAttack", "member", "PaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.heartAttack.member ? this.state.familyHealthHistory.heartAttack.member.find(x => x === "PaternalGrandfather") === "PaternalGrandfather" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">PaternalGrandfather</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.heartAttack ? this.state.familyHealthHistory.heartAttack.member ? this.state.familyHealthHistory.heartAttack.member.find(x => x === "PaternalGrandfather") === "PaternalGrandfather" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was he?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="PaternalGrandfatherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailurePaternalGrandfatherAge(e, "heartAttack", "PaternalGrandfatherAge")} value={this.state.familyHealthHistory.heartAttack.PaternalGrandfatherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}



                            </div> : '' : ''}
                        </div>
                        <div className="container">
                          <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                        </div>
                        <div className="container pad-l-2rem" id="check fields from stroke">
                          <p className="roboto-bold-18px  ">Stroke</p>

                          <div className=" d-flex">
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="stroke" id="stroke"  value="yes" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "stroke", "option")} checked={this.state.familyHealthHistory.stroke ? this.state.familyHealthHistory.stroke.value[0] === "yes" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="stroke">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.stroke ? "#ff0000" : "" }}>  Yes</h6>
                              </label>
                            </div>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="stroke" id="stroke"  value="no" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "stroke", "option")} checked={this.state.familyHealthHistory.stroke ? this.state.familyHealthHistory.stroke.value[0] === "no" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="stroke">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.stroke ? "#ff0000" : "" }}>  No</h6>
                              </label>
                            </div>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="stroke" id="stroke"  value="may be" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "stroke", "option")} checked={this.state.familyHealthHistory.stroke ? this.state.familyHealthHistory.stroke.value[0] === "may be" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="stroke">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.stroke ? "#ff0000" : "" }}>Maybe</h6>
                              </label>
                            </div>
                          </div>
                          {this.state.familyHealthHistory.stroke ? this.state.familyHealthHistory.stroke.value[0] === "yes" ?
                            <div>
                              <div className="form-check" id="check fields from motherAge stroke">

                                <input className="form-check-input" type="checkbox" name="Mother" value="Mother" id="Mother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "stroke", "member", "Mother")} defaultChecked={this.state.familyHealthHistory.stroke.member ? this.state.familyHealthHistory.stroke.member.find(x => x === "Mother") === "Mother" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Mother</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.stroke ? this.state.familyHealthHistory.stroke.member ? this.state.familyHealthHistory.stroke.member.find(x => x === "Mother") === "Mother" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was she?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="motherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureMotherAge(e, "stroke", "motherAge")} value={this.state.familyHealthHistory.stroke.motherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}

                              <div className="form-check" id="check fields from fatherAge stroke">
                                <input className="form-check-input" type="checkbox" name="Father" value="Father" id="Father" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "stroke", "member", "Father")} defaultChecked={this.state.familyHealthHistory.stroke.member ? this.state.familyHealthHistory.stroke.member.find(x => x === "Father") === "Father" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Father</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.stroke ? this.state.familyHealthHistory.stroke.member ? this.state.familyHealthHistory.stroke.member.find(x => x === "Father") === "Father" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was he?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="fatherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureFatherAge(e, "stroke", "fatherAge")} value={this.state.familyHealthHistory.stroke.fatherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}




                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="siblings" value="siblings" id="siblings" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "stroke", "member", "siblings")} defaultChecked={this.state.familyHealthHistory.stroke.member ? this.state.familyHealthHistory.stroke.member.find(x => x === "siblings") === "siblings" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Siblings</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.stroke ? this.state.familyHealthHistory.stroke.member ? this.state.familyHealthHistory.stroke.member.find(x => x === "siblings") === "siblings" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was he/she?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="siblingsAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureSiblingsAge(e, "stroke", "siblingsAge")} value={this.state.familyHealthHistory.stroke.siblingsAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}


                              <div className="form-check" id="check fields from MaternalGrandmotherAge stroke">
                                <input className="form-check-input" type="checkbox" name="MaternalGrandmother" value="MaternalGrandmother" id="MaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "stroke", "member", "MaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.stroke.member ? this.state.familyHealthHistory.stroke.member.find(x => x === "MaternalGrandmother") === "MaternalGrandmother" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">MaternalGrandmother</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.stroke ? this.state.familyHealthHistory.stroke.member ? this.state.familyHealthHistory.stroke.member.find(x => x === "MaternalGrandmother") === "MaternalGrandmother" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was she?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="MaternalGrandmotherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureMaternalGrandmotherAge(e, "stroke", "MaternalGrandmotherAge")} value={this.state.familyHealthHistory.stroke.MaternalGrandmotherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}


                              <div className="form-check" id="check fields from MaternalGrandfatherAge stroke">
                                <input className="form-check-input" type="checkbox" name="MaternalGrandfather" value="MaternalGrandfather" id="MaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "stroke", "member", "MaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.stroke.member ? this.state.familyHealthHistory.stroke.member.find(x => x === "MaternalGrandfather") === "MaternalGrandfather" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">MaternalGrandfather</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.stroke ? this.state.familyHealthHistory.stroke.member ? this.state.familyHealthHistory.stroke.member.find(x => x === "MaternalGrandfather") === "MaternalGrandfather" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was he?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="MaternalGrandfatherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureMaternalGrandfatherAge(e, "stroke", "MaternalGrandfatherAge")} value={this.state.familyHealthHistory.stroke.MaternalGrandfatherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}



                              <div className="form-check" id="check fields from PaternalGrandmotherAge stroke">
                                <input className="form-check-input" type="checkbox" name="PaternalGrandmother" value="PaternalGrandmother" id="PaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "stroke", "member", "PaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.stroke.member ? this.state.familyHealthHistory.stroke.member.find(x => x === "PaternalGrandmother") === "PaternalGrandmother" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">PaternalGrandmother</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.stroke ? this.state.familyHealthHistory.stroke.member ? this.state.familyHealthHistory.stroke.member.find(x => x === "PaternalGrandmother") === "PaternalGrandmother" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was she?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="PaternalGrandmotherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailurePaternalGrandmotherAge(e, "stroke", "PaternalGrandmotherAge")} value={this.state.familyHealthHistory.stroke.PaternalGrandmotherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}


                              <div className="form-check" id="check fields from PaternalGrandfatherAge stroke">
                                <input className="form-check-input" type="checkbox" name="PaternalGrandfather" value="PaternalGrandfather" id="PaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "stroke", "member", "PaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.stroke.member ? this.state.familyHealthHistory.stroke.member.find(x => x === "PaternalGrandfather") === "PaternalGrandfather" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">PaternalGrandfather</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.stroke ? this.state.familyHealthHistory.stroke.member ? this.state.familyHealthHistory.stroke.member.find(x => x === "PaternalGrandfather") === "PaternalGrandfather" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was he?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="PaternalGrandfatherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailurePaternalGrandfatherAge(e, "stroke", "PaternalGrandfatherAge")} value={this.state.familyHealthHistory.stroke.PaternalGrandfatherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}



                            </div> : '' : ''}
                        </div>
                        <div className="container pad-l-2rem">


                          <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                          <p className="roboto-bold-18px  " id="check fields from  aneurysm">Enlargement of an artery (aneurysm)</p>
                          <div className=" d-flex">
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="aneurysm" id="aneurysm"  value="yes" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "aneurysm", "option")} checked={this.state.familyHealthHistory.aneurysm ? this.state.familyHealthHistory.aneurysm.value[0] === "yes" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="aneurysm">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.aneurysm ? "#ff0000" : "" }}>  Yes</h6>
                              </label>
                            </div>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="aneurysm" id="aneurysm"  value="no" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "aneurysm", "option")} checked={this.state.familyHealthHistory.aneurysm ? this.state.familyHealthHistory.aneurysm.value[0] === "no" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="aneurysm">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.aneurysm ? "#ff0000" : "" }}>  No</h6>
                              </label>
                            </div>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="aneurysm" id="aneurysm"  value="may be" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "aneurysm", "option")} checked={this.state.familyHealthHistory.aneurysm ? this.state.familyHealthHistory.aneurysm.value[0] === "may be" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="aneurysm">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.aneurysm ? "#ff0000" : "" }}>Maybe</h6>
                              </label>
                            </div>
                          </div>
                          {this.state.familyHealthHistory.aneurysm ? this.state.familyHealthHistory.aneurysm.value[0] === "yes" ?
                            <div>
                              <div className="form-check" id="check fields from  motherAge aneurysm">
                                <input className="form-check-input" type="checkbox" name="Mother" value="Mother" id="Mother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "aneurysm", "member", "Mother")} defaultChecked={this.state.familyHealthHistory.aneurysm.member ? this.state.familyHealthHistory.aneurysm.member.find(x => x === "Mother") === "Mother" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Mother</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.aneurysm ? this.state.familyHealthHistory.aneurysm.member ? this.state.familyHealthHistory.aneurysm.member.find(x => x === "Mother") === "Mother" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was she?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="motherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureMotherAge(e, "aneurysm", "motherAge")} value={this.state.familyHealthHistory.aneurysm.motherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}

                              <div className="form-check" id="check fields from  fatherAge aneurysm">
                                <input className="form-check-input" type="checkbox" name="Father" value="Father" id="Father" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "aneurysm", "member", "Father")} defaultChecked={this.state.familyHealthHistory.aneurysm.member ? this.state.familyHealthHistory.aneurysm.member.find(x => x === "Father") === "Father" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Father</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.aneurysm ? this.state.familyHealthHistory.aneurysm.member ? this.state.familyHealthHistory.aneurysm.member.find(x => x === "Father") === "Father" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was he?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="fatherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureFatherAge(e, "aneurysm", "fatherAge")} value={this.state.familyHealthHistory.aneurysm.fatherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}



                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="siblings" value="siblings" id="siblings" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "aneurysm", "member", "siblings")} defaultChecked={this.state.familyHealthHistory.aneurysm.member ? this.state.familyHealthHistory.aneurysm.member.find(x => x === "siblings") === "siblings" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Siblings</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.aneurysm ? this.state.familyHealthHistory.aneurysm.member ? this.state.familyHealthHistory.aneurysm.member.find(x => x === "siblings") === "siblings" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was he/she?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="siblingsAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureSiblingsAge(e, "aneurysm", "siblingsAge")} value={this.state.familyHealthHistory.aneurysm.siblingsAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}


                              <div className="form-check" id="check fields from  MaternalGrandmotherAge aneurysm">
                                <input className="form-check-input" type="checkbox" name="MaternalGrandmother" value="MaternalGrandmother" id="MaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "aneurysm", "member", "MaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.aneurysm.member ? this.state.familyHealthHistory.aneurysm.member.find(x => x === "MaternalGrandmother") === "MaternalGrandmother" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">MaternalGrandmother</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.aneurysm ? this.state.familyHealthHistory.aneurysm.member ? this.state.familyHealthHistory.aneurysm.member.find(x => x === "MaternalGrandmother") === "MaternalGrandmother" ?

                                <div >
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was she?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="MaternalGrandmotherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureMaternalGrandmotherAge(e, "aneurysm", "MaternalGrandmotherAge")} value={this.state.familyHealthHistory.aneurysm.MaternalGrandmotherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}


                              <div className="form-check" id="check fields from  MaternalGrandfatherAge aneurysm">
                                <input className="form-check-input" type="checkbox" name="MaternalGrandfather" value="MaternalGrandfather" id="MaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "aneurysm", "member", "MaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.aneurysm.member ? this.state.familyHealthHistory.aneurysm.member.find(x => x === "MaternalGrandfather") === "MaternalGrandfather" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">MaternalGrandfather</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.aneurysm ? this.state.familyHealthHistory.aneurysm.member ? this.state.familyHealthHistory.aneurysm.member.find(x => x === "MaternalGrandfather") === "MaternalGrandfather" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was he?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="MaternalGrandfatherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureMaternalGrandfatherAge(e, "aneurysm", "MaternalGrandfatherAge")} value={this.state.familyHealthHistory.aneurysm.MaternalGrandfatherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}



                              <div className="form-check" id="check fields from  PaternalGrandmotherAge aneurysm">
                                <input className="form-check-input" type="checkbox" name="PaternalGrandmother" value="PaternalGrandmother" id="PaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "aneurysm", "member", "PaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.aneurysm.member ? this.state.familyHealthHistory.aneurysm.member.find(x => x === "PaternalGrandmother") === "PaternalGrandmother" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">PaternalGrandmother</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.aneurysm ? this.state.familyHealthHistory.aneurysm.member ? this.state.familyHealthHistory.aneurysm.member.find(x => x === "PaternalGrandmother") === "PaternalGrandmother" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was she?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="PaternalGrandmotherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailurePaternalGrandmotherAge(e, "aneurysm", "PaternalGrandmotherAge")} value={this.state.familyHealthHistory.aneurysm.PaternalGrandmotherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}


                              <div className="form-check" id="check fields from  PaternalGrandfatherAge aneurysm">
                                <input className="form-check-input" type="checkbox" name="PaternalGrandfather" value="PaternalGrandfather" id="PaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "aneurysm", "member", "PaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.aneurysm.member ? this.state.familyHealthHistory.aneurysm.member.find(x => x === "PaternalGrandfather") === "PaternalGrandfather" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">PaternalGrandfather</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.aneurysm ? this.state.familyHealthHistory.aneurysm.member ? this.state.familyHealthHistory.aneurysm.member.find(x => x === "PaternalGrandfather") === "PaternalGrandfather" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was he?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="PaternalGrandfatherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailurePaternalGrandfatherAge(e, "aneurysm", "PaternalGrandfatherAge")} value={this.state.familyHealthHistory.aneurysm.PaternalGrandfatherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}



                            </div> : '' : ''}
                          <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                          {/* -----eof -------------------- */}
                          <p className="roboto-bold-18px  " id="check fields from  dissection">Tear of an artery (dissection)</p>
                          <div className=" d-flex">
                            <div className="form-check   mr-5">
                              <input className="form-check-input" type="radio" name="dissection" id="dissection"  value="yes" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "dissection", "option")} checked={this.state.familyHealthHistory.dissection ? this.state.familyHealthHistory.dissection.value[0] === "yes" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="dissection">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.dissection ? "#ff0000" : "" }}>  Yes</h6>
                              </label>
                            </div>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="dissection" id="dissection"  value="no" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "dissection", "option")} checked={this.state.familyHealthHistory.dissection ? this.state.familyHealthHistory.dissection.value[0] === "no" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="dissection">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.dissection ? "#ff0000" : "" }}>  No</h6>
                              </label>
                            </div>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="dissection" id="dissection"  value="may be" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "dissection", "option")} checked={this.state.familyHealthHistory.dissection ? this.state.familyHealthHistory.dissection.value[0] === "may be" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="dissection">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.dissection ? "#ff0000" : "" }}>Maybe</h6>
                              </label>
                            </div>
                          </div>

                          {this.state.familyHealthHistory.dissection ? this.state.familyHealthHistory.dissection.value[0] === "yes" ?
                            <div>

                              <div className="form-check" id="check fields from  motherAge dissection">
                                <input className="form-check-input" type="checkbox" name="Mother" value="Mother" id="Mother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "dissection", "member", "Mother")} defaultChecked={this.state.familyHealthHistory.dissection.member ? this.state.familyHealthHistory.dissection.member.find(x => x === "Mother") === "Mother" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Mother</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.dissection ? this.state.familyHealthHistory.dissection.member ? this.state.familyHealthHistory.dissection.member.find(x => x === "Mother") === "Mother" ?

                                <div >
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was she?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="motherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureMotherAge(e, "dissection", "motherAge")} value={this.state.familyHealthHistory.dissection.motherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}

                              <div className="form-check" id="check fields from  fatherAge dissection">
                                <input className="form-check-input" type="checkbox" name="Father" value="Father" id="Father" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "dissection", "member", "Father")} defaultChecked={this.state.familyHealthHistory.dissection.member ? this.state.familyHealthHistory.dissection.member.find(x => x === "Father") === "Father" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Father</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.dissection ? this.state.familyHealthHistory.dissection.member ? this.state.familyHealthHistory.dissection.member.find(x => x === "Father") === "Father" ?

                                <div >
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was he?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="fatherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureFatherAge(e, "dissection", "fatherAge")} value={this.state.familyHealthHistory.dissection.fatherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}


                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="siblings" value="siblings" id="siblings" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "dissection", "member", "siblings")} defaultChecked={this.state.familyHealthHistory.dissection.member ? this.state.familyHealthHistory.dissection.member.find(x => x === "siblings") === "siblings" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">Siblings</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.dissection ? this.state.familyHealthHistory.dissection.member ? this.state.familyHealthHistory.dissection.member.find(x => x === "siblings") === "siblings" ?

                                <div >
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was he/she?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="siblingsAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureSiblingsAge(e, "dissection", "siblingsAge")} value={this.state.familyHealthHistory.dissection.siblingsAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}


                              <div className="form-check" id="check fields from  MaternalGrandmotherAge dissection">
                                <input className="form-check-input" type="checkbox" name="MaternalGrandmother" value="MaternalGrandmother" id="MaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "dissection", "member", "MaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.dissection.member ? this.state.familyHealthHistory.dissection.member.find(x => x === "MaternalGrandmother") === "MaternalGrandmother" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">MaternalGrandmother</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.dissection ? this.state.familyHealthHistory.dissection.member ? this.state.familyHealthHistory.dissection.member.find(x => x === "MaternalGrandmother") === "MaternalGrandmother" ?

                                <div >
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was she?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="MaternalGrandmotherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureMaternalGrandmotherAge(e, "dissection", "MaternalGrandmotherAge")} value={this.state.familyHealthHistory.dissection.MaternalGrandmotherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}


                              <div className="form-check" id="check fields from  MaternalGrandfatherAge dissection">
                                <input className="form-check-input" type="checkbox" name="MaternalGrandfather" value="MaternalGrandfather" id="MaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "dissection", "member", "MaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.dissection.member ? this.state.familyHealthHistory.dissection.member.find(x => x === "MaternalGrandfather") === "MaternalGrandfather" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">MaternalGrandfather</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.dissection ? this.state.familyHealthHistory.dissection.member ? this.state.familyHealthHistory.dissection.member.find(x => x === "MaternalGrandfather") === "MaternalGrandfather" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was he?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="MaternalGrandfatherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailureMaternalGrandfatherAge(e, "dissection", "MaternalGrandfatherAge")} value={this.state.familyHealthHistory.dissection.MaternalGrandfatherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}



                              <div className="form-check" id="check fields from  PaternalGrandmotherAge dissection">
                                <input className="form-check-input" type="checkbox" name="PaternalGrandmother" value="PaternalGrandmother" id="PaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "dissection", "member", "PaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.dissection.member ? this.state.familyHealthHistory.dissection.member.find(x => x === "PaternalGrandmother") === "PaternalGrandmother" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">PaternalGrandmother</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.dissection ? this.state.familyHealthHistory.dissection.member ? this.state.familyHealthHistory.dissection.member.find(x => x === "PaternalGrandmother") === "PaternalGrandmother" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was she?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="PaternalGrandmotherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailurePaternalGrandmotherAge(e, "dissection", "PaternalGrandmotherAge")} value={this.state.familyHealthHistory.dissection.PaternalGrandmotherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}


                              <div className="form-check" id="check fields from  PaternalGrandfatherAge dissection">
                                <input className="form-check-input" type="checkbox" name="PaternalGrandfather" value="PaternalGrandfather" id="PaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "dissection", "member", "PaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.dissection.member ? this.state.familyHealthHistory.dissection.member.find(x => x === "PaternalGrandfather") === "PaternalGrandfather" ? "checked" : "" : ""} />
                                <label className="form-check-label mb-1" >
                                  <h6 className="reg-18">PaternalGrandfather</h6>
                                </label>
                              </div>

                              {this.state.familyHealthHistory.dissection ? this.state.familyHealthHistory.dissection.member ? this.state.familyHealthHistory.dissection.member.find(x => x === "PaternalGrandfather") === "PaternalGrandfather" ?

                                <div>
                                  <div>

                                    <p className="roboto-reg-18px ml-4 mt-2" >How old was he?</p>
                                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{ border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem' }}>
                                      <input type="text" placeholder="Estimation is okay" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" name="PaternalGrandfatherAge" className="form-control roboto-reg-16px" style={{ height: '57px', width: '16.5rem', border: 'none' }} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeFamilyHealthHistoryHeartFailurePaternalGrandfatherAge(e, "dissection", "PaternalGrandfatherAge")} value={this.state.familyHealthHistory.dissection.PaternalGrandfatherAge} />
                                    </div>
                                  </div>

                                </div>
                                : "" : "" : ""}



                            </div> : '' : ''}


                          <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                          <p className="roboto-bold-18px  " id="check fields from  Fainting or passing ">Fainting or passing out after exercise </p>
                          <div className=" d-flex">
                            <div className="form-check   mr-5">
                              <input className="form-check-input" type="radio" name="faintingOrPassing" id="faintingOrPassing"  value="yes" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "faintingOrPassing", "option")} checked={this.state.familyHealthHistory.faintingOrPassing ? this.state.familyHealthHistory.faintingOrPassing.value[0] === "yes" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="faintingOrPassing">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.faintingOrPassing ? "#ff0000" : "" }}>  Yes</h6>
                              </label>
                            </div>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="faintingOrPassing" id="faintingOrPassing"  value="no" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "faintingOrPassing", "option")} checked={this.state.familyHealthHistory.faintingOrPassing ? this.state.familyHealthHistory.faintingOrPassing.value[0] === "no" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="faintingOrPassing">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.faintingOrPassing ? "#ff0000" : "" }}>  No</h6>
                              </label>
                            </div>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="faintingOrPassing" id="faintingOrPassing"  value="may be" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "faintingOrPassing", "option")} checked={this.state.familyHealthHistory.faintingOrPassing ? this.state.familyHealthHistory.faintingOrPassing.value[0] === "may be" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="faintingOrPassing">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.faintingOrPassing ? "#ff0000" : "" }}>Maybe</h6>
                              </label>
                            </div>
                          </div>
                          <div>
                            {this.state.familyHealthHistory.faintingOrPassing ? this.state.familyHealthHistory.faintingOrPassing.value[0] === "yes" ?

                              <div >
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="mother" value="mother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "faintingOrPassing", "members", "mother")} defaultChecked={this.state.familyHealthHistory.faintingOrPassing.members ? this.state.familyHealthHistory.faintingOrPassing.members.find(x => x === "mother") === "mother" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Mother</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="Father" value="Father" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "faintingOrPassing", "members", "Father")} defaultChecked={this.state.familyHealthHistory.faintingOrPassing.members ? this.state.familyHealthHistory.faintingOrPassing.members.find(x => x === "Father") === "Father" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Father</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="Siblings" value="Siblings" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "faintingOrPassing", "members", "Siblings")} defaultChecked={this.state.familyHealthHistory.faintingOrPassing.members ? this.state.familyHealthHistory.faintingOrPassing.members.find(x => x === "Siblings") === "Siblings" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Siblings</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="MaternalGrandmother" value="MaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "faintingOrPassing", "members", "MaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.faintingOrPassing.members ? this.state.familyHealthHistory.faintingOrPassing.members.find(x => x === "MaternalGrandmother") === "MaternalGrandmother" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Maternal Grandmother </h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="MaternalGrandfather" value="MaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "faintingOrPassing", "members", "MaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.faintingOrPassing.members ? this.state.familyHealthHistory.faintingOrPassing.members.find(x => x === "MaternalGrandfather") === "MaternalGrandfather" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Maternal Grandfather</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="PaternalGrandmother" value="PaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "faintingOrPassing", "members", "PaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.faintingOrPassing.members ? this.state.familyHealthHistory.faintingOrPassing.members.find(x => x === "PaternalGrandmother") === "PaternalGrandmother" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Paternal Grandmother</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="PaternalGrandfather" value="PaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "faintingOrPassing", "members", "PaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.faintingOrPassing.members ? this.state.familyHealthHistory.faintingOrPassing.members.find(x => x === "PaternalGrandfather") === "PaternalGrandfather" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Paternal Grandfather</h6>
                                  </label>
                                </div>
                              </div>
                              : '' : ''}
                          </div>
                          <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                          {/* ----eof--- */}
                          <h2 className="roboto-bold-18px pt-4" id="check fields from  Heart or vascular ">Heart or vascular surgery</h2>
                          <div className="d-flex" style={{ marginLeft: '-1.012rem' }}>
                            <div className="form-check pad-l-2rem  mr-5">
                              <input className="form-check-input" type="radio" name="heartOrVascular" id="heartOrVascular"  value="yes" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "heartOrVascular", "option")} checked={this.state.familyHealthHistory.heartOrVascular ? this.state.familyHealthHistory.heartOrVascular.value[0] === "yes" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="heartOrVascular">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.heartOrVascular ? "#ff0000" : "" }}>  Yes</h6>
                              </label>
                            </div>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="heartOrVascular" id="heartOrVascular"  value="no" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "heartOrVascular", "option")} checked={this.state.familyHealthHistory.heartOrVascular ? this.state.familyHealthHistory.heartOrVascular.value[0] === "no" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="heartOrVascular">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.heartOrVascular ? "#ff0000" : "" }}>  No</h6>
                              </label>
                            </div>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="heartOrVascular" id="heartOrVascular"  value="may be" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "heartOrVascular", "option")} checked={this.state.familyHealthHistory.heartOrVascular ? this.state.familyHealthHistory.heartOrVascular.value[0] === "may be" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="heartOrVascular">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.heartOrVascular ? "#ff0000" : "" }}>Maybe</h6>
                              </label>
                            </div>
                          </div>
                          <div className="container pad-l-2rem ">
                          </div>
                          <div>
                            {this.state.familyHealthHistory.heartOrVascular ? this.state.familyHealthHistory.heartOrVascular.value[0] === "yes" ?

                              <div >
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="mother" value="mother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "heartOrVascular", "members", "mother")} defaultChecked={this.state.familyHealthHistory.heartOrVascular.members ? this.state.familyHealthHistory.heartOrVascular.members.find(x => x === "mother") === "mother" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Mother</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="Father" value="Father" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "heartOrVascular", "members", "Father")} defaultChecked={this.state.familyHealthHistory.heartOrVascular.members ? this.state.familyHealthHistory.heartOrVascular.members.find(x => x === "Father") === "Father" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Father</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="Siblings" value="Siblings" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "heartOrVascular", "members", "Siblings")} defaultChecked={this.state.familyHealthHistory.heartOrVascular.members ? this.state.familyHealthHistory.heartOrVascular.members.find(x => x === "Siblings") === "Siblings" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Siblings</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="MaternalGrandmother" value="MaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "heartOrVascular", "members", "MaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.heartOrVascular.members ? this.state.familyHealthHistory.heartOrVascular.members.find(x => x === "MaternalGrandmother") === "MaternalGrandmother" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Maternal Grandmother </h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="MaternalGrandfather" value="MaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "heartOrVascular", "members", "MaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.heartOrVascular.members ? this.state.familyHealthHistory.heartOrVascular.members.find(x => x === "MaternalGrandfather") === "MaternalGrandfather" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Maternal Grandfather</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="PaternalGrandmother" value="PaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "heartOrVascular", "members", "PaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.heartOrVascular.members ? this.state.familyHealthHistory.heartOrVascular.members.find(x => x === "PaternalGrandmother") === "PaternalGrandmother" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Paternal Grandmother</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="PaternalGrandfather" value="PaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "heartOrVascular", "members", "PaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.heartOrVascular.members ? this.state.familyHealthHistory.heartOrVascular.members.find(x => x === "PaternalGrandfather") === "PaternalGrandfather" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Paternal Grandfather</h6>
                                  </label>
                                </div>
                              </div>
                              : '' : ''}
                          </div>

                          {/* ------eof----- */}
                          <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                          {/* ----eof--- */}

                          <h2 className="roboto-bold-18px pt-4" id="check fields from  Irregular heartbeat ">Irregular heartbeat (arrhythmia) <span style={{ fontSize: '12px', color: 'gray' }}> Optional</span></h2>


                          <div className="d-flex" style={{ marginLeft: '-1.012rem' }}>
                            <div className="form-check pad-l-2rem  mr-5">
                              <input className="form-check-input" type="radio" name="arrhythmiaFamilyHealthHistory" id="arrhythmiaFamilyHealthHistory"  value="yes" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "arrhythmiaFamilyHealthHistory", "option")} checked={this.state.familyHealthHistory.arrhythmiaFamilyHealthHistory ? this.state.familyHealthHistory.arrhythmiaFamilyHealthHistory.value[0] === "yes" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="arrhythmiaFamilyHealthHistory">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.arrhythmiaFamilyHealthHistory ? "#ff0000" : "" }}>  Yes</h6>
                              </label>
                            </div>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="arrhythmiaFamilyHealthHistory" id="arrhythmiaFamilyHealthHistory"  value="no" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "arrhythmiaFamilyHealthHistory", "option")} checked={this.state.familyHealthHistory.arrhythmiaFamilyHealthHistory ? this.state.familyHealthHistory.arrhythmiaFamilyHealthHistory.value[0] === "no" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="arrhythmiaFamilyHealthHistory">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.arrhythmiaFamilyHealthHistory ? "#ff0000" : "" }}>  No</h6>
                              </label>
                            </div>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="arrhythmiaFamilyHealthHistory" id="arrhythmiaFamilyHealthHistory"  value="may be" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "arrhythmiaFamilyHealthHistory", "option")} checked={this.state.familyHealthHistory.arrhythmiaFamilyHealthHistory ? this.state.familyHealthHistory.arrhythmiaFamilyHealthHistory.value[0] === "may be" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="arrhythmiaFamilyHealthHistory">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.arrhythmiaFamilyHealthHistory ? "#ff0000" : "" }}>Maybe</h6>
                              </label>
                            </div>
                          </div>
                          <div className="container pad-l-2rem ">
                          </div>
                          <div>
                            {this.state.familyHealthHistory.arrhythmiaFamilyHealthHistory ? this.state.familyHealthHistory.arrhythmiaFamilyHealthHistory.value[0] === "yes" ?

                              <div >
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="mother" value="mother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arrhythmiaFamilyHealthHistory", "members", "mother")} defaultChecked={this.state.familyHealthHistory.arrhythmiaFamilyHealthHistory.members ? this.state.familyHealthHistory.arrhythmiaFamilyHealthHistory.members.find(x => x === "mother") === "mother" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Mother</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="Father" value="Father" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arrhythmiaFamilyHealthHistory", "members", "Father")} defaultChecked={this.state.familyHealthHistory.arrhythmiaFamilyHealthHistory.members ? this.state.familyHealthHistory.arrhythmiaFamilyHealthHistory.members.find(x => x === "Father") === "Father" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Father</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="Siblings" value="Siblings" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arrhythmiaFamilyHealthHistory", "members", "Siblings")} defaultChecked={this.state.familyHealthHistory.arrhythmiaFamilyHealthHistory.members ? this.state.familyHealthHistory.arrhythmiaFamilyHealthHistory.members.find(x => x === "Siblings") === "Siblings" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Siblings</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="MaternalGrandmother" value="MaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arrhythmiaFamilyHealthHistory", "members", "MaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.arrhythmiaFamilyHealthHistory.members ? this.state.familyHealthHistory.arrhythmiaFamilyHealthHistory.members.find(x => x === "MaternalGrandmother") === "MaternalGrandmother" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Maternal Grandmother </h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="MaternalGrandfather" value="MaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arrhythmiaFamilyHealthHistory", "members", "MaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.arrhythmiaFamilyHealthHistory.members ? this.state.familyHealthHistory.arrhythmiaFamilyHealthHistory.members.find(x => x === "MaternalGrandfather") === "MaternalGrandfather" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Maternal Grandfather</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="PaternalGrandmother" value="PaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arrhythmiaFamilyHealthHistory", "members", "PaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.arrhythmiaFamilyHealthHistory.members ? this.state.familyHealthHistory.arrhythmiaFamilyHealthHistory.members.find(x => x === "PaternalGrandmother") === "PaternalGrandmother" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Paternal Grandmother</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="PaternalGrandfather" value="PaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "arrhythmiaFamilyHealthHistory", "members", "PaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.arrhythmiaFamilyHealthHistory.members ? this.state.familyHealthHistory.arrhythmiaFamilyHealthHistory.members.find(x => x === "PaternalGrandfather") === "PaternalGrandfather" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Paternal Grandfather</h6>
                                  </label>
                                </div>
                              </div>
                              : '' : ''}
                          </div>


                        <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                          <h2 className="roboto-bold-18px pt-4" id="check fields from  Diabetes ">Diabetes</h2>
                          <div className="d-flex" style={{ marginLeft: '-1.012rem' }}>
                            <div className="form-check pad-l-2rem  mr-5">
                              <input className="form-check-input" type="radio" name="diabetesFamilyHealthHistory" id="diabetesFamilyHealthHistory"  value="yes" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "diabetesFamilyHealthHistory", "option")} checked={this.state.familyHealthHistory.diabetesFamilyHealthHistory ? this.state.familyHealthHistory.diabetesFamilyHealthHistory.value[0] === "yes" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="diabetesFamilyHealthHistory">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.diabetesFamilyHealthHistory ? "#ff0000" : "" }}>  Yes</h6>
                              </label>
                            </div>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="diabetesFamilyHealthHistory" id="diabetesFamilyHealthHistory"  value="no" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "diabetesFamilyHealthHistory", "option")} checked={this.state.familyHealthHistory.diabetesFamilyHealthHistory ? this.state.familyHealthHistory.diabetesFamilyHealthHistory.value[0] === "no" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="diabetesFamilyHealthHistory">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.diabetesFamilyHealthHistory ? "#ff0000" : "" }}>  No</h6>
                              </label>
                            </div>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="diabetesFamilyHealthHistory" id="diabetesFamilyHealthHistory"  value="may be" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "diabetesFamilyHealthHistory", "option")} checked={this.state.familyHealthHistory.diabetesFamilyHealthHistory ? this.state.familyHealthHistory.diabetesFamilyHealthHistory.value[0] === "may be" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="diabetesFamilyHealthHistory">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.diabetesFamilyHealthHistory ? "#ff0000" : "" }}>Maybe</h6>
                              </label>
                            </div>
                          </div>
                          <div className="container pad-l-2rem ">
                          </div>
                          <div>
                            {this.state.familyHealthHistory.diabetesFamilyHealthHistory ? this.state.familyHealthHistory.diabetesFamilyHealthHistory.value[0] === "yes" ?

                              <div >
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="mother" value="mother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "diabetesFamilyHealthHistory", "members", "mother")} defaultChecked={this.state.familyHealthHistory.diabetesFamilyHealthHistory.members ? this.state.familyHealthHistory.diabetesFamilyHealthHistory.members.find(x => x === "mother") === "mother" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Mother</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="Father" value="Father" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "diabetesFamilyHealthHistory", "members", "Father")} defaultChecked={this.state.familyHealthHistory.diabetesFamilyHealthHistory.members ? this.state.familyHealthHistory.diabetesFamilyHealthHistory.members.find(x => x === "Father") === "Father" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Father</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="Siblings" value="Siblings" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "diabetesFamilyHealthHistory", "members", "Siblings")} defaultChecked={this.state.familyHealthHistory.diabetesFamilyHealthHistory.members ? this.state.familyHealthHistory.diabetesFamilyHealthHistory.members.find(x => x === "Siblings") === "Siblings" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Siblings</h6>
                                  </label>
                                </div>

                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="MaternalGrandmother" value="MaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "diabetesFamilyHealthHistory", "members", "MaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.diabetesFamilyHealthHistory.members ? this.state.familyHealthHistory.diabetesFamilyHealthHistory.members.find(x => x === "MaternalGrandmother") === "MaternalGrandmother" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Maternal Grandmother </h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="MaternalGrandfather" value="MaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "diabetesFamilyHealthHistory", "members", "MaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.diabetesFamilyHealthHistory.members ? this.state.familyHealthHistory.diabetesFamilyHealthHistory.members.find(x => x === "MaternalGrandfather") === "MaternalGrandfather" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Maternal Grandfather</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="PaternalGrandmother" value="PaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "diabetesFamilyHealthHistory", "members", "PaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.diabetesFamilyHealthHistory.members ? this.state.familyHealthHistory.diabetesFamilyHealthHistory.members.find(x => x === "PaternalGrandmother") === "PaternalGrandmother" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Paternal Grandmother</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="PaternalGrandfather" value="PaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "diabetesFamilyHealthHistory", "members", "PaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.diabetesFamilyHealthHistory.members ? this.state.familyHealthHistory.diabetesFamilyHealthHistory.members.find(x => x === "PaternalGrandfather") === "PaternalGrandfather" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Paternal Grandfather</h6>
                                  </label>
                                </div>
                              </div>
                              : '' : ''}
                          </div>

                          <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                          <h2 className="roboto-bold-18px pt-4" id="check fields from  check fields from  Hypertension ">Has anyone in your family had Hypertension? </h2>
                          <div className="d-flex" style={{ marginLeft: '-1.012rem' }}>
                            <div className="form-check pad-l-2rem  mr-5">
                              <input className="form-check-input" type="radio" name="hyperTension" id="hyperTension"  value="yes" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "hyperTension", "option")} checked={this.state.familyHealthHistory.hyperTension ? this.state.familyHealthHistory.hyperTension.value[0] === "yes" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="hyperTension">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.hyperTension ? "#ff0000" : "" }}>  Yes</h6>
                              </label>
                            </div>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="hyperTension" id="hyperTension"  value="no" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "hyperTension", "option")} checked={this.state.familyHealthHistory.hyperTension ? this.state.familyHealthHistory.hyperTension.value[0] === "no" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="hyperTension">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.hyperTension ? "#ff0000" : "" }}>  No</h6>
                              </label>
                            </div>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="hyperTension" id="hyperTension"  value="may be" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "hyperTension", "option")} checked={this.state.familyHealthHistory.hyperTension ? this.state.familyHealthHistory.hyperTension.value[0] === "may be" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="hyperTension">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.hyperTension ? "#ff0000" : "" }}>Maybe</h6>
                              </label>
                            </div>
                          </div>
                          <div className="container pad-l-2rem ">
                          </div>
                          <div>
                            {this.state.familyHealthHistory.hyperTension ? this.state.familyHealthHistory.hyperTension.value[0] === "yes" ?

                              <div >
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="mother" value="mother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hyperTension", "members", "mother")} defaultChecked={this.state.familyHealthHistory.hyperTension.members ? this.state.familyHealthHistory.hyperTension.members.find(x => x === "mother") === "mother" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Mother</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="Father" value="Father" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hyperTension", "members", "Father")} defaultChecked={this.state.familyHealthHistory.hyperTension.members ? this.state.familyHealthHistory.hyperTension.members.find(x => x === "Father") === "Father" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Father</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="Siblings" value="Siblings" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hyperTension", "members", "Siblings")} defaultChecked={this.state.familyHealthHistory.hyperTension.members ? this.state.familyHealthHistory.hyperTension.members.find(x => x === "Siblings") === "Siblings" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Siblings</h6>
                                  </label>
                                </div>

                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="MaternalGrandmother" value="MaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hyperTension", "members", "MaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.hyperTension.members ? this.state.familyHealthHistory.hyperTension.members.find(x => x === "MaternalGrandmother") === "MaternalGrandmother" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Maternal Grandmother </h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="MaternalGrandfather" value="MaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hyperTension", "members", "MaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.hyperTension.members ? this.state.familyHealthHistory.hyperTension.members.find(x => x === "MaternalGrandfather") === "MaternalGrandfather" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Maternal Grandfather</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="PaternalGrandmother" value="PaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hyperTension", "members", "PaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.hyperTension.members ? this.state.familyHealthHistory.hyperTension.members.find(x => x === "PaternalGrandmother") === "PaternalGrandmother" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Paternal Grandmother</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="PaternalGrandfather" value="PaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hyperTension", "members", "PaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.hyperTension.members ? this.state.familyHealthHistory.hyperTension.members.find(x => x === "PaternalGrandfather") === "PaternalGrandfather" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Paternal Grandfather</h6>
                                  </label>
                                </div>
                              </div>
                              : '' : ''}
                          </div>
                          <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                          <h2 className="roboto-bold-30px pt-4" id="check fields from  check fields from  hereditaryHeartConditions">
                            Did anyone in your family had a genetic test for hereditary heart conditions?
                                  </h2>
                          <div className=" d-flex">
                            <div className="form-check   mr-5">
                              <input className="form-check-input" type="radio" name="hereditaryHeartConditions" id="hereditaryHeartConditions"  value="yes" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "hereditaryHeartConditions", "option")} checked={this.state.familyHealthHistory.hereditaryHeartConditions ? this.state.familyHealthHistory.hereditaryHeartConditions.value[0] === "yes" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="hereditaryHeartConditions">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.hereditaryHeartConditions ? "#ff0000" : "" }}>  Yes</h6>
                              </label>
                            </div>
                            <div className="form-check  mr-5">
                              <input className="form-check-input" type="radio" name="hereditaryHeartConditions" id="hereditaryHeartConditions"  value="no" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "hereditaryHeartConditions", "option")} checked={this.state.familyHealthHistory.hereditaryHeartConditions ? this.state.familyHealthHistory.hereditaryHeartConditions.value[0] === "no" ? "checked" : "" : ""} />
                              <label className="form-check-label" htmlFor="hereditaryHeartConditions">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.hereditaryHeartConditions ? "#ff0000" : "" }}>  No</h6>
                              </label>
                            </div>
                            <div className="form-check-label  mr-5">
                              <input className="form-check-input" type="radio" name="hereditaryHeartConditions" id="hereditaryHeartConditions"  value="may be" onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "hereditaryHeartConditions", "option")} checked={this.state.familyHealthHistory.hereditaryHeartConditions ? this.state.familyHealthHistory.hereditaryHeartConditions.value[0] === "may be" ? "checked" : "" : ""} />
                              <label className="form-check" htmlFor="hereditaryHeartConditions">
                                <h6 className="reg-18" style={{ color: this.state.familyHealthHistoryFormErrors.hereditaryHeartConditions ? "#ff0000" : "" }}>Maybe</h6>
                              </label>
                            </div>
                          </div>
                          {this.state.familyHealthHistory.hereditaryHeartConditions ? this.state.familyHealthHistory.hereditaryHeartConditions.value[0] === "yes" ?
                            <div>

                              <div >
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="mother" value="mother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryHeartConditions", "members", "mother")} defaultChecked={this.state.familyHealthHistory.hereditaryHeartConditions.members ? this.state.familyHealthHistory.hereditaryHeartConditions.members.find(x => x === "mother") === "mother" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Mother</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="Father" value="Father" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryHeartConditions", "members", "Father")} defaultChecked={this.state.familyHealthHistory.hereditaryHeartConditions.members ? this.state.familyHealthHistory.hereditaryHeartConditions.members.find(x => x === "Father") === "Father" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Father</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="Siblings" value="Siblings" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryHeartConditions", "members", "Siblings")} defaultChecked={this.state.familyHealthHistory.hereditaryHeartConditions.members ? this.state.familyHealthHistory.hereditaryHeartConditions.members.find(x => x === "Siblings") === "Siblings" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Siblings</h6>
                                  </label>
                                </div>

                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="MaternalGrandmother" value="MaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryHeartConditions", "members", "MaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.hereditaryHeartConditions.members ? this.state.familyHealthHistory.hereditaryHeartConditions.members.find(x => x === "MaternalGrandmother") === "MaternalGrandmother" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Maternal Grandmother </h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="MaternalGrandfather" value="MaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryHeartConditions", "members", "MaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.hereditaryHeartConditions.members ? this.state.familyHealthHistory.hereditaryHeartConditions.members.find(x => x === "MaternalGrandfather") === "MaternalGrandfather" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Maternal Grandfather</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="PaternalGrandmother" value="PaternalGrandmother" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryHeartConditions", "members", "PaternalGrandmother")} defaultChecked={this.state.familyHealthHistory.hereditaryHeartConditions.members ? this.state.familyHealthHistory.hereditaryHeartConditions.members.find(x => x === "PaternalGrandmother") === "PaternalGrandmother" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Paternal Grandmother</h6>
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="PaternalGrandfather" value="PaternalGrandfather" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "hereditaryHeartConditions", "members", "PaternalGrandfather")} defaultChecked={this.state.familyHealthHistory.hereditaryHeartConditions.members ? this.state.familyHealthHistory.hereditaryHeartConditions.members.find(x => x === "PaternalGrandfather") === "PaternalGrandfather" ? "checked" : "" : ""} />
                                  <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h6 className="reg-18">Paternal Grandfather</h6>
                                  </label>
                                </div>
                              </div>

                            </div> : '' : ''}
                          <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                          <h2 className="roboto-bold-30px pt-4">
                            Is there anything else related to your personal or family history that you would like to
                            share?
                                  </h2>
                          {/* <input type="text" style="height:3.5rem;width:70rem"placeholder="Please enter health history details for relatives in the fields above if possible"/> */}
                          <input type="text" name="personalOrFamilyHistory" style={{ border: 0, outline: 0, borderBottom: '2px solid Black', width: '80%', color: this.state.familyHealthHistoryFormErrors.personalOrFamilyHistory ? "#ff0000" : "" }} placeholder="Please enter health history details for relatives in the fields above if possible" value={this.state.familyHealthHistory.personalOrFamilyHistory} onChange={this.handleChangefamilyHealthHistory} />
                          <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                        </div>
                      </div>
                      <div className="container mt-5 d-flex justify-content-end">
                        <button type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mr-5 mb-5" style={{ height: '3.75rem', width: '8.3rem' }} onClick={this.submitfamilyHealthHistory}>Finish</button>
                      </div>
                    </div>
                  </div>
                  {/* </div>
              </div> */}
                  {/* --------------------------------------------------------------------------------end of 3rd tab--------------------------------------------------------------------------- */}
                  {/* ------------------------------------------------------start complete section---------------------------------------- */}
                  <div className={this.state.isActive === 4 ? "tab-pane active" : "tab-pane"} id="settings" role="tabpanel" aria-labelledby="settings-tab">
                    <div className="container  pad-l-2rem pb-0 mb-0">
                      <div className="row mb-2">
                        <div className="col-sm-12 col-md-4 col-lg-6">
                          <h1 className="roboto-black-30px">Health History</h1>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-3">
                        {this.state.healthHistory.healthHistoryStatus==="Complete" ? <h1 style={{ color: '#4f5be7' }} className="roboto-bold-24px ">Complete</h1> : <h1 style={{ color: 'red' }} className="roboto-bold-24px ">InComplete</h1>}
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-3">
                          <button className="float-right btn btn-hover-white my-2 my-sm-0 text-uppercase" style={{ height: '55px', width: '100px', borderRadius: '30px' }} onClick={() => this.changeTab(1)} type="submit"><b>Edit</b></button>
                        </div>
                      </div>
                      <div className="container  mt-2">
                        <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                      </div>
                      <div className="row mb-2">
                        <div className="col-sm-12 col-md-4 col-lg-6">
                          <h1 className="roboto-black-30px">Family Tree</h1>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-3">
                        {this.state.familyTree.familyTreeStatus==="Complete" ? <h1 style={{ color: '#4f5be7' }} className="roboto-bold-24px ">Complete</h1> : <h1 style={{ color: 'red' }} className="roboto-bold-24px ">InComplete</h1>}
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-3">
                          <button className="float-right btn btn-hover-white my-2 my-sm-0  text-uppercase" style={{ height: '55px', width: '100px', borderRadius: '30px' }} onClick={() => this.changeTab(2)} type="submit"><b>Edit</b></button>
                        </div>
                        <div className="container  mt-2">
                          <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-sm-12 col-md-4 col-lg-6">
                          <h1 className="roboto-black-30px">Family Health History</h1>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-3">
                          {this.state.familyHealthHistory.familyHealthHistoryStatus==="Complete" ? <h1 style={{ color: '#4f5be7' }} className="roboto-bold-24px ">Complete</h1> : <h1 style={{ color: 'red' }} className="roboto-bold-24px ">InComplete</h1>}
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-3">
                          <button className="float-right btn btn-hover-white my-2 my-sm-0 text-uppercase " style={{ height: '55px', width: '100px', borderRadius: '30px' }} onClick={() => this.changeTab(3)} type="submit"><b>Edit</b></button>
                        </div>
                      </div>
                      <div className="container  mt-2">
                        <hr className="mx-auto" style={{ height: '1px', backgroundColor: '#cdcdcd' }} />
                      </div>
                      <div className="row mb-2">
                        <div className="col-sm-12 col-md-4 col-lg-6">
                          <h1 className="roboto-black-30px">Health Records Uploaded</h1>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-3">
                          <h1 className="float-sm-left roboto-bold-24px "> {files}</h1>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-3 clearfix">
                          <label className="float-right btn-outline-primary btn-hover-white text-center p-3 text-uppercase" onChange={this.uploadImage} style={{ height: '55px', width: '200px', borderRadius: '30px' }}>
                            <b>Upload</b><input type="file" hidden />
                          </label>
                        </div>
                      </div>
                      {/* <input type="text" class="file_input_textbox" readonly="readonly" value='No File Selected'> */}
                      {/* <input type="button" value="Browse" class="file_input_button" /> */}
                      {/* <input type="file" name="uploaded_file" class="file_input_hidden"/> */}
                    </div>
                    <div className="container pb-0 mb-0" style={{ fontWeight: 900, fontSize: '1.125rem', backgroundColor: '#e2f5f6', color: '#4f5be7', borderBottomRightRadius: '2rem', borderBottomLeftRadius: '2rem' }}>
                      <div className="container mt-5 pt-5 pb-0 mb-0">
                        {/* <img class="img-fluid" src="img\Health-History\family.png"> */}
                        {/* <img class="img-fluid" src="img\Health-History\family.png"> */}

                        {/* <div className="container d-flex justify-content-end mt-5">
                          <button type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mr-5 mb-5" style={{ height: '3.75rem', width: '8.3rem' }}>Share</button>
                        </div>*/}
                      </div>
                    </div>
                  </div></div></div></section>
            {/*eo gr-sec*/}
          </div>
      
        <div style={{ paddingTop: '12.25rem' }}>
        </div>
        <SubFooter/>
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
        <MobileFooter/>
      </div>
    )
  }
}
export default HealthHistory;
