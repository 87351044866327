import React from 'react';
import MainHeader from './MainHeader';
import Footer from './Footer';
import ReferProspect from './ReferProspect';
import MobileFooter from './MobileFooter';

import MetaTags from 'react-meta-tags';
import StickyContactMenu from './StickyContactMenu';
import OpenReferProspect from './OpenReferProspect';

import Cookies from 'js-cookie';

class CliniciansRefer2 extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  toLogin() {
        window.location.href="/clinicians_login";
    }

    
    toGeneticHelp() {

       window.location.href="/clinicians_genetic-help";
      }
  render() {
    return (
      

        <div>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
        {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.2/css/all.css" integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay" crossorigin="anonymous"> */}
        <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>truGeny</title>
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/header_footer.css" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <link rel="stylesheet" href="./css/sticky.css" />
        <MetaTags>
        <title>Genetic Testing Service - Refer your Patients</title>
		<meta name="description" content="Refer your patients to genetic tests by truGeny to identify an inherited condition or disease risk, and choose ways to prevent or treat a condition."/>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta property="og:title" content="Genetic Testing Service - Refer your Patients"/>
        <meta property="og:site_name" content="truGeny"/>
        <meta property="og:url" content="https://trugeny.com/"/>
        <meta property="og:description" content="Refer your patients to genetic tests by truGeny to identify an inherited condition or disease risk, and choose ways to prevent or treat a condition."/>
        <meta property="og:type" content="website"/>
        <meta property="og:image" content=""/>
</MetaTags>
        <style dangerouslySetInnerHTML={{__html: "\n/* @media only screen and (min-width: 1200px)\n{\n/* .custom-pad-mar {\n  padding-left:25rem;\n  padding-right:25rem;\n\n} */\n.genetics-banner-sec-wrap .genetics-banner-sec{\n  background: url(./img/clinicians/refer/1/bg-11.png) no-repeat center;-webkit-background-size: cover;\n  background-position:center;\n  background-size: cover;\n  background-repeat: no-repeat;\n}\n\n\n\n.gc-form-card-wrap .gc-form-card-btm{\n  background-color: #ccf1f1;\n  border-bottom-right-radius:0rem!important;\n  border-bottom-left-radius:0rem!important;\n}\n\n.pgc-form-card-wrap .gc-form-card-btm{\n  background-color: #fff;\n  border-bottom-right-radius:0rem!important;\n  border-bottom-left-radius:0rem!important;\n}\n\n" }} />
        <MainHeader category="genetic-counselling"></MainHeader>
      {/* eo-container */}
      <div style={{marginTop:'12rem'}}></div>
        <div className="container pt-0 pb-2 mt-0 d-none">
          <nav className="navbar container navbar-expand-xl  navbar-light d-none d-lg-block d-xl-block float-left">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon">
              </span>
            </button>
            <ul className=" row navbar-nav mr-auto ">
              <li className="nav-item ">
                <a className="nav-link float-right " href="clinicians.html">Clinicians</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="clinicians-genetic-counseling.html">Genetic Counselling </a>
              </li>
              <li className="nav-item">
                {/* <a class="nav-link" href="clinicians-medical-spec.html">Medical Specialities</a> */}
              </li>
              <li className="nav-item ">
                <a className="nav-link" href="clinicians-test-overview.html">Test</a>
              </li>
              <li className="nav-item ">
                <a className="nav-link" href="how-does-it-works.html">How does it work?</a>
              </li>
              <li className="nav-item ">
                {/* <a class="nav-link"   href="clinicians-cancer-test.html">Cancer Test</a> */}
              </li>
              <li className="nav-item ">
                {/* <a class="nav-link"  style="color:#4f5be7" href="clinicians-heart-test.html"><b>Heart Test</b></a> */}
              </li>
            </ul>
          </nav>
          <br />
          <br />
        </div>{/* eo-container */}
        <section className="genetic-Counseling-main-sec genetics-banner-sec-wrap">
          <div className=" genetics-banner-sec ">
            <div className="container pt-4 mt-2">
              <h1>Refer a Patient</h1>
              <p className="text-justify mt-3 mb-4">We Deliver a service that fits your needs.</p>
            </div>{/* eo-container*/}
            <div className="d-flex justify-content-center" id="secc1">
              <a style={{scrollBehavior: '[ auto | smooth ]'}} href="#secc1">  <img className="img-fluid d-none d-md-block d-lg-block btn-hover-white1" src="./img/111.png" /></a>
            </div>
          </div>{/* eo-heart-banner-sec */}
        </section>{/* eo-heart-banner-sec-wrap */}
        <StickyContactMenu></StickyContactMenu>
        {/* ------------------------------------------------------heart-banner-sec over------------------------------------------------------------------ */}
        <section className="genetics-informed-sec-main heart-disease-sec-main heart-disease-sec-main ">
          <div className="heart-disease-sec-main-wrap container">
            <div className="genetics-intro-text text-center">
              <h2 className=" text-center genetics-h2 ">How does it work?</h2>
            </div>{/* eo-intro-text-wrap*/}
            <div className=" heart-disease-sec row container">
              <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 d-block text-center  ">
                <img className="img-fluid mx-auto" src="./img/clinicians/refer/1/Illustration1.png" />
              </div>{/* eo-hd-img */}
              <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center">
                <div className="roboto-reg-18px">
                  <p>Once you recommend a patient, truGeny will contact the patient to schedule an appointment with our genetic counselling experts.<br /><br />During the counselling session, our genetic counsellor will conduct a thorough family history check to understand and determine any familial or hereditary trends.<br /><br />If there is any genetic test being considered for your patient, you will be informed. Additionally you will receive a summary of the counselling session and any other genetic test reports.</p>
                </div>
                {/* <p class="heartdisease-content ><b>  7 Lakh new cancer cases being registered each year in India.</b></p>
          <p class="heartdisease-content">  truGeny’s hereditary cancer test enables your patients to understand their genetic risks for developing cancers. When caught at an early stage, patient survival rates for cancers covered by truGeny’s hereditary cancer test increase significantly.</p> */}
              </div>{/* eo-hd-text */}
            </div>{/* eo-row */}
            <section className="gtk-sec d-none">
              <div className="container gtk-sec-wrap pb-0 gr-sec-wrap">
                <div className="gtk-main-header-wrap">
                  <h2 className="text-center">
                    How to get started
                  </h2>
                </div>{/* eo gr-header */}
                <div className="container gtk-card-main-wrap pt-5 mt-5  mb-5">
                  <div className="row">
                    <div className="gtk-card-wrap  col-12 col-md-6 col-lg-6 col-xl-6">
                      <div className=" card-1 shadow-lg r4 border-0 mb-5">
                        <div className="card-body p-0">
                          <h3 className="roboto-black-36px pt-5 px-0" style={{color: '#4f5be7'}}>Refer a Patient</h3>
                          <div className="card-top pb-0">
                            <div className="text-center py-lg-5">
                              <img className="img-fluid mx-auto" src="./img/Clinicians/refer/1/Illustration2.png" />
                            </div>{/* eo-hd-img */}
                          </div>{/* eo-card-top */}
                          <div className="card-btm ">
                            <p className="card-text">When you refer a patient to <b>truGeny</b> we can usually schedule an appointment (pre- or post-counseling) whithin 24-48 hours.</p>
                            <div className="she-btn text-center mx-auto">
                              <a className="btn btn-outline-primary btn-hover-white rounded-pill text-center " href="#" style={{color: 'white', backgroundColor: '#4f5be7'}} role="button"> Refer a patient now</a>
                            </div>
                          </div>{/* eo-card-btm */}
                        </div>{/* eo-card-body */}
                      </div>{/* eo-card-1 */}
                    </div>{/* eo-ns-card-wrap */}
                    <div className="gtk-card-wrap  col-12 col-md-6 col-lg-6 col-xl-6">
                      <div className=" card-1 shadow-lg r4 border-0 mb-5">
                        <div className="card-body p-0">
                          <h3 className="roboto-black-36px pt-5 mb-3" style={{color: '#4f5be7'}}>Just Want to Find Out More?</h3>
                          <div className="card-top">
                            <div className="text-center py-lg-5">
                              <img className="img-fluid mx-auto" src="./img/Clinicians/refer/1/Illustration3.png" />
                            </div>{/* eo-hd-img */}
                          </div>{/* eo-card-top */}
                          <div className="card-btm my-2">
                            <p className="card-text">When you refer a patient to <b>truGeny</b> we can usually schedule an appointment (pre- or post-counseling) whithin 24-48 hours.</p>
                            <div className="she-btn text-center mx-auto">
                              <a className="btn btn-outline-primary btn-hover-white rounded-pill text-center " href="#" role="button" style={{color: 'white', backgroundColor: '#4f5be7'}}> Connect with us</a>
                            </div>
                          </div>{/* eo-card-btm */}
                        </div>{/* eo-card-body */}
                      </div>{/* eo-card-1 */}
                    </div>{/* eo-ns-card-wrap */}
                  </div>{/* eo-row */}
                </div>{/* gr-card-wrap */}
              </div>{/* eo gr-sec-wrap */}
            </section>
          </div>{/* eo-heart-disease-sec-main-wrap*/}
        </section>{/* eo-heart-disease-sec-main */}
{/* end of gc-form-card-wrap */}
    {(Cookies.get("userId"))?
         <ReferProspect/>:<OpenReferProspect/>
        }
        {/* **********Schedule your Genetic Counseling Session Ends Here************** */}
        {/* Footer Links */}
        <Footer></Footer>
        <MobileFooter></MobileFooter>
        {/* -------------------------------------start q and a------------------------------ */}
        <div className="modal fade bd-example-modal-lg d-none" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content" style={{borderRadius: '40px'}}>
              <div className="modal-header bg-blue clearfix border-0" style={{borderTopRightRadius: '40px', borderTopLeftRadius: '40px'}}>
                <h5 className="modal-title" id="exampleModalLongTitle" style={{paddingTop: '5px', paddingBottom: '25px', color: '#2c333c', fontSize: '30px', fontWeight: 700}}>
                  Start your journey in 3 easy steps</h5>
                <button type="button" className="text-center close" data-dismiss="modal" aria-label="Close" style={{paddingTop: '25px', paddingBottom: '25px'}}>
                  <img className="img-fluid" src=".\img\registration\X.png" />
                </button>
                {/* <p class="float-left">Question 1 of 3</p> */}
              </div>
              <div className="bg-blue border-bottom-1">
                <p className="pl-3">Question 1 of 3</p>
              </div>
              <div className="modal-body pb-5 pt-0 m-0">
                <div className="container ">
                  <br />
                  <div className="tab-content">
                    <div id="home" className="container tab-pane active"><br />
                      <h3>How do you indentify your gender?</h3>          <hr />
                      <div className="d-flex d-flex-inline">
                        <img className="mr-3" src="./img/q&a/female.png" alt="" />
                        <img className="mr-3" src="./img/q&a/female.png" alt="" />
                      </div>
                      <div className="float-right pt-2 mt-2">
                        <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill" href="#menu1">Next</button>
                      </div>
                    </div>
                    <div id="menu1" className=" m-0 p-0 container tab-pane fade"><br />
                      <h3>Does anyone in your family have 2 diabetes?</h3><hr />
                      <h4>Certain ethnic groups have higher risk for diabetes,<br />
                        even at lower body weights.</h4>
                      <div className="col-xs-12 mt-2 ">
                        <div className="input-group-prepend " style={{border: 'solid 1px #ebebeb', maxWidth: '312px'}}>
                          <input type="text" placeholder="Select your ethnicity" className="form-control roboto-reg-16px" style={{border: 'none', height: '60px'}} aria-label="Text input with dropdown button" />
                          <button className="m-0 p-0 btn btn-outline-secondary roboto-bold-14px " href="#" style={{border: 'none', backgroundColor: 'white'}} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img className="m-0 p-0" src="img/q&a/carot.png" /></button>
                        </div>
                      </div>
                      <div className="float-right pt-2 mt-2">
                        <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill" href="#menu2">Next</button>
                      </div>
                    </div>
                    <div id="menu2" className=" m-0 p-0 container tab-pane fade"><br />
                      <h3>Does anyone in your family have 2 diabetes?</h3>
                      <hr />
                      <div className="radio">
                        <label><input type="radio" name="optradio" defaultChecked />  Parents</label>
                      </div>
                      <div className="radio">
                        <label><input type="radio" name="optradio" />  Siblings</label>
                      </div>
                      <div className="float-right pt-2 mt-2">
                        <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill"  href="#">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* sticky form---------------- */}
              {/* <div class="modal-footer">
                  <button type="button" class="btn btn-secondary"  data-toggle="pill" href="#menu2">Close</button>
                  <button type="button" class="btn btn-primary"  data-toggle="pill" href="#menu1">Next</button>
                </div> */}
            </div>
          </div>
        </div>
        {/* sticky form---------------- */}
       
        {/* --------eo sticky form----------------- */}
        {/* ----------------------------------------------------eo Q and A--------------------------------- */}
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>
    );
  }
}
export default CliniciansRefer2;
