import React from 'react';
import * as PayContants from './paymentContants';
import MetaTags from 'react-meta-tags';


class ThanksListen extends React.Component {
  render() {
    return (

        <div>

        <link rel="stylesheet" href="./landing.css" />

        <MetaTags>
        {/* <meta http-equiv="Refresh" content="25; url=https://trugeny.com/know-your-genes"/> */}

</MetaTags>



        <div className="pt-md-5"></div>
<div className="container background-pay-card mt-lg-5 mt-md-5 mt-sm-0 main-cover" >
<div className="container  d-flex justify-content-center pt-5" >

<a href="/" ><img className="align-self-center img-fluid p-5" src="./img/TruGeny_logo.png"/></a>
</div>

<div className="container  d-flex justify-content-center pt-3" >
          <h2 className="  text-center align-self-center  invite-1"  >
          Thank you. You’re Awesome.<br/>We will be in touch with you very soon.</h2>

</div>
 <p className="text-center roboto-bold-24px TruGeneColor"><b>meanwhile</b></p>
        <p className="text-center roboto-bold-24px">We are sure you love your friends and family.
        </p>
        <div className="text-center">
        <a className="text-white center-block btn btn-hover-white my-2 my-sm-0 center btn-hover-white roboto-black-30px invite text-center" onclick="window.location.href = 'mailto:info@trugeny.com';"><i className="far fa-envelope" /> Invite a friend or family member to benefit from truGeny </a>
        </div>
        <br />

        <div className="d-flex d-flex-inline justify-content-center ">
          <div className="container footer-wrap text-center">
            <div className="f-social-links">
              <ul className="social-links list-inline">
                <li className="list-inline-item">
                  <a  href={PayContants.facebookUrl} className="facebookColorWithBorder"><i className="fab fa-facebook fa-2x" /></a>
                </li>
                <li className="list-inline-item">
                  <a  href={PayContants.twitterUrl} className="icon-twitter fa-2x"><i className="fab fa-twitter" /></a>
                </li>
                <li className="list-inline-item">
                  <a href="whatsapp://send?text=Finally, you can control your health better https://trugeny.com/" data-action="share/whatsapp/share"  className="icon-google-plus fa-2x watsappColor"><i className="fab fa-whatsapp" /></a>
                </li>
                <li className="list-inline-item">
                  <a  href={PayContants.linkedinUrl} className="icon-linkedin fa-2x linkedInColor"><i className="fab fa-linkedin-in" /></a>
                </li>
                {/* <li class="list-inline-item">
              <a style="color:red" href="#" class="icon-google-plus fa-2x"><i class="fab fa-google"></i>
              </a>
            </li> */}
              </ul>
            </div>
            <p className="text-center">{PayContants.copyRight}</p>
          </div>
        </div>
        </div>

</div>






    );
  }
}
export default ThanksListen;
