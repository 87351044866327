import React from 'react';
import CommonHeder from './CommonHeder';
import MobileFooter from './MobileFooter';
import ProductCarouselCards from './ProductCarouselCards';
import {Link}  from 'react-router-dom';
import SubFooter from './SubFooter';


class ProductPage extends React.Component {


  constructor(props) {
    super(props);

   this.toOrderHeart=this.toOrderHeart.bind(this)

  }




  toOrderHeart() {
   // browserHistory.push("/ordertrugene");
    window.location.href="/ordertrugene";
   }

   toAllHelp() {
    // browserHistory.push("/ordertrugene");
     window.location.href="/al_help";
    }


  render() {

    return (
      <div>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
      <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
      {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossorigin="anonymous"> */}
      <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
      <title>truGeny</title>
      <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
      <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
      <link rel="stylesheet" href="./css/stylesheet_custom.css" />
      <link rel="stylesheet" href="stylesheet_custom.css" />
      <link rel="stylesheet" href="./css/fonts.css" />


      <link rel="stylesheet" href="./styleinline.css" />
      <link rel="stylesheet" href="style-mobile.css" />

      <link rel="stylesheet" href="./style-mobile2.css" />
      {/* header */}
      <CommonHeder category="product-page"></CommonHeder>

      {/* end of header */}
       <div style={{paddingBottom: '76px'}} className="padding22" />

        <div style={{marginTop: '8rem'}} className="margin"></div>


      {/* <section class="id-1" > */}
      <section className="id-1">
        <div className="container container-image" style={{}}>
          <div className="padding3" />
          <div className="gtk-main-header-wrap">
            <h2 className="text-center roboto-black-36px  ">
              truGeny brings you a new kind of DNA TEST
            </h2>
            <p className="text-center roboto-reg-30px pt-2">Discover insights from your DNA.<br /> Take actions for a healthier and longer life.</p>
            <h2 className="text-center roboto-reg-30px pt-md-5 pt-sm-2" style={{color: '#4f5be7'}}><b>Be PROACTIVE about your health.</b></h2>
            <div className="questions-btn text-center mx-auto">
              {/* <a class="btn btn-outline-primary btn-hover-white rounded-pill text-center " href="#" role="button">Order my truGeny Kit</a> */}
            </div>
          </div>{/* eo gr-header */}
          <div className="container p-0">
            <div className="card-deck" id="hide">
              {/* eo-card-deck-wrap */}
              <div className=" col-sm-12 col-md-12 col-lg-4 mb-md-5 mb-sm-2 py-md-5 py-sm-2 pr-0 pl-5">
                <div className="card rounded shadow-lg  r4 pCard">
                  <div className="  text-center p2Card">
                    <div className="card-block circle">
                      <img className="card-img-top text-center  " src="./img/payment/cancer.png" style={{height: '126px', width: '126px'}} alt="Cardcap" />
                      <h3 className="text-highlight pt-4 " style={{fontWeight: 900}}><strong>truFind</strong><br/><span className="textspancard" >Hereditary Cancer Test</span></h3>
                    </div>
                    <p>A detailed analysis of over 90 genes your genes to help you learn your risks for developing cancers of the breast, ovarian, uterine, colorectal, prostrate, pancreatic and stomach cancers.</p>
                    {/* <h3 class="font-card">Understand your genes and evaluate your risks for cancer and cardiac conditions.</h3> */}
                    {/* <a href="view-reports.html">   <img class="card-img-top text-center p-2 mb-1 button-view-report"  src="./img/payment/view-report-button.png"/></a> */}
                   <br/>
                    <Link to={{ pathname: '/ordertrugene', state: { orderedProduct:"trucancer"} }}>      <button style={{height: '40px', weight: '160px'}} className="mx-auto px-3  rounded-pill btn btn-outline-primary btn-hover-white mb-4" onClick={this.toOrderHeart}><b>ORDER NOW</b>  &nbsp;<i className="fas fa-long-arrow-alt-right" />&nbsp;</button></Link>
                    </div>
                </div>
              </div>{/* eo-card-deck-wrap */}
              <div className=" col-sm-12 col-md-12 col-lg-4 mb-md-5 mb-sm-2 py-md-5 py-sm-2">
                <div className="card  rounded shadow-lg r4 pCard">
                  <div className=" text-center p2Card">
                    <div className="card-block circle ">
                      <img className="card-img-top text-center " src="./img/career/familyplanning.png" style={{height: '126px', width: '126px'}} alt="Cardcap" />
                      <h3 className="text-highlight pt-4 " style={{fontWeight: 900}}><strong>truFamily</strong><br/><span className="textspancard" >Carrier Screening Test</span></h3>                   
                    </div>
                    <p className="mb-4">Planning for your future family’s health matters. Knowledge about your risk Of passing on a genetic condition to your Child can make a difference to the well being of your future children.</p>    
                    <br/>
                                                          {/* <h3 class="font-card">Activating your kit will only take a few minutes. You will need the barcode found on your saliva tube.</h3> */}
                    {/* <a href="view-reports.html">  <img class="card-img-top text-center mb-1 p-2" onclick="view-reports.html" src="./img/payment/view-report-button.png"/></a> */}
                    <Link to={{ pathname: '/ordertrugene', state: {orderedProduct:"trufamily" } }}>  <button style={{height: '40px', weight: '160px'}} className="mx-auto px-3 rounded-pill btn btn-outline-primary btn-hover-white mb-3" onClick={this.toOrderHeart} ><b>ORDER NOW</b>  &nbsp;<i className="fas fa-long-arrow-alt-right" />&nbsp;</button></Link>
                    </div>
                </div>
              </div>{/* eo-card-deck-wrap */}
              <div className=" col-sm-12 col-md-12 col-lg-4 mb-md-5 mb-sm-2 py-md-5 py-sm-2 pr-5 pl-0">
              <div className="card  rounded shadow-lg r4 pCard">
                  <div className=" text-center p2Card">
                    <div className="card-block circle ">
                      <img className="card-img-top text-center " src="./img/payment/heart.png" style={{height: '126px', width: '126px'}} alt="Cardcap" />
                      <h3 className="text-highlight pt-4 " style={{fontWeight: 900}}><strong>truHeart</strong><br/><span className="textspancard" >Coronary Artery Disease Test</span></h3>
                    </div>
                    <p className="mb-4">A next generation Polygenic Risk Score (PRS) based genetic test to you help you evaluate your risks for developing heart conditions such as familial Hypercholestrolemia, cardiomyopathy, Arrythmia.
                    </p>                                          {/* <h3 class="font-card">Activating your kit will only take a few minutes. You will need the barcode found on your saliva tube.</h3> */}
                    {/* <a href="view-reports.html">  <img class="card-img-top text-center mb-1 p-2" onclick="view-reports.html" src="./img/payment/view-report-button.png"/></a> */}
                    <Link to={{ pathname: '/ordertrugene', state: { orderedProduct:"truheart"} }}><button style={{height: '40px', weight: '160px'}} className="mx-auto px-3 rounded-pill btn btn-outline-primary btn-hover-white mb-3" onClick={this.toOrderHeart} ><b>ORDER NOW</b>  &nbsp;<i className="fas fa-long-arrow-alt-right" />&nbsp;</button></Link>
                    </div>
                </div>

              </div>
            </div>
            {/* ========================================================= */}
            <ProductCarouselCards></ProductCarouselCards>
            {/* ========================================================= */}
          </div>{/* gr-card-wrap */}
        </div>{/* eo gr-sec-wrap */}
      </section>
      <div style={{paddingBottom: '6rem'}}>
        <p className="text-center roboto-reg-18px mt-5 ">I am not very clear. Help me to understand</p>
        <p className="text-center mt-1 "><a style={{color: '#4f5be7'}} onClick={this.toAllHelp}>Learn more</a></p>
      </div>
      {/* </section> */}
     <SubFooter/>
      {/* eo Modal */}
      {/* Optional JavaScript */}
      {/* jQuery first, then Popper.js, then Bootstrap JS */}
      <MobileFooter/>
    </div>

    );
  }
}
export default ProductPage;
