import React from 'react';
import *as PayContants from './paymentContants';
import FamilyPlanningLandingFooter from './FamilyPlanningLandingFooter';
import axios from '../axios/axios';
import swal from 'sweetalert';
import CarouselCards2 from './CarouselCards2';

class FamilyPlanningLanding extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      pemail:'',
      pphone:'',
      pname:'',
      pmessage:'',

        errors:{}
      };
      this.baseState = this.state;

    this.prospectReg = this.prospectReg.bind(this);

}
prospect = (e) => {
  const prospectForm = this.state
  prospectForm[e.target.name] = e.target.value;
  this.setState(prospectForm);
}

validateForm() {
  let fields = this.state;
  let errors = {};
  let formIsValid = true;

  if (!fields["pname"]) {
    formIsValid = false;
    errors["pname"] = "*Please enter your name";
}


  if (!fields["pemail"]) {
      formIsValid = false;
      errors["pemail"] = "*Please enter your email address";
  }

  if (typeof fields["pemail"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(fields["pemail"])) {
          formIsValid = false;
          errors["pemail"] = "*Please enter valid email address";
      }
  }

  if (!fields["pphone"]) {
      formIsValid = false;
      errors["pphone"] = "*Please enter your mobile no.";
  }



  if (typeof fields["pphone"] !== "undefined") {
      if (!fields["pphone"].match(/^[0-9]{10}$/)) {
          formIsValid = false;
          errors["pphone"] = "*Please enter valid mobile no.";
      }
  }
  this.setState({
      errors: errors
  });
  return formIsValid;
}

prospectReg(e) {
  e.preventDefault();
    if (this.validateForm()) {
    const prospectForm = this.state
    prospectForm["source"] = "Family-Planning";
    this.setState(prospectForm);
      const { pname, pphone, pemail,pmessage ,source} = this.state;
      axios.post('users/prospect', { pname, pphone, pemail, pmessage,source })
          .then((response) => {
            this.setState(this.baseState);

            //   swal("Thank you for your interest. We will get back you soon...").then(
            //     setTimeout(() => {
            //      this.redirect()
            //   }, 3000)
            // );
            this.redirect()

          }).catch(error => {
            if(error.response===undefined){
              this.props.history.push("/error")
            }else{
              this.setState({
                  errorMsg: error.response.data.message
              })
            }

          });
          e.target.reset();
          this.setState(this.baseState);
        }


}
redirect(){
window.location.href="ThanksListen"
}


  render(){


      return(
       <div>
       <link rel="stylesheet" href="./style-clinicians.css" />


       <link rel="stylesheet" href="./styleinline.css" />
        <link rel="stylesheet" href="./style-mobile2.css" />
        <link rel="stylesheet" href="style-mobile.css" />

 <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K27BQ7L"
 height="0" width="0" class="fp22" ></iframe></noscript>


 <div className="fp">
   <header id="menu-head" >
     <div className="container ">
       <nav className="navbar navbar-light  justify-content-between pt-5">
         <a href="/" className="navbar-brand "><img className="d-none d-sm-block fp4" src="./img/family-planning/TruGeny_logo-mob.png" /></a>
         <a href="/" className="navbar-brand ml-0 pl-0"><img className="d-block d-sm-none ml-0 pl-0"  src="./img/family-planning/TruGeny_logo-mob.png" /></a>
         <a className="navbar-brand d-none "><img className="" src="./img/family-planning/logo-mob.png"/></a>


         <form className="menu-hero">
           <a href="mailto:info@trugeny.com" className="mr-sm-2 fp10"></a>
           <br/>


         </form>
       </nav>
         </div>

   </header>


 <div className="container">
   <div className="row pb-3 ">

   <div className="col-sm-7 mt-lg-5 mt-sm-1 pt-sm-1 fp30">
     <p className="text-center pFamilyLanding">Planning to start a family? <br/>Your Genes Matter.</p>
     <img className="img-fluid p-lg-3 p-md-3 p-sm-0 " src="./img/family-planning/4.png" />


   </div>

             <div className="col-sm-5 paddindivme">

               <div className=" col-sm divme px-0 ">
                    <form onSubmit={this.prospectReg}>
                             <div className=" hero-ravi justify-content-center px-1 pt-3 fp7" >

                               <p className="text-center fp27">Yes, I want an appointment.<br/> SIGN ME UP</p>

                               <div className="form-group">
                                 <label for="exampleInputEmail1 text-white"><small className="text-white pb-0 mb-0">Name *</small></label>
                                 <input type="text" className="form-control" id="exampleInputEmail1"  aria-describedby="emailHelp" name="pname" onChange={this.prospect} />
                                 <div className="landingformerror">{this.state.errors.pname}</div>

                               </div>
                               <div className="form-group">
                                 <label for="exampleInputPassword1 text-white"><small className="text-white pb-0 mb-0">Mobile Number *</small></label>
                                 <input type="tel" className="form-control" id="exampleInputPassword1"  pattern="[1-9]{1}[0-9]{9}"  onChange={this.prospect}  name="pphone" />
                                 <div className="landingformerror">{this.state.errors.pphone}</div>

                               </div>
                                <div className="form-group">
                                 <label for="exampleInputPassword1 text-white"><small className="text-white pb-0 mb-0">Email *</small></label>
                                 <input type="email" className="form-control" id="exampleInputPassword1"  name="pemail" onChange={this.prospect} />
                                 <div className="landingformerror">{this.state.errors.pemail}</div>

                               </div>

                             <div className="input-group-prepend mb-3 btn-custom1">
                               <button id="button1" className="btn btn-outline-primary  btn-hover-white mt-4 fp10 bLanding"  type="submit">Submit</button>

                             </div>
                           </div>
                           </form>
                         </div>

             </div>
         </div>
         <div className="row">
           <div className="col-sm-12 ">
             <p className="fp2">Genes are passed on from a parent to a child. Genes determine some of your physical
               attributes such as eye colour, skin colour, hair colour, height and so on. Genes also determine
               our health. At times, genes passed on by a parent can cause inherited conditions to the child.
               Almost all diseases including cancers, have a genetic component to them.</p>

               <p className="fp2">Not knowing if they are carrying a genetic condition can be worrisome for couples planning to
               have a baby, as they may appear to be healthy and there may be no symptoms of a disease.</p>
           </div>

         </div>
     </div>
 </div>

   <div className=" mb-3 mt-2 pt-5 pb-5 text-center-sm fp3" >
       <div className="fp33">
         <h1 className="text-center roboto-black-30px text-uppercase"><b  className="fp26">Take Control. Know your risk to take ACTION</b></h1>


         <p className="container text-center roboto-reg-24 fp12" > As a couple, learning about your risk of passing on a genetic condition to your future child
           before pregnancy will help you with wider range of options to make an informed decision.
         </p>
       </div>




   </div>




   <div className="container fp34" >
     <h1 className="text-center roboto-black-36px px-3 fp14" >By giving you deep insights about your Genes,
       truGeny helps you take control.<br/><br/>

       What do you get?</h1><br/><br/><br/>
       <div className="container op-card-wrap text-center">
            <div className="card-deck" id="hide">
              <div className="splitter col-sm-12 col-md-6 col-lg-6 col-xl-3 ">
                <div className="card card-1 rounded  shadow-lg p-1 mx-0 mb-5 r4">
                  <div className="card-body  text-center ">
                    <span className="card-number op-no-1">1</span>
                    <div className=" op-img-box  ">
                      <span className="op-img-wrap ">
                        <img className="card-img-top text-center" src="heart-img/calender-icon.png" alt="Card image cap" />
                      </span>
                    </div>
                    <h5 className="card-title">Schedule An Appointment</h5>
                    <p>It’s fast and easy to schedule your appointment online. You can do this from the comfort of your home.</p>
                  </div>
                </div>{/* eo card */}
              </div>{/* eo splitter */}
              <div className="splitter col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card card-2 rounded shadow-lg p-1 mx-0 mb-5 r4">
                  <div className="card-body  text-center ">
                    <span className="card-number op-no-2">2</span>
                    <div className=" op-img-box">
                      <span className="op-img-wrap">
                        <img className="card-img-top text-center" src="heart-img/call-out-icon.png" alt="Card image cap" />
                      </span>
                    </div>
                    <h5 className="card-title">Initial consultation </h5>
                    <p>We review your personal health history, answer your questions, and, if you are interested, help you select and order the right tests.</p>
                  </div>
                </div>{/* eo card */}
              </div>{/* eo splitter */}
              <div className="splitter col-sm-12 col-md-6 col-lg-6 col-xl-3 ">
                <div className="card card-3 rounded  shadow-lg p-1 mx-0 mb-5 r4">
                  <div className="card-body  text-center">
                    <span className="card-number op-no-3">3</span>
                    <div className=" op-img-box">
                      <span className="op-img-wrap">
                        <img className="card-img-top text-center" src="heart-img/sample-collection.png" alt="Card image cap" />
                      </span>
                    </div>
                    <h5 className="card-title">sample collection</h5>
                    <p>If you decide to have testing done, we will ship the sample collection kit to your preferred address.</p>
                  </div>
                </div>{/* eo card */}
              </div>{/* eo splitter */}
              <div className="splitter col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card card-4 rounded  shadow-lg p-1 mx-0 mb-5 r4">
                  <div className="card-body  text-center">
                    <span className="card-number op-no-4">4</span>
                    <div className=" op-img-box  ">
                      <span className="op-img-wrap">
                        <img className="card-img-top text-center" src="heart-img/results-icon.png" alt="Card image cap" />
                      </span>
                    </div>
                    <h5 className="card-title">results consultation</h5>
                    <p>Our certified genetic counsellors will review your test results, walk you through their implications, and answer your questions.</p>
                  </div>
                </div>{/* eo card */}
              </div>{/* eo splitter */}
            </div>{/* eo wyg-card-deck */}
            {/* ============================= */}
           <CarouselCards2></CarouselCards2>

      </div>
   </div>









     <div className="fp11">
       <div className="container fp5">
         <p className="text-center pt-5 pb-3 mb-0 fp25">I want to learn about my risk</p>
         <h4 className=" text-center  pt-5 pb-3 mb-0 l1 roboto-black-36px"><strong class="fp5">SCHEDULE MY APPOINTMENT</strong><br/><br/>
           </h4>
             <form className="needs-validation" onSubmit={this.prospectReg} >
               <div className=" col-sm divme px-0" >

                   <div className=" hero-ravi justify-content-center px-1 pt-3 " >



                     <div className=" hero-ravi justify-content-center px-1 pt-3 fp7">

                       <p className="text-center fp27">Yes, I want an appointment.<br/> SIGN ME UP</p>

                       <div className="form-group">
                         <label for="exampleInputEmail1 text-white"><small className="text-white pb-0 mb-0">Name *</small></label>
                         
                         
                         <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" name="pname" onChange={this.prospect} />
                         <div className="landingformerror">{this.state.errors.pname}</div>

                       </div>
                       <div className="form-group">
                         <label for="exampleInputPassword1 text-white"><small className="text-white pb-0 mb-0">Mobile Number *</small></label>
                         <input type="tel" className="form-control" id="exampleInputPassword1" pattern="[1-9]{1}[0-9]{9}" onChange={this.prospect}  name="pphone" />
                         <div className="landingformerror">{this.state.errors.pphone}</div>

                       </div>
                        <div className="form-group">
                         <label for="exampleInputPassword1 text-white"><small className="text-white pb-0 mb-0">Email *</small></label>
                         <input type="email" className="form-control" id="exampleInputPassword1" name="pemail" onChange={this.prospect} />
                         <div className="landingformerror">{this.state.errors.pemail}</div>

                       </div>

                     <div className="input-group-prepend mb-3 mt-3 btn-custom1">
                       <button id="button1" className="btn btn-outline-primary  btn-hover-white mt-4 fp10 bLanding"  type="submit">Submit</button>

                     </div>

                   </div>
               </div>
               </div>


             </form>
             <h1 className="text-center pt-3 fp16" ><strong > </strong></h1>
             <div className="fp32">
                 <h4 className=" text-center  py-3 fp17" ><strong className="fp31" >Connect us at</strong></h4>
                     <p className=" text-center fp25" ><a href={PayContants.truGenyMailhref}><i class="fa fa-envelope pr-2"></i>{PayContants.truGenyMail}</a></p>
                     <p className=" text-center fp25">
                     <a className="text-lowercase" id="register" href={PayContants.truGenyHrefConatct}>
                     <i className="fa fa-phone-volume pr-2" >
                     </i>
                     {PayContants.truGenyConatct}</a></p>
                     <br/>
             </div>


             <h1 className="text-center pt-3 "> <img className="mt-2 img-fluid" height="150px" width="250px" src="./img/family-planning/TruGeny_logo.png"/></h1>
             <div className="text-center fp19" >
               <a className="roboto-bold-14px text-center  pr-5 pl-5 mr-5 mx-auto p-3 btn btn-outline-primary btn-hover-white text-white fp18" href="/family-planning"
                 > Learn More</a>
             </div>
       </div>




         </div>
      <FamilyPlanningLandingFooter/>


       </div>

)
     }

   }
   export default FamilyPlanningLanding;
