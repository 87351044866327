import React from 'react';
import Header from './Header';
import axios from '../axios/axios';
class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            firstName:undefined,
            lastName:undefined
        }
    }

    componentDidMount() {

    
        axios.get('users/user/email/rohtek@gmail.com', 
        { headers: { Authorization: `Bearer ${localStorage.getItem("jwt-token")}` } }
        )
            .then((response) => {
        this.setState(
            {
                firstName:response.data.firstName,
                lastName:response.data.lastName
            }
        )
          });
    }
    render() {

        return (
            <div>
                <Header></Header>
                <h5>User Info......</h5>
                <table>
                    <tbody>
                    <tr>
                    <td>First Name :</td>
                    <td>{this.state.firstName}</td>
                    </tr>
                    <tr>
                    <td>Last Name :</td>
                    <td>{this.state.lastName}</td>
                    </tr>
                    </tbody>
                </table>

            </div>
        );
    }

}
export default Users;