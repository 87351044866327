import React from "react";
import Cookies from "js-cookie";
import Calendar from "react-calendar";
import axios from "../axios/axios";
import { css } from "@emotion/core";
import moment from "moment";
import Loader from "./Loader";
import changeCase from "change-case";
import swal from "sweetalert";
import MobileFooter from "./MobileFooter";
import * as PayContants from "./paymentContants";
import SubFooter from "./SubFooter";
import NewMainHeader from "./NewMainHeader";
import jwt_decode from "jwt-decode";
import { MoonLoader, FadeLoader } from "react-spinners";
import { UncontrolledAlert } from "reactstrap";
import Badge from "react-bootstrap/Badge";
import { UncontrolledTooltip } from 'reactstrap';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
class BookCounsellingIndex extends React.Component {
  constructor(props) {
    super(props);
    this.bookCouncelling = this.bookCouncelling.bind(this);
    this.state = {
      isActive: 1,
      visible:false,
      update: "readOnly",
      orderId: "",
      selectOption: false,
      date: new Date(),
      selectedDate: new Date().toLocaleDateString(),
      timeslots: [],
      loading: false,
      selectedTime: "",
      scheduldeButton: true,
      selectedAppdate: new Date(),
      contactNo: "",
      errors: {},
      data: [],
      productId: "",
      productName: "",
      unitPrice: "",
      currency: "",
      updateType: false,
      session: "",
      sessionType: "",
      emailData: "",
      emailStatus: "",
      loading2: false,
      isOpen: false,
      modalIsOpen: false,

      modal: false,
      createPwd: false,
      // otp: "",
      // otpstatus: false,

      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      confirmPwd: "",
      errorMsg: null,

      loginForm: {},
      loginErrors: {},
      passwordType: "",
      otpmessage: "",
      mobUserId: null,
      loginType: "",
      reOrderId:null,
      serviceId:null,
      reCartId:null,
      previousProductOrderId:null,
      purchaseButtonDis:true,
      //offers logis start

      offerDatesData:[],
      offerDates:[],
      offerStartDate:'',
      offerEndDate:'',
      offerName:'',
      offerForProduct:'',
      offerForProduct1:'',
      offerForProduct2:'',
      offerFree:'',
      offerPrice:'',
      offerSession:'',
      offerId:'',

      //offer logic end
    

    };

    this.baseState = this.state;
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onSelectTime = this.onSelectTime.bind(this);
    this.orderAppointment = this.orderAppointment.bind(this);
    this.getUserContactNo = this.getUserContactNo.bind(this);
    this.onChangeContactNo = this.onChangeContactNo.bind(this);
    this.checkContact = this.checkContact.bind(this);
    this.registration = this.registration.bind(this);
    this.changeTab = this.changeTab.bind(this);
    
    this.onChange = this.onChange.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.emailVerification = this.emailVerification.bind(this);
    this.scheduleAppointmentOpen = this.scheduleAppointmentOpen.bind(this);
    this.getAllProducts = this.getAllProducts.bind(this);
    this.orderAppointmentOpen = this.orderAppointmentOpen.bind(this);
    this.updateScheduleAppointment = this.updateScheduleAppointment.bind(this);
    this.updateContact = this.updateContact.bind(this);
    this.getOffer = this.getOffer.bind(this);
    this.offerSetup = this.offerSetup.bind(this);
    this.freePurchase = this.freePurchase.bind(this);

  }

  componentDidMount() {
    
    if (Cookies.get("userId")) {
      if (this.state.isActive !== 4) {
        this.props.history.push("/book-counselling");
      }
    }
    if (this.props.location.state) {
      if (this.props.location.state.session !== "undefined") {
        this.setState(
          {
            isActive: 2,
            selectOption: true,
            selectedPlan: this.props.location.state.selectedPlan,
            selectedPlanName: this.props.location.state.selectedPlanName
          },
          () => {     
            this.getOffer();
            
          }
        );
      }
    }

    if (this.state.selectedPlanName) {
      this.props.history.push("/product-page-demo");
    }
    this.onChangeDate(this.state.date);
    this.getOffer();
    this.getAllProducts();
  }
  getAllProducts(){
    axios
    .get("productservice/allProductDetails", {
      headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` },
      Accept: "application/json",
      "Content-Type": "application/json"
    })
    .then(
      response => {
        this.setState({
          data: response.data,
          unitPrice: response.data.unitPrice,
          productId: response.data.productId,
          value1:response.data[1].unitPrice,
          plan1:response.data[1].productName,
          value2:response.data[2].unitPrice,
          plan2:response.data[2].productName,
          
        })
      },
      () => {}
    ).catch(function(error) {});
  }


  getOffer(){
    //offer logic
    axios.get("offerDates/getOfferByService")
    .then((response) => {
      var offerForProduct=response.data.offerForProduct;
      var offerForProduct1="";
      var offerForProduct2="";
      if(/[,]/g.test(offerForProduct)){
      offerForProduct=response.data.offerForProduct.toString().split(",");
       offerForProduct1=response.data.offerForProduct.toString().split(",")[0];
       offerForProduct2=response.data.offerForProduct.toString().split(",")[1];
      }
 
        this.setState({
          offerDates: response.data,
          offerStartDate: response.data.offerStartDate,
          offerEndDate: response.data.offerEndDate,
          offerName:response.data.offerName,
          offerForProduct1:offerForProduct1,
          offerForProduct2:offerForProduct2,
          offerForProduct:offerForProduct[0],
          note:response.data.note,
          priceType:response.data.priceType
        },()=>{this.offerSetup(this.state.date)})
 
        var listDate = [];
        var startDate = response.data.offerStartDate;
        var endDate = response.data.offerEndDate;
        var dateMove = new Date(startDate);
        var strDate = startDate;
        
        while (strDate < endDate){
          var strDate = dateMove.toISOString().slice(0,10);
          listDate.push(strDate);
          dateMove.setDate(dateMove.getDate()+1);
        };
 
 
 this.setState({
         offerDatesData:listDate
        },()=>{this.offerSetup(this.state.date)});
      }
    )
 
    //offer code end here
 
 }

 offerSetup(date){
  const { offerDatesData, offerForProduct, offerForProduct1, offerForProduct2, priceType, offerDates,selectedPlanName } = this.state;

  this.setState({offerPrice:'',offerFree:'',offerId:''},()=>{})
  
  if(offerDatesData.find(x=>x===moment(date).format("YYYY-MM-DD"))&&(selectedPlanName===offerForProduct1||selectedPlanName===offerForProduct2||selectedPlanName===offerForProduct)){
    if(this.state.priceType==="free"){
      this.setState({offerPrice:offerDates.offerPrice,offerFree:priceType,offerId:offerDates.dateId},()=>{})
    }
    else{
      this.offerPriceValue()
      // this.setState({offerPrice:this.state.offerDates.offerPrice},()=>{})
    }

  }

}
offerOverAll(date) {
  const { offerDatesData, offerForProduct, offerForProduct1, offerForProduct2, priceType, offerDates } = this.state;
  this.setState({ offerPrice: -1, offerFree: '',offerId:'' }, () => { })
  if (offerDatesData.find(x => x === moment(date).format("YYYY-MM-DD")) && (offerForProduct1 || offerForProduct2 || offerForProduct)) {
    if (priceType === "free") {
      this.setState({ offerPrice: 0, offerFree: priceType,offerId:offerDates.dateId }, () => { })
    }
    else {
      // this.offerPriceValue()
      this.setState({ offerPrice: offerDates.offerPrice,offerId:offerDates.dateId }, () => { })
    }
  }
}

offerPriceValue() {
  
  const {offerDates, value1, value2, plan1, plan2 ,selectedPlanName} = this.state;
  if (selectedPlanName === plan1) {
    const value1discount = (value1 * offerDates.offerPrice)/100;
    this.setState({ offerPrice:value1discount,offerId:offerDates.dateId }, () => { })
  }
   if (selectedPlanName === plan2) {
    const value2discount = (value2 * offerDates.offerPrice)/100;
    this.setState({ offerPrice: value2discount,offerId:offerDates.dateId }, () => {  })
  }
}



  selectedPlan(e, value) {
      this.setState(
      {
        isActive: value,
        selectOption: true,
        selectedPlan: e.currentTarget.value,
        selectedPlanName: e.currentTarget.name
      },
      () => {this.offerSetup(this.state.date)}
    );
  }
  editPlan(e) {

    this.setState(
      {
        selectOption: true,
        selectedPlan: e.currentTarget.value,
        selectedPlanName: e.currentTarget.name
      },
      () => {
        let gotDate= moment(this.state.selectedDate,"DD-MM-YYYY").format("YYYY-MM-DD")
        //  let updateDate= moment(this.state.selectedDate).format("DD-MM-YYYY")
  
        //   let present =moment(  this.state.date, moment.defaultFormat).toDate();
          let dates =moment(  gotDate, moment.defaultFormat).toDate();
  
          // console.log("type",gotDate,updateDate,this.state.selectedDate)
         
  
          this.offerSetup(dates);
        this.orderAppointment();
      }
    );
  }

  changeDateFormate() {
    this.setState(
      { selectedDate: moment(this.state.selectedDate).format("DD-MM-YYYY") },
      () => { }
    );
  }

  getUserContactNo() {
    axios
      .get("users/user/" + Cookies.get("userId"), {
        headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` }
      })
      .then(response => {
        if (response.data) {
          this.setState({ contactNo: response.data["phone"] }, () => {
            this.subStringContact();
          });
        }
      })
      .catch(function (error) { });
  }

  subStringContact() {
    this.setState({ contactNo: this.state.contactNo.substr(3) }, () => { });
  }

  checkContact() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    if (fields["contactNo"]) {
      if (!fields["contactNo"].match(/^[0-9]{10}$/)) {
        formIsValid = false;
        errors["contactNo"] = "*Please enter valid mobile no.";
        this.setState({ update: "", errors: errors,purchaseButtonDis:true }, () => { });
      } else {
        this.setState({ update: "save", errors: errors });
        if (this.state.selectedTime) {
          this.setState({ purchaseButtonDis: false }, () => { });
        }
      }
    } else {
      formIsValid = false;
      errors["contactNo"] = "*Please enter  mobile no.";
      this.setState({ update: "save", errors: errors });
      if (this.state.selectedTime) {
        this.setState({ purchaseButtonDis: true }, () => { });
      }
    }
    if(formIsValid&&this.state.appointmentId){
      this.updateContact()
    }

    return formIsValid;
  }

  onChangeContactNo(event) {
    const state = this.state;
    state[event.target.name] = event.target.value;
    this.setState({ state });
  }

  onChangeDate(date) {

    this.offerSetup(date);
    this.setState(
      {
        loading: true,
        selectedDate: moment(date).format("DD-MM-YYYY"),
        selectedAppdate: date
      },
      () => { }
    );
    axios
      .post("/schedule/getAvailableSlots", {
        dateOfAppointment: moment.utc(date).format("YYYY-MM-DDTHH:mm:ss.SSS"),
        dateOfselection: moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS")
      })
      .then(response => {
        this.setState({ loading: false, timeslots: response.data }, () => { });
      });
  }

  bookCouncelling() {
    this.props.history.push("/book-counselling-schedule");
  }

  changeTab(value) {
    if (value === 6) {
      return null;
    }
   

    this.setState({ isActive: value }, () => {
      if (Cookies.get("userId")) {
        if (this.state.isActive===3) {
          window.location = "/book-counselling";
        }
      }
    });
  }

  onSelectTime(e) {
    this.setState({ selectedTime: e.target.value }, () => { });
   
  }

  scheduleAppointmentOpen() {
    if (!this.state.selectedPlanName) {
      swal("Please Select Plane").then(
this.changeTab(1)
        );
    } 
    if(Cookies.get("userId")){
      this.changeTab(4)

    }
    this.changeTab(3)
  }

  updateScheduleAppointment() {
    this.setState({ scheduldeButton: true }, () => {});
    const customerId = Cookies.get("userId");

    axios
      .post(
        "schedule/customer/" + customerId,
        {
          appointmentDate: moment(this.state.selectedAppdate).format(
            "YYYY-MM-DDTHH:mm:ss.SSS"
          ),
          startTime: this.state.selectedTime,
          appointmentId: this.state.appointmentId,
          appointmentcontactNo: this.state.contactNo,
          appointmentStatus: "SCHEDULED"
        },
        { headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` } }
      )
      .then(response => {
        this.setState(
          {
            doctorId: response.data.doctorId,
            appointmentId: response.data.appointmentId
          },
          () => {}
        );
        this.changeTab(4);
      })
      .catch(error => {
        if (error.response === undefined) {
          this.props.history.push("/error");
        } else {
          this.setState({ errorMsg: error.response.data.message });
          swal(this.state.errorMsg);
          swal("Please Select New Slot");
        }
      });
  }


  updateContact() {
    this.setState({ scheduldeButton: true }, () => {});
    const customerId = Cookies.get("userId");

    axios
      .post(
        "schedule/customercontact",
        {
        
          appointmentId: this.state.appointmentId,
          appointmentcontactNo: this.state.contactNo,
        
        },
        { headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` } }
      )
      .then(response => {
        this.setState(
          {
            doctorId: response.data.doctorId,
            appointmentId: response.data.appointmentId
          },
          () => {}
        );
        this.changeTab(4);
      })
      .catch(error => {
        if (error.response === undefined) {
          this.props.history.push("/error");
        } else {
          this.setState({ errorMsg: error.response.data.message });
          swal(this.state.errorMsg);
          swal("Please Select New Slot");
        }
      });
  }

  orderAppointmentOpen() {
    const {
      appointmentId,

      serviceId,
      reOrderId,
      reCartId,
      sessionType,
      email,
      offerFree,
      offerName,
      offerId
    } = this.state;
    const appointmentStatus = "SCHEDULED";
    const appointmentDate = moment(this.state.selectedAppdate).format(
      "YYYY-MM-DDTHH:mm:ss.SSS"
    );
    const appointmentcontactNo = this.state.contactNo;
    const productName = this.state.selectedPlanName;
    const startTime = this.state.selectedTime;

    const customerId = Cookies.get("userId");
    axios
      .post(
        "users/openBooking",
        {
          appointmentDate,
          startTime,
          appointmentId,
          appointmentcontactNo,
          productName,
          serviceId,
          reOrderId,
          reCartId,
          sessionType,
          customerId,
          email,
          appointmentStatus,
          offerFree,
          offerName,
          offerId
        },
        {
          headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` }
        }
      )
      .then(response => {
        this.setState(
          {
            doctorId: response.data.doctorId,
            orderId: response.data.razorOrderId,
            updateType: true,
            reOrderId: response.data.reOrderId,
            reCartId: response.data.reCartId,
            modalIsOpen: false,
            appointmentId:response.data.appointmentId,
            offerId:this.state.offerId

          },
          () => { }
        );
        // if (response.data.doctorId) {
        //   this.getCounsellorDetails();
        // }
        this.changeTab(4);
      })
      .catch(error => {
        if (error.response === undefined) {
          this.props.history.push("/error");
        } else {
          this.setState({ errorMsg: error.response.data.message });
          swal(this.state.errorMsg)
        }
      });
  }
  
  orderAppointment() {
    axios
      .post(
        `cartservice/` + Cookies.get("userId") + "/appointmentOrder",
        {
          productName: this.state.selectedPlanName,
          serviceId: this.state.appointmentId,
          reOrderId: this.state.reOrderId,
          reCartId: this.state.reCartId,
          sessionType:this.state.sessionType
        },
        {
          headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` }
        }
      )
      .then(response => {
        this.setState(
          {
            doctorId: response.data.doctorId,
            orderId: response.data.razorOrderId,
            updateType: true,
            reOrderId: response.data.orderId,
            reCartId: response.data.cardId
          },
          () => {}
        );
        // if (response.data.doctorId) {
        //   this.getCounsellorDetails();
        // }
        this.changeTab(4);
      })
      .catch(error => {
        if (error.response === undefined) {
          this.props.history.push("/error");
        } else {
          this.setState({ errorMsg: error.response.data.message }, () => {});
        }
      });
  }

  /**registartion */

  

  onChange = e => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onChangeEmail = e => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  handleChange = e => {
    const loginForm = this.state;
    loginForm[e.target.name] = e.target.value;
    this.setState(loginForm);
  };

  handleOnChange(value) {
    this.setState({ phone: value });
    this.emailVerification();
  }

  checktermsAndConditions = e => {
    const loginForm = this.state;
    if (e.target.checked) {
      loginForm[e.target.name] = 1;
    } else {
      loginForm[e.target.name] = 0;
    }
    this.setState(loginForm);
  };

  

  /* Registration Form Validation */

  validateForm() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;

    if (!fields["firstName"]) {
      formIsValid = false;
      errors["firstName"] = "*Please enter your first name.";
    }

    if (typeof fields["firstName"] !== "undefined") {
      if (!fields["firstName"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["firstName"] = "*Please enter alphabet characters only.";
      }
    }
    if (!fields["lastName"]) {
      formIsValid = false;
      errors["lastName"] = "*Please enter your last name.";
    }

    if (typeof fields["lastName"] !== "undefined") {
      if (!fields["lastName"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["lastName"] = "*Please enter alphabet characters only.";
      }
    }

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "*Please enter your email address";
    }

    if (typeof fields["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "*Please enter valid email address";
      }
    }

    if (!fields["phone"]) {
      formIsValid = false;
      errors["phone"] = "*Please enter your mobile no.";
    }

    if (typeof fields["phone"] !== "undefined") {
      if (!fields["phone"].match(/^[0-9]{10}$/)) {
        formIsValid = false;
        errors["phone"] = "*Please enter valid mobile no.";
      }
    }

    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "*Please enter your password.";
    }

    if (
      typeof fields["password"] !== "undefined" &&
      fields["password"] !== ""
    ) {
      if (
        !fields["password"].match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/)
      ) {
        formIsValid = false;
        errors["password"] = "*Please enter secure and strong password.";
      }
    }
    if (!fields["confirmPwd"]) {
      formIsValid = false;
      errors["confirmPwd"] = "*Please confirm password.";
    }
    if (fields["password"] !== fields["confirmPwd"]) {
      formIsValid = false;
      errors["confirmPwd"] = "*Password mismatched";
    }

    if (fields["terms"] === 0 || fields["terms"] === undefined) {
      formIsValid = false;
      errors["terms"] = "*Please accept terms and conditions";
    }

    // if(!this.state.appointmentDate){
    //   formIsValid = false;

    //   swal("Please Select AppointmentDate")
    // }
    // if(!this.state.startTime){
    //   formIsValid = false;

    //   swal("Please Select Time")
    // }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  createPassword() {
    this.setState({ createPwd: true }, function () { });
    this.setState({ otpstatus: false }, function () { });
    this.setState({ passwordType: "password" }, function () { });
  }

  loader2() {
    return (
      <div className="sweet-loading">
        <MoonLoader
          css={override}
          sizeUnit={"px"}
          size={50}
          color={"#123abc"}
          loading={this.state.loading2}
        />
      </div>
    );
  }

  emailVerification() {
    let formIsValid = true;
    // this.setState({loading2:true},()=>{}
    // )
    let id = this.state.email;
    if (id) {
      this.setState({visible:true},()=>{
        window.setTimeout(()=>{
          this.setState({visible:false})
        },2000)
      });
      (async () => {
        const rawResponse = await fetch(
          "https://api.zerobounce.net/v2/validate?api_key=87ba3889be724555ad29d62183ed6334&email=" +
          id +
          "&ip_address=",
          {
            method: "GET"
          }
        );
        const content = await rawResponse.json();
        if (content) {
          this.setState({ loading2: false }, () => { });
        }
        if (content.status === "valid") {
          this.setState(
            {
              emailData: content,
              emailStatus: content.status
            },
            () => { }
          );
        } else if (content.status === "invalid") {
          this.setState({ emailStatus: content.status }, () => { });
          formIsValid = false;
        }
      })();

      return formIsValid;
    }
  }

  /* saving user registration details*/
  registration(e) {
    e.preventDefault();
    if (this.validateForm() && this.state.emailStatus === "valid") {
      let group = "native";
      let userType = "CUSTOMER";
      const {
        firstName,
        lastName,
        email,
        phone,
        password,
        confirmPwd,
        passwordType,
        loginType
      } = this.state;
      this.setState({ loading: true,contactNo:phone,purchaseButtonDis:false }, () => { });
      axios
        .post("users/openreg", {
          firstName,
          lastName,
          email,
          phone,
          password,
          confirmPwd,
          group,
          passwordType,
          userType,
          loginType
        })
        .then(response => {
          this.setState({loading: false });
          if (
            jwt_decode(response.data.token).scopes[0].authority === "CUSTOMER"
          ) {
            Cookies.set("scope", "CUSTOMER");
            Cookies.set("jwt-token", response.data.token, { expires: 1 });
            Cookies.set("username", jwt_decode(response.data.token).firstName);
            Cookies.set("userId", jwt_decode(response.data.token).userId);

            // this.props.history.push('/loginSuccess');
          } else {
            swal("You are not a customer");
          }

          this.orderAppointmentOpen();

        })
        .catch(error => {
          if (error.response === undefined) {
            this.setState({ loader: false, loading: false }, () => { });
            this.props.history.push("/error");
          } else {
            this.setState({
              loader: false,
              loading: false,
              errorMsg: error.response.data.message
            });
            swal(this.state.errorMsg);
          }
        });
      e.target.reset();
    }
  }

  loader() {
    return (
      <div className="sweet-loading">
        <FadeLoader
          css={override}
          sizeUnit={"px"}
          size={100}
          color={"#123abc"}
          loading={this.state.loading}
        />
      </div>
    );
  }

  alertEmail() {
    return (
      <div>
        <UncontrolledAlert isOpen={this.state.visible} color="info">Email verified...!!</UncontrolledAlert>
      </div>
    );
  }

  alert() {
    return (
      <div>
        <UncontrolledAlert color="warning">
          Please select any counselling plan!
        </UncontrolledAlert>
      </div>
    );
  }

  alert2() {
    return (
      <div>
        <UncontrolledAlert isOpen={this.state.visible} color="danger">Invalid email id!</UncontrolledAlert>
      </div>
    );
  }

  
  freePurchase() {
    this.setState({ scheduldeButton: true }, () => {});
    let orderId = this.state.reOrderId;
    axios
      .get(`cartservice/` + orderId + "/freePurchase", {
        headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` }
      })
      .then(response => {
        swal("Your Appointment Scheduled Successfully");
        this.props.history.push("/bb-payment-success");

        
      })
      .catch(error => {
        if (error.response === undefined) {
          this.props.history.push("/error");
        } else {
          swal(error.response.data.message);
        }
      });
  }

  render() {
       //offer dates
       var offerPrice = this.state.offerPrice
       var listDate = [];
       var startDate =this.state.offerStartDate;
       var endDate = this.state.offerEndDate;
       var dateMove = new Date(startDate);
       var strDate = startDate;
       
       while (strDate < endDate){
         var strDate = dateMove.toISOString().slice(0,10);
         listDate.push(strDate);
         dateMove.setDate(dateMove.getDate()+1);
       };
       

       //end offer dates

    let gstAmt = (this.state.selectedPlan * 0) / 100;
    let razorpayOrderId = this.state.orderId;
    let scheduldeButtonType;
    let purchaseButton;
  if(this.state.appointmentId){
    scheduldeButtonType = (
      <button
        className="float-right btn btn-hover-white my-2 my-sm-0 center  mt-5 bookingButton"
        href="#messages"
        data-toggle="tab"
        type="submit"
        disabled={!this.state.selectedTime}
        onClick={this.updateScheduleAppointment}
      >
        <b>Update & Continue</b>
      </button>
    );

  }
  else{
    scheduldeButtonType = (
      <button
        className="float-right btn btn-hover-white my-2 my-sm-0 center  mt-5 bookingButton"
        href="#messages"
        data-toggle="tab"
        type="submit"
        disabled={!this.state.selectedTime}
        onClick={this.scheduleAppointmentOpen}
      >
        <b>Schedule & Continue</b>
      </button>
    );
  }
if(this.state.offerFree==="free"){
  purchaseButton = (
    <div className="col-md-4 col-sm-12 col-lg-4 ">
      <button
        className="float-right btn btn-hover-white my-2 my-sm-0 center  mt-5"
        id="con1"
        href="#settings"
        data-toggle="tab"
        style={{
          height: "60px",
          width: "210px",
          borderRadius: "30px"
        }}
        type="submit"
        disabled={this.state.update ? false : true}
        onClick={this.freePurchase}
      >
        <b>Free Purchase</b>
      </button>
    </div>
  );
}

else{
    purchaseButton = (
      <div className="col-md-4 col-sm-12 col-lg-4 ">
        <form method="POST" action={PayContants.action}>
          <input
            type="hidden"
            name="key_id"
            defaultValue={PayContants.key_id}
          />
          <input type="hidden" name="order_id" defaultValue={razorpayOrderId} />
          <input
            type="hidden"
            name="name"
            defaultValue={PayContants.pay_name}
          />
          <input
            type="hidden"
            name="description"
            defaultValue={PayContants.pay_description}
          />
          <input
            type="hidden"
            name="prefill[name]"
            defaultValue={PayContants.pay_profileName}
          />
          <input
            type="hidden"
            name="prefill[contact]"
            defaultValue={PayContants.pay_contact}
          />
          <input
            type="hidden"
            name="prefill[email]"
            defaultValue={PayContants.pay_email}
          />
          <input
            type="hidden"
            name="notes[shipping address]"
            defaultValue={PayContants.pay_address}
          />
          <input
            type="hidden"
            name="callback_url"
            defaultValue={PayContants.callback_url}
          />
          <input
            type="hidden"
            name="cancel_url"
            defaultValue={PayContants.cancel_url}
          />
          <button
            className="float-right btn btn-hover-white my-2 my-sm-0 center  mt-5"
            id="con1"
            href="#settings"
            data-toggle="tab"
            style={{
              height: "60px",
              width: "210px",
              borderRadius: "30px"
            }}
            type="submit"
            disabled={this.state.purchaseButtonDis}
          >
            <b>Purchase</b>
          </button>
        </form>
      </div>
    );}

    return (
      <div>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        {/* <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css"> */}
        <link rel="stylesheet" href="./css/bootstrap/w3.css" />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i"
          rel="stylesheet"
        />
        {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css"integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossorigin="anonymous"> */}
        <link
          rel="stylesheet"
          href="./fonts/fontawesome-free-5.8.2-web/css/all.css"
          type="text/css"
        />
        <link
          rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
          integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
          crossOrigin="anonymous"
        />
        <link
          href="https://www.jqueryscript.net/css/jquerysctipttop.css"
          rel="stylesheet"
          type="text/css"
        />
        <title>truGeny</title>
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <link
          href="https://www.jqueryscript.net/css/jquerysctipttop.css"
          rel="stylesheet"
          type="text/css"
        />
        <link rel="stylesheet" href="./css/jquery.calendar.css" />
        <link rel="stylesheet" href="style-clinicians.css" />
        <link rel="stylesheet" href="./styleinline.css" />
        <link rel="stylesheet" href="./style-mobile2.css" />
        <link rel="stylesheet" href="style-mobile.css" />
        <style
          dangerouslySetInnerHTML={{
            __html:
              '\n  .hello {\n    /* margin-top: 6rem; */\n    box-shadow: 0 17px 73px rgba(0, 0, 0, 0.57);\n    border-radius: 40px;\n    background-color: #eff7ff;\n    "\n\n  }\n\n  .title-font-modal {\n    width: 514px;\n    height: 36px;\n    color: #2c333c;\n    font-family: "Roboto - Black";\n    font-size: 36px;\n    font-weight: 400;\n  }\n\n  .title-part-modal {\n    width: 890px;\n    height: 130px;\n    background-color: #eff7ff;\n  }\n\n  .resize-1 {\n    width: 396px;\n    height: 60px;\n    background-color: #ffffff;\n  }\n\n .reasons-card-wrap .card-body {\n    height: auto!important;\n    text-align: center;\n    margin: 0 auto;\n}  .textr-box-size {\n    /* Style for "Input" */\n    width: 396px;\n    height: 60px;\n    border: 1px solid #ebebeb;\n    background-color: #ffffff;\n  }\n  '
          }}
        />
       

        {/* header
        <CommonHeder category="bookCounselling"></CommonHeder>*/}
        <NewMainHeader category="register"></NewMainHeader>
    

        {/* end of header */}
        <div className="padding22" />

        <div className="margin"></div>
        {/* -----------------------------------------start book my session------------------------------------------------- */}




        <div
          className="container hello pl-lg-2 pl-md-2 pl-sm-0"
          style={{
            backgroundColor: "#ffffff",
            paddingRight: "0px",
            paddingLeft: "0px"
          }}
        >
          <section className="reasons-sec ">
            <div className="container reasons-sec-wrap gr-sec-wrap pt-0 pb-0">
              <div className="reasons-main-header-wrap ">
                <div className="background-login-card">
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-lg-8 d-flex p-0 m-0">
                    <h2
                          id="head-tab1"
                          className={
                            this.state.isActive === 1
                              ? "ml-4 mt-5 pt-4 content-displayshow"
                              : "ml-4 mt-5 pt-4 content-displayblock"
                          }
                          style={{
                            fontSize: "2.25rem",
                            fontWeight: "900px",
                            marginLeft: "1rem"
                          }}
                        >
                          Congratulations !
                        </h2>
                      <h2
                        id="head-tab1"
                        className={
                          this.state.isActive === 2
                            ? "ml-4 mt-5 pt-4 content-displayshow cardInBookingDemo"
                            : "ml-4 mt-5 pt-4 content-displayblock cardInBookingDemo"
                        }
                      >
                        Our Certified genetic counsellor will call you at at the
                        time of your appointment.
                      </h2>

                      <h2
                        id="head-tab2"
                        className={
                          this.state.isActive === 3
                            ? "ml-4 mt-5 pt-4 content-displayshow cardInBookingDemo"
                            : "ml-4 mt-5 pt-4 content-displayblock cardInBookingDemo"
                        }
                      >
                        Registration
                      </h2>
                      <h2
                        id="head-tab3"
                        className={
                          this.state.isActive === 4
                            ? "ml-4 mt-5 pt-4 content-displayshow cardInBookingDemo"
                            : "ml-4 mt-5 pt-4 content-displayblock cardInBookingDemo"
                        }
                      >
                        Review your order
                      </h2>
                      <h2
                        id="head-tab4"
                        className={
                          this.state.isActive === 5
                            ? "ml-4 mt-5 pt-4 content-displayshow cardInBookingDemo"
                            : "ml-4 mt-5 pt-4 content-displayblock cardInBookingDemo"
                        }
                      >
                        Pay with
                      </h2>
                
                    </div>
                    <div
                      className={this.state.isActive === 1?"col-4 content-displayblock":"col-4"}
                      style={{ backgroundColor: "#eff7ff",borderTopRightRadius: "2rem" }}
                    >
                      <div
                        id="d2"
                        className={
                          this.state.isActive === 1
                            ? "col-md-12 col-sm-12 col-lg-4 content-displayblock"
                            : "col-md-12 col-sm-12 col-lg-4 "
                        }
                        style={{ backgroundColor: "#eff7ff" }}
                      ></div>
                    </div>

                    {/* <div
                      className="col-4 py-5"
                      style={{
                        borderTopRightRadius: "32px",
                        backgroundColor: "#eff7ff"
                      }}
                    >
                    </div> */}
                    {/* <h2 className="TruGeneColor roboto-bold-24px">Summery</h2> */}

                  </div>

                  {/* <div style="padding-bottom:2rem">
              </div> */}

                  <div className="row ">
                    <div className="col-md-8 col-sm-12 col-lg-8 p-0 m-0 roboto-reg-18px">
                      {/* <p id="p-1" className=" p-0 m-0 ml-3 pl-2" style={{fontWeight: 400, fontSize: '1rem'}}>You have just taken a step towards better health.</p><br /> */}
                      <br />
                      <ul
                        className="nav mb-5 text-uppercase "
                        id="myTab"
                        role="tablist"
                      >
                          <li className="nav-item ml-4">
                            <a 
                              className={
                                this.state.isActive === 1
                                  ? "nav-link active pl-0"
                                  : "nav-link"
                              }
                              vid="home-tab"
                              data-toggle="tab"
                              role="tab"
                              aria-selected="true"
                              onClick={
                                this.state.selectedPlan
                                  ? () => this.changeTab(1)
                                  : () => {}
                              }
                            >
                              counselling PLAN
                              <i className="fas fa-caret-right" />
                            </a>
                          </li>
                        <li className="nav-item ml-4">
                          <a 
                            className={
                              this.state.isActive === 2
                                ? "nav-link active pl-0"
                                : "nav-link"
                            }
                            vid="home-tab"
                            data-toggle="tab"
                            role="tab"
                            aria-selected="true"
                            onClick={
                              this.state.selectedPlan
                                ? () => this.changeTab(2)
                                : () => { }
                            }
                          >
                            SCHEDULE APPOINTMENT
                            <i className="fas fa-caret-right" />
                          </a>
                        </li>
                        <li className="nav-item ">
                          {/* document.getElementById("head-tab1").style.disply = "block"; */}
                          <a 
                            className={
                              this.state.isActive === 3
                                ? "nav-link active pl-0"
                                : "nav-link"
                            }
                            id="profile-tab"
                            data-toggle="tab"
                            role="tab"
                            aria-selected="false"
                            onClick={
                              this.state.selectedTime && !Cookies.get("userId")
                                ? () => this.changeTab(3)
                                : () => { }
                            }
                          >
                            REGISTRATION
                            <i className="fas fa-caret-right" />
                          </a>
                        </li>

                        {/* <li className="nav-item ">
                            <a className={this.state.isActive===4?"nav-link active pl-0":"nav-link"}  id="settings-tab" data-toggle="tab" href="#settings" role="tab" onClick={() => this.changeTab(4)} aria-controls="settings" aria-selected="false">PAYMENT<i className="fas fa-caret-right" /></a>
                          </li> */}
                        <li className="nav-item ">
                          <a  
                            className={
                              this.state.isActive === 4
                                ? "nav-link active pl-0"
                                : "nav-link"
                            }
                            id="messages-tab"
                            data-toggle="tab"
                            role="tab"
                            aria-selected="false"
                            onClick={
                              this.state.selectedPlan &&
                                this.state.selectedTime &&
                                this.state.customerId
                                ? () => this.changeTab(4)
                                : () => { }
                            }
                          >
                            REVIEW
                          </a>
                        </li>
                      </ul>
                      {/* eo gr-header */}
                    </div>
                    <div
                      className={this.state.isActive === 1?"col-4 content-displayblock":"col-4"}
                      style={{ backgroundColor: "#eff7ff" }}
                    >
                      <div
                        id="d2"
                        className={
                          this.state.isActive === 1
                            ? "col-md-12 col-sm-12 col-lg-4 content-displayblock"
                            : "col-md-12 col-sm-12 col-lg-4 "
                        }
                        style={{ backgroundColor: "#eff7ff" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              {/* eo gr-header */}
              {/* <div style="padding-bottom:2rem;">
          </div> */}

              {/* ----------------------------------------------------counselling sesseion section start----------------------------------------------------------------------- */}
              <div className="tab-content" style={{ marginLeft: "0px" }}>
              <div
                    className={
                      this.state.isActive === 1 ? "tab-pane active" : "tab-pane"
                    }
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    {/* <div style="background-color: #eff7ff;"> */}
                    <h2
                      className="text-center pb-5"
                      style={{
                        fontSize: "1.8",
                        fontWeight: 900,
                        color: "#4f5be7"
                      }}
                    >
                      Select a plan that works best for you
                    </h2>
                    <div className="container reasons-card-main-wrap pt-2 mt-2  mb-5">
                      <div className="row">
                        <div className="reasons-card-wrap  col-12 col-md-4 col-lg-4 col-xl-4">
                          <div className="card card-1 shadow-lg r4 border-0 mb-5 bg-white">
                            <div className="card-body p-0 indexBody" style={{height:'auto'}}>
                            <div className="card-top bb-card-image1">
                                <div className="text-center py-5 ">
                              <h1 className="text-center mb-0 py-5 roboto-black-36px">Free</h1>

                                  {/* <a style="font-size: 43px;font-weight: 900;">Free</a></image> */}
                                </div>
                                {/* eo-hd-img */}
                              </div>
                              {/* eo-card-top */}
                              <div className="card-btm bg-white">
                                {/* <h5 class= "card-title">Planning a Pregnancy</h5> */}
                                <p className="card-text"></p>
                                <ul
                                  className="text-center mx-auto m-0 p-0"
                                  style={{
                                    listStyle: "none",
                                    lineHeight: "1.8"
                                  }}
                                >
                                  <li>
                                    <i className="color-blt fas fa-caret-right" />{" "}
                                    Order your truGeny test.
                                  </li>
                                  <li>
                                    <i className="color-blt fas fa-caret-right" />{" "}
                                    Get complimentary 30 minutes of Pre-test and
                                    30 minutes of Post-test genetic counselling{" "}
                                  </li>
                                  <div style={{ paddingBottom: "4rem" }} />
                                
                                 <button
                                 className=" btn btn-outline-primary btn-hover-white  my-2 my-sm-0 "
                                 data-toggle="tab"
                                 href="#profile"
                                 role="tab"
                                 style={{
                                   color: "white",
                                   background: "#4f5be7",
                                   width: "165px",
                                   height: "60px",
                                   boxShadow:
                                     "0 10px 32px rgba(79, 91, 231, 0.48)",
                                   borderRadius: "30px"
                                 }}
                                 type="submit"
                                 name="free session"
                                 value="free"
                          onClick={()=>{swal("For Availability of Free Session You must order a truGeny Test Kit")}}
                               >
                                 <b>Select</b>
                               </button>
                                </ul>
                                <p />
                              </div>
                              {/* eo-card-btm */}
                            </div>
                            {/* eo-card-body */}
                          </div>
                          {/* eo-card-1 */}
                        </div>
                        {/* eo-ns-card-wrap */}
                        <div className="reasons-card-wrap  col-12 col-md-4 col-lg-4 col-xl-4">
                          <div className="card card-1 shadow-lg r4 border-0 mb-5">
                            <div className="card-body p-0 mx-0">
                            <div className="card-top bb-card-image2">
                                <div className="text-center py-5 ">
                                <h1 className="text-center mb-0 py-0 roboto-black-36px paddingbcard">{this.state.plan1}</h1>

                                {((this.state.offerForProduct===this.state.plan1||this.state.offerForProduct1===this.state.plan1||this.state.offerForProduct2===this.state.plan1)&&this.state.offerPrice>=0)?
                                
                                <del className="highlight">                       <h1 className="text-center mb-0 py-0 roboto-black-36px TruGeneColor"><i class="fa fa-inr" aria-hidden="true"></i> {this.state.value1}/-</h1>
                                </del>
                                :
                              <h1 className="text-center mb-0 py-0 roboto-black-36px TruGeneColor"><i class="fa fa-inr" aria-hidden="true"></i> {this.state.value1}/-</h1>
         
                                }
                                
          
                                  {/* <a style="font-size: 43px;font-weight: 900;">Free</a></image> */}
                                </div>
                                {/* eo-hd-img */}
                              </div>
                              {((this.state.offerForProduct===this.state.plan1||this.state.offerForProduct1===this.state.plan1||this.state.offerForProduct2===this.state.plan1)&&this.state.offerPrice>=0)?<h3>Offer: {this.state.note}</h3>
                       :""}
                              {/* eo-card-top */}
                              <div className="card-btm bg-white">
                                {/* <h5 class= "card-title">Pregnant</h5> */}
                                {/* <p class="card-text"> */}
                                <ul
                                  className="text-center mx-auto m-0 p-0 "
                                  style={{
                                    listStyle: "none",
                                    lineHeight: "1.8"
                                  }}
                                >
                                  <li />
                                  {((this.state.offerForProduct===this.state.plan1||this.state.offerForProduct1===this.state.plan1||this.state.offerForProduct2===this.state.plan1)&&this.state.offerPrice>=0)? <li>
                                    <i className="color-blt fas fa-caret-right" />{" "}
                                {this.state.note}
                                  </li>:""
                                     }
                                  <li>
                                    <i className="color-blt fas fa-caret-right" />{" "}
                                    Upload your reports
                                  </li>
                                  <li />
                                  <li>
                                    <i className="color-blt fas fa-caret-right" />{" "}
                                    30 minute genetic counselling session.
                                  </li>
                                  <li />
                                  <li>
                                    <i className="color-blt fas fa-caret-right" />{" "}
                                    Assessment of health
                                  </li>
                                  <li />
                                  <li>
                                    <i className="color-blt fas fa-caret-right" />{" "}
                                    Review of family health history
                                  </li>
                                  <li />
                                  <li>
                                    <i className="color-blt fas fa-caret-right" />{" "}
                                    Discuss Genetic testing options
                                  </li>
                                  <div style={{ paddingBottom: "2rem" }} />
                                </ul>
                                <button
                                  className=" btn btn-outline-primary btn-hover-white  my-2 my-sm-0 "
                                  data-toggle="tab"
                                  href="#profile"
                                  role="tab"
                                  style={{
                                    color: "white",
                                    background: "#4f5be7",
                                    width: "165px",
                                    height: "60px",
                                    boxShadow:
                                      "0 10px 32px rgba(79, 91, 231, 0.48)",
                                    borderRadius: "30px"
                                  }}
                                  type="submit"
                                  name={this.state.plan1}
                                  value={this.state.value1}
                                  onClick={
                                      Cookies.get("userId")
                                      ? e => this.editPlan(e)
                                      : e => this.selectedPlan(e, 2)
                                  }
                                >
                                  <b>Select</b>
                                </button>{" "}
                              </div>
                              {/* eo-card-btm */}
                            </div>
                            {/* eo-card-body */}
                          </div>
                          {/* eo-card-1 */}
                        </div>
                        <div className="reasons-card-wrap  col-12 col-md-4 col-lg-4 col-xl-4">
                          <div className="card card-1 shadow-lg r4 border-0 mb-5">
                            <div className="card-body p-0">
                            <div className="card-top bb-card-image3">
                                <div className="text-center py-5 ">
                                <h1 className="text-center mb-0 py-0 roboto-black-36px px-5">{this.state.plan2}</h1>
                                { ((this.state.offerForProduct===this.state.plan2||this.state.offerForProduct1===this.state.plan2||this.state.offerForProduct2===this.state.plan2)&&this.state.offerPrice>=0)?      
                                <del className="highlight"> <h1 className="text-center mb-0 py-0 roboto-black-36px TruGeneColor"><i class="fa fa-inr" aria-hidden="true"></i> {this.state.value2}/-</h1></del>
:
<h1 className="text-center mb-0 py-0 roboto-black-36px TruGeneColor"><i class="fa fa-inr" aria-hidden="true"></i> {this.state.value2}/-</h1>


}
                                  {/* <a style="font-size: 43px;font-weight: 900;">Free</a></image> */}
                                </div>
                                {/* eo-hd-img */}
                              </div>
                              
                              { ((this.state.offerForProduct===this.state.plan2||this.state.offerForProduct1===this.state.plan2||this.state.offerForProduct2===this.state.plan2)&&this.state.offerPrice>=0)?<h3>Offer: {this.state.note}</h3>
                       :""}
                              {/* eo-card-top */}
                              <div className="card-btm bg-white">
                                {/* <h5 class= "card-title">Pregnant</h5> */}
                                <ul
                                  className="text-center mx-auto m-0 p-0 "
                                  style={{
                                    listStyle: "none",
                                    lineHeight: "1.6"
                                  }}
                                >
                                  <li />
                                  { ((this.state.offerForProduct===this.state.plan2||this.state.offerForProduct1===this.state.plan2||this.state.offerForProduct2===this.state.plan2)&&this.state.offerPrice>=0)? <li>
                                    <i className="color-blt fas fa-caret-right" />{" "}
                                     {this.state.note}
                                  </li>:""
                                     }
                                  <li>
                                    <i className="color-blt fas fa-caret-right" />{" "}
                                    Upload your reports
                                  </li>
                                  <li />
                                  <li>
                                    <i className="color-blt fas fa-caret-right" />{" "}
                                    60 minute genetic counselling session.
                                  </li>
                                  <li />
                                  <li>
                                    <i className="color-blt fas fa-caret-right" />{" "}
                                    Assessment of health
                                  </li>
                                  <li />
                                  <li>
                                    <i className="color-blt fas fa-caret-right" />{" "}
                                    Review of family health history
                                  </li>
                                  <li />
                                  <li>
                                    <i className="color-blt fas fa-caret-right" />{" "}
                                    Discuss Genetic testing options
                                  </li>
                                  <li />
                                  <li>
                                    <i className="color-blt fas fa-caret-right" />{" "}
                                    15 days online chat with a licensed genetic
                                    counsellor
                                  </li>
                                  <div style={{ paddingBottom: "0.02 rem" }} />
                                </ul>
                                <button
                                  className=" btn btn-outline-primary btn-hover-white  my-sm-0 "
                                  data-toggle="tab"
                                  href="#profile"
                                  role="tab"
                                  style={{
                                    color: "white",
                                    background: "#4f5be7",
                                    width: "165px",
                                    height: "60px",
                                    boxShadow:
                                      "0 10px 32px rgba(79, 91, 231, 0.48)",
                                    borderRadius: "30px"
                                  }}
                                  type="submit"
                                  name={this.state.plan2}
                                  value={this.state.value2}
                                  onClick={
                                    Cookies.get("userId")

                                      ? e => this.editPlan(e)
                                      : e => this.selectedPlan(e, 2)
                                  }
                                >
                                  <b>Select</b>
                                </button>
                              </div>
                              {/* eo-card-btm */}
                            </div>
                            {/* eo-card-body */}
                          </div>
                          {/* eo-card-1 */}
                        </div>
                        {/* eo-ns-card-wrap */}
                        <div className="reasons-card-wrap  col-12 col-md-6 col-lg-6 col-xl-6">
                          <div className="card card-1 shadow-lg r4 border-0 mb-5">
                            <div className="card-body p-0">
                              <div className="card-top">
                                <div className="text-center py-lg-5 py-sm-1">
                                  {/* <img class="img-fluid mx-auto" src="./img/geneticspage/genetics-heartdisease.png" /> */}
                                  <img alt="booking"
                                    className="img-fluid mx-auto imgfluidMobile"
                                    src="./img/book-session/Illustration1.png"
                                  />
                                </div>
                                {/* eo-hd-img */}
                              </div>
                              {/* eo-card-top */}
                              <div className="card-btm bg-white">
                                <h5 className="card-title ">
                                  Proactive genetic exploration
                                </h5>
                                <p className="card-text">
                                  Our certified genetic counsellors are experts
                                  in various genetics specialty areas including
                                  cancer, prenatal, cardiovascular and many
                                  others. They will help you evaluate your
                                  genetic health risks, interpret and evaluate
                                  your test reports and guide
                                </p>
                              </div>
                              {/* eo-card-btm */}
                              {/* eo-card-btm */}
                            </div>
                            {/* eo-card-body */}
                          </div>
                          {/* eo-card-1 */}
                        </div>
                        {/* eo-ns-card-wrap */}
                        <div className="reasons-card-wrap  col-12 col-md-6 col-lg-6 col-xl-6">
                          <div className="card card-1 shadow-lg r4 border-0 mb-5">
                            <div className="card-body p-0">
                              <div className="card-top">
                                <div className="text-center py-lg-5 py-sm-1">
                                  {/* <img class="img-fluid mx-auto" src="./img/geneticspage/genetics-heartdisease.png" /> */}
                                  <img alt="booking"
                                    className="img-fluid mx-auto imgfluidMobile"
                                    src="./img/book-session/Illustration1.png"
                                  />
                                </div>
                                {/* eo-hd-img */}
                              </div>
                              {/* eo-card-top */}
                              <div className="card-btm bg-white">
                                <h5 className="card-title">
                                  Who are Genetic Counsellors?
                                </h5>
                                <p className="card-text">
                                  Genetic counsellors are health professionals
                                  with specialised graduate degrees and
                                  experience in the areas of medical genetics
                                  and Counseling.
                                </p>
                              </div>
                              {/* eo-card-btm */}
                            </div>
                            {/* eo-card-body */}
                          </div>
                          {/* eo-card-1 */}
                        </div>
                        {/* eo-ns-card-wrap */}
                      </div>
                      {/* eo-row */}
                    </div>
                    {/* gr-card-wrap */}
                    <h2 className="text-center bcCtext">
                      Complimentary genetic Counselling service with every
                      truGeny test
                    </h2>
                    <p className="text-center pb-5 bctext">
                      truGeny offers complimentary pre and post test genetic
                      Counselling session to those who take a truGeny test.
                    </p>
                  </div>
              {/* ----------------------------------------------------schedulde sesseion section start----------------------------------------------------------------------- */}

                <div
                  className={
                    this.state.isActive === 2 ? "tab-pane active" : "tab-pane"
                  }
                  id="messages"
                  role="tabpanel"
                  aria-labelledby="messages-tab"
                >
                  <div className="row">
                    <div
                      className="col-lg-8 date-time-sec-wrap px-0"
                      style={{}}
                    >
                      {this.state.doctorId === "" ? (
                        <p
                          style={{
                            fontSize: "1.25rem",
                            fontWeight: 400,
                            marginLeft: "2.5rem"
                          }}
                        >
                          Select an appointment date and time that is convenient
                          for you.
                        </p>
                      ) : (
                          ""
                        )}
                      <div className="date-time-sec-main row px-0 ">
                        <div className="date-main-sec col-lg-8 px-0">
                          <section
                            style={{ maxWidth: "460px", margin: "0 auto" }}
                          >
                            <div
                              id="pnlEventCalendar"
                              style={{ width: "100%" }}
                            />
                          </section>
                          <div>
                            <Calendar
                              style={{ height: 500 }}
                              onChange={this.onChangeDate}
                              value={this.state.date}
                              tileClassName={({ date, view }) => {
                                if(this.state.offerDatesData.find(x=>x===moment(date).format("YYYY-MM-DD"))&&(this.state.selectedPlanName===this.state.offerForProduct1||this.state.selectedPlanName===this.state.offerForProduct2||this.state.selectedPlanName===this.state.offerForProduct)){
                                 return  'highlight'
                                }
                              }}
                              
                      
                                tileContent={({ date, view }) => {
                                if(this.state.offerDatesData.find(x=>x===moment(date).format("YYYY-MM-DD"))&&(this.state.selectedPlanName===this.state.offerForProduct1||this.state.selectedPlanName===this.state.offerForProduct2||this.state.selectedPlanName===this.state.offerForProduct)){
                                  return <div>
                                 <span className="highlight" href="#" id="UncontrolledTooltipExample">*</span>
                                  <UncontrolledTooltip placement="auto" target="UncontrolledTooltipExample">
                                    {this.state.offerName}
                                  </UncontrolledTooltip>
                                </div>
                                 
                                }

                              }}

                              
                              tileDisabled={({ date }) => date.getDay() === 0}

                              /*maxDate={new Date(2020, 1, 0)}*/ minDate={
                                new Date()
                              }
                            ></Calendar>
                            {/*  <BasicCalendar minDate={new Date()} style={{ height: 300 }}></BasicCalendar> */}
                          </div>
                        </div>
                        {/* end of date-main-sec */}

                        <div className="time-main-sec col-lg-4 px-0 d-flex justify-content-center">
                          <div className="time-main-sec-wrap mx-auto">
                            <div className="time-sec-header">
                              <p>Available Time {this.state.selectedDate}</p>
                            </div>
                            {/* end of end of time-sec-header */}
                            <div className="time-table-wrap text-left mx-auto ">
                              {this.state.loading ? (
                                <Loader></Loader>
                              ) : this.state.timeslots.length ? (
                                this.state.timeslots.map((slot, index) => (
                                  <div
                                    className="time-table-row tt-row-1"
                                    key={index}
                                  >
                                    <button
                                      type="button"
                                      value={slot}
                                      style={{
                                        color:
                                          this.state.selectedTime === slot
                                            ? "red"
                                            : ""
                                      }}
                                      className="btn btn-outline-primary btn-hover-white"
                                      onClick={this.onSelectTime}
                                    >
                                      {slot}
                                    </button>
                                  </div>
                                ))
                              ) : (
                                    <div className="time-table-row tt-row-1">
                                      No Slots Available{}
                                    </div>
                                  )}
                            </div>
                            {/* end of end of time-table-wrap */}
                          </div>
                          {/* end of end of time-main-sec-wrap */}
                        </div>
                        {/* end of date-main-sec */}
                      </div>
                      {/* end of date-time-sec row */}
                    </div>
                    {/* ------------------------------eo calender code----------------------- */}
                    <div
                      className="col-lg-4 nnn IndexBackground"
                     
                    >
                      {/* <h2>Summary</h2> */}

                      <div className="table-responsive-sm">
                        {this.state.selectOption ? (
                          <div>
                            <p
                              id="summery"
                              style={{
                                fontSize: "30px",
                                fontWeight: 900,
                                color: "#4f5be7"
                              }}
                            >
                              Your Order Summary
                            </p>
                            <table className="table" style={{ border: "none" }}>
                              <thead>
                                <tr></tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <b>
                                      {changeCase.titleCase(
                                        this.state.selectedPlanName
                                      )}
                                    </b>
                                  </td>
                                  
                                  {(this.state.offerFree ==="free")?
                                 <td>Free Offer</td>:  <td>Rs. {this.state.selectedPlan}</td>}
                                                              



                                </tr>
                                {}
                                {this.state.selectedPlanName ===
                                "free session" ? (
                                  ""
                                ) : (
                                  null
                                  // <tr>
                                  //   <td>
                                  //     <b>GST @18%</b>
                                  //   </td>
                                  //   <td>Rs. {gstAmt}</td>
                                  // </tr>
                                )}

                                {this.state.offerPrice===""||this.state.offerPrice===undefined?'':<tr>
                                <td>
                                  <b>
                                  Offer-{this.state.offerName}
                                  </b>
                                </td>
                                <td>Rs. {this.state.offerPrice}</td>
                              </tr>}

                                <tr>
                                  <td>
                                    <b>Total</b>
                                  </td>
                                  {
                                    this.state.offerFree==="free"?<td>Free</td>:
                                    <td>
                                    Rs.{" "}
                                    {this.state.selectedPlanName ===
                                    "free session"
                                      ? this.state.selectedPlan
                                      : Number(this.state.selectedPlan) +
                                        Number(gstAmt)-offerPrice}
                                  </td>
                                  }
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        ) : (
                            this.alert()
                          )}
                      </div>
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-8">
                      <h3
                        className="pl-0 ml-0"
                        style={{
                          color: "#4f5be7",
                          fontSize: "16px",
                          fontWeight: 700,
                          textTransform: "uppercase",
                          paddingTop: "30px"
                        }}
                      >
                        Your prefered choice of appointment :
                      </h3>
                      <span className="d-flex">
                        <p className="keyInputBookingConsel">
                          Date:
                        {this.state.selectedDate}
                        </p></span>
                      {/* <p style="color: #2c333c;font-size: 24px;font-weight: 700;line-height: 36px;">Date : 17th May, 2019</p> */}
                      <div className="d-flex">
                        <p className="keyInputBookingConsel">Time :</p>
                        <p id="time_shedule" className="keyInputBookingConsel">
                          {this.state.selectedTime}{" "}
                        </p>
                      </div>
            
                     

                      <div className=" d-flex pb-5">
                        <p
                          className="mr-5 text-left"
                          style={{ fontSize: "1rem", fontWeight: 400 }}
                        >
                          The assigned genetic counsellor will call your contact
                          number provided.
                        </p>
                        {scheduldeButtonType}
                      </div>
                      <div>
                        {" "}
                        <p
                          className="mr-5 "
                          style={{
                            display: this.state.selectedTime ? "none" : "",
                            color: "red",
                            fontWeight: 800
                          }}
                        >
                          Please Select the Time Slot for Enabling Schedule
                          Button *
                        </p>
                      </div>
                    </div>
                    <div
                      className="col-sm-12 col-md-12 col-lg-4 "
                      style={{
                        backgroundColor: "#eff7ff",
                        borderBottomRightRadius: "2rem"
                      }}
                    ></div>
                  </div>
                </div>
                {/* ----------------------------------------------------registration section start----------------------------------------------------------------------- */}

                <div
                  className={
                    this.state.isActive === 3 ? "tab-pane active" : "tab-pane"
                  }
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  {/* <div style="background-color: #eff7ff;"> */}
                  <div className="row">
                    <div className="col-lg-8 px-0">
                      <h2
                        className="mt-3 pl-lg-3 pl-sm-0"
                        style={{ fontFamily: "Roboto Bold", fontSize: "30px" }}
                      >
                        {" "}
                        <b>Create Account</b>
                        <div className="errorMessage">
                          {this.state.errorMsg }
                          
                        </div>
                        {/* {(this.state.errorMsg)?<a>Sign in</a>:""} */}
                      </h2>
                      <div className="cr-sec-main container  pb-lg-5 mb-lg-2 pb-sm-0 mb-sm-0 b1-cr ">
                        <div style={{ maxWidth: "600px", margin: "left" }}>
                          <form
                            className="cr-form pt-0 gc-form needs-validation"
                            onSubmit={this.registration}
                          >
                            <div className="gc-form-card-btm pt-5">
                              <div className="row">
                                <div className="col-lg-6 col-sm-12">
                                  <div className="form-row justify-content-left">
                                    <div className="cr-col col-sm-10 col-md-8 col-lg-6 mb-3">
                                      <div
                                        className="input-group"
                                        style={{
                                          border: "solid 2px #ebebeb",
                                          width: "280px"
                                        }}
                                      >
                                        <input
                                          type="text"
                                          name="firstName"
                                          placeholder="First Name"
                                          onChange={this.onChange}
                                          className="form-control m-0 ottabt"
                                          id="validationTooltip01"
                                        />
                                        <div className="input-group-prepend">
                                          <span
                                            className="input-group-text"
                                            id="validationTooltipEmailPrepend"
                                          >
                                            <img src="img/homepage/i1.png" alt="booking"/>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="errorMessage">
                                      {this.state.errors.firstName}
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-6 col-sm-12">
                                  <div className="form-row justify-content-left">
                                    <div className="cr-col col-sm-10 col-md-8 col-lg-6 mb-3">
                                      <div
                                        className="input-group"
                                        style={{
                                          border: "solid 2px #ebebeb",
                                          width: "280px"
                                        }}
                                      >
                                        <input
                                          type="text"
                                          name="lastName"
                                          placeholder="Last Name"
                                          onChange={this.onChange}
                                          className="form-control m-0 ottabt"
                                          id="validationTooltip01"
                                        />

                                        <div className="input-group-prepend">
                                          <span
                                            className="input-group-text"
                                            id="validationTooltipEmailPrepend"
                                          >
                                            <img src="img/homepage/i1.png" alt="booking"/>
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                  <div className="errorMessage">
                                    {this.state.errors.lastName}
                                  </div>
                                </div>

                              </div>

                              <div className="row">
                                <div className="col-lg-6 col-sm-12">
                                  <div className="form-row justify-content-left">
                                    <div className="cr-col col-sm-10 col-md-8 col-lg-6 mb-3">
                                      <div
                                        className="input-group"
                                        style={{
                                          border: "solid 2px #ebebeb",
                                          width: "280px"
                                        }}
                                      >
                                        <input
                                          type="text"
                                          name="email"
                                          onBlur={this.emailVerification}
                                          onChange={this.onChangeEmail}
                                          placeholder="E-mail ID"
                                          className="form-control cr-email m-0 ottabt"
                                          id="validationTooltipEmailPrepend"
                                          aria-describedby="validationTooltipEmailPrepend"
                                        />
                                        <div className="input-group-prepend">
                                          <span
                                            className="input-group-text"
                                            id="validationTooltipEmailPrepend"
                                          >
                                            <img src="img/homepage/i3.png" alt="booking"/>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="errorMessage">
                                      {this.state.errors.email}
                                    </div>
                                    {this.state.emailStatus === "valid"
                                      ? this.alertEmail()
                                      : this.state.emailStatus === "invalid"
                                        ? this.alert2()
                                        : ""}
                                  </div>
                                </div>

                                <div className="col-lg-6 col-sm-12">
                                  <div className="form-row justify-content-left">
                                    <div className="cr-col col-sm-10 col-md-8 col-lg-6 mb-3">
                                      <div
                                        className="input-group"
                                        style={{
                                          border: "solid 2px #ebebeb",
                                          width: "280px"
                                        }}
                                      >
                                        <div className="input-group-prepend">
                                          <span
                                            className="input-group-text"
                                            id="validationTooltipEmailPrepend"
                                          >
                                            <Badge variant="light">+91</Badge>
                                          </span>
                                        </div>
                                        <input
                                          type="text"
                                          name="phone"
                                          maxLength={10}
                                          onChange={this.onChange}
                                          placeholder="Mobile Number."
                                          className="form-control cr-email m-0 ottabt"
                                          id="validationTooltipEmailPrepend"
                                          aria-describedby="validationTooltipEmailPrepend"
                                        />

                                        <div className="input-group-prepend">
                                          <span
                                            className="input-group-text"
                                            id="validationTooltipEmailPrepend"
                                          >
                                            <img src="./img/homepage/mob.png" alt="booking"/>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="errorMessage">
                                      {this.state.errors.phone}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-6 col-sm-12">
                                  <div className="form-row justify-content-left">
                                    <div className="cr-col col-sm-10 col-md-8 col-lg-6 mb-3">
                                      <div
                                        className="input-group"
                                        style={{
                                          border: "solid 2px #ebebeb",
                                          width: "280px"
                                        }}
                                      >
                                        <input
                                        
                                          type="password"
                                          name="password"
                                          placeholder="Create Password"
                                          onChange={this.onChange}
                                          className="form-control cr-email m-0 ottabt"
                                          id="validationTooltipEmailPrepend"
                                          aria-describedby="validationTooltipEmailPrepend"
                                        />
                                        <div className="input-group-prepend">
                                          <span
                                            className="input-group-text"
                                            id="validationTooltipEmailPrepend"
                                          >
                                            <img src="img/payment/pwd.png" alt="booking"/>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="errorMessage">
                                    {this.state.errors.password}
                                  </div>
                                </div>

                                <div className="col-lg-6 col-sm-12">
                                  <div className="form-row justify-content-left">
                                    <div className="cr-col col-sm-10 col-md-8 col-lg-6 mb-3">
                                      <div
                                        className="input-group"
                                        style={{
                                          border: "solid 2px #ebebeb",
                                          width: "280px"
                                        }}
                                      >
                                      
                                        <input
                                          type="password"
                                          name="confirmPwd"
                                          placeholder="Confirm Password"
                                          onChange={this.onChange}
                                          className="form-control cr-email m-0 ottabt"
                                          id="validationTooltipEmailPrepend"
                                          aria-describedby="validationTooltipEmailPrepend"
                                        />

                                        <div className="input-group-prepend">
                                          <span
                                            className="input-group-text"
                                            id="validationTooltipEmailPrepend"
                                          >
                                            <img src="./img/payment/pwd.png" alt="booking" />
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                  </div>

                                  <div className="errorMessage">
                                    {this.state.errors.confirmPwd}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="after-form-sec ">
                              <div className="col-xs-12 mx-auto d-flex justify-content-left my-lg-1 my-sm-5">
                                <div className="custom-control form-control-lg custom-checkbox d-flex justify-content-inline">
                                  <input
                                    type="checkbox"
                                    name="terms"
                                    defaultValue="0"
                                    onChange={this.checktermsAndConditions}
                                    id="customCheck1"
                                  />
                                  <label htmlFor="customCheck1">
                                    <span>
                                      <p
                                        style={{
                                          color: "black",
                                          fontSize: "1rem"
                                        }}
                                      >
                                        I agree to the truGeny{" "}
                                        <a
                                          style={{ color: "#4f5be7" }}
                                          href="/termsofservices"
                                        >
                                          Terms of Service
                                        </a>{" "}
                                        &amp;{" "}
                                        <a
                                          style={{ color: "#4f5be7" }}
                                          href="/al_privacy_policy"
                                        >
                                          Privacy Policy
                                        </a>
                                      </p>
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <div className="errorMessage">
                                {this.state.errors.terms}
                              </div>

                              {this.loader()}
                              <div className="row d-flex justify-content-right px-3 pt-3 pb-lg-1 pb-sm-5">
                                <button
                                  className="btn btn-outline-primary btn-hover-white b-0 my-sm-0 center regButton"
                                  style={{
                                    height: "60px",
                                    width: "26rem",
                                    borderRadius: "1.875rem"
                                  }}
                                  type="submit"
                                  disabled={
                                    this.state.emailStatus === "valid"&&!this.state.loading
                                      ? false
                                      : true
                                  }
                                >
                                  <b>CREATE ACCOUNT</b>
                                </button>
                              </div>
                      
                <div className="row d-flex justify-content-center px-3 pt-5">
                  <button className="btn btn-outline-primary btn-hover-white b-0 my-sm-0 center regButton1" style={{height: '60px', width: '26rem', borderRadius: '1.875rem'}} type="submit" disabled={this.state.emailStatus==="valid"?false:true}>
                    <b>CREATE ACCOUNT</b></button>


                </div>



                            </div>
                          </form>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-lg-4 nnn IndexBackground"
                      
                    >
                      {/* <h2>Summary</h2> */}

                      <div className="table-responsive-sm">
                        {this.state.selectOption ? (
                          <div>
                            <p
                              id="summery"
                              style={{
                                fontSize: "30px",
                                fontWeight: 900,
                                color: "#4f5be7"
                              }}
                            >
                              Your Order Summary
                            </p>
                            <table className="table" style={{ border: "none" }}>
                              <thead>
                                <tr></tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <b>
                                      {changeCase.titleCase(
                                        this.state.selectedPlanName
                                      )}
                                    </b>
                                  </td>
                                    
                                  {(this.state.offerFree ==="free")?
                                 <td>Free Offer</td>:  <td>Rs. {this.state.selectedPlan}</td>}
                                                              

                                </tr>
                                {/*     <tr>
                                <td><b>Shipping + Handling</b></td>
                                <td>Rs 9000.00</td>
                              </tr> */}
                                {this.state.selectedPlanName ===
                                "free session" || this.state.offerFree==="free"? (
                                  ""
                                ) : (null
                                  // <tr>
                                  //   <td>
                                  //     <b>GST @18%</b>
                                  //   </td>
                                  //   <td>Rs. {gstAmt}</td>  
                                  // </tr>
                                )}

                                {this.state.offerPrice===""||this.state.offerPrice===undefined?'':<tr>
                                <td>
                                  <b>
                                  Offer-{this.state.offerName}
                                  </b>
                                </td>
                                <td>Rs. {this.state.offerPrice}</td>
                              </tr>}

                                <tr>
                                  <td>
                                    <b>Total</b>
                                  </td>
                                     
                                  {(this.state.offerFree ==="free")?
                                 <td>Free Offer</td>:  
                                
                                 <td>
                                 Rs.{" "}
                                 {this.state.selectedPlanName ===
                                 "free session"
                                   ? this.state.selectedPlan
                                   : Number(this.state.selectedPlan) +
                                     Number(gstAmt)-offerPrice}
                               </td>
                                 }
                                                              

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        ) : (
                            this.alert()
                          )}
                      </div>
                    </div>
                  </div>

                  {/* gr-card-wrap
                    <h2 className="text-center bcCtext">
                      Complimentary genetic Counselling service with every
                      truGeny test
                    </h2>
                    <p className="text-center pb-5 bctext">
                      truGeny offers complimentary pre and post test genetic
                      Counselling session to those who take a truGeny test.
                    </p>*/}

                </div>

                {/* ----------------------------------------------------review section start----------------------------------------------------------------------- */}
                <div
                  className={
                    this.state.isActive === 4 ? "tab-pane active" : "tab-pane"
                  }
                  id="messages"
                  role="tabpanel"
                  aria-labelledby="messages-tab"
                >
                  {/* space */}
                  {/* <div style="padding-bottom:3rem;">

              </div> */}
                  {/* eo space */}
                  <div className="row">
                    <div className="col-lg-8 col-sm-12 col-md-12">
                      <p
                        className="mb-5"
                        style={{
                          color: "#84888e",
                          fontSize: "16px",
                          fontWeight: 400
                        }}
                      >
                        Make sure the information below is correct.
                      </p>
                      <div className="row">
                        <div className="col-md-4 col-sm-12 col-lg-4 ">
                          <h2
                            style={{
                              color: "#2c333c",
                              fontSize: "30px",
                              fontWeight: 700
                            }}
                          >
                            Selected plan
                          </h2>
                        </div>
                        <div className="col-md-4 col-sm-12 col-lg-4">
                          {/* <img class="m-0 img-fluid"src="img/payment/Box_confirm-page.png" style="width: 134px;height: 76px;padding-top:0px"/> */}
                          <h3
                            id="planname"
                            style={{
                              color: "#2c333c",
                              fontSize: "24px",
                              fontWeight: 400
                            }}
                          >
                            {changeCase.titleCase(this.state.selectedPlanName)}
                          </h3>
                        </div>
                        <div className="col-md-4 col-sm-12 col-lg-4">
                          <button
                            className="float-right btn btn-hover-white my-2 my-sm-0 center"
                            style={{
                              height: "60px",
                              width: "100px",
                              borderRadius: "30px"
                            }}
                            onClick={() => this.changeTab(1)}
                            type="submit"
                          >
                            <b>Edit</b>
                          </button>
                        </div>
                      </div>
                      <div className="container">
                        <hr />
                      </div>
                      {/* <div style="padding-top:1.25rem;">
                  </div> */}
                      <div className="row">
                        <div className="col-md-4 col-sm-12 col-lg-4 ">
                          <h2
                            style={{
                              color: "#2c333c",
                              fontSize: "30px",
                              fontWeight: 700
                            }}
                          >
                            Appointment request
                          </h2>
                        </div>
                        <div className="col-md-4 col-sm-12 col-lg-4 ">
                          {/* <img class="m-0 img-fluid"src="img/payment/Box_confirm-page.png" style="width: 134px;height: 76px;padding-top:0px"/> */}
                          <h3
                            id="sdate"
                            style={{
                              color: "#2c333c",
                              fontSize: "24px",
                              fontWeight: 400
                            }}
                          >
                            <i
                              className="fas fa-calendar-alt pr-2"
                              style={{ color: "#a9adb3" }}
                            />

                            {this.state.selectedDate}
                          </h3>
                          <br />
                          <h3
                            id="stime"
                            style={{
                              color: "#2c333c",
                              fontSize: "24px",
                              fontWeight: 400
                            }}
                          >
                            <i
                              className="fas fa-clock pr-2"
                              style={{ color: "#a9adb3" }}
                            />
                            {this.state.selectedTime}
                          </h3>
                        </div>
                        <div className="col-md-4 col-sm-12 col-lg-4">
                          <button
                            className="float-right btn btn-hover-white my-2 my-sm-0 center"
                            style={{
                              height: "60px",
                              width: "100px",
                              borderRadius: "30px"
                            }}
                            onClick={() => this.changeTab(2)}
                            type="submit"
                          >
                            <b>Edit</b>
                          </button>
                        </div>
                      </div>
                      <div className="container">
                        <hr />
                      </div>
                      {/* <div style="padding-top:1.25rem;">
                  </div> */}
                      {/* <div className="row">
                        <div className="col-md-4 col-sm-12 col-lg-4 ">
                          <h2
                            style={{
                              color: "#2c333c",
                              fontSize: "30px",
                              fontWeight: 700
                            }}
                          >
                            Contact Number
                          </h2>
                        </div>
                        <div className="col-md-4 col-sm-12 col-lg-4 ">
                          <h3
                            style={{
                              color: "#2c333c",
                              fontSize: "1.185rem",
                              fontWeight: 400
                            }}
                          >
                            <i
                              className="fas fa-mobile-alt pr-2"
                              style={{ color: "#a9adb3" }}
                            />
                            {this.state.contactNo}
                          </h3>
                        </div>
                        <div className="col-md-4 col-sm-12 col-lg-4">
                          <button
                            className="float-right btn btn-hover-white my-2 my-sm-0 center"
                            style={{
                              height: "60px",
                              width: "100px",
                              borderRadius: "30px"
                            }}
                            type="submit"
                            onClick={() => this.changeTab(2)}
                          >
                            <b>Edit</b>
                          </button>
                        </div>
                      </div> */}

                 <div className="row">
                        <div className="col-md-4 col-sm-12 col-lg-4 ">
                          <h2
                            style={{
                              color: "#2c333c",
                              fontSize: "30px",
                              fontWeight: 700
                            }}
                          >
                            Contact Number
                          </h2>
                        </div>
                        <div className="col-md-4 col-sm-12 col-lg-4 ">
                        <span className="d-flex">
                        <i
                              className="fas fa-mobile-alt fa-2x"
                              style={{ color: "#a9adb3" }}
                            />
                          <h3
                            style={{
                              color: "#2c333c",
                              fontSize: "1.185rem",
                              fontWeight: 400
                            }}
                          >
                           
                               <input
                            style={{
                              border: this.state.update ? "none" : ""
                            }}
                            type="text"
                            name="contactNo"
                            onChange={this.onChangeContactNo}
                            id="myText2"
                            value={this.state.contactNo}
                            readOnly={this.state.update}
                          />
                          </h3>
                          </span>
                        </div>
                        {/* <div className="col-md-4 col-sm-12 col-lg-4">
                          <button
                            className="float-right btn btn-hover-white my-2 my-sm-0 center"
                            style={{
                              height: "60px",
                              width: "100px",
                              borderRadius: "30px"
                            }}
                            type="submit"
                            onClick={() => this.changeTab(2)}
                          >
                            <b>Edit</b>
                          </button>
                        </div> */}

<div className="col-md-4 col-sm-12 col-lg-4 ">

                        {this.state.update ? (
                         
                              <button
                            className="float-right btn btn-hover-white my-2 my-sm-0 center"
                            style={{
                              height: "60px",
                              width: "100px",
                              borderRadius: "30px"
                            }}
                            type="submit"
                            onClick={() =>
                              this.setState({
                                update: "",
                                purchaseButtonDis: true
                              })
                            }
                          >
                            <b>Edit</b>
                          </button>                           
                          ) : (
                              
                              
                    <button
                            className="float-right btn btn-hover-white my-2 my-sm-0 center"
                            style={{
                              height: "60px",
                              width: "100px",
                              borderRadius: "30px"
                            }}
                            type="submit"
                            onClick={this.checkContact}

                          >
                            <b>Save</b>
                          </button>  
                             
                            )
                          }
</div>
                      </div>

                      <div className="container">
                        <hr />
                        <div style={{ color: "red", fontSize: "12px" }}>
                        {this.state.update === "" ? "Please save Contact" : ""}
                      </div>

                      <div style={{ color: "red", fontSize: "12px" }}>
                        {this.state.errors.contactNo}
                      </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4 col-sm-12 col-lg-4 "></div>
                        <div className="col-md-4 col-sm-12 col-lg-4 "></div>
                        {purchaseButton}

                        <div className="container">
                          <hr />
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-4 IndexBackground"
                      
                    >
                      {/* <h2>Summary</h2> */}
                      <div className="table-responsive-sm">
                        {this.state.selectOption ? (
                          <div>
                            <p
                              id="summery"
                              style={{
                                fontSize: "30px",
                                fontWeight: 900,
                                color: "#4f5be7"
                              }}
                            >
                              Your Order Summary
                            </p>
                            <table className="table" style={{ border: "none" }}>
                              <thead>
                                <tr></tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <b>
                                      {changeCase.titleCase(
                                        this.state.selectedPlanName
                                      )}
                                    </b>
                                  </td>
                                  {(this.state.offerFree ==="free")?
                                 <td>Free Offer</td>:  <td>Rs. {this.state.selectedPlan}</td>}
                                </tr>
                                {}
                                {this.state.selectedPlanName ===
                                "free session"  || this.state.offerFree==="free"? (
                                  ""
                                ) : (null
                                  // <tr>
                                  //   <td>
                                  //     <b>GST @18%</b>
                                  //   </td>
                                  //   <td>Rs. {gstAmt}</td>
                                  // </tr>
                                )}

                                {this.state.offerPrice===""||this.state.offerPrice===undefined?'':<tr>
                                <td>
                                  <b>
                                  Offer-{this.state.offerName}
                                  </b>
                                </td>
                                <td>Rs. {this.state.offerPrice}</td>
                              </tr>}

                                <tr>
                                  <td>
                                    <b>Total</b>
                                  </td>
                                  {
                                    this.state.offerFree==="free"?<td>Free</td>:
                                    <td>
                                    Rs.{" "}
                                    {this.state.selectedPlanName ===
                                    "free session"
                                      ? this.state.selectedPlan
                                      : Number(this.state.selectedPlan) +
                                        Number(gstAmt)-offerPrice}
                                  </td>
                                  }
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        ) : (
                            this.alert()
                          )}
                      </div>
                      <div style={{ paddingTop: "250px" }}>
                        {/* <h2 style="color: #4f5be7;font-size: 24px;font-weight: 700;">How did you hear about us?</h2> */}
                        {/* Large button groups (default and split) */}
                        {}
                      </div>
                    </div>
                  </div>
                </div>
                {/* ------------------------------------------------------payment---------------------------------------- */}
                <div
                  className={
                    this.state.isActive === 5 ? "tab-pane active" : "tab-pane"
                  }
                  id="settings"
                  role="tabpanel"
                  aria-labelledby="settings-tab"
                >
                  {/* <div style="padding-top:5.3rem">
              </div> */}
                  <div className="row">
                    <div className="col-lg-8 col-sm-12 col-md-12">
                      <h1
                        className="roboto-bold-30px"
                        style={{ fontSize: "30px", fontWeight: 700 }}
                      >
                        Credit or debit card
                      </h1>
                      <div
                        className="container pl-0"
                        style={{ paddingBottom: "20px" }}
                      >
                        <div className=" d-flex inline ">
                          <div className="container col-sm-12 col-lg-6 col-md-12 pl-0">
                            <div
                              className=" input-group-prepend mb-3 ml-0 pl-0 mr-2"
                              style={{
                                border: "solid 2px #ebebeb",
                                width: "300px",
                                height: "auto"
                              }}
                            >
                              <input
                                type="text"
                                placeholder="4862 6989 0597 8987"
                                className="form-control roboto-reg-16px"
                                style={{ border: "none", height: "60px" }}
                                aria-label="Text input with dropdown button"
                              />
                              <div className="input-group-prepend border-0 ">
                                <span
                                  className="input-group-text bg-white border-0"
                                  id="validationTooltip01"
                                >
                                  <img src="img/payment/visa.png" alt="booking"/>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="container col-sm-12 col-lg-6 col-md-12 pl-0">
                            <a >
                              {" "}
                              <img alt="booking"
                                className="img-fluid d-none d-lg-block"
                                style={{ paddingLeft: "100px" }}
                                src="img/payment/ssl.png"
                              />
                            </a>
                          </div>
                        </div>
                        <div className="d-flex inline  ">
                          <div className="container col-sm-12 col-lg-6 col-md-12  pl-0">
                            <div
                              className="d-flex inline"
                              style={{ width: "310px" }}
                            >
                              <div
                                className="input-group-prepend mb-3 mr-2 pl-0"
                                style={{
                                  border: "solid 2px #ebebeb",
                                  width: "200px"
                                }}
                              >
                                <input
                                  type="text"
                                  placeholder="10 / 19"
                                  className="form-control roboto-reg-16px"
                                  style={{ border: "none", height: "56px" }}
                                  aria-label="Text input with dropdown button"
                                />
                              </div>
                              <div
                                className="input-group-prepend mb-3 mr-2 pl-0"
                                style={{
                                  border: "solid 2px #ebebeb",
                                  width: "200px"
                                }}
                              >
                                <input
                                  type="text"
                                  placeholder={944}
                                  className="form-control roboto-reg-16px "
                                  style={{ border: "none", height: "56px" }}
                                  aria-label="Text input with dropdown button"
                                />
                                <div className="input-group-prepend border-0 ">
                                  <span
                                    className="input-group-text bg-white border-0"
                                    id="validationTooltip01"
                                  >
                                    <img src="img/payment/pay-Icon-2.png" alt="booking" />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="container col-sm-12 col-lg-6 col-md-12 pl-0">
                            <p
                              className="text-center d-none d-lg-block"
                              style={{ color: "#84888e" }}
                            >
                              We use SSL encription to protect your personal
                              information, including your payment information.
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <h2
                            className="mt-3 ml-3"
                            style={{ fontSize: "30px", fontWeight: 700 }}
                          >
                            Promo Code
                          </h2>
                        </div>
                        <div className="d-flex inline  ">
                          <div
                            className="input-group-prepend mb-3 mr-4 "
                            style={{
                              border: "solid 1px #ebebeb",
                              width: "180px"
                            }}
                          >
                            <input
                              type="text"
                              placeholder="Gifts or Promo codes"
                              className="form-control roboto-reg-16px"
                              style={{ border: "none", height: "56px" }}
                              aria-label="Text input with dropdown button"
                            />
                          </div>
                          <p className="text-center mt-1 d-none d-lg-block">
                            {}
                            <button
                              className="btn btn-hover-white my-2 my-sm-0"
                              style={{
                                height: "60px",
                                width: "100px",
                                borderRadius: "30px"
                              }}
                              type="submit"
                            >
                              <b>Apply</b>
                            </button>
                          </p>
                        </div>
                        <a  style={{ color: "#4f5be7" }}>
                          <i className="fas fa-trash-alt pr-2" /> Remove Code
                        </a>
                        <div className="container">
                          <hr />
                        </div>
                        {/* <div style="padding-top:1.25rem;">
  </div> */}
                        {/* <hr> */}
                        <div
                          style={{
                            paddingTop: "1.5rem",
                            marginBottom: "1.5rem"
                          }}
                        ></div>
                        <div className="float-right mb-5">
                          <button
                            className="float-right btn btn-hover-white my-2 my-sm-0 center  mt-5"
                            id="con1"
                            style={{
                              height: "60px",
                              width: "210px",
                              borderRadius: "30px"
                            }}
                            type="submit"
                          >
                            <b>Continue</b>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-4 IndexBackground"
                    
                    >
                      {}
                      <div className="table-responsive-sm">
                        {this.state.selectOption ? (
                         <table className="table" style={{ border: "none" }}>
                         <thead>
                           <tr></tr>
                         </thead>
                         <tbody>
                           <tr>
                             <td>
                               <b>
                                 {changeCase.titleCase(
                                   this.state.selectedPlanName
                                 )}
                               </b>
                             </td>
                             {(this.state.offerFree ==="free")?
                            <td>Free Offer</td>:  <td>Rs. {this.state.selectedPlan}</td>}
                           </tr>
                           {}
                           {this.state.selectedPlanName ===
                           "free session"  || this.state.offerFree==="free"? (
                             ""
                           ) : (null
                            //  <tr>
                            //    <td>
                            //      <b>GST @18%</b>
                            //    </td>
                            //    <td>Rs. {gstAmt}</td>
                            //  </tr>
                           )}

                           {this.state.offerPrice===""||this.state.offerPrice===undefined?'':<tr>
                           <td>
                             <b>
                             Offer-{this.state.offerName}
                             </b>
                           </td>
                           <td>Rs. {this.state.offerPrice}</td>
                         </tr>}

                           <tr>
                             <td>
                               <b>Total</b>
                             </td>
                             {
                               this.state.offerFree==="free"?<td>Free</td>:
                               <td>
                               Rs.{" "}
                               {this.state.selectedPlanName ===
                               "free session"
                                 ? this.state.selectedPlan
                                 : Number(this.state.selectedPlan) +
                                   Number(gstAmt)-offerPrice}
                             </td>
                             }
                           </tr>
                         </tbody>
                       </table>
                        ) : (
                            this.alert()
                          )}
                      </div>
                      <div
                        style={{
                          paddingTop: "13.5rem",
                          paddingBottom: "2rem"
                        }}
                      >
                        <h2
                          style={{
                            color: "#4f5be7",
                            fontSize: "1.5rem",
                            fontWeight: 700
                          }}
                        >
                          How did you hear about us?
                        </h2>
                        {}
                        {}
                        <select
                          className="form-control roboto-reg-16px"
                          id="exampleFormControlSelect1"
                          placeholder="Select an answer"
                          name="interest"
                          style={{
                            border: "none",
                            height: "60px",
                            width: "300px"
                          }}
                        >
                          <option className="dropdown-item roboto-reg-16px">
                            How did you heard about us
                          </option>
                          <option className="dropdown-item roboto-reg-16px">
                            Ad
                          </option>
                          <option className="dropdown-item roboto-reg-16px">
                            Website
                          </option>
                          <option className="dropdown-item roboto-reg-16px">
                            Internet
                          </option>
                          <option className="dropdown-item roboto-reg-16px">
                            Social Media
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* eo gr-sec-wrap */}
            </div>
          </section>
          {/*eo gr-sec*/}
        </div>

        <div style={{ paddingTop: "12.25rem" }}></div>
        <SubFooter />
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
        <MobileFooter />
      </div>
    );
  }
}
export default BookCounsellingIndex;
