import React from 'react'
import axios from '../axios/axios';
import swal from 'sweetalert';


class LandingIndividual extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          pemail:'',
          pphone:'',
          pname:'',
          pmessage:'',

            errors:{}
          };
          this.baseState = this.state;

        this.prospectReg = this.prospectReg.bind(this);

    }

    prospect = (e) => {
      const prospectForm = this.state
      prospectForm[e.target.name] = e.target.value;
      this.setState(prospectForm);
    }


    validateForm() {
      let fields = this.state;
      let errors = {};
      let formIsValid = true;

      if (!fields["pname"]) {
        formIsValid = false;
        errors["pname"] = "*Please enter your name";
    }


      if (!fields["pemail"]) {
          formIsValid = false;
          errors["pemail"] = "*Please enter your email address";
      }

      if (typeof fields["pemail"] !== "undefined") {
          //regular expression for email validation
          var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          if (!pattern.test(fields["pemail"])) {
              formIsValid = false;
              errors["pemail"] = "*Please enter valid email address";
          }
      }

      if (!fields["pphone"]) {
          formIsValid = false;
          errors["pphone"] = "*Please enter your mobile no.";
      }

      if (!fields["pmessage"]||fields["pmessage"]==="") {
        formIsValid = false;
        errors["pmessage"] = "*Please enter message.";
    }

      if (typeof fields["pphone"] !== "undefined") {
          if (!fields["pphone"].match(/^[0-9]{10}$/)) {
              formIsValid = false;
              errors["pphone"] = "*Please enter valid mobile no.";
          }
      }
      this.setState({
          errors: errors
      });
      return formIsValid;
    }

    prospectReg(e) {
      e.preventDefault();
        if (this.validateForm()) {
        const prospectForm = this.state
        prospectForm["source"] = "Listen-To-Your-Genes";
        this.setState(prospectForm);
          const { pname, pphone, pemail,pmessage ,source} = this.state;
          axios.post('users/prospect', { pname, pphone, pemail, pmessage,source })
              .then((response) => {
                this.setState(this.baseState);

                //   swal("Thank you for your interest. We will get back you soon...").then(
                //     setTimeout(() => {
                //      this.redirect()
                //   }, 3000)
                // );
                this.redirect()

              }).catch(error => {
                if(error.response===undefined){
                  this.props.history.push("/error")
                }else{
                  this.setState({
                      errorMsg: error.response.data.message
                  })
                }

              });
              e.target.reset();
              this.setState(this.baseState);
            }


  }
 redirect(){
    window.location.href="ThanksListen"
 }

   render(){


       return(
        <div>

        <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <link rel="stylesheet" href="style-clinicians.css" />
<title>truGeny Clinicians, India’s First Tele-Genetics Platform with Deep Clinical Expertise.</title>




<div className="banner">

    <div className="container">
        <div className="row">
            <div className="col-sm-12">
                <img className="img-fluid" src="./img/listen-to-your-genes/Logo-&-Speech-bubble (2).png"/>
            </div>
        </div>
    </div>

</div>
<div className="container">
    <div className="row pt-3 pb-3 my-5">
        <div className="col-sm-5 "><img className="img-fluid p-lg-3 p-md-3 p-sm-0" src="./img/listen-to-your-genes/e3.png"/></div>
        <div className="col-sm-7 mt-lg-5 mt-sm-1 pt-sm-1 pt-lg-5 helth"><p>You inherit a lot from your family, including your HEALTH.</p><p></p></div>
    </div>
</div>
<div className="bottom">
<div className="container">
    <div className="row pt-5">
        <div className="col-sm-7 bottomtext mt-lg-5 mt-sm-1 pt-sm-0 pt-lg-5  pr-5 ">
            <p>Genes are passed on from a parent to the child. You inherit one set of genes from your father and one set from your mother. Genes control the way you look, the way your body works and genes are what makes you unique. Genes can also increase the risk in a family for developing certain diseases such as cancers, heart conditions amongst many others.</p>
            <p>Learning about your genes can help you better understand your risks and take actions for preventing diseases and leading a healthier life.</p>
            <p className="new">Listen to your GENES. Take action.<br/>
            Live Healthy. Live Long</p>
        </div>
        <div className="col-sm-5 bottomtex mb-5">



                    <div className="c1 mt-5">
                            <div className="formheading mb-4 mt-2">
                                    <p className="p-2">Yes, I want to learn more</p>

                                </div>

                            <form onSubmit={this.prospectReg}>


                              <div className="form-group">
                                <label for="exampleInputEmail1 text-white"><small className="text-white pb-0 mb-0">Name *</small></label>
                                <input type="text" className="form-control" id="exampleInputEmail1"  aria-describedby="emailHelp" name="pname" onChange={this.prospect}  />
                                <div className="landingformerror">{this.state.errors.pname}</div>

                              </div>
                              <div className="form-group">
                                <label for="exampleInputPassword1 text-white"><small className="text-white pb-0 mb-0">Mobile Number *</small></label>
                                <input type="tel" className="form-control" id="exampleInputPassword1"  pattern="[1-9]{1}[0-9]{9}" onChange={this.prospect}  name="pphone"  />
                                <div className="landingformerror">{this.state.errors.pphone}</div>
                              </div>
                               <div className="form-group">
                                <label for="exampleInputPassword1 text-white"><small className="text-white pb-0 mb-0">Email *</small></label>
                                <input type="email" className="form-control" id="exampleInputPassword1"  name="pemail" onChange={this.prospect} />
                                <div className="landingformerror">{this.state.errors.pemail}</div>

                              </div>

                                  <select className={this.state.errors.pmessage ?"formheadingselect my-3 customdivbox landingformselecterror":"formheadingselect my-3 customdivbox landingformselect"} id="exampleFormControlSelect1" type="text" placeholder=" I am interested to learn about: " name="pmessage" onChange={this.prospect} >
                                      <option className="" value="" selected="" disabled="" >I am interested to learn about:</option>
                                      <option value="My Cancer Risk">My Cancer Risk</option>
                                      <option value="My Heart Health Risk">My Heart Health Risk</option>
                                      <option value="Genetic Counselling">Genetic Counselling</option>
                                      <option value="Pregnancy / Family Planning">Pregnancy  / Family Planning</option>
                                  </select>
                            <h1  className="text-center">
                              <button type="submit" className="btn btn-primary">Submit</button></h1>
                            </form>
                            </div>
        </div>
    </div>
</div>
</div>








        </div>

         )
     }

  }
  export default LandingIndividual;
