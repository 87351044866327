import React from 'react'
import Footer from './Footer';
import MainHeader from './MainHeader';
import StickyHeader from 'react-sticky-header';
import SubFooter from './SubFooter';
import axios from '../axios/axios';
import swal from 'sweetalert';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { Form } from 'reactstrap';
class CliniciansResetPwd extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmPwd: '',
      errors: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.toResetPwd=this.toResetPwd.bind(this);
  }

  handleChange = (e) => {
    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);
  }

  validateForm() {

    let fields = this.state;
    let errors = {};
    let formIsValid = true;

  
    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "*Please enter your password.";
    }

    if (typeof fields["password"] !== "undefined" && fields["password"] !== "") {
      if (!fields["password"].match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
        formIsValid = false;
        errors["password"] = "*Please enter secure and strong password.";
      }
    }
    if (!fields["confirmPwd"]) {
      formIsValid = false;
      errors["confirmPwd"] = "*Please confirm password.";
    }
    if (fields["password"] !== fields["confirmPwd"]) {
      formIsValid = false;
      errors["confirmPwd"] = "*Password mismatched";

    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }
    
    toResetPwd(e) {
        e.preventDefault();
        if (this.validateForm()) {
          let email = localStorage.getItem("email");
          const { password   } = this.state;
          axios.post('users/changepwd', {email, password })
            .then((response) => {

              swal("Password has been changed....!")
    
              this.props.history.push('/clinicians_login');
            }).catch(
              error => {
                if (error.response === undefined) {
                  this.props.history.push("/clinicians_error")
                } else {
                  this.setState({
                    errorMsg: error.response.data.message
                  })
                }
              }
            )
        }
      }

   render(){
       return(
        <div>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        {/* <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css"> */}
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese" rel="stylesheet" />
        <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
        {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossorigin="anonymous"> */}
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>truGeny</title>
        <link rel="icon" href="./img/landingpage/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/header_footer.css" />
        <link rel="stylesheet" href="./css/payment.css" />
        <style dangerouslySetInnerHTML={{__html: "\n\ninput[type='radio'] {\n    -webkit-appearance:none;\n    width:15px;\n    height:15px;\n    border:1px solid darkgray;\n    border-radius:50%;\n    outline:none;\n    box-shadow:0 0 5px 0px gray inset;\n}\ninput[type='radio']:hover {\n    box-shadow:0 0 5px 0px lightblue inset;\n}\ninput[type='radio']:before {\n    content:'';\n    display:block;\n    width:60%;\n    height:60%;\n    margin: 20% auto;\n    border-radius:50%;\n}\ninput[type='radio']:checked:before {\n    background:blue;\n}\n\n    " }} />
        {/* --------------------------------------------------------------------------------------------head section end-------------------------------------------------------------------------------------------- */}
        <StickyHeader
    // This is the sticky part of the header.
    header={
      <div style={{ backgroundColor:"white" }}>
     
     <MainHeader></MainHeader>
        </div>
    }
  >
   
  </StickyHeader>
        <div style={{paddingTop: '14rem'}} />
        <div className=" text-center mb-1 sec-1">
          <div className="container c1 text-center">
            <div className="head-text">
              <h1 className="head-text-h1  text-center img-fluid roboto-black-36px">Reset Password</h1>
            </div>
            {/* ---------------------------------------------------section 2 started-----------*/}
            <div className="cr-sec-main container mt-5 pt-3  pb-5 mb-2 b1-cr ">
              <div className="head-text">
                {/* <h1 class="py-3 text-center img-fluid roboto-reg-24px">We have sent you an OTP on <br/> +9926934237 </h1> */}
              </div>
              <Form  className="cr-form pt-0 gc-form needs-validation" action method validate>
              <div className="gc-form-card-btm pt-2 pb-3">
                  <div className="form-row justify-content-center pt-5 ">
                    <div className="cr-col col-sm-10 col-md-8 col-lg-6 mb-3">
                      <div className="input-group">
                        <input type="password" name="password" onChange={this.handleChange} className="form-control cr-email m-0" id="validationTooltipEmailPrepend" placeholder="  Enter New Password" aria-describedby="validationTooltipEmailPrepend" required />
                        <span className="input-group-text" id="validationTooltipEmailPrepend" style={{border: 'none', backgroundColor: 'white'}}><img className="img-fluid" src="img/payment/pwd.png" /></span>

                        <div className="invalid-tooltip">
                          Enter New Password
                        </div>
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="validationTooltipEmailPrepend"><img className="d-none" src="img/homepage/i3.png" /></span>
                        </div>
                      </div>
                      <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.password}</div>
                    </div>
                  </div>
                  {/* end of form-row */}
                  <div className="form-row justify-content-center">
                    <div className="cr-col col-sm-10 col-md-8 col-lg-6 mb-3">
                      <div className="input-group">
                        <input type="password" name="confirmPwd" onChange={this.handleChange} className="form-control cr-email m-0" id="validationTooltipEmailPrepend" placeholder="  Confirm Password " aria-describedby="validationTooltipEmailPrepend" required />
                        <span className="input-group-text" id="validationTooltipEmailPrepend" style={{border: 'none', backgroundColor: 'white'}}><img className="img-fluid" src="img/payment/pwd.png" /></span>

                        <div className="invalid-tooltip">
                          Confirm Password
                        </div>
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="validationTooltipEmailPrepend"><img className="d-none" src="img/homepage/i3.png" /></span>
                        </div>
                      </div>
                      <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.confirmPwd}</div>
                    </div>
                  </div>
                  
                  <div className="after-form-sec">
                {/*Grid column*/}
                <div className="row d-flex justify-content-center px-3 pt-3">
                  <button className="btn btn-outline-primary btn-hover-white b-0 my-sm-0 center px-5" style={{height: '60px', width: '260px', borderRadius: '1.875rem', color: 'white', background: '#4f5be7'}} type="submit" onClick={this.toResetPwd}>
                    <b>Change Password</b></button>
                </div>
              </div>

               
              </div>
              </Form>
              {/* end of gc-form-card-btm */}
             {/* end of after-form-sec */}
            </div>
            
            
            {/* end of cr-sec-main */}
            <div className="container text-center mt-5 pb-5">
              <img className="plus-button img-fluid mb-3" src="./img/registration/icon.png" />
              <p className=" text-center  " style={{color: '#84888e'}}>Are you a Doctor or a Health Care Provider? If you are trying <br />to order a truGeny Test or wanting to view your patients report, <br />
                <a className="pb-5 cr-1" href="clinicians_login"><strong>CLICK HERE</strong></a> to create your account</p>
            </div>
          </div>
        </div>
        <div style={{paddingTop: '12.25rem'}}>
        </div>
        {/* ------------------------------------------------------------------------------------------------- */}
        <SubFooter></SubFooter>
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>
       )
   }

}
export default CliniciansResetPwd;