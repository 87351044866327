import axios from 'axios';

export default axios.create({
//Local testing
    // baseURL: 'http://localhost:8080',
//Test Sever
    //   baseURL: 'http://13.232.201.151:8081',
    // baseURL: 'http://35.154.243.213:8081',
//Live
    baseURL: 'https://trugene.in:8081',
// ravi in dec=velop branch
});
