import React from "react";
import *as PayContants from './paymentContants';


class InnerHelpContact extends React.Component {
       render(){
           return( <div className="container pt-0 pb-2 mt-0">
           <nav className="navbar container navbar-expand-xl  navbar-light d-none d-lg-block d-xl-block float-left py-0">
             <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
               <span className="navbar-toggler-icon">
               </span>
             </button>
             <ul className=" row navbar-nav mr-auto ">
               <li className="nav-item ">
                 {/* <a class="nav-link float-right text-center activenavbar" href="index.html"><span class="activenavbar"><i  class=" i-button mr-1 fas fa-question-circle"></i></span>FAQs</a> */}
               </li>
               <li className="nav-item">
                 {/* <a class="nav-link" href="genetic-counseling.html">Generic Counseling</a> */}
               </li>
               <li className="nav-item">
                 <a className="nav-link" href={PayContants.truGenyMailhref} data-toggle="tooltip" title={PayContants.truGenyMail}><span><i className="i-button mr-1 fas fa-at" /></span>Email</a>
               </li>
               <li className="nav-item active">
                 <a className="nav-link " title={PayContants.truGenyConatct} data-toggle="tooltip" href={PayContants.truGenyHrefConatct}  style={{color: '#4f5be7'}}><span><i className="i-button mr-1 fas fa-mobile-alt" /></span>{PayContants.truGenyConatct} </a>
               </li>
             </ul>
           </nav>
           <br />
           <br />
         </div>

           );
           }
    }
    export default InnerHelpContact;