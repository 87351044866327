import React from 'react';
import CommonHeder from './CommonHeder';
import SubFooter from './SubFooter';
import { Collapse} from 'reactstrap';
import InnerHelpContact from './InnerHelpContact';



class AllHelp extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
         
      q1:false,
      b1:true,

      q2:false,
      b2:true,

      q3:false,
      b3:true,

      q4:false,
      b4:true,

      q5:false,
      b5:true,

      q6:false,
      b6:true,

      q7:false,
      b7:true,

    }
    this.toggle1 = this.toggle1.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.toggle3 = this.toggle3.bind(this);
    this.toggle4 = this.toggle4.bind(this);
    this.toggle5 = this.toggle5.bind(this);
    this.toggle6 = this.toggle6.bind(this);

   this.toOrderHeart=this.toOrderHeart.bind(this)

  }

  toggle1() {
    this.setState(state => ({ q1: !state.q1 }));
    this.setState(state => ({ b1: !state.b1 }));

    
    this.setState({q2: false}, function () {});
    this.setState({b2: true}, function () {});
    this.setState({q3: false}, function () {});
    this.setState({b3: true}, function () {});
    this.setState({q4: false}, function () {});
    this.setState({b4: true}, function () {});
    this.setState({q5: false}, function () {});
    this.setState({b5: true}, function () {});
    this.setState({q6: false}, function () {});
    this.setState({b6: true}, function () {});
    this.setState({q7: false}, function () {});
    this.setState({b7: true}, function () {});

  }

  toggle2() {
    this.setState(state => ({ q2: !state.q2 }));
    this.setState(state => ({ b2: !state.b2 }));

    this.setState({q1: false}, function () {});
    this.setState({b1: true}, function () {});
    this.setState({q3: false}, function () {});
    this.setState({b3: true}, function () {});
    this.setState({q4: false}, function () {});
    this.setState({b4: true}, function () {});
    this.setState({q5: false}, function () {});
    this.setState({b5: true}, function () {});
    this.setState({q6: false}, function () {});
    this.setState({b6: true}, function () {});
    this.setState({q7: false}, function () {});
    this.setState({b7: true}, function () {});
  }

  toggle3() {
    this.setState(state => ({ q3: !state.q3 }));
    this.setState(state => ({ b3: !state.b3 }));

    this.setState({q1: false}, function () {});
    this.setState({b1: true}, function () {});
    this.setState({q2: false}, function () {});
    this.setState({b2: true}, function () {});
    this.setState({q4: false}, function () {});
    this.setState({b4: true}, function () {});
    this.setState({q5: false}, function () {});
    this.setState({b5: true}, function () {});
    this.setState({q6: false}, function () {});
    this.setState({b6: true}, function () {});
    this.setState({q7: false}, function () {});
    this.setState({b7: true}, function () {});
  }

  toggle4() {
    this.setState(state => ({ q4: !state.q4 }));
    this.setState(state => ({ b4: !state.b4 }));

    this.setState({q1: false}, function () {});
    this.setState({b1: true}, function () {});
    this.setState({q2: false}, function () {});
    this.setState({b2: true}, function () {});
    this.setState({q3: false}, function () {});
    this.setState({b3: true}, function () {});
    this.setState({q5: false}, function () {});
    this.setState({b5: true}, function () {});
    this.setState({q6: false}, function () {});
    this.setState({b6: true}, function () {});
    this.setState({q7: false}, function () {});
    this.setState({b7: true}, function () {});
  }
  toggle5() {
    this.setState(state => ({ q5: !state.q5 }));
    this.setState(state => ({ b5: !state.b5 }));

    this.setState({q1: false}, function () {});
    this.setState({b1: true}, function () {});
    this.setState({q2: false}, function () {});
    this.setState({b2: true}, function () {});
    this.setState({q3: false}, function () {});
    this.setState({b3: true}, function () {});
    this.setState({q4: false}, function () {});
    this.setState({b4: true}, function () {});
    this.setState({q6: false}, function () {});
    this.setState({b6: true}, function () {});
    this.setState({q7: false}, function () {});
    this.setState({b7: true}, function () {});
  }

  toggle6() {
    this.setState(state => ({ q6: !state.q6 }));
    this.setState(state => ({ b6: !state.b6 }));

    this.setState({q1: false}, function () {});
    this.setState({b1: true}, function () {});
    this.setState({q2: false}, function () {});
    this.setState({b2: true}, function () {});
    this.setState({q3: false}, function () {});
    this.setState({b3: true}, function () {});
    this.setState({q4: false}, function () {});
    this.setState({b4: true}, function () {});
    this.setState({q5: false}, function () {});
    this.setState({b5: true}, function () {});
    this.setState({q7: false}, function () {});
    this.setState({b7: true}, function () {});
  }

 



  toOrderHeart() {
   // browserHistory.push("/ordertrugene");
    window.location.href="/ordertrugene";
   }


  render() {

    return (
        <div>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
        {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.2/css/all.css" integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay" crossorigin="anonymous"> */}
        <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>truGeny</title>
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/header_footer.css" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <link rel="stylesheet" href="./css/sticky.css" />
        <style dangerouslySetInnerHTML={{__html: "\n/* @media only screen and (min-width: 992px)\n/* {\n.custom-pad-mar {\n  padding-left:25rem;\n  padding-right:25rem;\n\n} */\n\n.activenavbar{\n  color:#4f5be7!important;\n}\n.card-body{\n  text-align: left!important;\n}\n.heart-banner-sec-wrap .heart-banner-sec{\n  background: url(./img/help/bg1.png);\n  background-position:center;\n  background-size: cover;\n  background-repeat: no-repeat;\n}\n.collapse .show{\n  background-color: WHITE!important;\n}\n.accordion>.card .card-header::after {\n    margin-bottom: -1px;\n    background: blue;\n}\n.accordion>.card .card-header::before {\n    margin-bottom: -1px;\n    background: blue;\n}\n\n" }} />
        {/* header */}
        <CommonHeder></CommonHeder>
        {/* end of header */}
        <div style={{marginTop: '9rem'}} className="margin">
        </div>
        <hr id="hor-line" className style={{marginTop: '.5rem', marginBottom: '.1rem', border: 0, borderTop: '1px solid rgba(0,0,0,.1)'}} />
        <InnerHelpContact></InnerHelpContact>
        <section className="heart-banner-sec-wrap banner-position">
          <div className=" heart-banner-sec ">
            <div className="container pt-4 mt-2">
              <h1 className><strong>truGeny support</strong></h1>
              <h2 className="roboto-reg-24px my-4">Get the right answers to your questions</h2>
              <div className="input-group " style={{height: '75px', boxShadow: '0 17px 40px rgba(92, 103, 108, 0.2)', backgroundColor: '#ffffff', borderRadius: '2rem'}}>
                <input type="text" className="form-control m-0" placeholder="Search for help.." id="myInput" onkeyup="myFunction()" style={{borderTopLeftRadius: '2rem', borderBottomLeftRadius: '2rem', height: '75px', boxShadow: '0 17px 40px rgba(92, 103, 108, 0.2)', backgroundColor: '#ffffff'}} required />
                <div className="valid-tooltip">
                  Looks good!
                </div>
                <div className="input-group-prepend border-0 d-flex justify-content-center">
                  <span className="btn btn-outline-primary input-group-text border-0 justify-content-center" style={{cursor: 'pointer', borderBottomRightRadius: '2rem', borderTopRightRadius: '2rem', backgroundColor: '#4f5be7', width: '160px', color: 'white'}} id="validationTooltip01">SEARCH</span>
                </div>
              </div>
            </div>{/* eo-container*/}
            <div className="d-flex justify-content-center" id="secc1">
              <a style={{scrollBehavior: '[ auto | smooth ]'}} href="#secc1">  <img className="img-fluid d-none d-md-block d-lg-block btn-hover-white1" src="./img/111.png" /></a>
            </div>
          </div>{/* eo-heart-banner-sec */}
        </section>{/* eo-heart-banner-sec-wrap */}
        {/* ------------------------------------------------------heart-banner-sec over------------------------------------------------------------------ */}
        <section className="container" id="myData">
          {/* <nav aria-label="breadcrumb pt-5 " >
    <ol class="breadcrumb " style="background-color: #fff !important;padding-top:3rem">
      <li class="breadcrumb-item"><a href="#" class="roboto-reg-18px" style="color: #4f5be7;" ><b>Support</b></a></li>
      <li class="breadcrumb-item active" aria-current="page"><a  class="roboto-reg-18px" >Clinicians</a></li>
    </ol>
  </nav> */}
         
      <div className=" mt-5">
            <div className="text-center">
              <div className="card-block  p-2 circle">
                <div className="pr-5 pb-5 pl-5 hc-accordian-wrap">
                  <div className="accordion" id="accordionIhc">

                  <div className="card ">
                    <div className="card-header" id="headingThree">
                      <a href="/all_help_covid" style={{color: '#4f5be7'}}>
                      </a><h5 className="acc-header collapse-color mb-0"><a href="/all_help_covid" className="py-3 px-3" style={{backgroundColor: '#4f5be7',color:'white',borderRadius:'10px',border:'1px solid red'}}>
                         Coronavirus HELP</a>
                          <a  onClick={this.toggle3} id="headingOneImg" className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#collapseOne">SHOW MORE<img className="ml-4" src="img/help/caret.png" /></a>
                      </h5>
                    </div>
                    <Collapse isOpen={this.state.q3}>
                    <div id="collapseThree" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                      <div className="card-body">
                        {/* <a href=""  class=" btn btn-link roboto-bold-18px" style="color: #000;font-size:18px;">
                      <p class=" roboto-reg-18px">What is genetic counselling, who is a genetic counsellor, what happens in a genetic counselling session?Learn all about genetic counselling</p>
            </a> */}
                        <a href="/help_covid" style={{color: '#000', fontSize: '18px'}}>
                          <p className=" roboto-reg-18px">
                          What effect does the coronavirus have on pregnant women?<br/><br/>

                 If you are naturally healthy and are pregnant, you can rest easy. As of yet, no cases have been recorded with proof of increased infection from coronavirus on pregnant women. If infected, you will most likely encounter similar symptoms of cold/flu.

                Symptoms of pneumonia might appear in older people with weak immunity or long-term conditions. Other than that, you can stay in touch with your maternity doctor and you should be good to go. For emergency purposes, always keep a tab on the contact information available on MoHFW | Home
                <br/>
                  </p></a><a>  </a>
                  <div className="d-flex justify-content-right"  >
                      <a href="/all_help_covid" >Show more</a>
                      </div>
                      </div>




                      </div>
                    </Collapse>
                      <a>
                    </a></div>
       
                                  




                    <div className="card ">
                      <div className="card-header" id="headingOne">
                        <a href="/all_help_indivisual" style={{color: '#4f5be7'}}>
                        </a><h5 className="acc-header collapse-color mb-0"><a href="/all_help_indivisual" style={{color: '#4f5be7'}}>
                            I’m an individual</a>
                          <a  onClick={this.toggle1} id="headingOneImg" className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#collapseOne">SHOW MORE<img className="ml-4" src="img/help/caret.png" /></a>
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q1}>
                      <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionIhc">
                        <div className="card-body border-0">
                          <a href="/all_help_indivisual" style={{color: '#000'}}>  <p className=" roboto-reg-18px">You will find information to help you understand about how to book your genetic counselling appointment, order a test, provide a sample, activating your kit and much more.</p></a>
                        </div>
                      </div>
                      </Collapse>
                      
                    </div>
                    <div className="card d-none">
                      <div className="card-header" id="headingTwo">
                        <a href="/al_help_clinicians" style={{color: '#4f5be7'}}>
                        </a><h5 className="acc-header collapse-color mb-0"><a href="/al_help_clinicians" style={{color: '#4f5be7'}}>
                            I’m a physician</a>
                            <a  onClick={this.toggle2} id="headingOneImg" className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#collapseOne">SHOW MORE<img className="ml-4" src="img/help/caret.png" /></a>
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q2}>
                      <div id="collapseTwo" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordionIhc">
                        <div className="card-body">
                          <a href="/al_help_clinicians" style={{color: '#000'}}>
                            <p className=" roboto-reg-18px">You will find information to help you understand about how to order a test, provide a sample, activating your kit and much more.                    </p>
                          </a>
                        </div>
                      </div>
                      </Collapse>
                      
                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        <a href="/al_help_counselling" style={{color: '#4f5be7'}}>
                        </a><h5 className="acc-header collapse-color mb-0"><a href="/al_help_counselling" style={{color: '#4f5be7'}}>
                            Genetic Counselling</a>
                            <a  onClick={this.toggle3} id="headingOneImg" className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#collapseOne">SHOW MORE<img className="ml-4" src="img/help/caret.png" /></a>
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q3}>
                      <div id="collapseThree" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          {/* <a href=""  class=" btn btn-link roboto-bold-18px" style="color: #000;font-size:18px;">
                        <p class=" roboto-reg-18px">What is genetic counselling, who is a genetic counsellor, what happens in a genetic counselling session?Learn all about genetic counselling</p>
              </a> */}
                          <a href="/al_help_counselling" style={{color: '#000', fontSize: '18px'}}>
                            <p className=" roboto-reg-18px">
                              What is genetic counselling, who is a genetic counsellor, what happens in a genetic counselling session?Learn all about genetic counselling                   </p></a><a>
                          </a></div><a>
                        </a></div>
                      </Collapse>
                        <a>
                      </a></div><a>
                    </a><div className="card "><a>
                      </a><div className="card-header" id="headingFour"><a>
                        </a><a href="/al_help_genetic_test" style={{color: '#4f5be7'}}>
                        </a><h5 className="acc-header collapse-color mb-0"><a href="/al_help_genetic_test" style={{color: '#4f5be7'}}>
                        truGeny Genetic Tests</a>
                        <a  onClick={this.toggle4} id="headingOneImg" className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#collapseOne">SHOW MORE<img className="ml-4" src="img/help/caret.png" /></a>
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q4}>
                      <div id="collapseFour" className="collapse show" aria-labelledby="headingFour" data-parent="#accordionIhc">
                        <div className="card-body">
                          <a href="/al_help_genetic_test" className=" btn btn-link roboto-bold-18px" style={{color: '#000', fontSize: '18px'}}>
                            <p className=" roboto-reg-18px">Learn more about truGeny tests.</p>
                          </a>
                        </div>
                      </div>
                      
                      </Collapse>
                      
                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingFive">
                        <a href="al_help_security" style={{color: '#4f5be7'}}>
                        </a><h5 className="acc-header collapse-color mb-0"><a href="al_help_security" style={{color: '#4f5be7'}}>
                            Security &amp; Privacy</a>
                            <a  onClick={this.toggle5} id="headingOneImg" className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#collapseOne">SHOW MORE<img className="ml-4" src="img/help/caret.png" /></a>
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q5}>
                      <div id="collapseFive" className="collapse show" aria-labelledby="headingFive" data-parent="#accordionIhc">
                        <div className="card-body">
                          <a href="al_help_security" className=" btn btn-link roboto-bold-18px" style={{color: '#000', fontSize: '18px'}}>
                            <p className=" roboto-reg-18px">Learn more about Security &amp; Privacy.</p></a>
                        </div>
                      </div>
                      </Collapse>
                      
                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingSix">
                        <a href="#" style={{color: '#4f5be7'}}>
                        </a><h5 className="acc-header collapse-color mb-0"><a href="#" style={{color: '#4f5be7'}}>
                            Contact Us</a>
                            <a  onClick={this.toggle6} id="headingOneImg" className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#collapseOne">SHOW MORE<img className="ml-4" src="img/help/caret.png" /></a>
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q6}>
                      <div id="collapseSix" className="collapse show" aria-labelledby="headingSix" data-parent="#accordionIhc">
                        <div className="card-body">
                          <a href="#" style={{color: '#000'}}>
                          </a><a href="#" className=" btn btn-link roboto-bold-18px" style={{color: '#000', fontSize: '18px'}}>
                            <p className=" roboto-reg-18px">Let’s be in touch.</p></a>
                        </div>
                      </div>
                      
                      </Collapse>
                      
                    </div>
                  </div>
                </div>{/* eo bs-example */}
                {/* end of accordian code */}
              </div>
            </div>{/* eo card-block */}
          </div>{/* eo card body */}
          {/* eo card */}
          {/* eo-heart-accordian-sec-wrap */}
        </section>{/* eo-heart-accordian-sec */}
        {/* **********Schedule your Genetic Counseling Session Ends Here************** */}
        {/* Footer Links */}
        <SubFooter></SubFooter>
        {/* end of footer */}
        <div className="modal fade bd-example-modal-lg" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content" style={{borderRadius: '40px'}}>
              <div className="modal-header bg-blue clearfix border-0" style={{borderTopRightRadius: '40px', borderTopLeftRadius: '40px'}}>
                <h5 className="modal-title" id="exampleModalLongTitle" style={{paddingTop: '5px', paddingBottom: '25px', color: '#2c333c', fontSize: '30px', fontWeight: 700}}>
                  Start your journey in 3 easy steps</h5>
                <button type="button" className="text-center close" data-dismiss="modal" aria-label="Close" style={{paddingTop: '25px', paddingBottom: '25px'}}>
                  <img className="img-fluid" src=".\img\registration\X.png" />
                </button>
                {/* <p class="float-left">Question 1 of 3</p> */}
              </div>
              <div className="bg-blue border-bottom-1">
                <p className="pl-3">Question 1 of 3</p>
              </div>
              <div className="modal-body pb-5 pt-0 m-0">
                <div className="container ">
                  <br />
                  <div className="tab-content">
                    <div id="home" className="container tab-pane active"><br />
                      <h3>How do you indentify your gender?</h3>          <hr />
                      <div className="d-flex d-flex-inline">
                        <img className="mr-3" src="./img/q&a/female.png" alt="" />
                        <img className="mr-3" src="./img/q&a/female.png" alt="" />
                      </div>
                      <div className="float-right pt-2 mt-2">
                        <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill" href="#menu1">Next</button>
                      </div>
                    </div>
                    <div id="menu1" className=" m-0 p-0 container tab-pane fade"><br />
                      <h3>Does anyone in your family have 2 diabetes?</h3><hr />
                      <h4>Certain ethnic groups have higher risk for diabetes,<br />
                        even at lower body weights.</h4>
                      <div className="col-xs-12 mt-2 ">
                        <div className="input-group-prepend " style={{border: 'solid 1px #ebebeb', maxWidth: '312px'}}>
                          <input type="text" placeholder="Select your ethnicity" className="form-control roboto-reg-16px" style={{border: 'none', height: '60px'}} aria-label="Text input with dropdown button" />
                          <button className="m-0 p-0 btn btn-outline-secondary roboto-bold-14px " href="#" style={{border: 'none', backgroundColor: 'white'}} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img className="m-0 p-0" src="img/q&a/carot.png" /></button>
                        </div>
                      </div>
                      <div className="float-right pt-2 mt-2">
                        <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill" href="#menu2">Next</button>
                      </div>
                    </div>
                    <div id="menu2" className=" m-0 p-0 container tab-pane fade"><br />
                      <h3>Does anyone in your family have 2 diabetes?</h3>
                      <hr />
                      <div className="radio">
                        <label><input type="radio" name="optradio" defaultChecked />  Parents</label>
                      </div>
                      <div className="radio">
                        <label><input type="radio" name="optradio" />  Siblings</label>
                      </div>
                      <div className="float-right pt-2 mt-2">
                        <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill" onclick="window.location.href = 'thankyou2.html';" href="#">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* sticky form---------------- */}
              {/* <div class="modal-footer">
                  <button type="button" class="btn btn-secondary"  data-toggle="pill" href="#menu2">Close</button>
                  <button type="button" class="btn btn-primary"  data-toggle="pill" href="#menu1">Next</button>
                </div> */}
            </div>
          </div>
        </div>
        {/* sticky form---------------- */}
        <div className="row ">
          <div className="nb-form">
            {/* <p class="title d-none d-lg-block ">May I Help You</p> */}
            <img src="https://www.growyourbrand.com/wp-content/uploads/2018/11/chat.gif" alt="" className="user-icon img-fluid" />
            <form>
              <input type="text" name="cpname" placeholder="Name:" required />
              <input type="email" name="cpemail" placeholder="Email:" required />
              <input type="tel" name="cpphone" placeholder="Phone:" required />
              <textarea name="cpmessage" placeholder="Message:" required defaultValue={""} />
              <input type="submit" defaultValue="Send message" style={{background: '#4f5be7'}} />
            </form>
          </div>
        </div>
        {/* --------eo sticky form----------------- */}
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>

    );
  }
}
export default AllHelp;