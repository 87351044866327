import React from 'react';
import {withRouter} from 'react-router-dom'
import axios from '../axios/axios';
import swal from 'sweetalert';
import { FadeLoader} from 'react-spinners';
import { css } from '@emotion/core';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;
class OpenReferProspect extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        doctorsData:'',
        data:'',
        loading: false,
        referProspect:{},
        errors:{},
      };
    this.prospectReg = this.prospectReg.bind(this);
    this.prospectReg2=this.prospectReg2.bind(this);
    this.getDoctorsData=this.getDoctorsData.bind(this)
   
  }
  prospect = (e) => {
    const prospectForm = this.state.referProspect
    prospectForm[e.target.name] = e.target.value;
    this.setState(prospectForm);
  }
  loader(){
    return (
      <div className='sweet-loading'>
        <FadeLoader
          css={override}
          sizeUnit={"px"}
          size={150}
          color={'#123abc'}
          loading={this.state.loading}
        />
    </div> )

  
  }
  validateForm() {

    let fields = this.state;
    let errors = {};
    let formIsValid = true;

    if (!fields["pemail"]) {
      formIsValid = false;
      errors["pemail"] = "*Please enter your email address";
    }

    if (typeof fields["pemail"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(fields["pemail"])) {
        formIsValid = false;
        errors["pemail"] = "*Please enter valid email address";
      }
    }

    if (!fields["pphone"]) {
      formIsValid = false;
      errors["pphone"] = "*Please enter your mobile no.";

    }

    if (typeof fields["pphone"] !== "undefined") {
      if (!fields["pphone"].match(/^[0-9]{10}$/)) {
        formIsValid = false;
        errors["pphone"] = "*Please enter valid mobile no.";
      }
    }
    if (!fields["pmessage"]) {
      formIsValid = false;
      errors["pmessage"] = "*Please enter your message";
    }
    if (!fields["referedByClinicianMedicalRegNo"]) {
      formIsValid = false;
      errors["referedByClinicianMedicalRegNo"] = "*Please enter your Medical Registration No";
    }
    if (!fields["referedByClinicianSmcName"]) {
      formIsValid = false;
      errors["referedByClinicianSmcName"] = "*Please Select Smc Name";
    }
    if (!fields["referedByClinicianYearInfo"]) {
      formIsValid = false;
      errors["referedByClinicianYearInfo"] = "*Please Select Year";
    }
    if (!fields["referedByClinicianEmail"]) {
      formIsValid = false;
      errors["referedByClinicianEmail"] = "*Please enter your Clinician  email address";
    }

    if (typeof fields["referedByClinicianEmail"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(fields["referedByClinicianEmail"])) {
        formIsValid = false;
        errors["referedByClinicianEmail"] = "*Please enter valid Clinician  email address";
      }
    }

    if (!fields["referedByClinicianContact"]) {
      formIsValid = false;
      errors["referedByClinicianContact"] = "*Please enter Clinician mobile no.";

    }


    if (typeof fields["referedByClinicianContact"] !== "undefined") {
      if (!fields["referedByClinicianContact"].match(/^[0-9]{10}$/)) {
        formIsValid = false;
        errors["referedByClinicianContact"] = "*Please enter valid Clinician mobile no.";
      }
    }


    if (!fields["organizationname"]) {
      formIsValid = false;
      errors["organizationname"] = "*Please enter your Organization name.";
    }

    if (typeof fields["organizationname"] !== "undefined") {
      if (!fields["organizationname"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["organizationname"] = "*Please enter alphabet characters only.";
      }
    }


    this.setState({
      errors: errors
    });
    return formIsValid;

  }
  prospectReg(e) {
    e.preventDefault();
    const prospectForm = this.state.referProspect
    prospectForm["source"] = "ReferedByClinician";
    this.setState(prospectForm);
   if (this.validateForm()) {

        axios.post('users/prospect', this.state.referProspect)
            .then((response) => {
              this.setState({loading:false})
                
                swal("Thank you for your interest. We will get back to you soon...")


                // this.setState({
                //     sucessMsg:"Thank you for your interest. We will get back you soon..."
                // })
               // alert("Thanks for your intersr. We will get back you soon")   
            
 
            }).catch(error => {
              if(error.response===undefined){
                this.props.history.push("/clinicians_error")
              }else{
                this.setState({
                    errorMsg: error.response.data.message
                })  
              }
               
            });

            e.target.reset();
            
            this.prospectReg2(e);
    }
   
  
}
prospectReg2(e){
  e.preventDefault();
  e.target.reset();
}
getDoctorsData(){
    this.setState({loading:true},()=>{}
    )
    let id=this.state.referProspect.referedByClinicianMedicalRegNo;
    if(id){
    (async () => {
      const rawResponse = await fetch('https://mciindia.org/MCIRest/open/getDataFromService?service=searchDoctor', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 'registrationNo': id})
      });
      const content = await rawResponse.json();
      this.setState({

        doctorsData: content,
        data:"Invalid Registration Number...!",
        loading:false

      })
    
     
    })();
  }
  }

  
doctorsDataRender(){

    return(
      <div className="form-row justify-content-center">
        <div className="cr-col  col-sm-5 col-md-4 col-lg-3 mb-3" htmlFor="validationTooltip01">
        <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.referedByClinicianSmcName}</div>

          <div className="input-group ">
            <select type="text"  name="referedByClinicianSmcName" onChange={this.prospect} className="form-control m-0" id="validationTooltip01"  placeholder="Registration Counsil" required >
            <option  value="" hidden="hidden">Medical Counsellor Name</option>
            {this.state.doctorsData.map((e, key) => {
            return <option  key={key} selected={e == e.value} value={e.value}>{e.smcName}</option>;
        })}
            </select>
            <div className="valid-tooltip">
              Looks good!
            </div>
            <div className="input-group-prepend border-0">
              <span className="input-group-text border-0" id="validationTooltip01"><img src="img/homepage/i1.png" /></span>
            </div>
          </div>
        </div>
        <div className="cr-col  col-sm-5 col-md-4 col-lg-3 mb-3">
        <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.referedByClinicianYearInfo}</div>

          <div className="input-group">
          <select type="text" name="referedByClinicianYearInfo" onChange={this.prospect} className="form-control m-0" id="validationTooltip01"  placeholder="Registration Counsil" required >
          <option  value="" hidden="hidden">Registration Year</option>
            {this.state.doctorsData.map((e, key) => {
            return <option  key={key} value={e.value}>{e.yearInfo}</option>
  
        })}
            </select>          <div className="valid-tooltip">
              Looks good!
            </div>
            <div className="input-group-prepend">
              <span className="input-group-text" id="validationTooltip02"><img src="img/homepage/i1.png" /></span>
            </div>
          </div>
        </div>
      </div>
    )
  
  }

  


  render() {
      return (
        <div>

<div style={{paddingBottom: '6rem'}}>
          <h2 className="text-center roboto-black-40px">Use this form to refer your patients</h2>
        </div>
        {/* Start of Genetic Counsell Help cards  */}
        <div className="gc-form-card container ">
          <div className="gc-form-card-wrap  shadow-lg">
         
            {/* <h2 className="text-center roboto-black-40px p-5">Information about your patient</h2> */}
            <h2 className="text-center roboto-black-40px p-5">Patient Information Form </h2>

            <div className="pgc-form-card-btm">
              <form className="gc-form needs-validation" onSubmit={this.prospectReg}>
                <div className="form-row justify-content-center">
                  <div className=" gc-col col-md-5 mb-3">
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.pname}</div>

                    <div className="input-group">
                      <input type="text" className="form-control m-0" name="pname"  onChange={this.prospect} id="validationTooltipEmailPrepend" placeholder="Patient's Name" aria-describedby="validationTooltipEmailPrepend" required />
                      <div className="invalid-tooltip">
                        Please enter a valid E-mail ID.
                      </div>
                      <div className="input-group-prepend">
                        <span style={{borderRight: '1px solid #ebebeb'}} className="input-group-text" id="validationTooltipEmailPrepend"><img src="img/homepage/i1.png" /></span>
                      </div>
                    </div>
                  </div>
                  <div className="gc-col col-md-5 mb-3">
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.pemail}</div>

                    <div className="input-group">
                      <input type="text" className="form-control m-0" id="validationTooltip03" name="pemail"  onChange={this.prospect} placeholder="Email Id" aria-describedby="validationTooltip03" required />
                      <div className="valid-tooltip">
                        Looks good!
                      </div>
                      <div className="input-group-prepend">
                      <span style={{borderRight: '1px solid #ebebeb'}} className="input-group-text" id="validationTooltipEmailPrepend"><img src="img/homepage/i3.png" /></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row justify-content-center">
                  <div className=" gc-col col-md-5 mb-3">
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.pphone}</div>

                    <div className="input-group">
                      <input type="text" className="form-control m-0" id="validationTooltipEmailPrepend" name="pphone"  onChange={this.prospect} placeholder="Contact number" aria-describedby="validationTooltipEmailPrepend" required />
                      <div className="invalid-tooltip">
                      Contact number
                      </div>
                      <div className="input-group-prepend">
                        <span style={{borderRight: '1px solid #ebebeb'}} className="input-group-text" id="validationTooltipEmailPrepend"><img src="img/homepage/mob.png" /></span>
                      </div>
                    </div>
                  </div>
                  <div className="gc-col col-md-5 mb-3">
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.condition}</div>

                    <div className="input-group">
                      <input type="text" className="form-control m-0" id="validationTooltip03" onChange={this.prospect} name="condition" placeholder="Condition" aria-describedby="validationTooltip03" required />
                      <div className="valid-tooltip">
                        Looks good!
                      </div>
                      <div className="input-group-prepend">
                        <span style={{borderRight: '1px solid #ebebeb'}} className="input-group-text" id="validationTooltip03"><img src="img/clinicians/refer/1/doc.png" /></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gc-form-card-btm">
         <br/>
                <div className="form-row justify-content-center">
                  
                  <div className="gc-col col-md-5 mb-3">
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.pmessage}</div>

                    <div className="input-group">
                      <textarea className="form-control m-0 " name="pmessage"  onChange={this.prospect} id="validationTextarea" rows={3} placeholder="Message" maxLength="500" required style={{height: '100px'}} />
                    </div>
                  </div>
                </div>{/* end of form-row */}
             
            </div>{/* end of gc-form-card-btm */}
                <h2 className="text-center roboto-black-40px p-5">Your Information</h2>

                <div className="form-row justify-content-center">
                <div className="cr-col col-sm-10 col-md-8 col-lg-6 mb-3">
                <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.referedByClinicianMedicalRegNo}</div>

                    <div className="input-group">
                      <input type="text" className="form-control m-0" onBlur={this.getDoctorsData} name="referedByClinicianMedicalRegNo"  onChange={this.prospect} id="validationTooltipEmailPrepend" placeholder="Medical Registration Number." aria-describedby="validationTooltipEmailPrepend" required />
                      <div className="invalid-tooltip">
                       
                      </div>
                      <div className="input-group-prepend">
                        <span style={{borderRight: '1px solid #ebebeb'}} className="input-group-text" id="validationTooltipEmailPrepend"><img src="img/homepage/i1.png" /></span>
                      </div>
                    </div>
                  </div>
                 
                </div>
                
               
                {this.state.loading?this.loader():this.state.doctorsData.length?this.doctorsDataRender():<div style={{ color: 'red', fontSize: '12px' }}>{this.state.data}</div>}


                <div className="form-row justify-content-center">
                  <div className=" gc-col col-md-5 mb-3">
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.referedByClinicianName}</div>

                    <div className="input-group">
                      <input type="text" className="form-control m-0" name="referedByClinicianName"  onChange={this.prospect} id="validationTooltipEmailPrepend" placeholder="Clinician's Name" aria-describedby="validationTooltipEmailPrepend" required />
                      <div className="invalid-tooltip">
                        Please enter a valid E-mail ID.
                      </div>
                      <div className="input-group-prepend">
                        <span style={{borderRight: '1px solid #ebebeb'}} className="input-group-text" id="validationTooltipEmailPrepend"><img src="img/homepage/i1.png" /></span>
                      </div>
                    </div>
                  </div>
                  <div className="gc-col col-md-5 mb-3">
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.referedByClinicianEmail}</div>

                    <div className="input-group">
                      <input type="text" className="form-control m-0" id="validationTooltip03" name="referedByClinicianEmail"  onChange={this.prospect} placeholder="Email Id" aria-describedby="validationTooltip03" required />
                      <div className="valid-tooltip">
                        Looks good!
                      </div>
                      <div className="input-group-prepend">
                      <span style={{borderRight: '1px solid #ebebeb'}} className="input-group-text" id="validationTooltipEmailPrepend"><img src="img/homepage/i3.png" /></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row justify-content-center">
                  <div className=" gc-col col-md-5 mb-3">
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.referedByClinicianContact}</div>

                    <div className="input-group">
                      <input type="text" className="form-control m-0" id="validationTooltipEmailPrepend" name="referedByClinicianContact"  onChange={this.prospect} placeholder=" Clinician Contact number" aria-describedby="validationTooltipEmailPrepend" required />
                      <div className="invalid-tooltip">
                      Contact number
                      </div>
                      <div className="input-group-prepend">
                        <span style={{borderRight: '1px solid #ebebeb'}} className="input-group-text" id="validationTooltipEmailPrepend"><img src="img/homepage/mob.png" /></span>
                      </div>
                    </div>
                  </div>
                  <div className=" gc-col col-md-5 mb-3">
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.organizationname}</div>
                    <div className="input-group">
                      <input type="text" className="form-control m-0" id="validationTooltip03" name="organizationname" onChange={this.prospect} placeholder="Organization name" aria-describedby="validationTooltip03" required />
                      <div className="valid-tooltip">
                        Looks good!
                      </div>
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="validationTooltip03"><img src="img/clinicians/heart-test/star.png" /></span>
                      </div>
                    </div>
                  </div>

                  </div>


                
               
                <div className="wrapper pt-3 mt-4 mb-5 pb-5">
                  <button className="center-block btn btn-outline-primary btn-hover-white my-2 my-sm-0 center" style={{height: '60px', width: '210px', borderRadius: '30px', color: 'white', background: '#4f5be7'}} name="submit" type="submit"><b>SUBMIT</b></button>
                </div>
              </form></div>
              <div className="form-row justify-content-center mt-5">
              <div className=" gc-col col-md-5 mb-3">
                
              </div>
              <div className="gc-col col-md-5 mb-3 mt-4">
              </div>
            </div>
          </div>
        </div>
        
        </div>

      
        
      );
  }
    }
    


export default withRouter(OpenReferProspect);
