import React from 'react';
import Cookies from 'js-cookie';
import axios from '../axios/axios';
import { withRouter } from 'react-router-dom';

class CommonHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            dropdownOpen: false,
            lastorderHistory:[],
            visible: false,
            desktop:false,
            mobilemenu:false
        }
        this.toggleMenu = this.toggleMenu.bind(this);
        this.toggle = this.toggle.bind(this);
        this.logout = this.logout.bind(this);
        this.resize = this.resize.bind(this);
        this.toggleMobileMenu =this.toggleMobileMenu.bind(this);


    }
    componentDidMount(){
        axios.get('cartservice/'+Cookies.get("userId")+'/orderHistoryForProduct', 
        { headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` } }
        ).then((response) => {
           if(response.data){this.setState({lastorderHistory:response.data},()=>{})}
          }).catch(error => {});

          window.addEventListener("resize", this.resize.bind(this));
          this.resize();
    }
    toggleMenu() {
        this.setState({ menu: !this.state.menu },()=>{})
    }
    toggle() {
        this.setState({ dropdownOpen: !this.state.dropdownOpen },()=>{})

    }

    toSettings(){
        window.location.href="/settings";
    }
    toActivatekit(){
        window.location.href="/activate-kit";
    }
    logout() {
         Cookies.remove('username');
        Cookies.remove('jwt-token');
        this.props.history.push('/');
    }
    resize() {
        const desktop= window.innerWidth>=576;
        this.setState({desktop},()=>{});
     }
     toggleMobileMenu() {
        this.setState({ mobilemenu: !this.state.mobilemenu },()=>{})
      }

    render() {
        let styles;
        let stylemarginTop;
            if(this.props.category==="loginSuccess"){
               if(!this.state.desktop){
                stylemarginTop=   <div style={{paddingBottom: '16rem'}} className="padding">
                </div>;
               }  else{
                stylemarginTop=   <div style={{paddingBottom: '16rem'}} className="padding">
                </div>;
               } 
        }
        if(this.state.mobilemenu){
          styles = {
            sideBar1: {
              width: '250px',
            },
            bgwhit:{
              'margin-left': '250px'
            }
      
      
          };
        }else{
          styles = {
            sideBar1: {
              width: '0px',
            },
            bgwhit:{
              'margin-left': '0px'
            }
      
      
          };
    
        }
        

    
     
        const { sideBar1 } = styles;
        const show = (this.state.menu) ? "show" : "";
        const dropdownOpen =(this.state.dropdownOpen) ? "show" : "";
        return (
            <div>
      
               
            <header id="headbar" className="fixed-top bg-white mainheader">
                    {/* navbar */}
                    <nav className="risk-nav navbar container navbar-expand-lg nav-menu p-0 pb-4">
        {/* <a href="#" class="navbar-brand "> */}
        <a href="/" className="navbar-brand ">
          <img className="ml-4"  src="./img/Logo.png" width={108} height={60} alt="Logo" />
        </a>
        <button className={show?"navbar-toggler nav-button change":"navbar-toggler nav-button collapsed"} type="button" data-toggle="collapse" data-target="#myNavbar" onClick={this.toggleMenu}>
          <div className="line1" />
          <div className="line2" />
          <div className="line3" />
        </button>
        <div className={show?"navbar-collapse justify-content-start text-uppercase font-weight-regular collapse show":"navbar-collapse justify-content-start text-uppercase font-weight-regular collapse"} id="myNavbar" style={{}}>
          <ul className="navbar-nav ">
            <li className="nav-item">
  <a href="/health-history" className={this.props.category === "healthHistory" ? "nav-link   menu-item nav-active" : "nav-link   menu-item" }>Health History</a></li>
            <li className="nav-item">
  <a href="/book-counselling" className={this.props.category === "bookCounselling" ? "nav-link   menu-item nav-active" : "nav-link   menu-item"}>Book counselling appointment</a>            </li>
            <li className="nav-item">
 <a href="/product-page" className={this.props.category === "product-page" ? "nav-link   menu-item nav-active" : "nav-link   menu-item"}>Order trugeny test</a></li>
            <li className="nav-item ">
<a href="/your-reports" className={this.props.category === "yourReports" ? "nav-link   menu-item nav-active" : "nav-link   menu-item"}>Your Reports</a></li>
          </ul>
          <div className="help-btn-wrap">
            <a className="btn btn-outline-primary btn-hover-white rounded-pill font-weight-bold " href="/al_help" role="button">HELP</a>
          </div>
          {/* eo-help-btn-wrap */}
          {/* <div class="login-name-wrap d-flex flex-sm-column justify-content-end ">
            <a class=" font-weight-bold " href="Activate-kit.html" role="">John Doe<i class="fas fa-chevron-down"> </i></a>
          </div> */}
                <div className={dropdownOpen?"login-name-wrap d-flex flex-sm-column dropdown bg-white show":"login-name-wrap d-flex flex-sm-column  dropdown  bg-white"} onClick={this.toggle}>
            <a className=" font-weight-bold pt-0 "  id="navbarDropdownMenuLink-4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{Cookies.get('username')}<i className="fas fa-chevron-down"> </i>
            </a><div className={dropdownOpen?"dropdown-menu dropdown-menu-left dropdown-info mt-0 pt-0 show":"dropdown-menu dropdown-menu-left dropdown-info mt-0 pt-0"} aria-labelledby="navbarDropdownMenuLink-4"><a className=" font-weight-bold pt-0 "  id="navbarDropdownMenuLink-4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              </a>
              <a className="dropdown-item pl-4 py-0" href="/settings" >Settings</a>
              <hr className="my-2 py-0"/>
              {this.state.lastorderHistory.orderId?
              <div><a className="dropdown-item pl-4 py-0" href="/activate-kit" >Activate Kit</a>  <hr className="my-2 py-0"/></div>:""}
              {/* <a class="dropdown-item pl-4" href="#">Profile</a> */}
              <a className="dropdown-item pl-4 py-0" href="/logout" >Log out</a>
            </div></div>
            
            
            </div>
      </nav>
                    {/* end of navbar */}
                </header>
                <div id="main" className="bg-white mobileheader">
                <div id="mySidenav" className="sidebar1" style={sideBar1}>
                  <div className="container">
                    <div className="row d-flex justify-content-inline">
                      <div className="col-4 pr-0">
                        <span><img className="img-fluid p-1" src="https://www.vovia.com/assets/icon-user2.png" alt="userImage"/></span>
                      </div>
                      <div className="col-5 pl-0 pt-4"><span> {Cookies.get("username")}</span></div>
                      <div className="col-3 d-flex justify-content-end pl-0 pr-0 pt-5"><a  id="jhon" className="closebtn m-0"   onClick={this.toggleMobileMenu}>×</a></div>
                    </div>
                  </div>
                  <hr />
                  <span><a href="/your-reports" className={this.props.category === "yourReports" ? "nav-link   menu-item nav-active" : "nav-link   menu-item"}>Your Report</a></span><hr />
                  <span><a href="/health-history" className={this.props.category === "healthHistory" ? "nav-link   menu-item nav-active" : "nav-link   menu-item" }>Health History</a></span><hr />
                  <span><a href="/book-counselling" className={this.props.category === "bookCounselling" ? "nav-link   menu-item nav-active" : "nav-link   menu-item"}>Book Counseling Appointment</a></span><hr />
                  <span><a href="/product-page" className={this.props.category === "product-page" ? "nav-link   menu-item nav-active" : "nav-link   menu-item"}>Order truGeny Kit</a></span><hr />
                  <span><a href="/al_help" className="nav-link   menu-item">Help</a></span><hr />

                  <span><a href="/settings" className={this.props.category === "settings" ? "nav-link   menu-item nav-active" : "nav-link   menu-item"}>Setting</a></span>
                  
                  {this.state.lastorderHistory.orderId?<span> <a href="/activate-kit" className={this.props.category === "activate-kit" ? "nav-link   menu-item nav-active" : "nav-link   menu-item"}>Activate-kit</a></span>:""}
                  
                  <span><a href="/logout" >Log-Out</a></span>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="d-flex  justify-content-start">
                     <a  href="/"> <img  src="./img/Logo.png" style={{height: '38px', width: '60px'}} /></a>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex  justify-content-end">
                      <span style={{fontSize: '30px', cursor: 'pointer',color: '#4f5be7'}} onClick={this.toggleMobileMenu}>☰ </span>
                    </div>
                  </div>
                </div>
              </div>
                
          
            </div>
        );
    }
}
export default withRouter(CommonHeader);
