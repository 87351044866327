import React from 'react';
import MainHeader from './MainHeader';
import Footer from './Footer';
import CliniciansProspect from './CliniciansProspect';

import MetaTags from 'react-meta-tags';
import StickyContactMenu from './StickyContactMenu';


class CliniciansCancerTest extends React.Component {
  constructor(props) {
    super(props);
 
      this.state={
        genesview:false
      }

  this.toggle = this.toggle.bind(this);

}

validationsForHealthHistorySession(){
  window.scrollTo(5000,5000)

}

  componentDidMount() {
    window.scrollTo(0, 0)
  }
  toggle() {
    this.setState(prevState => ({
        genesview: !prevState.genesview
    }));
}

  toLogin() {
        window.location.href="/clinicians_login";
    }

    
    toGeneticHelp() {

       window.location.href="/clinicians_genetic-help";
      }
  render() {
    return (
      

        <div>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
        {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.2/css/all.css" integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay" crossorigin="anonymous"> */}
        <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>truGeny</title>
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/header_footer.css" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <link rel="stylesheet" href="./css/sticky.css" />
        <style dangerouslySetInnerHTML={{__html: "\n/* @media only screen and (min-width: 1200px)\n{\n/* .custom-pad-mar {\n  padding-left:25rem;\n  padding-right:25rem;\n\n} */\n\n.demo {\n\t\twidth:100%;\n\t\tborder:1px solid #C0C0C0;\n    border-radius: 2rem;\n\t\tborder-collapse:collapse;\n\t\tpadding:5px;\n\n\t}\n\t.demo th {\n\t\tborder:1px solid #C0C0C0;\n\t\tpadding:5px;\n\t\tbackground:#fff;\n\t}\n  .demo tr:nth-child(odd)\n   {\n     background-color: #dcf4f4;\n   }\n\t.demo td {\n\t\tborder:1px solid #C0C0C0;\n\t\tpadding:5px;\n    /* background:#fff; */\n\n\t}\n\n  .genetics-banner-sec-wrap .genetics-banner-sec{\n    background: url(./img/clinicians/cancer-test/cancer-test-bg.png) no-repeat center;-webkit-background-size: cover;\n    background-position:center;\n    background-size: cover;\n    background-repeat: no-repeat;\n  }\n\n  .gc-help-main-sec{\n    background: url(./img/clinicians/cancer-test/bg2.png) no-repeat center;-webkit-background-size: cover;\n    -moz-background-size: cover;\n    -o-background-size: cover;\n    background-size: cover;\n    background-position: top;\n  }\n\n\n\n\n" }} />
        <link rel="stylesheet" href="./clinicians_style-mobile2.css"/>
        <link rel="stylesheet" href="./clinicians_style-mobile.css"/>
        <link rel="stylesheet" href="./clinicians_newsticky.css"/>
        <MetaTags>
        <title>Patients Care: Genetic test for Cancer Patients</title>
		<meta name="description" content="truGeny’s genetic tests for hereditary cancer risk detection help you assess your patients’ risks for hereditary cancers early and give the best care."/>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta property="og:title" content="Patients Care: Genetic test for Cancer Patients"/>
        <meta property="og:site_name" content="truGeny"/>
        <meta property="og:url" content="https://trugeny.com/"/>
        <meta property="og:description" content="truGeny’s genetic tests for hereditary cancer risk detection help you assess your patients’ risks for hereditary cancers early and give the best care. "/>
        <meta property="og:type" content="website"/>
        <meta property="og:image" content="https://trugeny.com/"/>
</MetaTags>
        
        <MainHeader  category="tests"></MainHeader>
        <div style={{marginTop:'12rem'}} className="margin"></div>

        <div className="container py-0 mt-0 d-none">
          <nav className="navbar container navbar-expand-xl  navbar-light d-none d-lg-block d-xl-block float-left py-0">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon">
              </span>
            </button>
            <ul className=" row navbar-nav mr-auto ">
              <li className="nav-item ">
                <a className="nav-link float-right " href="clinicians.html">Clinicians</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="clinicians-genetic-counseling.html">Genetic Counselling </a>
              </li>
              <li className="nav-item">
                {/* <a class="nav-link" href="clinicians-medical-spec.html">Medical Specialities</a> */}
              </li>
              <li className="nav-item ">
                <a className="nav-link" style={{color: '#4f5be7'}} href="clinicians-test-overview.html"><b>Test</b></a>
              </li>
              <li className="nav-item ">
                <a className="nav-link" href="how-does-it-works.html">How does it work?</a>
              </li>
              <li className="nav-item ">
                {/* <a class="nav-link"  style="color:#4f5be7" href="clinicians-cancer-test.html"><b>Cancer Test</b></a> */}
              </li>
              <li className="nav-item ">
                {/* <a class="nav-link"  href="clinicians-heart-test.html">Heart Test</a> */}
              </li>
            </ul>
          </nav>
          <br />
          <br />
        </div>{/* eo-container */}
        <section className="genetic-Counseling-main-sec genetics-banner-sec-wrap" style={{width: '100%'}}>
          <div className=" genetics-banner-sec ">
            <div className="container pt-4 mt-2">
              <h1 className="">truGeny’s Hereditary<br /> Cancer Test</h1>
              <p className="text-justify mt-3 mb-4 pb-0">Assess risks for hereditary cancers early and make a difference to your patients care.</p>
              <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0 text-uppercase mx-auto px-auto" style={{width: '12.5rem', fontSize: '14px', color: 'white', backgroundColor: '#4f5be7'}}><b>Order truGeny Now</b></button>
            </div>{/* eo-container*/}
            <div id="secc1" className="d-flex justify-content-center">
              <a style={{scrollBehavior: '[ auto | smooth ]'}} href="#secc1">  <img className="img-fluid d-none d-md-block d-lg-block btn-hover-white1" src="./img/111.png" /></a>
            </div>
          </div>{/* eo-heart-banner-sec */}
        </section>{/* eo-heart-banner-sec-wrap */}
        <StickyContactMenu></StickyContactMenu>
        {/* ------------------------------------------------------heart-banner-sec over------------------------------------------------------------------ */}
        <section className="genetics-informed-sec-main heart-disease-sec-main heart-disease-sec-main p-0 m-0">
          <div className="heart-disease-sec-main-wrap container">
            <div className="container d-flex justify-content-center paddingClini1" >
              {/* <h2 class=" text-center genetics-h2 ">Make the most informed informed healthcare decisions.
      </h2> */}
              <p className="roboto-bold-24px text-center" style={{maxWidth: '34rem'}}>Over 8.8 lakh deaths will be due to cancer by 2020. Cancers of Breast, Lung and Cervix will top the list</p>
            </div>{/* eo-intro-text-wrap*/}
            <div className="container d-flex justify-content-center" style={{paddingTop: '1rem'}}>
              <p style={{maxWidth: '21rem'}}>National Cancer Registry Program - ICMR</p>
            </div>
            <div className="container d-flex justify-content-center" style={{paddingTop: '1rem'}}>
              <img className="img-fluid mx-auto" src="./img/clinicians/cancer-test/Logo.png" style={{maxWidth: '13rem'}} />
            </div>
            <div className="paddingClini">
            </div>
            <div className="container d-flex justify-content-center" style={{paddingTop: '1rem'}}>
              <h2 className="roboto-black-40px text-center" style={{maxWidth: '62rem'}}>5% to 7% of your patients are likely to carry a high-risk
                genetic variant that they are unaware of.</h2>
            </div>
            <div style={{paddingBottom: '5rem'}}>
            </div>
            <div className="container">
              <div className="card-deck">
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-4">
                  <div className="card rounded shadow-lg   r4  mb-5">
                    <div className="card-body text-center ">
                      <div className="card-block   pr-5 pt-2 pl-5 pb-2 circle">
                        <img className="card-img img-fluid text-center p-3 " src="./img/clinicians/cancer-test/2-layers.png" alt="Card image cap" />
                      </div>
                      <h3 className="roboto-black-18px">Assess</h3>
                      <p className="text-center">
                        Determine which of your patients are at an increased risk for a genetic condition and need to get genetic testing done.
                        <br />
                        <br />
                        We provide the necessary genetic counselling services by video or phone.
                      </p>
                    </div>{/* eo-card-body */}
                  </div>{/* eo-card */}
                </div>{/* eo-card-deck-wrap */}
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-4">
                  <div className="card rounded shadow-lg r4 mb-5">
                    <div className="card-body text-center">
                      <div className="card-block pr-5 pt-2 pl-5 pb-2 circle">
                        <img className="card-img text-center p-3" src="./img/clinicians/cancer-test/6-layers.png" alt="Card image cap" />
                      </div>
                      <h3 className="roboto-black-18px">Select</h3>
                      <p className="card-text" /><p>Choose which is the most appropriate genetic test for your patient.
                        <br /><br />
                        We can take care of all the steps from ordering the tests to report interpretation and counselling.</p><p />
                    </div>
                  </div>
                </div>{/* eo-card-deck-wrap */}
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-4">
                  <div className="card rounded shadow-lg   r4 mb-5">
                    <div className="card-body text-center">
                      <div className="card-block  pr-5 pt-2 pl-5 pb-2 circle ">
                        <img className="card-img text-center  p-3" src="./img/clinicians/cancer-test/2-layers3.png" alt="Card image cap" />
                      </div>
                      <h3 className="roboto-black-18px">Integrate</h3>
                      <p className="card-text" /><p>truGeny’s test report provides you with clinically actionable insights that you can integrate into your patient’s care.<br /><br />We provide you with access to our certified genetic counsellors.</p><p />
                    </div>
                  </div>
                </div>{/* eo-card-deck-wrap */}
              </div>{/* eo-card-deck */}
            </div>
            <div className="paddingClini2">
            </div>
            <div className=" heart-disease-sec row container sec6">
              <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 d-block text-center  ">
                <img className="img-fluid mx-auto p-lg-5 p-sm-2" src="./img/clinicians/cancer-test/Illustration1.png" />
              </div>{/* eo-hd-img */}
              <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center">
                <h3>The Genetic Mutations impacting occurrence of Hereditary Cancers</h3>
                {/* <p class="heartdisease-content">Most diseases have a genetic cause.</p> */}
                <p className="roboto-reg-18px">Numerous cancer-susceptibility genes have been identified<br /><br />Many of those genes are associated with multiple cancer types<br /><br />Conversely, a single cancer type can be linked to mutations in several genes</p>
                {/* <p class="heartdisease-content">Our genetic counsellors can help evaluate if you’re at risk, and if genetic testing may be right for you.</p> */}
              </div>{/* eo-hd-text */}
            </div>{/* eo-row */}
          </div>{/* eo-heart-disease-sec-main-wrap*/}
        </section>{/* eo-heart-disease-sec-main */}
        {/* Start of Genetic Counsell Help cards  */}
        <div className="gc-help-main-sec">
          <div className="container gc-help-main-wrap pr-0 pl-0">
            <h2 className="text-center">How does it work?</h2>
            <p className="roboto-reg-24px text-center pt-2">truGeny combines the efficiency of its telegenetics platform with clinical grade tests<br />to enable you deliver patient care that is unparalleled.</p>
            <div className="container gc-help-card-main-wrap mx-auto mt-5  mb-5">
              <div className="row text-center ">
                <div className="card-deck-wrap col-lg-1 col-sm-12 col-md-12 p-0">
                </div>
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-2 p-1">
                  <div className="card rounded shadow-lg  mb-4 r4  cardH">
                    <div className="card-body text-center ">
                      <div className="card-block mt-2 p-2 circle">
                        <img className="card-img img-fluid text-center  " src="./img/clinicians/cancer-test/card1.png" style={{height: '100px', width: '100px'}} alt="Card image cap" />
                      </div>
                      <p className="roboto-black-18px">Schedule an Appointment</p>
                      <p className="roboto-reg-16px">It's fast and easy for your patients to schedule a convenient appointment time online.</p>
                    </div>{/* eo-card-body */}
                  </div>{/* eo-card */}
                </div>{/* eo-card-deck-wrap */}
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-2 p-1">
                  <div className="card rounded shadow-lg  mb-4 r4  cardH">
                    <div className="card-body text-center ">
                      <div className="card-block mt-2 p-2 circle">
                        <img className="card-img img-fluid text-center  " src="./img/clinicians/cancer-test/card2.png" style={{height: '100px', width: '100px'}} alt="Card image cap" />
                      </div>
                      <p className="roboto-black-18px">Initial Consultation</p>
                      <p className="roboto-reg-16px">Our experts, genetic counsellors will review your patients history and answer their questions.</p>
                    </div>{/* eo-card-body */}
                  </div>{/* eo-card */}
                </div>{/* eo-card-deck-wrap */}
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-2 p-1">
                  <div className="card rounded shadow-lg  mb-4 r4  cardH">
                    <div className="card-body text-center ">
                      <div className="card-block mt-2 p-2 circle">
                        <img className="card-img img-fluid text-center  " src="./img/clinicians/cancer-test/card3.png" style={{height: '100px', width: '100px'}} alt="Card image cap" />
                      </div>
                      <p className="roboto-black-18px">Sample Collection</p>
                      <p className="roboto-reg-16px">If a patient requires genetic testing, we will ship the sample collection kit to the patients home.</p>
                    </div>{/* eo-card-body */}
                  </div>{/* eo-card */}
                </div>{/* eo-card-deck-wrap */}
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-2 p-1">
                  <div className="card rounded shadow-lg  mb-4 r4  cardH">
                    <div className="card-body text-center ">
                      <div className="card-block mt-2 p-2 circle">
                        <img className="card-img img-fluid text-center  " src="./img/clinicians/cancer-test/card4.png" style={{height: '100px', width: '100px'}} alt="Card image cap" />
                      </div>
                      <p className="roboto-black-18px">Results Consultation</p>
                      <p className="roboto-reg-16px">Our genetic counsellor will review the test results, walk your patient through the report explain it’s implications and answer their questions.</p>
                    </div>{/* eo-card-body */}
                  </div>{/* eo-card */}
                </div>{/* eo-card-deck-wrap */}
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-2 p-1">
                  <div className="card rounded shadow-lg  mb-4 r4  cardH">
                    <div className="card-body text-center ">
                      <div className="card-block mt-2 p-2 circle">
                        <img className="card-img img-fluid text-center  " src="./img/clinicians/cancer-test/card5.png" style={{height: '100px', width: '100px'}} alt="Card image cap" />
                      </div>
                      <p className="roboto-black-18px">Expert Follow Up</p>
                      <p className="roboto-reg-16px">You will receive a summary of the patient’s counselling session and you can integrate the report insights into your patient’s healthcare plan.</p>
                    </div>{/* eo-card-body */}
                  </div>{/* eo-card */}
                </div>{/* eo-card-deck-wrap */}
              </div>
              <div className="card-deck-wrap col-lg-1 col-sm-12 col-md-12 p-0">
              </div>
            </div>{/* eo-card-deck */}
          </div>{/*eo-container */}
          {/* <div class="gc-help-button-wrap text-center">
     <a class="gc-help-button btn btn-outline-primary btn-hover-white rounded-pill text-center" href="#" role="button">Schedule Your Appointment</a>
   </div> */}
        </div>{/* gc-help-main-wrap */}
        {/* gc-help-main-sec */}
        {/* end of Genetic Counsell Help cards  */}
        {/* Genes can influence section starts here */}
        <section className="genetics-informed-sec-main heart-disease-sec-main heart-disease-sec-main">
          <div className="heart-disease-sec-main-wrap container">
            <div className="families-sec heart-disease-sec row container">
              <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 text-center order-md-2">
                <img className="img-fluid mx-auto" src="./img/clinicians/cancer-test/Illustration2.png" />
              </div>{/* eo-hd-img */}
              <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center order-md-1">
                <h3>Genes covered?</h3>
                <p className="heartdisease-content">NGS based test panels analyse multiple genes simultaneously. Broad Multi-Gene NGS panels for evaluation of Hereditary Cancer predisposition provides coverage of 90 genes.</p>
                <br/>
                <button type="button" className="btn btn-outline-primary mt-2 btn-hover-white rounded-pill" onClick={this.toggle}>View Gene List&nbsp;&nbsp;<i className="fas fa-long-arrow-alt-right" />
                </button>
              </div>{/* eo-hd-text */}
            </div>{/* eo-row */}
          </div>{/* eo-heart-disease-sec-main-wrap*/}
        </section>{/* eo-heart-disease-sec-main */}
        {/* ******************Our Process-sec************ */}
        <section id="for-cancer" className="our-process-sec" style={this.state.genesview?{}:{display:'none'}}>
          <div className="container our-process-sec-header ">
            <h2 className="text-center roboto-black-36px" style={{color: '#4f5be7'}}>For cancers</h2>
          </div>{/* eo what-you-sec-wrap */}
          <div className="container" style={{overflowX: 'auto'}}>
            <table className="demo  ">
              <thead>
                <tr>
                  <th><p className="p-0 m-0 roboto-black-18px" style={{color: '#2c333c'}}>Genes</p></th>
                  <th><p className="p-0 m-0 roboto-black-18px" style={{color: '#de7f52'}}>Breast</p></th>
                  <th><p className="p-0 m-0 roboto-black-18px" style={{color: '#60c0cb'}}>Ovarian</p></th>
                  <th><p className="p-0 m-0 roboto-black-18px" style={{color: '#4f5be7'}}>Uterine</p></th>
                  <th><p className="p-0 m-0 roboto-black-18px" style={{color: '#eab624'}}>Colorectal</p></th>
                  <th><p className="p-0 m-0 roboto-black-18px" style={{color: '#bdacdf'}}>Melanoma</p></th>
                  <th><p className="p-0 m-0 roboto-black-18px" style={{color: '#30c2fd'}}>Pancreatic</p></th>
                  <th><p className="p-0 m-0 roboto-black-18px" style={{color: '#75b720'}}>Stomach</p></th>
                  <th><p className="p-0 m-0 roboto-black-18px" style={{color: '#f06eaa'}}>Prestate*</p></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>BRCA1</td>
                  <td><i style={{color: '#de7f52'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#60c0cb'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#30c2fd'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#75b720'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&amp;BRCA2</td>
                  <td />
                  <td><i style={{color: '#60c0cb'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#30c2fd'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#75b720'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#f06eaa'}} className="fas fa-circle" /></td>
                </tr>
                <tr>
                  <td>MLH1</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#60c0cb'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#eab624'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#bdacdf'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#30c2fd'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#75b720'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#f06eaa'}} className="fas fa-circle" /></td>
                </tr>
                <tr>
                  <td>MSH2</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#60c0cb'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#4f5be7'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#eab624'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#75b720'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#f06eaa'}} className="fas fa-circle" /></td>
                </tr>
                <tr>
                  <td>MSH6</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#60c0cb'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#4f5be7'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#eab624'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#30c2fd'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#75b720'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#f06eaa'}} className="fas fa-circle" /></td>
                </tr>
                <tr>
                  <td>PMS2***</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#60c0cb'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#4f5be7'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#eab624'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#75b720'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#f06eaa'}} className="fas fa-circle" /></td>
                </tr>
                <tr>
                  <td>STK11</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#eab624'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#bdacdf'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#30c2fd'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#f06eaa'}} className="fas fa-circle" /></td>
                </tr>
                <tr>
                  <td>EPCAM**</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#bdacdf'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#30c2fd'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>APC</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#bdacdf'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>MUTYH</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#eab624'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#bdacdf'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#75b720'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#f06eaa'}} className="fas fa-circle" /></td>
                </tr>
                <tr>
                  <td>CDH1</td>
                  <td><i style={{color: '#de7f52'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#bdacdf'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#f06eaa'}} className="fas fa-circle" /></td>
                </tr>
                <tr>
                  <td>MITF**</td>
                  <td><i style={{color: '#de7f52'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#75b720'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>BMPR1A</td>
                  <td><i style={{color: '#de7f52'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#4f5be7'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#eab624'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#30c2fd'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>BAP1</td>
                  <td><i style={{color: '#de7f52'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#4f5be7'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#eab624'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#30c2fd'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#75b720'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>SMAD4</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#4f5be7'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#eab624'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#30c2fd'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>CDKN2A</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>GREM1**</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#eab624'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#75b720'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>CDK4**</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#eab624'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#30c2fd'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>PTEN</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#eab624'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#30c2fd'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#75b720'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>TP53</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#eab624'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#75b720'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>POLD1**</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#60c0cb'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#30c2fd'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>POLE**</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#60c0cb'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#eab624'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#75b720'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>PALB2</td>
                  <td><i style={{color: '#de7f52'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#60c0cb'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#eab624'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#f06eaa'}} className="fas fa-circle" /></td>
                </tr>
                <tr>
                  <td>NBN</td>
                  <td><i style={{color: '#de7f52'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#60c0cb'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>CHEK2</td>
                  <td><i style={{color: '#de7f52'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#60c0cb'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#f06eaa'}} className="fas fa-circle" /></td>
                </tr>
                <tr>
                  <td>ATM</td>
                  <td><i style={{color: '#de7f52'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#60c0cb'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#30c2fd'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#f06eaa'}} className="fas fa-circle" /></td>
                </tr>
                <tr>
                  <td>BRIP1</td>
                  <td><i style={{color: '#de7f52'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#60c0cb'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#30c2fd'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#f06eaa'}} className="fas fa-circle" /></td>
                </tr>
                <tr>
                  <td>BARD1</td>
                  <td><i style={{color: '#de7f52'}} className="fas fa-circle" /></td>
                  <td><i style={{color: '#60c0cb'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>RAD51C</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#60c0cb'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td><i style={{color: '#30c2fd'}} className="fas fa-circle" /></td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                {/* <tr>
<td>&nbsp;</td>
<td>&nbsp;</td>
<td>&nbsp;</td>
<td>&nbsp;</td>
<td>&nbsp;</td>
<td>&nbsp;</td>
<td>&nbsp;</td>
<td>&nbsp;</td>
<td>&nbsp;</td>
</tr> */}
              </tbody><tbody>
              </tbody></table>
          </div>
          {/* <div className="container gc-help-button-wrap text-center pt-5">
            <a className="gc-help-button btn btn-outline-primary btn-hover-white rounded-pill text-center" role="button" style={{backgroundColor: '#4f5be7', color: 'white'}}>Download sample report</a>
          </div>*/}
          {/* eo-row */}
          {/* gr-card-wrap */}
          {/* eo gr-sec-wrap */}
        </section>{/*eo gr-sec*/}
        {/* end of genetic-cause */}
        <section className="genetic-cause-sec-main ">
          <div className="genetic-cause-sec-wrap">
            <div className="genetic-cause-header-wrap">
              <h2 className="genetic-cause-header genetics-h2 pt-0 text-center">
                Reports that are ACTIONABLE
              </h2>
              <p className="roboto-reg-18px text-center">truGeny’s clinical grade reports are clear and easy to use. The reports provide recommendations for managing patients based on well-recognized guidelines for genetic and familial risks.</p>
              <p className="roboto-reg-18px text-center">Screening guidelines created by nationally recognized boards, such as the ICMR, will help you and your patient create a healthcare plan that’s right for them.</p>
            </div>{/* eo gr-header */}
            <div className="questions-btn text-center mx-auto">
              <p className="">Learn How to Partner With Us</p>
              <a className="btn btn-outline-primary btn-hover-white rounded-pill text-center " style={{backgroundColor: '#4f5be7', color: 'white'}} onClick={()=>this.validationsForHealthHistorySession()} role="button">Learn How to Partner With Us
              </a>
            </div>
          </div>{/* genetic-cause-sec-wrap */}
        </section>
        {/* ******************End of Genetic Session section************ */}
        {/* ******************are you ready section************ */}
        <section className="ayr-sec-main heart-disease-sec-main">
          <div className="ayr-sec-main-wrap heart-disease-sec-main-wrap container">
            <div className="intro-text-wrap text-center">
              <h2 className="text-center d-none">Are you Ready?</h2>
            </div>{/* eo-intro-text-wrap*/}
          </div>{/* eo-heart-disease-sec-main-wrap*/}
        </section>{/* eo-heart-disease-sec-main */}
        {/* ******************end of are you ready section************ */}
        {/* **********Schedule your Genetic Counseling Session Starts Here************** */}
        <CliniciansProspect></CliniciansProspect>
        {/* **********Schedule your Genetic Counseling Session Ends Here************** */}
        {/* Footer Links */}
        <Footer></Footer>
        {/* -------------------------------------start q and a------------------------------ */}
        <div className="modal fade bd-example-modal-lg d-none" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content" style={{borderRadius: '40px'}}>
              <div className="modal-header bg-blue clearfix border-0" style={{borderTopRightRadius: '40px', borderTopLeftRadius: '40px'}}>
                <h5 className="modal-title" id="exampleModalLongTitle" style={{paddingTop: '5px', paddingBottom: '25px', color: '#2c333c', fontSize: '30px', fontWeight: 700}}>
                  Start your journey in 3 easy steps</h5>
                <button type="button" className="text-center close" data-dismiss="modal" aria-label="Close" style={{paddingTop: '25px', paddingBottom: '25px'}}>
                  <img className="img-fluid" src=".\img\registration\X.png" />
                </button>
                {/* <p class="float-left">Question 1 of 3</p> */}
              </div>
              <div className="bg-blue border-bottom-1">
                <p className="pl-3">Question 1 of 3</p>
              </div>
              <div className="modal-body pb-5 pt-0 m-0">
                <div className="container ">
                  <br />
                  <div className="tab-content">
                    <div id="home" className="container tab-pane active"><br />
                      <h3>How do you indentify your gender?</h3>          <hr />
                      <div className="d-flex d-flex-inline">
                        <img className="mr-3" src="./img/q&a/female.png" alt="" />
                        <img className="mr-3" src="./img/q&a/female.png" alt="" />
                      </div>
                      <div className="float-right pt-2 mt-2">
                        <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill" href="#menu1">Next</button>
                      </div>
                    </div>
                    <div id="menu1" className=" m-0 p-0 container tab-pane fade"><br />
                      <h3>Does anyone in your family have 2 diabetes?</h3><hr />
                      <h4>Certain ethnic groups have higher risk for diabetes,<br />
                        even at lower body weights.</h4>
                      <div className="col-xs-12 mt-2 ">
                        <div className="input-group-prepend " style={{border: 'solid 1px #ebebeb', maxWidth: '312px'}}>
                          <input type="text" placeholder="Select your ethnicity" className="form-control roboto-reg-16px" style={{border: 'none', height: '60px'}} aria-label="Text input with dropdown button" />
                          <button className="m-0 p-0 btn btn-outline-secondary roboto-bold-14px " href="#" style={{border: 'none', backgroundColor: 'white'}} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img className="m-0 p-0" src="img/q&a/carot.png" /></button>
                        </div>
                      </div>
                      <div className="float-right pt-2 mt-2">
                        <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill" href="#menu2">Next</button>
                      </div>
                    </div>
                    <div id="menu2" className=" m-0 p-0 container tab-pane fade"><br />
                      <h3>Does anyone in your family have 2 diabetes?</h3>
                      <hr />
                      <div className="radio">
                        <label><input type="radio" name="optradio" defaultChecked />  Parents</label>
                      </div>
                      <div className="radio">
                        <label><input type="radio" name="optradio" />  Siblings</label>
                      </div>
                      <div className="float-right pt-2 mt-2">
                        <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill" href="#">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* sticky form---------------- */}
              {/* <div class="modal-footer">
                  <button type="button" class="btn btn-secondary"  data-toggle="pill" href="#menu2">Close</button>
                  <button type="button" class="btn btn-primary"  data-toggle="pill" href="#menu1">Next</button>
                </div> */}
            </div>
          </div>
        </div>
        {/* sticky form---------------- */}
       
        {/* --------eo sticky form----------------- */}
        {/* ----------------------------------------------------eo Q and A--------------------------------- */}
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>
    );
  }
}
export default CliniciansCancerTest;
