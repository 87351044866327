import React, { Component } from "react";
import axios from "../axios/axios";
import {Link,Redirect}  from 'react-router-dom';
import Currency from "react-currency-formatter";


import Carousel from "react-bootstrap/Carousel";
class ProductIndexCarousel extends Component {
  constructor(props) {
    super(props);
    this.state={
        data:[],
       
        product1:"",
        product2:"",
        product3:"",
        productprice1:0,
        productprice2:0,
        productprice3:0,
        
      }
    this.toOrderHeart = this.toOrderHeart.bind(this);
  }
  componentDidMount(){
    this.getAllProducts();
  }

  getAllProducts(){
    axios
    .get("productservice/productDetails")
    .then(
      response => {
    this.setState({
      product1:response.data[0].productName,
      productprice1:response.data[0].unitPrice,
      product2:response.data[1].productName,
      productprice2:response.data[1].unitPrice,
      product3:response.data[2].productName,
      productprice3:response.data[2].unitPrice,
    });
      },
      () => {}
    ).catch(function(error) {});
  }

  toOrderHeart() {
    // browserHistory.push("/ordertrugene");
    window.location.href = "/ordertrugene";
  }

  render() {
      
    const productUrl = "/order-now-index";
    return (
        
      <div
        id="carouselExampleControls"
        className="carousel slide mt-4"
        data-ride="false"
      ><style dangerouslySetInnerHTML={{__html: "\n\n.mb-4, .my-4 {\n    margin-bottom: 0.5rem!important;\n}\n" }} />
        <div className="carousel-inner new">
          <Carousel controls={true} indicators={false}  interval={false}>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className=" col-sm-12 col-md-12 col-lg-4 mb-md-5 mb-sm-2 py-md-5 py-sm-2">
                  <div
                    className="card rounded newcard"
                    style={{ height: "375px" }}
                  >
                    <div className="card-body  text-center">
                      <div className="card-block circle">
                        <img
                          className="card-img-top text-center  "
                          src="./img/payment/cancer.png"
                          style={{ height: "90px", width: "90px" }}
                          alt="Card image cap"
                        />
                                            <h3 className="text-highlight pt-4 " style={{fontWeight: 900}}><strong>truFind</strong><br/><span className="textspancard" >Hereditary Cancer Test</span></h3>
                                            <h1 className="text-center mb-0 py-0 roboto-bold-24px TruGeneColor">
                         <Currency
                      currency="INR"
                      quantity={this.state.productprice3}
                    />/-
                        </h1>
                       
                      </div>
                      <p>
                        A detailed analysis of over 90 genes your genes to help
                        you learn your risks for developing cancers of the
                        breast, ovarian, uterine, colorectal, prostrate,
                        pancreatic and stomach cancers.
                      </p>
                      {/* <h3 class="font-card">Understand your genes and evaluate your risks for cancer and cardiac conditions.</h3> */}
                      {/* <a href="view-reports.html">   <img class="card-img-top text-center p-2 mb-1 button-view-report"  src="./img/payment/view-report-button.png"/></a> */}
                      <Link
                        to={{
                          pathname: productUrl,
                          state: { orderedProduct: this.state.product3 }
                        }}
                      >
                        {" "}
                        <button
                          style={{ height: "40px", weight: "160px" }}
                          className="mx-auto px-3  rounded-pill btn btn-outline-primary btn-hover-white mb-4"
                        >
                          <b>ORDER NOW</b> &nbsp;
                          <i className="fas fa-long-arrow-alt-right" />
                          &nbsp;
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className=" col-sm-12 col-md-12 col-lg-4 mb-md-5 mb-sm-2 py-md-5 py-sm-2">
                  <div
                    className="card  rounded newcard "
                    style={{ height: "375px" }}
                  >
                    <div className="card-body text-center">
                      <div className="card-block circle ">
                        <img
                          className="card-img-top text-center "
                          src="./img/career/familyplanning.png"
                          style={{ height: "90px", width: "90px" }}
                          alt="Card image cap"
                        />
                                             <h3 className="text-highlight pt-4 " style={{fontWeight: 900}}><strong>{this.state.product2}</strong><br/><span className="textspancard" >Carrier Screening Test</span></h3>          
                                             <h1 className="text-center mb-0 py-0 roboto-bold-24px TruGeneColor">
                           <Currency
                      currency="INR"
                      quantity={this.state.productprice2}
                    />/-
                        </h1>
                                                  </div>
                      <p className="mb-4">
                      Planning for your future family’s health matters. Knowledge about your risk Of passing on a genetic condition to your Child can make a difference to the well being of your future children.
                      </p>{" "}
                      {/* <h3 class="font-card">Activating your kit will only take a few minutes. You will need the barcode found on your saliva tube.</h3> */}
                      {/* <a href="view-reports.html">  <img class="card-img-top text-center mb-1 p-2" onclick="view-reports.html" src="./img/payment/view-report-button.png"/></a> */}
                      <Link
                        to={{
                          pathname: productUrl,
                          state: { orderedProduct: this.state.product2 }
                        }}
                      >
                        {" "}
                        <button
                          style={{ height: "40px", weight: "160px" }}
                          className="mx-auto px-3 rounded-pill btn btn-outline-primary btn-hover-white mb-3"
                        >
                          <b>ORDER NOW</b> &nbsp;
                          <i className="fas fa-long-arrow-alt-right" />
                          &nbsp;
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* eo-card-deck-wrap */}
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className=" col-sm-12 col-md-12 col-lg-4 mb-md-5 mb-sm-2 py-md-5 py-sm-2">
                  <div
                    className="card  rounded newcard "
                    style={{ height: "375px" }}
                  >
                    <div className="card-body text-center">
                      <div className="card-block circle ">
                        <img
                          className="card-img-top text-center "
                          src="./img/payment/heart.png"
                          style={{ height: "90px", width: "90px" }}
                          alt="Card image cap"
                        />
                                            <h3 className="text-highlight pt-4 " style={{fontWeight: 900}}><strong>{this.state.product1}</strong><br/><span className="textspancard" >Coronary Artery Disease Test</span></h3>   
                                            <h1 className="text-center mb-0 py-0 roboto-bold-24px TruGeneColor">
                          <Currency
                      currency="INR"
                      quantity={this.state.productprice1}
                    />/-
                        </h1>
                      </div>
                      <p className="mb-4">
                        A next generation Polygenic Risk Score (PRS) based
                        genetic test to you help you evaluate your risks for
                        developing heart conditions such as familial
                        Hypercholestrolemia, cardiomyopathy, Arrythmia.
                      </p>{" "}
                      {/* <h3 class="font-card">Activating your kit will only take a few minutes. You will need the barcode found on your saliva tube.</h3> */}
                      {/* <a href="view-reports.html">  <img class="card-img-top text-center mb-1 p-2" onclick="view-reports.html" src="./img/payment/view-report-button.png"/></a> */}
                      <Link
                        to={{
                          pathname: productUrl,
                          state: { orderedProduct: this.state.product1 }
                        }}
                      >
                        <button
                          style={{ height: "40px", weight: "160px" }}
                          className="mx-auto px-3 rounded-pill btn btn-outline-primary btn-hover-white mb-3"
                        >
                          <b>ORDER NOW</b> &nbsp;
                          <i className="fas fa-long-arrow-alt-right" />
                          &nbsp;
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* eo-card-deck-wrap */}
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    );
  }
}
export default ProductIndexCarousel;
