import React from 'react';

export default class  Hearthealthvideo extends React.Component{
 

render(){
  return (<section className="learn-video-sec bg-white">
    <div className="learn-video-sec-wrap container bg-white">
      <div className="row">
        <div className="learn-video-sec-header col-sm-6">
          <br />
          <br />
          <br />
          <h2 className="text-center roboto-black-36px pt-5" >Learn about your risks <br />SIMPLY </h2>
        </div>
        <div className="text-right embed-responsive embed-responsive-16by9 col-sm-6 ">
          <iframe title="HeartHealthVideo" width="1280" height="720" src="https://www.youtube.com/embed/3Dvgs37qvx0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""></iframe>
        </div>
      </div>
    </div>
  </section>);
}
}



