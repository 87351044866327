import React from "react";
// import HeaderLinks from './HeaderLinks';
import axios from "../axios/axios";
import swal from "sweetalert";
import MetaTags from "react-meta-tags";
import *as PayContants from './paymentContants';


class KnowYourGenes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pemail: "",
      pphone: "",
      pname: "",
      pmessage: "",

      errors: {}
    };
    this.baseState = this.state;

    this.prospectReg = this.prospectReg.bind(this);
  }

  prospect = e => {
    const prospectForm = this.state;
    prospectForm[e.target.name] = e.target.value;
    this.setState(prospectForm);
  };

  validateForm() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;

    if (!fields["pname"]) {
      formIsValid = false;
      errors["pname"] = "*Please enter your name";
    }

    if (!fields["pemail"]) {
      formIsValid = false;
      errors["pemail"] = "*Please enter your email address";
    }

    if (typeof fields["pemail"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["pemail"])) {
        formIsValid = false;
        errors["pemail"] = "*Please enter valid email address";
      }
    }

    if (!fields["pphone"]) {
      formIsValid = false;
      errors["pphone"] = "*Please enter your mobile no.";
    }

    if (!fields["pmessage"] || fields["pmessage"] === "") {
      formIsValid = false;
      errors["pmessage"] = "*Please enter message.";
    }

    if (typeof fields["pphone"] !== "undefined") {
      if (!fields["pphone"].match(/^[0-9]{10}$/)) {
        formIsValid = false;
        errors["pphone"] = "*Please enter valid mobile no.";
      }
    }
    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  prospectReg(e) {
    e.preventDefault();
    if (this.validateForm()) {
      const prospectForm = this.state;
      prospectForm["source"] = "TruGenyGenes";
      this.setState(prospectForm);
      const { pname, pphone, pemail, pmessage, source } = this.state;
      axios
        .post("users/prospect", { pname, pphone, pemail, pmessage, source })
        .then(response => {
          this.setState(this.baseState);

          // swal(
          //   "Thank you for your interest. We will get back you soon..."
          // ).then(
          //   setTimeout(() => {
          //     this.redirect();
          //   }, 4000)
          // );
          this.redirect();
        })
        .catch(error => {
          if (error.response === undefined) {
            this.props.history.push("/error");
          } else {
            this.setState({
              errorMsg: error.response.data.message
            });
          }
        });
      e.target.reset();
      this.setState(this.baseState);
    }
  }
  redirect() {
    window.location.href = "ThanksListen";
  }

  render() {
    return (
      <div>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <link rel="stylesheet" href="newsticky.css" />

        <link
          rel="stylesheet"
          href="./fonts/fontawesome-free-5.8.2-web/css/all.css"
          type="text/css"
        />

        <link
          rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
          integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
          crossOrigin="anonymous"
        />

        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />

        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/header_footer.css" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <link rel="stylesheet" href="./css/sticky.css" />
        <link rel="stylesheet" href="./styleinline.css" />
        <link rel="stylesheet" href="./style-mobile2.css" />
        <link rel="stylesheet" href="./style-mobile.css" />
        <link rel="stylesheet" href="./style-clinicians.css" />

        <MetaTags>
          <title>
            About - Most Trusted Genetic Counselling Company in India
          </title>
          <meta
            name="description"
            content="truGeny is founded with a mission to help shift healthcare from reactive to proactive. We help people understand and benefit from their genes."
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta
            property="og:title"
            content="About - Most Trusted Genetic Counselling Company in India"
          />
          <meta property="og:site_name" content="truGeny" />
          <meta property="og:url" content="https://trugeny.com/" />
          <meta
            property="og:description"
            content="truGeny is founded with a mission to help shift healthcare from reactive to proactive. We help people understand and benefit from their genes."
          />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="" />
        </MetaTags>
        <header id="menu-head" >
          <div className="container ">
            <nav className="navbar navbar-light  justify-content-between pt-5">
              <a href="/" className="navbar-brand ">
                <img
                  className="d-none d-sm-block kgy1"
                  src="./img/know-your-genes/TruGeny_logo-mob.png"
                />
              </a>
              <a href="/" className="navbar-brand ml-0 pl-0">
                <img
                  className="d-block d-sm-none ml-0 pl-0"
                  src="./img/know-your-genes/TruGeny_logo-mob.png"
                />
              </a>
              <a className="navbar-brand d-none ">
                <img className="" src="./img/know-your-genes/logo-mob.png" />
              </a>

              <form className="menu-hero">
                <a href="mailto:info@trugeny.com" className="mr-sm-2 kgy16"></a>
                <br />
              </form>
            </nav>
          </div>
        </header>

        <div className="container">
          <div className="row pb-3 ">
            <div className="col-sm-7 mt-lg-5 mt-sm-1 pt-sm-1 helth">
              <p className="text-center">
                You inherit a lot from your family, including your HEALTH.
              </p>
              <img
                className="img-fluid p-lg-3 p-md-3 p-sm-0"
                src="./img/know-your-genes/e3.png"
              />
            </div>

            <div className="col-sm-5 paddindivme">
              <form onSubmit={this.prospectReg}>
                <div className=" col-sm divme px-0 ">
                  <div className=" hero-ravi justify-content-center px-1 pt-3 fp7">
                    <p className="text-center fp41">
                      {" "}
                      <u>I Want to Learn About My Genes</u>
                    </p>

                    <div className="form-group">
                      <label for="exampleInputEmail1 text-white">
                        <small className="text-white pb-0 mb-0">Name *</small>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        name="pname" onChange={this.prospect} 
                      />
                      <div className="landingformerror">{this.state.errors.pname}</div>
                    </div>
                    <div className="form-group">
                      <label for="exampleInputPassword1 text-white">
                        <small className="text-white pb-0 mb-0">
                          Mobile Number *
                        </small>
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        id="exampleInputPassword1"
                        pattern="[1-9]{1}[0-9]{9}"
                        onChange={this.prospect}  name="pphone" 
                      />
                        <div className="landingformerror">{this.state.errors.pphone}</div>

                    </div>
                    <div className="form-group">
                      <label for="exampleInputPassword1 text-white">
                        <small className="text-white pb-0 mb-0">Email *</small>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputPassword1"
                        name="pemail" onChange={this.prospect} 
                      />
                      <div className="landingformerror">{this.state.errors.pemail}</div>

                    </div>
                    <div className="form-group learn-text-box mb-3 kgy4">
                      <label for="exampleInputPassword1 text-white">
                        <small className={this.state.errors.pmessage ?"text-white pb-0 mb-0 landingformselecterror":"text-white pb-0 mb-0 landingformselect"}></small>
                      </label>
                      <select
                        className={this.state.errors.pmessage ?"form-control roboto-reg-16px  landingformselecterror":"form-control roboto-reg-16px  landingformselect"}
                        id="exampleFormControlSelect1"
                        type="text"
                        name="pmessage" onChange={this.prospect}
                       
                      >
                        <option
                          className="dropdown-item roboto-reg-16px d-none"
                          value=""
                          selected=""
                          disabled=""
                        >
                          I am interested to learn about:
                        </option>
                        <option
                          className="dropdown-item roboto-reg-16px"
                          value="My Cancer Risk"
                        >
                          My Cancer Risk
                        </option>
                        <option
                          className="dropdown-item roboto-reg-16px"
                          value="My Heart Health Risk"
                        >
                          My Heart Health Risk
                        </option>
                        <option
                          className="dropdown-item roboto-reg-16px"
                          value="Genetic Counselling"
                        >
                          Genetic Counselling
                        </option>
                        <option
                          className="dropdown-item roboto-reg-16px"
                          value="Pregnancy / Family Planning"
                        >
                          Pregnancy / Family Planning
                        </option>
                      </select>
                    </div>

                    <div className="input-group-prepend mb-3 btn-custom1">
                      <button
                        id="button1"
                        className="btn btn-outline-primary  btn-hover-white mt-4 fp10"
                      
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className=" mb-3 mt-2 pt-5 pb-5 text-center-sm kgy7">
          <h1 className="text-center roboto-black-30px text-uppercase">
            <b>Genes Are Passed on from a parent to a child</b>
          </h1>

          <h1 className="text-center roboto-black-30px ">
            <b>Listen to your Genes</b>
          </h1>
          <p className="container text-center roboto-reg-24 d-none kgy8">
            {" "}
            You inherit one set of genes from your father and one set from your
            mother. Genes control the way you look, the way your body works and
            genes are what makes you unique. Genes can also increase the risk in
            a family for developing certain diseases such as cancers, heart
            conditions amongst many others.
          </p>
          <p className="container text-center roboto-reg-24 kgy8">
            You inherit one set of genes from your father and one set from your
            mother. Genes control the way you look, the way your body works and
            genes are what make you unique. Genes can also increase the risk in
            a family for developing certain diseases such as cancers, heart
            conditions amongst many others.
          </p>
          <p className="container text-center roboto-reg-24 kgy9">
            Learning about your genes can help you better understand your risks
            and take actions for preventing diseases and leading a healthier
            life.
          </p>
        </div>

        <div className="container divcenter">
          <h1 className="text-center roboto-black-36px px-3 kgy10">
            live healthier, happier and longer
          </h1>
          <div className="container ">
            <div className=" text-center card-deck ">
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="card bg-default mx-auto shadow-lg mb-5 bg-white  card-height-landing">
                  <div className="card-body text-center ">
                    <div className="card-block mt-2 p-2">
                      <img
                        className="card-img-top text-center mt-2 p-2 card-radius-circle"
                        src="./img/know-your-genes/Forma 1.png"
                        alt="Card image cap"
                      />
                    </div>
                    <p className="card-text roboto-reg-18px px-2 kgy11">
                      Access from anywhere and anytime. Even from the
                      convenience of your home{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="card bg-default mx-auto shadow-lg mb-5 bg-white  card-height-landing">
                  <div className="card-body text-center ">
                    <div className="card-block mt-2 p-2">
                      <img
                        className="card-img-top text-center mt-2 p-2 card-radius-circle"
                        src="./img/know-your-genes/Shape 1 copy.png"
                        alt="Card image cap"
                      />
                    </div>
                    <p className="card-text roboto-reg-18px kgy11">
                      Personalised Counselling by certified genetic counsellors{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3 text-center">
                <div className="card bg-default mx-auto  shadow-lg mb-5 bg-white  card-height-landing">
                  <div className="card-body text-center">
                    <div className="card-block mt-2 p-2">
                      <img
                        className="card-img-top text-center mt-2 p-2 card-radius-circle "
                        src="./img/know-your-genes/Forma 3.png"
                        alt="Card image cap"
                      />
                    </div>
                    <p className="card-text roboto-reg-18px kgy11">
                      Highly accurate screening tests <b>&</b> Reports with
                      actionable insights{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="card mx-auto bg-default shadow-lg  mb-5 bg-white card-height-landing">
                  <div className="card-body text-center">
                    <div className="card-block mt-2 p-2">
                      <img
                        className="card-img-top text-center mt-2 p-2 card-radius-circle"
                        src="./img/know-your-genes/Forma 4.png"
                        alt="Card image cap"
                      />
                    </div>
                    <p className="card-text roboto-reg-18px card-text kgy11">
                      Affordable pricing
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="kgy7">
          <h4 className=" text-center  pt-5 pb-3 mb-0 l1 roboto-black-36px">
            <strong className="kgy2">I WANT TO LEARN NOW!</strong>
          </h4>
          <form
            className="needs-validation"
            onSubmit={this.prospectReg}
          >
            <div className=" col-sm divme px-0">
              <div className=" hero-ravi justify-content-center px-1 pt-3 fp7">
                <div className="form-group">
                  <label for="exampleInputEmail1 text-white">
                    <small className="text-white pb-0 mb-0">Name *</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                  
                    aria-describedby="emailHelp"
                    name="pname" onChange={this.prospect} 
                    />
                    <div className="landingformerror">{this.state.errors.pname}</div>
                </div>
                <div className="form-group">
                  <label for="exampleInputPassword1 text-white">
                    <small className="text-white pb-0 mb-0">Mobile Number *</small>
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    id="exampleInputPassword1"
                    pattern="[1-9]{1}[0-9]{9}"
                    onChange={this.prospect}  name="pphone" 
                    />
                      <div className="landingformerror">{this.state.errors.pphone}</div>

                </div>
                <div className="form-group">
                  <label for="exampleInputPassword1 text-white">
                    <small className="text-white pb-0 mb-0">Email *</small>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputPassword1"
                    name="pemail" onChange={this.prospect} 
                    />
                    <div className="landingformerror">{this.state.errors.pemail}</div>

                </div>
                <div className="form-group learn-text-box mb-3 kgy4">
                  <label for="exampleInputPassword1 text-white">
                    <small className="text-white pb-0 mb-0"></small>
                  </label>
                  <select
                        className={this.state.errors.pmessage ?"form-control roboto-reg-16px  landingformselecterror":"form-control roboto-reg-16px  landingformselect"}
                        id="exampleFormControlSelect1"
                        type="text"
                        name="pmessage" onChange={this.prospect}
                       
                      >
                    <option
                      className="dropdown-item roboto-reg-16px d-none"
                      value=""
                      selected=""
                      disabled=""
                    >
                      I am interested to learn about:
                    </option>
                    <option
                      className="dropdown-item roboto-reg-16px"
                      value="My Cancer Risk"
                    >
                      My Cancer Risk
                    </option>
                    <option
                      className="dropdown-item roboto-reg-16px"
                      value="My Heart Health Risk"
                    >
                      My Heart Health Risk
                    </option>
                    <option
                      className="dropdown-item roboto-reg-16px"
                      value="Genetic Counselling"
                    >
                      Genetic Counselling
                    </option>
                    <option
                      className="dropdown-item roboto-reg-16px"
                      value="Pregnancy / Family Planning"
                    >
                      Pregnancy / Family Planning
                    </option>
                  </select>
                </div>

                <div className="input-group-prepend mb-3 btn-custom1">
                  <button
                    id="button1"
                    className="btn btn-outline-primary  btn-hover-white mt-4 fp10"
                 
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
          <h1 className="text-center pt-3 kgy12">
            <strong> </strong>
          </h1>
          <h4 className=" text-center  py-5 mb-3 l1 kgy13">
            Your Genes Matter. <strong className="kgy2">TAKE CHARGE</strong>
          </h4>

          <h1 className="text-center pt-3 ">
            {" "}
            <img
              className="mt-2 img-fluid"
              height="150px"
              width="250px"
              src="./img/know-your-genes/TruGeny_logo.png"
            />
          </h1>
          <div className="text-center kgy15">
            <a
              className="roboto-bold-14px text-center  pr-5 pl-5 mr-5 mx-auto p-3 btn btn-outline-primary btn-hover-white text-white kgy14"
              href={PayContants.truGenyMailhref}
            >
              <i className="far fa-envelope"></i> {PayContants.truGenyMail}
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default KnowYourGenes;
