import React from 'react';
import *as PayContants from './paymentContants';
import {

  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
class MobileFooter extends React.Component{
  constructor(props) {
    super(props);
    this.closeModel = this.closeModel.bind(this);
    this.state = {
      isOpen: false,
      modalIsOpen: false,

      modal: false,
    }}

  toggleModal() {
    this.setState(prevState => ({ modalIsOpen: !prevState.modalIsOpen }));
  }

  closeModel() {
    this.setState({
      modal: false,
      errorMsg: null,
      errors: {}
    });
  }

   render(){
  

       return(
<>
<style dangerouslySetInnerHTML={{__html: "\n        .share2 {\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-align: start;\n    align-items: flex-start;\n    -ms-flex-pack: justify;\n    justify-content: space-between;\n    padding: 1rem 1rem;\n    border-bottom: 0px solid #dee2e6;\n    border-top-left-radius: calc(.3rem - 1px);\n    border-top-right-radius: calc(.3rem - 1px);\n}\n.share1 {\n    position: relative;\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-direction: column;\n    flex-direction: column;\n    width: 100%;\n    pointer-events: auto;\n    background-color: transparent;\n    background-clip: padding-box;\n    border: 0px solid rgba(0,0,0,.2);\n    border-radius: .3rem;\n    outline: 0;\n}\n    " }} />
        <Modal isOpen={this.state.modalIsOpen} >
        <ModalHeader toggle={this.toggleModal.bind(this)} modalClassName="d-none share2">
       
        </ModalHeader>
        <ModalBody>
        <div className="modal-body text-center">
        <a href={PayContants.facebookUrl}><img src="./images/facebook.png" className="modileFooter" /></a>
        <a href={PayContants.linkedinUrl}><img src="./images/linkedin.png" className="modileFooter" /></a>
        {/* <a href><img src="./images/whatsapp.png" className="modileFooter" /></a>
        <a href><img src="./images/youtube.png" className="modileFooter" /></a> */}
        <a href={PayContants.twitterUrl}><img src="./images/twitter.png" className="modileFooter" /></a>
        <a href={PayContants.instagramUrl}><img src="./images/instagram-sketched.png" className="modileFooter" /></a>
      </div>
        </ModalBody>
       
      </Modal>
        <div className="fixed-bottom bg-white stickme">
        <div className="container">
          <div className="row py-2">
            <div className="col-1 p-0 m-0">
              {/* <i style="color:#4f5be7"class="fas fa-home fa-2x"></i> */}
            </div>
            <div className="col-2 ">
              {/* <i class="fas fa-home fa-2x"></i>
 */}
              <a  href='/'><img style={{color: 'black'}} src="./img/icons/house.svg" className="img-fluid" /></a>
            </div>
            <div className="col-2">
              {/* <i class="fas fa-mobile-alt fa-2x"></i> */}
             <a href={PayContants.truGenyHrefConatct}> <img   src="./img/icons/smartphone.svg" className="img-fluid" /></a>
            </div>
            <div className="col-2">
              <a href={PayContants.truGenyMailhref}><img style={{color: 'black'}}   src="./img/icons/envelope.svg" className="img-fluid" /></a>
            </div>
            <div className="col-2" onClick={this.toggleModal.bind(this)} >
              <img style={{color: 'black'}} src="./img/icons/share1.svg"  className="img-fluid" />
            </div>
           
           
          </div>
        </div>
      </div>
      </>
       )
   }

}
export default MobileFooter;