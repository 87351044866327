import React from 'react';
import { Form } from 'reactstrap';

import '../styles.css';
import axios from '../axios/axios';

import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import NewMainHeader from './NewMainHeader';
import SubFooter from './SubFooter';
import swal from 'sweetalert';
import SubMainFooter from './SubMainFooter';
import MobileFooter from './MobileFooter';

import { getUser } from './UserType';


class Login extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      modal: false,

      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      confirmPwd: '',
      errorMsg: null,
      errors: {},
      loginForm: {},
      loginErrors: {},
      loginType:''
    };


    this.toggle = this.toggle.bind(this);
    this.login = this.login.bind(this);
    this.closeModel = this.closeModel.bind(this);
    this.getOtp=this.getOtp.bind(this);

  }
  toggle() {
    this.props.history.push("/register")
  }
  closeModel() {
    this.setState({
      modal: false,
      errorMsg: null,
      errors: {}
    });
  }
  allowsOnlyAlphaNumeric(value){
    
 

    return /^[^0-9]/.test(value)
  }
  onChange = (e) => {
    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);
    }
  handleChange = (e) => {
    const loginForm = this.state
    loginForm[e.target.name] = e.target.value;
    this.setState(loginForm);
    if(e.target.name==="email"){
      if(e.target.value.match(/^[0-9]{10}$/)){
               this.setState({loginType:'otp'})
      }
      else{
        this.setState({loginType:'password'})
      }
        } 
  }
  getOtp(){
    let loginForm = this.state;
    let loginErrors = {};
    let mobile = this.state.email;
    axios.get('users/sendOtp/'+mobile )
      .then((response) => {
        
      }).catch(
        error => {
          if (error.response === undefined) {
            this.props.history.push("/error")
          } else {
         
          swal(error.response.data.message)
          }
        }
      )
    if(!loginForm["email"].match(/^[0-9_\s-]{10}$/)){
      loginErrors["email"] = "*Please enter 10 digit's Mobile No";
     }else{
     


    }
    
    
    this.setState({
      loginErrors: loginErrors
    });

  }

  
  componentDidMount(){

    const user=getUser();

    if(user==="CUSTOMER") {
        this.props.history.push("/loginSuccess")
    } 

  }
  

  loginValidation() {
    let loginForm = this.state;
    let loginErrors = {};
    let formIsValid = true;

    if (loginForm["email"]==="") {
      formIsValid = false;
      loginErrors["email"] = "*Please enter your email address";
    }else{

      if (typeof loginForm["email"] !== "undefined") {
        if (this.allowsOnlyAlphaNumeric(loginForm["email"])) {
          var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          if (!pattern.test(loginForm["email"])) {
            formIsValid = false;
            loginErrors["email"] = "*Please enter valid email address";
            this.setState({loginType:'email'})
          }
        } 
        
       else{
  
          if(!loginForm["email"].match(/^[0-9]{10}$/)){
            formIsValid = false;
            loginErrors["email"] = "*Please enter 10 digit's Mobile No";
          }
      }
  
  
       
      }
  
      
    }
 

        if (!loginForm["password"]) {
      formIsValid = false;
      if(this.state.loginType==="otp"){
        loginErrors["password"] = "*Please enter 4 digit's otp.";
      }
      else{
        loginErrors["password"] = "*Please enter your password.";

      }
    }

    if (typeof loginForm["password"] !== "undefined" && loginForm["password"] !== "") {

     if(this.state.loginType==="otp"){
      if (!loginForm["password"].length===4) {
        formIsValid = false;
        loginErrors["password"] = "*Please enter 4 digit otp.";
      }

     }
     else{
      if (!loginForm["password"].match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
        formIsValid = false;
        loginErrors["password"] = "*Please enter valid password.";
      }
     }
    }
    this.setState({
      loginErrors: loginErrors
    });
    return formIsValid;

  }




  /* user login authentication*/

  login=(e) => {
    e.preventDefault();
    if (this.loginValidation()) {
      const { email, password,loginType } = this.state;
      axios.post('users/login', { email, password,loginType })
        .then((response) => {
          
          if(jwt_decode(response.data.token).scopes[0].authority==="CUSTOMER"){
            Cookies.set('scope', 'CUSTOMER');
            Cookies.set('jwt-token', response.data.token, { expires: 1 });
            Cookies.set('username', jwt_decode(response.data.token).firstName);
            Cookies.set('userId', jwt_decode(response.data.token).userId);
            window.location.href='/loginSuccess';
            
            // this.props.history.push('/loginSuccess');
          }else{
            swal("You are not a customer")
          }
          
        }).catch(
          error => {
            if (error.response === undefined) {
              this.props.history.push("/error")
            } else {
              this.setState({
              errorMsg: error.response.data.message
            })
            swal(error.response.data.message)
            }
          }
        )
      }
    }



  render() {


    return (

      <div>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese" rel="stylesheet" />
        <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>truGeny</title>
        <link rel="icon" href="./img/landingpage/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <link rel="stylesheet" href="./css/header_footer.css" />
        <link rel="stylesheet" href="./css/payment.css" />
        <style dangerouslySetInnerHTML={{ __html: "\n  /* form focus styles*/\n\n  .form-control:focus {\n    border-color: #ebebeb;\n    box-shadow: inset\n    0 0px 0px rgba(0, 0, 0, .01),\n    0 0 0px rgba(0, 0, 0, .01),\n    0 0 30px rgba(141, 141, 141, 0.5),\n    0 0 0px rgba(0, 0, 0, .01);\n  }\n.c333{\n  border-bottom-left-radius: 40px;\n    border-bottom-right-radius: 2rem;\n}\n  /*End of  form focus styles*/\n  " }} />
                <link rel="stylesheet" href="./style-mobile2.css" />
        <link rel="stylesheet" href="style-mobile.css" />

        {/* --------------------------------------------------------------------------------------------head section end-------------------------------------------------------------------------------------------- */}
        <NewMainHeader category="signin"></NewMainHeader>
        <div style={{ paddingTop: '7rem' }} />
        <div className=" text-center mb-1 sec-1">
          {/* <a href="index.html"> <img  class="logo-create-page text-center img-fluid"src="./img/registration/Logo.png"/></a> */}
          <div className="container c1 text-center">
            <div className="head-text">
              <h1 className="head-text-h1  text-center img-fluid roboto-black-36px">SignIn to your truGeny account </h1>
              <a href="/register" className="text-center img-fluid para roboto-reg-16px" style={{ color: '#4f5be7' }}>Create your truGeny account? Sign Up</a>
            </div>
            {/* ---------------------------------------------------section 2 started-----------*/}
            <div className="container mt-5 pt-5  pb-5 mb-2 b1-cr">
              {/* <div class="col-sm-12 d-flex d-inline-flex mx-auto justify-content-center"> */}
              <div className="col-xs-12">
                {/* <div class="row">

        <div class="input-group-prepend mb-3 mx-auto"style="border:solid 1px #ebebeb;max-width:220px;margin-right: 10px !important;">
          <input type="text" placeholder="First Name" class="form-control roboto-reg-16px" style="border:none;height:60px" aria-label="Text input with dropdown button" >
          <button class="btn btn-outline-secondary roboto-bold-14px " href="#"  style="border:none;background-color:white;" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="img/payment/ship-Icon-1.png"/></button>
        </div>

      <div class="input-group-prepend mb-3 mx-auto"style="border:solid 1px #ebebeb;max-width:220px;margin-left: 10px !important;">
        <input type="text" placeholder="Last Name" class="form-control roboto-reg-16px" style="border:none;height:60px" aria-label="Text input with dropdown button" >
        <button class="btn btn-outline-secondary roboto-bold-14px " href="#"  style="border:none;background-color:white;" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="img/payment/ship-Icon-1.png"/></button>
      </div>

  </div> */}
              </div>
              {/*Grid column*/}

              {/*Grid column*/}
              <Form >
                <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errorMsg}</div>
                <div className="col-xs-12 ">
                  <div className="input-group-prepend mb-3 mx-auto" style={{ border: 'solid 1px #ebebeb', maxWidth: '460px' }}>
                    <input type="text" name="email" placeholder="Email Id / Mobile Number" onChange={this.handleChange} className="form-control roboto-reg-16px" style={{  height: '60px',border: '0px', borderColor: this.state.loginErrors.email ? "#ff0000" : "" }}aria-label="Text input with dropdown button" />
                    <span className="input-group-text" id="validationTooltipEmailPrepend" style={{border: 'none', backgroundColor: 'white'}}><img className="img-fluid" src="img/homepage/i3.png" /></span>                  </div>
                </div>
                <div className="col-xs-12 ">
                <div className="gc-col input-group-prepend mb-3 mx-auto" style={{maxWidth: '460px'}}>
                <span  style={{color: 'red', fontSize: '12px' }}>{this.state.loginErrors.email}</span>
                </div>
              </div>
                <div className="col-xs-12 ">
                <div className="gc-col input-group-prepend mb-3 mx-auto" style={{maxWidth: '460px'}}>
                  {/* <a href="" style={{color: '#4f5be7'}}>Click to get otp</a> */}
                  
                  <button type="button" className="btn btn-outline-primary btn-hover-white  my-sm-0 " style={{ height: '40px', width: '150px', borderRadius: '30px' }} disabled={this.state.loginType==="otp"?false:true} onClick={this.getOtp}>Click to get otp</button>
                </div>
              </div>
                {/*Grid column*/}
                <div className="col-xs-12 ">
                  <div className="input-group-prepend mb-3 mx-auto" style={{ border: 'solid 1px #ebebeb', maxWidth: '460px' }}>
                    <input type="password" name="password" placeholder="Password / OTP" onChange={this.handleChange} className="form-control roboto-reg-16px" style={{  height: '60px',border: '0px', borderColor: this.state.loginErrors.password ? "#ff0000" : "" }}  aria-label="Text input with dropdown button" />
                    <span className="input-group-text" id="validationTooltipEmailPrepend" style={{border: 'none', backgroundColor: 'white'}}><img className="img-fluid" src="img/payment/pwd.png" /></span>
                  </div>

                </div>
                <div className="col-xs-12 ">
                <div className="gc-col input-group-prepend mb-3 mx-auto" style={{maxWidth: '460px'}}>
                <span  style={{color: 'red', fontSize: '12px' }}>{this.state.loginErrors.password}</span>
                </div>
              </div>

                {/*Grid column*/}
                {/* <div class="col-xs-12">

  <div class="row">
      <div class="input-group-prepend mb-3 mx-auto"style="border:solid 1px #ebebeb;max-width:220px;margin-right: 10px !important;">
        <input type="text" placeholder="Create Password" class="form-control roboto-reg-16px" style="border:none;height:60px" aria-label="Text input with dropdown button" >
        <button class="btn btn-outline-secondary roboto-bold-14px " href="#"  style="border:none;background-color:white;" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="img/payment/pwd.png"/></button>
      </div>

      <div class="input-group-prepend mb-3 mx-auto"style="border:solid 1px #ebebeb;max-width:220px;margin-left: 10px !important;">
        <input type="text" placeholder="Confirm Password" class="form-control roboto-reg-16px" style="border:none;height:60px" aria-label="Text input with dropdown button" >
        <button class="btn btn-outline-secondary roboto-bold-14px " href="#"  style="border:none;background-color:white;" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="img/payment/pwd.png"/></button>
      </div>

    </div>

</div> */}
                <div className="col-xs-12 mx-auto d-flex justify-content-center mt-1 ">
                  {/* <span><input type="checkbox" name="vehicle" value="Bike" style="color:#4f5be7">&nbsp;&nbsp;</span> */}
                </div>
                <div className="wrapper mt-3 pb-3">
                  <button className="btn btn-outline-primary btn-hover-white  my-sm-0 center" style={{ height: '60px', width: '210px', borderRadius: '30px' }} type="submit" onClick={this.login} >
                    <b>Sign In</b></button>
                </div>
                {/*Grid column*/}
                <div className="wrapper mt-3 pb-3 ">
                  <div className="wrapper mt-3 pb-3 ">
                    {/* <span><input type="checkbox" name="vehicle" value="Bike" style="color:#4f5be7">&nbsp;&nbsp;</span> */}
                    <a href="/forgotpwd" style={{ color: '#4f5be7', fontSize: '1rem' }}>Forgot Password</a>
                  </div>
                  <div className="col-xs-12">
                    <div className="row ">
                      {/* Form */}
                      <div className="input-group-prepend  mx-auto" style={{ maxWidth: '220px', marginRight: '10px !important' }}>
                      </div>

                    </div>
                    {/* <button  style="height:40px;width:220px;" class="mt-5 mb-3 rounded-pill  btn btn-outline-primary btn-hover-white roboto-bold-14px" onclick="window.location.href = 'view-reports.html';" type="submit" >Login</button> */}
                  </div>
                  <div className="col-xs-12 mx-auto d-flex justify-content-center pt-1 pb-3">
                    {/* <span class="line-span align-self-center"></span> */}
                    {/* <span class="text-uppercase px-2 or align-self-center">or</span> */}
                    {/* <span class="line-span align-self-center"> </span> */}
                  </div>
                  <div className="col-xs-12">
                    <div className="row">
                      {/* Form */}
                      <div className="input-group-prepend mb-3 mx-auto" style={{ border: 'solid 1px #ebebeb', maxWidth: '220px', marginRight: '10px !important' }}>
                        {/* <input type="text" placeholder="Email-id" class="form-control roboto-reg-16px" style="border:none;height:60px" aria-label="Text input with dropdown button" > */}
                        {/* <a href=""><img src="img/payment/fb.png"/></a> */}
                      </div>
                      <div className="input-group-prepend mb-3 mx-auto" style={{ border: 'solid 1px #ebebeb', maxWidth: '220px', marginLeft: '10px !important' }}>
                        {/* <input type="text" placeholder="Email-id" class="form-control roboto-reg-16px" style="border:none;height:60px" aria-label="Text input with dropdown button" > */}
                        {/* <a href=""><img src="img/payment/gmail.png"/></a> */}
                      </div>
                      {/* Form */}
                    </div>
                  </div>

                </div>
              </Form>
            </div>
            <div className="container text-center bg-white py-4 c333">
              <img className="plus-button img-fluid mb-3" src="./img/registration/Icon.png" />
              <p className=" text-center  " style={{ color: '#84888e' }}>Are you a Doctor or a Health Care Provider? If you are trying <br />to order a truGeny Test or wanting to view your patients report, <br />
                <a style={{ color: '#4f5be7', fontSize: '1rem' }} className="pb-5 cr-1" href="https://trugeny.com/clinicians_login"><strong>CLICK HERE</strong></a> to create your account</p>
            </div>
          </div>
          <div style={{ marginTop: '6rem' }}>
          </div>
          {/* ------------------------------------------------------------------------------------------------- */}
          <SubMainFooter></SubMainFooter>
          <MobileFooter></MobileFooter>
        </div>
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>
    )

  }
}

export default Login;