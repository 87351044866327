import React from "react";
import Cookies from "js-cookie";
import axios from "../axios/axios";
import MobileFooter from "./MobileFooter";
import { Link } from "react-router-dom";
import SubFooter from "./SubFooter";
import NewMainHeader from "./NewMainHeader";
import swal from "sweetalert";
import Currency from "react-currency-formatter";
import ProductIndexCarousel from "./ProductIndexCarousel";
import moment from "moment";


class ProductPageIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      data:[],
      service1:"",
      service2:"",
      service3:"",
      serviceprice1:0,
      serviceprice2:0,
      serviceprice3:0,
      product1:"",
      product2:"",
      product3:"",
      productprice1:0,
      productprice2:0,
      productprice3:0,
      date: new Date(),

      //offers logis start

      offerDatesData:[],
      offerDates:[],
      offerStartDate:'',
      offerEndDate:'',
      offerName:'',
      offerForProduct:'',
      offerForProduct1:'',
      offerForProduct2:'',
      offerFree:'',
      offerPrice:'',
      offerSession:'',
      offerId:'',
      //offer logic end
      
    }
    this.toOrderHeart = this.toOrderHeart.bind(this);
    this.getOffer = this.getOffer.bind(this);
    this.camelize = this.camelize.bind(this);
  }
  componentDidMount(){
   if( Cookies.get("userId")){
     window.location.href='/loginSuccess'
   }
    this.getAllServices();
    this.getAllProducts();
    this.getOffer();

    
  }

  getAllServices(){
    axios
    .get("productservice/allProductDetails")
    .then(
      response => {
        this.setState({
         
          service1:response.data[0].productName,
          serviceprice1:response.data[0].unitPrice,
          service2:response.data[1].productName,
          serviceprice2:response.data[1].unitPrice,
          service3:response.data[2].productName,
          serviceprice3:response.data[2].unitPrice,
          
        });
      //   this.state.data.map((answer, i) => {     
      //     console.log("Entered");                 
      //     // Return the element. Also pass key     
      //    console.log(answer.productName);
      //  })
      },
      () => {}
    ).catch(function(error) {});
  }
  getAllProducts(){
    axios
    .get("productservice/productDetails")
    .then(
      response => {
    this.setState({
      product1:response.data[0].productName,
      productprice1:response.data[0].unitPrice,
      product2:response.data[1].productName,
      productprice2:response.data[1].unitPrice,
      product3:response.data[2].productName,
      productprice3:response.data[2].unitPrice,
    });
      },
      () => {}
    ).catch(function(error) {});
  }
  getOffer(){
    //offer logic
    axios.get("offerDates/getOfferByService")
    .then((response) => {
      var offerForProduct=response.data.offerForProduct;
      var offerForProduct1="";
      var offerForProduct2="";
      if(/[,]/g.test(offerForProduct)){
       offerForProduct=response.data.offerForProduct.toString().split(",");
       offerForProduct1=response.data.offerForProduct.toString().split(",")[0];
       offerForProduct2=response.data.offerForProduct.toString().split(",")[1];
      }
 
        this.setState({
          offerDates: response.data,
          offerStartDate: response.data.offerStartDate,
          offerEndDate: response.data.offerEndDate,
          offerName:response.data.offerName,
          offerForProduct1:offerForProduct1,
          offerForProduct2:offerForProduct2,
          offerForProduct:offerForProduct[0],
          note:response.data.note,
          priceType:response.data.priceType
        },()=>{})
 
        var listDate = [];
        var startDate = response.data.offerStartDate;
        var endDate = response.data.offerEndDate;
        var dateMove = new Date(startDate);
        var strDate = startDate;
        
        while (strDate < endDate){
          var strDate = dateMove.toISOString().slice(0,10);
          listDate.push(strDate);
          dateMove.setDate(dateMove.getDate()+1);
        };
 
 
 this.setState({
         offerDatesData:listDate
        },()=>{this.offerOverAll(this.state.date)});
      }
    )
 
    //offer code end here
 
 }

  toOrderHeart() {
    // browserHistory.push("/ordertrugene");
    window.location.href = "/ordertrugene";
  }

  toAllHelp() {
    // browserHistory.push("/ordertrugene");
    window.location.href = "/al_help";
  }

  camelize(str) {
    return str.toLowerCase().replace(/(?:(^.)|(\s+.))/g, function(chr) {
      return chr.charAt(chr.length - 1).toUpperCase();
    });
  }

  offerOverAll(date) {
    const { offerDatesData, offerForProduct, offerForProduct1, offerForProduct2, priceType, offerDates } = this.state;
    this.setState({ offerPrice: -1, offerFree: '',offerId:'' }, () => { })
    if (offerDatesData.find(x => x === moment(date).format("YYYY-MM-DD")) && (offerForProduct1 || offerForProduct2 || offerForProduct)) {
      if (priceType === "free") {
        this.setState({ offerPrice: 0, offerFree: priceType,offerId:offerDates.dateId }, () => { })
      }
      else {
        // this.offerPriceValue()
        this.setState({ offerPrice: offerDates.offerPrice,offerId:offerDates.dateId }, () => { })
      }
    }
  }

  render() {
    const productUrl = "/order-now-index";
    const bookingUrl = "book-counselling-index";

    return (
      <div>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <link
          rel="stylesheet"
          href="https://www.w3schools.com/w3css/4/w3.css"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i"
          rel="stylesheet"
        />
        {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossorigin="anonymous"> */}
        <link
          rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
          integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
          crossOrigin="anonymous"
        />
        <title>truGeny</title>
        <link
          rel="stylesheet"
          href="./fonts/fontawesome-free-5.8.2-web/css/all.css"
          type="text/css"
        />
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <link rel="stylesheet" href="./styleinline.css" />
        <link rel="stylesheet" href="./style-mobile2.css" />

        <link rel="stylesheet" href="style-mobile.css" />
        {/* header       <CommonHeder category="product-page-demo"></CommonHeder>
         */}
        <NewMainHeader category="product-page-index"></NewMainHeader>

        {/* end of header <div style={{paddingBottom: '76px'}} className="padding22" /> */}

        <div style={{ marginTop: "8rem" }} className="margin"></div>
       

        {/* <section class="id-1" > */}

        <section className="id-1 ">
        
          <div className="container shadow-lg py-5">
            <div className="container padding3 " />
            <h2
              className="text-center content-displayshow TruGeneColor"
              style={{
                fontSize: "2.25rem",
                marginLeft: "1rem",
                fontWeight: 900
              }}
            >
              Counselling Appointment
            </h2>
            <div className="conatainter ">
              <div className="row ">
                <div className="col-lg-12">
                  <span>
                    <p className="text-center roboto-reg-24px ">
                      <img className="img-fluid-cus" src="./img/login/1.png" alt="product"/>{" "}
                      Schedule your appointment with our genetic experts to
                      start your genetic journey
                    </p>
                  </span>
                </div>
              </div>
            </div>
            <p className="text-center roboto-bold-24px TruGeneColor pb-3">
              Select a plan that works best for you
            </p>

            <div className="container text-center my-5">
              <div className="row bookmycouncel text-center">
                <div className="col-lg-4">
                  <div
                    className="card shadow-lg pb-3 mb-5"
                    style={{ borderRadius: "32px" }}
                  >
                    <div
                      className="card-top bb-card-image1"
                      style={{
                        backgroundImage: "url(./img/book-session/card-1.png)",
                        borderTopLeftRadius: "32px",
                        borderTopRightRadius: "32px"
                      }}
                    >
                      <div className="text-center py-4 ">
                        <h1 className="text-center mb-0  roboto-bold-24px">
                        <br/>
                         {this.camelize(this.state.service1)}
                        </h1>
                      </div>
                    </div>
                    <div className="card-body">
                      <br />
                      <br />
                      <p className="card-text">Order your truGeny test.</p>
                      <p className="card-text">
                        Get complimentary 30 minutes of Pre-test and 30 minutes
                        of Post-test genetic counselling
                      </p>
                      <br />
                      <a
                        onClick={() => {
                          swal(
                            "For Availability of Free Session You must order a TruGeny Test"
                          );
                        }}
                        className="btn btn-outline-primary btn-hover-white  my-2 my-sm-0 py-2 cardHref"
                     
                      >
                        Select
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div
                    className="card shadow-lg pb-3 mb-5"
                    style={{ borderRadius: "32px" }}
                  >
                    <div
                      className="card-top bb-card-image1"
                      style={{
                        backgroundImage: "url(./img/book-session/card-2.png)",
                        borderTopLeftRadius: "32px",
                        borderTopRightRadius: "32px"
                      }}
                    >
                      <div className="text-center py-4 ">
                        <h1 className="text-center mb-0 py-0 roboto-bold-24px paddingbcard">
                        <br/>
                        {this.camelize(this.state.service2)}
                        </h1>
                      </div>
                    </div>

                    <div className="card-body" style={{ lineHeight: "1.2" }}>
                      <h1 className="text-center mb-0 py-0 roboto-bold-24px TruGeneColor">
                      {((this.state.offerForProduct===this.state.service2||this.state.offerForProduct1===this.state.service2||this.state.offerForProduct2===this.state.service2)&&this.state.offerPrice>=0)?
                      
                    <del className="highlight">  <span className="TrugeneColor"> <Currency className="TrugeneColor"
                      currency="INR"
                      quantity={this.state.serviceprice2}
                    />/-<br/></span></del>
                        :<>
                        <Currency
                        currency="INR"
                        quantity={this.state.serviceprice2}
                      />/-
                          <br/></>
                      }
                      </h1>
                      
         
           {((this.state.offerForProduct===this.state.service2||this.state.offerForProduct1===this.state.service2||this.state.offerForProduct2===this.state.service2)&&this.state.offerPrice>=0)?<h3>Offer: {this.state.note}</h3>
                       :""}

                      <br />
                      <p className="card-text" style={{ marginBottom: "10px" }}>
                        Upload your reports
                      </p>
                      <p className="card-text" style={{ marginBottom: "10px" }}>
                        30 minute genetic counselling session.
                      </p>
                      <p className="card-text" style={{ marginBottom: "10px" }}>
                        Assessment of health
                      </p>
                      <p className="card-text" style={{ marginBottom: "10px" }}>
                        Review of family health history
                      </p>
                      <p className="card-text" style={{ marginBottom: "10px" }}>
                        Discuss Genetic testing options
                      </p>
                      <br/>

                      <Link
                        to={{
                          pathname: bookingUrl,
                          state: {
                            session: "available",
                            selectedPlan: this.state.serviceprice2,
                            selectedPlanName: this.state.service2
                          },
                          price: this.state.serviceprice2
                        }}
                      >
                        {" "}
                        <button
                          className="btn btn-outline-primary btn-hover-white cardHref"
                        
                        >
                          Select
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div
                    className="card shadow-lg pb-3"
                    style={{ borderRadius: "32px" }}
                  >
                    <div
                      className="card-top bb-card-image1"
                      style={{
                        backgroundImage: "url(./img/book-session/card-3.png)",
                        borderTopLeftRadius: "32px",
                        borderTopRightRadius: "32px"
                      }}
                    >
                      <div className="text-center py-4 ">
                        <h1 className="text-center mb-0 py-0 roboto-bold-24px paddingbcard">
                        <br/>
                          {this.camelize(this.state.service3)}
                        </h1>
                      </div>
                    </div>
                    <div className="card-body">
                      <h1 className="text-center mb-0 py-0 roboto-bold-24px TruGeneColor">
                      {((this.state.offerForProduct===this.state.service3||this.state.offerForProduct1===this.state.service3||this.state.offerForProduct2===this.state.service3)&&this.state.offerPrice>=0)?
                    <del className="highlight">   <span className="TrugeneColor"><Currency 
                      currency="INR"
                      quantity={this.state.serviceprice3}
                    />/-</span><br/></del>
                        :<>
                        <Currency
                        currency="INR"
                        quantity={this.state.serviceprice3}
                      />/-
                          <br/></>
                      }
                      </h1>
                      
         
           {((this.state.offerForProduct===this.state.service3||this.state.offerForProduct1===this.state.service3||this.state.offerForProduct2===this.state.service3)&&this.state.offerPrice>=0)?<h3>Offer: {this.state.note}</h3>
                       :""}
                      <br />
                      <p className="card-text">
                        Preliminary session + 15 days online chat with a
                        licensed genetic counsellor
                      </p>
                      <br />
                      <br />
                      <br />
<br/>
                      <Link
                        to={{
                          pathname: bookingUrl,
                          state: {
                            session: "available",
                            selectedPlan: this.state.serviceprice3,
                            selectedPlanName: this.state.service3
                          },
                          price: this.state.serviceprice3
                        }}
                      >
                        {" "}
                        <button
                          
                          className="btn btn-outline-primary btn-hover-white  my-2 my-sm-0 py-2 cardHref"
                        
                        >
                          Select
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />

          <h2 className="text-center roboto-black-36px  mt-5 pt-5 TruGeneColor">
            Order your truGeny Test Kit
          </h2>
          <span>
            <p className="text-center roboto-reg-24px pt-2">
              <img className="img-fluid-cus" src="./img/login/2.png" alt="product"/> Understand
              your genes and evaluate your risk for cancer and cardiac
              conditions.
            </p>
          </span>

          <div className="container container-image" style={{ height: "auto" }}>
            <div className="gtk-main-header-wrap">
              <h2 className="text-center roboto-black-36px  ">
                truGeny brings you a new kind of DNA TEST
              </h2>
              <p className="text-center roboto-reg-24px pt-2">
                Discover insights from your DNA. Take actions for a healthier
                and longer life.
              </p>
              <h2
                className="text-center roboto-reg-24px pt-md-2 pt-sm-2"
                style={{ color: "#4f5be7" }}
              >
                <b>Be PROACTIVE</b>
              </h2>
              <div className="questions-btn text-center mx-auto">
                {/* <a class="btn btn-outline-primary btn-hover-white rounded-pill text-center " href="#" role="button">Order my truGeny Kit</a> */}
              </div>
            </div>
            {/* eo gr-header */}
            <div className="container p-0 pb-5">
              <div className="card-deck" id="hide">
                {/* eo-card-deck-wrap */}
                <div className=" col-sm-12 col-md-12 col-lg-4 mb-md-5 mb-sm-2 py-md-2 py-sm-2 pr-0 pl-5">
                  <div className="card rounded shadow-lg  r4 pCard">
                    <div className="  text-center p2Card">
                      <div className="card-block circle">
                        <img
                          className="card-img-top text-center  "
                          src="./img/payment/cancer.png"
                          style={{ height: "126px", width: "126px" }}
                          alt="Cardcap"
                        />
                        <h3
                          className="text-highlight pt-4 "
                          style={{ fontWeight: 900 }}
                        >
                          <strong>TruFind</strong>
                          <br />
                          <span className="textspancard">
                            Hereditary Cancer Test
                          </span>
                        </h3>
                        <h1 className="text-center mb-0 py-0 roboto-bold-24px TruGeneColor">
                         <Currency
                      currency="INR"
                      quantity={this.state.productprice3}
                    />/-
                        </h1>
                        <br />
                      </div>
                      <p>
                        A detailed analysis of over 90 genes your genes to help
                        you learn your risks for developing cancers of the
                        breast, ovarian, uterine, colorectal, prostrate,
                        pancreatic and stomach cancers.
                      </p>
                      {/* <h3 class="font-card">Understand your genes and evaluate your risks for cancer and cardiac conditions.</h3> */}
                      {/* <a href="view-reports.html">   <img class="card-img-top text-center p-2 mb-1 button-view-report"  src="./img/payment/view-report-button.png"/></a> */}
                      <br />
                      <Link
                        to={{
                          pathname: productUrl,
                          state: { orderedProduct: this.state.product3 }
                        }}
                      >
                        {" "}
                        <button
                          style={{ height: "40px", weight: "160px" }}
                          className="mx-auto px-3  rounded-pill btn btn-outline-primary btn-hover-white mb-4"
                        >
                          <b>ORDER NOW</b> &nbsp;
                          <i className="fas fa-long-arrow-alt-right" />
                          &nbsp;
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* eo-card-deck-wrap */}
                <div className=" col-sm-12 col-md-12 col-lg-4 mb-md-5 mb-sm-2 py-md-2 py-sm-2">
                  <div className="card  rounded shadow-lg r4 pCard">
                    <div className=" text-center p2Card">
                      <div className="card-block circle ">
                        <img
                          className="card-img-top text-center "
                          src="./img/career/familyplanning.png"
                          style={{ height: "126px", width: "126px" }}
                          alt="Cardcap"
                        />
                        <h3
                          className="text-highlight pt-4 "
                          style={{ fontWeight: 900 }}
                        >
                          <strong>{this.state.product2}</strong>
                          <br />
                          <span className="textspancard">
                            Carrier Screening Test
                          </span>
                        </h3>
                        <h1 className="text-center mb-0 py-0 roboto-bold-24px TruGeneColor">
                           <Currency
                      currency="INR"
                      quantity={this.state.productprice2}
                    />/-
                        </h1>
                        <br />
                      </div>
                      <p className="mb-4">
                        Planning for your future family’s health matters.
                        Knowledge about your risk Of passing on a genetic
                        condition to your Child can make a difference to the
                        well being of your future children.
                      </p>
                      <br />
                      {/* <h3 class="font-card">Activating your kit will only take a few minutes. You will need the barcode found on your saliva tube.</h3> */}
                      {/* <a href="view-reports.html">  <img class="card-img-top text-center mb-1 p-2" onclick="view-reports.html" src="./img/payment/view-report-button.png"/></a> */}
                      <Link
                        to={{
                          pathname: productUrl,
                          state: { orderedProduct: this.state.product2 }
                        }}
                      >
                        {" "}
                        <button
                          style={{ height: "40px", weight: "160px" }}
                          className="mx-auto px-3 rounded-pill btn btn-outline-primary btn-hover-white mb-3"
                        >
                          <b>ORDER NOW</b> &nbsp;
                          <i className="fas fa-long-arrow-alt-right" />
                          &nbsp;
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* eo-card-deck-wrap */}
                <div className=" col-sm-12 col-md-12 col-lg-4 mb-md-5 mb-sm-2 py-md-2 py-sm-2 pr-5 pl-0">
                  <div className="card  rounded shadow-lg r4 pCard">
                    <div className=" text-center p2Card">
                      <div className="card-block circle ">
                        <img
                          className="card-img-top text-center "
                          src="./img/payment/heart.png"
                          style={{ height: "126px", width: "126px" }}
                          alt="Cardcap"
                        />
                        <h3
                          className="text-highlight pt-4 "
                          style={{ fontWeight: 900 }}
                        >
                          <strong>{this.state.product1}</strong>
                          <br />
                          <span className="textspancard">
                            Coronary Artery Disease Test
                          </span>
                        </h3>
                        <h1 className="text-center mb-0 py-0 roboto-bold-24px TruGeneColor">
                          <Currency
                      currency="INR"
                      quantity={this.state.productprice1}
                    />/-
                        </h1>
                        <br />
                      </div>
                      <p className="mb-4">
                        A next generation Polygenic Risk Score (PRS) based
                        genetic test to you help you evaluate your risks for
                        developing heart conditions such as familial
                        Hypercholestrolemia, cardiomyopathy, Arrythmia.
                      </p>{" "}
                      {/* <h3 class="font-card">Activating your kit will only take a few minutes. You will need the barcode found on your saliva tube.</h3> */}
                      {/* <a href="view-reports.html">  <img class="card-img-top text-center mb-1 p-2" onclick="view-reports.html" src="./img/payment/view-report-button.png"/></a> */}
                      <Link
                        to={{
                          pathname: productUrl,
                          state: { orderedProduct: this.state.product1 }
                        }}
                      >
                        <button
                          style={{ height: "40px", weight: "160px" }}
                          className="mx-auto px-3 rounded-pill btn btn-outline-primary btn-hover-white mb-3"
                        >
                          <b>ORDER NOW</b> &nbsp;
                          <i className="fas fa-long-arrow-alt-right" />
                          &nbsp;
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <br />

              {/* ========================================================= */}
              <ProductIndexCarousel></ProductIndexCarousel>
              {/* ========================================================= */}
            </div>
            {/* gr-card-wrap */}
          </div>
          {/* eo gr-sec-wrap */}
        </section>
        <div style={{ paddingBottom: "6rem" }}>
          <p className="text-center roboto-reg-18px mt-5 ">
            I am not very clear. Help me to understand
          </p>
          <p className="text-center mt-1 ">
            <a style={{ color: "#4f5be7" }} onClick={this.toAllHelp}>
              Learn more
            </a>
          </p>
        </div>
        {/* </section> */}
        <SubFooter />
        {/* eo Modal */}
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
        <MobileFooter />
      </div>
    );
  }
}
export default ProductPageIndex;
