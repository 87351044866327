import React from 'react'
import Footer from './Footer';
import { Collapse } from 'reactstrap';
import MainHeader from './MainHeader';
import MobileFooter from './MobileFooter';

import $ from 'jquery';
import StickyContactMenu from './StickyContactMenu';


class CliniciansHelpSecurity extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            q1: false,
            b1: true,

            q2: false,
            b2: true,

     

        }
        this.toggle1 = this.toggle1.bind(this);
        this.toggle2 = this.toggle2.bind(this);
       


    }

    toggle1() {
        this.setState(state => ({ q1: !state.q1 }));
        this.setState(state => ({ b1: !state.b1 }));


        this.setState({ q2: false }, function () { });
        this.setState({ b2: true }, function () { });


    }

    toggle2() {
        this.setState(state => ({ q2: !state.q2 }));
        this.setState(state => ({ b2: !state.b2 }));

        this.setState({ q1: false }, function () { });
        this.setState({ b1: true }, function () { });
       
    }

    componentDidMount(){
      
        $(document).ready(function(){
          $("#myInput").on("keyup", function() {
            var value = $(this).val().toLowerCase();
            $("#myData h5").filter(function() {
              $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
            });
        
          });
        });
      }


    render() {


        return (
            <div>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
                <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
                {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.2/css/all.css" integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay" crossorigin="anonymous"> */}
                <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
                <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
                <title>truGeny</title>
                <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
                <link rel="stylesheet" href="./css/header_footer.css" />
                <link rel="stylesheet" href="./css/stylesheet_custom.css" />
                <link rel="stylesheet" href="stylesheet_custom.css" />
                <link rel="stylesheet" href="./css/fonts.css" />
                <link rel="stylesheet" href="./css/sticky.css" />
               
        
                <style dangerouslySetInnerHTML={{ __html: "\n/* @media only screen and (min-width: 992px)\n/* {\n.custom-pad-mar {\n  padding-left:25rem;\n  padding-right:25rem;\n\n} */\n.heart-banner-sec-wrap .heart-banner-sec{\n  background: url(./img/help/banner-sp.png);\n  background-position:center;\n  background-size: cover;\n  background-repeat: no-repeat;\n}\n" }} />
                <link rel="stylesheet" href="./clinicians_style-mobile2.css"/>
                <link rel="stylesheet" href="./clinicians_style-mobile.css"/>
                <link rel="stylesheet" href="./clinicians_newsticky.css"/>


                <MainHeader category="help"></MainHeader>
                <section className="heart-banner-sec-wrap banner-position">
                    <div className=" heart-banner-sec ">
                        <div className="container pt-4 mt-2">
                            <h1 className><strong>Security &amp; Privacy</strong></h1>
                            {/* <h2 class="roboto-reg-24px my-3">Get the right answers to your questions</h2> */}
                            <div className="input-group " style={{ height: '75px', boxShadow: '0 17px 40px rgba(92, 103, 108, 0.2)', backgroundColor: '#ffffff', borderRadius: '2rem' }}>
                            <input type="text" className="form-control m-0" placeholder="Search for help..." name="name" id="myInput" onkeyup="myFunction()" style={{borderTopLeftRadius: '2rem', borderBottomLeftRadius: '2rem', height: '75px', boxShadow: '0 17px 40px rgba(92, 103, 108, 0.2)', backgroundColor: '#ffffff'}} required />
                            <div className="valid-tooltip">
                                    Looks good!
                </div>
                                <div className="input-group-prepend border-0 d-flex justify-content-center">
                                <span className="btn btn-outline-primary input-group-text border-0 justify-content-center helpSearchB" id="validationTooltip01">SEARCH</span>
                                </div>
                            </div>
                        </div>{/* eo-container*/}
                        <div className="d-flex justify-content-center" id="secc1">
                            <a style={{ scrollBehavior: '[ auto | smooth ]' }} href="#secc1">  <img className="img-fluid d-none d-md-block d-lg-block btn-hover-white1" src="./img/111.png" alt="alt" /></a>
                        </div>
                    </div>{/* eo-heart-banner-sec */}
                </section>{/* eo-heart-banner-sec-wrap */}
                <StickyContactMenu></StickyContactMenu>
                {/* ------------------------------------------------------heart-banner-sec over------------------------------------------------------------------ */}
                <section className="container "  id="myData">
                    <nav aria-label="breadcrumb pt-5 ">
                        <ol className="breadcrumb " style={{ backgroundColor: '#fff', paddingTop: '3rem' }}>
                            <li className="breadcrumb-item"><a href="/clinicians_help" className="roboto-reg-18px" style={{ color: '#4f5be7' }}><b>Support</b></a></li>
                            {/* <li class="breadcrumb-item"><a href="#">Library</a></li> */}
                            <li className="breadcrumb-item active" aria-current="page"><a className="roboto-reg-18px">Security &amp; Privacy</a></li>
                        </ol>
                    </nav>
                    {/* Support / Security & Privacy */}
                    <div className=" mt-5">
                        <div className>
                            <div className="card-block  p-2 circle">
                                <div className="pr-5 pb-5 hc-accordian-wrap">
                                    <div className="accordion" id="accordionIhc">
                                        <div className="card">
                                            <div className="card-header" id="headingOne">
                                                <h5 className="acc-header collapse-color mb-0">
                                                    Does truGeny protect my data?
                                                    {this.state.b1?<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle1} className="fa fa-plus-circle  rounded-circle " /></button>:<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle1} className="fa fa-minus-circle  rounded-circle " /></button>}
                                                </h5>
                                            </div>
                                            <Collapse isOpen={this.state.q1}>
                                            <div id="b2" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionIhc">
                                                <div className="card-body">
                                                    <p>truGeny ensures that all personally identifiable health information data is encrypted and stored securely.
                                                      The only people with access to your identity will be you and your genetic counsellor, so that when you
                                                      make your genetic counselling appointment, your counsellor can go though and discuss your report in
                                                        detail and answer all your questions.</p>
                                                </div>
                                            </div>
                                            </Collapse>
                                            
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="headingTwo">
                                                <h5 className="acc-header collapse-color mb-0">
                                                    What are truGeny’s privacy policy, terms of service and informed consent? Where can I read them?
                                                    {this.state.b2?<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle2} className="fa fa-plus-circle  rounded-circle " /></button>:<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle2} className="fa fa-minus-circle  rounded-circle " /></button>}
                                                </h5>
                                            </div>
                                            <Collapse isOpen={this.state.q2}>
                                            <div id="b3" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordionIhc">
                                                <div className="card-body">
                                                    <p>At truGeny your privacy is of utmost importance and we work hard to ensure that all your data is
                                                     absolutely secure. We collect information about you, to help us personalise your experience.</p>
                                                    <p><a href="">read our Privacy Policy</a></p>
                                                    <p><a href="">read our terms and conditions</a></p>
                                                    <p><a href="">read our informed consent form.</a></p>
                                                </div>
                                            </div>
                                            </Collapse>
                                            
                                        </div>
                                    </div>
                                </div>{/* eo bs-example */}
                                {/* end of accordian code */}
                            </div>
                        </div>{/* eo card-block */}
                    </div>{/* eo card body */}
                    {/* eo card */}
                    {/* eo-heart-accordian-sec-wrap */}
                </section>{/* eo-heart-accordian-sec */}
                {/* **********Schedule your Genetic Counseling Session Ends Here************** */}
                {/* Footer Links */}
                <Footer></Footer>
                <MobileFooter></MobileFooter>
                {/* end of footer */}
                {/* --------eo sticky form----------------- */}
                {/* Optional JavaScript */}
                {/* jQuery first, then Popper.js, then Bootstrap JS */}
            </div>


        )
    }

}
export default CliniciansHelpSecurity;