import React from 'react'
import Footer from './Footer';
import { Collapse } from 'reactstrap';
import MainHeader from './MainHeader';
import MobileFooter from './MobileFooter';
import $ from 'jquery';
import StickyContactMenu from './StickyContactMenu';

class CliniciansHelpCounselling extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
         
          q1:false,
          b1:true,
    
          q2:false,
          b2:true,
    
          q3:false,
          b3:true,
    
          q4:false,
          b4:true,
    
          q5:false,
          b5:true,
  
    
        }
        this.toggle1 = this.toggle1.bind(this);
        this.toggle2 = this.toggle2.bind(this);
        this.toggle3 = this.toggle3.bind(this);
        this.toggle4 = this.toggle4.bind(this);
        this.toggle5 = this.toggle5.bind(this);


        
    }

    toggle1() {
      this.setState(state => ({ q1: !state.q1 }));
      this.setState(state => ({ b1: !state.b1 }));
  
      
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});

  
    }
  
    toggle2() {
      this.setState(state => ({ q2: !state.q2 }));
      this.setState(state => ({ b2: !state.b2 }));
  
      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});

    }
  
    toggle3() {
      this.setState(state => ({ q3: !state.q3 }));
      this.setState(state => ({ b3: !state.b3 }));
  
      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});

    }
  
    toggle4() {
      this.setState(state => ({ q4: !state.q4 }));
      this.setState(state => ({ b4: !state.b4 }));
  
      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});

    }
    toggle5() {
      this.setState(state => ({ q5: !state.q5 }));
      this.setState(state => ({ b5: !state.b5 }));
  
      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});

    }
    componentDidMount(){
      
      $(document).ready(function(){
        $("#myInput").on("keyup", function() {
          var value = $(this).val().toLowerCase();
          $("#myData h5").filter(function() {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
          });
      
        });
      });
    }


  
   render(){
  

       return(
        <div>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
        {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.2/css/all.css" integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay" crossorigin="anonymous"> */}
        <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>truGeny</title>
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/header_footer.css" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <link rel="stylesheet" href="./css/sticky.css" />
       
        
        <style dangerouslySetInnerHTML={{__html: "\n/* @media only screen and (min-width: 992px)\n/* {\n.custom-pad-mar {\n  padding-left:25rem;\n  padding-right:25rem;\n\n} */\n\n.activenavbar{\n  color:#4f5be7!important;\n}\n.card-body{\n  text-align: left!important;\n}\n.heart-banner-sec-wrap .heart-banner-sec{\n  background: url(./img/help/bg1.png);\n  background-position:center;\n  background-size: cover;\n  background-repeat: no-repeat;\n}\n.i-button{\n  display: inline-block;\nborder-radius: 60px;\nbox-shadow: 0px 0px 2px #888;\npadding: 0.5em 0.6em;\n}\n.heart-banner-sec-wrap .heart-banner-sec{\n  background: url(./img/help/banner-gc.png);\n  background-position:center;\n  background-size: cover;\n  background-repeat: no-repeat;\n}\n" }} />
        <link rel="stylesheet" href="./clinicians_style-mobile2.css"/>
        <link rel="stylesheet" href="./clinicians_style-mobile.css"/>
        <link rel="stylesheet" href="./clinicians_newsticky.css"/>

        <MainHeader category="help"></MainHeader>
        <section className="heart-banner-sec-wrap banner-position">
          <div className=" heart-banner-sec ">
            <div className="container pt-4 mt-2">
              <h1 className><strong>Genetic Counselling</strong></h1>
              <div className="input-group " style={{height: '75px', boxShadow: '0 17px 40px rgba(92, 103, 108, 0.2)', backgroundColor: '#ffffff', borderRadius: '2rem'}}>
              <input type="text" className="form-control m-0" placeholder="Search for help..." name="name" id="myInput" onkeyup="myFunction()" style={{borderTopLeftRadius: '2rem', borderBottomLeftRadius: '2rem', height: '75px', boxShadow: '0 17px 40px rgba(92, 103, 108, 0.2)', backgroundColor: '#ffffff'}} required />
                <div className="valid-tooltip">
                  Looks good!
                </div>
                <div className="input-group-prepend border-0 d-flex justify-content-center">
                <span className="btn btn-outline-primary input-group-text border-0 justify-content-center helpSearchB" id="validationTooltip01">SEARCH</span>
                </div>
              </div>
            </div>{/* eo-container*/}
            <div className="d-flex justify-content-center" id="secc1">
              <a style={{scrollBehavior: '[ auto | smooth ]'}} href="#secc1">  <img className="img-fluid d-none d-md-block d-lg-block btn-hover-white1" src="./img/111.png" /></a>
            </div>
          </div>{/* eo-heart-banner-sec */}
        </section>{/* eo-heart-banner-sec-wrap */}
        <StickyContactMenu></StickyContactMenu>
        {/* ------------------------------------------------------heart-banner-sec over------------------------------------------------------------------ */}
        <section className="container "  id="myData">
          <nav aria-label="breadcrumb mt-5 ">
            <ol className="breadcrumb " style={{backgroundColor: '#fff', paddingTop: '3rem'}}>
              <li className="breadcrumb-item">
                <a href="/clinicians_help" className="roboto-reg-18px" style={{color: '#4f5be7'}}><b>Support</b></a>
              </li>
              {/* <li class="breadcrumb-item"><a href="#">Library</a></li> */}
              <li className="breadcrumb-item active" aria-current="page">
                <a className="roboto-reg-18px">Genetic Counselling</a>
              </li>
            </ol>
          </nav>
          {/* Support / Genetic Counselling */}
          <div className=" mt-5">
            <div className="text-center">
              <div className="card-block  p-2 circle">
                <div className="pr-5 pb-5 pl-5 hc-accordian-wrap">
                  <div className="accordion" id="accordionIhc">
                    <div className="card">
                      <div className="card-header" id="headingOne">
                        {/* <a href="help_indivisual.html" style="color:#4f5be7"> */}
                        <h5 className="acc-header collapse-color mb-0">
                          Who is a genetic counsellor?
                          {this.state.b1?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle1}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle1} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q1}>
                      <div id="a1" className="collapse show " aria-labelledby="headingOne" data-parent="#accordionIhc">
                        <div className="card-body">
                          {/* <a href="help_indivisual.html" style="color:#000"> */}
                          <p>A genetic counsellor is an individual healthcare professional who has undergone specialised training in human genetics and
                            counselling. If you are wanting to understand your genetics or planning to have or have had a genetic test, a genetic
                            counsellor will play a very important role in this journey.</p>
                          <p>As genetic counsellor will first help you understand your genetic health based upon your personal health history and your
                            family health history. They will guide you if you need to undergo genetic testing and also recommend the rights tests for you.
                            Once you have done a genetic test, genetic counsellors will help you to understand your report and guide you on next steps.</p>
                        </div>
                      </div>
                      </Collapse>
                      
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingTwo">
                        <h5 className="acc-header collapse-color mb-0">
                          {/* <a href="help_indivisual.html" style="color:#4f5be7"> */}
                          What information and details do i need to provide before my genetic counselling appointment?
                          {this.state.b2?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle2}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle2} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q2}>

                      <div id="a2" className="collapse show " aria-labelledby="headingTwo" data-parent="#accordionIhc">
                        <div className="card-body">
                          {/* <a href="help_indivisual.html" style="color:#000"> */}
                          <p>When you log into your account on the truGeny website and schedule an appointment, you will need to ensure that your
                            personal health history as well as your family health history details are all filled in and updated. It is important for the genetic
                            counsellor to have a complete picture of your’s and your family’s health history.Additionally, if you have any clinical test
                            reports or have already undergone a genetic test, we recommend for your to share these details on your account.</p><br />
                          <p>Additionally, before your appointment you may want to prepare a list of questions, so that your counselling appointment time
                            is well utilised and help the counsellor to focus and address on a specific concern area.</p>
                        </div>
                      </div>
                      
                      </Collapse>
                      
                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        {/* <a href="help_indivisual.html" style="color:#4f5be7"> */}
                        <h5 className="acc-header collapse-color mb-0">
                          What will happen during my genetic counselling appointment?
                          {this.state.b3?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle3}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle3} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q3}>
                      <div id="a3" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          {/* <a href="help_indivisual.html" style="color:#000"> */}
                          <p>Our genetic counsellor will prepare for your appointment by reviewing your’s and your family’s health history to create a
                            detailed tree (pedigree) of family medical information. During the appointment, our counsellor will review and discuss your
                            risks for a particular condition. The counsellor will answer your questions and address any concerns you might have.
                            The counsellor will also review your options for genetic testing and guide you on the right test for you.</p>
                          <p>If you have already had a genetic test done, the counsellor will you help you interpret and understand your reports and
                            discuss the next steps to better manage your health.</p>
                          <p>Overall a genetic counselling session will last for 30 to 45 minutes.</p>
                        </div>
                      </div>
                      </Collapse>
                      
                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        <h5 className="acc-header collapse-color mb-0">
                          {/* <a href="help_indivisual.html" style="color:#4f5be7"> */}
                          If i have purchased a truGeny test do i still need to pay for genetic counselling?
                          {this.state.b4?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle4}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle4} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q4}>
                      <div id="a4" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>Each time you buy a truGeny test, you will receive complimentary genetic counselling session. You will not need to pay any
                            additional amount for a genetic counselling session</p>
                        </div>
                      </div>
                      </Collapse>
                     
                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingFive">
                        <h5 className="acc-header collapse-color mb-0">
                          {/* <p style="color:#4f5be7"> */}
                          What is a genetic condition?
                          {this.state.b5?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle5}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle5} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q5}>
                      <div id="a5" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          {/* <a href="help_indivisual.html" style="color:#000"> */}
                          <p>Genetics plays a very important part in most health conditions, such as heart disease, cancer, and many more.
                            Understanding family health history and conducting Genetic testing can help determine if a person has an increased risk of
                            developing a particular health condition, or passing on the risk onto their children.
                            Genetic factors also influence how individuals respond to certain medications.</p>
                        </div>
                      </div>
                      </Collapse>
                      
                    </div>
                  </div>
                </div>{/* eo bs-example */}
                {/* end of accordian code */}
              </div>
            </div>{/* eo card-block */}
          </div>{/* eo card body */}
          {/* eo card */}
          {/* eo-heart-accordian-sec-wrap */}
        </section>{/* eo-heart-accordian-sec */}
        {/* **********Schedule your Genetic Counseling Session Ends Here************** */}
        {/* Footer Links */}
        <Footer></Footer>
        <MobileFooter></MobileFooter>
        {/* end of footer */}
        {/* --------eo sticky form----------------- */}
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>

        
       )
   }

}
export default CliniciansHelpCounselling;