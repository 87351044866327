import React from 'react'

class Razorpay extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            razorpayData:''
        }

    }
    render() {
       
        const {razorpayOrderId}=this.props.location
      
        return (
            <form method="POST" action="https://api.razorpay.com/v1/checkout/embedded">
            <input type="hidden" name="key_id" defaultValue="rzp_test_3oWYKsj947PbQ0" />
            <input type="hidden" name="order_id" defaultValue={razorpayOrderId} />
            <input type="hidden" name="name" defaultValue="Acme Corp" />
            <input type="hidden" name="description" defaultValue="A Wild Sheep Chase" />
            <input type="hidden" name="prefill[name]" defaultValue="Gaurav Kumar" />
            <input type="hidden" name="prefill[contact]" defaultValue={9123456780} />
            <input type="hidden" name="prefill[email]" defaultValue="gaurav.kumar@example.com" />
            <input type="hidden" name="notes[shipping address]" defaultValue="L-16, The Business Centre, 61 Wellfield Road, New Delhi - 110001" />
            <input type="hidden" name="callback_url" defaultValue="http://localhost:8081/cartservice/razorpay" />
            <input type="hidden" name="cancel_url" defaultValue="https://localhost:3000" />
            <button>Payment By Razorpay</button>
          </form>
        );
            
    }
}
export default Razorpay;