import React from 'react';
import NewMainHeader from './NewMainHeader';
import Prospect from './Prospect';
import Footer from './Footer';
import MobileFooter from './MobileFooter';
import GeneticCounsellingCarouselCards from './GeneticCounsellingCarouselCards';
import GeneticCounsellingCarouselCards2 from './GeneticCounsellingCarouselCards2';
import GeneticCounsellingCarouselCards3 from './GeneticCounsellingCarouselCards3';
import StickyContactMenu from './StickyContactMenu';
import MetaTags from 'react-meta-tags';
import FaqsComponent from './faqscomponent';


class GeneticCounselling extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  toLogin() {
    window.location.href = "/register";
  }


  toGeneticHelp() {

    window.location.href = "/genetic-help";
  }
  toBookSession() {
    window.location.href = "/book-counselling"
  }
  toOrder() {
    window.location.href = "/product-page"
  }
  render() {
    return (


      <div>
        <meta charSet="utf-8" />
        <title>truGeny</title>
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />

        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/header_footer.css" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <link rel="stylesheet" href="./css/sticky.css" />

        <link rel="stylesheet" href="./style-mobile2.css" />
        <link rel="stylesheet" href="style-mobile.css" />
        <MetaTags>
          <title>Genetic Counselling in India | Pregnancy | Cancer | Heart Disease</title>
          <meta name="description" content="truGeny offers genetic counselling services to help people understand complex information about their DNA. Genetic counselling in india." />
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
          <meta property="og:title" content="Genetic Counselling in India | Pregnancy | Cancer | Heart Disease" />
          <meta property="og:site_name" content="truGeny" />
          <meta property="og:url" content="https://trugeny.com/" />
          <meta property="og:description" content="truGeny offers genetic counselling services to help people understand complex information about their DNA. Genetic counselling in india." />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="" />
          <meta name="keywords" content="Genetic Counselling in India, genetic counselling for pregnancy, genetic counselling for cancer, genetic counselling for heart disease, cost of genetic testing in india, genetic testing in india, cost genetic testing in india, genetic testing for cancer in india, genetic testing for pregnancy, genetic testing for heart disease" />

        </MetaTags>



        <style dangerouslySetInnerHTML={{ __html: "\nhtml{\n  scroll-behavior: [ auto | smooth ];\n}\n.font-nav{\n  padding-right:15px;\nfont-size:15px;\ncolor:gray;\nfont-weight:300;\n\n}\n.img-size-custom{\n   height:70px;\n   width:120px;\n}\n\n.img-11{\n  height:48px;\n  /* width:120px; */\n}\n.nav-tab-1{\n    padding-top:5px!important;\n    padding-bottom:5px!important;\n    color:#808080;\n    font-size:15px;\n\n}\n@media (max-width: 767.98px) {\n  .font-nav{\n    padding-right:4px;\n  font-size:10px;\n  color:gray;\n\n  }\n  .nav-tab-1{\n      padding-top:3px!important;\n      padding-bottom:3px!important;\n      color:#808080;\n\n  }\n   }\n   .nav-active1{\n     font-weight:700;\n     color:#4f5be7;\n   }\n   .roboto-black-28pxx{\n     font-size:28px!important;\n   }\n    color:#4f5be7;\n   }\n\n" }} />


        <NewMainHeader category="genetic-counselling">
        </NewMainHeader>
        <StickyContactMenu ></StickyContactMenu>


        <div class="banner_genetic_councelling1">
          <div class="container">
            <div class="row">
              <div class="col-sm-12">
                <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <h1 class="plan-future">Begin your family planning with a<br />
                        HELLO! </h1>
                    </div>
                    <div class="carousel-item">
                      <h1 class="plan-future">Begin your family planning with a<br />
                        HELLO!2 </h1>
                    </div>
                    <div class="carousel-item">
                      <h1 class="plan-future">Begin your family planning with a<br />
                        HELLO!3 </h1>
                    </div>
                  </div>
                  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  </button>
                  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="familyplanning1 home_new1 shadow">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <p class="familyplanning2 pt-5 px-5">While planning for a baby, understand your health as a couple. Start with a simple conversation with experts. </p>
                <div class="text-center mb-5"><a href="" class="familyplanning3  btn-primary">LEARN NOW</a></div>
              </div>
            </div>
          </div>
        </div>

        <div class=" home_new2">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <p class="familyplanning2">While planning for a baby, understand your health as a couple. Start with a simple conversation with experts. </p>
                <div class="text-center mb-5">
                  <a href="" class="familyplanning3  btn-primary">LEARN NOW</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="row">
            <div class="col-sm-12 py-5 text-center">
              <p class="familyplanning4">Planning a pregnancy and want to protect your baby’s health?</p>
              <p class="familyplanning9 familyplanning10">Now You Can!<br />
                Make Well Informed Decisions with truGeny</p>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row py-5">
            <div class="col-sm-6 mt-5 px-0">
              <div class="genetic2 p-5">
                <p class="familyplanning6 familyplanning8 familyplanning13">Get Expert Guidance             </p>
                <p class="familyplanning7 familyplanning8 familyplanning13">Did you know that most diseases have a genetic cause?
                  truGeny’s board certified and expert genetic counsellors can help evaluate if you as a couple are carrying any genetic risk that could be passed on to your child. </p>
              </div>
            </div>
            <div class="col-sm-6 text-center">
              <img class="img-fluid" src="./img/newpages/Illustration6.png"/>
            </div>
          </div>
        </div>


        <div class="container-fluid">
          <div class="">
            <div class="row py-5">
              <div class="col-sm-5 text-center" style={{ zIndex: '5' }}>
                <img class="img-fluid familyplanning12 m-0" src="./img/newpages/genetics-health-risks.png" />
              </div>
              <div class="col-sm-7 px-0">
                <div class="genetic3 p-5">
                  <p class="familyplanning7 familyplanning13 pl-5 familyplanning14 familyplanning28">Learn about your genes and your health risks from the comfort
                    of your home<br /><br /> With truGeny’s flexible tele-genetics platform, you can select for a phone or an online video consultation and schedule a timing of your convenience. </p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="container-fluid">
          <div class="">
            <div class="row py-5">
              <div class="col-sm-7 px-0">
                <div class="genetic4 p-5">
                  <p class="familyplanning6 familyplanning13 pl-5 familyplanning14">Be Proactive about your Health. <br />Get Genetic Counselling.</p>
                  <p class="familyplanning7 familyplanning13 pl-5 familyplanning14 familyplanning28">Our expert genetic counsellors will help you evaluate your risks and guide you to make the right choices for better health. The duration of a counselling session will be for 30 minutes. At the end of the session, you will receive an easy-to-understand summary and guidance on the next steps to follow.</p>
                </div>
              </div>
              <div class="col-sm-5 text-center" style={{ zIndex: '5' }}>
                <img class="img-fluid familyplanning12 m-0 pt-lg-5" src="./img/newpages/genetics-health-risks.png" />
              </div>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="row">
            <div class="col-sm-12 py-5 text-center">
              <p class="familyplanning4 pb-4">Reasons for you to schedule a Genetic Counselling Session </p>

            </div>
          </div>
          <div class="row genetic8">
            <div class="col-sm-6 text-center">
              <div class="card shadow border-0 genetic5">
                <p class="text-center mb-0 pt-4"><img class="img-fluid card-img-top" src="./img/newpages/planning-pregnancy.png" style={{ width: '50%' }} /></p>
                <p class="familyplanning7 familyplanning13 pt-4 mb-0 px-4">Planning a Pregnancy</p>
                <p class="genetic7 familyplanning13 pt-4 px-4">Understanding all the options of prenatal
                  testing during a pregnancy can be overwhelming.
                  Our genetic experts can help identify and
                  interpret risks for an inherited disorder and
                  suggest the best prenatal testing options for you.</p>
                <div class=" mb-5 mt-4">
                  <a href="" class="genetic6  btn-primary">SCHEDULE YOUR APPOINTMENT</a>
                </div>
              </div>
            </div>
            <div class="col-sm-6 text-center">
              <div class="card shadow border-0 genetic5">
                <p class="text-center mb-0 pt-4"><img class="img-fluid card-img-top" src="./img/newpages/pregnant.png" style={{ width: '33%' }} /></p>
                {/* style="width: 33%;" */}
                <p class="familyplanning7 familyplanning13 pt-4">Pregnant</p>
                <p class="genetic7 familyplanning13 pt-4 px-4">Understanding all the options of prenatal
                  testing during a pregnancy can be overwhelming.
                  Our genetic experts can help identify and
                  interpret risks for an inherited disorder and
                  suggest the best prenatal testing options for you.</p>
                <div class=" mb-5 mt-4">
                  <a href="" class="genetic6  btn-primary">SCHEDULE YOUR APPOINTMENT</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="row">
            <div class="col-sm-12 py-5 text-center">
              <p class="familyplanning4">A Genetic Counselling session can help you:</p>
            </div>
          </div>
          <div class="row py-5" style={{maxWidth: '1100px',margin: 'auto'}}>
            <div class="col-sm-3 text-center">
              <img class="img-fluid familyplanning21" src="./img/newpages/Group 196.png" />
              <p class="genetic7 pt-4">Understand your risk of developing a disease based
                on your family health history or a genetic test report</p>
            </div>
            <div class="col-sm-3 text-center">
              <img class="img-fluid familyplanning21" src="./img/newpages/Group 195.png" />
              <p class="genetic7 pt-4">Determine if genetic testing is a good option for you</p>
            </div>
            <div class="col-sm-3 text-center">
              <img class="img-fluid familyplanning21" src="./img/newpages/Group 194.png" />
              <p class="genetic7 pt-4">Choose the right genetic test for you</p>
            </div>
            <div class="col-sm-3 text-center">
              <img class="img-fluid familyplanning21" src="./img/newpages/Group 193.png" />
              <p class="genetic7 pt-4">Understand your results and take actions accordingly</p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 py-5 text-center">
              <a href="" class="familyplanning3  btn-primary">SCHEDULE YOUR APPOINTMENT</a>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="">
            <div class="row py-5">
              <div class="col-sm-5 text-center pt-lg-4" style={{ zIndex: '5' }}>
                <img class="img-fluid familyplanning12" src="./img/newpages/illu123.png" />
              </div>
              <div class="col-sm-7 px-0">
                <div class="familyplanning11 p-5">
                  <p class="familyplanning6 familyplanning13 pl-5 familyplanning14">Understanding of your genetic risks
                    or to consider genetic testing</p>
                  <p class="familyplanning7 familyplanning13 pl-5 familyplanning14 familyplanning28">You will receive a 30 to 60 minute phone or video consultation with our genetic counsellors. They will discuss your genetic risks, and various testing options as appropriate and help you understand the significance of testing.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="row py-5">
            <div class="col-sm-6 px-0">
              <p class="familyplanning6 familyplanning13">Understanding of your genetic<br />
                test reports </p>
              <p class="familyplanning7  familyplanning13">You will receive a 30 minute phone or video
                consultation. Our Genetic Counselling experts will help you interpret and understand the genetic test report. They will explain about what these results mean for you and your family. The counsellors will answer your questions and discuss further options that you could consider.</p>
            </div>
            <div class="col-sm-6 text-center">
              <img class="img-fluid" src="./img/newpages/benefitIllustration.png" />
            </div>
          </div>
        </div>



        <div class="container">
          <div class="row">
            <div class="col-sm-12 py-5 text-center">
              <p class="familyplanning4">IT'S AN EASY AND SIMPLE PROCESS</p>
              <p class="familyplanning9 familyplanning10">What do you GET?</p>
              <p class="familyplanning9 familyplanning10 pt-3">It starts with a conversation </p>
            </div>
          </div>
          <div class="row py-5">
            <div class="col-sm-3 text-center">
              <img class="img-fluid familyplanning21" src="./img/newpages/Group 210.png"/>
              <p class ="familyplanning7 familyplanning13 pt-4">1. SCHEDULE AN<br/>APPOINTMENT</p>
            </div>
            <div class="col-sm-3 text-center">
              <img class="img-fluid familyplanning21" src="./img/newpages/Group 211.png"/>
              <p class ="familyplanning7 familyplanning13 pt-4">2. INITIAL<br/> CONSULTATION</p>
            </div>
            <div class="col-sm-3 text-center">
              <img class="img-fluid familyplanning21" src="./img/newpages/Group 212.png" />
              <p class="familyplanning7 familyplanning13 pt-4">3. SAMPLE<br />COLLECTION</p>
            </div>
            <div class="col-sm-3 text-center">
              <img class="img-fluid familyplanning21" src="./img/newpages/Group 210.png" />
              <p class="familyplanning7 familyplanning13 pt-4">4. RESULTS<br /> CONSULTATION</p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 py-5 text-center">
              <p class="familyplanning4 mb-5">GET A 15 MIN FREE HEALTH CONSULTATION! </p>
              <a href="" class="familyplanning3  btn-primary">BOOK MY APPOINTMENT</a>
            </div>
          </div>
        </div>

        <div class="familyplanning22 py-5 mb-5">
          <div class="container">
            <div class="row py-4">
              <div class="col-12">
                <div class="familyplanning23 text-center">
                  <p class="familyplanning6 mb-0 py-5 ">Get your health risk score <img class="img-fluid" src="./img/newpages/Group 16.png" /> </p>
                </div>
              </div>
            </div>
          </div>
        </div>


        

        <Prospect></Prospect>



        <Footer></Footer>

        <MobileFooter></MobileFooter>

      </div>
    );
  }
}
export default GeneticCounselling;
