import React from 'react';
import {withRouter} from 'react-router-dom'
import axios from '../axios/axios';
import swal from 'sweetalert';

class CliniciansProspect extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

        errors:{}
      };
    this.prospectReg = this.prospectReg.bind(this);
   
  }
  prospect = (e) => {
    const prospectForm = this.state
    prospectForm[e.target.name] = e.target.value;
    this.setState(prospectForm);
   
  }
  validateForm() {

    let fields = this.state;
    let errors = {};
    let formIsValid = true;


    if (!fields["firstname"]) {
      formIsValid = false;
      errors["firstname"] = "*Please enter your first name.";
    }

    if (typeof fields["firstname"] !== "undefined") {
      if (!fields["firstname"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["firstname"] = "*Please enter alphabet characters only.";
      }
    }

    if (!fields["organizationname"]) {
      formIsValid = false;
      errors["organizationname"] = "*Please enter your Organization name.";
    }

    if (typeof fields["organizationname"] !== "undefined") {
      if (!fields["organizationname"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["organizationname"] = "*Please enter alphabet characters only.";
      }
    }
    

    if (!fields["lastname"]) {
      formIsValid = false;
      errors["lastname"] = "*Please enter your first name.";
    }

    if (typeof fields["lastname"] !== "undefined") {
      if (!fields["lastname"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["lastname"] = "*Please enter alphabet characters only.";
      }
    }

    if (!fields["pemail"]) {
        formIsValid = false;
        errors["pemail"] = "*Please enter your email address";
    }
    

    if (typeof fields["pemail"] !== "undefined") {
        //regular expression for email validation
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(fields["pemail"])) {
            formIsValid = false;
            errors["pemail"] = "*Please enter valid email address";
        }
    }

    if (!fields["pphone"]) {
        formIsValid = false;
        errors["pphone"] = "*Please enter your mobile no.";
    }

    if (typeof fields["pphone"] !== "undefined") {
        if (!fields["pphone"].match(/^[0-9]{10}$/)) {
            formIsValid = false;
            errors["pphone"] = "*Please enter valid mobile no.";
        }
    }

    this.setState({
        errors: errors
    });
    return formIsValid;

}
  prospectReg(e) {
    e.preventDefault();

    if (this.validateForm()) {
      const source = "ClinicianLead";


        const { firstname,lastname, pphone, pemail,pmessage,organizationname } = this.state;
        axios.post('users/prospect', {  firstname,lastname, pphone, pemail,source, pmessage,organizationname })
            .then((response) => {
                
              swal("Thank you for your interest. We will get back you soon...").then(this.setState({errors:{}},()=>{}))
             
                // this.setState({
                //     sucessMsg:"Thank you for your interest. We will get back you soon..."
                // })
               // alert("Thanks for your intersr. We will get back you soon")   
            
 
            }).catch(error => {
              if(error.response===undefined){
                this.props.history.push("/clinicians_error")
              }else{
                this.setState({
                    errorMsg: error.response.data.message
                })  
              }
               
            });
            e.target.reset();
    }
   
  
}
  render() {
      return (
        <div>
        <div className="gc-form-card container">
          <div className="gc-form-card-wrap  shadow-lg">
            <div className=" gc-form-card-top">
              <h2 className="text-center">Learn how to partner with us</h2>
              <p className=" gc-form-subtitle text-center"> We work with Physicians and Hospital systems that want to build or expand their genomic service offerings.
                Fill in the information below and one of our representatives will get in touch with you soon. </p>
              {/* <p class="text-center" > sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p> */}
            </div>{/* gc-form-card-top */}
            <div className="gc-form-card-btm">
              <form className="gc-form needs-validation" onSubmit={this.prospectReg} >
                <div className="form-row justify-content-center">
                  <div className="gc-col  col-md-5 mb-3" htmlFor="validationTooltip01">
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.firstname}</div>
                    <div className="input-group ">
                    <input type="text"  className="form-control m-0" id="validationTooltip01" name="firstname" placeholder="First Name:" onChange={this.prospect} required   />
                      <div className="valid-tooltip">
                        Looks good!
                      </div>
                      <div className="input-group-prepend border-0">
                        <span className="input-group-text border-0"  id="validationTooltip01"><img src="img/homepage/i1.png" /></span>
                      </div>
                    </div>
                  </div>
                  <div className="gc-col col-md-5 mb-3">
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.lastname}</div>
                    <div className="input-group">
                      <input type="text" className="form-control m-0" name="lastname" id="validationTooltip02" onChange={this.prospect} placeholder="Last Name" required />
                      <div className="valid-tooltip">
                        Looks good!
                      </div>
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="validationTooltip02"><img src="img/homepage/i1.png" /></span>
                      </div>
                    </div>
                  </div>
                </div>{/* end of form-row */}
                <div className="form-row justify-content-center">
                  <div className=" gc-col col-md-5 mb-3">
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.pemail}</div>
                    <div className="input-group">
                      <input type="text" name="pemail" onChange={this.prospect} className="form-control m-0" id="validationTooltipEmailPrepend" placeholder="E-mail ID" aria-describedby="validationTooltipEmailPrepend" required />
                      <div className="invalid-tooltip">
                        Please enter a valid E-mail ID.
                      </div>
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="validationTooltipEmailPrepend"><img src="img/homepage/i3.png" /></span>
                      </div>
                    </div>
                  </div>
                  <div className="gc-col col-md-5 mb-3">
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.organizationname}</div>
                    <div className="input-group">
                      <input type="text" className="form-control m-0" id="validationTooltip03" name="organizationname" onChange={this.prospect} placeholder="Organization name" aria-describedby="validationTooltip03" required />
                      <div className="valid-tooltip">
                        Looks good!
                      </div>
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="validationTooltip03"><img src="img/clinicians/heart-test/star.png" /></span>
                      </div>
                    </div>
                  </div>
                </div>{/* end of form-row */}
                <div className="form-row justify-content-center">
                  <div className="gc-col col-md-5 mb-3">
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.pphone}</div>
                    <div className="input-group">
                      <input type="text" maxlength={10} className="form-control m-0" id="validationTooltip05" name="pphone" onChange={this.prospect}  placeholder="Mobile number" required />
                      <div className="invalid-tooltip">
                        Please provide a valid Phone number.
                      </div>
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="validationTooltip05"><img src="img/homepage/mob.png" /></span>
                      </div>
                    </div>
                  </div>
                  <div className="gc-col col-md-5 mb-3">
                    <select className="form-control roboto-reg-16px" id="exampleFormControlSelect1" type="text" name="pmessage" maxlength="1500"
                    onChange={this.prospect} style={{border: 'none', height: '60px'}}>
                      <option className="dropdown-item roboto-reg-16px">How did you hear about us</option>
                      <option className="dropdown-item roboto-reg-16px" value="Ad">Ad</option>
                      <option className="dropdown-item roboto-reg-16px" value="Website">Website</option>
                      <option className="dropdown-item roboto-reg-16px" value="Internet">Internet</option>
                      <option className="dropdown-item roboto-reg-16px" value="Social Media">Social Media</option>
                    </select>
                  </div>
                </div>{/* end of form-row */}
                <div className="wrapper pt-3 mt-4 mb-5 pb-5">
                  <button className="center-block btn btn-outline-primary btn-hover-white my-2 my-sm-0 center" style={{height: '60px', width: '210px', borderRadius: '30px', color: 'white', background: '#4f5be7'}} name="submit" type="submit"><b>SUBMIT</b></button>
                </div>
              </form>
            </div>{/* end of gc-form-card-btm */}
          </div>{/* end of gc-form-card-wrap */}
        </div>{/* end of gc-form-card */}
        </div>

      
        
      );
  }
    }
    


export default withRouter(CliniciansProspect);